import React from "react";
import {
    FiniteListWithAsyncLoaderAbstract, ListProps
} from "../../Components";
import {useMercuryContext} from "../../user-context";

class LanguageCodeFilter extends FiniteListWithAsyncLoaderAbstract {

    async fetch() {

        const { data } = await this.props.context.legacyClient.get("/languageCode");
        return data.map( ( item:any ) => {
            return {
                title: item.code,
                value: item._id
            }
        })
    }
}

export default (props:ListProps) => {
    const context = useMercuryContext();
    return <LanguageCodeFilter context={context} {...props} />
}
