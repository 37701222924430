import axios from "axios";
import ConcurrentTaskQueue from "../../utils/ConcurrentTaskQueue";

export default class InvoiceSubmission {

    Context = null;

    projects = new Map();

    events = new Map();

    constructor(props: any) {

        this.Context = props.context;
        //@ts-ignore
        this.Client = axios.create();

        //@ts-ignore
        this.Client.defaults.baseURL = props.context.baseUrl;
        //@ts-ignore
        this.Client.defaults.headers.common['Authorization'] = `Bearer ${props.context.accessToken}`;
        //@ts-ignore
        this.Client.defaults.headers.common['Content-Type'] = "application/json";

        props.projects.map((project: any, i: any) => {
            this.addProject(project, i);
            return null;
        });

    }

    on(evt: any, fn: any) {
        let events = this.events.get(evt);
        if (events) {
            events.push(fn);
        } else {
            events = [fn];
        }
        this.events.set(evt, events);
        this.emit(evt);
    }

    emit(evt: any) {
        const events = this.events.get(evt);

        if (events) {
            events.map((fn: any) => {
                fn.call(this, Array.from(this.projects.values()))
                return null;
            })
        }
    }

    url = (id: any) => `/project/${id}/submitInvoice`

    addProject(project: any, i: any) {
        project.state = "Queued";
        project.result = "Pending";
        project.attempts = 0;
        project.completed = 0;
        project.errored = 0;
        project.total = project.plps.length;
        this.updateProject(project);
    }

    updateProject(project: any) {
        this.projects.set(project?.shortId, project);
        this.emit("update");
    }

    async submitProject(project: any) {
        this.updateProject({...project, errored: 0})
        await new Promise((accept) => {
            const Queue = new ConcurrentTaskQueue(process.env.REACT_APP_INVOICE_SUBMISSION_CONCURRENCY || 1);
            Queue.on("complete", () => {
                const updated = this.projects.get(project.shortId);
                if ( updated.state === "Errored"  && updated.attempts < 2) {
                    updated.attempts++
                    const retry = {...updated, state: "Errored", result: "Retrying...", errored: 0, completed: 0, attempts: updated.attempts };
                    this.updateProject(retry);
                    this.submitProject(retry);
                    //@ts-ignore
                    return accept();
                }
                else {
                    updated.sent = true;
                }
                this.updateProject(updated);
                //@ts-ignore
                return accept();
            });

            Queue.add(project.plps.map((plp: any) => {

                return async () => {
                    await new Promise((accept) => {

                        this.updateProject( { ...project, state: "Submitting"})

                        if (!project.invoiceName || project.invoiceName === "N/A") {
                            this.updateProject({
                                ...project,
                                state: "Not sent",
                                result: "This project has no invoice number attached and cannot be submitted."
                            })
                            //@ts-ignore
                            return accept();
                        }

                        if (!project.varStatus || ["INVOICE_FAILED", "ACCRUAL"].indexOf(project.varStatus) === -1) {
                            this.updateProject({
                                ...project,
                                state: "Not sent",
                                result: "Project must have a var status of INVOICE_FAILED or ACCRUAL"
                            })
                            //@ts-ignore
                            return accept();
                        }

                        //@ts-ignore
                        this.Client.post(this.url(project.shortId), {plpId: plp})
                            .then((response: any) => {
                                const result = response.data;

                                if (result.success) {
                                    if (result.totalPLP === result.submittedPLP) {
                                        project.completed++;
                                        this.updateProject({
                                            ...project,
                                            state: project.errored ? `Errored` : project.completed === project.total ? "Complete" : "Submitting",
                                            result: project.errored ? `Submitted ${project.completed} of ${project.total}` : project.completed === project.total ? "Success" : "Pending",
                                            sent: project.completed === project.total
                                        });
                                    } else {
                                        this.updateProject({
                                            ...project,
                                            state: "Errored",
                                            result: `Submitted ${project.completed} of ${project.total}`,
                                            errored: project.errored++
                                        });
                                    }
                                } else {
                                    this.updateProject({
                                        ...project,
                                        state: "Errored",
                                        result: `Submitted ${project.completed} of ${project.total}`,
                                        errored: project.errored++
                                    });
                                }
                                //@ts-ignore
                                return accept();
                            })
                            .catch((error: any) => {
                                if (error.response) {
                                    this.updateProject({
                                        ...project,
                                        state: "Errored",
                                        result: `Submitted ${project.completed} of ${project.total}`,
                                        errored: project.errored++
                                    });

                                } else {
                                    this.updateProject({
                                        ...project,
                                        state: "Errored",
                                        result: `An unknown error occurred, will retry.`,
                                        errored: project.errored++
                                    });
                                }
                                //@ts-ignore
                                return accept();
                            })
                    })
                }
            }))
        })
    }

    retry(id: any) {
        const project = this.projects.get(id);

        if (project) {
            project.attempts++
            project.sent = false;
            project.state = "Retrying...";
            project.completed = 0;
            project.errored = 0;
            this.updateProject(project);
            this.submitProject(project);
        }
    }

    async runner() {
        await new Promise((accept) => {
            const Queue = new ConcurrentTaskQueue(5);
            Queue.on("complete", accept);
            Queue.add(Array.from(this.projects.values()).map((project) => {
                return async () => await this.submitProject(project);
            }))
        })
    }

    async submit() {
        await this.runner();
    }
}
