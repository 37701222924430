import React from "react";

import { Button, Drawer, Form, Input } from "antd";
import {
  ReloadOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import UniversalButton from "../../../ui/UniversalButton";

const AddHelixTemplate = ({ handlers, values }) => {
  const { showDrawer, closeDrawer, onFinishNewTemplate, handleRefresh } =
    handlers;
  const { form, visibleDrawer, helixTemplates } = values;

  return (
    <>
      <UniversalButton
        clickMethod={showDrawer}
        text="Add Template"
        type="primary"
        icon={
          !visibleDrawer ? (
            <PlusCircleOutlined style={{ fontSize: "15px" }} />
          ) : (
            <MinusCircleOutlined style={{ fontSize: "15px" }} />
          )
        }
      />
      <UniversalButton
        clickMethod={handleRefresh}
        text="Refresh"
        icon={<ReloadOutlined />}
      />
      <Drawer
        title="Add Helix Template"
        placement="right"
        onClose={closeDrawer}
        open={visibleDrawer}
        width={500}
        closable={true}
        className="manage-pages-drawer"
        forceRender
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinishNewTemplate}
          name="newTemplateForm"
        >
          <Form.Item
            name="nameValue"
            label="Name"
            rules={[
              { required: true, message: "Please enter Helix Template name" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="helixIdValue"
            label="Helix Template ID"
            rules={[
              {
                required: true,
                message: "Please enter Helix Template ID",
              },
              // ids can't be the same
              ({ getFieldValue }) => ({
                validator(record, value) {
                  const helixForm = getFieldValue();
                  const helixIdValues = [];

                  helixTemplates.map((item) => {
                    return helixIdValues.push({
                      id: item._id,
                      helixId: item.helixId,
                    });
                  });

                  const matchedHelixId = helixIdValues.filter(
                    (item) =>
                      item.helixId === value && item.id !== helixForm.idValue
                  );

                  if (matchedHelixId.length === 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error(
                        "ID: " +
                          value +
                          " already exists, please enter a new id"
                      )
                    );
                  }
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="descValue"
            label="Description"
            rules={[
              { required: true, message: "Please enter Helix a description" },
            ]}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save Template
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default AddHelixTemplate;
