import React from "react";
import Avatar from "../Avatar/Avatar";
import {Avatar as PictureAvatar, Popover, Typography} from "antd";
import TeamsLogo from "./TeamsLogo";
import "./UserBadge.scss"
import {MailOutlined} from "@ant-design/icons";
const { Text } = Typography;

interface UserBadge {
    firstName?: string;
    lastName?: string;
    email?: string;
    name: string;
    position?: string;
    reportsTo?: string;
    hideAvatar?:boolean;
    picture?: string;
    avatarSize?:number;
    hideEmail?:boolean;
}

export default function UserBadge( props:UserBadge ) {

    const
        badge = (
            <div
                className="userBadge top-wrap"
                style={{
                    display: "flex",
                    alignItems: "left"
                }}
            >
                {
                    props.picture
                        ? <PictureAvatar size={props.avatarSize || 50} src={props.picture}  />
                        : !props.hideAvatar && <Avatar
                        size={props.avatarSize || 50}
                        firstName={props.firstName || props.name.split(" ")[0]}
                        lastName={props.lastName || props.name.split(" ")[1]}
                        name={props.name}
                    />
                }
                <div
                    className="details-wrap"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                    }}
                >
                    {
                        props.email
                            ? <a href={`msteams:/l/chat/0/0?users=${props.email}`}>
                            <span>
                                <Text strong>{props.name} <TeamsLogo scale={0.2} /></Text>
                            </span>
                            </a>
                            : <Text strong>{props.name}</Text>
                    }
                    {
                        props.email && !props.hideEmail && (
                            <a href={`mailto:${props.email}`}><Text>{props.email}</Text></a>
                        )}
                </div>
            </div>
        ),
        popContent = (
            <div className={"userBadgeContainer"}>
                <p><strong>{props.name}</strong></p>
                <dl>
                    <dt><TeamsLogo scale={0.2} /><a href={`msteams:/l/chat/0/0?users=${props.email}`}>{props.email}</a></dt>
                    <dt><MailOutlined /><a href={`mailto:${props.email}`}>{props.email}</a></dt>
                </dl>
            </div>
        );

    if ( props.hideEmail ) {
        return (
            <Popover placement="right" content={popContent} trigger="hover" >
                <div className={"userBadgeContainer"}>
                    {badge}
                </div>
            </Popover>
        )
    }
    return <div className={"userBadgeContainer"}>{badge}</div>;
}
