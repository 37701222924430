import { FunctionComponent } from "react";
import { Progress } from "antd";
import "./progress.scss";
export interface ProgressBarProps {
  showInfo?: boolean;
  status: "normal" | "success" | "exception" | "active" | undefined;
  title?: string;
  progress: number;
  strokeColor?: any;
  strokeWidth?: number;
  trailColor?: string;
  type?: "line" | "circle" | "dashboard";
  size?: "small" | "default";
  websocket?: string;
  emit?: string;
  customStyle?: any;
}

export const ProgressBar: FunctionComponent<ProgressBarProps> = ({
  progress,
  customStyle,
  showInfo,
  status,
  strokeColor,
  trailColor,
  type,
  size,
}) => {
  return (
    <div className={type === 'circle' ? "progress-wrap" : 'progress-line'}>
      <Progress
        percent={Math.ceil( progress ? progress : 0 ) }
        showInfo={showInfo}
        status={status}
        strokeColor={strokeColor}
        {...(type === 'circle' ? { strokeWidth: 9 } : {})}
        trailColor={trailColor}
        type={type}
        size={size}
        className={type === 'circle' ? "progress-circle" : ''}
        style={{
          ...customStyle
        }}
      />
    </div>
  );
};

export default ProgressBar;
