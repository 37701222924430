import React from "react";
import {
    FiniteListWithAsyncLoaderAbstract, ListProps
} from "../Components";
import {useMercuryContext} from "../user-context";

class AdjStatusFilter extends FiniteListWithAsyncLoaderAbstract {

    async fetch() {

        return await new Promise( ( accept ) => {
            this.props.context.gpSocket.adj.emit(
                "listStatuses",
                ( data: any ) => {
                    return accept( data.map( ( item:any ) => {
                        return {
                            title: item.label,
                            value: item.value
                        }
                    }) )
                }
            );
        }) as any
    }
}

export default (props:ListProps) => {
    const context = useMercuryContext();
    return <AdjStatusFilter context={context} {...props} />
}
