import React from "react";
import {
  FiniteListWithAsyncLoaderAbstract, ListProps
} from "../Components";
import {useMercuryContext} from "../user-context";

class ProductFilter extends FiniteListWithAsyncLoaderAbstract {

  async fetch() {
    const { data } = await this.props.context.gpClient.get("/google-gp-v1/project/listFields/product");
    return data.map( ( item:any ) => {
      return {
        title: item.label,
        value: item.value
      }
    })
  }
}

export default (props:ListProps) => {
  const context = useMercuryContext();
  return <ProductFilter context={context} {...props} />
}
