import React from "react";
import {FiniteListFilter, FiniteListProps} from "../../../stories/FiniteListFilter/FiniteListFilter";

export default (props: FiniteListProps) => {
    return <FiniteListFilter
        {...props}
        data={[
            {
                key: 0,
                title: "System",
                value: 'SYSTEM'
            },
            {
                key: 1,
                title: "Helix",
                value: 'HELIX'
            },
            {
                key: 2,
                title: "Manual",
                value: 'MANUAL'
            }
        ]}/>
}
