import React from "react";
import {Form, Select} from "antd";

const { Option } = Select;

export default function CostCode( props ) {

  const {
    costCodes,
    selectedCostCodes,
    handleCostCodeChange
  } = props;

  return (
    <Form.Item
      label="Cost code"
      name="costCode"
      rules={[{ required: false}]}
      key={props.key}
    >
      <Select
        style={{ width: '100%' }}
        placeholder="Please select"
        value={selectedCostCodes}
        onChange={handleCostCodeChange}
        onClear={handleCostCodeChange}
        allowClear={true}
      >
        {
          ( costCodes || [] ).map( ( costCode ) => {
            return <Option key={costCode.key} value={costCode.code}>{costCode.code}</Option>
          })
        }
      </Select>
    </Form.Item>
  )
}