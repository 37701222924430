import React from "react";
import {
  FiniteListWithAsyncLoaderAbstract, ListProps
} from "../../Components";
import {useMercuryContext} from "../../user-context";

class FiniteServiceFilter extends FiniteListWithAsyncLoaderAbstract {

  async fetch() {
    const { data } = await this.props.context.client.get("/core-gss-v1/service");

    return data.map( ( item:any ) => {
      return {
        title: item.name,
        value: item.id
      }
    })
  }
}

const ServiceFilter = (props:ListProps) => {
  const context = useMercuryContext();

  return <FiniteServiceFilter context={context} {...props} width={350}/>

}

export default ServiceFilter;