import AuthVendorAbstract from "./AuthVendor.abstract";
import {vendors} from "../LoginPage";

import {InteractionRequiredAuthError, LogLevel, PublicClientApplication} from "@azure/msal-browser";

// RWS ONE - AZure Entra ID

/**
 * Configuration object to be passed to MSAL instance on creation.
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
*/

export const rwsOneConfig = {
    auth: {
        clientId: process.env.REACT_APP_RWSONE_CLIENT_ID,
        authority: process.env.REACT_APP_RWSONE_DOMAIN,
        redirectUri: process.env.REACT_APP_ENVIRONMENT_URL // "http://localhost:3000" for local env
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }

                switch (level) {	// eslint-disable-line
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        // console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        // console.warn(message);
                        return;
                }
            }
        }
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const rwsOneLoginRequest = {
    scopes: ["User.Read"]
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API.
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
export default class RWSOne extends AuthVendorAbstract<any> {

    identifier=vendors.RWSONE;

    public CLIENT_ID=process.env.REACT_APP_RWSONE_CLIENT_ID

    async doLogin(): Promise<string|void> {

        this.context.switchProvider( this.identifier )
        const { instance, accounts } = this.provider;

        const
            currentAccount = accounts[0],
            silentRequest = {
                scopes: [`${this.CLIENT_ID}/.default`], // fix for valid signature
                account: currentAccount,
                forceRefresh: false,
            },
            request = {
                scopes: [`${this.CLIENT_ID}/.default`],
                // loginHint: currentAccount?.username,
            };
        try {
            const securityData = await instance.acquireTokenSilent(silentRequest);
            return securityData?.accessToken;
        }
        catch ( e ) {
            return instance.acquireTokenRedirect(request);
        }

    }

    async doLogout(): Promise<void> {

        const
            { instance, accounts, inProgress } = this.provider,
            homeAccountId = accounts && accounts[0]?.homeAccountId;

        const logoutRequest = {
            account: instance.getAccountByHomeId(homeAccountId),
            postLogoutRedirectUri: "/logout",
        };
        await instance.logoutRedirect(logoutRequest);
    }

}
