import InvoiceSubmissionModal from "./InvoiceSubmissionModal";
import AccrualSubmission from "./AccrualSubmission";
import React from "react";

export default class AccrualSubmissionModal extends InvoiceSubmissionModal {

    title = "Accrual submission";

    warning = <p>Submitting accruals. <strong>Please do not close the window or navigate away from the page until the process is complete.</strong></p>;

    async componentDidMount() {

        //@ts-ignore
        const ids = await this.listPlpsDetails( this.props.rows as any )

        this.setState( { loading: false } );

        //@ts-ignore
        const Submitter: any = this.submitter = new AccrualSubmission( {
            //@ts-ignore
            context: this.props.context,
            projects: ids
        });

        Submitter.on( "update", ( s: any ) => {
            this.setState({ rows: s } );
        }  );

        await Submitter.submit();
    }
}
