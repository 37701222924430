import React, { useState, useEffect, useRef, useCallback } from "react";
import { useMercuryContext } from "../user-context";
import { Helmet } from "react-helmet";
import { Form } from "antd";

import PageTitle from "../ui/PageTitle";
import AddCurrency from "../components/managePages/currencies/AddCurrency";
import EditCurrency from "../components/managePages/currencies/EditCurrency";
import DeleteCurrency from "../components/managePages/currencies/DeleteCurrency";
import CurrenciesTable from "../components/managePages/currencies/CurrenciesTable";
import BackTopButton from "../ui/BackTopButton";
import LoadingStar from "../ui/LoadingStar";

import {
  failedNotification,
  successfulNotification,
  infoNotification,
} from "../utils/notifications";

const initialEditingValue = {
  isEditing: false,
  editingRow: "",
  isDeleting: false,
};

const ManageCurrencies = () => {
  const { client: Client, accessToken } = useMercuryContext(),
    editFormRef = useRef(null),
    [form] = Form.useForm(),
    [editingForm] = Form.useForm(),
    [isLoading, setIsLoading] = useState(false),
    [visibleDrawer, setVisibleDrawer] = useState(false),
    [visibleEditDrawer, setVisibleEditDrawer] = useState(false),
    [switchValue, setSwitchValue] = useState(true),
    [currencies, setCurrencies] = useState([]),
    [sortedInfo, setSortedInfo] = useState(""),
    [editing, setEditing] = useState(initialEditingValue),
    [total, setTotal] = useState(0);

  const fetchCurrencyList = useCallback(
    async (useIsLoading = true) => {
      try {
        setIsLoading(useIsLoading);
        const { data } = await Client.get("core-cs-v1");

        setCurrencies(data);
        setTotal(data.length);
      } catch (error) {
        console.error('Error fetching currencies list: ', error);
      }
      setIsLoading(false);
    },
    [Client]
  );

  // add new currency
  const addCurrency = async (body) => {
    try {
      setEditing({ ...editing, isEditing: true, isDeleting: false });
      const { data } = await Client.post("core-cs-v1", body);

      if (data.error) {
        failedNotification(data.error, data.message);
      } else {
        infoNotification(
          `Currency - ${data.name} was added.`,
          "Please refresh the page if the new currency is not visible."
        );
      }
    } catch (error) {
      console.error('Error adding currency: ', error);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // update a single currency
  const updateCurrency = async (currencyId, newData) => {
    try {
      setEditing({ ...editing, isEditing: true, isDeleting: false });
      const { data } = await Client.patch(`core-cs-v1/${currencyId}`, newData);

      if (data) {
        successfulNotification(
          `Currency - ${data.name} was updated.`,
          `Please refresh your page if the update is not visible.`
        );
      }
    } catch (error) {
      console.error('Error updating currency: ', error);
      failedNotification(error.message, error.details);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // on load
  useEffect(() => {
    fetchCurrencyList();
  }, [fetchCurrencyList]);

  const defaultValues = {
    idValue: editing.editingRow.id,
    nameValue: editing.editingRow.name,
    symbolValue: editing.editingRow.symbol,
    decimalCharacterValue: editing.editingRow.decimalCharacter,
    separatorCharacterValue: editing.editingRow.separatorCharacter,
    precisionValue: editing.editingRow.precision,
    displayPatternValue: editing.editingRow.displayPattern,
    negativeDisplayPatternValue: editing.editingRow.negativeDisplayPattern,
    isActiveValue: editing.editingRow.isActive,
  };

  // fix to update initial values of edit form
  useEffect(() => {
    if (editFormRef.current) {
      editingForm.setFieldsValue(defaultValues);
    }
  }, [editFormRef, editingForm, defaultValues]);

  const handleRefresh = async () => {
    try {
      (await accessToken) && fetchCurrencyList(false);
      infoNotification(
        "Currency data reloaded",
        "The Currency data has been reloaded"
      );
    } catch (error) {
      failedNotification(
        "Failed to reload",
        "Unable to reload the Currency data at this time, please try again. If the issue persists please contact support."
      );
    }
  };

  // Add new currency START
  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const closeDrawer = () => {
    setVisibleDrawer(false);
    form.resetFields();
  };

  // handle change of active switch button
  function handleChangeActive(checked) {
    setSwitchValue(checked);
  }

  // on save of add new currency
  const onFinishNewCurrency = (values) => {
    // generates the new template object
    const newValues = {
      name: values.nameValue,
      symbol: values.symbolValue,
      decimalCharacter: values.decimalCharacterValue,
      separatorCharacter: values.separatorCharacterValue,
      precision: values.precisionValue,
      displayPattern: values.displayPatternValue,
      negativeDisplayPattern: values.negativeDisplayPatternValue,
      isActive: switchValue,
    };

    addCurrency(newValues);
    closeDrawer();
  };
  // Add new currency END

  // Update currency START

  function showEditDrawer() {
    setVisibleEditDrawer(true);
  }

  const closeEditDrawer = (event) => {
    setVisibleEditDrawer(false);
    form.resetFields();
    event !== undefined && setEditing(initialEditingValue);
  };

  // on save of edit helix template
  const onFinishEditing = (values) => {
    const {
      idValue,
      nameValue,
      symbolValue,
      decimalCharacterValue,
      separatorCharacterValue,
      precisionValue,
      displayPatternValue,
      negativeDisplayPatternValue,
      isActiveValue,
    } = values;

    const newData = {
      id: idValue,
      name: nameValue,
      symbol: symbolValue,
      decimalCharacter: decimalCharacterValue,
      separatorCharacter: separatorCharacterValue,
      precision: precisionValue,
      displayPattern: displayPatternValue,
      negativeDisplayPattern: negativeDisplayPatternValue,
      isActive: isActiveValue.toString(),
    };

    const currencyId = editing.editingRow.id;

    updateCurrency(currencyId, newData);

    closeEditDrawer();
  };
  // Update END

  const handleFinishDelete = async (submitted) => {
    const currencyId = editing.editingRow.id;
    try {
      // Check if form was submitted or cancelled
      if (!submitted) {
        setEditing(initialEditingValue);
        return;
      }
      setEditing({ ...editing, isEditing: true, isDeleting: true });
      const { data } = await Client.delete(`core-cs-v1/${currencyId}`);

      if (data) {
        successfulNotification(
          `Currency - ${editing.editingRow.name} was deleted.`,
          `Please refresh your page if the update is not visible.`
        );
      }
    } catch (error) {
      console.error('Error deleting a currency: ', error);
      failedNotification(error.message, error.details);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // handle change table
  const handleChangeTable = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
  };

  // handle click table edit button
  const handleClickEdit = (record) => {
    setEditing({
      ...editing,
      isEditing: true,
      editingRow: record,
      isDeleting: false,
    });
    showEditDrawer();
  };

  // Delete button
  const handleClickDelete = (record) => {
    setEditing({
      ...editing,
      isEditing: true,
      editingRow: record,
      isDeleting: true,
    });
  };

  return (
    <div className="manage-page manage-currencies-page" style={{padding: '0 10px'}}>
      <Helmet>
        <title>Manage Currencies - Mercury © RWS</title>
      </Helmet>
      {isLoading ? (
        <LoadingStar />
      ) : (
        <>
          <PageTitle title="Manage Currencies" />
          <AddCurrency
            handlers={{
              showDrawer,
              closeDrawer,
              onFinishNewCurrency,
              handleChangeActive,
              handleRefresh,
            }}
            values={{ form, visibleDrawer, switchValue, currencies }}
          />
          <EditCurrency
            handlers={{
              closeEditDrawer,
              onFinishEditing,
            }}
            values={{
              editingForm,
              visibleEditDrawer,
              editing,
              editFormRef,
              currencies,
            }}
          />
          <DeleteCurrency
            visible={editing.isDeleting}
            onSubmit={handleFinishDelete}
            selectedCurrency={editing.editingRow}
          />
          <CurrenciesTable
            values={{ currencies, sortedInfo, editing, total }}
            handlers={{
              handleChangeTable,
              handleClickEdit,
              handleClickDelete,
              handleFinishDelete,
            }}
          />
          <BackTopButton />
        </>
      )}
    </div>
  );
};

export default ManageCurrencies;
