import React from "react";
import {DatePicker, Form} from "antd";
import datePickerRanges from "../../../utils/datePickerRanges";

const { RangePicker } = DatePicker;

export default function ReceivedDate( props ) {

  const {
    updateReceived
  } = props;

  return (
    <Form.Item
      label="Received date"
      name="received"
      rules={[{ required: false}]}
      key={props.key}
    >
      <RangePicker onChange={updateReceived} style={{width: "100%"}} onClear={updateReceived} allowClear={true} presets={datePickerRanges}/>
    </Form.Item>
  )
}