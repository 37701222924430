import React, {  useMemo, useCallback } from "react";
import { Form, Select } from "antd";

export default function ProductIds(props) {
  
  const { selectedProducts, handleProductChange, products } = props;
  
  const SELECT_ALL_OPTION = {
    label: "Select All",
    value: "all",
  };

  function useSelectAllOption(options) {
    const optionsWithAllOption = useMemo(
      () => [SELECT_ALL_OPTION, ...options],
      [options]
    );
    const getValueFromEvent = useCallback(
      (value, selections) => {
        if (!selections?.length) return selections;
        if (value && value.length && value.includes("all")) {
          const labelInValue = typeof value[0]?.label === "string";
          // if "Select All" option selected, set value to all options also keep labelValue
          return labelInValue ? options : options.map((o) => o.value);
        } else {
          return value;
        }
      },
      [options]
    );

    return [getValueFromEvent, optionsWithAllOption];
  }

  const options = [];

  products?.map((item) =>
    options.push({
      key: item.key,
      value: item.title,
      label: item.title,
    })
  )

  const optionSort = options.sort((a, b) => a.label.localeCompare(b.label));

  const [getValueFromEvent, optionsWithAllOption] = useSelectAllOption(optionSort);

  return (
    <Form.Item
      label="Products"
      name="product_ids"
      rules={[{ required: false }]}
      key={props.key}
      getValueFromEvent={getValueFromEvent}
    >
      <Select
        className="select-multiple"
        mode="multiple"
        style={{ width: "100%" }}
        placeholder="Please select"
        value={selectedProducts}
        onChange={handleProductChange}
        onClear={handleProductChange}
        allowClear={true}
        options={optionsWithAllOption}
      ></Select>
    </Form.Item>
  );
}
