import React from "react";
import { Row, Col, Typography, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

const TableFooter = () => {
  const { Text } = Typography;

  const tableDefinitions = [
    {
      item: "(h)",
      definition: "hour",
    },
    { item: "WWC", definition: "Weighted Word Count" },
    { item: "Received from GGL", definition: "Last response from Google" },
  ];

  return (
    <>
      <Row>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "5px",
          }}
        >
          <Tooltip
            placement="right"
            color={"var(--tertiary-color)"}
            title={"Table columns definition →"}
          >
            <QuestionCircleOutlined className="question-mark" />
          </Tooltip>
        </Col>
        {tableDefinitions.map((el, index) => (
          <Col style={{ padding: "0 22px" }} key={index}>
            <Text strong style={{ color: "var(--tertiary-color)" }}>
              {el.item}
            </Text>
            <Text strong> - </Text>
            <Text>{el.definition}</Text>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default TableFooter;
