import axios from 'axios';
import {
  failedNotification,
  successfulNotification,
} from "../../../utils/notifications";
import { bulkGetDetails, bulkMatchLanguage, docsGetDetails } from './bulkUtils';

const bulkExtensionForm = async (
  requestedDueDate,
  justificationType,
  justificationCode,
  setJustificationType,
  setJustificationCode,
  accessToken,
  setIsModalVisible,
  setLoading,
  setDisabled,
  caseId,
  setCaseId,
  setLanguageOptions,
  setProjectIds,
  projectIds,
  languageOptions,
  apis,
  productTypes,
  productAreas,
  userDetails,
  form,
  rejectHandler
) => {
  const resetState = () => {
    setIsModalVisible(false);
    setLoading(false);
    setJustificationType(null);
    setJustificationCode(0);
    setDisabled(false);
    setCaseId(null);
    setLanguageOptions(null);
    setProjectIds(null);
    form.resetFields();
  };

  let bulkData;
  const bulkDetails = [];
  const docDetails = [];
  let errorResponse = [];

  const getDetails = async () => {

    for (const id of projectIds) {

      const bulkResult = await bulkGetDetails(apis, id, accessToken);
      const docResult = await docsGetDetails(id, accessToken);

      if (bulkResult === null || docResult === null) {
        return console.log('The api fetch result was null. Check endpoint');
      }

      bulkDetails.push(bulkResult?.data);
      docDetails.push(docResult?.data);

    }

    return bulkMatchLanguage(
      bulkDetails,
      docDetails,
      languageOptions,
      productTypes,
      productAreas,
      userDetails
    );

  };

  const executeEndpoints = async () => {
    bulkData = await getDetails();
  };

  await executeEndpoints();

    try {

      let headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      let submittedPLPPromises = [];

        submittedPLPPromises = bulkData.map((extension) => {

        return axios
          .post(
            // `${BASE_PATH()}/${API_VERSION}/ges/extension`, // v1
            `${process.env.REACT_APP_API_URL}/google-ges-v1/`, //v2
            {
              projectId: extension.projectId,
              shortId: extension.shortId,
              sourceLanguageCode: extension.sourceLanguageCode,
              targetLanguageCode: extension.targetLanguageCode,
              originalDueDate: extension.originalDueDate,
              requestedDueDate: requestedDueDate,
              justificationCode,
              justification: justificationType.googleType,
              justificationKind: justificationType.kind,
              justificationLimit: justificationType.limit,
              justificationFrequency: justificationType.frequency,
              urgency: extension.urgency,
              bulk: true,
              requestedBy: extension.requestedBy,
              product: extension.product,
              productArea: extension.productArea,
              // extra fields
              title: extension.title,
              plpStatus: extension.plpStatus,
              docStatus: extension.docStatus,
              dueDateTier: extension.dueDateTier,
              componentType: extension.componentType,
              nonExtendable: extension.nonExtendable,
              receivedDate: extension.receivedDate,
              legallySensitive: extension.legallySensitive,
              projectType: extension.projectType,
              validationStatus: extension.plpStatus,
              ...(caseId ? { caseId } : ''),
            }, {
            headers: headers,
          })
          .then((response) => {
            let targetLanguage = response.data[0].targetLanguageCode;
            successfulNotification(
              `The extension request for ${targetLanguage} successfully submitted`,
              ""
            );
          })
          .catch((error) => {
            if (error.response) {

              errorResponse.push(error.response.data.extension);

              failedNotification(
                error.response.data.details.title.name,
                error.response.data.details.title.message
              );
              
            } else {
              failedNotification("Failed to submit extension");
            }
          });
      });

      await Promise.allSettled(submittedPLPPromises);

    } catch (error) {
      failedNotification("Failed to submit extension");
    }

    if(errorResponse.length !==0) {
      rejectHandler(errorResponse)
    }

    resetState();
  };

export default bulkExtensionForm;
