export default class EventEmitter {

    events = new Map();
    singleFireEvents = new Map();

    on( evt, fn ) {
        let events = this.events.get( evt );
        if ( events ) {
            events.push( fn );
        }
        else {
            events = [ fn ];
        }
        this.events.set( evt, events );
    }

    off( evt, fn ) {
        if ( !fn ) {
            this.removeAllListeners( evt );
            return;
        }

        const
          events = ( this.events.get( evt ) || [] ).filter( ( a, b ) => a !== b ),
          singles = ( this.singleFireEvents.get( evt ) || [] ).filter( ( a, b ) => a !== b );

        this.events.set( evt, events );
        this.singleFireEvents.set( evt, singles );
    }

    emit( evt ) {
        const
          events = this.events.get( evt ),
          singles = this.singleFireEvents.get( evt );

        if ( events ) {
            events.map( ( fn ) => {
                fn.call( this )
                return null;
            })
        }

        if ( singles ) {
            events.map( ( fn ) => {
                fn.call( this )
                return null;
            });
            this.singleFireEvents.delete( evt );
        }
    }

    once( evt, fn ) {
        let events = this.singleFireEvents.get( evt );
        if ( events ) {
            events.push( fn );
        }
        else {
            events = [ fn ];
        }
        this.singleFireEvents.set( evt, events );
    }

    removeAllListeners( evt ) {
        if ( !evt ) {
            this.events = new Map();
            this.singleFireEvents = new Map();
            return;
        }

        this.events.delete( evt );
        this.singleFireEvents.delete( evt );
    }
}
