// import { JUSTIFICATIONS_API } from './config';
import axios from 'axios';

export const getJustifications = async (accessToken) => {
  const res = await axios
    .get(`${process.env.REACT_APP_API_URL}/google-ges-v1/justification/paginate?page=1&limit=500`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .catch((err) => {
      console.error(err);
      return err;
    });

  return res;
};


// for v1
// export const getJustifications = async (accessToken) => {
//   const base_url = JUSTIFICATIONS_API();
//   const res = await axios
//     .get(`${base_url}/get`, {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${accessToken}`,
//       },
//     })
//     .catch((err) => {
//       console.error(err);
//       return err;
//     });
//   return res;
// };
