import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Modal,
  Tooltip,
  Progress
} from "antd";
import { DownOutlined, ShareAltOutlined } from "@ant-design/icons";
import {
  failedNotification,
} from "../utils/notifications";
import { useMercuryContext } from "../user-context";
import { BASE_GLMS_URL } from "../utils/variables";

const errorList: object = {
  amount: null,
  id: null,
}

const ProjectShare = (props: {selectedRows: any, reload: () => Promise<void> }) => {
  const { accessToken, gpClient } = useMercuryContext();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSharing, setIsSharing] = useState<boolean>(false);
  const [sharePassed, setSharePassed] = useState<any>({});
  const [shareFailed, setShareFailed] = useState<any>({
    ...errorList,
    message: null,
  });
  const [shareSkipped, setShareSkipped] = useState<any>(errorList);
  const [progress, setProgress] = useState(0);

  const selectedRows = props.selectedRows;
  let initialValues = selectedRows;

  useEffect(() => {
    if (!visible) {
        setProgress(0);
      }
    }, [visible]);

  let errors = 0;
  let projectIds: any = [];
  let statusDone: any = [];

    // Share button
    const handleClickShare = () => {
      setIsSharing(true);
      setVisible(true);
    };

    const handleClickFinishSharing = async (submitted: any) => {
      try {
        // Check if form was submitted or cancelled
        if (!submitted) {
          setIsSharing(false);
          setVisible(false);
          return;
        }

        setLoading( true );
  
        let headers = {
          Authorization: `Bearer ${accessToken}`,
        };
  
        let submittedProjectPromises = [];
  
        submittedProjectPromises = selectedRows.map((project: any) => {
          if (
            project.projectStatus.toUpperCase() === "CANCELED" ||
            project.projectStatus.toUpperCase() === "CANCELLED" ||
            project.projectStatus.toUpperCase() === "COMPLETED"
          ) {
            statusDone.push(project);
            let shareSkippedArray = statusDone?.map(
              (item: any) => item.shortId
            );
  
            if (isSharing) {
              setShareSkipped({
                amount: shareSkippedArray?.length,
                id: shareSkippedArray,
              });
            }
          } else {
            // Share the project
              const body = {
                "shortIds": [project.shortId],
                "type": "MANUAL",
                // "linguistId":"",
                // "email": "",
                // "sesameId": ""
              };

            return gpClient
              .post(`/google-gp-v1/share/project/manual`, body)
              .then(() => {
                props.reload()
                if(isSharing) {
                  setSharePassed({ amount: selectedRows.length });
                }
              })
              .catch((error: any) => {
                projectIds.push(project);
                let projectIdsArray = projectIds?.map((item: any) => item.shortId);
  
                if (isSharing) {
                  setShareFailed({
                    amount: errors++,
                    id: projectIdsArray,
                    message: `Error: ${error.response.data.message}`,
                  });
                }
  
                // Display and error to the user
                if (error.response) {
                  console.error(error.response.data);
                } else {
                  failedNotification(
                    `Failed to share project: ${project.shortId}`
                  );
                }
              }).finally( () => {
                setLoading( false );
              })
          }
          // increment errors otherwise failed projects number is incorrect
          return errors++;
        });
  
        await Promise.allSettled(submittedProjectPromises);
      } catch (error) {
        failedNotification("Failed to share Projects");
      } finally {
        setTimeout(() => {
          setIsSharing(false);
          setSharePassed({});
          setShareFailed({});
          setShareSkipped({});
        }, 5000);
      }
    };


  if (props.selectedRows.filter((e: any) => e.deleted !== true).length === 0) {
    return (
      <Tooltip
        title="Select at least 1 row"
        placement="bottom"
        color={"var(--tertiary-color)"}
      >
        <Button
          className="action-btn edit-btn"
          type="primary"
          icon={<ShareAltOutlined />}
          style={{ marginLeft: "10px" }}
          disabled
        >
          Share
        </Button>
      </Tooltip>
    );
  }

  return (
    <>
      <Button
        onClick={handleClickShare}
        className="action-btn edit-btn"
        icon={<ShareAltOutlined />}
        type="primary"
        style={{ marginLeft: "10px" }}
        loading={loading}
      >
        Share
      </Button>
      <Modal
      mask={false}
      open={visible}
      title="Share"
      okText="OK"
      cancelText="Cancel"
      onCancel={async () => {
        await handleClickFinishSharing(false);
      }}
      onOk={async () => {
        try {
          setConfirmLoading(true);
          await handleClickFinishSharing(true);
          setConfirmLoading(false);
          setProgress(100);
        } catch (error) {
          console.error("Validate Failed:", error);
        }
      }}
      destroyOnClose={true}
      confirmLoading={confirmLoading}
    >
      <Progress percent={progress} style={{ marginBottom: "15px" }} strokeColor={"var(--primary-color)"}  status="active" />

      {initialValues.length !== 0 && (
        <p>
          <strong>{initialValues.length}</strong> project(s) selected to share
        </p>
      )}

      {sharePassed?.amount &&
        progress === 100 &&
        (shareSkipped?.amount ? (
          <p style={{ color: "var(--primary-color)" }}>
            <strong>{sharePassed?.amount - shareSkipped?.amount}</strong> project(s) successfully shared
          </p>
        ) : shareFailed?.amount ? (
          <p style={{ color: "var(--primary-color)" }}>
            <strong>{sharePassed?.amount - shareFailed?.amount}</strong> project(s) successfully shared
          </p>
        ) : (
          ""
        ))}

      {shareSkipped?.id && progress === 100 ? (
        <>
          <p style={{ color: "var(--red)", marginBottom: "5px" }}>
            <strong>{shareSkipped?.amount}</strong> project(s) skipped
          </p>
          <ul style={{ marginLeft: "15px", color: "var(--red)" }}>
            {shareSkipped.id.map((item: any, index: number) => (
              <li key={index}>
                <em>{item}</em>
              </li>
            ))}
          </ul>
        </>
      ) : (
        ""
      )}

      {shareFailed?.id && progress === 100 ? (
        <>
          <p style={{ color: "var(--red)", marginBottom: "5px" }}>
            <strong>{shareFailed?.amount - shareSkipped?.amount}</strong> project(s) failed to share
          </p>
          <ul style={{ marginLeft: "15px", color: "var(--red)" }}>
            {shareFailed?.id?.map((item: any, index: number) => (
              <li key={index}>
                <em>{item}</em>
              </li>
            ))}
          </ul>
        </>
      ) : (
        ""
      )}
    </Modal>
    </>
  );
};

export default ProjectShare;
