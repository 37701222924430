import React from "react";
import {ListProps} from "../../../stories/ListFilter/ListFilter";
import {BooleanFilter} from "../../../Components";

export default (props: ListProps) => {

    return <BooleanFilter
        enabledNotSet={false}
        {...props}
        />
}
