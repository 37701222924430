import React, { useState } from "react";
import { Modal } from "antd";
import { WarningOutlined } from "@ant-design/icons";

const DeleteHelixTemplateMap = ({ visible, selectedTemplateMap, onSubmit }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  return (
    <Modal
      mask={false}
      open={visible}
      title="Proceed and delete the following?"
      okText="Confirm and Delete"
      cancelText="Cancel"
      icon={<WarningOutlined style={{ color: "var(--red)" }} />}
      className="action-btn delete-btn"
      okType="danger"
      onCancel={async () => {
        await onSubmit(false);
      }}
      onOk={async () => {
        try {
          setConfirmLoading(true);
          await onSubmit(true);
          setConfirmLoading(false);
        } catch (error) {
          console.log("Validate Failed:", error);
        }
      }}
      confirmLoading={confirmLoading}
    >
      <p>
        Please confirm that you wish to delete Helix template <br />
        <strong>'{selectedTemplateMap?.helixTemplateObj?.name}'</strong>{" "}
        mapping, this option cannot be undone.
      </p>
    </Modal>
  );
};

export default DeleteHelixTemplateMap;
