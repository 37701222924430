import React from "react";

import { Button, Drawer, Form, Select, Input } from "antd";

const { Option } = Select;

const EditHelixTemplateMap = ({ handlers, values }) => {
  const { closeEditDrawer, onFinishEditing } = handlers;
  const {
    editingForm,
    visibleEditDrawer,
    editing,
    editFormRef,
    helixTemplates,
    components,
    products,
    services,
  } = values;

  const editingRow = editing && editing.editingRow;

  return (
    <>
      <Drawer
        title="Edit Google Helix Template Mapping"
        placement="right"
        onClose={closeEditDrawer}
        open={visibleEditDrawer}
        width={500}
        closable={true}
        className="manage-pages-drawer"
        forceRender
      >
        {editingRow && (
          <Form
            form={editingForm}
            layout="vertical"
            onFinish={onFinishEditing}
            name="editProductForm"
            ref={editFormRef}
            initialValues={{
              modifier: "public",
              servicesValue: editing.editingRow.services,
              productsValue: editing.editingRow.products,
              componentsValue: editing.editingRow.components,
              helixTemplateIdValue: editing.editingRow.helixTemplateId,
              orderValue: editing.editingRow.key,
            }}
          >
            <Form.Item
              name="servicesValue"
              label="Services"
              rules={[{ required: false, message: "Please select a Service" }]}
            >
              <Select
                className="service-select"
                placeholder="Please select a Service"
                dropdownStyle={{
                  textAlign: "center",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "var(--tertiary-color)",
                }}
                popupMatchSelectWidth={false}
                allowClear
                mode="multiple"
              >
                {services.map((service, index) => (
                  <Option key={service._id} value={service.name}>
                    {service.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="productsValue"
              label="Products"
              rules={[{ required: false, message: "Please select a Product" }]}
            >
              <Select
                className="product-select"
                placeholder="Please select a product"
                dropdownStyle={{
                  textAlign: "center",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "var(--tertiary-color)",
                }}
                popupMatchSelectWidth={false}
                allowClear
                mode="multiple"
              >
                {products.map((product, index) => (
                  <Option key={product._id} value={product.title}>
                    {product.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="componentsValue"
              label="Components"
              rules={[
                { required: false, message: "Please select a Component" },
              ]}
            >
              <Select
                className="component-select"
                placeholder="Please select a component"
                dropdownStyle={{
                  textAlign: "center",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "var(--tertiary-color)",
                }}
                popupMatchSelectWidth={false}
                allowClear
                mode="multiple"
              >
                {components.map((component, index) => (
                  <Option key={index} value={component}>
                    {component}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="helixTemplateIdValue"
              label="Helix Template"
              rules={[{ required: true, message: "Please select a template" }]}
            >
              <Select
                className="template-select"
                placeholder="Please select a template"
                dropdownStyle={{
                  textAlign: "center",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "var(--tertiary-color)",
                }}
                popupMatchSelectWidth={false}
                allowClear
              >
                {helixTemplates.map((template, index) => (
                  <Option key={template.helixId} value={template._id}>
                    {template.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="orderValue" noStyle>
              <Input type="hidden" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Mapping
              </Button>
            </Form.Item>
          </Form>
        )}
      </Drawer>
    </>
  );
};

export default EditHelixTemplateMap;
