import React from "react";
import axios from "axios";
import { Button, Tooltip } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import { failedNotification } from "../utils/notifications";
class DownloadReferenceZip extends React.Component {
  state = {
    downloadState: "INACTIVE",
    hasError: false,
    errorMessage: "",
  };

  handleDownload = async () => {
    this.setState({
      downloadState: "DOWNLOADING",
    });

    try {
      let headers = {
        Authorization: `Bearer ${this.props.accessToken}`,
      };

      let response = null;
      let url = `${this.props.baseURI}/projects/${this.props.shortId}/files/download/reference`;

      // POST with the array of files to download
      response = await axios.post(
        url,
        {},
        {
          headers: headers,
        }
      );

      // Get the URL to upload the file to
      let downloadURL = response.data.url;

      // Create a link and click it to download the file
      let downloadLink = document.createElement("a");
      downloadLink.href = downloadURL;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      // There is an error so show a fail
      console.error(error);
      if (error) {
        failedNotification(
          "Download failed",
          "Unable to download the file(s) at this time."
        );
      }
    } finally {
      this.setState({
        downloadState: "INACTIVE",
      });
    }
  };

  render() {
    switch (this.state.downloadState) {
      case "DOWNLOADING":
        return (
          <Button
            icon={<DownloadOutlined className="download-all-icon" />}
            style={{ padding: "0", color: "var(--tertiary-color)", border: "none" }}
            loading
          />
        );
      case "INACTIVE":
      default:
        return (
          <Tooltip
            title="Download all Reference Files"
            color={"var(--tertiary-color)"}
            placement={"left"}
          >
            <Button
              icon={<DownloadOutlined className="download-all-icon" />}
              style={{ border: "none" }}
              onClick={this.handleDownload}
            />
          </Tooltip>
        );
    }
  }
}

export default DownloadReferenceZip;
