import React from "react";
import { Link } from "react-router-dom";

import { Descriptions, Tooltip, Collapse } from "antd";
import { InfoCircleOutlined, LinkOutlined } from "@ant-design/icons";

import TimezoneConverter from "../../../timezone-converter";

const GeneralInformation = ({ data, details }) => {
  const { Panel } = Collapse;
  const {
    product,
    sourceLanguage,
    urgency,
    componentType,
    projectStatus,
    varStatus,
    receivedDate,
    poN,
    invoiceName,
    projectType,
    lqeVideoTotalCharge
  } = data || {};
  const {plps} = details || {};

  const plpData = [];

  plps && plps.map((plp) =>
    plpData.push({
      reviewDateValue: plp.latestReviewDueTimestamp,
      translationDateValue: plp.latestTranslationDueTimestamp
    })
  )

  // get the latest due date from plps
  const plpReviewDate = plpData?.reduce(((a, b) => a.reviewDateValue > b.reviewDateValue ? a : b), []);
  const plpTransDate = plpData?.reduce(((a, b) => a.translationDateValue > b.translationDateValue ? a : b), []);

  return (
    <Collapse defaultActiveKey={["0"]} style={{ marginBottom: "20px" }}>
      <Panel
        header="General Information"
        key="0"
        className="general-information"
      >
        <Descriptions bordered={true} column={2} size={"small"}>
          <Descriptions.Item label="Product">{product}</Descriptions.Item>
          <Descriptions.Item label="Component Type">
            {componentType}
          </Descriptions.Item>
          <Descriptions.Item label="Source Language">
            {sourceLanguage}
          </Descriptions.Item>
          <Descriptions.Item label="Status">{projectStatus}</Descriptions.Item>
          <Descriptions.Item label="Urgency">{urgency}</Descriptions.Item>
          <Descriptions.Item label="Var Status">{varStatus}</Descriptions.Item>
          <Descriptions.Item label="Received Date">
            <TimezoneConverter date={receivedDate} />
          </Descriptions.Item>
          <Descriptions.Item
            label="Project Type"
            style={{ textTransform: "uppercase" }}
          >
            {projectType}
          </Descriptions.Item>
          <Descriptions.Item label="Latest Translation Due Date">
            {plpTransDate.translationDateValue ? (
              <Tooltip
                title="Displays the latest translation due date from all of the PLPs"
                color={"var(--tertiary-color)"}
                placement={"bottom"}
              >
                <TimezoneConverter date={plpTransDate.translationDateValue} />
                <InfoCircleOutlined
                  style={{ color: "var(--tertiary-color)", marginLeft: "3px" }}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title="No translation due date for the PLPs"
                placement="left"
                color="var(--red)"
                overlayInnerStyle={{ width: "245px" }}
              >
                -
              </Tooltip>
            )}
          </Descriptions.Item>
          <Descriptions.Item
            label="Latest Review Due Date"
            style={{ textTransform: "uppercase" }}
          >
            {plpReviewDate.reviewDateValue ? (
              <Tooltip
                title="Displays the latest review due date from all of the PLPs"
                color={"var(--tertiary-color)"}
                placement={"bottom"}
              >
                <TimezoneConverter date={plpReviewDate.reviewDateValue} />
                <InfoCircleOutlined
                  style={{ color: "var(--tertiary-color)", marginLeft: "3px" }}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title="No review due date for the PLPs"
                placement="left"
                color="var(--red)"
                overlayInnerStyle={{ width: "245px" }}
              >
                -
              </Tooltip>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="VD Adj ID">
            VD Adj ID - tbc
          </Descriptions.Item>
          <Descriptions.Item label="Penalty Adj ID">
            Penalty Adj ID - tbc
          </Descriptions.Item>
          <Descriptions.Item label="Admin Adj ID">
            Admin Adj ID - tbc
          </Descriptions.Item>
          <Descriptions.Item label="PO Number">{poN}</Descriptions.Item>
          <Descriptions.Item label="Invoice Number">
            {invoiceName}
          </Descriptions.Item>
          {projectType === "LQE_VIDEO" && (
            <Descriptions.Item label="Total Charge">
              {lqeVideoTotalCharge}
            </Descriptions.Item>
          )}
          <Descriptions.Item
            label={
              <>
                <Link to={`/project/${details?.shortId}?type=projectId`}>
                  <LinkOutlined /> View Project Details
                </Link>
              </>
            }
          ></Descriptions.Item>
        </Descriptions>
      </Panel>
    </Collapse>
  );
};

export default GeneralInformation;
