import React, { useState, useEffect, useRef, useCallback } from "react";
import { useMercuryContext } from "../user-context";
import { Helmet } from "react-helmet";
import { Form } from "antd";

import { sorter } from "../utils/helpers";

import PageTitle from "../ui/PageTitle";
import BackTopButton from "../ui/BackTopButton";

import EditRole from "../components/managePages/roles/EditRole";
import RolesTable from "../components/managePages/roles/RolesTable";
import AddRole from "../components/managePages/roles/AddRole";

import {
  failedNotification,
  successfulNotification,
} from "../utils/notifications";

import "../components/managePages/roles/manage-roles.scss";

const initialEditingValue = { isEditing: false, editingRow: "" };

const ManageRoles = () => {
  const { umsClient: Client, client:mcasClient, flags } = useMercuryContext(),
    [isLoading, setIsLoading] = useState(false),
    editFormRef = useRef(null),
    [editingForm] = Form.useForm(),
    [newRoleForm] = Form.useForm(),
    [visibleEditDrawer, setVisibleEditDrawer] = useState(false),
    [visibleNewRoleDrawer, setVisibleNewRoleDrawer] = useState(false),
    [roles, setRoles] = useState(null),
    [sortedInfo, setSortedInfo] = useState(""),
    [editing, setEditing] = useState(initialEditingValue),
    [total, setTotal] = useState(0),
    [permissionsList, setPermissionsList] = useState([]),
    [applicationsList, setApplicationsList] = useState([]); // customer application list

  const defaultValues = {
    nameValue: editing.editingRow.name,
    descriptionValue: editing.editingRow.description,
    customerApplicationValue: editing.editingRow.customerApplication,
    isActiveValue: editing.editingRow.isActive,
  };

  // fix to update initial values of edit user form
  useEffect(() => {
    if (editFormRef.current) {
      editingForm.setFieldsValue(defaultValues);
    }
  }, [editFormRef, editingForm, defaultValues]);

  // fetch all roles
  const fetchRolesList = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await Client.get( `/core-ums-v2/roles` );
      // fix to add unique key
      const dataWithKey = data.map((item) => {
        return {
          ...item,
          key: item.id,
        };
      });
      setRoles(dataWithKey.sort((a, b) => sorter(a.name, b.name)));
      setTotal(data.length);
    } catch (error) {
      console.error('Error fetching roles list: ', error);
    }
    setIsLoading(false);
  }, [Client, flags.umsVersion]);

  // Edit role
  const updateRole = async (newData, id) => {
    try {
      setEditing({ ...editing, isEditing: true });
      const res = await Client.patch( `/core-ums-v2/roles/${id}`, newData );

      if (res instanceof Error) {
        if (res.message === "Request failed with status code 422") {
          failedNotification("Error", res?.response?.data?.message);
        } else {
          failedNotification(res.name, res.message);
        }
      } else {
        successfulNotification(
          `Successfully submitted`,
          `Role (${res.data.name}) was updated.`
        );
      }
    } catch (error) {
      console.error(error);
      failedNotification(error.response.data.error, error.response.data.detail);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // Create role
  const addNewRole = async (body) => {
    try {
      setEditing({ ...editing, isEditing: true });
      const res = await Client.post( `/core-ums-v2/roles`, body );

      if (res instanceof Error) {
        if (res.message === "Request failed with status code 422") {
          failedNotification(
            "Error",
            res?.response?.data?.details?.name?.message
          );
        } else {
          failedNotification(res.name, res.message);
        }
      } else {
        const { data } = res;
        successfulNotification(
          `Role - ${data.name} was added.`,
          "Please refresh the page if the new role is not visible."
        );
      }
    } catch (error) {
      console.error(error.response);
      console.error(error);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // fetch all permissions
  const fetchPermissionsList = useCallback(async () => {
    try {
      const { data } = await Client.get( `/core-ums-v2/permissions` );
      // fix to add unique key
      const dataWithKey = data.map((item) => {
        return {
          ...item,
          key: item.id,
        };
      });
      setPermissionsList(dataWithKey);
    } catch (error) {
      console.error('Error fetching permissions list: ', error);
    }
  }, [Client, flags.umsVersion]);

  // fetch all permissions
  const fetchApplicationsList = useCallback(async () => {
    try {
      const { data } = await mcasClient.get("core-mcas-v1");

      setApplicationsList(data);
    } catch (error) {
      console.error('Error fetching applications list: ', error);
    }
  }, [mcasClient]);

  useEffect(() => {
    fetchRolesList();
  }, [fetchRolesList]);

  useEffect(() => {
    if (!permissionsList?.length) {
      fetchPermissionsList();
    }
    if (!applicationsList?.length) {
      fetchApplicationsList();
    }
  }, [
    fetchPermissionsList,
    permissionsList,
    fetchApplicationsList,
    applicationsList,
  ]);

  // refresh button
  const handleRefresh = () => {
    fetchRolesList();
  };

  // Update role START
  function showEditDrawer() {
    setVisibleEditDrawer(true);
  }

  const closeEditDrawer = (event) => {
    setVisibleEditDrawer(false);
    editingForm.resetFields();
    event !== undefined && setEditing(initialEditingValue);
  };

  // on save of edit role
  const onFinishEditing = (values, id, permissionsValue) => {
    const newData = {
      name: values.nameValue,
      description: values.descriptionValue,
      customerApplication: values.customerApplicationValue,
      isActive: values.isActiveValue,
      permissions: permissionsValue,
    };

    updateRole(newData, id);
    closeEditDrawer();
  };
  // Update role END

  // handle change table
  const handleChangeTable = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
  };

  // handle click table edit button
  const handleClickEdit = (record) => {
    setEditing({ ...editing, isEditing: true, editingRow: record });
    showEditDrawer();
  };

  // Add new Role START
  // Add new cost code START
  const showNewRoleDrawer = () => {
    setVisibleNewRoleDrawer(true);
  };

  const closeNewRoleDrawer = () => {
    setVisibleNewRoleDrawer(false);
    newRoleForm.resetFields();
  };

  // on save of add new role
  const onFinishNewRole = (values, permissions) => {
    const newRoleValues = {
      name: values.nameValue,
      description: values.descriptionValue,
      customerApplication: values.customerApplicationValue,
      isActive: values.activeValue,
      permissions: permissions,
    };

    addNewRole(newRoleValues);
    closeNewRoleDrawer();
  };

  // Add new Role END

  return (
    <div className="manage-page manage-roles-page">
      <Helmet>
        <title>Manage Roles - Mercury © RWS</title>
      </Helmet>

      <PageTitle title="Manage Roles" />
      <AddRole
        handlers={{
          showNewRoleDrawer,
          closeNewRoleDrawer,
          onFinishNewRole,
          handleRefresh,
        }}
        values={{
          newRoleForm,
          visibleNewRoleDrawer,
          permissionsList,
          applicationsList,
        }}
      />
      <EditRole
        handlers={{
          closeEditDrawer,
          onFinishEditing,
        }}
        values={{
          editingForm,
          visibleEditDrawer,
          editing,
          editFormRef,
          permissionsList,
          applicationsList,
        }}
      />
      <RolesTable
        values={{ roles, sortedInfo, editing, total, isLoading,applicationsList }}
        handlers={{
          handleChangeTable,
          handleClickEdit,
        }}
      />
      <BackTopButton />
    </div>
  );
};

export default ManageRoles;
