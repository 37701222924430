import React from "react";
import {
    TableAbstract,
    CustomTooltip,
    DateFilter,
    BooleanFilter,
    ListFilter
} from "../../Components";
import {useMercuryContext} from "../../user-context";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { Checkbox, Tooltip } from "antd"

import TimezoneConverter from "../../timezone-converter";
import { BlockOutlined } from "@ant-design/icons";
import JustificationFilter from "./Justification.Filter";
import StatusFilter from "./Status.filter";
import ProductAreaFilter from "../../Search/ProductArea.filter";
import ProductFilter from "../../Search/Product.filter";

import { Link } from 'react-router-dom';

import './ExtensionsRequests.scss'
import ListExtModal from "./ListExtModal";
import { BulkModal } from "../../components/extensions/components/BulkModal/BulkModal";

class ExtensionsRequests extends TableAbstract<any> {
    defaultSortField = "name";
    FilterComponents = [
        {
            id: "requestedDueDate",
            title: "Requested Due Date (to-from)",
            name: "requestedDueDate",
            component: DateFilter,
            active: true
        },
        {
            id: "createdAt",
            title: "Created date (to-from)",
            name: "createdAt",
            component: DateFilter,
            active: true
        },
        {
            id: "justification",
            title: "Justification",
            name: "justification",
            component: JustificationFilter,
            active: true
        },
        {
            id: "bulk",
            name: "bulk",
            title: "Bulk Request",
            component: BooleanFilter,
            active: true
        },
        {
            id: "shortId",
            name: "shortId",
            title: "Project IDS",
            component: ListFilter,
            active: true
        },
        {
            id: "status",
            name: "status",
            title: "Status",
            component: StatusFilter,
            active: true
        },
        {
            id: "productArea",
            name: "productArea",
            title: "Product Area",
            component: ProductAreaFilter,
            active: true
        },
        {
            id: "product",
            name: "product",
            title: "Product",
            component: ProductFilter,
            active: true
        },
  ];
    columns = [
        {
            id: 'projectId',
            title: 'Project ID',
            dataIndex: 'projectId',
            sorter: true,
            width: 200,
            render: (text:string, record:any) => {
              return (
                <>
                  <a
                    href={`/project/${record.projectId}/?type=projectId`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BlockOutlined className="project-link-icon" />
                  </a>
                  <Link
                    to={`/project/${record.projectId}?type=projectId`}
                    id={record.projectId}
                    className={'project-link'}
                  >
                    {record.projectId}
                  </Link>
                </>
              );
            },
        },
        {
            id: "sourceLanguageCode",
            title: <Tooltip title="Source Language" placement="left" color={"var(--tertiary-color)"}>Source Language</Tooltip> as any,
            dataIndex: "sourceLanguageCode",
            width: 150,
            sorter: true,
            fixed: undefined,
        },
        {
            id: "targetLanguageCode",
            title: <Tooltip title="Target Language" placement="left" color={"var(--tertiary-color)"}>Target Language</Tooltip> as any,
            dataIndex: "targetLanguageCode",
            width: 150,
            sorter: true,
            fixed: undefined,
        },
        {
            id: "originalDueDate",
            title: 'Original Due Date',
            dataIndex: 'originalDueDate',
            width: 180,
            sorter: true,
            fixed: undefined,
            render: (text:string, record:any) => <TimezoneConverter date={record.originalDueDate} format={'DD MMM YYYY'} />
        },
        {
            id: "requestedDueDate",
            title: 'Requested Due Date',
            dataIndex: 'requestedDueDate',
            width: 180,
            sorter: true,
            fixed: undefined,
            render: (text:string, record:any) => <TimezoneConverter date={record.requestedDueDate} format={'DD MMM YYYY'} />
        },
        {
            id: "status",
            title: "Status",
            dataIndex: "status",
            sorter: true,
            width: 150,
            fixed: undefined
        },
        {
            id: "justification",
            title: "Justification",
            dataIndex: "justification",
            sorter: true,
            width: 400,
            fixed: undefined,
            render: (text: string) => <CustomTooltip text={text} length={80}/>,
        },
        {
            id: "urgency",
            title: "Urgency",
            dataIndex: "urgency",
            sorter: true,
            width: 180,
            fixed: undefined
        },
        {
            id: "details",
            title: 'Details',
            dataIndex: 'details',
            sorter: false,
            width: 120,
            fixed: undefined, 
            render: (text:string, record:any) => <ListExtModal requestId={record._id}/>
        },
        {
            id: 'bulk',
            title: 'Bulk',
            dataIndex: 'bulk',
            sorter: true,
            width: 80,
            render: (text:string, record:any) => {
              if (record.bulk) {
                return <Checkbox defaultChecked disabled />;
              } else {
                return <Checkbox defaultChecked={false} disabled />;
              }
            },
          },
    ];

    renderActions() {
        const { userDetails } = this.userContext;

        const apis = {
            projectEndpoint: `${process.env.REACT_APP_API_URL}/google-gp-v1/project/shortId/`,
            productTypeEndpoint: `${process.env.REACT_APP_API_URL}/core-gpms-v1/product`,
            productAreaEndpoint: `${process.env.REACT_APP_API_URL}/core-gpms-v1/productarea`,
          };

      return <>
            {/* Still using old js file, to be refactored once we move all validation on backend */}
            <BulkModal apis={apis} userDetails={userDetails} />
      </>
    }

    rowClassName(record: any, index: number): string {
        return 'rateRow'
    }

    async fetch() {
        const { sortOrder, sortField, currentPage, itemsPerPage } = this.state;

        const res = await this.userContext.client.post(`/google-ges-v1/search`, {
            filter: this.filtersToQuery(),
            // filter: this.filters,
            sort: {
                [sortField||"requestedDueDate"]: sortOrder === "ascend" ? 1 : -1
            },
            pagination: {
                pageNumber: currentPage || 1,
                resultsPerPage: itemsPerPage
            }
        });

        return {data:res.data} as any;
    }

    pageTitle = "Extensions Requests - Mercury";
    title = "Extensions Requests";
    pageClass = "extensionsTable"
}

export default function Extension() {
    const
        context = useMercuryContext(),
        breaks = useBreakpoint();

    return (<ExtensionsRequests id={"extensionsRequests"} noSelect={true} context={context as any} useLegacyEndpoints={false} breaks={breaks as any}/>);
}