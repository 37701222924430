import { useCallback, useState } from "react";
import { Button, Modal, Table, Space, Tooltip } from "antd";
import { useMercuryContext } from "../../user-context";

import "./DeletePLPButton.scss"

import type { TableProps } from "antd";
interface DataType {
  key: string;
  projectId: string;
  targetLang: number;
  adjustment: any;
}

export default function DeletePLPButton({ selectedRows, batchId, reload, show, handleClose, handleShow }: any) {

  const context = useMercuryContext(),
  { gpSocket } = context,
    [loading, setLoading] = useState<boolean>(false),
    [disabled, setDisabled] = useState<boolean>(false),
    [error, setError] = useState<any>(null);

    const deletePLPs = useCallback(() => {
      setDisabled(true);
      setLoading(true);
      setError(null);

      const socket = gpSocket.adj;
        socket.emit( "deleteAdjustmentsPlps", { ids: selectedRows.map((row:any) => row.plp._id), batchId: batchId }, ( cb: any ) => {
          if ( cb ) {
            reload();
            console.info( `deleteAdjustmentsPlps callback: ${JSON.stringify( cb, null, 4 )}` )
          }
        })
      setDisabled(false);
      setLoading(false);
    }, [selectedRows, setLoading, gpSocket, reload, show, handleClose, handleShow]);

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Project ID",
      dataIndex: "projectShortId",
      key: "projectShortId",
    },
    {
      title: "Target Language",
      dataIndex: "targetLanguageCode",
      key: "targetLanguageCode",
    },
    {
      title: "Adjustment",
      dataIndex: "adjustment",
      key: "adjustment",
    },
  ];

  return (
    <>
      <Modal
        forceRender
        destroyOnClose={true}
        title="Delete PLP"
        width={500}
        className="delete-plps-modal"
        open={selectedRows?.length === 0 ? false : show}
        onCancel={handleClose}
        zIndex={1000}
        footer={[
          <Space
            style={{ display: "flex", justifyContent: "flex-end" }}
            key="modal-footer"
          >
            <Button
              key="cancel"
              type="default"
              disabled={disabled}
              onClick={() => {
                handleClose()
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                deletePLPs();
                reload();
              }}
              disabled={disabled}
              loading={loading}
              type="primary"
              danger={true}
            >
              Delete
            </Button>
          </Space>,
        ]}
      >
        <div className={"error"}>{error}</div>
        {`Are you sure you wish to delete the following ${
          selectedRows.length === 1 ? "PLP?" : "PLPs?"
        }`} <br /><span style={{ color: "var(--red)" }}>This action is irreversible</span>
        <Table
          columns={columns}
          dataSource={selectedRows}
          size="small"
          className="delete-plps-table"
          style={{ marginTop: "10px" }}
          pagination={false}
        />
      </Modal>
      <Tooltip
        title={selectedRows.length === 0 ? "Select at least 1 item" : ""}
        placement="bottom"
        color={"var(--tertiary-color)"}
      >
        <Button
          danger={true}
          onClick={() => {
            handleShow()
          }}
          disabled={selectedRows.length === 0}
          type={"primary"}
        >
          Delete {selectedRows.length === 1 ? "PLP" : "PLPs"}
        </Button>
      </Tooltip>
    </>
  );
}
