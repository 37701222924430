import React, {useState, useEffect} from "react";
import Search from "antd/es/input/Search";
import {
    Button,
    Popover,
    DatePicker,
    Tabs,
    Radio,
    Select,
    Col,
    Row,
} from "antd";
import {DateTime} from "luxon";
import {DownOutlined} from "@ant-design/icons";

import type { TabsProps } from 'antd';
import dayjs from 'dayjs'

import "./datefilter.scss";

export interface DateFilterProps {
    onChange?: any;
    name?: string;
    value?: DateFilterOutput;
    ranges?: any;
    title: string;
    placeholder?: string;
}

// Desired outputs

export type DateFilterRelative = [
    number,
        "seconds" | "minutes" | "hours" | "days" | "weeks" | "months" | "years" | "quarters",
];
export type DateFilterOutput = Date[] | DateFilterRelative | any;

interface DateFilterValue {
    type: "absolute" | "relative";
    unit: string;
    units: number|string[];
}

/**
 * Life cycle
 * initial props => state
 * editing => state
 * onchange => update props via controlling ( parent state )
 * new props
 */

const timeUnits = [
    {
        id: 1,
        name: "Year(s)",
        value: "year",
    },
    {
        id: 2,
        name: "Quarter",
        value: "qtr",
    },
    {
        id: 3,
        name: "Month(s)",
        value: "month",
    },
    {
        id: 4,
        name: "Day(s)",
        value: "day",
    },
    {
        id: 5,
        name: "Hours(s)",
        value: "hour",
    },
    {
        id: 6,
        name: "Minutes(s)",
        value: "minute",
    },
];

const yearFormat = (year: any) => DateTime.fromISO(year).toFormat("yyyy");
const quarterFormat = (quarter: any) => DateTime.fromISO(quarter).toFormat("yyyy-Qq");
const monthFormat = (month: any) => DateTime.fromISO(month).toFormat("MM-yyyy");
const dateFormat = (month: any) => DateTime.fromISO(month).toFormat("dd-MM-yyyy");
// const dateParsed = (p: any) => new Date(Date.parse(p))


export class DateFilterObj {

    static isValid(obj: any) {
        if ( !obj ) {
            return false;
        }

        if (typeof obj !== "object" || Array.isArray(obj)) {
            return false;
        }

        const keys = new Set(Object.keys(obj));

        return keys.has("type") && keys.has("unit") && keys.has("units");
    }

    static fromJSON(obj: DateFilterValue) {
        return new DateFilterObj(obj)
    }

    private type = "absolute";
    private units:number|string[] = 1;
    private unit = "day"

    constructor(obj?: DateFilterValue) {
        if (obj) {
            this.type = obj.type;
            this.unit = obj.unit;
            this.units = obj.units;
        }
    }

    public toJSON() {
        const {unit, units, type} = this;
        return {unit, units, type}
    }

    public toDateRange() {
        switch (this.type) {
            case "relative":
                switch (this.unit) {
                    case "minute":
                        return this.getRelativeMinute()
                    case "hour":
                        return this.getRelativehour()
                    case "day":
                        return this.getRelativeDay()
                    case "qtr":
                        return this.getRelativeQuater()
                    case "year":
                        return this.getRelativeYear()
                    case "month":
                        return this.getRelativeMonth()
                }
                break;
            default:
                return this.units
        }
    }

    getRelativeYear() {

        const now = DateTime.now();

        if (this.units === 0) {
            return [now.startOf("year").toISO(), now.endOf("year").toISO()]
        }
        if (Number(this.units) > 0) {
            return [now.plus({year: 1}).startOf("year").toISO(), now.plus({year: this.units as number}).endOf("year").toISO()]
        }

        if (Number(this.units) < 0) {
            return [now.minus({year: Math.abs(this.units as number)}).startOf("year").toISO(), now.minus({year: 1}).endOf("year").toISO()]
        }
    }

    getRelativeMinute() {
        const now = DateTime.now();

        if (this.units === 0) {
            return [now.startOf("minute").toISO(), now.endOf("minute").toISO()]
        }
        if (Number(this.units) > 0) {
            return [now.plus({minute: 1}).startOf("minute").toISO(), now.plus({minute: this.units as number}).endOf("minute").toISO()]
        }

        if (Number(this.units) < 0) {
            return [now.minus({minute: Math.abs(this.units as number)}).startOf("minute").toISO(), now.minus({minute: 1}).endOf("minute").toISO()]
        }
    }

    getRelativehour() {
        const now = DateTime.now();

        if (this.units === 0) {
            return [now.startOf("hour").toISO(), now.endOf("hour").toISO()]
        }
        if (Number(this.units) > 0) {
            return [now.plus({hour: 1}).startOf("hour").toISO(), now.plus({hour: this.units as number}).endOf("hour").toISO()]
        }

        if (Number(this.units) < 0) {
            return [now.minus({hour: Math.abs(this.units as number)}).startOf("hour").toISO(), now.minus({hour: 1}).endOf("hour").toISO()]
        }
    }

    getRelativeQuater() {
        const now = DateTime.now();

        if (this.units === 0) {
            return [now.startOf("quarter").toISO(), now.endOf("quarter").toISO()]
        }
        if (Number(this.units) > 0) {
            return [now.plus({quarter: 1}).startOf("quarter").toISO(), now.plus({quarter: this.units as number}).endOf("quarter").toISO()]
        }

        if (Number(this.units) < 0) {
            return [now.minus({quarter: Math.abs(this.units as number)}).startOf("quarter").toISO(), now.minus({quarter: 1}).endOf("quarter").toISO()]
        }
    }

    getRelativeDay() {
        const now = DateTime.now();

        if (this.units === 0) {
            return [now.startOf("day").toISO(), now.endOf("day").toISO()]
        }
        if (Number(this.units) > 0) {
            return [now.plus({day: 1}).startOf("day").toISO(), now.plus({day: this.units as number}).endOf("day").toISO()]
        }

        if (Number(this.units) < 0) {
            return [now.minus({day: Math.abs(this.units as number)}).startOf("day").toISO(), now.minus({day: 1}).endOf("day").toISO()]
        }
    }

    getRelativeMonth() {
        const now = DateTime.now();

        if (this.units === 0) {
            return [now.startOf("month").toISO(), now.endOf("month").toISO()]
        }
        if (Number(this.units) > 0) {
            return [now.plus({month: 1}).startOf("month").toISO(), now.plus({month: this.units as number}).endOf("month").toISO()]
        }

        if (Number(this.units) < 0) {
            return [now.minus({month: Math.abs(this.units as number)}).startOf("month").toISO(), now.minus({month: 1}).endOf("month").toISO()]
        }
    }

    static getDateRange(obj: any) {
        if (DateFilterObj.isValid(obj)) {
            const instance = new DateFilterObj(obj);
            return instance.toDateRange();
        }
        return obj;
    }

    static displayValue(obj: DateFilterValue, placeholder?: string) {
        if (!DateFilterObj.isValid(obj)) {
            return placeholder;
        }
        switch (obj.type) {
            case "relative":
                if (obj.units === -1) {
                    if (obj.unit === "day") {
                        return "Yesterday"
                    }

                    return `Last ${obj.unit}`
                }

                if (obj.units === 1) {
                    if (obj.unit === "day") {
                        return "Tomorrow"
                    }

                    return `Next ${obj.unit}`
                }

                if (obj.units === 0) {
                    if (obj.unit === "day") {
                        return "Today"
                    }

                    return `This ${obj.unit}`

                }

                if (Number(obj.units) > 0) {
                    return `Next ${Math.abs(obj.units as number)} ${obj.unit}s`
                }
                if (Number(obj.units) < 0) {
                    return `Last ${Math.abs(obj.units as number)} ${obj.unit}s`
                }
                return `This ${obj.unit}`;
            case "absolute":

                const
                    range = obj.units as string[];

                let formatter = yearFormat

                switch( obj.unit ) {
                    case "year":
                        formatter = yearFormat
                        break;
                    case "qtr":
                        formatter = quarterFormat
                        break;
                    case "month":
                        formatter = monthFormat
                        break;
                    case "day":
                        formatter = dateFormat
                        break;
                }
                const [ formatStart, formatEnd ] = range.map( d => formatter( d ))
                if ( formatStart === formatEnd ) {
                    return formatStart
                }
                return `${formatStart} ➡ ${formatEnd}`
        }
    }

}

export const DateFilter = ({
       title,
       onChange,
       value,
       name,
       ranges,
       placeholder = "Select",
   }: DateFilterProps) => {

    const
        {RangePicker} = DatePicker;

    const
        [open, setOpen] = useState(false),
        [radioValue, setRadioValue] = useState<any>(),
        [searchValue, setSearchValue] = useState<any>(value),
        [tabKey, setTabKey] = useState<any>("2"),
        [lastNext, setLastNext] = useState<"last" | "next" | undefined>(undefined),
        [stateValue, setStateValue] = useState<DateFilterValue | undefined>(undefined),
        [displayedText, setDisplayedText] = useState<any>(DateFilterObj.displayValue(value ? value: {}, placeholder));

    const handleTabKey = (key: any) => {
        setTabKey(key);
    };

    function handleChangeRadio(e: any) {
        setRadioValue( e.target.value )
    }

    function handleAbsoluteDateChange(e:any[]) {
        const
            [ start, end ] = e.map( d => d.toISOString() );

        // default to day
        let startDate = DateTime.fromISO( start ).startOf( "day" ).toISO();
        let endDate = DateTime.fromISO( end ).endOf( "day" ).toISO();

        switch (radioValue) {
            case 'month':
                startDate = DateTime.fromISO( start ).startOf( "month" ).startOf( "day" ).toISO();
                endDate = DateTime.fromISO( end ).endOf( "month" ).endOf( "day" ).toISO();
                break;
            case 'qtr':
                startDate = DateTime.fromISO( start ).startOf( "quarter" ).startOf( "day" ).toISO();
                endDate = DateTime.fromISO( end ).endOf( "quarter" ).endOf( "day" ).toISO();
                break;
            case 'year':
                startDate = DateTime.fromISO( start ).startOf( "year" ).startOf( "month" ).startOf( "day" ).toISO();
                endDate = DateTime.fromISO( end ).endOf( "year" ).endOf( "month" ).endOf( "day" ).toISO();
                break;
            default:
                break;
        }

        const updated = {
                type: "absolute",
                unit: radioValue,
                units: [startDate, endDate]
            }

        setStateValue(updated as DateFilterValue)
    }

    function handlePopoverVisibleChange() {
        setOpen((prev) => !prev);
    }

    function handleClear(e: any) {
        onChange({
            target: {
                name,
                value: null
            }
        })
        setStateValue( undefined )
        setLastNext(undefined)
        setSearchValue(value)
        setOpen((prev) => !prev);
    }

    function handleSearch(e?: any) {
        if ( !stateValue ) {
            return;
        }

        setOpen((prev) => !prev);

        if ( stateValue.type === "relative" && lastNext === "last" ) {
            stateValue.units = -Math.abs( stateValue.units as number )

            onChange({
                target: {
                    name,
                    value:stateValue
                }
            })
            return;
        }

        onChange({
            target: {
                name,
                value:stateValue
            }
        })

    }

    function btnActive(unit: string, units: number) {
        if (stateValue && stateValue.type === "relative") {
            return stateValue.units === units && stateValue.unit === unit;
        }
        return false
    }

    function search(obj: DateFilterValue) {

        setOpen((prev) => !prev);
        setStateValue(obj)

        onChange({
            target: {
                name,
                value: obj
            }
        });
    }

    function AbsoluteChoice() {

        if ( radioValue ) {
            return radioValue;
        }

        if (!stateValue || stateValue.type === "relative") {
            return "day"
        }
        if ( ["year", "month", "qtr","day"].indexOf(stateValue.unit) === -1 ) {
            return "day"
        }

        return stateValue.unit;
    }

    function AbsoluteValue( unit:string ):any {
        if ( !stateValue || stateValue.type !== "absolute" ) {
            return undefined
        }
        if ( unit !== stateValue.unit ) {
            return undefined
        }
        return Array.isArray( stateValue.units ) ? stateValue.units.map( d => dayjs( d ) ) : undefined
    }

    useEffect(() => {
        setDisplayedText(DateFilterObj.displayValue(value, placeholder));
        if ( !value ) {
            setRadioValue( "day")
            return;
        }

        if ( value.type === "absolute" ) {
            setRadioValue( value.unit );
        }

        setSearchValue( Math.abs( value.units ))
        setStateValue( value )
    }, [value, placeholder])

    useEffect(() => {
        if (!stateValue || stateValue.type !== "relative") {
            // setLastNext(undefined)
            setTabKey("1");
            return;
        }

        setTabKey("2");

    }, [stateValue])

    useEffect(() => {
        if (stateValue && stateValue.type === "relative") {
            if (searchValue !== '') {
                if (Number(stateValue.units) < 0) {
                    setLastNext("last")
                } else {
                    setLastNext("next")
                }
            }
        }
    }, [stateValue, searchValue])

    const tabItems: TabsProps['items'] = [
        {
          key: '1',
          label: 'Absolute',
          children: <Radio.Group
                onChange={handleChangeRadio}
                value={AbsoluteChoice()}
                className="options-wrapper"
                >
            <div className="option option__1">
                <Radio value={"year"}>Year</Radio>
                <RangePicker
                    presets={ranges}
                    // @ts-ignore
                    onChange={handleAbsoluteDateChange}
                    value={AbsoluteValue("year") as any}
                    defaultValue={null}
                    format={"YYYY"}
                    picker="year"
                    disabled={radioValue !== "year"}
                />
            </div>
            <div className="option option__2">
                <Radio value={"qtr"}>Quarter</Radio>
                <RangePicker
                    presets={ranges}
                    // @ts-ignore
                    onChange={handleAbsoluteDateChange}
                    value={AbsoluteValue("qtr") as any}
                    defaultValue={null}
                    picker="quarter"
                    disabled={radioValue !== "qtr"}
                />
            </div>
            <div className="option option__3">
                <Radio value={"month"}>Month</Radio>
                <RangePicker
                    presets={ranges}
                    // @ts-ignore
                    onChange={handleAbsoluteDateChange}
                    value={AbsoluteValue("month") as any}
                    format={"MM-YYYY"}
                    picker="month"
                    disabled={radioValue !== "month"}
                />
            </div>
            <div className="option option__4">
                <Radio value={"day"}>Date</Radio>
                <RangePicker
                    presets={ranges}
                    // @ts-ignore
                    onChange={handleAbsoluteDateChange}
                    value={AbsoluteValue("day") as any}
                    format={"DD-MM-YYYY"}
                    disabled={radioValue !== "day"}
                />
            </div>
        </Radio.Group>,
        },
        {
          key: '2',
          label: 'Relative',
          children: <><Row gutter={[8, 8]} className="relative-dates">
          <Col span={5}>
              <span>Year</span>
          </Col>
          <Col span={"19"}>
              <Row gutter={[8, 0]}>
                  <Col span={8}>
                      <Button
                          onClick={() => search({
                              type: "relative",
                              unit: "year",
                              units: -1
                          })}
                          type={btnActive("year", -1) ? "primary" : undefined}
                          block={true}
                      >Last</Button>
                  </Col>
                  <Col span={8}>
                      <Button
                          onClick={() => search({
                              type: "relative",
                              unit: "year",
                              units: 0
                          })}
                          type={btnActive("year", 0) ? "primary" : undefined}
                          block={true}
                      >This</Button>
                  </Col>
                  <Col span={8}>
                      <Button
                          onClick={() => search({
                              type: "relative",
                              unit: "year",
                              units: 1
                          })}
                          type={btnActive("year", 1) ? "primary" : undefined}
                          block={true}
                      >Next</Button>
                  </Col>
              </Row>
          </Col>
      </Row>

      <Row gutter={[8, 8]} className="relative-dates">
          <Col span={5}>
              <span>Quarter</span>
          </Col>
          <Col span={"19"}>
              <Row gutter={[8, 0]}>
                  <Col span={8}>
                      <Button
                          // disabled={(radioValue !== "relativeYear" || timeRange === "this") && true}
                          onClick={() => search({
                              type: "relative",
                              unit: "qtr",
                              units: -1
                          })}
                          type={btnActive("qtr", -1) ? "primary" : undefined}
                          block={true}
                      >Last</Button>
                  </Col>
                  <Col span={8}>
                      <Button
                          // disabled={(radioValue !== "relativeYear" || timeRange === "this") && true}
                          onClick={() => search({
                              type: "relative",
                              unit: "qtr",
                              units: 0
                          })}
                          type={btnActive("qtr", 0) ? "primary" : undefined}
                          block={true}
                      >This</Button>
                  </Col>
                  <Col span={8}>
                      <Button
                          // disabled={(radioValue !== "relativeYear" || timeRange === "this") && true}
                          onClick={() => search({
                              type: "relative",
                              unit: "qtr",
                              units: 1
                          })}
                          type={btnActive("qtr", 1) ? "primary" : undefined}
                          block={true}
                      >Next</Button>
                  </Col>
              </Row>
          </Col>
      </Row>

      <Row gutter={[8, 8]} className="relative-dates">
          <Col span={5}>
              <span>Month</span>
          </Col>
          <Col span={"19"}>
              <Row gutter={[8, 0]}>
                  <Col span={8}>
                      <Button
                          // disabled={(radioValue !== "relativeYear" || timeRange === "this") && true}
                          onClick={() => search({
                              type: "relative",
                              unit: "month",
                              units: -1
                          })}
                          type={btnActive("month", -1) ? "primary" : undefined}
                          block={true}
                      >Last</Button>
                  </Col>
                  <Col span={8}>
                      <Button
                          // disabled={(radioValue !== "relativeYear" || timeRange === "this") && true}
                          onClick={() => search({
                              type: "relative",
                              unit: "month",
                              units: 0
                          })}
                          type={btnActive("month", 0) ? "primary" : undefined}
                          block={true}
                      >This</Button>
                  </Col>
                  <Col span={8}>
                      <Button
                          // disabled={(radioValue !== "relativeYear" || timeRange === "this") && true}
                          onClick={() => search({
                              type: "relative",
                              unit: "month",
                              units: 1
                          })}
                          type={btnActive("month", 1) ? "primary" : undefined}
                          block={true}
                      >Next</Button>
                  </Col>
              </Row>
          </Col>
      </Row>

      <Row gutter={[8, 8]} className="relative-dates">
          <Col span={5}>
              <span>Day</span>
          </Col>
          <Col span={"19"}>
              <Row gutter={[8, 0]}>
                  <Col span={8}>
                      <Button
                          // disabled={(radioValue !== "relativeYear" || timeRange === "this") && true}
                          onClick={() => search({
                              type: "relative",
                              unit: "day",
                              units: -1
                          })}
                          type={btnActive("day", -1) ? "primary" : undefined}
                          block={true}
                      >Yesterday</Button>
                  </Col>
                  <Col span={8}>
                      <Button
                          // disabled={(radioValue !== "relativeYear" || timeRange === "this") && true}
                          onClick={() => search({
                              type: "relative",
                              unit: "day",
                              units: 0
                          })}
                          type={btnActive("day", 0) ? "primary" : undefined}
                          block={true}
                      >Today</Button>
                  </Col>
                  <Col span={8}>
                      <Button
                          onClick={() => search({
                              type: "relative",
                              unit: "day",
                              units: 1
                          })}
                          type={btnActive("day", 1) ? "primary" : undefined}
                          block={true}
                      >Tomorrow</Button>
                  </Col>
              </Row>
          </Col>
      </Row>

      <Row gutter={[8, 8]} className="relative-dates">
          <Col span={5}>
              <span>Hour</span>
          </Col>
          <Col span={"19"}>
              <Row gutter={[8, 0]}>
                  <Col span={6}>
                      <Button
                          onClick={() => search({
                              type: "relative",
                              unit: "hour",
                              units: -12
                          })}
                          type={btnActive("hour", -12) ? "primary" : undefined}
                          block={true}
                      >Last 12</Button>
                  </Col>
                  <Col span={6}>
                      <Button
                          onClick={() => search({
                              type: "relative",
                              unit: "hour",
                              units: -1
                          })}
                          type={btnActive("hour", -1) ? "primary" : undefined}
                          block={true}
                      >Last 1</Button>
                  </Col>
                  <Col span={6}>
                      <Button
                          onClick={() => search({
                              type: "relative",
                              unit: "hour",
                              units: 1
                          })}
                          type={btnActive("hour", 1) ? "primary" : undefined}
                          block={true}
                      >Next 1</Button>
                  </Col>
                  <Col span={6}>
                      <Button
                          onClick={() => search({
                              type: "relative",
                              unit: "hour",
                              units: 12
                          })}
                          type={btnActive("hour", 12) ? "primary" : undefined}
                          block={true}
                      >Next 12</Button>
                  </Col>
              </Row>
          </Col>
      </Row>

      <Row gutter={[8, 8]} className="relative-dates">
          <Col span={5}>
              <span>Minute</span>
          </Col>
          <Col span={"19"}>
              <Row gutter={[8, 0]}>
                  <Col span={6}>
                      <Button
                          onClick={() => search({
                              type: "relative",
                              unit: "minute",
                              units: -30
                          })}
                          type={btnActive("minute", -30) ? "primary" : undefined}
                          block={true}
                      >Last 30</Button>
                  </Col>
                  <Col span={6}>
                      <Button
                          onClick={() => search({
                              type: "relative",
                              unit: "minute",
                              units: -15
                          })}
                          type={btnActive("minute", -15) ? "primary" : undefined}
                          block={true}
                      >Last 15</Button>
                  </Col>
                  <Col span={6}>
                      <Button
                          onClick={() => search({
                              type: "relative",
                              unit: "minute",
                              units: 15
                          })}
                          type={btnActive("minute", 15) ? "primary" : undefined}
                          block={true}
                      >Next 15</Button>
                  </Col>
                  <Col span={6}>
                      <Button
                          onClick={() => search({
                              type: "relative",
                              unit: "minute",
                              units: 30
                          })}
                          type={btnActive("minute", 30) ? "primary" : undefined}
                          block={true}
                      >Next 30</Button>
                  </Col>
              </Row>
          </Col>
      </Row>

      <Row gutter={[8, 8]} className="relative-dates">
          <Col span={6}>
              <div className="select-wrapper">
                  <div className="option">
                      <Select
                          placeholder="Range"
                          autoClearSearchValue={false}
                          value={lastNext}
                          onChange={setLastNext}
                          defaultValue={undefined}
                          style={{width: '95px'}}
                      >
                          <Select.Option key="0" value="last">
                              Last
                          </Select.Option>
                          <Select.Option key="1" value="next">
                              Next
                          </Select.Option>
                      </Select>
                  </div>
              </div>
          </Col>
          <Col span={12}>
              <Search
                  placeholder={"Value"}
                  allowClear
                  value={searchValue}
                  onChange={(e: any) => {
                      setSearchValue(e.target.value)
                      setStateValue({
                          type: "relative",
                          unit: stateValue ? stateValue.unit || "year" : "year",
                          units: lastNext === "last" ? -Math.abs(e.target.value) : Math.abs(e.target.value)
                      })
                  }}
                  min={0}
                  type="number"
                  style={{height: '32px'}}
              />
          </Col>
          <Col span={6}>
              <div className="select-wrapper">
                  <div className="option">
                      <Select
                          autoClearSearchValue={false}
                          placeholder="Time"
                          value={
                              stateValue && stateValue.unit ? stateValue.unit : "year"
                          }
                          onChange={(e: any) => {
                              setStateValue({
                                  type: "relative",
                                  unit: e,
                                  units: stateValue ? stateValue.units || 1 : 1,
                              })
                          }}
                      >
                          {timeUnits.map((unit) => (
                              <Select.Option key={unit.id} value={unit.value}>
                                  {unit.name}
                              </Select.Option>
                          ))}
                      </Select>
                  </div>
              </div>
          </Col>
      </Row></>,
        }
      ];

    const content = (
        <div className={"date-filter-content"}>
            <div className="top">
                <Tabs activeKey={tabKey || "2"} size="small" onChange={handleTabKey} items={tabItems}/>
                <div className="separator"/>
                <div className="bottom">
                    <Button onClick={handleClear} danger size="small">
                        Clear
                    </Button>
                    <Button
                        onClick={() => {
                            handleSearch();
                        }}
                        type="primary"
                        size="small"
                        disabled={!stateValue}
                    >
                        Search
                    </Button>
                </div>
            </div>
        </div>
    );

    return (
        <div className="date-filter-wrap">
            <Popover
                placement="bottomLeft"
                content={content}
                trigger="click"
                open={open}
                onOpenChange={handlePopoverVisibleChange}
            >
                <label className="filter-label">{title}</label>
                <Button className="trigger-filter" size="small">
                    {displayedText}
                    <DownOutlined/>
                </Button>
            </Popover>
        </div>
    );
};

export function isRelativeDate(value: any) {
    if (
        Array.isArray(value)
        && value.length === 2
        && typeof value[0] === "number"
        && typeof value[1] === "string"
    ) {
        return true
    }
    return false
}
