import React, { useEffect, useRef, useState } from "react";

import { Button, Drawer, Form } from "antd";

import { EditOutlined } from "@ant-design/icons";
import { useMercuryContext } from "../../user-context";
import { useScrollMonitor } from "../../Components";

import {
  failedNotification,
  successfulNotification,
} from "../../utils/notifications";

import "./EditPoType.scss";
import { activeFormItem, descriptionFormItem, titleFormItem } from "../../reusable/formItems";

interface ICurrentPoType {
  _id: any;
  title: string;
  description: string;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
}
interface IProps {
  currentPoType: ICurrentPoType;
  reload: () => any;
}

const EditPoType: React.FC<IProps> = ({ currentPoType, reload }) => {
  const context = useMercuryContext(),
    { gpClient } = context;

  const position = useScrollMonitor();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false),
    [loading, setLoading] = useState<boolean>(false);

  const [editingForm] = Form.useForm();

  const editFormRef = useRef(null);

  // TODO: remove the entire height logic once we update antd version and scrollLocker warning is not visible
  // with the current antd version, the user is allowed to scroll, but when is scrolling the header height is changing
  let height = 64,
    drawerClassName = "edit-po-type-drawer";

  if (isDrawerOpen && position < 64 && position >= 40) {
    height = 64 - (position - 40);
  } else if (isDrawerOpen && position < 64) {
    height = 64;
  } else if (isDrawerOpen) {
    height = 40;
  }

  if (isDrawerOpen && height <= 47) {
    drawerClassName = "edit-po-type-drawer ant-drawer__bigger";
  }

  useEffect(() => {
    if (isDrawerOpen) {
      editingForm.setFieldsValue({
        title: currentPoType.title,
        description: currentPoType.description,
        isActive: currentPoType.isActive,
      });
    }
  }, [isDrawerOpen, currentPoType, editingForm]);

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    editingForm.resetFields();
  };

  const onFinishEditingPOType = async (values: any) => {
    setLoading(true);

    const endpoint = `google-gp-v1/PoType/${currentPoType._id}`;

    try {
      await gpClient.patch(endpoint, values).then((result: any) => {
        successfulNotification(
          `PO Type was updated`,
          "The new PO Type details should be visible in the table. Otherwise please reload."
        );

        isDrawerOpen && closeDrawer();
        setLoading(false);
        reload();
      });
    } catch (e: any) {
      console.error("Error updating a PO Type: ");
      console.error(e);

      failedNotification("Failed to update the PO Type", "Please try again.");

      setLoading(false);
    }
  };

  const handleSwitchChange = (checked: boolean) => {
    editingForm.setFieldsValue({ isActive: checked });
  };

  return (
    <div className="edit-po-type">
      <Button
        size="small"
        type="link"
        onClick={() => setIsDrawerOpen(true)}
        className="enabled-edit-button"
      >
        <EditOutlined /> Edit
      </Button>
      {isDrawerOpen && (
        <Drawer
          title="Edit PO Type"
          placement="right"
          onClose={closeDrawer}
          open={isDrawerOpen}
          width={500}
          closable={true}
          className={drawerClassName}
          forceRender
        >
          <Form
            layout="horizontal"
            form={editingForm}
            ref={editFormRef}
            onFinish={onFinishEditingPOType}
            name="EditPoTypeForm"
          >
            {titleFormItem(true)}
            {descriptionFormItem(true)}
            {activeFormItem(handleSwitchChange)}
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save PO Type
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      )}
    </div>
  );
};

export default EditPoType;
