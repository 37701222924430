import React, {useCallback, useEffect, useState} from "react";
import {Button, Form, Input, Modal, Space} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useMercuryContext} from "../../../user-context";

export default function AssignInvoiceButton( { selectedRows } ) {
  const
      { gpSocket } = useMercuryContext(),
      ids = selectedRows.filter( ( row ) => row.status !== "INVOICED").map( ( row ) => row._id ),
      [ show, setShow ] = useState( false ),
      [ loading, setLoading ] = useState( false ),
      [ invoiceName, setInvoiceName] = useState( "" ),
      [ disabled, setDisabled ] = useState( false ),
      [ error, setError] = useState( null ),
      send = useCallback( () => {
        setDisabled( true );
        setLoading( true );
        setError( null );

        const socket =  gpSocket.adj;

        socket.emit( "setInvoiceName", { batches: ids, invoiceName }, ( err ) => {

          if ( err ) {
            setError( `And Error occurred: ${err.toString() }` )
          }

          setDisabled( false )
          setLoading( false );
          setShow( false )
        })


      }, [ setLoading, ids, invoiceName, gpSocket ]);

  return (
      <>
        <Modal
            title="Assign invoice"
            width={500}
            open={show}
            onCancel={() => !disabled && setShow( false )}
            zIndex={1000}
            footer={[
                <Space style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button key="cancel" type="default" disabled={disabled} onClick={() => setShow( false )}>
                    Cancel
                  </Button>
                  <Button
                      onClick={send}
                      disabled={invoiceName.length === 0 || disabled }
                      loading={loading}
                      icon={<PlusOutlined />}
                      type={ error ? "danger" : "primary" }>
                    Assign
                  </Button>
                </Space>
            ]}
        >
          <>
            <Form.Item>
              <Input
                  type={"text"}
                  onChange={( e ) => setInvoiceName( e.target.value )}
                  value={invoiceName}
                  placeholder={"Add invoice name"}/>
            </Form.Item>
            <div className={"error"}>
              { error }
            </div>
          </>

        </Modal>
        <Button onClick={() => setShow( true )} disabled={ids.length === 0} type={"primary"}>
          Assign
        </Button>
      </>
  )
}
