import SearchProjectAutocomplete from "./SearchProjectAutocomplete";
import Profile from "../../auth/Profile";

const RightMenu = (props) => {
  return (
    <div className="right-menu-container">
      <SearchProjectAutocomplete
        handleMainMenuSelection={props.adminMenuData.handleMainMenuSelection}
      />
      <Profile
        adminData={props.adminMenuData}
        handleMainMenuSelection={props.adminMenuData.handleMainMenuSelection}
      />
    </div>
  );
};

export default RightMenu;
