import React from "react";

import { Button, Drawer, Form, Select, Input } from "antd";
import {
  ReloadOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import UniversalButton from "../../../ui/UniversalButton";

const { Option } = Select;

const AddHelixTemplateMap = ({ handlers, values }) => {
  const { showDrawer, closeDrawer, onFinishNewTemplateMap, handleRefresh } =
    handlers;
  const {
    form,
    visibleDrawer,
    helixTemplates,
    components,
    products,
    services,
    order,
  } = values;

  return (
    <>
      <UniversalButton
        clickMethod={showDrawer}
        text="Add Mapping"
        type="primary"
        icon={
          !visibleDrawer ? (
            <PlusCircleOutlined style={{ fontSize: "15px" }} />
          ) : (
            <MinusCircleOutlined style={{ fontSize: "15px" }} />
          )
        }
      />
      <UniversalButton
        clickMethod={handleRefresh}
        text="Refresh"
        icon={<ReloadOutlined />}
      />
      <Drawer
        title="Add Google Helix Template Mapping"
        placement="right"
        onClose={closeDrawer}
        open={visibleDrawer}
        width={500}
        closable={true}
        className="manage-pages-drawer"
        forceRender
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinishNewTemplateMap}
          name="newTemplateForm"
          initialValues={{
            modifier: "public",
            orderValue: order + 1,
          }}
        >
          <Form.Item
            name="servicesValue"
            label="Services"
            rules={[{ required: false, message: "Please select a Service" }]}
          >
            <Select
              className="service-select"
              placeholder="Please select a Service"
              dropdownStyle={{
                textAlign: "center",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "var(--tertiary-color)",
              }}
              popupMatchSelectWidth={false}
              allowClear
              mode="multiple"
            >
              {services.map((service, index) => (
                <Option key={service._id} value={service.name}>
                  {service.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="productsValue"
            label="Products"
            rules={[{ required: false, message: "Please select a Product" }]}
          >
            <Select
              className="product-select"
              placeholder="Please select a product"
              dropdownStyle={{
                textAlign: "center",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "var(--tertiary-color)",
              }}
              popupMatchSelectWidth={false}
              allowClear
              mode="multiple"
            >
              {products.map((product, index) => (
                <Option key={product._id} value={product.title}>
                  {product.title}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="componentsValue"
            label="Components"
            rules={[{ required: false, message: "Please select a Component" }]}
          >
            <Select
              className="component-select"
              placeholder="Please select a component"
              dropdownStyle={{
                textAlign: "center",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "var(--tertiary-color)",
              }}
              popupMatchSelectWidth={false}
              allowClear
              mode="multiple"
            >
              {components.map((component, index) => (
                <Option key={index} value={component}>
                  {component}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="helixTemplateIdValue"
            label="Helix Template"
            rules={[{ required: true, message: "Please select a template" }]}
          >
            <Select
              className="template-select"
              placeholder="Please select a template"
              dropdownStyle={{
                textAlign: "center",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "var(--tertiary-color)",
              }}
              popupMatchSelectWidth={false}
              allowClear
            >
              {helixTemplates.map((template, index) => (
                <Option key={template._id} value={template._id}>
                  {template.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="orderValue" noStyle>
            <Input type="hidden" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save Mapping
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default AddHelixTemplateMap;
