import React, { useState } from 'react';
import { Modal, Form, Input } from 'antd';


const SetWMTPONumber = ({
    visible,
    selectedPLPs,
    onSubmit
  }) => {

    const [confirmLoading, setConfirmLoading] = useState(false);


    let initialValues = selectedPLPs.reduce(function (initialValues, selectedPLP) {
      if (!initialValues.wmtProject) {
        initialValues.wmtProject = (selectedPLP.projectType === 'WMT');
      } 
      
      if (!initialValues.wmtPONumber) {
        initialValues.wmtPONumber = selectedPLP.wmtPONumber;
      } else {
        if (initialValues.wmtPONumber !== selectedPLP.wmtPONumber) {
          initialValues.wmtPONumber = null;
        }
      }

      return initialValues;
    }, {})

    const [form] = Form.useForm();
    
    return (
      <Modal
        open={visible}
        title="Set WMT PO Number"
        okText="OK"
        cancelText="Cancel"
        onCancel={async () => {
          await onSubmit(false);
        }}
        onOk={async () => {
          try {
            let values = await form.validateFields();
            setConfirmLoading(true);
            await onSubmit(true, values);
            setConfirmLoading(false);
            form.resetFields();
          } catch (error) {
            console.log('Validate Failed:', error);
          }
        }}
        confirmLoading={confirmLoading}
      >
        <Form
          form={form}
          layout="vertical"
          name="setWMTPONumber"
          initialValues={{ 
            modifier: 'public',
            wmtPONumber: initialValues.wmtPONumber,
          }}
        >
            <Form.Item
              name="wmtPONumber"
              label="WMT PO Number"
              rules={[{ required: true, message: 'Please enter the WMT PO Number' }]}
            >
              <Input />
            </Form.Item>
          {/* {initialValues.wmtProject &&
          } */}

        </Form>
      </Modal>
    );
  };

  export default SetWMTPONumber;