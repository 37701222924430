import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Tag, Button } from 'antd';
import { EditOutlined } from "@ant-design/icons";

const DataTable = ({ accessToken, config, setViewEditLinguistic, setReg, products, filterQuery, refreshLinguists, setRefreshLinguists }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState();
  const [pageKeys, setPageKeys] = useState([null]);
  const [tableIsLoading, setTableIsLoading] = useState(false);

  const columns = [
    {
      title: () => (<div style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>Name</div>),
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: () => (<div style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>Personal Email</div>),
      dataIndex: 'personal_email',
      key: 'personal_emil',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.personal_email.localeCompare(b.personal_email),
    },
    {
      title: () => (<div style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>Sesame Account</div>),
      dataIndex: 'sesameId',
      key: 'sesameId',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.sesameId ? a.sesameId.localeCompare(b.sesameId) : -1,
    },
    {
      title: () => (<div style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>RWS Email</div>),
      dataIndex: 'sdl_email',
      key: 'sdl_email',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.sdl_email ? a.sdl_email.localeCompare(b.sesameId) : -1,
    },
    {
      title: () => (<div style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>Sharing Eligible</div>),
      dataIndex: 'eligible',
      key: 'eligible',
      width: '30px',
      render: eligible => (
        eligible ? 'Yes' : 'No'
      )
    },
    {
      title: () => (<div style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>Affiliation</div>),
      dataIndex: 'affiliation',
      key: 'affiliation',
    },
    {
      title: () => (<div style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>Notifications Enabled</div>),
      dataIndex: 'share_notifications',
      key: 'share_notifications',
      render: share_notifications => (
        share_notifications ? 'Yes' : 'No'
      )
    },
    {
      title: () => (<div style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>Source Language</div>),
      dataIndex: 'sourceLanguage',
      key: 'sourceLanguage',
      render: (text) => text ? <Tag color='var(--tertiary-color)'>{text}</Tag> : ''
    },
    {
      title: () => (<div style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>Target Languages</div>),
      dataIndex: 'language_codes',
      key: 'language_codes',
      render: (language_codes => (
        language_codes.map((item, index) => {
          return <Tag key={index} color='var(--tertiary-color)' style={{ margin: '0 5px 5px 0' }}>{item}</Tag>
        }))
      )
    },
    {
      title: () => (<div style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>Products</div>),
      dataIndex: 'products',
      key: 'products',
      render: products => {
        if (!products) return null;
        return products.map((item, index) => {
          return <Tag key={index} color='var(--tertiary-color)' style={{ margin: '0 5px 5px 0' }}>{item}</Tag>
        });
      }
    },
    {
      title: () => (<div style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>Product Areas</div>),
      dataIndex: 'productAreas',
      key: 'productAreas',
      render: (productAreas => (
        productAreas.map((item, index) => {
          return <Tag key={index} color='var(--tertiary-color)' style={{ margin: '0 5px 5px 0' }}>{item.label}</Tag>
        }))
      )
    },
    {
      title: () => (<div style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>Component Types</div>),
      dataIndex: 'components',
      key: 'components',
      render: (components => (
        components.map((item, index) => {
          return item !== '' ? <Tag key={`components-${index}`} color='var(--tertiary-color)' style={{ margin: '0 5px 5px 0' }}>{item}</Tag> : ''
        }))
      )
    },
    {
      title: () => (<div style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>Project Roles</div>),
      dataIndex: 'project_roles',
      key: 'project_roles',
      render: project_roles => {
        if (!project_roles) return null;
        return project_roles.map((item, index) => {
          return <Tag key={`project_roles-${index}`} color='var(--tertiary-color)' style={{ margin: '0 5px 5px 0' }}>{item}</Tag>
        });
      }
    },
    {
      title: () => (<div style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>Program Roles</div>),
      dataIndex: 'program_role',
      key: 'program_role',
    },
    {
      title: () => (<div style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>Edit</div>),
      dataIndex: 'id',
      render: (_, reg) => (
        <div>
          <Button type="text" style={{ color: 'var(--primary-color)' }} onClick={() => {
            setReg(reg);
            setViewEditLinguistic(true);
          }}>
            <EditOutlined/> Edit
          </Button>
        </div>
      )
    }
  ];

  const getLinguistics = async keys => {
    setTableIsLoading(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    const raw = JSON.stringify({...filterQuery, lastKey: keys.slice(-1)[0], pageSize });

    fetch(`${config.URL}/glms/linguistic/search`, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      cache: 'no-cache',
    }).then(async response => {
      const data = await response.json();
      
      setPageKeys([...keys, data.lastKey]);
      setTotalRecords(data.totalRecords);

      for (const linguist of data.linguists) {
        const areaKeys = {};
        if (linguist.products) {
          for (const product of linguist.products) {
            const foundProduct = products.find(x => x.title === product);
            if (foundProduct) {
              areaKeys[foundProduct.productArea._id] = foundProduct.productArea.title;
            }
          }
        }
        linguist.productAreas = Object.keys(areaKeys).map(key => ({ value: key, label: areaKeys[key] }));
      }

      setData(data.linguists);
      setTableIsLoading(false);
    });
  };

  useEffect(() => {
    if (accessToken && products && products.length > 0) {
      getLinguistics(pageKeys);
    }
  }, [accessToken, products, currentPage]);

  useEffect(() => {
    if (refreshLinguists) {
      setRefreshLinguists(false);
      setPageKeys([null]);
      if (currentPage === 1) {
        getLinguistics([null]);
      } else {
        setCurrentPage(1);
      }
    }
  }, [refreshLinguists]);

  const onPageChange = (number, size) => {
    // change of page size
    if (pageSize !== size) {
      setPageSize(size);
      setRefreshLinguists(true);
      return;
    }

    // change of page number
    setPageSize(size);
    if (number <= currentPage) {
      setPageKeys(x => x.slice(0, -2));
      setCurrentPage(x => x - 1);
    } else {
      setCurrentPage(x => x + 1);
    }
  };

  return (
    <div>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ padding: '0 15px' }}>
        <Col span={24}>
          <Table
            dataSource={data}
            columns={columns}
            size="small"
            rowKey="id"
            pagination={{
              position: ['bottomCenter'],
              showSizeChanger: true,
              onChange: onPageChange,
              total: totalRecords,
              defaultPageSize: pageSize,
              current: currentPage,
            }}
            locale={{
              triggerDesc: 'Sort by descend for this page',
              triggerAsc: 'Sort by ascend for this page',
              cancelSort: 'Cancel sort for this page',
            }}
            loading={tableIsLoading}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DataTable;