import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Row, Col, Modal, Radio, Select, Card, Tag, Space, Switch, Divider } from 'antd';
import { CloseOutlined, CheckOutlined, ExclamationCircleOutlined, RightCircleFilled, DoubleLeftOutlined, WarningOutlined, CheckCircleOutlined } from '@ant-design/icons';

const BulkEdit = ({
  accessToken,
  config,
  styles,
  setShowBulkEdit,
  allComponentTypes,
  allProducts,
  allProjectRoles,
  allLanguages,
  query,
  setRefreshLinguists,
}) => {
  const [form] = Form.useForm();
  const [componentsRadioOpt, setComponentsRadioOpt] = useState("addRemove");
  const [productsRadioOpt, setProductsRadioOpt] = useState("addRemove");
  const [projectRolesRadioOpt, setProjectRolesRadioOpt] = useState("addRemove");
  const [languagesRadioOpt, setLanguagesRadioOpt] = useState("addRemove");
  const [sourceLanguagesRadioOpt, setSourceLanguagesRadioOpt] = useState("");
  const [overwriteActive, setOverwriteActive] = useState(false);
  const [overwriteEligible, setOverwriteEligible] = useState(false);
  const [overwriteShareNotifications, setOverwriteShareNotifications] = useState(false);
  const [selectProductAreaCode, setSelectProductAreaCode] = useState();
  const [allProductAreas, setAllProductAreas] = useState([]);
  const [componentFindReplaceRequired, setComponentFindReplaceRequired] = useState(false);
  const [productFindReplaceRequired, setProductFindReplaceRequired] = useState(false);
  const [projectRoleFindReplaceRequired, setProjectRoleFindReplaceRequired] = useState(false);
  const [languageFindReplaceRequired, setLanguageFindReplaceRequired] = useState(false);
  const [sourceLanguageFindReplaceRequired, setSourceLanguageFindReplaceRequired] = useState(false);

  // Avoid putting the same item in add and remove
  const [allComponentTypesAdd, setAllComponentTypesAdd] = useState(allComponentTypes.map(x => ({ value: x, label: x })));
  const [allComponentTypesRemove, setAllComponentTypesRemove] = useState(allComponentTypes.map(x => ({ value: x, label: x })));
  const [allProductsAdd, setAllProductsAdd] = useState(allProducts.map(x => ({ value: x.title, label: x.title })));
  const [allProductsRemove, setAllProductsRemove] = useState(allProducts.map(x => ({ value: x.title, label: x.title })));
  const [allProjectRolesAdd, setAllProjectRolesAdd] = useState(allProjectRoles.map(x => ({ value: x.value, label: x.value })));
  const [allProjectRolesRemove, setAllProjectRolesRemove] = useState(allProjectRoles.map(x => ({ value: x.value, label: x.value })));
  const [allLanguagesAdd, setAllLanguagesAdd] = useState(allLanguages.map(x => ({ value: x.code, label: `${x.name} (${x.code})` })));
  const [allLanguagesRemove, setAllLanguagesRemove] = useState(allLanguages.map(x => ({ value: x.code, label: `${x.name} (${x.code})` })));
  const [allSourceLanguagesAdd, setAllSourceLanguagesAdd] = useState(allLanguages.map(x => ({ value: x.code, label: `${x.name} (${x.code})` })));
  const [allSourceLanguagesRemove, setAllSourceLanguagesRemove] = useState(allLanguages.map(x => ({ value: x.code, label: `${x.name} (${x.code})` })));

  // https://jira.sdl.com/browse/MER-1211
  const [refreshSelectKey, setRefreshSelectKey] = useState(false);

  const onBack = () => {
    clearForm();
    setShowBulkEdit(false);
  };

  useEffect(() => {
    const areaKeys = {};
    for (const product of allProducts) {
      areaKeys[product.productArea._id] = product.productArea.title;
    }
    const areas = [];
    for (const key in areaKeys) {
      areas.push({ value: key, label: areaKeys[key] });
    }
    areas.sort((a, b) => a.label.localeCompare(b.label));
    setAllProductAreas(areas);
  }, [allProducts]);

  useEffect(() => {
    const fields = ["components", "products", "projectRoles", "languages", "sourceLanguages"];
    for (const field of fields) {

      if (form.getFieldValue(`${field}_find`) ? false : true && form.getFieldValue(`${field}_replace`).length === 0) {
        form.validateFields([`${field}_find`, `${field}_replace`]);
      }
    }
  }, [componentFindReplaceRequired, productFindReplaceRequired, projectRoleFindReplaceRequired, languageFindReplaceRequired, sourceLanguageFindReplaceRequired]);
 
  const onValuesChange = changedValues => {
    const fields = ["components", "products", "projectRoles", "languages", "sourceLanguages"];

    for (const field of fields) {

      // Update required fields based on changes
      if (changedValues.hasOwnProperty(`${field}_find`) || changedValues.hasOwnProperty(`${field}_replace`)) {
        const required = (form.getFieldValue(`${field}_find`) ? true : false) || form.getFieldValue(`${field}_replace`).length > 0;

        switch (field) {
          case "components":
            setComponentFindReplaceRequired(required);
            break;
          case "products":
            setProductFindReplaceRequired(required);
            break;
          case "projectRoles":
            setProjectRoleFindReplaceRequired(required);
            break;
          case "languages":
            setLanguageFindReplaceRequired(required);
            break;
          case "sourceLanguages":
            setSourceLanguageFindReplaceRequired(required);
            break;
        }
      }

      // Avoid putting the same item in add and remove
      if (changedValues.hasOwnProperty(`${field}_add`)) {
        const set = new Set(changedValues[`${field}_add`]);
        switch (field) {
          case "components":
            setAllComponentTypesRemove(allComponentTypes.filter(x => !set.has(x)).map(x => ({ value: x, label: x })));
            break;
          case "products":
            setAllProductsRemove(allProducts.filter(x => !set.has(x.title)).map(x => ({ value: x.title, label: x.title })));
            break;
          case "projectRoles":
            setAllProjectRolesRemove(allProjectRoles.filter(x => !set.has(x.value)).map(x => ({ value: x.value, label: x.value })));
            break;
          case "languages":
            setAllLanguagesRemove(allLanguages.filter(x => !set.has(x.code)).map(x => ({ value: x.code, label: `${x.name} (${x.code})` })));
            break;
          case "sourceLanguages":
            setAllSourceLanguagesRemove(allLanguages.filter(x => !set.has(x.code)).map(x => ({ value: x.code, label: `${x.name} (${x.code})` })));
            break;
        }
      }
      if (changedValues.hasOwnProperty(`${field}_remove`)) {
        const set = new Set(changedValues[`${field}_remove`]);
        switch (field) {
          case "components":
            setAllComponentTypesAdd(allComponentTypes.filter(x => !set.has(x)).map(x => ({ value: x, label: x })));
            break;
          case "products":
            setAllProductsAdd(allProducts.filter(x => !set.has(x.title)).map(x => ({ value: x.title, label: x.title })));
            break;
          case "projectRoles":
            setAllProjectRolesAdd(allProjectRoles.filter(x => !set.has(x.value)).map(x => ({ value: x.value, label: x.value })));
            break;
          case "languages":
            setAllLanguagesAdd(allLanguages.filter(x => !set.has(x.code)).map(x => ({ value: x.code, label: `${x.name} (${x.code})` })));
            break;
          case "sourceLanguages":
            setAllSourceLanguagesAdd(allLanguages.filter(x => !set.has(x.code)).map(x => ({ value: x.code, label: `${x.name} (${x.code})` })));
            break;
        }
      }
    }
  };

  const confirmChangesModal = confirmInfo => {
    const content = (
      <React.Fragment>
        <Divider orientation="left">Edit all linguists that satisfy the following filters:</Divider>
          {confirmInfo.filterMessages.map((message, i) => (
            <div key={i} style={{ fontSize: "14px" }}>
              <Space>
                <RightCircleFilled style={{ color: "var(--tertiary-color)" }} />
                {message}
              </Space>
            </div>
          ))}
        <Divider orientation="left">Apply these changes to those linguists:</Divider>
        {confirmInfo.changeMessages.map((message, i) => (
          <div key={i} style={{ fontSize: "14px" }}>
            <Space>
              <RightCircleFilled style={{ color: "var(--tertiary-color)" }} />
              {message}
            </Space>
          </div>
        ))}
        {confirmInfo.warningMessages.length > 0 && (
          <React.Fragment>
            <Divider orientation="left">Warning Messages:</Divider>
            {confirmInfo.warningMessages.map((message, i) => (
              <div key={i} style={{ fontSize: "14px" }}>
                <Space>
                  <WarningOutlined style={{ color: "#faad14" }} />
                  {message}
                </Space>
              </div>
            ))}
          </React.Fragment>
        )}
      </React.Fragment>
    );

    if (confirmInfo.changeMessages.length > 0) {
      Modal.confirm({
        title: "Confirm Bulk Edit",
        icon: <ExclamationCircleOutlined />,
        width: "80%",
        content: content,
        okText: "Update",
        onOk: async () => {
          const body = { query: confirmInfo.query, changes: confirmInfo.changes };
          const requestOptions = {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(body),
          };
          const response = await fetch(`${config.URL}/glms/linguistic/bulkedit`, requestOptions);
          const data = await response.json();
          if (data.errors && data.errors.length > 0) {
            const errorsContent = (
              <React.Fragment>
                <Divider orientation="left">Bulk Edit executed, result warnings:</Divider>
                  {data.errors.map((error, i) => (
                    <div key={i} style={{ fontSize: "14px" }}>
                      <Space>
                        <RightCircleFilled style={{ color: "#faad14" }} />
                        {error}
                      </Space>
                    </div>
                  ))}
              </React.Fragment>
            );
            Modal.warning({
              title: "Bulk Edit",
              icon: <ExclamationCircleOutlined />,
              width: "80%",
              content: errorsContent,
              okText: "Ok",
              onOk: () => {
                setRefreshLinguists(true);
                onBack();
              },
            });
          } else {
            Modal.success({
              title: "Bulk Edit",
              icon: <CheckCircleOutlined />,
              width: "80%",
              content: "Bulk Edit executed successfully.",
              okText: "Ok",
              onOk: () => {
                setRefreshLinguists(true);
                onBack();
              },
            });
          }
        },
      });
    } else {
      Modal.warning({
        title: "Confirm Bulk Edit",
        icon: <ExclamationCircleOutlined />,
        width: "80%",
        content: content,
        okText: "Cancel",
      });
    }
  };

  const clearForm = () => {
    form.setFieldsValue({
      components_add: [],
      components_remove: [],
      components_find: null,
      components_replace: [],
      components_overwrite: [],
      products_add: [],
      products_remove: [],
      products_find: null,
      products_replace: [],
      products_overwrite: [],
      projectRoles_add: [],
      projectRoles_remove: [],
      projectRoles_find: null,
      projectRoles_replace: [],
      projectRoles_overwrite: [],
      languages_add: [],
      languages_remove: [],
      languages_find: null,
      languages_replace: [],
      languages_overwrite: [],
      sourceLanguages_add: [],
      sourceLanguages_remove: [],
      sourceLanguages_find: null,
      sourceLanguages_replace: [],
      sourceLanguages_overwrite: [],
      active: true,
      eligible: true,
      shareNotifications: true,
      productArea: null,
    });
    setComponentsRadioOpt("addRemove");
    setProductsRadioOpt("addRemove");
    setProjectRolesRadioOpt("addRemove");
    setLanguagesRadioOpt("addRemove");
    setSourceLanguagesRadioOpt("");
    setOverwriteActive(false);
    setOverwriteEligible(false);
    setOverwriteShareNotifications(false);
  };

  const onReset = () => {
    clearForm();
  };

  const buildFilterMessagesFromQuery = query => {
    let output = [];
    if (query.hasOwnProperty('personal_email')) {
      output.push(`Personal Email contains: ${query.personal_email}`);
    }
    if (query.hasOwnProperty('sesameId')) {
      output.push(`Sesame Account contains: ${query.sesameId}`);
    }
    if (query.hasOwnProperty('sdl_email')) {
      output.push(`RWS Email contains: ${query.sdl_email}`);
    }
    if (query.hasOwnProperty('eligible')) {
      output.push(`Eligible is: ${query.eligible}`);
    }
    if (query.hasOwnProperty('language_codes')) {
      output.push(`Languages contains at least one of: ${query.language_codes.join(', ')}`);
    }
    if (query.hasOwnProperty('products')) {
      output.push(`Products contains at least one of: ${query.products.join(', ')}`);
    }
    if (query.hasOwnProperty('components')) {
      output.push(`Component Types contains at least one of: ${query.components.join(', ')}`);
    }
    if (query.hasOwnProperty('project_roles')) {
      output.push(`Project Roles contains at least one of: ${query.project_roles.join(', ')}`);
    }
    if (query.hasOwnProperty('program_role')) {
      output.push(`Program Role is: ${query.program_role}`);
    }
    if (query.hasOwnProperty('affiliation')) {
      output.push(`Affiliation is: ${query.affiliation}`);
    }
    if (query.hasOwnProperty('active')) {
      output.push(`Active is: ${query.active}`);
    }
    return output;
  };

  const onSubmit = async formValues => {
    // Build confirmInfo object with query, changes and all messages
    let confirmInfo = { query: query, changes: {}, filterMessages: [], changeMessages: [], warningMessages: []};

    // Filter Messages
    confirmInfo.filterMessages = buildFilterMessagesFromQuery(confirmInfo.query);

    // Components
    switch (componentsRadioOpt) {
      default:
      case "addRemove":
        if (formValues.components_add.length > 0 || formValues.components_remove.length > 0) {
          confirmInfo.changes.components = {
            addRemove: {
              add: formValues.components_add,
              remove: formValues.components_remove,
            }
          };
          // Change Messages
          if (formValues.components_add.length > 0) {
            confirmInfo.changeMessages.push(`Components: Adding ${formValues.components_add.join(', ')}.`);
          }
          if (formValues.components_remove.length > 0) {
            confirmInfo.changeMessages.push(`Components: Removing ${formValues.components_remove.join(', ')}.`);
          }
        }
        break;
      case "findReplace":
        if (formValues.components_find) {
          confirmInfo.changes.components = {
            findReplace: {
              find: formValues.components_find,
              replace: formValues.components_replace,
            }
          };
          // Change Messages
          confirmInfo.changeMessages.push(`Components: Finding ${formValues.components_find} and Replacing with ${formValues.components_replace.join(', ')}.`);
        }
        break;
      case "overwrite":
        confirmInfo.changes.components = {
          overwrite: formValues.components_overwrite
        };
        // Change Messages
        confirmInfo.changeMessages.push(`Components: Overwriting all with ${formValues.components_overwrite.join(', ')}.`);
        break;
    }
    // Products
    switch (productsRadioOpt) {
      default:
      case "addRemove":
        if (formValues.products_add.length > 0 || formValues.products_remove.length > 0) {
          confirmInfo.changes.products = {
            addRemove: {
              add: formValues.products_add,
              remove: formValues.products_remove,
            }
          };
        }
        // Change Messages
        if (formValues.products_add.length > 0) {
          confirmInfo.changeMessages.push(`Products: Adding ${formValues.products_add.join(', ')}.`);
        }
        if (formValues.products_remove.length > 0) {
          confirmInfo.changeMessages.push(`Products: Removing ${formValues.products_remove.join(', ')}.`);
        }
        break;
      case "findReplace":
        if (formValues.products_find) {
          confirmInfo.changes.products = {
            findReplace: {
              find: formValues.products_find,
              replace: formValues.products_replace,
            }
          };
          // Change Messages
          confirmInfo.changeMessages.push(`Products: Finding ${formValues.products_find} and Replacing with ${formValues.products_replace.join(', ')}.`);
        }
        break;
      case "overwrite":
        confirmInfo.changes.products = {
          overwrite: formValues.products_overwrite
        };
        // Change Messages
        confirmInfo.changeMessages.push(`Products: Overwriting all with ${formValues.products_overwrite.join(', ')}.`);
        break;
    }
    // Project Roles
    switch (projectRolesRadioOpt) {
      default:
      case "addRemove":
        if (formValues.projectRoles_add.length > 0 || formValues.projectRoles_remove.length > 0) {
          confirmInfo.changes.projectRoles = {
            addRemove: {
              add: formValues.projectRoles_add,
              remove: formValues.projectRoles_remove,
            }
          };
        }
        // Change Messages
        if (formValues.projectRoles_add.length > 0) {
          confirmInfo.changeMessages.push(`Project Roles: Adding ${formValues.projectRoles_add.join(', ')}.`);
        }
        if (formValues.projectRoles_remove.length > 0) {
          confirmInfo.changeMessages.push(`Project Roles: Removing ${formValues.projectRoles_remove.join(', ')}.`);
        }
        break;
      case "findReplace":
        if (formValues.projectRoles_find) {
          confirmInfo.changes.projectRoles = {
            findReplace: {
              find: formValues.projectRoles_find,
              replace: formValues.projectRoles_replace,
            }
          };
          // Change Messages
          confirmInfo.changeMessages.push(`Project Roles: Finding ${formValues.projectRoles_find} and Replacing with ${formValues.projectRoles_replace.join(', ')}.`);
        }
        break;
      case "overwrite":
        confirmInfo.changes.projectRoles = {
          overwrite: formValues.projectRoles_overwrite
        };
        // Change Messages
        confirmInfo.changeMessages.push(`Project Roles: Overwriting all with ${formValues.projectRoles_overwrite.join(', ')}.`);
        break;
    }
    // Target Languages
    switch (languagesRadioOpt) {
      default:
      case "addRemove":
        if (formValues.languages_add.length > 0 || formValues.languages_remove.length > 0) {
          confirmInfo.changes.languages = {
            addRemove: {
              add: formValues.languages_add,
              remove: formValues.languages_remove,
            }
          };
        }
        // Change Messages
        if (formValues.languages_add.length > 0) {
          confirmInfo.changeMessages.push(`Languages: Adding ${formValues.languages_add.join(', ')}.`);
        }
        if (formValues.languages_remove.length > 0) {
          confirmInfo.changeMessages.push(`Languages: Removing ${formValues.languages_remove.join(', ')}.`);
        }
        break;
      case "findReplace":
        if (formValues.languages_find) {
          confirmInfo.changes.languages = {
            findReplace: {
              find: formValues.languages_find,
              replace: formValues.languages_replace,
            }
          };
          // Change Messages
          confirmInfo.changeMessages.push(`Languages: Finding ${formValues.languages_find} and Replacing with ${formValues.languages_replace.join(', ')}.`);
        }
        break;
      case "overwrite":
        confirmInfo.changes.languages = {
          overwrite: formValues.languages_overwrite
        };
        // Change Messages
        confirmInfo.changeMessages.push(`Languages: Overwriting all with ${formValues.languages_overwrite.join(', ')}.`);
        break;
    }
        // Source languages
        switch (sourceLanguagesRadioOpt) {
          default:
          // case "addRemove":
          //   if (formValues.sourceLanguages_add.length > 0 || formValues.sourceLanguages_remove.length > 0) {
          //     confirmInfo.changes.sourceLanguages = {
          //       addRemove: {
          //         add: formValues.sourceLanguages_add,
          //         remove: formValues.sourceLanguages_remove,
          //       }
          //     };
          //   }
          //   // Change Messages
          //   if (formValues.sourceLanguages_add.length > 0) {
          //     confirmInfo.changeMessages.push(`Source Languages: Adding ${formValues.sourceLanguages_add}.`);
          //   }
          //   if (formValues.sourceLanguages_remove.length > 0) {
          //     confirmInfo.changeMessages.push(`Source Languages: Removing ${formValues.sourceLanguages_remove}.`);
          //   }
          //   break;
          // case "findReplace":
          //   if (formValues.sourceLanguages_find) {
          //     confirmInfo.changes.sourceLanguages = {
          //       findReplace: {
          //         find: formValues.sourceLanguages_find,
          //         replace: formValues.sourceLanguages_replace,
          //       }
          //     };
          //     // Change Messages
          //     confirmInfo.changeMessages.push(`Source Languages: Finding ${formValues.sourceLanguages_find} and Replacing with ${formValues.sourceLanguages_replace}.`);
          //   }
          //   break;
          case "overwrite":
            if (formValues.sourceLanguages_overwrite) {
              confirmInfo.changes.sourceLanguages = {
                overwrite: formValues.sourceLanguages_overwrite
              };
              // Change Messages
              confirmInfo.changeMessages.push(`Source Languages: Overwriting all with ${formValues.sourceLanguages_overwrite}.`);
            }
            break;
        }

    if (overwriteActive) {
      confirmInfo.changes.active = formValues.active;
      // Change Messages
      confirmInfo.changeMessages.push(`Active: Overwriting all to ${formValues.active}.`);
    }
    if (overwriteEligible) {
      confirmInfo.changes.eligible = formValues.eligible;
      // Change Messages
      confirmInfo.changeMessages.push(`Share Eligible: Overwriting all to ${formValues.eligible}.`);
    }
    if (overwriteShareNotifications) {
      confirmInfo.changes.notifications = formValues.shareNotifications;
      // Change Messages
      confirmInfo.changeMessages.push(`Receive Sharing Notifications: Overwriting all to ${formValues.shareNotifications}.`);
    }

    // Add warning message if there are no filters
    if (confirmInfo.filterMessages.length === 0) {
      confirmInfo.warningMessages.push("You have not selected any filters, this edit will affect all linguists.");
    }

    // Add warning message if there are no changes
    if (confirmInfo.changeMessages.length === 0) {
      confirmInfo.warningMessages.push("You have not selected any changes, please review the form.");
    }

    confirmChangesModal(confirmInfo);
  };

  const onPreventMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onAddProductArea = type => {
    setSelectProductAreaCode(type);
  };

  const addProductsToSelect = () => {
    const productAreaId = form.getFieldValue("productArea");
    if (productAreaId && selectProductAreaCode) {
      const selectedProducts = form.getFieldValue(`products_${selectProductAreaCode}`);
      for (const product of allProducts) {
        if (product.productArea._id === productAreaId && !selectedProducts.includes(product.title)) {
          selectedProducts.push(product.title);
        }
      }
      form.setFieldsValue({ [`products_${selectProductAreaCode}`]: selectedProducts });
      form.validateFields([`products_${selectProductAreaCode}`]);
    }
    setSelectProductAreaCode(null);
    form.setFieldsValue({ productArea: null });
    setRefreshSelectKey(x => !x);
  };

  const customTagRender = ({ label, closable, onClose }) => {
    return (
      <Tag
        color='var(--tertiary-color)'
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, marginBottom: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Modal
      title="Bulk Edit"
      open={true}
      width="80%"
      onCancel={onBack}
      maskClosable={false}
      footer={[
        <Button key="cancel" type="default" onClick={onBack}>
          Cancel
        </Button>,
        <Button key="reset" type="default" danger onClick={onReset}>
          Reset
        </Button>,
        <Button key="ok" type="primary" onClick={form.submit}>
          Update
        </Button>,
      ]}
    >
      <div id={styles['edit-linguistic']}>
        <Form name="bulk-edit-linguistic"
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          onValuesChange={onValuesChange}
          initialValues={{
            components_add: [],
            components_remove: [],
            components_find: null,
            components_replace: [],
            components_overwrite: [],
            products_add: [],
            products_remove: [],
            products_find: null,
            products_replace: [],
            products_overwrite: [],
            projectRoles_add: [],
            projectRoles_remove: [],
            projectRoles_find: null,
            projectRoles_replace: [],
            projectRoles_overwrite: [],
            languages_add: [],
            languages_remove: [],
            languages_find: null,
            languages_replace: [],
            languages_overwrite: [],
            sourceLanguages_add: [],
            sourceLanguages_remove: [],
            sourceLanguages_find: null,
            sourceLanguages_replace: [],
            sourceLanguages_overwrite: [],
            active: true,
            eligible: true,
            shareNotifications: true,
          }}
        >
          <Space direction="vertical" style={{ width: "100%" }} size="large">
            <Row gutter={8}>
              <Col span={4}>Components</Col>
              <Col span={20}>
                <Card
                  cover={
                    <Radio.Group
                      value={componentsRadioOpt}
                      buttonStyle="solid"
                      onChange={ev => { setComponentsRadioOpt(ev.target.value); }}
                    >
                      <Radio.Button style={{ width: "33%", textAlign: "center" }} value="addRemove">Add / Remove</Radio.Button>
                      <Radio.Button style={{ width: "34%", textAlign: "center" }} value="findReplace">Find / Replace</Radio.Button>
                      <Radio.Button style={{ width: "33%", textAlign: "center" }} value="overwrite">Overwrite</Radio.Button>
                    </Radio.Group>
                  }
                >
                  {componentsRadioOpt === "addRemove" && (
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Row gutter={8}>
                        <Col span={3}>Add</Col>
                        <Col span={21}>
                          <Form.Item name="components_add" noStyle>
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: '100%' }}
                              placeholder="Component Types"
                              options={allComponentTypesAdd}
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                              tagRender={customTagRender}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={3}>Remove</Col>
                        <Col span={21}>
                          <Form.Item name="components_remove" noStyle>
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: '100%' }}
                              placeholder="Component Types"
                              options={allComponentTypesRemove}
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                              tagRender={customTagRender}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Space>
                  )}
                  {componentsRadioOpt === "findReplace" && (
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Row gutter={8}>
                        <Col span={3}>Find</Col>
                        <Col span={21}>
                          <Form.Item name="components_find" rules={[{ required: componentFindReplaceRequired, message: 'This field is required.' }]}>
                            <Select
                              style={{ width: '100%' }}
                              placeholder="Component Type"
                              options={allComponentTypes.map(x => ({ value: x, label: x }))}
                              showSearch
                              allowClear
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={3}>Replace</Col>
                        <Col span={21}>
                          <Form.Item name="components_replace" rules={[{ required: componentFindReplaceRequired, message: 'This field is required.' }]}>
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: '100%' }}
                              placeholder="Component Types"
                              options={allComponentTypes.map(x => ({ value: x, label: x }))}
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                              tagRender={customTagRender}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Space>
                  )}
                  {componentsRadioOpt === "overwrite" && (
                    <Row gutter={8}>
                      <Col span={3}>Overwrite</Col>
                      <Col span={21}>
                        <Form.Item name="components_overwrite" rules={[{ required: true, message: 'This field is required.' }]}>
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Component Types"
                            options={allComponentTypes.map(x => ({ value: x, label: x }))}
                            filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            tagRender={customTagRender}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Card>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={4}>Products</Col>
              <Col span={20}>
                <Card
                  cover={
                    <Radio.Group
                      value={productsRadioOpt}
                      buttonStyle="solid"
                      onChange={ev => { setProductsRadioOpt(ev.target.value); }}
                    >
                      <Radio.Button style={{ width: "33%", textAlign: "center" }} value="addRemove">Add / Remove</Radio.Button>
                      <Radio.Button style={{ width: "34%", textAlign: "center" }} value="findReplace">Find / Replace</Radio.Button>
                      <Radio.Button style={{ width: "33%", textAlign: "center" }} value="overwrite">Overwrite</Radio.Button>
                    </Radio.Group>
                  }
                >
                  {productsRadioOpt === "addRemove" && (
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Row gutter={8}>
                        <Col span={3}>Add</Col>
                        <Col span={18}>
                          <Form.Item name="products_add" noStyle>
                            <Select
                              key={refreshSelectKey}
                              mode="multiple"
                              allowClear
                              style={{ width: '100%' }}
                              placeholder="Products"
                              options={allProductsAdd}
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                              tagRender={customTagRender}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Button
                            type="dashed"
                            icon={<DoubleLeftOutlined />}
                            style={{ width: '100%' }}
                            onClick={() => { onAddProductArea("add"); }}
                          >
                            Area
                          </Button>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={3}>Remove</Col>
                        <Col span={18}>
                          <Form.Item name="products_remove" noStyle>
                            <Select
                              key={refreshSelectKey}
                              mode="multiple"
                              allowClear
                              style={{ width: '100%' }}
                              placeholder="Products"
                              options={allProductsRemove}
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                              tagRender={customTagRender}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Button
                            type="dashed"
                            icon={<DoubleLeftOutlined />}
                            style={{ width: '100%' }}
                            onClick={() => { onAddProductArea("remove"); }}
                          >
                            Area
                          </Button>
                        </Col>
                      </Row>
                    </Space>
                  )}
                  {productsRadioOpt === "findReplace" && (
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Row gutter={8}>
                        <Col span={3}>Find</Col>
                        <Col span={21}>
                          <Form.Item name="products_find" rules={[{ required: productFindReplaceRequired, message: 'This field is required.' }]}>
                            <Select
                              style={{ width: '100%' }}
                              placeholder="Product"
                              options={allProducts.map(x => ({ value: x.title, label: x.title }))}
                              showSearch
                              allowClear
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={3}>Replace</Col>
                        <Col span={18}>
                          <Form.Item name="products_replace" rules={[{ required: productFindReplaceRequired, message: 'This field is required.' }]}>
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: '100%' }}
                              placeholder="Products"
                              options={allProducts.map(x => ({ value: x.title, label: x.title }))}
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                              tagRender={customTagRender}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Button
                            type="dashed"
                            icon={<DoubleLeftOutlined />}
                            style={{ width: '100%' }}
                            onClick={() => { onAddProductArea("replace"); }}
                          >
                            Area
                          </Button>
                        </Col>
                      </Row>
                    </Space>
                  )}
                  {productsRadioOpt === "overwrite" && (
                    <Row gutter={8}>
                      <Col span={3}>Overwrite</Col>
                      <Col span={18}>
                        <Form.Item name="products_overwrite" rules={[{ required: true, message: 'This field is required.' }]}>
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Products"
                            options={allProducts.map(x => ({ value: x.title, label: x.title }))}
                            filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            tagRender={customTagRender}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Button
                          type="dashed"
                          icon={<DoubleLeftOutlined />}
                          style={{ width: '100%' }}
                          onClick={() => { onAddProductArea("overwrite"); }}
                        >
                          Area
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Card>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={4}>Project Roles</Col>
              <Col span={20}>
                <Card
                  cover={
                    <Radio.Group
                      value={projectRolesRadioOpt}
                      buttonStyle="solid"
                      onChange={ev => { setProjectRolesRadioOpt(ev.target.value); }}
                    >
                      <Radio.Button style={{ width: "33%", textAlign: "center" }} value="addRemove">Add / Remove</Radio.Button>
                      <Radio.Button style={{ width: "34%", textAlign: "center" }} value="findReplace">Find / Replace</Radio.Button>
                      <Radio.Button style={{ width: "33%", textAlign: "center" }} value="overwrite">Overwrite</Radio.Button>
                    </Radio.Group>
                  }
                >
                  {projectRolesRadioOpt === "addRemove" && (
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Row gutter={8}>
                        <Col span={3}>Add</Col>
                        <Col span={21}>
                          <Form.Item name="projectRoles_add" noStyle>
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: '100%' }}
                              placeholder="Project Roles"
                              options={allProjectRolesAdd}
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                              tagRender={customTagRender}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={3}>Remove</Col>
                        <Col span={21}>
                          <Form.Item name="projectRoles_remove" noStyle>
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: '100%' }}
                              placeholder="Project Roles"
                              options={allProjectRolesRemove}
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                              tagRender={customTagRender}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Space>
                  )}
                  {projectRolesRadioOpt === "findReplace" && (
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Row gutter={8}>
                        <Col span={3}>Find</Col>
                        <Col span={21}>
                          <Form.Item name="projectRoles_find" rules={[{ required: projectRoleFindReplaceRequired, message: 'This field is required.' }]}>
                            <Select
                              style={{ width: '100%' }}
                              placeholder="Project Role"
                              options={allProjectRoles.map(x => ({ value: x.value, label: x.value }))}
                              showSearch
                              allowClear
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={3}>Replace</Col>
                        <Col span={21}>
                          <Form.Item name="projectRoles_replace" rules={[{ required: projectRoleFindReplaceRequired, message: 'This field is required.' }]}>
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: '100%' }}
                              placeholder="Project Roles"
                              options={allProjectRoles.map(x => ({ value: x.value, label: x.value }))}
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                              tagRender={customTagRender}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Space>
                  )}
                  {projectRolesRadioOpt === "overwrite" && (
                    <Row gutter={8}>
                      <Col span={3}>Overwrite</Col>
                      <Col span={21}>
                        <Form.Item name="projectRoles_overwrite" rules={[{ required: true, message: 'This field is required.' }]}>
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Project Roles"
                            options={allProjectRoles.map(x => ({ value: x.value, label: x.value }))}
                            filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            tagRender={customTagRender}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Card>
              </Col>
            </Row>
            <Row gutter={8}>
            <Col span={4}>Target Languages</Col>
              <Col span={20}>
                <Card
                  cover={
                    <Radio.Group
                      value={languagesRadioOpt}
                      buttonStyle="solid"
                      onChange={ev => { setLanguagesRadioOpt(ev.target.value); }}
                    >
                      <Radio.Button style={{ width: "33%", textAlign: "center" }} value="addRemove">Add / Remove</Radio.Button>
                      <Radio.Button style={{ width: "34%", textAlign: "center" }} value="findReplace">Find / Replace</Radio.Button>
                      <Radio.Button style={{ width: "33%", textAlign: "center" }} value="overwrite">Overwrite</Radio.Button>
                    </Radio.Group>
                  }
                >
                  {languagesRadioOpt === "addRemove" && (
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Row gutter={8}>
                        <Col span={3}>Add</Col>
                        <Col span={21}>
                          <Form.Item name="languages_add" noStyle>
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: '100%' }}
                              placeholder="Languages"
                              options={allLanguagesAdd}
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                              tagRender={customTagRender}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={3}>Remove</Col>
                        <Col span={21}>
                          <Form.Item name="languages_remove" noStyle>
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: '100%' }}
                              placeholder="Languages"
                              options={allLanguagesRemove}
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                              tagRender={customTagRender}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Space>
                  )}
                  {languagesRadioOpt === "findReplace" && (
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Row gutter={8}>
                        <Col span={3}>Find</Col>
                        <Col span={21}>
                          <Form.Item name="languages_find" rules={[{ required: languageFindReplaceRequired, message: 'This field is required.' }]}>
                            <Select
                              style={{ width: '100%' }}
                              placeholder="Language"
                              options={allLanguages.map(x => ({ value: x.code, label: `${x.name} (${x.code})` }))}
                              showSearch
                              allowClear
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={3}>Replace</Col>
                        <Col span={21}>
                          <Form.Item name="languages_replace" rules={[{ required: languageFindReplaceRequired, message: 'This field is required.' }]}>
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: '100%' }}
                              placeholder="Languages"
                              options={allLanguages.map(x => ({ value: x.code, label: `${x.name} (${x.code})` }))}
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                              tagRender={customTagRender}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Space>
                  )}
                  {languagesRadioOpt === "overwrite" && (
                    <Row gutter={8}>
                      <Col span={3}>Overwrite</Col>
                      <Col span={21}>
                        <Form.Item name="languages_overwrite" rules={[{ required: true, message: 'This field is required.' }]}>
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Languages"
                            options={allLanguages.map(x => ({ value: x.code, label: `${x.name} (${x.code})` }))}
                            filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            tagRender={customTagRender}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Card>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={4}>Source Language</Col>
              <Col span={20}>
                <Card
                  cover={
                    <Radio.Group
                      value={sourceLanguagesRadioOpt}
                      buttonStyle="solid"
                      onChange={ev => { setSourceLanguagesRadioOpt(ev.target.value)}}
                    >
                      {/* IMPORTANT: sourceLanguage is a string not array so backend will not support add/remove or find/replace functionality  */}
                      {/* <Radio.Button style={{ width: "33%", textAlign: "center" }} value="addRemove">Add / Remove</Radio.Button>
                      <Radio.Button style={{ width: "34%", textAlign: "center" }} value="findReplace">Find / Replace</Radio.Button> */}
                      <Radio.Button style={{ width: "33%", textAlign: "center" }} value="">No Overwrite</Radio.Button>
                      <Radio.Button style={{ width: "67%", textAlign: "center" }} value="overwrite">Overwrite</Radio.Button>
                    </Radio.Group>
                  }
                >
                  {/* {sourceLanguagesRadioOpt === "addRemove" && (
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Row gutter={8}>
                        <Col span={3}>Add</Col>
                        <Col span={21}>
                          <Form.Item name="sourceLanguages_add" noStyle>
                            <Select
                              // mode="multiple"
                              allowClear
                              style={{ width: '100%' }}
                              placeholder="Languages"
                              options={allSourceLanguagesAdd}
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                              tagRender={customTagRender}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={3}>Remove</Col>
                        <Col span={21}>
                          <Form.Item name="sourceLanguages_remove" noStyle>
                            <Select
                              // mode="multiple"
                              allowClear
                              style={{ width: '100%' }}
                              placeholder="Languages"
                              options={allSourceLanguagesRemove}
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                              tagRender={customTagRender}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Space>
                  )}
                  {sourceLanguagesRadioOpt === "findReplace" && (
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Row gutter={8}>
                        <Col span={3}>Find</Col>
                        <Col span={21}>
                          <Form.Item name="sourceLanguages_find" rules={[{ required: sourceLanguageFindReplaceRequired, message: 'This field is required.' }]}>
                            <Select
                              style={{ width: '100%' }}
                              placeholder="Language"
                              options={allLanguages.map(x => ({ value: x.code, label: `${x.name} (${x.code})` }))}
                              showSearch
                              allowClear
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={3}>Replace</Col>
                        <Col span={21}>
                          <Form.Item name="sourceLanguages_replace" rules={[{ required: sourceLanguageFindReplaceRequired, message: 'This field is required.' }]}>
                            <Select
                              // mode="multiple"
                              allowClear
                              style={{ width: '100%' }}
                              placeholder="Languages"
                              options={allLanguages.map(x => ({ value: x.code, label: `${x.name} (${x.code})` }))}
                              filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                              tagRender={customTagRender}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Space>
                  )} */}
                  {sourceLanguagesRadioOpt === "overwrite" && (
                    <Row gutter={8}>
                      <Col span={3}>Overwrite</Col>
                      <Col span={21}>
                        <Form.Item name="sourceLanguages_overwrite" rules={[{ required: true, message: 'This field is required.' }]}>
                          <Select
                            // mode="multiple"
                            allowClear
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Languages"
                            options={allLanguages.map(x => ({ value: x.code, label: `${x.name} (${x.code})` }))}
                            filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            tagRender={customTagRender}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Card>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={4}>Active</Col>
              <Col span={20}>
                <Space style={{ width: "100%" }}>
                  <Switch
                    unCheckedChildren={(
                      <Space>
                        No Changes
                        <CloseOutlined />
                      </Space>
                    )}
                    checkedChildren={(
                      <Space>
                        <CheckOutlined />
                        Overwrite All
                      </Space>
                    )}
                    checked={overwriteActive}
                    onChange={setOverwriteActive}
                  />
                  {overwriteActive && (
                    <Form.Item name="active" valuePropName="checked" noStyle>
                      <Switch
                        unCheckedChildren={(
                          <Space>
                            Not Active
                            <CloseOutlined />
                          </Space>
                        )}
                        checkedChildren={(
                          <Space>
                            <CheckOutlined />
                            Active
                          </Space>
                        )}
                        defaultChecked
                      />
                    </Form.Item>
                  )}
                </Space>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={4}>Share Eligible</Col>
              <Col span={20}>
                <Space style={{ width: "100%" }}>
                  <Switch
                    unCheckedChildren={(
                      <Space>
                        No Changes
                        <CloseOutlined />
                      </Space>
                    )}
                    checkedChildren={(
                      <Space>
                        <CheckOutlined />
                        Overwrite All
                      </Space>
                    )}
                    checked={overwriteEligible}
                    onChange={setOverwriteEligible}
                  />
                  {overwriteEligible && (
                    <Form.Item name="eligible" valuePropName="checked" noStyle>
                      <Switch
                        unCheckedChildren={(
                          <Space>
                            Not Eligible
                            <CloseOutlined />
                          </Space>
                        )}
                        checkedChildren={(
                          <Space>
                            <CheckOutlined />
                            Eligible
                          </Space>
                        )}
                        defaultChecked
                      />
                    </Form.Item>
                  )}
                </Space>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={4}>Receive Sharing Notifications</Col>
              <Col span={20}>
                <Space style={{ width: "100%" }}>
                  <Switch
                    unCheckedChildren={(
                      <Space>
                        No Changes
                        <CloseOutlined />
                      </Space>
                    )}
                    checkedChildren={(
                      <Space>
                        <CheckOutlined />
                        Overwrite All
                      </Space>
                    )}
                    checked={overwriteShareNotifications}
                    onChange={setOverwriteShareNotifications}
                  />
                  {overwriteShareNotifications && (
                    <Form.Item name="shareNotifications" valuePropName="checked" noStyle>
                      <Switch
                        unCheckedChildren={(
                          <Space>
                            No Share Notifications
                            <CloseOutlined />
                          </Space>
                        )}
                        checkedChildren={(
                          <Space>
                            <CheckOutlined />
                            Receive Share Notifications
                          </Space>
                        )}
                        defaultChecked
                      />
                    </Form.Item>
                  )}
                </Space>
              </Col>
            </Row>
          </Space>
          {selectProductAreaCode && (
            <Modal
              title="Select Products"
              footer={null}
              open={true}
              onCancel={() => { setSelectProductAreaCode(null); }}
            >
              <Row gutter={8}>
                <Col span={20}>
                  <Form.Item name="productArea" label="Product Area">
                    <Select
                      placeholder="Product Area"
                      style={{ width: '100%' }}
                      options={allProductAreas}
                      showSearch
                      filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Button type="primary" onClick={addProductsToSelect}>Select</Button>
                </Col>
              </Row>
            </Modal>
          )}
        </Form>
      </div>
    </Modal>
  );
};

BulkEdit.propTypes = {
  styles: PropTypes.object,
  accessToken: PropTypes.string,
  config: PropTypes.object,
  setShowBulkEdit: PropTypes.func,
  allComponentTypes: PropTypes.array,
  allProducts: PropTypes.array,
  allProjectRoles: PropTypes.array,
  allLanguages: PropTypes.array,
  query: PropTypes.object,
  setRefreshLinguists: PropTypes.func,
};

export default BulkEdit;