import React, { useEffect, useState, useCallback } from "react";
import { Button, Popover, Checkbox, Input } from "antd";
import {
  DownOutlined,
  ExclamationCircleFilled,
  SortAscendingOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";

import "./finitelistfilter.scss";

import type { CheckboxValueType } from "antd/es/checkbox/Group";

import { sorter } from "../utils/helpers";

export interface FiniteListItem {
  key: string | number;
  title: string;
  value: any;
}

export interface CheckboxListItem {
  index: number;
  key: string | number;
  label: string;
  value: any;
}

export interface FiniteListProps {
  data: FiniteListItem[];
  onChange?: any;
  title?: string;
  value?: (string | number | boolean)[];
  name: string;
  loading?: boolean;
  errored?: boolean;
  onRetry?: () => void;
  multi?: boolean;
  forceOpen?: boolean;
  allowSort?: boolean; // Allow users to sort - sorting button will be visible
  alphabeticallySort?: boolean; // Sorting order: true - alphabetically(default), false - order of how they were passed.
  placeholder?:string;
  width?: number; // Set popup width, default set to 300
}

export const FiniteListFilter = ({
  data,
  onChange,
  value,
  name,
  title,
  onRetry,
  multi,
  loading = false,
  errored = false,
  forceOpen = false,
  allowSort = false,
  alphabeticallySort = true,
  width = 300
}: FiniteListProps) => {
  const [open, setOpen] = useState(false),
    placeholder = "Select",
    [displayedText, setDisplayedText] = useState<any>(placeholder),
    [selected, setSelected] = useState<CheckboxListItem[]>([]),
    [available, setAvailable] = useState<CheckboxListItem[]>([]);

  const [isSearching, setIsSearching] = useState<boolean>(false),
    [searchingList, setSearchingList] = useState<CheckboxListItem[]>([]),
    [searchValue, setSearchValue] = useState<string>("");

  const [alphabeticallySorting, setAlphabeticallySorting] =
    useState<boolean>(alphabeticallySort);

  const [, updateState] = useState(),
    forceUpdate = useCallback(() => updateState({} as any), []);

  const [loadedData, setLoadedData] = useState<boolean>(false);

  const { Search } = Input;

  const sortByLabel = (array: CheckboxListItem[]) => {
    return array?.sort((a: CheckboxListItem, b: CheckboxListItem) =>
      sorter(a.label, b.label)
    );
  };

  const sortByIndex = (array: CheckboxListItem[]) => {
    return array?.sort((a: CheckboxListItem, b: CheckboxListItem) =>
      sorter(a.index, b.index)
    );
  };

  const setDefaultLists = useCallback(() => {
    const defaultAvailable: any = [],
      defaultSelected: any = [];

    data.forEach((s, i) => {
      if (value?.includes(s.value)) {
        defaultSelected.push({
          index: i,
          key: s.key ? s.key : i,
          label: s.title,
          value: s.value === null ? "null" : s.value,
        });
      } else {
        defaultAvailable.push({
          index: i,
          key: s.key ? s.key : i,
          label: s.title,
          value: s.value === null ? "null" : s.value,
        });
      }
    });

    setSelected(
      alphabeticallySort ? sortByLabel(defaultSelected) : defaultSelected
    );

    setAvailable(
      alphabeticallySort ? sortByLabel(defaultAvailable) : defaultAvailable
    );
  }, [alphabeticallySort, data, value]);

  useEffect(() => {
    if (!loadedData && data) {
      setDefaultLists();
      setLoadedData(true);
    }
  }, [setDefaultLists, data, loadedData]);

  useEffect(() => {
    if (loadedData && allowSort) {
      if (alphabeticallySorting) {
        setSelected(sortByLabel(selected));
        setAvailable(sortByLabel(available));
        setSearchingList(sortByLabel(searchingList));

        forceUpdate();
      } else {
        setSelected(sortByIndex(selected));
        setAvailable(sortByIndex(available));
        setSearchingList(sortByIndex(searchingList));

        forceUpdate();
      }
    }
  }, [
    loadedData,
    allowSort,
    alphabeticallySorting,
    selected,
    available,
    searchingList,
    forceUpdate,
  ]);

  useEffect(() => {
    if (!Array.isArray(value) || value.length === 0) {
      setDisplayedText(placeholder);
    } else {
      let text = "";

      if (alphabeticallySorting) {
        text = value!
          .map((item) => {
            return data.filter((dataItem) => {
              if (dataItem.value === item) {
                return true;
              }
              return false;
            })[0]?.title;
          })
          .sort((a: any, b: any) => sorter(a, b))
          .join(", ");
      } else {
        text = value!
          .map((item) => {
            return data.filter((dataItem) => {
              if (dataItem.value === item) {
                return true;
              }
              return false;
            })[0]?.title;
          })
          .join(", ");
      }

      setDisplayedText(text);
    }
  }, [value, data, alphabeticallySorting]);

  const handleSearch = () => {
    let result = {
      target: {
        name: name,
        value: Array.isArray(selected)
          ? selected.map((item: CheckboxListItem) =>
              item.value === "null" ? null : item.value
            )
          : [selected],
      },
    };

    setOpen(false);
    onChange(result);
    setIsSearching(false);
    setSearchingList([]);
    setSearchValue("");
    setLoadedData(false);
  };

  const handleClear = () => {
    setAvailable([...available, ...selected]);
    setSelected([]);
    if (isSearching) {
      setIsSearching(false);
      setSearchingList([...available]);
      setSearchValue("");
    }

    setDisplayedText(placeholder);
    setOpen(false);
    return onChange({
      target: {
        name: name,
        value: "",
      },
    });
  };

  const handlePopoverVisibleChange = () => {
    setDefaultLists();
    setLoadedData(false);
    setOpen((prev) => !prev);
  };

  const handleSelectAll = (e: any) => {
    e.preventDefault();

    if (isSearching) {
      setSelected([...selected, ...searchingList]);
      setSearchingList([]);
    } else {
      setSelected([...selected, ...available]);
      setAvailable([]);
    }
  };

  const handleInvertSelection = (e: any) => {
    e.preventDefault();

    if (isSearching) {
      setAvailable([
        ...available.filter(
          (item) => !searchingList.find((s) => s.value === item.value)
        ),
        ...selected,
      ]);
      setSearchingList(selected);
      setSelected(searchingList);
    } else {
      setAvailable(selected);
      setSelected(available);
    }
  };

  const onChangeSelected = (checkedValues: CheckboxValueType[]) => {
    const newAvailable = selected.filter(
      (item: CheckboxListItem) => !checkedValues.includes(item.value)
    );
    setAvailable([...available, newAvailable[0]]);

    setSelected(
      selected.filter((item: CheckboxListItem) =>
        checkedValues.includes(item.value)
      )
    );

    if (isSearching) {
      const newSearchingAvailable = selected.filter(
        (item: CheckboxListItem) => !checkedValues.includes(item.value)
      );

      setSearchingList(
        [...searchingList, newSearchingAvailable[0]].filter(
          (item: CheckboxListItem) =>
            item?.label
              ?.toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())
        )
      );
    }
  };

  const onChangeAvailable = (checkedValues: CheckboxValueType[]) => {
    let option = available.find(
      (a: CheckboxListItem) => a.value === checkedValues[0]
    );

    setSelected([option, ...selected] as any);

    setAvailable(
      available.filter(
        (item: CheckboxListItem) => item.value !== checkedValues[0]
      )
    );

    if (isSearching) {
      setSearchingList(
        searchingList.filter(
          (item: CheckboxListItem) => item.value !== checkedValues[0]
        )
      );
    }
  };

  const handleSearchInput = (value: string) => {
    setSearchValue(value);

    if (value.length) {
      setIsSearching(true);

      const newList = available.filter((item: CheckboxListItem) =>
        item?.label?.toString().toLowerCase().includes(value.toLowerCase())
      );

      setSearchingList(newList);
    } else {
      setIsSearching(false);
      setSearchingList([]);
    }
  };

  const content = (
    <div className="finiteFilter" style={{width: `${width}px`}}>
      <Search
        placeholder="Search"
        onChange={(e) => handleSearchInput(e.target.value)}
        allowClear
        value={searchValue}
      />
      <div className="select-wrap">
        <Button onClick={handleSelectAll} size="small">
          Select All
        </Button>

        <Button onClick={handleInvertSelection} size="small">
          Invert Selection
        </Button>
      </div>
      <div className="separator" />
      <div className="lists">
        <p className="list-title list-title__selected">
          {`Selected (${selected.length})`}
          {allowSort &&
            (alphabeticallySorting ? (
              <OrderedListOutlined
                className="sort-btn"
                title="Default sort"
                onClick={() => setAlphabeticallySorting(false)}
              />
            ) : (
              <SortAscendingOutlined
                className="sort-btn"
                title="Alphabetically sort"
                onClick={() => setAlphabeticallySorting(true)}
              />
            ))}
        </p>
        <Checkbox.Group
          options={selected}
          onChange={onChangeSelected}
          className="list list--selected"
          // @ts-ignore
          value={
            selected.length > 0
              ? selected.map((s: CheckboxListItem) => s?.value)
              : []
          }
        />
        <p className="list-title list-title__available">{`Available (${
          isSearching ? searchingList.length : available.length
        })`}</p>
        <Checkbox.Group
          options={isSearching ? searchingList : available}
          onChange={onChangeAvailable}
          className="list list--available"
          value={[]}
        />
      </div>
      <div className="separator" />
      <div className="bottom">
        <Button onClick={handleClear} danger size="small">
          Clear
        </Button>
        <Button onClick={handleSearch} type="primary" size="small">
          Search
        </Button>
      </div>
    </div>
  );

  const errorContent = (
    <div className="finiteFilter">
      <h3>An error has occurred fetching the data.</h3>
      {onRetry ? (
        <>
          <div className="separator" />
          <div className="bottom">
            <Button onClick={onRetry} type="primary" size="small">
              Retry?
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );

  if (forceOpen) {
    return (
      <div className="list-filter-wrap">
        <div className={"ant-popover-inner-content"}>{content}</div>
      </div>
    );
  }

  return (
    <div className={"finite-filter-wrap".concat(errored ? " errored" : "")}>
      <Popover
        placement="bottomLeft"
        content={errored ? errorContent : content}
        trigger="click"
        open={open}
        onOpenChange={handlePopoverVisibleChange}
      >
        <label className="filter-label">{title}</label>
        <Button
          loading={loading}
          className={"trigger-filter"}
          size="small"
          icon={errored ? <ExclamationCircleFilled /> : null}
        >
          {!loading &&
            (displayedText.length >= 25
              ? `${displayedText?.slice(0, 25)} ...`
              : displayedText)}{" "}
          <DownOutlined />
        </Button>
      </Popover>
    </div>
  );
};
