import { FunctionComponent, Component, ReactElement } from "react";
import { Button, Popover, Checkbox, Modal, Input } from "antd";

import type { CheckboxValueType } from "antd/es/checkbox/Group";

import { DownOutlined } from "@ant-design/icons";
import "./filtersbar.scss";

import FiltersLoader from "./FiltersLoader";

import { ChangeEventShim } from "../../Components";
import FormItem from "antd/es/form/FormItem";
import { SavedFilter } from "../Table/Table.abstract";

// interface IOptions {
//     [prop: string]: any;
// }

export interface Filter {
    id: string;
    value: any;
    title: string;
    name: string;
    active: boolean;
    component: FunctionComponent<any>;
    props: any[];
    placeholder?: string;
}

export interface OnSaveParams {
    title: string,
    filters: Filter[]
}

export interface FilterBarProps {
    filters: Filter[];
    onChange: (params: any) => any;
    onClearAll: (params?: any) => any;
    enabledSave?: boolean; // if is true we should pass onSave function too
    onSave?: (props: OnSaveParams) => Promise<any> | any;
    filterLoader?: typeof FiltersLoader;
    onLoadedFilterChange?: (filter: SavedFilter) => Promise<void> | void;
    onLoadedFilterDelete?: (id: string) => Promise<void> | void;
    onLoadedFilterUpdate?: (filter: SavedFilter) => Promise<void> | void;
    onLoadedFilterReset?: (id: string) => Promise<void> | void;
    onChangeMoreLess?: (list: CheckboxValueType[]) => Promise<void> | void;
    onApplyMoreLess?: (list: CheckboxValueType[]) => Promise<void> | void;
    savedFilters?: SavedFilter[];
    currentSavedFilter?: SavedFilter;
    appliedFilters?: any;
    title?: string;
    controlsEnabled: boolean;
    additionalButtons?: ReactElement[];
    loading?: boolean;
    filtersLoading?: boolean
}

export interface FilterBarState {
    open: boolean;
    selectedMoreOptions: CheckboxValueType[];
    onSaveModal: boolean;
    saveAsTitle: string;
    savingFilters: boolean;
}

interface IDropdown {
    label: string;
    value: string;
}

export class FiltersBar extends Component<FilterBarProps, FilterBarState> {

    constructor(props: FilterBarProps) {
        super(props);
        this.state = {
            open: false,
            selectedMoreOptions: props.filters.map(f => { return f.active ? f.name : undefined }).filter(f => f !== undefined) as string[],
            onSaveModal: false,
            saveAsTitle: "",
            savingFilters: false,
        }
    }

    componentDidUpdate(prevProps: Readonly<FilterBarProps>, prevState: Readonly<FilterBarState>, snapshot?: any) {
        if (
            prevProps.filters.filter((f) => f.active).length !== this.props.filters.filter((f) => f.active).length
            && this.props.filters.filter((f) => f.active).length === 0
        ) {
            this.setState({ open: false });
        }

        // MER-2578
        // if (prevProps.filters !== this.props.filters) {
        //     this.setState({selectedMoreOptions: this.props.filters.map(f => { return f.active ? f.name : undefined }).filter(f => f !== undefined) as string[],})
        // }
    }

    moreContent = () => {
        return (
            <div className="more-content-wrap">
                <div className="top">
                    <Checkbox.Group
                        options={
                            this.props.filters.map((f) => {
                                return { label: f.title, value: f.name, checked: f.active } as IDropdown;
                            })
                        }
                        onChange={(selectedMoreOptions) => {
                            this.props.onChangeMoreLess!(selectedMoreOptions)
                            this.setState({selectedMoreOptions})
                        }}
                        value={this.state.selectedMoreOptions}
                    />
                </div>
                <div className="separator" />
                <div className="bottom">
                    {this.state.selectedMoreOptions.length > 1 ? <Button onClick={this.uncheckAllMoreLess} type="link" size="small" className="uncheck-all">
                        Uncheck all
                    </Button> : <div></div>}
                    <Button onClick={this.applyFilter} type="primary" size="small">
                        Apply
                    </Button>
                </div>
            </div>
        )
    }


    uncheckAllMoreLess = () => {
        this.props.onChangeMoreLess!([])
        this.setState({selectedMoreOptions: []})
    }

    applyFilter = () => {
        const
            { selectedMoreOptions } = this.state;
        this.props.onApplyMoreLess!(selectedMoreOptions)
        this.setState({ open: false })
    }

    changeHandler = (e: ChangeEventShim) => {
        const { filters } = this.props;
        return this.props.onChange(filters.map((filter) => {
            if (e.target.name === filter.name) {
                return {
                    ...filter,
                    value: e.target.value
                }
            }
            return filter;
        }))
    }

    removeFilter = (filter: Filter) => {

        this.props.onChange(this.props.filters.map((f) => {
            if (f.name === filter.name) {
                return {
                    ...filter,
                    active: false
                }
            }
            return f
        }));
    }

    handleModalCancel = () => {
        const { onSaveModal } = this.state;
        this.setState({ saveAsTitle: "", onSaveModal: !onSaveModal })
    }

    render() {

        const
            filters = [];

        if (this.props.title) {
            filters.push(<h1>{this.props.title}</h1>)
        }

            return (
                <div className="filters"  id="filtersContainer">
                    <Modal
                        title="Save filter set"
                        open={this.state.onSaveModal}
                        onOk={async () => {
                            this.setState({ savingFilters: true })
                            await this.props.onSave!({ title: this.state.saveAsTitle, filters: this.props.filters })
                            this.setState({ savingFilters: false, saveAsTitle: "", onSaveModal: false })
                        }}
                        onCancel={() => this.handleModalCancel()}
                        className="manage-columns-modal"
                        okButtonProps={{
                            disabled: this.state.saveAsTitle.length ? false : true,
                            loading: this.state.savingFilters
                        }}
                    >
                        <FormItem>
                            <Input
                                type={"text"}
                                onChange={(e) => {
                                    this.setState({ saveAsTitle: e.target.value })
                                }}
                                placeholder={"Name this filter set"}
                                value={this.state.saveAsTitle} />
                        </FormItem>

                    </Modal>

                    <div className={"filters-bar-wrap"}>
                        <div className="active-filters">
                            {this.props.title && <div className="filter filter__0"><h1>{this.props.title}</h1></div>}
                            {
                                this.props.onLoadedFilterChange &&
                                this.props.onLoadedFilterDelete &&
                                this.props.onSave && (
                                    <div className="filter filter__0">
                                        <FiltersLoader
                                            // @ts-ignore
                                            appliedFilters={this.props.appliedFilters as any}
                                            currentSavedFilter={this.props.currentSavedFilter}
                                            savedFilters={this.props.savedFilters || []}
                                            onChange={(filter: SavedFilter): void | Promise<void> => {
                                                this.props.onLoadedFilterChange!(filter);
                                            }}
                                            onDelete={(id: string): void | Promise<void> => {
                                                this.props.onLoadedFilterDelete!(id);
                                                this.props.onClearAll()
                                            }}
                                            onReset={(id: string): void | Promise<void> => {
                                                this.props.onLoadedFilterReset!(id);
                                            }}
                                            onUpdate={(filter: SavedFilter): void | Promise<void> => {
                                                this.props.onLoadedFilterUpdate!(filter)
                                            }}
                                            controlsEnabled={this.props.controlsEnabled}
                                            loading={this.props.filtersLoading || false}

                                        />
                                    </div>
                                )}
                            {
                                this.props.filters.filter((f: any) => f.active).map((filter: any, index: number) => {

                                    const
                                        { id, name, value, props, ...rest } = filter;

                                    return (
                                        <div key={index + 1} className={`filter filter__${index + 1}`}>
                                            <filter.component key={id} id={id} name={name} value={value}
                                                disabled={this.props.loading}
                                                onChange={this.changeHandler} {...props || {}} {...rest} />
                                        </div>
                                    )
                                })

                            }
                            <div className="filter filter__0">
                                <Popover
                                    placement="bottomRight"
                                    content={this.moreContent}
                                    trigger="click"
                                    className="more-filter"
                                    overlayClassName='more-filter-overlay'
                                    open={this.state.open}
                                    onOpenChange={(e) => this.setState({ open: e })}
                                >
                                    <Button
                                        className="more-trigger"
                                        size="small"
                                        onClick={() => this.setState({ open: !this.state.open })}
                                        disabled={this.props.loading}
                                    >
                                        More / Less <DownOutlined />
                                    </Button>
                                </Popover>
                            </div>
                            {this.props.onSave && (
                                <div className="filter filter__0">
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={() => this.setState({ onSaveModal: !this.state.onSaveModal })}
                                        disabled={this.props.loading || this.props.filters.filter(f => !!f.value).length === 0}
                                    >
                                        Save As
                                    </Button>
                                </div>
                            )}
                            <div className="filter filter__0">
                                <Button
                                    danger
                                    size="small"
                                    disabled={this.props.loading || this.props.filters.filter(f => !!f.value).length === 0 || !this.props.filters.filter((f) => f.active).length}
                                    onClick={() => {
                                        this.props.onClearAll();
                                    }}
                                >
                                    Clear All
                                </Button>
                            </div>
                            {
                                this.props.additionalButtons && this.props.additionalButtons.map(btn => {
                                    return <div key={btn.key} className="filter filter__0">{btn}</div>
                                })
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }

export default function filtersBar(props: FilterBarProps) {
    return <FiltersBar {...props} />
}
