import React from "react";
import { Row, Col, Typography } from "antd";
import Logo from "../stories/Loaders/Logo";
import LogoGIF from "../assets/images/loading.gif";

function LoadingStar(props) {
  const { Title } = Typography;
  return (
    <Row
      justify="center"
      style={{
        textAlign: "center",
        height: "calc(100vh - 162px)",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Col span={8}>
        {
          props.logo
            ? <Logo animate={true} />
            : <img
                src={LogoGIF}
                className="logo"
                alt="logo"
                style={{ minWidth: "25px", minHeight: "25px" }}
              />
        }
        {
          props.title && <Title level={5} style={{ fontWeight: "400", paddingTop: "10px" }}>
            {props.title}
          </Title>
        }
      </Col>
    </Row>
  );
}

export default LoadingStar;
