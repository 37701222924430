import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const EffectiveDateTooltip: React.FC = () => {
    return (
      <>
        Effective Date{" "}
        <Tooltip
          title={
            <div>
              <p style={{ marginBottom: "3px" }}>
                Rate will become effective from the date and time specified.
              </p>
              <p style={{ marginBottom: "3px" }}>
                Dates from the past are not allowed.
              </p>
              <p style={{ marginBottom: "10px" }}>
                Please select a future date and time.
              </p>
              <p style={{ marginBottom: "unset" }}>
                <span style={{ textDecoration: "underline" }}>Important:</span>{" "}
                All effective dates will be shown and set using UTC time zone.
              </p>
            </div>
          }
          placement="top"
          color={"var(--tertiary-color)"}
          overlayInnerStyle={{ width: "240px" }}
        >
          <InfoCircleOutlined
            style={{
              marginLeft: "5px",
              color: "var(--tertiary-color)",
              cursor: "help",
            }}
          />
        </Tooltip>
      </>
    );
  };

  export default EffectiveDateTooltip