import React from "react";

import { Button, Drawer, Form, Input, Switch } from "antd";
import {
  ReloadOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import UniversalButton from "../../../ui/UniversalButton";

const AddCostCode = ({ handlers, values }) => {
  const {
    showDrawer,
    closeDrawer,
    onFinishNewCostCode,
    handleChangeActive,
    handleRefresh,
  } = handlers;
  const { form, visibleDrawer, switchValue } = values;


  return (
    <>
      <UniversalButton
        clickMethod={showDrawer}
        text="New Cost Code"
        type="primary"
        icon={
          !visibleDrawer ? (
            <PlusCircleOutlined style={{ fontSize: "15px" }} />
          ) : (
            <MinusCircleOutlined style={{ fontSize: "15px" }} />
          )
        } 
      />
      <UniversalButton
        clickMethod={handleRefresh}
        text="Refresh"
        icon={<ReloadOutlined />}
      />
      <Drawer
        title="Add New Cost Code"
        placement="right"
        onClose={closeDrawer}
        open={visibleDrawer}
        width={500}
        closable={true}
        className="manage-pages-drawer"
        forceRender
      >
        <Form form={form} layout="horizontal" onFinish={onFinishNewCostCode} name="newCostCodeForm">
          <Form.Item
            name="codeValue"
            label="Cost Code"
            rules={[{ required: true, message: "Please type cost code title" }]}
          >
            <Input placeholder="Ex: GGL_CGLATO_038" />
          </Form.Item>
          <Form.Item label="Active">
            <Switch checked={switchValue} onChange={handleChangeActive} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save Cost Code
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default AddCostCode;
