import React from "react";

import { Collapse, Table, Button, Tooltip } from "antd";
import TableFooter from "../invoicingTable/TableFooter";
import { formatFilters, isObjectEmpty, sorter } from "../../../utils/helpers";
import { InfoCircleOutlined } from "@ant-design/icons";

import{ NumericFormat as NumberFormat }from "react-number-format";
import TimezoneConverter from "../../../timezone-converter";

const InvoicingTable = ({ handlers, values, tableData }) => {
  const { Panel } = Collapse;
  const {
    handleTableRowSelection,
    handleChangeDetailsTable,
    handleClearFilterSort,
  } = handlers;
  const {
    filterData,
    filterValues,
    sortData,
    totalTableProjects,
    selectedRowsID,
    isLoading,
    projectType
  } = values;

  // All table columns
  const tableColumns = [
    {
      title: "PLP Lang",
      dataIndex: "plpLang",
      key: "plpLang",
      width: 100,
      filteredValue: filterData.plpLang || null,
      // filters: formatFilters(filterValues.plpLang),
      onFilter: (value, record) => record.plpLang === value,
      sorter: (a, b) => sorter(a.plpLang, b.plpLang),
      sortOrder: sortData.columnKey === "plpLang" && sortData.order,
      fixed: "left",
      render: (text) => text,
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      filteredValue: filterData.service || null,
      // filters: formatFilters(filterValues.service),
      onFilter: (value, record) => record.service === value,
      sorter: (a, b) => sorter(a.service, b.service),
      sortOrder: sortData.columnKey === "service" && sortData.order,
      render: (text) => text,
      fixed: "left",
      width: 210,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 140,
      filteredValue: filterData.status || null,
      // filters: formatFilters(filterValues.status),
      onFilter: (value, record) => record.status === value,
      sorter: (a, b) => sorter(a.status, b.status),
      sortOrder: sortData.columnKey === "status" && sortData.order,
      render: (text, record) =>
        record.status === "invoiced" ? (
          <Tooltip
            title="Invoiced projects cannot be modified."
            placement="right"
            color={"var(--tertiary-color)"}
          >
            {text}
          </Tooltip>
        ) : (
          text
        ),
    },
    {
      title: "Received Date",
      dataIndex: "receivedDate",
      key: "receivedDate",
      sorter: (a, b) => sorter(a.receivedDate, b.receivedDate),
      sortOrder: sortData.columnKey === "receivedDate" && sortData.order,
      width: 160,
      render: (text, record) => <TimezoneConverter date={text} />,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      width: 160,
      key: "dueDate",
      sorter: (a, b) => sorter(a.dueDate, b.dueDate),
      sortOrder: sortData.columnKey === "dueDate" && sortData.order,
      render: (text, record) => {
        if (text) {
          return <TimezoneConverter date={text} />;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Var Status",
      dataIndex: "varStatus",
      key: "varStatus",
      width: 120,
      filteredValue: filterData.varStatus || null,
      filters: formatFilters(filterValues.varStatus),
      onFilter: (value, record) => record.varStatus === value,
      sorter: (a, b) => sorter(a.varStatus, b.varStatus),
      sortOrder: sortData.columnKey === "varStatus" && sortData.order,
      render: (text, record) => {
        if (record.updatingVarStatus) {
          return "updating";
        } else {
          if (
            Array.isArray(record.invoiceErrors) &&
            record.invoiceErrors.length > 0
          ) {
            return record.varStatus !== "ACCRUAL" &&
              record.varStatus !== "ESTIMATE" &&
              record.varStatus !== "INVOICE" ? (
              <Tooltip
                title={record.invoiceErrors.reduce((errorMessage, error) => {
                  return (errorMessage += error + "\r\n");
                }, "")}
                placement="right"
                color={"var(--red)"}
              >
                {text}
                <InfoCircleOutlined
                  style={{ color: "var(--red)", marginLeft: "3px" }}
                />
              </Tooltip>
            ) : (
              text
            );
          } else {
            return text;
          }
        }
      },
    },
    {
      title: "Received from GGL",
      dataIndex: "receivedGGL",
      key: "receivedGGL",
      filteredValue: filterData.receivedGGL || null,
      // filters: formatFilters(filterValues.receivedGGL),
      onFilter: (value, record) => record.receivedGGL === value,
      sorter: (a, b) => sorter(a.receivedGGL, b.receivedGGL),
      sortOrder: sortData.columnKey === "receivedGGL" && sortData.order,
      width: 160,
      render: (text, record) => <TimezoneConverter date={text} />,
    },
    {
      title: "WWC",
      dataIndex: "wwc",
      key: "wwc",
      sorter: (a, b) => sorter(a.wwc, b.wwc),
      sortOrder: sortData.columnKey === "wwc" && sortData.order,
      width: 60,
      render: (text, record) => {
        if (text) {
          return text;
        } else {
          return "0";
        }
      },
    },
    {
      title: "Service (h)",
      dataIndex: "serviceHour",
      key: "serviceHour",
      sorter: (a, b) => sorter(a.serviceHour, b.serviceHour),
      sortOrder: sortData.columnKey === "serviceHour" && sortData.order,
      width: 70,
      render: (text) => text,
    },
    {
      title: "DTP (h)",
      dataIndex: "dtpHour",
      key: "dtpHour",
      sorter: (a, b) => a.dtpHour - b.dtpHour,
      sortOrder: sortData.columnKey === "dtpHour" && sortData.order,
      width: 60,
      render: (text) => text,
    },
    {
      title: "Eng (h)",
      dataIndex: "engHour",
      key: "engHour",
      sorter: (a, b) => sorter(a.engHour, b.engHour),
      sortOrder: sortData.columnKey === "engHour" && sortData.order,
      width: 60,
      render: (text) => text,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      sorter: (a, b) => sorter(a.rate, b.rate),
      sortOrder: sortData.columnKey === "rate" && sortData.order,
      width: 60,
      render: (text) => text,
    },
    {
      title: "PM Surcharge",
      dataIndex: "pmSurcharge",
      key: "pmSurcharge",
      sorter: (a, b) => sorter(a.pmSurcharge, b.pmSurcharge),
      sortOrder: sortData.columnKey === "pmSurcharge" && sortData.order,
      width: 100,
      render: (text) => {
        return (
          <NumberFormat
            value={text}
            decimalScale={4}
            fixedDecimalScale={true}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        );
      },
    },
    {
      title: "Surcharge %",
      dataIndex: "weekendSurcharge",
      key: "weekendSurcharge",
      sorter: (a, b) => sorter(a.weekendSurcharge, b.weekendSurcharge),
      sortOrder: sortData.columnKey === "weekendSurcharge" && sortData.order,
      width: 100,
      render: (text, record) => {
        if (record.weekendSurchargeApplies) {
          return `${text} %`;
        } else {
          return "N/A";
        }
      },
    },
    {
      title: "Invoice Amount",
      dataIndex: "totalCharge",
      key: "totalCharge",
      sorter: (a, b) => sorter(a.totalCharge, b.totalCharge),
      sortOrder: sortData.columnKey === "totalCharge" && sortData.order,
      width: 120,
      render: (text) => {
        return (
          <NumberFormat
            value={text}
            decimalScale={4}
            fixedDecimalScale={true}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        );
      },
    },

    //tableData?.[0]?.startingNumberOfIntents ?
    projectType === "QV" ?
    {
      title: "Starting Intents",
      dataIndex: "startingNumberOfIntents",
      key: "startingNumberOfIntents",
      sorter: (a, b) => sorter(a.startingNumberOfIntents, b.startingNumberOfIntents),
      sortOrder: sortData.columnKey === "startingNumberOfIntents" && sortData.order,
      width: 60,
      render: (text, record) => {
        if (text) {
          return text;
        } else {
          return "0";
        }
      },
    }
    :
    {},
    projectType === "QV" ?
    {
      title: "Final Intents",
      dataIndex: "finalNumberOfIntents",
      key: "finalNumberOfIntents",
      sorter: (a, b) => sorter(a.finalNumberOfIntents, b.finalNumberOfIntents),
      sortOrder: sortData.columnKey === "finalNumberOfIntents" && sortData.order,
      width: 60,
      render: (text, record) => {
        if (text) {
          return text;
        } else {
          return "0";
        }
      },
    }
    :
    {}
  ];

  return (
   <Collapse defaultActiveKey={["1"]} className='collapsable-table' style={{ margin: "0 15px 20px 15px" }}>
   <Panel
        header="Details"
        key="1"
        className="invoicing-table-wrap"
        extra={`Total: ${totalTableProjects ? totalTableProjects : '0'}`}
      >
        <div className="table-header">
          <Button
            disabled={!sortData.column && isObjectEmpty(filterData)}
            danger
            onClick={handleClearFilterSort}
            style={{ marginRight: "16px" }}
          >
            Clear filters and sorting
          </Button>
        </div>
        <Table
          columns={tableColumns}
          dataSource={tableData}
          className={"invoicing-table"}
          pagination={false}
          loading={isLoading}
          rowSelection={{
            selectedRowKeys: selectedRowsID,
            ...handleTableRowSelection,
          }}
          onChange={handleChangeDetailsTable}
          rowClassName={(record) =>
            record.status === "invoiced" && "disabled-row"
          }
          scroll={{ x: 1500 }}
          sticky={{ offsetHeader: 64 }}
          footer={() => <TableFooter />}
        />
      </Panel>
    </Collapse>
  );
};

export default InvoicingTable;
