import React from "react";

import { Button, Drawer, Form, Input, Switch, Select } from "antd";
import {
  ReloadOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import UniversalButton from "../../../ui/UniversalButton";

const AddProduct = ({ handlers, values }) => {
  const { Option } = Select;
  const {
    showDrawer,
    closeDrawer,
    onFinishNewProduct,
    handleChangeActive,
    handleChangeVariation,
    handleChangeNonExtendable,
    handleRefresh,
  } = handlers;
  const { form, visibleDrawer, dropdownOptions, nonExtendableValue, queryVariationValue, switchValue } = values;

  const productAreaOptions =
    dropdownOptions &&
    dropdownOptions?.productArea.map((item) => (
      <Option value={item._id} key={item._id}>
        {item.title}
      </Option>
    ));

  const costCodeOptions =
    dropdownOptions &&
    dropdownOptions?.costCode.map((item) => (
      <Option value={item._id} key={item._id}>
        {item.code}
      </Option>
    ));

  return (
    <>
      <UniversalButton
        clickMethod={showDrawer}
        text="New Product"
        type="primary"
        icon={
          !visibleDrawer ? (
            <PlusCircleOutlined style={{ fontSize: "15px" }} />
          ) : (
            <MinusCircleOutlined style={{ fontSize: "15px" }} />
          )
        }
      />
      <UniversalButton
        clickMethod={handleRefresh}
        text="Refresh"
        icon={<ReloadOutlined />}
      />
      <Drawer
        title="Add New Product"
        placement="right"
        onClose={closeDrawer}
        open={visibleDrawer}
        width={500}
        closable={true}
        className="manage-pages-drawer"
        forceRender
      >
        <Form form={form} layout="vertical" onFinish={onFinishNewProduct} name="newProductForm">
          <Form.Item
            name="productValue"
            label="Product Title"
            rules={[{ required: true, message: "Please type product title" }]}
          >
            <Input placeholder="Ex: Ad Hoc" />
          </Form.Item>
          <Form.Item
            name="productAreaValue"
            label="Product Area"
            rules={[
              { required: true, message: "Please select the product area" },
            ]}
          >
            <Select>{productAreaOptions}</Select>
          </Form.Item>
          <Form.Item
            name="costCodeValue"
            label="Cost Code"
            rules={[{ required: true, message: "Please select the cost code" }]}
          >
            <Select>{costCodeOptions}</Select>
          </Form.Item>
          <Form.Item
            name="vendorValue"
            label="Vendor"
            rules={[{ required: true, message: "Please select the vendor" }]}
          >
            <Select>
              <Option value="sdl">Sdl</Option>
              <Option value="moravia">Moravia</Option>
            </Select>
          </Form.Item>
          <Form.Item name="descValue" label="Description">
            <Input.TextArea placeholder="Reason for changing product status" />
          </Form.Item>
          <Form.Item label="Non extendable without Fast Approval">
            <Switch checked={nonExtendableValue} onChange={handleChangeNonExtendable} />
          </Form.Item>
          <Form.Item label="Query Variation">
            <Switch checked={queryVariationValue} onChange={handleChangeVariation} />
          </Form.Item>
          <Form.Item label="Active">
            <Switch checked={switchValue} onChange={handleChangeActive} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save Product
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default AddProduct;
