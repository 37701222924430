import axios from 'axios';
import { BASE_URL } from "../../../utils/variables";

export const getLanguages = async (accessToken) => {
  return await axios.get(
    `${BASE_URL}/languageCode`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};
