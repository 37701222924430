import React from "react";
import { DownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Dropdown, Space, Button, Tooltip } from "antd";
import type { MenuProps } from "antd";

interface DropdownProps {
  buttonLabel: string;
  onSelect: (key: string) => void;
  disabledOptions: string[];
}

const InfoTooltip = () => {
  return (
    <Tooltip
      title="Select 1 row to enable this option"
      getPopupContainer={(trigger) => trigger.parentElement!}
    >
      <InfoCircleOutlined
        style={{
          color: "var(--primary-color)",
          cursor: "pointer",
          marginLeft: "8px",
        }}
      />
    </Tooltip>
  );
};

const SesameDropdown: React.FC<DropdownProps> = ({
  buttonLabel,
  onSelect,
  disabledOptions,
}) => {
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Remove Exception Group",
      disabled: disabledOptions.includes("1"),
    },
    {
      key: "2",
      label: (
        <span>
          Create new account <InfoTooltip />
        </span>
      ),
      disabled: disabledOptions.includes("2"),
    },
    {
      key: "3",
      label: "Reset Password",
      disabled: disabledOptions.includes("3"),
    },
    {
      key: "4",
      label: (
        <span>
          Account Suspension <InfoTooltip />
        </span>
      ),
      children: [
        {
          key: "4-1",
          label: "Suspend account",
          disabled: disabledOptions.includes("4-1"),
        },
        {
          key: "4-2",
          label: "Activate account",
          disabled: disabledOptions.includes("4-2"),
        },
      ],
      disabled: disabledOptions.includes("4"),
    },
  ];

  const handleClick: MenuProps["onClick"] = (e) => {
    onSelect(e.key);
  };

  return (
    <Dropdown
      menu={{ items, onClick: handleClick }}
      overlayStyle={{ zIndex: 999999999 }}
    >
      <Button>
        <Space>
          {buttonLabel}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default SesameDropdown;
