import { useEffect, useState, useCallback } from "react";
import { useMercuryContext } from "../../../user-context";
import { postGExtensionRequests } from "../../../components/extensions/api/extensionRequest";
import ExtProjectTable from "../../../components/extensions/components/ListExtByProject/ExtProjectTable/ExtProjectTable";
import ExtensionDetailsModal from "../../../components/extensions/components/ExtensionDetailsModal/ExtensionDetailsModal";

export const PlpExtensions = (props: any) => {
  const { projectId, filters } = props;
  const [reqExtElements, setReqExtElements] = useState([]);
  const initialFilterState = { shortId: [projectId], ...filters };
  const [filtersValues, setFiltersValues] = useState(initialFilterState);
  const [stateRequest, setStateRequest] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalExtensionId, setModalExtensionId] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 100;
  const [arrTokens, setArrTokens] = useState<any>([]);
  const { accessToken } = useMercuryContext();

  const handleGetExtTable = useCallback(async () => {
    try {
      setStateRequest(true);
      const nextToken = arrTokens[currentPage - 2];
      postGExtensionRequests(
        filtersValues,
        currentPage,
        projectsPerPage,
        nextToken,
        accessToken
        // setStateRequest,
        // reqExtElements,
      ).then((res: any) => {
        setReqExtElements(res.extensionRequests);
        if (currentPage > arrTokens.length) {
          const arrTokensNew = [...arrTokens, res.nextToken];
          setArrTokens(arrTokensNew);
        }
        setStateRequest(false);
      });
    } catch (error) {
      setStateRequest(false);
      console.error(error);
    }
  }, [accessToken, arrTokens, currentPage, filtersValues]);

  useEffect(() => {
    handleGetExtTable();
  }, []);

  const handleExtensionIdClick = (value: any) => {
    setModalExtensionId(value);
    setIsModalVisible(true);
  };
  return (
    <div className="dashboard-extensions list-request">
      <ExtProjectTable
        reqExtElements={reqExtElements}
        stateRequest={stateRequest}
        filtersValues={filtersValues}
        setFiltersValues={setFiltersValues}
        handleExtensionIdClick={handleExtensionIdClick}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        arrTokens={arrTokens}
        projectsPerPage={projectsPerPage}
        hideLanguageCode
      />

      <ExtensionDetailsModal
        modalExtensionId={modalExtensionId}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </div>
  );
};
