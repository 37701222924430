import React from "react";
import {FiniteListFilter, FiniteListProps} from "../stories/FiniteListFilter/FiniteListFilter";

const VarStatusFilter = (props: FiniteListProps) => {
    const options = [
        { title: "NEW", value: "NEW" },
        { title: "ESTIMATE", value: "ESTIMATE" },
        { title: "ESTIMATE FAILED", value: "ESTIMATE_FAILED" },
        { title: "ACCRUAL", value: "ACCRUAL"},
        { title: "ACCRUAL FAILED", value: "ACCRUAL_FAILED" },
        { title: "INVOICE", value: "INVOICE" },
        { title: "INVOICE FAILED", value: "INVOICE_FAILED" }
    ];

    return <FiniteListFilter
        {...props}
        data={options.map( ( option, i ) =>{
            return {
                key: i,
                title: option.title,
                value: option.value
            }
        })} />
}

export default VarStatusFilter