import React, { useState } from "react";
import Total from "../../../ui/Total";
import { sorter } from "../../../utils/helpers";

import { Table, Typography, Skeleton, Input, Spin, Row } from "antd";

import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const ExtensionJustificationTable = ({ values, handlers }) => {
  const { justifications, sortedInfo, editing, total } = values;
  const { handleChangeTable, handleClickEdit, handleClickDelete } = handlers;

  const { Link, Title } = Typography;
  const { Search } = Input;

  const [filterTable, setFilterTable] = useState(null);

  const data =
    justifications &&
    justifications.map((item, index) => {
      return {
        key: index,
        id: item._id,
        googleType: item.googleType,
        kind: item.kind,
        limit: item.limit,
        frequency: item.frequency,
        description: item.description,
      };
    });

  const columns = [
    {
      title: "Justification Name",
      dataIndex: "googleType",
      sorter: (a, b) => sorter(a.googleType, b.googleType),
      sortOrder: sortedInfo.field === "googleType" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.googleType === editing.editingRow.googleType ? (
            text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text
        ),
    },
    {
      title: "Kind",
      dataIndex: "kind",
      width: 150,
      sorter: (a, b) => sorter(a.kind, b.kind),
      sortOrder: sortedInfo.field === "kind" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.googleType === editing.editingRow.googleType ? (
            text === 'fastApproval' ? `Fast approval` : `Additional review`
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text === 'fastApproval' ? `Fast approval` : `Additional review`
        ),
    },
    {
      title: "Limit",
      dataIndex: "limit",
      width: 100,
      sorter: (a, b) => sorter(a.limit, b.limit),
      sortOrder: sortedInfo.field === "limit" && sortedInfo.limit,
      render: (text, record) =>
        editing.isEditing ? (
          record.googleType === editing.editingRow.googleType ? (
            text === 0 ? `Anytime` : `${text} hrs`
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text === 0 ? `Anytime` : `${text} hrs`
        ),
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      sorter: (a, b) => sorter(a.frequency, b.frequency),
      sortOrder: sortedInfo.field === "frequency" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.googleType === editing.editingRow.googleType ? (
            text === 0 ? `N/A` : text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text === 0 ? `N/A` : text
        ),
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => sorter(a.description, b.description),
      sortOrder: sortedInfo.field === "description" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.googleType === editing.editingRow.googleType ? (
            text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text
        ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      width: 100,
      render: (text, record) =>
        editing.isEditing && record.googleType !== editing.editingRow.googleType ? (
          <Skeleton active size="small" title={true} paragraph={false} />
        ) : (
          <Link
            onClick={() => {
              handleClickEdit(record);
            }}
            style={{
              marginRight: "10px",
            }}
          >
            <EditOutlined /> Edit
          </Link>
        ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      width: 100,
      render: (text, record) =>
        editing.isDeleting && record.googleType !== editing.editingRow.googleType ? (
          <Skeleton active size="small" title={true} paragraph={false} />
        ) : (
          <Link
            onClick={() => {
              handleClickDelete(record);
            }}
            style={{
              marginRight: "10px",
            }}
          >
            <DeleteOutlined /> Delete
          </Link>
        ),
    },
  ];

  const handleSearch = (value) => {
    const filterTable = data.filter((item) =>
      item.googleType.toString().toLowerCase().includes(value.toLowerCase())
    );

    setFilterTable(filterTable);
  };

  return (
    <>
      <Search
        style={{ width: "300px", margin: "0 0 10px 0" }}
        placeholder="Search by name"
        onSearch={handleSearch}
        allowClear
        enterButton
      />
      <Row className="title-wrap">
        <Title level={5} style={{ margin: "0" }}>
          EXTENSION JUSTIFICATIONS LIST
          {editing.isEditing ? (
            <Spin size="small" style={{ marginLeft: "10px" }} />
          ) : null}
        </Title>
        <Total number={total} text="Justifications" />
      </Row>

      <Table
        columns={columns}
        dataSource={filterTable == null ? data : filterTable}
        size="small"
        className="manage-page-table"
        style={{ width: "100%"}}
        onChange={handleChangeTable}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: 20,
        }}
        scroll={{
          x: "100%",
        }}
      />
    </>
  );
};

export default ExtensionJustificationTable;
