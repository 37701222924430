import { FunctionComponent } from "react";
import { Modal, Table } from "antd";
import type { TableColumnsType } from "antd";

import "./autoUploadModal.scss";

interface IMatchedWithOtherXliff {
  uploadedFilename: string;
  matchedXliffFiles: any;
}

interface IFile {
  key: string;
  lastModified: Date;
  lastModifiedDate: Date;
  matchedTo: any;
  matchedWithOtherXliff?: IMatchedWithOtherXliff;
  name: string;
  originFileContent: any;
  previouslyMatchedTo: any;
  selectedMatchToKey: string;
  status: string;
  tempFileType: string;
  tempReference: boolean;
  uid: string;
}

interface IProps {
  isOpen: boolean;
  okHandle: (params: any) => any;
  cancelHandle: (params: any) => any;
  files: IFile[];
}

interface DataType {
  key: React.Key;
  displayName: string;
}

export const AutoUploadModal: FunctionComponent<IProps> = ({
  isOpen,
  okHandle,
  cancelHandle,
  files,
}) => {

  const autoUploadColumns: TableColumnsType<DataType> = [
    {
      dataIndex: "displayName",
      key: "key",
    },
  ];

  return (
    <Modal
      title="We have identified other XLIFF files"
      open={isOpen}
      onOk={okHandle}
      onCancel={cancelHandle}
      cancelText={"No"}
      okText={"Yes"}
      wrapClassName="auto-upload-modal"
      width={600}
      maskClosable={false}
    >
      <p className="subtitle">All uploaded files have been matched.</p>
      <div className="all-matches">
        {files.map((file: any) => {
          const matchedFile = file.matchedWithOtherXliff;

          return (
            <div
              className="single-match"
              key={file.key}
            >
              <p className="single-match__filename">
                <span>Uploaded file:</span>
                {matchedFile.uploadedFilename}
              </p>
              <p className="single-match__filename">
                <span>Additional XLIFF files:</span>
              </p>
              <div className="single-match__table">
                <Table
                  dataSource={matchedFile?.matchedXliffFiles}
                  columns={autoUploadColumns}
                  size="small"
                  pagination={false}
                />
              </div>
            </div>
          );
        })}
      </div>
      <p className="question">
        Do you want to automatically upload the file for the other XLIFF files?
      </p>
    </Modal>
  );
};
