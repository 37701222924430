import React, { useState, useEffect } from "react";
import momentTZ from "moment-timezone";
import { useMercuryContext, useMercuryDispatch } from "../../user-context";

import { failedNotification } from "../../utils/notifications";

import { Select } from "antd";

import { GlobalOutlined } from "@ant-design/icons";
import './SetTimezone.scss'


const SetTimezone = () => {
  const mercuryDispatch = useMercuryDispatch();
  const { Option } = Select;

  const [isActive, setIsActive] = useState(false);
  // all timezones for dropdown options
  const [timezones, setTimezones] = useState([]);

  // manage current timezone passed from useContext module
  const { timezone } = useMercuryContext();

  // get all timezones and format them
  async function getTimezones() {
    let timezones;
    try {
      timezones = await momentTZ.tz.names();
    } catch (e) {
      console.error(e);
      failedNotification("Error", "Failed to retrieve timezones.");
    }

    let formatTimezones = [];

    timezones.length > 0 &&
      timezones.map((item) => {
        let newTimezone = {
          value: item,
          time: momentTZ.tz(item).format("Z"),
        };
        formatTimezones.push(newTimezone);
        return newTimezone;
      });

    // sort all timezones
    const sortedTimezones = formatTimezones.sort((a, b) => {
      return (
        parseFloat(a.time.replace(":", ".")) -
        parseFloat(b.time.replace(":", "."))
      );
    });

    // save in state the final version of all timezones
    setTimezones(sortedTimezones);
  }

  useEffect(() => {
    getTimezones();
  }, [isActive]);

  const timezoneMenu = timezones.map((item, index) => (
    <Option value={item.value} key={index}>
      {`(GMT ${item.time}), ${item.value.replaceAll("_", " ")}`}
    </Option>
  ));

  const handleChange = (item) => {
    mercuryDispatch({ type: "UPDATE_TIMEZONE", payload: item });
    localStorage.setItem(`timezone`, item);
    setIsActive(false);
  };

  return (
    <div
      className={
        isActive
          ? "timezone-menu-wrap timezone-menu-active"
          : "timezone-menu-wrap"
      }
    >
      <GlobalOutlined />
      <Select
        value={timezone}
        onChange={handleChange}
        onDropdownVisibleChange={() => setIsActive(!isActive)}
        onBlur={() => setIsActive(false)}
        showSearch={true}
        bordered={false}
        style={{ width: "100%", caretColor: !isActive && "transparent" }}
        className="select-timezone-dropdown"
        dropdownStyle={{ minWidth: "240px" }}
        dropdownAlign={{ offset: [-18, 4] }}
      >
        {timezoneMenu}
      </Select>
    </div>
  );
};

export default SetTimezone;
