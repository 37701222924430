import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import AccrualSubmissionModal from "./AccrualSubmissionModal";
import { useMercuryContext } from "../../user-context";

export default function AccrualButton({ selectedRows, reload, page }: any) {

    const
        context = useMercuryContext(),
        rows = selectedRows || [],
        states = ["ESTIMATE", "ACCRUAL", "ACCRUAL_FAILED"],
        allowedSubmitCriteria = (element: any) => states.indexOf(element.varStatus) !== -1,
        allowSubmit = rows.length > 0 && rows.filter((e: any) => e.deleted !== true).filter(allowedSubmitCriteria).length,
        [loading, setLoading] = useState(false),
        [invoiceSubmission, setInvoiceSubmission] = useState(false);

    const handleOk = () => {
        setInvoiceSubmission(true)
    };

    return (
      <>
        {invoiceSubmission && (
          <AccrualSubmissionModal
            //@ts-ignore
            context={context}
            show={invoiceSubmission}
            rows={rows}
            loadingHandle={setLoading}
            close={() => {
              setInvoiceSubmission(false);
              reload.call(page);
            }}
          />
        )}
        <Tooltip
          title={!allowSubmit ? "Select at least 1 item" : ""}
          placement="bottom"
          color={"var(--tertiary-color)"}
        >
          <Button
            onClick={handleOk}
            loading={loading}
            disabled={!allowSubmit}
            type="primary"
          >
            Accrue
          </Button>
        </Tooltip>
      </>
    );

}
