import React, {useCallback, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {Button, Layout, Menu, Popconfirm, Table, Typography} from "antd";
import {useMercuryContext} from "../../user-context";
import {Link, Route, Routes, useNavigate } from "react-router-dom";

import LoadingStar from "../../ui/LoadingStar";
import TimezoneConverter from "../../timezone-converter";
import {BASE_URL} from "../../utils/variables";
import FormModal from "./Modal";
import {useLocation} from "react-router-dom";
import {formatFilters, sorter} from '../../utils/helpers'
import moment from "moment";

import "./Reports.scss"
import Report from "./Report";
import {failedNotification, successfulNotification,} from "../../utils/notifications";
import {DeleteOutlined, DownloadOutlined, EditOutlined} from "@ant-design/icons";

import EditDescriptionForm from "./Components/EditDescriptionForm";

const initialEditingValue = { isEditing: false, editingRow: "", expanded: false };

const { Paragraph } = Typography;

const {Sider, Content} = Layout;

const componentsEndpoint = `${BASE_URL}/projects/componentType/list`;
const urgencyEndpoint = `${BASE_URL}/projects/turnaroundTimeUrgency/list`;
const statusEndpoint = `${BASE_URL}/projects/catToolStatus/list`;
const languageCodeEndpoint = `${BASE_URL}/languageCode`;

const ReportsPage = () => {

  const
    {client, legacyClient, accessToken, userDetails} = useMercuryContext(),
    { notifications } = userDetails,
    [initialNotificationsLength, setInitialNotificationsLength] = useState(notifications.length),
    [reports, setReports] = useState(null),
    [myReports, setMyReports] = useState(null),
    [fetchingReports, setFetchReports] = useState(false),
    [fetchingMyReports, setFetchMyReports] = useState(false),
    [newReport, setNewReport] = useState(null),
    [products, setProducts] = useState(null),
    [fetchingProducts, setFetchingProducts] = useState(false ),
    [costCodes, setCostCodes] = useState(null),
    [fetchingCostCodes, setFetchingCostCodes] = useState(false ),
    [components, setComponents] = useState(null),
    [fetchingComponents, setFetchingComponents] = useState(false ),
    [urgency, setUrgency] = useState(null),
    [fetchingUrgency, setFetchingUrgency] = useState(false ),
    [status, setStatus] = useState(null),
    [fetchingStatus, setFetchingStatus] = useState(false ),
    [targetLangageCodes, setTargetLangageCodes] = useState(null),
    [fetchingLanguageCode, setFetchingLanguageCode] = useState(false ),
    location = useLocation();

  function QueueReportModal(props) {
    setNewReport(props);
  }

  const RenderReports = (props) => {
    const
      Columns = [
        {
          title: "",
          dataIndex: "name",
          render: (text, record) =>
            <>
              <a href={`#${record.name}`} onClick={( e ) => {
                e.preventDefault()
                QueueReportModal(record)
              }}>{record.name}</a>
              <br/>
              <p>{record.description}</p>
            </>
        }
      ],
      navigate = useNavigate(),
      [ projectIds, setProjectIds ] = useState([]),
      [ projectString, setProjectString ] = useState( "" ),
      [ invoiceNameString, setInvoiceNameString ] = useState( "" ),
      [ projectNameString, setProjectNameString ] = useState( "" ),
      [ selectedProducts, setSelectedProducts ] = useState( [] ),
      [ selectedCostCodes, setSelectedCostCodes ] = useState( [] ),
      [ selectedComponents, setSelectedComponents ] = useState( [] ),
      [ selectedTLC, setSelectedTLC ] = useState( [] ), // target language codes
      [ receivedDates, setReceivedDates ] = useState( null ),
      [ dueDates, setDueDates ] = useState( null ),
      [ deliveredDates, setDeliveredDates ] = useState( null ),
      [ selectedStatus, setSelectedStatus ] = useState( null ),
      [ selectedUrgency, setSelectedUrgency ] = useState( null ),
      [ submitting, setSubmitting ] = useState( false ),
      [ submitted, setSubmitted ] = useState( false ),
      [ description, setDescription] = useState( null ),
      [ batchId, setBatchId] = useState( null ),
      handleTagChange = (e) => {
        e.preventDefault();
        let
          strings = e.target.value.split(/[ ,;]+/);

        if ( strings.length > 1 ) {
          setProjectIds( Array.from( new Set( ( projectIds.concat( strings.filter( id => id.length > 0) ) ) ) ) )
          return setProjectString( "" );
        }

        setProjectString( strings.pop() );
      },
      handleInvoiceNameChange = (e) => {
        setInvoiceNameString( e.currentTarget.value );
      },

      handleProjectNameChange = (e) => {
        setProjectNameString( e.currentTarget.value );
      },

      handleTagBlurChange = ( e ) => {
          e.preventDefault();
          let
            string = e.target.value

          if ( string.length > 1 ) {
            setProjectIds( Array.from( new Set( ( projectIds.concat( [ string ] ) ) ) ) );
            return setProjectString( "" );
          }
      },
      handleRemoveTag = (t) => {
        const projectIdsWithoutTag = projectIds.filter((item) => item !== t);
        setProjectIds( [...projectIdsWithoutTag] );
      },
      handleKeyDown = ( e ) => {
        // generate a tag on enter
        if(e.currentTarget.value.length > 0 && e.key.toLowerCase() ==='enter') {
          const currentValue = e.target.value,
          newProjectIds = [...projectIds, currentValue.toString()];
          setProjectIds( Array.from(new Set(newProjectIds)) );
          setProjectString( "" );
        }
        // remove last tag
        if ( e.currentTarget.value.length === 0 && e.key.toLowerCase() === "backspace" ) {
          projectIds.pop()
          setProjectIds( Array.from( projectIds ) );
        }
      },
      handleProductChange = ( e ) => {
        if (e && e[0] ==='all') {
          const options = [];
          products.map((item) => options.push(item.title));
          const optionSort = options.sort((a, b) => a.localeCompare(b));
          setSelectedProducts( optionSort );
        } else {
          setSelectedProducts( e );
        }
      },
      handleCostCodeChange = ( e ) => {
        setSelectedCostCodes( e );
      },
      handleComponentChange = ( e ) => {
        setSelectedComponents( e );
      },
      updateReceived = ( e ) => {
        setReceivedDates( e && e.map( d => { return d.format( "YYYY-MM-DD" ) } ) );
      },
      updateDueDate = ( e ) => {
        setDueDates( e && e.map( d => { return d.format( "YYYY-MM-DD" ) } ) );
      },
      updateDeliveredDate = ( e ) => {
        setDeliveredDates( e && e.map( d => { return d.format( "YYYY-MM-DD" ) } ) );
      },
      handleStatusChange = ( e ) => {
        setSelectedStatus( e );
      },
      handleUrgencyChange = ( e ) => {
        setSelectedUrgency( e );
      },
      handleTLCChange = ( e ) => {
        setSelectedTLC( e );
      },
      handleDescription = ( e ) => {
        setDescription( e.currentTarget.value  );
      },
      handleChangeBatchId = (e) => {
        setBatchId( e.currentTarget.value )
      },
      body = {
        reportId: newReport?.reportId,
        parameters: {
          componentTypes: selectedComponents || null,
          receivedDate: receivedDates ? receivedDates : null,
          dueDate: dueDates ? dueDates : null,
          projectIds: projectIds|| null,
          invoiceNames: invoiceNameString|| null,
          projectNames: projectNameString|| null,
          turnaroundTimeUrgency: selectedUrgency || null,
          products: selectedProducts || null,
          costCodes: selectedCostCodes || null,
          targetLanguageCode: selectedTLC || null,
          catToolStatus: selectedStatus || null,
          reportDescription: description ? description : null,
          deliveredDate: deliveredDates ? deliveredDates : null,
          batchId: batchId || null
        }
      },
      submit = () => {
        setSubmitting( true )
      },
      send = useCallback(async () => {
          try {
            const { data:report } = await client.post("/core-rs-v1/queuedreports", { ...body } );
            await GetMyReports();
            navigate( `/reports/my-reports/${report.queuedReportId}`);
            setNewReport( null );
            setSubmitted( true );
            setSubmitting( false );
          }
          catch( e ) {
            console.error( e );
          }
      }, [ setSubmitting, setSubmitted, body, navigate ] );

    useEffect( () => {
      if ( submitting && !submitted ) {
        setSubmitting( true )
        send();
      }
    }, [setSubmitting, send, submitting, submitted]);

    let
      invalid = true;

    Object.keys( body.parameters ).map( key => {
      const
        p = body.parameters;

        if ( invalid && ( ( key !== 'reportDescription' || key !== "projectNames" ) && p[key] && p[key].length > 0 ) ) {
        invalid = false;
      }
      return null;

    } );

    return <>
      {newReport && <FormModal {...{
        projectIds,
        newReport,
        setNewReport,
        submit,
        submitting,
        handleTagChange,
        handleProjectNameChange,
        handleTagBlurChange,
        handleKeyDown,
        handleRemoveTag,
        handleInvoiceNameChange,
        selectedProducts,
        products,
        selectedCostCodes,
        costCodes,
        projectString,
        invoiceNameString,
        projectNameString,
        updateReceived,
        updateDueDate,
        handleComponentChange,
        handleProductChange,
        handleCostCodeChange,
        handleUrgencyChange,
        handleStatusChange,
        components,
        selectedUrgency,
        urgency,
        selectedStatus,
        status,
        invalid,
        targetLangageCodes,
        handleTLCChange,
        selectedTLC,
        description,
        handleDescription,
        updateDeliveredDate,
        handleChangeBatchId
      } } />
      }
      <Table columns={Columns} dataSource={props.data} pagination={{pageSize:100}}/>
    </>
  }


  const RenderMyReports = (props) => {
      const [sortMyReports, setSortMyReports] = useState({
        order: "descend",
        field: "dateQueued",
      }),
      [filterMyReports, setFilterMyReports] = useState({}),
      filterValues = {
        name: [],
        status: [],
      },
      [editing, setEditing] = useState(initialEditingValue),
      TimedOut = ( record ) => {

          if ( record.status.toUpperCase() !== "PENDING" ) {
            return record;
          }

          const
            fromNow = new Date( record.dateQueued ).getTime() + ( 5 * 60 * 60 * 1000 ),
            TimedOut = new Date().getTime() > fromNow;


          if ( TimedOut ) {
            record.status = "TIMED OUT";
            return record;
          }

          return record;
      };

      props.data &&
      props.data.forEach(
        (item) =>{
          item = TimedOut( item ); // fix to change status to TIMED OUT
          // populate filter values on load
          filterValues.name.push(item.name);
          filterValues.status.push(item.status)
        }
      );

      const textExpand = (record) => {
        setEditing({ ...editing, isEditing: false, editingRow: record, expanded: true });
      };

      const textClose = () => {
        setEditing(initialEditingValue);
      };

      const Columns = [
        {
          title: "Report",
          dataIndex: "name",
          width: 400,
          sorter: (a, b) => sorter(a.name, b.name),
          sortOrder: sortMyReports.field === "name" && sortMyReports.order,
          filteredValue: filterMyReports.name,
          filters: formatFilters(filterValues.name),
          onFilter: (value, record) => record.name === value,
          render: (text, record) => (
            <>
              <Link to={`/reports/my-reports/${record.queuedReportId}`}>
                {record.name}
              </Link>
              <Paragraph
                ellipsis={{
                  rows: 1,
                  expandable: true,
                  onExpand: () => textExpand(record),
                }}
                style={{ marginBottom: "0" }}
              >
                {record.parameters.reportDescription}
              </Paragraph>
              {editing.expanded && editing?.editingRow?.key === record?.key && (
                <Button
                  href="#"
                  onClick={textClose}
                  style={{
                    float: "right",
                    border: "none",
                    color: "var(--tertiary-color)",
                  }}
                >
                  Close
                </Button>
              )}
            </>
          ),
        },
        {
          title: "Date created",
          dataIndex: "dateQueued",
          sorter: (a, b) => sorter(a.dateQueued, b.dateQueued),
          sortOrder: sortMyReports.field === "dateQueued" && sortMyReports.order,
          render: (text) => <TimezoneConverter date={text} />,
        },
        {
          title: "Date completed",
          dataIndex: "dateCompleted",
          sorter: (a, b) => sorter(a.dateCompleted, b.dateCompleted),
          sortOrder: sortMyReports.field === "dateCompleted" && sortMyReports.order,
          render: (text, record) => record.dateCompleted ? <TimezoneConverter date={text}/> : null,
        },
        {
          title: "Expiry Date",
          dataIndex: "expiryDate",
          sorter: (a, b) => sorter(a.dateCompleted, b.dateCompleted), // fix for nonexistent key
          sortOrder: sortMyReports.field === "expiryDate" && sortMyReports.order,
          render: (text, record) => {
            const expiryDate = record.dateCompleted ? moment(record.dateCompleted).add(15, 'days') : null;
            return expiryDate && <TimezoneConverter date={expiryDate} format={"DD MMM YYYY"}/>
          },
        },
        {
          title: "Status",
          dataIndex: "status",
          sorter: (a, b) => sorter(a.status, b.status),
          sortOrder: sortMyReports.field === "status" && sortMyReports.order,
          filteredValue: filterMyReports.status,
          filters: formatFilters(filterValues.status),
          onFilter: (value, record) => record.status === value,
          render: (text, record) => text,
        },
        {
          title: "Download",
          dataIndex: "download",
          width: 80,
          sorter: (a, b) => sorter(a.status, b.status), // fix for nonexistent key
          sortOrder: sortMyReports.field === "download" && sortMyReports.order,
          render: (text, record) => {
            if (record.status !== "COMPLETE") {
              return null;
            }

            return <div style={{ display: 'flex', justifyContent: "center" }}>
                <Button
                  text="Download"
                  type="primary"
                  icon={<DownloadOutlined />}
                  href={`${process.env.REACT_APP_API_URL}/core-rs-v1/queuedreports/download/${record.queuedReportId}?token=${accessToken}`}
                  download={true}
                  target={"_blank"}
                />
              </div>
          },
        },
        {
          title: "Edit",
          dataIndex: "edit",
          width: 60,
          render: (text, record) =>
            (record.status === "COMPLETE" ||
            record.status === "FAILED" ||
            record.status === "TIMED OUT") && (
              <>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleDescriptionEdit(record)}
                  style={{
                    border: "none",
                    color: "var(--tertiary-color)",
                  }}
                />
                <EditDescriptionForm
                  onSubmit={updateDescription}
                  visible={editing?.isEditing}
                  selectedDescription={editing?.editingRow}
                />
              </>
            ),
        },
        {
          title: "Delete",
          dataIndex: "delete",
          width: 60,
          render: (text, record) => (record.status === 'COMPLETE' || record.status === 'FAILED' || record.status === 'TIMED OUT') &&
              <Popconfirm
                title={`Delete ${record.name}?`}
                onConfirm={() => deleteReport(record)}
                okText='Yes'
                cancelText='No'
                okButtonProps={{ type: "danger" }}
                cancelButtonProps={{ type: "primary" }}
                icon={<DeleteOutlined style={{ color:'var(--red)'}} />}
              >
                <div className='delete-report-container'><DeleteOutlined /></div>
              </Popconfirm>
        },
      ];

      const deleteReport = async (report) => {
        const deletedReport = await client.delete(`/core-rs-v1/queuedreports/${report.queuedReportId}`)
        // is returning same status 200 even if there is an error so I checked for message
        // TODO: improve queue report to return a different status if it will fail and the id should be stored as a different key
        if(deletedReport.data.message) {
          failedNotification(
            deletedReport.data.message,
            <>
              <span>Name: {report.name}</span><br/>
              <span>Status: {report.status}</span><br/>
              { report.dateQueued && <><span>Date Created: <TimezoneConverter date={report.dateQueued}/></span><br/></>}
              { report.dateCompleted && <span>Date Completed: <TimezoneConverter date={report.dateCompleted}/></span>}
          </>
          );
        } else {
          successfulNotification(
            `Report was deleted`,
            <>
              <span>Name: {report.name}</span><br/>
              <span>Status: {report.status}</span><br/>
              { report.dateQueued && <><span>Date Created: <TimezoneConverter date={report.dateQueued}/></span><br/></>}
              { report.dateCompleted && <span>Date Completed: <TimezoneConverter date={report.dateCompleted}/></span>}
            </>
          );
          // reload reports
          await GetMyReports();
        }
      }

      const changeMyReportsTable = (pagination, filters, sorter, extra) => {
        setSortMyReports(sorter);

        // fix to set an empty object if there are no values instead of undefined keys
        const checkFilters = Object.values(filters).filter((item) => item !== null);

        if (checkFilters.length >= 1) {
          setFilterMyReports(filters);
        } else {
          setFilterMyReports({});
        }
      };

      const handleDescriptionEdit = (record) => {
        setEditing({ ...editing, isEditing: true, editingRow: record, expanded: false });
      };

      const updateDescription = async (submitted, values) => {
        try {
          // Check if form was submitted or cancelled
          if (!submitted) {
            setEditing(initialEditingValue);
            return;
          }

          const body = {
            reportId: values?.reportId,
            parameters: {
              componentTypes: values?.parameters?.componentTypes || null,
              receivedDate: values?.parameters?.receivedDate ? values?.parameters?.receivedDate : null,
              dueDate: values?.parameters?.dueDate || null,
              projectIds: values?.parameters?.projectIds || null,
              invoiceNames: values?.parameters?.invoiceNames || null,
              projectNames: values?.parameters?.projectNames || null,
              turnaroundTimeUrgency: values?.parameters?.turnaroundTimeUrgency || null,
              products: values?.parameters?.products || null,
              costCodes: values?.parameters?.costCodes || null,
              targetLanguageCode: values?.parameters?.targetLanguageCode || null,
              catToolStatus: values?.parameters?.catToolStatus || null,
              reportDescription: values?.reportDescription ? values?.reportDescription : null,
              deliveredDate: values?.deliveredDate ? values?.deliveredDate : null,
            },
          };

          await client
            .patch(`/core-rs-v1/queuedreports/${values.queuedReportId}`, body)
            .then((response) => {
              successfulNotification(
                `${response.data.name}`,
                `Report description updated`
              );
            })
            .catch((error) => {
              if (error.response) {
                failedNotification(
                  error.response.data.error,
                  error.response.data.detail
                );
              } else {
                failedNotification("Failed to update the report description");
              }
            });
          setEditing(initialEditingValue);
        } catch (error) {
          console.error(error);
        } finally {
          await GetMyReports();
        }
      };

    return (
      <>
        <Table
          className='my-reports-table'
          columns={Columns}
          size="small"
          dataSource={props.data}
          onChange={changeMyReportsTable}
          rowKey={(record) => record.key}
          pagination={{
          position: ["bottomCenter"],
          defaultPageSize: 20,
          }}
          scroll={{
            x: "100%",
          }}
        />
      </>
    );
  }

  const GetReports = useCallback( async function () {
    const
      {data} = await client.get("/core-rs-v1/reports"),
      rows = data.map((r, i) => {
        return {...r, key: `report-${i}`}
      });
    setReports(rows)
    setFetchReports(false);
  }, [ setReports, setFetchReports, client ] )


  const GetMyReports = useCallback( async function () {
    const
      {data} = await client.get("/core-rs-v1/queuedreports"),
      rows = data.map((r, i) => {
        return {...r, key: `report-${i}`}
      });

    setMyReports(rows)
    setFetchMyReports(false);
  }, [ setMyReports, setFetchMyReports, client ] )

  const GetProducts = useCallback( async function () {
    const
      {data} = await client.get("/core-gpms-v1/product"),
      rows = data.map((r) => {
        return {...r, key: `component-${r._id}`}
      });

    setProducts(rows)
    setFetchingProducts(false);
  }, [ client, setProducts, setFetchingProducts ] )

  const GetUrgency = useCallback( async function () {
    const
      {data} = legacyClient && await legacyClient.get(urgencyEndpoint),
      rows = data.map((r, i) => {
        return {title: r, key: `component-${i}`}
      });

    setUrgency(rows)
    setFetchingUrgency(false);
  }, [ legacyClient, setUrgency, setFetchingUrgency ] )

  const GetComponents = useCallback( async function () {
    const
      {data} = legacyClient && await legacyClient.get(componentsEndpoint),
      rows = data.map((r, i) => {
        return {title: r, key: `component-${i}`}
      });

    setComponents(rows)
    setFetchingComponents(false);
  }, [ legacyClient, setComponents, setFetchingComponents ] );

  const GetStatus = useCallback( async function() {
    const
      {data} = legacyClient && await legacyClient.get(statusEndpoint),
      rows = data.map((r, i) => {
        return {title: r, key: `status-${i}`}
      });

    setStatus(rows)
    setFetchingStatus(false);
  }, [ setStatus, legacyClient, setFetchingStatus ] )

  const GetLanguageCode = useCallback( async function() {
    const
      {data} = legacyClient && await legacyClient.get(languageCodeEndpoint),
      rows = data.map((r, i) => {
        return {title: r.code, key: `targetLanguageCode-${i}`}
      });

      setTargetLangageCodes(rows)
    setFetchingLanguageCode(false);
  }, [ setTargetLangageCodes, legacyClient, setFetchingLanguageCode] )

  const GetCostCodes = useCallback( async function () {
    const
      {data} = await client.get("/core-ccs-v1"),
      rows = data.map((costCode) => {
        return {...costCode, key: `component-${costCode._id}`}
      });

    setCostCodes(rows)
    setFetchingCostCodes(false);
  }, [ client, setCostCodes, setFetchingCostCodes ] )

  useEffect(() => {
    if (!reports && !fetchingReports) {
      setFetchReports(true);
      GetReports();
    }

    if (!myReports && !fetchingMyReports) {
      setFetchMyReports(true);
      GetMyReports();
    }

    // temporary fix for updating my reports when user will receive a notification
    // TODO: remove it once we will update notification event listener
    // TODO: remove initialNotificationsLength state and importing notifications too
    // TODO: remove initialNotificationsLength and notifications.length from useEffect dependency array
    if (!fetchingMyReports && initialNotificationsLength !== notifications.length) {
      setFetchMyReports(true);
      GetMyReports();
      setInitialNotificationsLength(notifications.length)
    }

    if ( !products && !fetchingProducts ) {
      setFetchingProducts( true )
      GetProducts();
    }

    if ( !costCodes && !fetchingCostCodes ) {
      setFetchingCostCodes( true )
      GetCostCodes();
    }

    if ( !components && !fetchingComponents ) {
      setFetchingComponents( true )
      GetComponents();
    }

    if ( !urgency && !fetchingUrgency ) {
      setFetchingUrgency( true )
      GetUrgency();
    }

    if ( !status && !fetchingStatus ) {
      setFetchingStatus( true )
      GetStatus();
    }

    if ( !targetLangageCodes && !fetchingLanguageCode ) {
      setFetchingLanguageCode( true )
      GetLanguageCode();
    }
  }, [
    GetMyReports,
    GetReports,
    GetProducts,
    GetComponents,
    GetUrgency,
    GetStatus,
    setFetchReports,
    setFetchMyReports,
    setFetchingProducts,
    setFetchingComponents,
    setFetchingUrgency,
    setFetchingStatus,
    GetLanguageCode,
    GetCostCodes,
    myReports,
    fetchingMyReports,
    components,
    fetchingComponents,
    fetchingProducts,
    fetchingReports,
    fetchingStatus,
    fetchingUrgency,
    products,
    costCodes,
    reports,
    status,
    urgency,
    fetchingLanguageCode,
    fetchingCostCodes,
    targetLangageCodes,
    initialNotificationsLength,
    notifications.length
  ]);

const reportsMenuItems = [
  { id: 0, path: '/reports', title: 'Reports' },
  { id: 1, path: '/reports/my-reports', title: 'My Reports' }
];

const displayedReportsMenuItems = reportsMenuItems.map((i) => {
  return {
    label: <Link to={i.path}>{i.title}</Link>,
    key: i.id,
  }
});

const path = location?.pathname,
    matchMenuItem = path.includes('/reports/my-reports/') ? reportsMenuItems[1] : reportsMenuItems.find( item => item.path === path );

let queuedReportId;
    
if (location?.pathname.includes('/reports/my-reports/')) {
  queuedReportId = location?.pathname.replace('/reports/my-reports/', '')
}

  return (
      <Layout className={"reports"}>
        <Sider className="reports-sidebar">
          <Menu defaultSelectedKeys={matchMenuItem?.id.toString()} items={displayedReportsMenuItems}/>
        </Sider>
        <Helmet>
          <title>Mercury reporting - Mercury © RWS</title>
        </Helmet>
        <Content>
          <Routes>
            <Route 
              path={"*"} 
              element={<div className="reports-page">
                <h1>Reports</h1>
                {!reports ? <LoadingStar /> : <RenderReports data={reports} />}
              </div>}
            />
            <Route 
              path={"/my-reports/:queuedReportId"} 
              element={ <Report location={location} key={window.location.pathname} queuedReportId={queuedReportId}/>}
            />
            <Route 
              path={"/my-reports"}
              element={<div className="my-reports-page">
                <h1>My reports</h1>
                {!myReports ? (
                  <LoadingStar />
                ) : (
                  <RenderMyReports data={myReports} />
                )}
              </div>}
            />
          </Routes>
        </Content>
      </Layout>
  )
}

export default ReportsPage
