import React, { useEffect, useRef, useState } from "react";

import { Button, Drawer, Form } from "antd";

import { PlusCircleOutlined } from "@ant-design/icons";
import { useMercuryContext } from "../../user-context";
import { useScrollMonitor } from "../../Components";

import {
  failedNotification,
  successfulNotification,
} from "../../utils/notifications";

import "./AddPoType.scss";
import { activeFormItem, descriptionFormItem, titleFormItem } from "../../reusable/formItems";

interface IPoTypeValues {
  title: string;
  description: string;
  isActive?: boolean;
}

interface IProps {
  reload: () => any;
}

const AddPoType: React.FC<IProps> = ({ reload }) => {
  const context = useMercuryContext(),
    { gpClient } = context;

  const position = useScrollMonitor();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false),
    [loading, setLoading] = useState<boolean>(false);

  const [addingForm] = Form.useForm();

  const addFormRef = useRef(null);

  // TODO: remove the entire height logic once we update antd version and scrollLocker warning is not visible
  // with the current antd version, the user is allowed to scroll, but when is scrolling the header height is changing
  let height = 64,
    drawerClassName = "add-po-type-drawer";

  if (isDrawerOpen && position < 64 && position >= 40) {
    height = 64 - (position - 40);
  } else if (isDrawerOpen && position < 64) {
    height = 64;
  } else if (isDrawerOpen) {
    height = 40;
  }

  if (isDrawerOpen && height <= 47) {
    drawerClassName = "add-po-type-drawer ant-drawer__bigger";
  }

  useEffect(() => {
    if (isDrawerOpen) {
      addingForm.setFieldsValue({
        isActive: true,
      });
    }
  }, [isDrawerOpen, addingForm]);

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    addingForm.resetFields();
  };

  const onFinishPOType = async (values: IPoTypeValues) => {
    setLoading(true);
    const endpoint = `google-gp-v1/PoType`;

    try {
      await gpClient.post(endpoint, values).then((result: any) => {
        console.log("result", result);
        successfulNotification(
          `PO Type(${result.data.title}) was created`,
          "The new PO Type should be visible in the table. Otherwise please reload."
        );

        isDrawerOpen && closeDrawer();
        reload();
      });
    } catch (e: any) {
      console.error("Error creating a PO Type: ");
      console.error(e);

      failedNotification("Failed to create the PO Type", "Please try again.");
    }

    setLoading(false);
  };

  const handleSwitchChange = (checked: boolean) => {
    addingForm.setFieldsValue({ isActive: checked });
  };

  return (
    <div className="add-po-type">
      <Button
        type="primary"
        onClick={() => setIsDrawerOpen(true)}
        className="enabled-add-button"
      >
        <PlusCircleOutlined /> Add PO Type
      </Button>
      {isDrawerOpen && (
        <Drawer
          title="Add PO Type"
          placement="right"
          onClose={closeDrawer}
          open={isDrawerOpen}
          width={500}
          closable={true}
          className={drawerClassName}
          forceRender
        >
          <Form
            layout="horizontal"
            form={addingForm}
            ref={addFormRef}
            onFinish={onFinishPOType}
            name="AddPoTypeForm"
          >
           {titleFormItem(true)}
           {descriptionFormItem(true)}
           {activeFormItem(handleSwitchChange)}
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save PO Type
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      )}
    </div>
  );
};

export default AddPoType;
