import React from "react";
import TimezoneConverter from "../timezone-converter";
import { Tooltip } from "antd";

const FillSvg = ({ percentage, date }) => {
  return date ? (
    <Tooltip
      color="var(--primary-color)"
      title={
        <>
          <span style={{ marginRight: "5px" }}>
            {percentage === 0
              ? "Not Shared"
              : percentage === 1
              ? "Partially Shared"
              : percentage === 2
              ? "Fully Shared"
              : "Not Shared"}{" "}
            -
          </span>
          <TimezoneConverter date={date} />
        </>
      }
      placement={"left"}
    >
      <svg
        width="17.5"
        height="15"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: "help" }}
      >
        <g id="Layer_1">
          <rect
            stroke="var(--primary-color)"
            id="svg_5"
            height="13.50363"
            width="8.36496"
            y="0.83943"
            x="0.69345"
            strokeWidth="0.5"
            fill={
              percentage === 1 || percentage === 2
                ? "var(--primary-color)"
                : "var(--white)"
            }
          />
          <path
            id="svg_7"
            d="m8.84135,0.83943l8,0l0,13.57128l-8,0l0,-13.57128z"
            opacity="undefined"
            strokeWidth="0.5"
            stroke="var(--primary-color)"
            fill={percentage === 2 ? "var(--primary-color)" : "var(--white)"}
          />
        </g>
      </svg>
    </Tooltip>
  ) : (
    <Tooltip
      color="var(--primary-color)"
      title={
        percentage === 0
          ? "Not Shared"
          : percentage === 1
          ? "Partially Shared"
          : percentage === 2
          ? "Fully Shared"
          : "Not Shared"
      }
      placement={"left"}
    >
      <svg
        width="17.5"
        height="15"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: "help" }}
      >
        <g id="Layer_1">
          <rect
            stroke="var(--primary-color)"
            id="svg_5"
            height="13.50363"
            width="8.36496"
            y="0.83943"
            x="0.69345"
            strokeWidth="0.5"
            fill={
              percentage === 1 || percentage === 2
                ? "var(--primary-color)"
                : "var(--white)"
            }
          />
          <path
            id="svg_7"
            d="m8.84135,0.83943l8,0l0,13.57128l-8,0l0,-13.57128z"
            opacity="undefined"
            strokeWidth="0.5"
            stroke="var(--primary-color)"
            fill={percentage === 2 ? "var(--primary-color)" : "var(--white)"}
          />
        </g>
      </svg>
    </Tooltip>
  );
};

export default FillSvg;
