const createPayload = (values) => {
    const payload = {
        query: {
        ...(values.sesameAccount && { sesameId: values.sesameAccount }),
        ...(values.email && { personal_email: values.email }),
        ...(values.sdl_email && { sdl_email: values.sdl_email }),
        ...(values.sharingElegible && values.sharingElegible === 'yes' ? { eligible: true } : values.sharingElegible === 'no' ? { eligible: false } : {}),
        ...(values.notifications && values.notifications === 'yes' ? { share_notifications: true } : values.notifications === 'no' ? { share_notifications: false } : {}),
        ...(values.language && { language_codes: values.language }),
        ...(values.sourceLanguage && { sourceLanguage: values.sourceLanguage }),
        ...(values.product && { products: values.product }),
        ...(values.componentType && { components: values.componentType }),
        ...(values.projectRole && { project_roles: values.projectRole }),
        ...(values.programRole && { program_role: values.programRole }),
        ...(values.affiliation && { affiliation: values.affiliation }),
        ...(values.active && values.active === 'yes' ? { active: true } : values.active === 'no' ? { active: false } : {}),
        }
    };

    if (values.sesameAccountOptions) {
        const shouldFilterSesameAccount = (values.sesameAccountOptions.includes("with") && !values.sesameAccountOptions.includes("without")) ||
        (!values.sesameAccountOptions.includes("with") && values.sesameAccountOptions.includes("without"));
        const sesameAccountFilter = values.sesameAccountOptions.includes("with") ? true : false;
        if (shouldFilterSesameAccount) {
        payload.sesameAccount = sesameAccountFilter;
        }
    }

    return payload;
}

export default createPayload;
