import React from "react";
import { Button } from "antd";

const UniversalButton = ({ type, clickMethod, text, icon }) => {
  return (
    <Button type={type} onClick={clickMethod} icon={icon}>
      {text}
    </Button>
  );
};

export default UniversalButton;
