import React from "react";
import {
    FiniteListFilter, ListProps
} from "../Components";
import {useMercuryContext} from "../user-context";

export default (props:ListProps) => {
    const context = useMercuryContext();
    return <FiniteListFilter data={
        [
            {
                key: "admin",
                title: "Admin",
                value: "admin"
            },
            {
                key: "bonus",
                title: "Bonus",
                value: "bonus"
            },
            {
                key: "correction",
                title: "Correction",
                value: "correction"
            },
            {
                key: "credit",
                title: "Credit",
                value: "credit"
            },
            {
                key: "penalty",
                title: "penalty",
                value: "penalty"
            }
        ]
    } {...props}/>
}
