import React, {useCallback, useState} from "react";
import Notification, {NotificationProps } from "./Notification";
import {useMercuryContext} from "../../user-context";
import {Helmet} from "react-helmet";
import PageTitle from "../../ui/PageTitle";
import {Button, Timeline, Tooltip} from "antd";
import {DeleteFilled} from "@ant-design/icons";
import "./Notification.scss"

export interface NotificationsProps {
    // notifications: Notification
}

export default function Notifications ( props:NotificationsProps ) {
    const
        { userDetails, umsClient:client, flags } = useMercuryContext(),
        { notifications, id } = userDetails,
        [ deleteAll, setDeleteAll ] = useState( false );


    const Clear = useCallback( async () => {
        setDeleteAll( true );
        await client.patch( `/core-ums-v2/users/clear-notifications/${id}` );
        setDeleteAll( false );
    }, [ setDeleteAll, client, id, flags.umsVersion ] );

    const displayedNotifications = notifications.sort( ( a:NotificationProps, b:NotificationProps ) => {
        if ( new Date( a.received ) > new Date( b.received) ) {
            return -1;
        }
        return 1;
    }).map((notification: NotificationProps) => {
        return { children: <Notification {...notification} key={notification.id}/> }
    })

    return (
        <div className="manage-page notifications">
            <Helmet>
                <title>Mercury Notifications - Mercury © RWS</title>
            </Helmet>
            {
                notifications.length === 0
                    ? <div style={{ width:"80%", margin: "20px auto", textAlign: "center"}}>
                        <PageTitle title="You have no notifications" />
                    </div>
                    : <>
                        <PageTitle title={
                            <>
                                <span>Notifications and updates</span>
                                <div className={"notifications-actions"}>
                                    <Tooltip title="Permanently remove all notifications" placement={"left"}>
                                        <Button loading={deleteAll} type="primary" danger icon={<DeleteFilled />} disabled={deleteAll} onClick={Clear}>
                                            Clear all
                                        </Button>
                                    </Tooltip>
                                </div>
                            </>
                        } />
                        <Timeline
                            className={"notification-container"}
                            mode={"alternate"}
                            items={displayedNotifications}
                        />
                    </>
            }
        </div>

    )
}
