import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleFilled,
  ExportOutlined,
  HourglassOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Collapse, Popover, Space, Spin, Table } from "antd";
import { removeUnderscore } from "../../../utils/helpers";
import TimezoneConverter from "../../../timezone-converter";
import SharingPercentage from "../../../ui/SharingPercentage";
import { useEffect, useState } from "react";
import PolyglotLogo from "../../../stories/assets/polyglot-logo.svg";
import UniversalButton from "../../../ui/UniversalButton";
import { Link } from "react-router-dom";

const PlpInformation = ({ plpId, context }: any) => {
  const [documents, setDocuments] = useState<any>([]);
  const [sorting, setSorting] = useState<any>({
    field: "catToolStatus",
    order: "ascend",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoading(true);
    try {
      const { data } = await context.gpClient.get(
        `/google-gp-v1/document/documentsPerPlp/${plpId}`
      );
      setDocuments(data);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <Spin size="small" style={{ margin: "0 10px" }} />
        Loading list...
      </div>
    );
  }

  const items = [
    {
      key: "1",
      label: "Actions",
      className: "actions",
      children: (
        <Space
          size="large"
          style={{
            width: "100%",
            alignItems: "flex-start",
            margin: "15px 16px",
          }}
        >
          <UniversalButton
            clickMethod={() => fetch()}
            text="Refresh"
            icon={<ReloadOutlined />}
            type={undefined}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <Collapse
        style={{ margin: "0 15px 20px 15px" }}
        defaultActiveKey={["1"]}
        items={items}
      />
      <Collapse
        defaultActiveKey={["1"]}
        style={{ margin: "5px 15px 5px 15px" }}
        items={[
          {
            key: "1",
            label: "Documents",
            extra: `Total: ${documents.length}`,
            children: (
              <Table
                columns={[
                  {
                    title: "Document",
                    dataIndex: "title",
                    align: "left",
                    render: (text, record) => {
                      const docId = record?._id;
                      const taskId = record?.documentId?.split("/")?.[1];
                      return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <a
                            href={`/document/${docId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="View document details in a new tab"
                          >
                            <ExportOutlined style={{ marginRight: 6 }} />
                          </a>
                          <Link to={`/document/${docId}`} id={text} title="View document details">
                            {text}
                          </Link>
                          <a
                            href={`https://localization.google.com/polyglot/tasks/${taskId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ userSelect: "none" }}
                            title="View document in Polyglot"
                          >
                            <img
                              src={PolyglotLogo}
                              alt="polyglot"
                              style={{
                                margin: "6px 0 0 6px",
                                width: "auto",
                                height: "20px",
                              }}
                            />
                          </a>
                        </div>
                      );
                    },
                  },
                  {
                    title: "Auto Completed",
                    dataIndex: "autoCompleted",
                    render: (text, record) => {
                      switch (record.autocompleted) {
                        case "PENDING":
                          return (
                            <Popover
                              content={
                                "The document has been sent for autocompletion, but has not yet been resolved"
                              }
                            >
                              <HourglassOutlined
                                style={{ color: "var(--tertiary-color)" }}
                              />
                            </Popover>
                          );
                        case true:
                          return (
                            <CheckOutlined
                              style={{ color: "var(--tertiary-color)" }}
                            />
                          );
                        case false:
                          return (
                            <Popover
                              content={
                                "The document is waiting to be sent for autocompletion."
                              }
                            >
                              <CloseOutlined />
                            </Popover>
                          );

                        case "FAILED":
                          return (
                            <Popover
                              content={
                                "Autocompletion of this document has failed. IT will automatically be retried."
                              }
                            >
                              <ExclamationCircleFilled
                                style={{ color: "var(--red)" }}
                              />
                            </Popover>
                          );

                        case "N/A":
                        default:
                          return <span>-</span>;
                      }
                    },
                  },
                  {
                    title: "Status",
                    dataIndex: "catToolStatus",
                    align: "left",
                    sorter: {
                      compare: (a, b) =>
                        a.catToolStatus.localeCompare(b.catToolStatus),
                    },
                    sortOrder:
                      sorting.field === "catToolStatus" && sorting.order,
                    render: (text) => removeUnderscore(text),
                  },
                  {
                    title: "Urgency",
                    dataIndex: "urgency",
                    align: "left",
                    render: (text) => text,
                  },
                  {
                    title: "Due Date",
                    dataIndex: "translationDueTimestamp",
                    sortDirections: ["descend", "ascend"],
                    // Converts to unix form to be able to sort
                    sorter: {
                      compare: (a, b) =>
                        new Date(a.translationDueTimestamp).getTime() / 1000 -
                        new Date(b.translationDueTimestamp).getTime() / 1000,
                    },
                    sortOrder: sorting.field === "translationDueTimestamp" && sorting.order,
                    align: "left",
                    render: (text, record) => {
                      return text ? (
                        <TimezoneConverter date={text} />
                      ) : (
                        "Not set"
                      );
                    },
                  },
                  {
                    title: "Sharing",
                    dataIndex: "sharingStatus",
                    sorter: {
                      compare: (a, b) => a.sharingStatus - b.sharingStatus,
                    },
                    sortOrder:
                      sorting.field === "sharingStatus"
                        ? sorting.order
                        : undefined,
                    align: "center",
                    render: (text, record) => (
                      <SharingPercentage
                        percentage={text}
                        date={record.lastSharedDate}
                      />
                    ),
                  },
                  {
                    title: "Weighted Word Count",
                    dataIndex: "weightedWordCount",
                    sorter: {
                      compare: (a, b) =>
                        a.weightedWordCount - b.weightedWordCount,
                    },
                    align: "left",
                    sortOrder:
                      sorting.field === "weightedWordCount" && sorting.order,
                    render: (text) => text,
                  },
                ]}
                dataSource={documents?.map((el: any) => {
                  return { ...el, key: el?._id };
                })}
                className={"language-pairs-table"}
                pagination={false}
                onChange={(pagination, filters, sort: any) => {
                  setSorting(sort);
                }}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default PlpInformation;
