export function capitalizeString(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  export function sorter(a, b) {
    a = !a ? "" : Array.isArray(a) ? a.join() : a;
    b = !b ? "" : Array.isArray(b) ? b.join() : b;
  
    const diff = a - b;
    if (Number.isNaN(diff)) {
      return a.localeCompare(b) || a.length - b.length;
    } else {
      return diff;
    }
  }