import React, { useState } from "react";
import Total from "../../../ui/Total";
import TimezoneConverter from "../../../timezone-converter";

import { Table, Typography, Skeleton, Input, Spin, Row } from "antd";

import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

const CostCodesTable = ({ values, handlers }) => {
  const { costCodes, sortedInfo, editing, total } = values;
  const { handleChangeTable, handleClickEdit } = handlers;

  const { Text, Link, Title } = Typography;
  const { Search } = Input;

  const [filterTable, setFilterTable] = useState(null);

  const data =
    costCodes &&
    costCodes.map((item) => {
      return {
        key: item._id,
        title: item.code,
        active: item?.isActive,
        createDate: item.createdAt ? item.createdAt : item?.created_at,// fallback for old db keys
        updateDate: item.updatedAt ? item.updatedAt : item?.updated_at,
      };
    });

  const columns = [
    {
      title: "Cost Code Title",
      dataIndex: "title",
      width: 400,
      sorter: (a, b) => {
        a = a.title === null ? "" : a.title;
        b = b.title === null ? "" : b.title;
        return a.localeCompare(b);
      },
      sortOrder: sortedInfo.field === "title" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.title === editing.editingRow.title ? (
            text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text
        ),
    },
    {
      title: "Create Date",
      dataIndex: "createDate",
      width: 200,
      render: (text, record) =>
        editing.isEditing && record.title !== editing.editingRow.title ? (
          <Skeleton active size="small" title={true} paragraph={false} />
        ) : text != null ? (
          <Text>
            <TimezoneConverter date={text} />
          </Text>
        ) : (
          ""
        ),
    },
    {
      title: "Last Update Date",
      dataIndex: "updateDate",
      width: 200,
      render: (text, record) =>
        editing.isEditing && record.title !== editing.editingRow.title ? (
          <Skeleton active size="small" title={true} paragraph={false} />
        ) : text != null ? (
          <Text>
            <TimezoneConverter date={text} />
          </Text>
        ) : (
          ""
        ),
    },
    {
      title: "Active",
      dataIndex: "active",
      width: 100,
      sorter: (a, b) => {
        a = a.active === null ? "" : a.active;
        b = b.active === null ? "" : b.active;
        return a - b;
      },
      sortOrder: sortedInfo.field === "active" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.title === editing.editingRow.title ? (
            record.active === true ? (
              <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
            ) : (
              <CloseOutlined style={{ color: "var(--red)" }} />
            )
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : record.active === true ? (
          <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
        ) : (
          <CloseOutlined style={{ color: "var(--red)" }} />
        ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      width: 100,
      render: (text, record) =>
        editing.isEditing && record.title !== editing.editingRow.title ? (
          <Skeleton active size="small" title={true} paragraph={false} />
        ) : (
          <Link
            onClick={() => {
              handleClickEdit(record);
            }}
            style={{
              marginRight: "10px",
            }}
          >
            <EditOutlined /> Edit
          </Link>
        ),
    },
  ];

  const handleSearch = (value) => {
    const filterTable = data.filter((item) =>
      item.title.toString().toLowerCase().includes(value.toLowerCase())
    );

    setFilterTable(filterTable);
  };

  return (
    <>
      <Search
        style={{ width: "300px", margin: "0 0 10px 0" }}
        placeholder="Search by title"
        onSearch={handleSearch}
        allowClear
        enterButton
      />
      <Row className="title-wrap">
        <Title level={5} style={{ margin: "5px 0 0" }}>
          COST CODES LIST
          {editing.isEditing && (
            <Spin size="small" style={{ marginLeft: "10px" }} />
          )}
        </Title>
        <Total number={total} text="cost codes" />
      </Row>
      <Table
        columns={columns}
        dataSource={filterTable == null ? data : filterTable}
        size="small"
        className="manage-page-table"
        onChange={handleChangeTable}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: 20,
        }}
      />
    </>
  );
};

export default CostCodesTable;
