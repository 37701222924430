import React from "react";
import { Layout } from "antd";

import { currentYear } from "../utils/helpers";

function Footer() {
  const { Footer } = Layout;
  
   return (
    <Footer className="footer">
      Copyright © {currentYear()} - All Rights Reserved by
      <a
        href="https://www.rws.com"
        target="_blank"
        rel="noopener noreferrer"
        className="link"
      >
        {" "}
        RWS
      </a>
    </Footer>
  );
}

export default Footer;
