import React, {Component} from "react";
import LocalStorage from "../../Utilities/LocalStorage";
import {FiniteListFilter,FiniteListProps,FiniteListItem} from "../FiniteListFilter/FiniteListFilter";

export interface AsyncListLoaderProps extends Omit<FiniteListProps, "data">{
    id: string;
    context: any;
    freshness?: number;
}

export interface AsyncListLoaderState {
    loaded: boolean;
    errored: boolean;
    data?:FiniteListItem[]
}

export abstract class FiniteListWithAsyncLoaderAbstract extends Component<AsyncListLoaderProps, AsyncListLoaderState >{

    private listFreshness:LocalStorage = new LocalStorage("listFreshness");
    private storage!:LocalStorage;
    private userContext!:any;
    private maxAge:number = 60*60*24;

    public id!:string;

    public state = {
        loaded: false,
        errored: false,
        data: undefined,
        multi: true
    }

    constructor( props:AsyncListLoaderProps ) {
        super( props );
    }

    set freshness( value ) {
        this.listFreshness.set( {
            ...this.listFreshness.data,
            [this.props.id]: value
        })
    }

    get freshness() {
        return (this.listFreshness.data && this.listFreshness.data[this.props.id] )|| new Date().getTime();
    }

    abstract fetch(signal: any):Promise<FiniteListItem[]>;

    private async load() {
        try {
            this.abortController.abort();

            const
                data = await this.fetch({signal: this.abortController.signal});
            this.freshness = new Date().getTime() + this.maxAge;
            this.storage.set( data );
            this.setState( { data, loaded: true } );
        }
        catch ( e ) {
            console.error( this.id, e )
            this.setState( { errored: true, loaded: true, data: [] } );
        }
    }

    protected abortController = new AbortController();

    async componentDidMount() {
        this.id = this.props.id;
        this.storage = new LocalStorage( this.props.id );
        this.userContext = this.props.context;
        if ( this.props.freshness ) this.maxAge = this.props.freshness;

        if ( ( new Date().getTime() >= this.freshness ) || !this.storage.data ) {
            await this.load();
            return;
        }
        this.setState({ data: this.storage.data, loaded: true, errored: false } );
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    public retry = () => {
        this.setState({ errored: false, loaded: false }, this.load )
    }

    render() {
        return <FiniteListFilter
            {...this.props}
            data={this.state.data || []}
            onChange={this.props.onChange}
            title={this.props.title}
            value={this.props.value}
            loading={!this.state.loaded}
            errored={this.state.errored}
            onRetry={this.retry}
            name={this.props.name}
            multi={this.state.multi}
        />
    }


}
