import React, { useState } from "react";
import axios from "axios";

import {
  failedNotification,
  successfulNotification,
} from "../utils/notifications";

import { Button, Tooltip, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const DeleteAllReferenceFilesBtn = ({
  baseURI,
  accessToken,
  shortId,
  data,
  projectStatus,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  // call the api to delete only that reference file
  const deleteAllReferenceFiles = async (
    baseURI,
    accessToken,
    shortId,
    fileId,
    name
  ) => {
    try {
      let headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      let url = `${baseURI}/projects/${shortId}/files/delete/${fileId}`;

      // make the api request to delete the reference file
      await axios
        .delete(url, {
          headers: headers,
        })
        .then((response) => console.log(response));
    } catch (err) {
      console.error('Delete reference files error: ', err);
      let errorTitle = err.response.data.error;
      let errorMessage = err.response.data.detail;
      failedNotification(
        errorTitle,
        <>
          <p>Unable to delete {name} at this time. Please Refresh your page.</p>
          <p>{errorMessage}</p>
        </>
      );
    }
  };

  // delete all reference files
  const handleClickDeleteAllReference = () => {
    setIsLoading(true);

    const promises = [];
    data.forEach((item) => {
      let promise = new Promise(function (resolve, reject) {
        resolve(
          deleteAllReferenceFiles(
            baseURI,
            accessToken,
            shortId,
            item._id,
            item.displayName
          )
        );
      });
      promises.push(promise);
    });

    Promise.all(promises).then(() => {
      successfulNotification(
        "Delete request successfully submitted",
        "Please Refresh your page."
      );
      setIsLoading(false);
    });
  };

  return projectStatus === "IN_PREPROCESSING" ? (
    <Tooltip
      color="#efc000"
      title={`Delete all Reference Files`}
      placement={"left"}
    >
      <Popconfirm
        onConfirm={handleClickDeleteAllReference}
        okText="Yes"
        cancelText="No"
        okType="danger"
        icon={<DeleteOutlined style={{ color: "#efc000" }} />}
        title={"Are you sure you want to delete all the reference files?"}
        cancelButtonProps={{ type: "ghost" }}
        okButtonProps={{ type: "primary" }}
      >
        <Button
          icon={<DeleteOutlined style={{ color: "#efc000" }} />}
          className="delete-all-icon"
          loading={isLoading}
        />
      </Popconfirm>
    </Tooltip>
  ) : (
    <Tooltip
      color="var(--grey)"
      title={`Reference files cannot be deleted once a project is no longer in the pre-processing state.`}
      placement={"left"}
    >
      {" "}
      <Button
        style={{
          border: "none",
          borderLeft: "1px solid var(--grey)",
          borderRadius: 0,
          padding: "0",
          height: "25px",
          pointerEvents: "none",
        }}
        icon={<DeleteOutlined />}
        disabled
      />
    </Tooltip>
  );
};

export default DeleteAllReferenceFilesBtn;
