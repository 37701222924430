import React, {Component} from "react";
import LocalStorage from "../../Utilities/LocalStorage";
import {FiniteListProps} from "../FiniteListFilter/FiniteListFilter";
import {Select} from "antd";

export interface FiniteDropDownWithAsyncLoaderItem {
    value: any;
    label: string;
}

export interface FiniteDropDownWithAsyncLoaderProps extends Omit<FiniteListProps, "data">{
    id: string;
    context: any;
    freshness?: number;
    showSearch?: boolean;
    allowClear?:boolean;
    style?:any;
}

export interface FiniteDropDownWithAsyncLoaderState {
    loaded: boolean;
    errored: boolean;
    data?:FiniteDropDownWithAsyncLoaderItem[]
}

export abstract class FiniteDropDownWithAsyncLoader extends Component<FiniteDropDownWithAsyncLoaderProps, FiniteDropDownWithAsyncLoaderState >{

    private listFreshness:LocalStorage = new LocalStorage("listFreshness");
    private storage!:LocalStorage;
    private userContext!:any;
    private maxAge:number = 60*60*24;

    public id!:string;

    public state = {
        loaded: false,
        errored: false,
        data: undefined,
        multi: true
    }

    set freshness( value ) {
        this.listFreshness.set( {
            ...this.listFreshness.data,
            [this.props.id]: value
        })
    }

    get freshness() {
        return (this.listFreshness.data && this.listFreshness.data[this.props.id] )|| new Date().getTime();
    }

    abstract fetch(signal: any):Promise<FiniteDropDownWithAsyncLoaderItem[]>;

    private async load() {
        try {
            this.abortController.abort();

            const
                data = await this.fetch({signal: this.abortController.signal});
            this.freshness = new Date().getTime() + this.maxAge;
            this.storage.set( data );
            this.setState( { data, loaded: true } );
        }
        catch ( e ) {
            console.error( this.id, e )
            this.setState( { errored: true, loaded: true, data: [] } );
        }
    }

    protected abortController = new AbortController();

    async componentDidMount() {
        this.id = this.props.id;
        this.storage = new LocalStorage( this.props.id );
        this.userContext = this.props.context;
        if ( this.props.freshness ) this.maxAge = this.props.freshness;

        if ( ( new Date().getTime() >= this.freshness ) || !this.storage.data ) {
            await this.load();
            return;
        }
        this.setState({ data: this.storage.data, loaded: true, errored: false } );
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    public retry = () => {
        this.setState({ errored: false, loaded: false }, this.load )
    }

    public onChange = async ( v:any ) => {
        this.setState({ loaded: false } )
        await this.props.onChange( v );
        this.setState( { loaded: true })
    }

    render() {

        return (
            <Select
                value={this.props.value as any}
                options={this.state.data ? this.state.data : []}
                placeholder={this.props.placeholder}
                filterOption={(input, option) =>
                    // @ts-ignore
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                style={this.props.style}
                onChange={this.onChange}
                // @ts-ignore
                status={this.state.errored ? "error" : undefined}
                disabled={!this.state.loaded}
                showSearch={this.props.showSearch}
                allowClear={this.props.allowClear}
                loading={!this.state.loaded} />
        )
    }
}
