import {useState, FunctionComponent, useCallback, useEffect} from "react";
import { AutoComplete, Spin } from "antd";

import { useNavigate } from "react-router-dom";

import "./SearchProjectAutocomplete.scss";
import { useMercuryContext } from "../../user-context";

interface IOption {
  value: string;
  title: string;
  url: string;
  _id: string;
}

const { Option } = AutoComplete;

const SearchProjectAutocomplete: FunctionComponent<any> = ({handleMainMenuSelection}) => {
  const context = useMercuryContext(),
    { client } = context,
    navigate = useNavigate();

  const [value, setValue] = useState<string>(""),
    [options, setOptions] = useState<IOption[]>([]),
    [loadingOptions, setLoadingOptions] = useState<boolean>(false),
    [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const onSearch = (data: string) => {
    if (data) {
      if (data.length > 1) {
        getOptionsData(data);
      } else {
        setOptions([]);
      }

      setValue(data);
    } else {
      setValue("");
      setOptions([]);
    }
  };

    const onSelect = (value: string, option: any) => {
        closeDropdown();
        handleMainMenuSelection('projects')
        navigate(option.url)
    };


  useEffect( () => {
    const socket = context.gpSocket.project;

    socket.on( "autocomplete", ( data:any ) => {
      setOptions( [...options, data ] );
    } )

    return () => socket.off( "autocomplete" )
  } )

  const closeDropdown = () => {
    setIsDropdownOpen(false);
    setLoadingOptions(false);
    setValue("");
    setOptions([]);
  };

  const getOptionsData = useCallback(
    async (search: string) => {
      setLoadingOptions(true);

      setOptions([])

      const socket = context.gpSocket.project;

      socket.emit( "autocomplete", { search, limit:10 }, () => {
        setLoadingOptions(false);
      })

    },
    []
  );

  const handleDropdownChange = (open: boolean) => {
    setIsDropdownOpen(open);
  };

  const renderedOptions = options.map((o) => {
    return (
      <Option key={o._id} value={o.value} url={o.url}>
        {o.value}
      </Option>
    );
  });

  return (
    <div className="search-project-autocomplete">
      {loadingOptions && <Spin size="small" style={{ marginRight: "10px" }} />}
      <AutoComplete
        style={{
          width: 150,
        }}
        onSearch={onSearch}
        onSelect={onSelect}
        value={value}
        popupMatchSelectWidth={value.length > 1 ? 850 : 250}
        onDropdownVisibleChange={handleDropdownChange}
        open={isDropdownOpen}
        defaultActiveFirstOption={true}
        allowClear
        onClear={closeDropdown}
        placeholder="Search project"
        notFoundContent={
          value.length <= 1
            ? "Please type at least 2 characters"
            : loadingOptions
            ? "Loading..."
            : "No Match"
        }
        // TODO: add a class to the dropdown and nest all css under that class. Once antd has been updated < 4.23.0
        // popupClassName='search-autocomplete-dropdown'
      >
        {renderedOptions}
      </AutoComplete>
    </div>
  );
};

export default SearchProjectAutocomplete;
