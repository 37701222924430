export interface Data {
  key: string;
  value: string;
  title: string;
}

export interface Language {
  code: string;
  name: string;
}

const translator: Data = {
  key: "Translator",
  value: "Translator",
  title: "Translator",
};

const languageLead: Data = {
  key: "Language Lead",
  value: "Language_Lead",
  title: "Language Lead",
};

const projectManager: Data = {
  key: "Project Manager",
  value: "Project_Manager",
  title: "Project Manager",
};

const talentManager: Data = {
  key: "Talent Manager",
  value: "Talent_Manager",
  title: "Talent Manager",
};

const techServices: Data = {
  key: "Tech Services",
  value: "Tech_Services",
  title: "Tech Services",
};

const techSupport: Data = {
  key: "Tech Support",
  value: "Tech_Support",
  title: "Tech Support",
};

const tester: Data = {
  key: "Tester",
  value: "Tester",
  title: "Tester",
};

const testLead: Data = {
  key: "Test Lead",
  value: "Test_Lead",
  title: "Test Lead",
};

const subtitling: Data = {
  key: "LQE Subtitling",
  value: "LQE_Subtitling",
  title: "LQE Subtitling",
};

const nonSubtitling: Data = {
  key: "LQE Non Subtitling",
  value: "LQE_Non_Subtitling",
  title: "LQE Non Subtitling",
};

const all: Data = {
  key: "All",
  value: "All",
  title: "All",
};

const lifeSciences: Data = {
  key: "Life Sciences",
  value: "Life_Sciences",
  title: "Life Sciences",
};

export const programRoles: Data[] = [
  translator,
  languageLead,
  {
    key: "GDT",
    value: "GDT",
    title: "GDT",
  },
  projectManager,
  talentManager,
  techServices,
  techSupport,
  tester,
  testLead,
  subtitling,
  nonSubtitling,
];

export const roles: Data[] = [
  {
    key: "Linguist",
    value: "Linguist",
    title: "Linguist",
  },
  projectManager,
  talentManager,
  techServices,
  techSupport,
  tester,
  testLead,
  {
    key: "GDT Lead",
    value: "GDT_Lead",
    title: "GDT Lead",
  },
  subtitling,
  nonSubtitling,
];

export const legoDragonLevels: Data[] = [
  translator,
  languageLead,
  subtitling,
  nonSubtitling,
  {
    key: "Google Mandatory Trainings - Non Linguist (i.e. PM, TM, GDT, Tech Services)",
    value:
      "Google Mandatory Trainings - Non Linguist (i.e. PM, TM, GDT, Tech Services)",
    title:
      "Google Mandatory Trainings - Non Linguist (i.e. PM, TM, GDT, Tech Services)",
  },
];

export const contentType: Data[] = [
  all,
  {
    key: "Help",
    value: "Help",
    title: "Help",
  },
  {
    key: "Marketing",
    value: "Marketing",
    title: "Marketing",
  },
  {
    key: "UI",
    value: "UI",
    title: "UI",
  },
  {
    key: "API",
    value: "API",
    title: "API",
  },
  {
    key: "Legal",
    value: "Legal",
    title: "Legal",
  },
  {
    key: "Other",
    value: "Other",
    title: "Other",
  },
  lifeSciences,
];

export const statusOptions: Data[] = [
  { key: "Yes", value: "Yes", title: "Yes" },
  { key: "No", value: "No", title: "No" },
  { key: "NA", value: "NA", title: "NA" },
];

export const tcOptions: Data[] = [
  { key: "Added", value: "Added", title: "Added" },
  { key: "Pending", value: "Pending", title: "Pending" },
  { key: "NA", value: "NA", title: "NA" },
];

export const requestTypes: Data[] = [
  { key: "New", value: "New", title: "New" },
  { key: "Suspension", value: "Suspension", title: "Suspension" },
  { key: "Reactivation", value: "Reactivation", title: "Reactivation" },
];
