import React, { useCallback, useEffect, useState } from "react";
import { Button } from "antd";
import { useMercuryContext } from "../../../user-context";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

export default function DeleteButton ({ record, reload }:any) {
  const { gpClient: client } = useMercuryContext(),
    [loading, setLoading] = useState(false),
    [disabled, setDisabled] = useState(["INVOICED", "PENDING_INVOICE"].indexOf(record.status?.toUpperCase()) !== -1),
    [doubleTap, setDoubleTap] = useState(false),
    [visible, setVisible] = useState(true);

  useEffect(() => {
    if ((["INVOICED", "PENDING_INVOICE"].indexOf(record.status?.toUpperCase()) !== -1) ||
    ( !record.statusMap || record.statusMap.PENDING_INVOICE !== 0 || record.statusMap.INVOICED !== 0 )) {
      setVisible(false);
    }
  }, []);

  useEffect(() => {
    if (doubleTap) {
      const timer = setTimeout(() => {
        setDoubleTap(false);
      }, 2000);
      
      // Clear the timer when the component unmounts or the doubleTap state changes
      return () => clearTimeout(timer);
    }
  }, [doubleTap]);

  const send = useCallback(() => {
    if (!doubleTap) { setDoubleTap(true); return; }

    setLoading(true);
    client.delete(`/google-gp-v1/adjustments/${record._id}`).then(() => {
        reload();
      })
      .catch((e:any) => {
        console.error(e);
        setDisabled(true);
    });
    setLoading(false);
  }, [client, doubleTap, record._id, reload]);

  return visible ? (
    <Button
      danger={true}
      type={(doubleTap || loading) ? "dashed" :'default'}
      disabled={loading || disabled}
      loading={loading}
      className={doubleTap ? "doubleTap" : ""}
      onClick={send}
    >
      {doubleTap ? (
        <ExclamationCircleOutlined style={{ color: "var(--red)" }} />
      ) : (
        <DeleteOutlined />
      )}
    </Button>
  ) : (
    <></>
  );
}