import React from "react";
import {Form, Select} from "antd";

const { Option } = Select;

export default function TargetLanguage( props ) {

  const
    {
      selectedTLC,
      handleTLCChange,
      targetLangageCodes
    } = props;

  return (
    <Form.Item
      label="Target Language Code"
      name="targetLanguageCode"
      rules={[{ required: false}]}
      key={props.key}
    >
      <Select
        className='select-multiple'
        mode="multiple"
        style={{ width: '100%' }}
        placeholder="Please select"
        value={selectedTLC}
        onChange={handleTLCChange}
      >
        {
          ( targetLangageCodes || [] ).map( ( p ) => {
            return <Option key={p.key} value={p.title}>{p.title}</Option>
          })
        }
      </Select>
    </Form.Item>
  )
}