import React from "react";
import moment from "moment-timezone";
import { useMercuryContext } from "../user-context";

export interface TimezoneProps {
    date: Date|string;
    format?: string;
}

const TimezoneConverter = ({ date, format }:TimezoneProps) => {
    const { timezone } = useMercuryContext();

    const displayedDate = date
        ? moment.tz( date instanceof Date ? date.toString : date, timezone).format(format || "DD MMM YYYY, HH:mm:ss")
        : <i className={"muted"}>Not set</i>;
    return <>{displayedDate}</>;
};

export default TimezoneConverter;
