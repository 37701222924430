import { Descriptions } from "antd";

const PlpInvoicing = (props: any) => {
  const { invoiceData, workLog, totalWeightedWordCount } = props;
  const {
    wordRate,
    wordCharge,
    serviceRate,
    serviceCharge,
    dtpCharge,
    dtpRate,
    engineeringCharge,
    engineeringRate,
    pmFeeCharge,
    pmFeeRate,
    totalCharge,
  } = invoiceData;

  const { dtpHours, engineeringHours, serviceHours } = workLog || {};

  return (
    <Descriptions bordered={true} column={3} size={"small"}>
      <Descriptions.Item label="Word Charge">{wordCharge}</Descriptions.Item>
      <Descriptions.Item label="Total Weighted Word Count">
        {totalWeightedWordCount}
      </Descriptions.Item>
      <Descriptions.Item label="Word Rate">{wordRate}</Descriptions.Item>
      <Descriptions.Item label="Service Charge">
        {serviceCharge}
      </Descriptions.Item>
      <Descriptions.Item label="Service Hours">
        {serviceHours}
      </Descriptions.Item>
      <Descriptions.Item label="Service Rate">{serviceRate}</Descriptions.Item>
      <Descriptions.Item label="DTP Charge">{dtpCharge}</Descriptions.Item>
      <Descriptions.Item label="DTP Hours">{dtpHours}</Descriptions.Item>
      <Descriptions.Item label="DTP Rate">{dtpRate}</Descriptions.Item>
      <Descriptions.Item label="Engineering Charge">
        {engineeringCharge}
      </Descriptions.Item>
      <Descriptions.Item label="Engineering Hours">
        {engineeringHours}
      </Descriptions.Item>
      <Descriptions.Item label="Engineering Rate">
        {engineeringRate}
      </Descriptions.Item>
      <Descriptions.Item label="PM Charge">{pmFeeCharge}</Descriptions.Item>
      <Descriptions.Item label="">{""}</Descriptions.Item>
      <Descriptions.Item label="PM Rate %">{pmFeeRate}</Descriptions.Item>
      <Descriptions.Item label="Total Charge">{totalCharge}</Descriptions.Item>
    </Descriptions>
  );
};

export default PlpInvoicing;
