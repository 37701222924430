import React from "react";
import axios from "axios";
import {
  Select,
  Modal,
  Table,
  InputNumber,
  Button,
  Typography,
  Tooltip,
  Spin,
} from "antd";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Text } = Typography;

/*** Table Config END ***/

class UpdateProjectStatus extends React.Component {
  state = {
    selectValue: null,
    visible: false,
    dataSource: [],
    visibleMessage: false,
    errorMessage: false,
    informationMessage: null,
    validationPassed: false,
    editableDuration: true,
    durationValue: localStorage.getItem(`durationValue-${this.props.shortId}`),
  };

  // Table
  // table columns
  columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 500,
    },
    {
      title: "Stage",
      dataIndex: "status",
      width: 350,
      render: (text, record) => {
        switch (true) {
          case record.status === "Awaiting Submission":
            return text;

          case record.status === "Submitting":
            return (
              <div>
                <Spin
                  style={{
                    marginRight: "10px",
                  }}
                />{" "}
                {text}
              </div>
            );

          case record.status === "Submitted":
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CheckCircleOutlined
                  style={{
                    color: "var(--tertiary-color)",
                    marginRight: "10px",
                    fontSize: "17px",
                  }}
                />{" "}
                {text}
              </div>
            );

          default:
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CloseCircleOutlined
                  style={{
                    color: "var(--red)",
                    marginRight: "10px",
                    fontSize: "17px",
                  }}
                />{" "}
                {text}
              </div>
            );
        }
      },
    },
  ];

  // handle changes input number - duration
  handleChangeInput = (value) => {
    //saves in state the new value
    this.setState({
      durationValue: value,
    });

    // display error message if duration is less than 1
    if (value < 1) {
      this.setState({
        visibleMessage: true,
        errorMessage: true,
        informationMessage:
          "Minimum value for duration (days): 1. Please check again",
      });
    } else {
      this.setState({
        visibleMessage: false,
        errorMessage: false,
        informationMessage: null,
      });
    }
  };

  // Modal
  // handle modal changes
  handleUpdate = async (value) => {
    // table data updated
    const data = this.props.details.map((item, index) => {
      return {
        key: index,
        name: item.document,
        duration: 0,
        status: "Awaiting Submission",
        resourceName: item.resourceName,
      };
    });

    // saves in state selected value of the dropdown and updates dataSource
    this.setState({
      visible: true,
      selectValue: value,
      dataSource: data,
    });
  };

  // hides modal
  hideModal = () => {
    this.setState({
      visible: false,
      dataSource: [],
      selectValue: null,
      visibleMessage: false,
      errorMessage: false,
      informationMessage: null,
      validationPassed: false,
      editableDuration: true,
    });
  };

  // Modal

  // Handle click ok button of the modal
  /***** SOURCE_PRE_PROCESSED Handle *****/
  handleOkSourcePreProcessed = () => {
    const requestedStatus = this.state.selectValue;

    // single promise body
    const promise = (object) => {
      return new Promise((resolve, reject) => {
        // Updates status START
        const newData = [...this.state.dataSource];

        //find this object
        const targetObj = newData.find((item) => item.key === object.key);

        //modify object
        const updatedStatus = {
          ...object,
          status: "Submitting",
        };
        Object.assign(targetObj, updatedStatus);
        // saves in state new value
        this.setState({
          dataSource: newData,
        });
        // Update status END

        let headers = {
          Authorization: `Bearer ${this.props.accessToken}`,
        };

        // Converts from days to hours
        // days on UI, seconds on api request

        const finalDuration = this.state.durationValue * 24 * 60 * 60;

        axios
          .post(
            `${this.props.baseURI}/projects/${this.props.shortId}/files/status`,
            {
              resourceName: object.resourceName,
              status: requestedStatus,
              postProcessingDuration: finalDuration,
            },
            {
              headers: headers,
            }
          )
          .then((response) => {
            // promise will pass
            resolve(response);
            // updates status
            const updatedStatus = {
              ...object,
              status: "Submitted",
            };
            Object.assign(targetObj, updatedStatus);
            // saves in state new value
            this.setState({
              dataSource: newData,
            });
          })
          .catch((error) => {
            // promise will fail
            if (error.response) {
              reject(error);
              // updates status
              const updatedStatus = {
                ...object,
                status: error.response && error.response.data.error,
              };
              Object.assign(targetObj, updatedStatus);
              // saves in state new value
              this.setState({
                dataSource: newData,
              });
            }
          });
      });
    };

    const data = this.state.dataSource;

    const promises = [];

    // Checks if all duration fields have at least value 1
    if (this.state.durationValue > 0) {
      // saves in local storage the duration value - used shord it to save it as unique key
      localStorage.setItem(
        `durationValue-${this.props.shortId}`,
        this.state.durationValue
      );
      // loop through dataSource and return a new promise for each object
      // if we want to display the result on the same time for all files we should include it inside Promise.all
      data.forEach((object) => {
        promises.push(promise(object));
      });

      // if duration is greater than 1 , it will hide the message, change validation to passed and disable editing
      this.setState({
        visibleMessage: false,
        errorMessage: false,
        informationMessage: null,
        validationPassed: true,
        editableDuration: false,
      });

      // show a message when all promises have been completed successfully
      Promise.all(promises)
        .then((result) => {
          result &&
            console.log(
              "All change status requests have been submitted successfully."
            );
        })
        .catch((error) => {
          console.log("Failed ", error);
        });
    } else {
      // display error message if duration is less than 1
      this.setState({
        visibleMessage: true,
        errorMessage: true,
        informationMessage:
          "Minimum value for duration (days): 1. Please check again",
      });
    }
  };

  /***** TRANSLATED_POST_PROCESSED Handle *****/
  handleOkTranslatedPostProcessed = () => {
    const requestedStatus = this.state.selectValue;

    // single promise body
    const promise = (object) => {
      return new Promise((resolve, reject) => {
        // Updates status START
        const newData = [...this.state.dataSource];

        //find this object
        const targetObj = newData.find((item) => item.key === object.key);

        //modify object
        const updatedStatus = {
          ...object,
          status: "Submitting",
        };
        Object.assign(targetObj, updatedStatus);
        // saves in state new value
        this.setState({
          dataSource: newData,
        });
        // Update status END

        let headers = {
          Authorization: `Bearer ${this.props.accessToken}`,
        };

        axios
          .post(
            `${this.props.baseURI}/projects/${this.props.shortId}/files/status`,
            {
              resourceName: object.resourceName,
              status: requestedStatus,
            },
            {
              headers: headers,
            }
          )
          .then((response) => {
            // promise will pass
            resolve(response);
            // updates status
            const updatedStatus = {
              ...object,
              status: "Submitted",
            };
            Object.assign(targetObj, updatedStatus);
            // saves in state new value
            this.setState({
              dataSource: newData,
            });
          })
          .catch((error) => {
            // promise will fail
            if (error.response) {
              reject(error);
              // updates status
              const updatedStatus = {
                ...object,
                status: error.response && error.response.data.error,
              };
              Object.assign(targetObj, updatedStatus);
              // saves in state new value
              this.setState({
                dataSource: newData,
              });
            }
          });
      });
    };

    const data = this.state.dataSource;

    const promises = [];

    // loop through dataSource and return a new promise for each object
    // if we want to display the result on the same time for all files we should include it inside Promise.all
    data.forEach((object) => {
      promises.push(promise(object));
    });

    // it will hide the message
    this.setState({
      visibleMessage: false,
      errorMessage: false,
      informationMessage: null,
      validationPassed: true,
    });

    // show a message when all promises have been completed successfully
    Promise.all(promises)
      .then((result) => {
        result &&
          console.log(
            "All change status requests have been submitted successfully."
          );
      })
      .catch((error) => console.log("Failed all promises", error));
  };

  // handle click of Close and refresh button on Modal- after validation
  handleCloseRefresh = () => {
    this.hideModal();
    this.props.handleRefresh();
  };

  render() {
    return (
      <React.Fragment>
        <Select
          placeholder={"Change Status"}
          style={{ width: 135 }}
          popupMatchSelectWidth={false}
          className="select-change-status"
          onSelect={this.handleUpdate}
          value={this.state.selectValue}
        >
          {this.props.projectStatus === "IN_PREPROCESSING" && (
            <Option value="SOURCE_PRE_PROCESSED">Source Pre Processed</Option>
          )}
          {this.props.projectStatus !== "IN_PREPROCESSING" && (
            <Option value="TRANSLATED_POST_PROCESSED">
              Translation Post Processed
            </Option>
          )}
        </Select>

        <Modal
          className="update-status-modal"
          title={this.props.title}
          open={this.state.visible}
          maskClosable={true}
          closable={false}
          width={"1150px"}
          footer={
            <div style={{ textAlign: "right" }}>
              {this.state.validationPassed === false ? (
                <Tooltip
                  placement="left"
                  color={"var(--red)"}
                  title={"This action is irreversible, are you sure?"}
                >
                  <Button
                    type="primary"
                    onClick={
                      this.state.selectValue === "SOURCE_PRE_PROCESSED"
                        ? this.handleOkSourcePreProcessed
                        : this.state.selectValue === "TRANSLATED_POST_PROCESSED"
                        ? this.handleOkTranslatedPostProcessed
                        : ""
                    }
                  >
                    OK
                  </Button>
                </Tooltip>
              ) : (
                <Button type="primary" onClick={this.handleCloseRefresh}>
                  Close and Refresh
                </Button>
              )}
              {this.state.validationPassed === false && (
                <Button
                  type="secondary"
                  onClick={this.hideModal}
                  style={{ marginLeft: "20px" }}
                >
                  Cancel
                </Button>
              )}
            </div>
          }
        >
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Table
              columns={this.columns}
              className="modal-table"
              dataSource={this.state.dataSource}
              size="small"
              pagination={false}
              style={{
                width: "100%",
              }}
            />
            {this.state.selectValue === "SOURCE_PRE_PROCESSED" && (
              <div className="duration-input-wrap">
                <Text
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Duration (days)
                </Text>
                {this.state.editableDuration ? (
                  <InputNumber
                    min={1}
                    onChange={(value) => this.handleChangeInput(value)}
                    value={this.state.durationValue}
                  />
                ) : (
                  <InputNumber disabled value={this.state.durationValue} />
                )}
              </div>
            )}
          </div>
          {this.state.visibleMessage && (
            <Text
              type={this.state.errorMessage === true ? "danger" : "success"}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                fontWeight: "bold",
              }}
            >
              {this.state.informationMessage}
            </Text>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

export default UpdateProjectStatus;
