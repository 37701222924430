import React from "react";
import { Breadcrumb, Tooltip, Typography } from "antd";
import "./Breadcrumbs.scss";
import { useNavigate } from "react-router-dom";

export interface BreadcrumbItemType {
  key: string;
  label?: string;
  href?: string; // Optional: if you still want to track links
  tooltip?: string; // Optional: tooltip text
  type?: string; // Type to identify if it is a separator
  separator?: string; // Custom separator string
  level?: 1 | 2 | 3 | 4 | 5; // Allowed levels for Ant Design Title component
  color?: string; // Custom color for breadcrumb item
}

export interface BreadcrumbsProps {
  items: BreadcrumbItemType[];
  separator?: string; // Optional: pass separator if needed
}

const { Title } = Typography;

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items, separator }) => {
  const navigate = useNavigate();

  const breadcrumbItems = items.map((item, index) => {
    const isLastItem = index === items.length - 1;

    if (item.type === "separator") {
      return {
        key: `separator-${index}`,
        title: (
          <Title
            className="separator breadcrumb-item"
            level={item.level ?? 4}
            style={{
              color: item.color ?? "inherit",
            }}
          >
            {item.separator ?? "/"}
          </Title>
        ),
      };
    }

    return {
      key: item.key,
      title: (
        <Tooltip title={item.tooltip ?? ""}>
          <Title
            onClick={() => !isLastItem && item.href && navigate(item.href)}
            level={item.level ?? 4}
            style={{
              color: item.color ?? "rgba(40, 44, 54, 0.88)",
              fontSize: !item?.color ? "18px" : "",
              cursor: !item.tooltip ? "default" : "pointer",
            }}
            className="breadcrumb-item-active"
          >
            {item.label}
          </Title>
        </Tooltip>
      ),
    };
  });

  return (
    <Breadcrumb
      separator={separator}
      className="custom-breadcrumb"
      items={breadcrumbItems}
    />
  );
};

export default Breadcrumbs;
