export const JustificationCodes = [
  { value: 0, name: '0 - Normal' },
  { value: 1, name: '1 - Outside vendor control' },
  { value: 2, name: '2 - Rush project' },
  { value: 3, name: '3 - Late from translation' },
  { value: 4, name: '4 - Late from review' },
  {
    value: 5,
    name: '5 - Reassignment of project',
  },
];

export const StatusCodes = [
  { value: 'queued', name: 'queued' },
  { value: 'pending', name: 'pending' },
  { value: 'approved', name: 'approved' },
  { value: 'approved_modified', name: 'approved_modified' },
  { value: 'rejected', name: 'rejected' },
];

export const BulkOptions = [
  { value: 'true', name: 'true' },
  { value: 'false', name: 'false' },
];
