import React, { useState, useEffect } from "react";
import Total from "../../../ui/Total";
import { Grid, Tag } from "antd";

import { Table, Typography, Skeleton, Input, Spin, Row } from "antd";

import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  ReloadOutlined,
} from "@ant-design/icons";

import UniversalButton from "../../../ui/UniversalButton";

const UserTable = ({ values, handlers }) => {
  const { users, sortedInfo, editing, total, isLoading } = values,
    { handleChangeTable, handleClickEdit, handleRefresh } = handlers,
    { Link, Title } = Typography,
    { Search } = Input,
    [searchValue, setSearchValue] = useState(""),
    breaks = Grid.useBreakpoint(),
    [filterTable, setFilterTable] = useState(null);

  const data =
    users &&
    users.map((item, index) => {
      return {
        ...item,
        key: item._id,
        vendorManager: item?.admin ? true : item?.vendorManager,
      };
    });

  const resetSearch = () => {
    setFilterTable(null);
    setSearchValue("");
  };

  useEffect(() => {
    users && resetSearch();
  }, [users]);

  const responsiveColumns = () => {
    if (breaks.lg) {
      return [
        {
          title: "First Name",
          dataIndex: "firstName",
          sorter: (a, b) => {
            a = a.firstName === null ? "" : a.firstName;
            b = b.firstName === null ? "" : b.firstName;
            return a.localeCompare(b);
          },
          sortOrder: sortedInfo.field === "firstName" && sortedInfo.order,
          render: (text, record) =>
            editing.isEditing ? (
              record.lastName === editing.editingRow.lastName ? (
                text
              ) : (
                <Skeleton active size="small" title={true} paragraph={false} />
              )
            ) : (
              text
            ),
        },
        {
          title: "Last name",
          dataIndex: "lastName",
          sorter: (a, b) => {
            a = a.lastName === null ? "" : a.lastName;
            b = b.lastName === null ? "" : b.lastName;
            return a.localeCompare(b);
          },
          sortOrder: sortedInfo.field === "lastName" && sortedInfo.order,
          render: (text, record) =>
            editing.isEditing ? (
              record.lastName === editing.editingRow.lastName ? (
                text
              ) : (
                <Skeleton active size="small" title={true} paragraph={false} />
              )
            ) : (
              text
            ),
        },
        {
          title: "Email",
          dataIndex: "email",
          sorter: (a, b) => {
            a = a.email === null ? "" : a.email;
            b = b.email === null ? "" : b.email;
            return a.localeCompare(b);
          },
          sortOrder: sortedInfo.field === "email" && sortedInfo.order,
          render: (text, record) =>
            editing.isEditing ? (
              record.lastName === editing.editingRow.lastName ? (
                <a href={`mailto:${text}`}>{text}</a>
              ) : (
                <Skeleton active size="small" title={true} paragraph={false} />
              )
            ) : (
              <a href={`mailto:${text}`}>{text}</a>
            ),
        },
        {
          title: "Customer Applications",
          dataIndex: "customerApplications",
          width: 180,
          render: (text, record) =>
            editing.isEditing ? (
              record.lastName === editing.editingRow.lastName ? (
                record.customerApplications &&
                record.customerApplications.map((ca) => (
                  <Tag key={ca._id}>{ca.name}</Tag>
                ))
              ) : (
                <Skeleton active size="small" title={true} paragraph={false} />
              )
            ) : (
              record.customerApplications &&
              record.customerApplications.map((ca) => (
                <Tag key={ca._id}>{ca.name}</Tag>
              ))
            ),
        },
      ];
    }

    return [
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => {
          a = a.name === null ? "" : a.name;
          b = b.name === null ? "" : b.name;
          return a.localeCompare(b);
        },
        sortOrder: sortedInfo.field === "name" && sortedInfo.order,
        render: (text, record) =>
          editing.isEditing ? (
            record.name === editing.editingRow.name ? (
              <>
                <span>{record.name}</span>
                <br />
                <a href={`mailto:${record.email}`}>{record.email}</a>
              </>
            ) : (
              <Skeleton active size="small" title={true} paragraph={false} />
            )
          ) : (
            <>
              <span>{record.name}</span>
              <br />
              <span>{record.email}</span>
            </>
          ),
      },
    ];
  };

  const columns = [
    ...responsiveColumns(),
    ...[
      {
        title: "Admin",
        dataIndex: "admin",
        sorter: (a, b) => {
          a = a.admin === null ? "" : a.admin;
          b = b.admin === null ? "" : b.admin;
          return a - b;
        },
        sortOrder: sortedInfo.field === "admin" && sortedInfo.order,
        render: (text, record) =>
          editing.isEditing ? (
            record.lastName === editing.editingRow.lastName ? (
              record.admin === true ? (
                <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
              ) : (
                <CloseOutlined style={{ color: "var(--red)" }} />
              )
            ) : (
              <Skeleton active size="small" title={true} paragraph={false} />
            )
          ) : record.admin === true ? (
            <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
          ) : (
            <CloseOutlined style={{ color: "var(--red)" }} />
          ),
      },
      {
        title: "Vendor Manager",
        dataIndex: "vendorManager",
        width: 100,
        sorter: (a, b) => {
          a = a.vendorManager === null ? "" : a.vendorManager;
          b = b.vendorManager === null ? "" : b.vendorManager;
          return a - b;
        },
        sortOrder: sortedInfo.field === "vendorManager" && sortedInfo.order,
        render: (text, record) =>
          editing.isEditing ? (
            record.lastName === editing.editingRow.lastName ? (
              record.vendorManager === true ? (
                <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
              ) : (
                <CloseOutlined style={{ color: "var(--red)" }} />
              )
            ) : (
              <Skeleton active size="small" title={true} paragraph={false} />
            )
          ) : record.vendorManager === true ? (
            <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
          ) : (
            <CloseOutlined style={{ color: "var(--red)" }} />
          ),
      },
      {
        title: "Active",
        dataIndex: "isActive", // TODO: fix backend to pass isActive to all users
        sorter: (a, b) => {
          a = a.isActive === null ? "" : a.isActive;
          b = b.isActive === null ? "" : b.isActive;
          return a - b;
        },
        sortOrder: sortedInfo.field === "isActive" && sortedInfo.order,
        render: (text, record) =>
          text === true ? (
            <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
          ) : (
            <CloseOutlined style={{ color: "var(--red)" }} />
          ),
      },
      {
        title: "Edit",
        dataIndex: "edit",
        render: (text, record) =>
          editing.isEditing &&
          record.lastName !== editing.editingRow.lastName ? (
            <Skeleton active size="small" title={true} paragraph={false} />
          ) : (
            <Link
              onClick={() => {
                handleClickEdit(record);
              }}
              style={{
                marginRight: "10px",
              }}
            >
              <EditOutlined /> Edit
            </Link>
          ),
      },
    ],
  ];

  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = (value) => {
    // ToDo: search should be handled server side
    const filterTable = data.filter((item) => {
      const searchValue = value.toLowerCase(),
        firstName = item.firstName.toString().toLowerCase(),
        lastName = item.lastName.toString().toLowerCase(),
        email = item.email.toString().toLowerCase();

      return (
        firstName.includes(searchValue) ||
        lastName.includes(searchValue) ||
        email.includes(searchValue)
      );
    });
    setFilterTable(filterTable);
  };

  return (
    <>
      <UniversalButton
        clickMethod={handleRefresh}
        text="Refresh"
        icon={<ReloadOutlined />}
      />
      <Search
        style={{ width: "300px", margin: "0 0 10px 0" }}
        placeholder="Search..."
        onSearch={handleSearch}
        onChange={handleChangeSearch}
        value={searchValue}
        allowClear
        enterButton
      />
      <Row className="title-wrap">
        <Title level={5} style={{ margin: "5px 0 0" }}>
          Users
          {editing.isEditing && (
            <Spin size="small" style={{ marginLeft: "10px" }} />
          )}
        </Title>
        <Total number={total} text="users" />
      </Row>
      <Table
        columns={columns}
        dataSource={users && (!filterTable ? [...data] : [...filterTable])}
        className="manage-page-table"
        onChange={handleChangeTable}
        loading={isLoading}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: 20,
        }}
      />
    </>
  );
};

export default UserTable;
