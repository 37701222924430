import React, { useRef } from "react";
import {Button, Collapse, ConfigProvider, Form, InputNumber, List, Modal, Space, Tooltip, Typography,} from "antd";
import {DeleteOutlined, EditOutlined, ReloadOutlined, UndoOutlined, WarningOutlined,} from "@ant-design/icons";
import EditWordCount from "../editPLPForm/EditWordCount";
import EditHours from "../editPLPForm/EditHours";
import SetWMTPONumber from "../editPLPForm/SetWMTPONumber";
import NewRateForm from "../newRateForm/NewRateForm";
import PLPRecoveryForm from "../plpRecoveryForm/PLPRecoveryForm";
import NumericInput from "../../../ui/NumericInput";
import {failedNotification,} from "../../../utils/notifications";
import {useMercuryContext} from "../../../user-context";
import { DocumentWordCountsWizard } from "../../../stories/DocumentWordCountsWizard/DocumentWordCountsWizard"
import {BASE_URL} from "../../../utils/variables";
import { InputNumberProps } from "antd/lib";

const InvoicingActions = ({ handlers, values }: any) => {
    const { accessToken } = useMercuryContext();
    const
    { Paragraph, Text } = Typography,
    { Panel } = Collapse,
    [form] = Form.useForm(),
    { flags } = useMercuryContext(),
    {
        handleClickEditWWC,
        handleClickEditHours,
        handleClickDelete,
        handleClickEstimate,
        handleClickRate,
        handleClickAccrue,
        //handleClickInvoice,
        // handleClickDifferentRate,
        handleDeleteProject,
        handleSubmitNewPMFee,
        handleFinishEditingWWC,
        handleFinishEditingHours,
        handleClickSetPONumber,
        handleSetWMTPONumber,
        handleClickFinishRate,
            handleClickReload,
            handleSubmitPLPRecovery,
            handleClickPLPRecovery,
            submitQVData,
            submitPLPQVData,
            quietRefresh
        } = handlers,
        {
            activeActions,
            selectedRows,
            isEditingWWC,
            isEditingHours,
            isEditingPO,
            isNewRate,
            isProcessingEstimateSubmission,
            isProcessingAccrualSubmission,
            isProcessingReload,
            details,
            rates,
            deletedPLPs,
            recovering,
            pmFee
        } = values,
        projectType = details?.customVendorProjectMetadata?.projectType,
        varStatus = details?.customVendorProjectMetadata?.varStatus;
        
        const newPMFeeRef = useRef(pmFee);

    const listData: any = [];
    selectedRows.map((item: any) =>
        listData.push({
            name: item.plpLang,
            service: item.service,
            status: item.status,
        })
    );
    const modalContent = (
        <List
            size="small"
            header={<div></div>}
            dataSource={listData}
            renderItem={(item: any) => (
                <List.Item>
                    {item.name} - (Service: {item.service}, Status: {item.status})
                </List.Item>
            )}
        />
    );

    const LQETotalValues = selectedRows?.every((item: any) => details?.customVendorProjectMetadata?.projectType ==="LQE_VIDEO" && (item.wwc === '0' || item.serviceRate === 0));

    // Modal with a list of projects that will be deleted
    const showListModal = () => {
        Modal.confirm({
            title: "Proceed to delete the following?",
            content: modalContent,
            mask: true,
            okText: "Confirm and Delete",
            cancelText: "Cancel",
            onOk: handleClickDelete,
            okType: "danger",
            icon: <WarningOutlined style={{ color: "var(--red)" }} />,
            className: "action-btn delete-btn",
            width: 600,
        });
    };

    const updateFinalIntent = () => {
        Modal.confirm({
            title: "This will update all selected languages",
            // confirmLoading : true,
            onOk: async () => {
                try {
                    let values = await form.validateFields();
                    let finalIntent = values.finalNumberOfIntents ? values.finalNumberOfIntents : null;
                    await submitPLPQVData(selectedRows, {finalNumberOfIntents: finalIntent});

                    form.resetFields();
                } catch (error) {

                    failedNotification("Error", "Failed to update Final Intent")
                }
            },
            content: (
                <Form
                    form={form}
                    layout="vertical"
                    name="FinalNumberOfIntents"
                >
                    <Form.Item
                        name="finalNumberOfIntents"
                    >
                        <NumericInput
                            onPressEnter={() => {}}
                            min={1}
                            max={50}
                        />
                    </Form.Item>
                </Form>
            ),
            mask: true,
            okText: "Confirm",
            cancelText: "Cancel",
            okType: "danger",
            className: "action-btn delete-btn",
        })
        ;
    };

    const updateStartingIntent = () => {
        Modal.confirm({
            title: "Confirmation needed",
            onOk: async () => {
                try {
                    let values = await form.validateFields();
                    let startingIntent = values.startingIntent ? values.startingIntent : null;
                    await submitQVData({startingNumberOfIntents: startingIntent});

                } catch (error) {
                    failedNotification("Error", "Failed to update Google Intent")
                }
            },
            content: (
                <Form
                    form={form}
                    layout="vertical"
                    name="StartingIntent"
                >
                    <Form.Item
                        name="startingIntent"
                        initialValue={
                            details?.queryVariationProjectMetadata?.startingNumberOfIntents ?
                                details.queryVariationProjectMetadata.startingNumberOfIntents :
                                1
                        }
                    >
                        <NumericInput
                            onPressEnter={() => {}}
                            min={1}
                            max={50}
                        />

                    </Form.Item>

                </Form>
            ),
            mask: true,
            okText: "Confirm",
            cancelText: "Cancel",
            //onOk: handleDeleteProject,
            okType: "danger",
            //icon: <WarningOutlined style={{ color: "var(--red)" }} />,
            className: "action-btn delete-btn",
        })
        ;
    };

    const showDeleteConfirmationModal = () => {
        Modal.confirm({
            title: "Confirmation needed",
            content: (
                <>
                    <Paragraph>
                        Note: This will delete the entire project. All the data will be
                        removed from database.
                    </Paragraph>
                    <Text strong>Proceed to delete the full project?</Text>
                </>
            ),
            mask: true,
            okText: "Confirm and Delete",
            cancelText: "Cancel",
            onOk: handleDeleteProject,
            okType: "danger",
            className: "danger-modal",
        });
    };

    const onChangePmFee: InputNumberProps['onChange'] = (value) => {
        newPMFeeRef.current = value;
    };

    const showEditPMFeeModal = () => {
        Modal.confirm({
            title: "Edit PM Fee Rate",
            content: (
                <>
                    <Text>Current PM Fee Rate: {pmFee}%</Text>
                    <hr/>
                    <Text>The new PM Fee Rate will be applied to all plps.</Text>
                    <br></br>
                    <Text>Recalculating the invoice amount may take a moment. If you don't see any changes after submitting, please try reloading the page.</Text>
                    <br></br>
                    <div style={{display: 'flex', alignItems: 'center', margin:'10px 0'}}>
                        <ConfigProvider
                            theme={{
                                components: {
                                InputNumber: {
                                    activeBorderColor:'#00a89f',
                                    handleBorderColor:'#00a89f',
                                    hoverBorderColor:'#00a89f'
                                },
                                },
                            }}
                        >
                            { details && (pmFee !== null && pmFee !== undefined) &&<>
                                <InputNumber
                                    defaultValue={pmFee}
                                    onChange={onChangePmFee} 
                                    />
                                <p style={{marginLeft:'5px', userSelect: 'none'}}>%</p>
                                </>
                            }
                        </ConfigProvider>
                    </div>
                </>
                ),
            mask: true,
            okText: "Ok",
            cancelText: "Cancel",
            okType: "danger",
            closable: true,
            onOk: () => handleSubmitNewPMFee(newPMFeeRef.current),
            className: "danger-modal"
        });
    };

    return (
        <>
            <Collapse defaultActiveKey={["1"]} style={{ margin: "0 15px 20px 15px" }}>
                <Panel header="Actions" key="1" className="actions invoicing-details-actions">
                    <Space
                        size="large"
                        style={{
                            width: "100%",
                            alignItems: "flex-start",
                            flexWrap: "wrap",
                            padding: "0 16px 15px 16px"
                        }}
                    >
                        {activeActions ? (
                            <div>
                                <Button
                                    onClick={handleClickEditWWC}
                                    className="action-btn edit-btn"
                                    icon={<EditOutlined />}
                                    type="primary"
                                >
                                    Edit Word Count
                                </Button>
                                <EditWordCount
                                    visible={isEditingWWC}
                                    onSubmit={handleFinishEditingWWC}
                                    selectedPLPs={selectedRows}
                                />
                            </div>
                        ) : (
                            <Tooltip
                                title="Select at least 1 row"
                                placement="bottom"
                                color={"var(--tertiary-color)"}
                            >
                                <Button
                                    disabled
                                    icon={
                                        <EditOutlined style={{ color: "var(--primary-color)" }} />
                                    }
                                >
                                    Edit Word Count
                                </Button>
                            </Tooltip>
                        )}
                        {activeActions ? (
                            <div>
                                <Button
                                    onClick={handleClickEditHours}
                                    className="action-btn edit-btn"
                                    icon={<EditOutlined />}
                                    type="primary"
                                >
                                    Edit Hours
                                </Button>
                                <EditHours
                                    visible={isEditingHours}
                                    onSubmit={handleFinishEditingHours}
                                    selectedPLPs={selectedRows}
                                    reload={quietRefresh}
                                />
                            </div>
                        ) : (
                            <Tooltip
                                title="Select at least 1 row"
                                placement="bottom"
                                color={"var(--tertiary-color)"}
                            >
                                <Button
                                    disabled
                                    icon={
                                        <EditOutlined style={{ color: "var(--primary-color)" }} />
                                    }
                                >
                                    Edit Hours
                                </Button>
                            </Tooltip>
                        )}
                        {
                            flags.queryVariations && projectType === "QV" ?
                                activeActions ?
                                    (
                                        <Button
                                            onClick={updateStartingIntent}
                                            className="action-btn edit-btn"
                                            disabled={ !activeActions }
                                            type="primary"
                                            icon={
                                                <EditOutlined />
                                            }
                                        >
                                            Edit Start Intent
                                        </Button>
                                    ) :
                                    (
                                        <Button
                                            className="action-btn edit-btn"
                                            disabled
                                            icon={
                                                <EditOutlined style={{ color: "var(--primary-color)" }} />
                                            }
                                        >
                                            Edit Start Intent
                                        </Button>
                                    )
                                :
                                <>
                                </>

                        }
                        {
                            flags.queryVariations && projectType === "QV" ?
                                activeActions ?
                                    (
                                        <Button
                                            onClick={updateFinalIntent}
                                            className="action-btn edit-btn"
                                            disabled={ !activeActions }
                                            type="primary"
                                            icon={
                                                <EditOutlined />
                                            }
                                        >
                                            Edit Final Intent
                                        </Button>
                                    ) :
                                    (
                                        <Button
                                            onClick={updateFinalIntent}
                                            disabled
                                            className="action-btn edit-btn"
                                            icon={
                                                <EditOutlined style={{ color: "var(--primary-color)" }} />
                                            }
                                        >
                                            Edit Final Intent
                                        </Button>
                                    )
                                :
                                <>
                                </>
                        }
                        {projectType === "WMT" && (
                            <>
                                {activeActions ? (
                                    <div>
                                        <Button
                                            onClick={handleClickSetPONumber}
                                            className="action-btn edit-btn"
                                            icon={<EditOutlined />}
                                            type="primary"
                                        >
                                            Set WMT PO Number
                                        </Button>
                                        <SetWMTPONumber
                                            visible={isEditingPO}
                                            onSubmit={handleSetWMTPONumber}
                                            selectedPLPs={selectedRows}
                                        />
                                    </div>
                                ) : (
                                    <Tooltip
                                        title="Select at least 1 row"
                                        placement="bottom"
                                        color={"var(--tertiary-color)"}
                                    >
                                        <Button
                                            disabled
                                            icon={
                                                <EditOutlined
                                                    style={{ color: "var(--primary-color)" }}
                                                />
                                            }
                                        >
                                            Set WMT PO Number
                                        </Button>
                                    </Tooltip>
                                )}
                            </>
                        )}

                        {activeActions ? (
                            <Button
                                className="action-btn delete-btn"
                                type="primary"
                                danger
                                icon={<DeleteOutlined />}
                                onClick={showListModal}
                            >
                                Delete
                            </Button>
                        ) : (
                            <Tooltip
                                title="Select at least 1 row"
                                placement="bottom"
                                color={"var(--tertiary-color)"}
                            >
                                <Button
                                    style={{ borderColor: "var(--red)" }}
                                    type="primary"
                                    danger
                                    disabled
                                    icon={<DeleteOutlined style={{ color: "var(--red)" }} />}
                                >
                                    Delete
                                </Button>
                            </Tooltip>
                        )}
                        {activeActions && !LQETotalValues ? (
                            <Button
                                onClick={handleClickEstimate}
                                className="action-btn estimate-btn action-green-btn"
                                type="primary"
                                loading={isProcessingEstimateSubmission}
                            >
                                Estimate
                            </Button>
                        ) : (
                            <Tooltip
                                title="Select at least 1 row"
                                placement="bottom"
                                color={"var(--tertiary-color)"}
                            >
                                <Button
                                    disabled
                                    style={{ borderColor: "var(--tertiary-color)" }}
                                >
                                    Estimate
                                </Button>
                            </Tooltip>
                        )}
                        {activeActions && !LQETotalValues ? (
                            <Button
                                onClick={handleClickAccrue}
                                className="action-btn accrual-btn action-green-btn"
                                type="primary"
                                loading={isProcessingAccrualSubmission}
                            >
                                Accrual
                            </Button>
                        ) : (
                            <Tooltip
                                title="Select at least 1 row"
                                placement="bottom"
                                color={"var(--tertiary-color)"}
                            >
                                <Button
                                    disabled
                                    style={{ borderColor: "var(--tertiary-color)" }}
                                >
                                    Accrual
                                </Button>
                            </Tooltip>
                        )}
                        {activeActions ? (
                            <div>
                                <Button
                                    onClick={handleClickRate}
                                    className="action-btn different-rate-btn action-green-btn"
                                    icon=""
                                    type="primary"
                                >
                                    New Rate
                                </Button>
                                <NewRateForm
                                    visible={isNewRate}
                                    onSubmit={handleClickFinishRate}
                                    selectedPLPs={selectedRows}
                                    generalInfo={details}
                                    ratesCollection={rates}
                                />
                            </div>
                        ) : (
                            <Tooltip
                                title="Select at least 1 row"
                                placement="bottom"
                                color={"var(--tertiary-color)"}
                            >
                                <Button
                                    disabled
                                    style={{ borderColor: "var(--tertiary-color)" }}
                                >
                                    New Rate
                                </Button>
                            </Tooltip>
                        )}
                        {details?.customVendorProjectMetadata?.projectType ===
                        "LQE_VIDEO" ? (
                            <div> 
                                <DocumentWordCountsWizard shortId={details.shortId} baseURL={BASE_URL as any} accessToken={accessToken} onWizardClose={(dataChanged) => {
                                    if (dataChanged) {
                                        handleClickReload();
                                    }
                                }}/>
                            </div>
                        ) : (
                            <Tooltip
                                title="Select at least 1 row"
                                placement="bottom"
                                color={"var(--tertiary-color)"}
                            >
                                <Button
                                    disabled
                                    style={{ borderColor: "var(--tertiary-color)" }}
                                >
                                    Set Total Charge
                                </Button>
                            </Tooltip>
                        )}
                        <Tooltip
                            title={`Current PM Fee Rate: ${(pmFee)}%`}
                            placement="bottom"
                            color={"var(--red)"}
                        >
                            <Button
                                className="action-btn edit-pm-fee-btn"
                                type="primary"
                                icon={<EditOutlined />}
                                onClick={showEditPMFeeModal}
                                disabled={varStatus && varStatus === 'INVOICE'}
                            >
                                Edit PM Fee Rate
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title="Delete the entire project"
                            placement="bottom"
                            color={"var(--red)"}
                        >
                            <Button
                                className="action-btn delete-btn"
                                type="primary"
                                danger
                                icon={<DeleteOutlined />}
                                onClick={showDeleteConfirmationModal}
                            >
                                Delete Project
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title="Reload the project details"
                            placement="bottom"
                            color={"var(--tertiary-color)"}
                        >
                            <Button
                                className="action-btn reload-btn"
                                icon={<ReloadOutlined />}
                                onClick={handleClickReload}
                                loading={isProcessingReload}
                            >
                                Reload
                            </Button>
                        </Tooltip>
                    </Space>
                </Panel>
            </Collapse>

            {deletedPLPs.length > 0 ? (
                <Collapse defaultActiveKey={["2"]} style={{ margin: "0 15px 20px 15px" }}>
                    <Panel header="Recovery" key="2" className="actions">
                        <Space
                            size="large"
                            style={{
                                width: "100%",
                                alignItems: "flex-start",
                                margin: "7px 8px",
                            }}
                        >
                            {deletedPLPs.length > 0 ? (
                                <Tooltip
                                    title={`Recoverable PLP(s) ${deletedPLPs.length}`}
                                    placement="bottom"
                                    color={"var(--tertiary-color)"}
                                >
                                    <Button
                                        className="action-btn different-rate-btn action-green-btn"
                                        type="primary"
                                        danger
                                        icon={<UndoOutlined />}
                                        onClick={ () => { handleClickPLPRecovery(true) }}
                                    >
                                        Recover PLP
                                    </Button>
                                    <PLPRecoveryForm
                                        values={{
                                            deletedPLPs,
                                            recovering,
                                        }}
                                        handlers={{
                                            handleSubmitPLPRecovery, handleClickPLPRecovery
                                        }}
                                    />
                                </Tooltip>
                            ) : (
                                <>
                                </>
                            )}
                        </Space>
                    </Panel>
                </Collapse>
            ) : (
                <>
                </>) }

        </>
    );
};

export default InvoicingActions;
