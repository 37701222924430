import React, { useState, useEffect, useCallback } from "react";
import { useMercuryContext } from "../../user-context";
import TimezoneConverter from "../../timezone-converter";

import { JustificationCodes } from "./placeholders";

import { PlusSquareFilled } from "@ant-design/icons";
import { Button, Modal, Descriptions } from "antd";
import ExtensionLoader from "./ExtensionLoader";

interface IProps {
  requestId: string;
  visibleDates?: boolean; // on v1 = isDateNeeded
}

const ListExtModal: React.FC<IProps> = ({ requestId, visibleDates = true }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false),
    [requestData, setRequestData] = useState<any>({}),
    [loading, setLoading] = useState<boolean>(false);

  const { client } = useMercuryContext();

  const { Item } = Descriptions;

  const getExtensionData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await client.get(`/google-ges-v1/${requestId}`);
      setRequestData(data);
      setLoading(false);
    } catch (error: any) {
      console.error(error.toString());
      setLoading(false);
    }
  }, [client]);

  useEffect(() => {
    if (isModalVisible) {
      getExtensionData();
    }

    return () => {
      setRequestData({});
    };
  }, [isModalVisible]);

  const printJustDesc = JustificationCodes.find(
    (element) =>
      parseInt(element.name.substring(0, 1)) === requestData.justificationCode
  )?.name?.substring(2);

  return (
    <div className="list-extension-modal">
      <Button
        size="small"
        type="link"
        onClick={() => setIsModalVisible(true)}
        disabled={isModalVisible}
      >
        <PlusSquareFilled style={{marginLeft:'10px'}}/>
      </Button>
      {isModalVisible && (
        /* @ts-ignore */
        <Modal
          className="extension-details-modal"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
          data-testid="extension-modal"
          width={600}
          title="Extension Request Details"
        >
          {loading ? (
            <ExtensionLoader />
          ) : (
            <Descriptions bordered column={1}>
              <Item label="Requested By"> {requestData.requestedBy}</Item>
              {visibleDates && (
                <>
                  <Item label="Created">
                    <TimezoneConverter
                      date={requestData.createdAt}
                      format={"DD MMM YYYY\t hh:mm:ss"}
                    />
                  </Item>
                  <Item label="Last Updated">
                    <TimezoneConverter
                      date={requestData.updatedAt}
                      format={"DD MMM YYYY\t hh:mm:ss"}
                    />
                  </Item>
                </>
              )}
              <Item label="Justification Code">
                {requestData.justificationCode} {printJustDesc}
              </Item>
              <Item label="Justification">{requestData.justification}</Item>
              <Item label="Product">{requestData.product}</Item>
              <Item label="Product Area"> {requestData.productArea}</Item>
            </Descriptions>
          )}
        </Modal>
      )}
    </div>
  );
};

export default ListExtModal;
