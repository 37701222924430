import React, { FunctionComponent } from "react";
import "./logo.scss";

export interface IconProps {
    animate: any;
}

const Icon: FunctionComponent<IconProps> = ({ animate }) => {
    return (
      <svg
        className={`logo-svg ${ animate && "animate"}`}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="43.333"
        height="43.333"
        overflow="visible"
        version="1.1"
        viewBox="0 0 43.333 43.333"
        xmlSpace="preserve"
      >
          <g>
              <defs>
                  <path id="XMLID_4_" d="M-39.667 -39.333H40.917V82.78H-39.667z"/>
              </defs>
              <clipPath id="XMLID_6_">
                  <use xlinkHref="#XMLID_4_"/>
              </clipPath>
              <path
                fill="#00A89F"
                d="M1.04 1.371H19.977999999999998V20.31H1.04z"
                className="__logo-square-tl"
                clipPath="url(#XMLID_6_)"
              />
              <path
                fill="#003D7F"
                d="M9.129 21.723c0 6.771 5.49 12.262 12.263 12.262 6.77 0 12.26-5.49 12.26-12.262s-5.49-12.262-12.26-12.262c-6.773 0-12.263 5.49-12.263 12.262"
                className="__logo-circle"
                clipPath="url(#XMLID_6_)"
              />
              <path
                fill="#00A89F"
                d="M1.04 23.136H19.977999999999998V42.074H1.04z"
                className="__logo-square-bl"
                clipPath="url(#XMLID_6_)"
              />
              <path
                fill="#00A89F"
                d="M22.805 1.371H41.744V20.31H22.805z"
                className="__logo-square-tr"
                clipPath="url(#XMLID_6_)"
              />
          </g>
      </svg>
    );
}

export default Icon;
