import axios from 'axios';

export const bulkGetDetails = async (apis, projectId, accessToken) => {

  // const { projectEndpoint } = apis;
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    // return await axios.get(`${projectEndpoint}${projectId}`, {
    return await axios.get(`${process.env.REACT_APP_SERVICES_API_URL}/google-gp-v1/project/shortId/${projectId}`, {
      headers: headers,
    });
  } catch (error) {
    console.log('Error:' + error);
  }

};

export const docsGetDetails = async (projectId, accessToken) => {

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  try {
    return await axios.post(
      `${process.env.REACT_APP_SERVICES_API_URL}/google-gp-v1/document/search`,
      {
        filter: { projectIds: [`${projectId}`] },
      },
      {
        headers: headers,
      }
    );
  } catch (error) {
    console.log("Error:" + error);
  }

};

export const bulkMatchLanguage = (
  bulkDetails,
  docDetails,
  languageOptions,
  productTypes,
  productAreas,
  userDetails
) => {
  const matchLanguage = [];

  const foundDoc = docDetails[0].documents.map(d => d);

  for (const detail of bulkDetails) {

    for (const language of languageOptions) {

      if (detail === null) continue;

      // if it finds a plp with the same language
      const found = detail?.plps?.find((plp) => plp?.targetLanguageCode === language);

      // if it finds a doc with the same language
      const foundDocLanguage = foundDoc.find((d) => d.targetLanguageCode === language);

      if (!found) continue;

      const matchProductType =
        productTypes && productTypes.find((pType) => pType?.title === found?.product);
      const matchProductArea =
        productAreas &&
        productAreas.find((pArea) => pArea?._id === matchProductType?.productArea);

      const titleContainsCovid = detail?.title.toLowerCase().includes('covid');

      const earliestReceivedDate = found?.documents
        ?.map((item) => item.translationVendorReceivedTimestamp)
        .reduce((a, b) => (Date.parse(a) > Date.parse(b) ? b : a));

      const legallySensitive = found?.documents?.filter(
        (doc) => doc?.legallySensitive?.isSet
      ).length;

      const readyProjectType = detail?.customVendorProjectMetadata?.projectType;

      const statusHistory = found?.customVendorPLPMetadata?.statusHistory && found?.customVendorPLPMetadata?.statusHistory.find((item) => item.status === 'IN_TRANSLATION');

      console.log('earliestReceivedDate: ', JSON.stringify(earliestReceivedDate))
      console.log('statusHistoryTimeStamp: ', JSON.stringify(statusHistory?.timestamp))

      matchLanguage.push({
        title: titleContainsCovid,
        projectId: detail?.shortId,
        shortId: detail?.shortId,
        sourceLanguageCode: detail?.sourceLanguageCode,
        urgency: detail?.turnaroundTimeUrgency,
        product: detail?.product,
        originalDueDate: found?.latestOriginalTranslationDueTimestamp,
        dueDateTier: found?.highestDueDateTier,
        componentType: found?.componentType,
        nonExtendable: matchProductType?.isNonExtendable,
        productArea: matchProductArea?.title,
        plpStatus: found?.plpStatus,
        docStatus: foundDocLanguage?.catToolStatus,
        targetLanguageCode: found?.targetLanguageCode,
        requestedBy: userDetails.email,
        receivedDate: statusHistory?.timestamp ? statusHistory?.timestamp : earliestReceivedDate,
        legallySensitive: legallySensitive !== 0 ? true : false,
        projectType: readyProjectType,
      });

      console.log('matchLanguage: ' + JSON.stringify(matchLanguage))

    }
  }
  return matchLanguage;
};
