import React, {createContext, useEffect } from 'react';
import {Auth0Provider, useAuth0, Auth0ContextInterface} from "@auth0/auth0-react";
import { Route, Link, useLocation, useNavigate, Routes} from "react-router-dom";
import axios, { AxiosInstance } from "axios";
import {MsalProvider} from "@azure/msal-react";
import { Button } from "antd";
import Cookies from 'js-cookie';
import legacySocket from "./NativeSocket"
import {
    Href,
    Location,
    LocationDescriptor, LocationDescriptorObject,
    LocationListener,
    LocationState,
    Path,
    TransitionPromptHook,
    UnregisterCallback
} from "history";
import {
    LDProvider, useFlags,
    useLDClient,
    withLDProvider
} from 'launchdarkly-react-client-sdk';
import NativeSocket from "./NativeSocket";
import {PublicClientApplication} from "@azure/msal-browser";
import {worldwideMsalConfig} from "./AuthVendors/RWSWorldWide";
import {rwsOneConfig} from './AuthVendors/RWSOneEntraID';
import {fMoraviaMsalConfig} from "./AuthVendors/Moravia";
import {vendors} from "./LoginPage";
import { default as io, Socket } from "./SocketIOSocket";
import HoldingPage from "../main/HoldingPage";
import ProgressManager from "../Search/Progress.feedback";

export interface History<HistoryLocationState = LocationState> {
    length: number;
    action: Action;
    location: Location<HistoryLocationState>;
    push(path: Path, state?: HistoryLocationState): void;
    push(location: LocationDescriptor<HistoryLocationState>): void;
    replace(path: Path, state?: HistoryLocationState): void;
    replace(location: LocationDescriptor<HistoryLocationState>): void;
    go(n: number): void;
    goBack(): void;
    goForward(): void;
    block(prompt?: boolean | string | TransitionPromptHook<HistoryLocationState>): UnregisterCallback;
    listen(listener: LocationListener<HistoryLocationState>): UnregisterCallback;
    createHref(location: LocationDescriptorObject<HistoryLocationState>): Href;
}


interface UserDetails {
    email: string;
    email_verified: boolean;
    family_name: string;
    given_name: string;
    name: string;
    nickname: string;
    picture: string;
    updated_at: Date;
}

export interface UserContext {
    accessToken?: string;
    MASToken?:string;
    isAuthenticated: boolean;
    isAuthLoading: boolean;
    userDetails: UserDetails;
    isAdmin: boolean;
    timezone: string;
    baseUrl: string;
    returnTo?:string;
    loginWithRedirect?: () => void;
    client: AxiosInstance;
    legacyClient: AxiosInstance,
    gpClient: AxiosInstance,
    umsClient: AxiosInstance,
    umsSocket: Record<string, Socket>,
    gpSocket: Record<string, Socket>,
    chSocket: Record<string, Socket>,
    useAuth0: Auth0ContextInterface;
    socket: NativeSocket;
    socketInit?:boolean;
    vapid_key?:string;
    history: History;
    navigate: any;
    location: Location,
    LD: LDProvider;
    LD_Ready: boolean;
    logout: () => void;
    loginRequired: boolean;
    setToken: ( token:string ) => void;
    verifyToken: ( token:string ) => boolean;
    removeToken: ( ) => void;
    state:any;
    dispatch:any;
    flags:Record<any, any>
    authMethod?: string;
    switchProvider: (vendor:vendors) => void;
    progress: Map<string, any>;
    progressManager: ProgressManager;
}

export enum ActionTypes {
    UPDATE_TOKEN="UPDATE_TOKEN",
    DELETE_TOKEN="DELETE_TOKEN",
    UPDATE_IS_AUTH="UPDATE_IS_AUTH",
    UPDATE_IS_AUTHLOADING="UPDATE_IS_AUTHLOADING",
    UPDATE_USER_DETAILS="UPDATE_USER_DETAILS",
    UPDATE_IS_ADMIN="UPDATE_IS_ADMIN",
    UPDATE_TIMEZONE="UPDATE_TIMEZONE",
    UPDATE_BASE_URL="UPDATE_BASE_URL",
    ATTACH_LOGIN_METHOD="ATTACH_LOGIN_METHOD",
    ATTACH_SOCKET="ATTACH_SOCKET",
    SOCKET_INIT="SOCKET_INIT",
    VAPID_KEY="VAPID_KEY",
    UPDATE_NOTIFICATIONS="UPDATE_NOTIFICATIONS",
    ATTACH_HISTORY="ATTACH_HISTORY",
    ATTACH_LD="ATTACH_LD",
    CONNECT="CONNECT",
    LD_READY="LD_READY",
    UPDATE_FLAGS="UPDATE_FLAGS",
    LOGIN_REQUIRED="LOGIN_REQUIRED",
    SWITCH_VENDOR="SWITCH_VENDOR",
    UPDATE_PROGRESS="UPDATE_PROGRESS",
    CREATE_PROGRESS_MANAGER="CREATE_PROGRESS_MANAGER"
}

export interface Action {
    type: ActionTypes;
    payload?: any;
    notifier?: any;
    history?: any;
    navigate?: any;
}

type Context = UserContext|{};


const rwsOneInstance = new PublicClientApplication(
    rwsOneConfig as any
);
const msalWorldwideInstance = new PublicClientApplication(
    worldwideMsalConfig as any
);
const msalMoraviaInstance = new PublicClientApplication(
    fMoraviaMsalConfig as any
);

const defaultDispatch: React.Dispatch<Action> = () => {};
const MercuryContext = createContext<Partial<UserContext>>( {
    state: { userDetails: {}, progress: new Map() },
    dispatch: defaultDispatch
} );

function parseJwt (token:string) {
    const
        base64Url = token.split('.')[1],
        base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'),
        jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

    return JSON.parse(jsonPayload);
}

function defaultClient( login: () => void, API_URL:string, token:string ) {
    const instance = axios.create();
    instance.defaults.baseURL = API_URL;
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    instance.defaults.headers.common['Content-Type'] = "application/json";
    console.log( instance.defaults )
    instance.interceptors.response.use(response => {
        return response;
    }, error => {
        console.error( error )
        if ( error.response && error.response.status === 401) {
            return login()
        }
        throw error;
    });

    return instance;
}


function urlBase64ToUint8Array(base64String:string):Uint8Array {
    const
        padding = "=".repeat((4 - base64String.length % 4) % 4),
        base64 = (base64String + padding)
            // eslint-disable-next-line no-useless-escape
            .replace(/\-/g, "+")
            .replace(/_/g, "/"),
        rawData = window.atob(base64),
        outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function Subscribe( register:ServiceWorkerRegistration, vapidKey:string, socket:NativeSocket ) {
    if ( !register.pushManager ) {
        // Apple has a self registering push api, which we wont bother with for now.
        return;
    }
    register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(vapidKey)
    })
    .then( ( subscription) => {
        socket.send( JSON.stringify({ action: "subscribe", payload: subscription.toJSON() }) )
    })
        .catch( console.error )
}

function storeReducer(state:Partial<UserContext>, action:Action ):Context {

    let
        progress = state.progress ?? new Map(),
        requestId, request;

    switch (action.type) {
        case 'UPDATE_TOKEN':
            return {
                ...state,
                accessToken: action.payload,
                isAuthLoading: false,
                socketInit: true,
                loginRequired: false
            };
        case 'DELETE_TOKEN':
            return {
                ...state,
                accessToken: undefined,
                loginRequired: true,
                isAuthenticated: false
            };
        case 'UPDATE_IS_AUTH':
            return {
                ...state,
                isAuthenticated: action.payload,
            };
        case 'UPDATE_IS_AUTHLOADING':
            return {
                ...state,
                isAuthLoading: action.payload,
            };
        case 'UPDATE_USER_DETAILS':

            return {
                ...state,
                isAdmin: action.payload.admin,
                userDetails: action.payload,
                isAuthLoading: false,
                isAuthenticated: true
            };
        case 'UPDATE_NOTIFICATIONS':
            const
                ordered = action.payload.filter( ( n:any ) => n !== null ).sort( ( a:any, b:any ) => {
                    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
                } ),
                [ latest ] = ordered;

            if ( latest && action.notifier) {
                action.notifier.info( {
                    key: latest._id,
                    message: latest.title,
                    description: latest.body,
                    duration: 500,
                    btn: (
                            <Button type="primary" size="small" onClick={() => {
                                if( state.navigate ) {
                                    action.notifier.close()
                                    state.navigate( latest.url );
                                }
                            }}>
                                Show me
                            </Button>
                    )
                })
            }

            return {
                ...state,
                userDetails: {
                    ...state.userDetails,
                    notifications: ordered
                }
            };
        case 'UPDATE_IS_ADMIN':
            return {
                ...state,
                isAdmin: action.payload,
            };
        case 'UPDATE_TIMEZONE':
            return {
                ...state,
                timezone: action.payload,
            };
        case 'UPDATE_BASE_URL':
            return {
                ...state,
                baseUrl: action.payload,
            };

        case 'ATTACH_LOGIN_METHOD':
        case 'ATTACH_SOCKET':
            return {
                ...state,
                ...action.payload,
            };
        case 'SOCKET_INIT':
            return {
                ...state,
                socketInt: false,
            };
        case 'ATTACH_HISTORY':
            return {
                ...state,
                ...action.payload
            };
        case "ATTACH_LD":

            return {
                ...state,
                LD:action.payload.LD
            }
            // break;
        case "LD_READY":

            return {
                ...state,
                LD_Ready: true
            }
        case "UPDATE_FLAGS":
            return {
                ...state,
                flags: action.payload
            }
        case ActionTypes.SWITCH_VENDOR:
            Cookies.set( "auth_method", action.payload );
            return {
                ...state,
                authMethod: action.payload
            }
        case 'LOGIN_REQUIRED':
            return {
                ...state,
                loginRequired: action.payload
            };
        case 'VAPID_KEY':

            navigator.serviceWorker.register('/worker.js', {
                    scope: '/'
                })
                .then( ( register ) => {
                    let serviceWorker;
                    if (register.installing) {
                        serviceWorker = register.installing;
                    } else if (register.waiting) {
                        serviceWorker = register.waiting;
                    } else if (register.active) {
                        serviceWorker = register.active;
                    }

                    if (serviceWorker) {
                        if (serviceWorker.state === "activated") {
                            Subscribe( register, action.payload, state.socket!)
                        }
                        serviceWorker.addEventListener("statechange", (e:any) => {
                            if (e.target.state === "activated") {
                                Subscribe( register, action.payload, state.socket!)
                            }
                        });
                    }
                })
            return {
                ...state,
                vapid_key: action.payload,
            };

        case 'CREATE_PROGRESS_MANAGER':
            return {
                ...state,
                progressManager: action.payload,
            }

        case 'UPDATE_PROGRESS':

            progress.set( action.payload.requestId, action.payload );

            return {
                ...state,
                progress,
            };

        default:
            return state;
    }
}

function ContextProvider(props:React.PropsWithChildren<any>) {

    const
        switchProvider = ( vendor:vendors ) => {
            const current = Cookies.get( "auth_method" );
            if (
                // [ vendors.MORAVIA, vendors.WORLDWIDE ].indexOf( current as vendors ) !== -1 &&
                current !== vendor
            ) {
                Cookies.remove( "auth_method" );
                Cookies.set( "auth_method", vendor );
            }
            dispatch({ type: ActionTypes.SWITCH_VENDOR, payload: vendor })
        },
        removeToken = () => {
            Cookies.remove('mercury_shared_token')
            dispatch({ type: ActionTypes.DELETE_TOKEN } )
        },
        verifyToken = ( token:string ) => {
            try {

                const
                    parsed = parseJwt( token ),
                    { exp } = parsed;

                if ( Date.now() >= exp * 1000  ) {
                    console.error( new Error( "Token has expired" ) )
                    return false;
                }
                return true;
            }
            catch ( e ) {
                console.error( new Error( "Token validation error" ) )
                return false;
            }
        },
        setToken = ( token:string ) => {
            try {
                if ( verifyToken( token ) ) {
                    Cookies.set('mercury_shared_token', token )
                    dispatch({ type: ActionTypes.UPDATE_TOKEN, payload: token } )
                }
                else {
                    removeToken();
                }

            } catch (error) {
                console.error('Token not valid, it will be removed. Redirect to login page.', error)
                removeToken();
            }
        },
        token = Cookies.get('mercury_shared_token'),
        { isAuthenticated, loginWithRedirect, logout } = useAuth0(),
        [_state, dispatch] = React.useReducer<React.Reducer<Context, Action>>(storeReducer, {
            authMethod: Cookies.get( "auth_method" ),
            switchProvider,
            setToken,
            removeToken,
            verifyToken,
            MASToken: token
        }),
        state = _state as UserContext,
        { Loader, REACT_APP_API_URL } = props,
        navigate = useNavigate(),
        location = useLocation(),
        flags = useFlags(),
        LDClient = useLDClient(),
        loggedOut = Cookies.get("logged_out" );

    if ( loggedOut ) {
        Cookies.remove("logged_out" );
        removeToken();
    }

    useEffect( () => {
        dispatch({ type: ActionTypes.UPDATE_FLAGS, payload: flags })
    }, [ flags ] );


    if ( !Object.keys( flags ).length ) {
        return (
            <MultiAuth vendor={state.authMethod} {...props }>
                <Loader logo={true} animate={true} />
            </MultiAuth>
        )
    }

    if ( !state.accessToken && !state.loginRequired ) {
        dispatch({ type: ActionTypes.ATTACH_HISTORY, payload: { navigate, location, logout, removeToken }})
        dispatch({ type: ActionTypes.LOGIN_REQUIRED, payload: true})
    }

    if ( state.loginRequired ) {
        return (
            <MultiAuth vendor={state.authMethod} {...props }>
                <MercuryContext.Provider value={{state, dispatch}} {...props} />
            </MultiAuth>
        )
    }

    if ( state && !state.accessToken && !state.isAuthLoading) {
        dispatch({ type: ActionTypes.ATTACH_HISTORY, payload: { navigate, location, logout }})
        dispatch({ type: ActionTypes.UPDATE_IS_AUTHLOADING, payload: true });
        dispatch({ type: ActionTypes.UPDATE_BASE_URL, payload: props.REACT_APP_BASE_URL } );
        return (
            <MultiAuth vendor={state.authMethod} {...props }>
                <Loader logo={true} animate={true} title={"Getting access token..."} />
            </MultiAuth>
        )
    }

    if( !state.socket && state.socketInit && state.accessToken  ) {

        try {

            const socket = io.create(
                "/core-ums-v2/ws/socket.io",
                token!,
                "user"
            )

            const ums = {
                user: socket,
                comment: io.create(
                    "/core-ums-v2/ws/socket.io",
                    token!,
                    "comment"
                )
            }

            dispatch( { type:ActionTypes.ATTACH_SOCKET, payload: { socket } } );

            const
                gps = {
                    project: io.create(
                        "/google-gp-v1/ws/socket.io",
                        token!,
                        "project"
                    ),
                    plp: io.create(
                        "/google-gp-v1/ws/socket.io",
                        token!,
                        "plp"
                    ),
                    adj: io.create(
                        "/google-gp-v1/ws/socket.io",
                        token!,
                        "adjustment"
                    ),
                    filterStorage: io.create(
                        "/google-gp-v1/ws/socket.io",
                        token!,
                        "filterStorage"
                    ),
                    sharing: io.create(
                        "/google-gp-v1/ws/socket.io",
                        token!,
                        "sharing"
                    )
                },
                chs = {
                    captionHub : io.create(
                        "/core-ch-v1/ws/socket.io",
                        token!,
                        "captionHub"
                    )
                }

            socket.on( "update-user-details", ( payload:any ) => {


                    dispatch({ type: ActionTypes.UPDATE_BASE_URL, payload: props.REACT_APP_BASE_URL } );
                    dispatch({
                        type:ActionTypes.ATTACH_LOGIN_METHOD,
                        payload: {
                            loginWithRedirect,
                            client: defaultClient( loginWithRedirect, props.REACT_APP_API_URL, state.accessToken! ),
                            legacyClient: defaultClient( loginWithRedirect, props.REACT_APP_BASE_URL, state.accessToken! ),
                            gpClient: defaultClient( loginWithRedirect, process.env.REACT_APP_DEV ? "http://localhost:4000" : props.REACT_APP_SERVICES_API_URL, state.accessToken! ),
                            umsClient: defaultClient( loginWithRedirect, process.env.REACT_APP_DEV ? "http://localhost:4000" : props.REACT_APP_SERVICES_API_URL, state.accessToken! ),
                            gpSocket: gps,
                            chSocket: chs,
                            umsSocket: ums,
                            isAuthenticated,
                            useAuth0
                        }
                    });

                    dispatch({ type:ActionTypes.CREATE_PROGRESS_MANAGER, payload: new ProgressManager( dispatch, state.accessToken! )})

                    return dispatch( {
                        type: ActionTypes.UPDATE_USER_DETAILS,
                        navigate,
                        notifier:props.Notifier,
                        payload: {
                            ...payload,
                            ...{
                                picture: payload && payload.picture
                                    ? payload.picture
                                    : null
                            }
                        } })
            })

            socket.on( "notify", (payload:any) => {

                props.Notifier.info( {
                    key: payload._id,
                    message: payload.title,
                    description: payload.body,
                    duration: 500,
                    btn: (
                        <Button type="primary" size="small" onClick={() => {
                            if( state.navigate ) {
                                props.Notifier.close()
                                state.navigate( payload.url );
                            }
                        }}>
                            Show me
                        </Button>
                    )
                })

                socket.emit( "updateUserDetails" );
            } )

            socket.on( "logout", () => {
                dispatch( { type: ActionTypes.DELETE_TOKEN } );
            })

            return (
                <MultiAuth vendor={state.authMethod} {...props }>
                    <Loader logo={true} animate={true} title={"Creating connection..."} />
                </MultiAuth>
            )
        }
        catch ( e ) {
            console.error( "Authentication error", e );
        }

        return (
            <MultiAuth vendor={state.authMethod} {...props }>
                <Loader logo={true} animate={true} />
            </MultiAuth>
        )
    }


    if (  !LDClient ) {
        return (
            <MultiAuth vendor={state.authMethod} {...props }>
                <Loader logo={true} animate={true} title={"Initialising feature client..."} />
            </MultiAuth>
        )
    }


    if ( state.isAuthenticated && state.accessToken && state.baseUrl && state.userDetails && state.socket && !state.LD_Ready ) {

        LDClient.identify( {
            key: state.userDetails.email,
            name: state.userDetails.name,
            email: state.userDetails.email
        }).then( () => {
            dispatch( { type: ActionTypes.LD_READY, payload: true } );
        })

        return (
            <MultiAuth vendor={state.authMethod} {...props }>
                <Loader logo={true} animate={true} title={"Waiting for features..."} />
            </MultiAuth>
        )
    }


    if ( state.LD_Ready ) {

        if ( flags.holdingPage ) {

            const
                { startTime, endTime } = JSON.parse( flags.holdingPage ),
                start = new Date( startTime ),
                now = new Date();

            if ( now.getTime() >= start.getTime() ) {
                return <HoldingPage flags={flags} />
            }
        }

        return (
            <MultiAuth vendor={state.authMethod} {...props }>
                <MercuryContext.Provider value={{state, dispatch}} {...props} />
            </MultiAuth>
        )
    }


    return (
        <MultiAuth vendor={state.authMethod} {...props }>
            <Loader logo={true} animate={true} title={"Loading application..."}/>
        </MultiAuth>
    )
}

interface MultiAuthProps {
    vendor:vendors;
    REACT_APP_AUTH_AUDIENCE:string;
    REACT_APP_AUTH_CLIENT_ID:string;
    REACT_APP_AUTH_DOMAIN:string;
}

function MultiAuth( props:React.PropsWithChildren<MultiAuthProps> ) {
    const
        { vendor, children } = props,
        {
            REACT_APP_AUTH_AUDIENCE,
            REACT_APP_AUTH_CLIENT_ID,
            REACT_APP_AUTH_DOMAIN
        } = props,
            navigate = useNavigate();

    if ( vendor === vendors.RWSONE ) {
        return (
            <MsalProvider instance={ rwsOneInstance }>
                {children}
            </MsalProvider>
        )
    }

    if ( vendor === vendors.WORLDWIDE ) {
        return (
            <MsalProvider instance={ msalWorldwideInstance }>
                {children}
            </MsalProvider>
        )
    }

    if ( vendor === vendors.MORAVIA ) {
        return (
            <MsalProvider instance={ msalMoraviaInstance }>
                {children}
            </MsalProvider>
        )
    }

    const onRedirectCallback = (appState:any) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={REACT_APP_AUTH_DOMAIN}
            clientId={REACT_APP_AUTH_CLIENT_ID}
            audience={REACT_APP_AUTH_AUDIENCE!}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    )
}

const LogoutRedirect = () => {
    const navigate = useNavigate();

    const returnUrl = Cookies.get('return_url');

    if (returnUrl) {
        navigate(returnUrl)
        Cookies.remove('return_url' );
    }
    return <div />;
}

function AuthProvider( props:React.PropsWithChildren<any>) {
    return (
        <Routes>
            <Route
                path="/logout"
                element={<LogoutRedirect />}
            />
            <Route
                path="*"
                element={<ContextProvider {...props } />} />
        </Routes>
    )
}

function useMercuryContext() {
    const { state, dispatch } = React.useContext(MercuryContext);
    return state;
}

function useMercuryDispatch() {
    const { state, dispatch } = React.useContext(MercuryContext);
    return dispatch;
}

export const MercuryContextProvider = withLDProvider({
    clientSideID: process.env.REACT_APP_LD_ID as string,
})(AuthProvider)

export {
    MercuryContext,
    useMercuryContext,
    useMercuryDispatch,
    Route,
    Link,
    useLocation
};
