import React, { useState, useEffect, useRef, useCallback } from "react";
import { useMercuryContext } from "../user-context";
import { Helmet } from "react-helmet";
import { Form } from "antd";

import PageTitle from "../ui/PageTitle";
import AddCostCode from "../components/managePages/cost-codes/AddCostCode";
import EditCostCodes from "../components/managePages/cost-codes/EditCostCodes";
import CostCodesTable from "../components/managePages/cost-codes/CostCodesTable";
import BackTopButton from "../ui/BackTopButton";
import LoadingStar from "../ui/LoadingStar";

import {
  failedNotification,
  successfulNotification,
} from "../utils/notifications";

const initialEditingValue = { isEditing: false, editingRow: "" };

const ManageCostCodes = () => {
  const { client: Client, accessToken } = useMercuryContext(),
    editFormRef = useRef(null),
    [form] = Form.useForm(),
    [editingForm] = Form.useForm(),
    [isLoading, setIsLoading] = useState(false),
    [visibleDrawer, setVisibleDrawer] = useState(false),
    [visibleEditDrawer, setVisibleEditDrawer] = useState(false),
    [switchValue, setSwitchValue] = useState(false),
    [costCodes, setCostCodes] = useState([]),
    [sortedInfo, setSortedInfo] = useState(""),
    [editing, setEditing] = useState(initialEditingValue),
    [total, setTotal] = useState(0);

  // fetch all products
  const fetchCostCodesList = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await Client.get("core-ccs-v1");

      setCostCodes(data);
      setTotal(data.length);
    } catch (error) {
      console.error('Error fetching cost codes: ', error);
    }
    setIsLoading(false);
  }, [Client]);

  // add new cost code
  const addCostCode = async (body) => {
    try {
      setEditing({ ...editing, isEditing: true });
      const res = await Client.post("core-ccs-v1", body);
      // temporary fix until MER-995 will be done
      if (res instanceof Error) {
        if (res.message === "Request failed with status code 409") {
          failedNotification(
            res.name,
            `Cost code (${body.code}) already exists.`
          );
        } else {
          failedNotification(res.name, res.message);
        }
      } else {
        const { data } = res;
        successfulNotification(
          `Cost Code - ${data.code} was added.`,
          "Please refresh the page if the new cost code is not visible."
        );
      }
    } catch (error) {
      console.error('Error adding cost code: ', error);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // update a single cost code
  const updateCostCode = async (costCodeId, newData) => {
    try {
      setEditing({ ...editing, isEditing: true });
      const res = await Client.patch(`core-ccs-v1/${costCodeId}`, newData);

      // temporary fix until MER-995 will be done
      if (res instanceof Error) {
        if (res.message === "Request failed with status code 409") {
          failedNotification(
            res.name,
            `Cost code (${newData.code}) already exists.`
          );
        } else {
          failedNotification(res.name, res.message);
        }
      } else {
        const { data } = res;
        successfulNotification(
          `Cost Code - ${data.code} was updated.`,
          "Please refresh the page if the update is not visible."
        );
      }
    } catch (error) {
      console.error(`Error updating cost code ${costCodeId}: `, error);
      failedNotification(error.message, error.details);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // on load
  useEffect(() => {
    fetchCostCodesList();
  }, [fetchCostCodesList]);

  const defaultValues = {
    codeValue: editing.editingRow.title,
    activeValue: editing.editingRow.active,
  };

  // fix to update initial values of edit product form
  useEffect(() => {
    if (editFormRef.current) {
      editingForm.setFieldsValue(defaultValues);
    }
  }, [editFormRef, editingForm, defaultValues]);

  // refresh button
  const handleRefresh = () => {
    //fetch details again
    accessToken && fetchCostCodesList(accessToken);
  };

  // Add new cost code START
  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const closeDrawer = () => {
    setVisibleDrawer(false);
    form.resetFields();
    setSwitchValue(false);
  };

  // handle change of active switch button
  function handleChangeActive(checked) {
    setSwitchValue(checked);
  }

  // on save of add new cost code
  const onFinishNewCostCode = (values) => {
    // generates the new product object
    const newValues = {
      code: values.codeValue,
      isActive: switchValue,
    };
    addCostCode(newValues);
    closeDrawer();
  };
  // Add new cost code END

  // Update Cost Code START

  function showEditDrawer() {
    setVisibleEditDrawer(true);
  }

  const closeEditDrawer = (event) => {
    setVisibleEditDrawer(false);
    form.resetFields();
    event !== undefined && setEditing(initialEditingValue);
  };

  // on save of edit cost code
  const onFinishEditing = (values) => {
    const { activeValue, codeValue } = values;

    const newData = {
      isActive: activeValue.toString(),
      code: codeValue,
    };

    // function to call api endpoint that will update
    const costCodeId = editing.editingRow.key;

    updateCostCode(costCodeId, newData);

    closeEditDrawer();
  };
  // Update cost code END

  // handle change table
  const handleChangeTable = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
  };

  // handle click table edit button
  const handleClickEdit = (record) => {
    setEditing({ ...editing, isEditing: true, editingRow: record });
    showEditDrawer();
  };

  return (
    <div className="manage-page manage-cost-codes-page" style={{padding: '0 10px'}}>
      <Helmet>
        <title>Manage Cost Codes - Mercury © RWS</title>
      </Helmet>
      {isLoading ? (
        <LoadingStar />
      ) : (
        <>
          <PageTitle title="Manage Cost Codes" />
          <AddCostCode
            handlers={{
              showDrawer,
              closeDrawer,
              onFinishNewCostCode,
              handleChangeActive,
              handleRefresh,
            }}
            values={{ form, visibleDrawer, switchValue }}
          />
          <EditCostCodes
            handlers={{
              closeEditDrawer,
              onFinishEditing,
            }}
            values={{
              editingForm,
              visibleEditDrawer,
              editing,
              editFormRef,
            }}
          />
          <CostCodesTable
            values={{ costCodes, sortedInfo, editing, total }}
            handlers={{
              handleChangeTable,
              handleClickEdit,
            }}
          />
          <BackTopButton />
        </>
      )}
    </div>
  );
};

export default ManageCostCodes;
