import AuthVendorAbstract from "./AuthVendor.abstract";
import Cookies from "js-cookie";
import {Auth0ContextInterface} from "@auth0/auth0-react/src/auth0-context";
import {vendors} from "../LoginPage";

export default class RWSId extends AuthVendorAbstract<Auth0ContextInterface> {

    identifier=vendors.RWSID;

    async doLogin(): Promise<string|void> {

        const {
            loginWithRedirect,
            isAuthenticated,
            getAccessTokenSilently,
            isLoading,
            logout
        } = this.provider;

        try {

            const rwsTokenValue = await getAccessTokenSilently({
                // audience: process.env.REACT_APP_AUTH_AUDIENCE,
                scope: "openid profile email",
            });

            return rwsTokenValue as string;

            // return ""
        }
        catch ( e ) {
            console.error( "Error in authRWS", e );
            await loginWithRedirect({
                appState: {
                    returnTo: document.location.pathname,
                },
            });

        }
    }

    async doLogout(): Promise<void> {
        const
            {
                logout
            } = this.provider,
            address = window.location.href.split("/"),
            logoutPath = `${address[0]}//${address[2]}/logout`;

        logout({ returnTo: logoutPath, localOnly: true });

    }

}
