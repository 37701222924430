import React, { useState } from "react";
import Total from "../../../ui/Total";
import { sorter } from "../../../utils/helpers";

import { Table, Typography, Skeleton, Input, Spin, Row } from "antd";

import { EditOutlined, CheckOutlined, CloseOutlined  } from "@ant-design/icons";

const WorkflowTypeTable = ({ values, handlers }) => {
  const { workflowTypes, sortedInfo, editing, total } = values;
  const { handleChangeTable, handleClickEdit } = handlers;

  const { Link, Title } = Typography;
  const { Search } = Input;

  const [filterTable, setFilterTable] = useState(null);

  const data =
  workflowTypes &&
  workflowTypes.map((item, index) => {
      return {
        key: index,
        id: item.id,
        title: item.title,
        description: item.description,
        isActive: item.isActive,
      };
    });

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      sorter: (a, b) => sorter(a.title, b.title),
      sortOrder: sortedInfo.field === "title" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.title === editing.editingRow.title ? (
            text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text
        ),
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => sorter(a.description, b.description),
      sortOrder: sortedInfo.field === "description" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.title === editing.editingRow.title ? (
            text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text
        ),
    },
    {
      title: "Active",
      dataIndex: "isActive",
      width: 100,
      sorter: (a, b) => sorter(a.isActive, b.isActive),
      sortOrder: sortedInfo.field === "isActive" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.title === editing.editingRow.title ? (
            record.isActive === true ? (
              <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
            ) : (
              <CloseOutlined style={{ color: "var(--red)" }} />
            )
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : record.isActive === true ? (
          <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
        ) : (
          <CloseOutlined style={{ color: "var(--red)" }} />
        ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      width: 100,
      render: (text, record) =>
        editing.isEditing && record.title !== editing.editingRow.title ? (
          <Skeleton active size="small" title={true} paragraph={false} />
        ) : (
          <Link
            onClick={() => {
              handleClickEdit(record);
            }}
            style={{
              marginRight: "10px",
            }}
          >
            <EditOutlined /> Edit
          </Link>
        ),
    },
  ];

  const handleSearch = (value) => {
    const filterTable = data.filter((item) =>
      item.title.toString().toLowerCase().includes(value.toLowerCase())
    );

    setFilterTable(filterTable);
  };

  return (
    <>
      <Search
        style={{ width: "300px", margin: "0 0 10px 0" }}
        placeholder="Search by title"
        onSearch={handleSearch}
        allowClear
        enterButton
      />
      <Row className="title-wrap">
        <Title level={5} style={{ margin: "0" }}>
          WORKFLOW TYPES LIST
          {editing.isEditing || editing.isDeleting ? (
            <Spin size="small" style={{ marginLeft: "10px" }} />
          ) : null}
        </Title>
        <Total number={total} text="Workflow Types" />
      </Row>

      <Table
        columns={columns}
        dataSource={filterTable == null ? data : filterTable}
        size="small"
        className="manage-page-table"
        style={{ width: "100%"}}
        onChange={handleChangeTable}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: 20,
        }}
      />
    </>
  );
};

export default WorkflowTypeTable;
