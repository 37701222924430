import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MercuryContextProvider } from "./user-context";

import { notification } from "antd";
import LoadingStar from "./ui/LoadingStar";
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from "react-router-dom";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */

function RenderApp() {
  const location = useLocation(),
      navigate = useNavigate();

  // @ts-ignore
  return (
      // @ts-ignore
        <MercuryContextProvider
          REACT_APP_AUTH_DOMAIN={process.env.REACT_APP_AUTH_DOMAIN}
          REACT_APP_AUTH_CLIENT_ID={process.env.REACT_APP_AUTH_CLIENT_ID}
          REACT_APP_AUTH_AUDIENCE={process.env.REACT_APP_AUTH_AUDIENCE}
          Notifier={notification}
          Loader={LoadingStar}
          location={location}
          REACT_APP_API_URL={process.env.REACT_APP_API_URL}
          REACT_APP_SERVICES_API_URL={process.env.REACT_APP_SERVICES_API_URL}
          REACT_APP_BASE_URL={process.env.REACT_APP_BASE_URL}
          REACT_APP_ENVIRONMENT_URL={process.env.REACT_APP_ENVIRONMENT_URL}
          REACT_APP_WS_PATH={process.env.REACT_APP_WS_PATH}
          navigate={navigate}
          loginPath="/login"
        >
          <Routes>
            <Route path="*" element={<App/>} />
          </Routes>
        </MercuryContextProvider>

  );
}

ReactDOM.render(
  <Router>
    <RenderApp />
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
