/**
 * Reorder
 */
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * With new product ids
 */
const withNewProductIds = (column, productIds) => ({
  id: column.id,
  productIds,
});

/**
 * Reorder single drag
 */
const reorderSingleDrag = ({
  entities,
  selectedProductIds,
  source,
  destination,
}) => {
  // moving in the same list
  if (source.droppableId === destination.droppableId) {
    const column = entities.columns[source.droppableId];

    const reordered = reorder(
      column.productIds,
      source.index,
      destination.index
    );

    const updated = {
      ...entities,
      columns: {
        ...entities.columns,
        [column.id]: withNewProductIds(column, reordered),
      },
    };

    return {
      entities: updated,
      selectedProductIds,
    };
  }

  // moving to a new list
  const home = entities.columns[source.droppableId];
  const foreign = entities.columns[destination.droppableId];

  // the id of the product to be moved
  const productId = home.productIds[source.index];

  // remove from home column
  const newHomeProductIds = [...home.productIds];
  newHomeProductIds.splice(source.index, 1);

  // add to foreign column
  const newForeignProductIds = [...foreign.productIds];
  newForeignProductIds.splice(destination.index, 0, productId);

  const updated = {
    ...entities,
    columns: {
      ...entities.columns,
      [home.id]: withNewProductIds(home, newHomeProductIds),
      [foreign.id]: withNewProductIds(foreign, newForeignProductIds),
    },
  };

  return {
    entities: updated,
    selectedProductIds,
  };
};

/**
 * Get home column
 */
export const getHomeColumn = (entities, productId) => {
  const columnId = entities.vendor.find((id) => {
    const column = entities.columns[id];
    return column.productIds.includes(productId);
  });

  return entities.columns[columnId];
};

/**
 * Reorder multi drag
 */
const reorderMultiDrag = ({
  entities,
  selectedProductIds,
  source,
  destination,
}) => {
  const start = entities.columns[source.droppableId];
  const dragged = start.productIds[source.index];

  const insertAtIndex = (() => {
    const destinationIndexOffset = selectedProductIds.reduce(
      (previous, current) => {
        if (current === dragged) {
          return previous;
        }

        const final = entities.columns[destination.droppableId];
        const column = getHomeColumn(entities, current);

        if (column !== final) {
          return previous;
        }

        const index = column.productIds.indexOf(current);

        if (index >= destination.index) {
          return previous;
        }

        // the selected item is before the destination index
        // we need to account for this when inserting into the new location
        return previous + 1;
      },
      0
    );

    const result = destination.index - destinationIndexOffset;
    return result;
  })();

  // doing the ordering now as we are required to look up columns
  // and know original ordering
  const orderedSelectedProductIds = [...selectedProductIds];

  orderedSelectedProductIds.sort((a, b) => {
    // moving the dragged item to the top of the list
    if (a === dragged) {
      return -1;
    }

    if (b === dragged) {
      return 1;
    }

    // sorting by their natural indexes
    const columnForA = getHomeColumn(entities, a);
    const indexOfA = columnForA.productIds.indexOf(a);
    const columnForB = getHomeColumn(entities, b);
    const indexOfB = columnForB.productIds.indexOf(b);

    if (indexOfA !== indexOfB) {
      return indexOfA - indexOfB;
    }

    // sorting by their order in the selectedProductIds list
    return -1;
  });

  // we need to remove all of the selected products from their columns
  const withRemovedProducts = entities.vendor.reduce((previous, columnId) => {
    const column = entities.columns[columnId];

    // remove the id's of the items that are selected
    const remainingProductIds = column.productIds.filter(
      (id) => !selectedProductIds.includes(id)
    );

    previous[column.id] = withNewProductIds(column, remainingProductIds);
    return previous;
  }, entities.columns);

  const final = withRemovedProducts[destination.droppableId];

  const withInserted = (() => {
    const base = [...final.productIds];
    base.splice(insertAtIndex, 0, ...orderedSelectedProductIds);
    return base;
  })();

  // insert all selected products into final column
  const withAddedProducts = {
    ...withRemovedProducts,
    [final.id]: withNewProductIds(final, withInserted),
  };

  const updated = {
    ...entities,
    columns: withAddedProducts,
  };

  return {
    entities: updated,
    selectedProductIds: orderedSelectedProductIds,
  };
};

/**
 * Multi drag aware reorder
 */
export const multiDragAwareReorder = (args) => {
  if (args.selectedProductIds.length > 1) {
    return reorderMultiDrag(args);
  }

  return reorderSingleDrag(args);
};

/**
 * Multi select to
 */
export const multiSelectTo = (entities, selectedProductIds, newProductId) => {
  // Nothing already selected
  if (!selectedProductIds.length) {
    return [newProductId];
  }

  const columnOfNew = getHomeColumn(entities, newProductId);
  const indexOfNew = columnOfNew.productIds.indexOf(newProductId);

  const lastSelected = selectedProductIds[selectedProductIds.length - 1];
  const columnOfLast = getHomeColumn(entities, lastSelected);
  const indexOfLast = columnOfLast.productIds.indexOf(lastSelected);

  // multi selecting to another column
  // select everything up to the index of the current item
  if (columnOfNew !== columnOfLast) {
    return columnOfNew.productIds.slice(0, indexOfNew + 1);
  }

  // multi selecting in the same column
  // need to select everything between the last index and the current index inclusive

  // nothing to do here
  if (indexOfNew === indexOfLast) {
    return null;
  }

  const isSelectingForwards = indexOfNew > indexOfLast;
  const start = isSelectingForwards ? indexOfLast : indexOfNew;
  const end = isSelectingForwards ? indexOfNew : indexOfLast;

  const inBetween = columnOfNew.productIds.slice(start, end + 1);

  // everything in between needs to have it's selection toggled.
  // with the exception of the start and end values which will always be selected

  const toAdd = inBetween.filter((productId) => {
    // if already selected: then no need to select it again
    if (selectedProductIds.includes(productId)) {
      return false;
    }
    return true;
  });

  const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse();
  const combined = [...selectedProductIds, ...sorted];

  return combined;
};


// lift and drop arrows

export const noop = () => {};

export const delay = (fn, time) =>
  new Promise((resolve) => {
    setTimeout(() => {
      fn();
      resolve();
    }, time);
  });

export const liftAndMove = async (
  draggableKey,
  sensorAPIRef,
  direction,
  setIsControlDragging
) => {
  const api = sensorAPIRef.current;

  if (!api) {
    return null;
  }

  // the 2nd argument is used for cleaning up memory(any event listeners) in case of force drop lock
  const preDrag = api.tryGetLock(draggableKey, noop);
  if (!preDrag) {
    console.log("unable to start capturing");
    return null;
  }
  // lift
  const actions = preDrag.snapLift();
  const { moveDown, moveUp, drop } = actions;
  setIsControlDragging(true);
  // move
  switch (direction) {
    case "UP":
      await delay(moveUp, 50);
      break;
    case "DOWN":
      await delay(moveDown, 50);
      break;
    default:
      break;
  }
  // drop
  await delay(drop, 300);
  setIsControlDragging(false);
};