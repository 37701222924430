import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { defaultHeaders } from "../api/APIUtils";
import { removeUnderscore } from "../utils/helpers";

import UniversalButton from "../ui/UniversalButton";
import {
  failedNotification,
  successfulNotification,
} from "../utils/notifications";
import { extractFileName } from "../utils/helpers";

import { Modal, Table, Select, Typography, Spin, Tooltip } from "antd";
import {
  PullRequestOutlined,
  ExclamationCircleOutlined,InfoCircleOutlined
} from "@ant-design/icons";
import "./link-g-suite-files.css";

const LinkGSuiteFiles = ({ baseURI, accessToken, shortId, gSuiteFiles }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false); // submit button spinner
  const [deliverableFiles, setDeliverableFiles] = useState([]);
  const [dtpFiles, setDtpFiles] = useState([]);

  const { Option } = Select;
  const { Text } = Typography;

  const getDeliverableFiles = useCallback(async () => {
    try {
      setLoadingModal(true);
      const projectEndpoint = `${baseURI}/deliverablefiles?shortId=${shortId}`;

      await axios
        .get(projectEndpoint, {
          headers: defaultHeaders(accessToken),
        })
        .then((files) => {
          setDeliverableFiles([...files.data]);
          setLoadingModal(false);
        });
    } catch (error) {
      isModalVisible &&
        failedNotification(
          error.response.data
            ? error.response.data.error
            : "Failed to load GSuite Files",
          error.response
            ? error.response.data.detail
            : "Please refresh and try again."
        );
      setLoadingModal(false);
    }
  }, [accessToken, baseURI, isModalVisible, shortId]);

  useEffect(() => {
    if (!gSuiteFiles) {
      setLoadingModal(true);
    } else {
      setLoadingModal(false);
    }
    isModalVisible && getDeliverableFiles();
  }, [isModalVisible, getDeliverableFiles, gSuiteFiles]);

  const calculateMatching = (file, list) => {
    let dtpFile = null;
    let matchedFile = null;
    // remove filename extension
    dtpFile = extractFileName(file.displayName);

    // add target language to filename
    dtpFile = `${dtpFile}_${file.languageCode}`;

    // if it was previously matched return that match
    if (file.customVendorFileMetadata.status === "POST_PROCESSED_UPLOADED") {
      matchedFile = list.find(
        (item) => item.id === file.customVendorFileMetadata.uploadedFileId
      );
    }

    if (!matchedFile) {
      // try to match filename with gsuite file by name
      matchedFile = list.find((item) => extractFileName(item.name) === dtpFile);
    }

    return matchedFile;
  };

  useEffect(() => {
    const processedDtpFiles =
      gSuiteFiles.length > 0 &&
      gSuiteFiles.map((obj, index) => ({
        ...obj,
        key: index, // fix for antd columns
        targetLanguage: obj.languageCode, // fix for antd columns
        matchedTo: calculateMatching(obj, deliverableFiles),
        stage: obj.customVendorFileMetadata.status
          ? removeUnderscore(obj.customVendorFileMetadata.status)
          : "",
        submissionResult: { status: "loaded", message: "" },
      }));

    setDtpFiles(processedDtpFiles);
  }, [gSuiteFiles, deliverableFiles]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  // on click of submit button
  const handleSubmit = async () => {
    // make api request to submit for each file
    setLoadingSubmit(true);
    const endpoint = `${baseURI}/project/${shortId}/uploadedFileId`;

    for (let file of dtpFiles) {
      // check if another successful submission was made before trying again
      if (
        file.submissionResult.status === "loaded" ||
        file.submissionResult.status === "error"
      ) {
        if (!file.matchedTo) {
          file.submissionResult = {
            status: "done",
            message:
              file.customVendorFileMetadata.status === "POST_PROCESSED_UPLOADED"
                ? "No Match. Closing the modal will revert back to previous match."
                : "Not Submitted. No Match.",
          };
          setDtpFiles([...dtpFiles]);
        } else if (
          file.matchedTo.id === file.customVendorFileMetadata.uploadedFileId
        ) {
          file.submissionResult = {
            status: "done",
            message: "Not Submitted. No change detected.",
          };
          setDtpFiles([...dtpFiles]);
        } else {
          try {
            const data = {
              resourceName: file.fileDataRef.resourceName,
              uploadedFileId: file.matchedTo && file.matchedTo.id,
            };

            await axios({
              method: "post",
              url: endpoint,
              headers: defaultHeaders(accessToken),
              data: data,
            });

            file.submissionResult = {
              status: "done",
              message: "Success. It was submitted.",
            };
            setDtpFiles([...dtpFiles]);
          } catch (error) {
            console.log(error);

            file.submissionResult = {
              status: "error",
              message: `Error! ${error.response.data.error}`,
            };

            setDtpFiles([...dtpFiles]);
          }
        }
      }
    }
    setLoadingSubmit(false);
    // check if all files were successful submitted
    const allRequestWereSuccessful = dtpFiles.every(
      (item) => item.submissionResult.status === "done"
    );

    if (allRequestWereSuccessful) {
      successfulNotification(
        "All GSuite files have been linked.",
        "Please refresh the page."
      );
      setIsModalVisible(false);
    }
  };

  // on dropdown selection
  function handleSelectGSuite(value, record) {
    // find the gsuite file based on id
    const selectedFile = deliverableFiles.find((item) => item.id === value);

    // find the current dtp file
    const dtpFile = dtpFiles.find((item) => item._id === record._id);

    // update matchedTo value of the current dtp
    dtpFile.matchedTo = selectedFile;

    // update state
    setDtpFiles([...dtpFiles]);
  }

  const columns = [
    {
      title: "Post Processing Files",
      key: "displayName",
      dataIndex: "displayName",
    },
    {
      title: (
        <>
          <Tooltip title="Target Language" placement={"top"}>
            TL
            <InfoCircleOutlined
              style={{ color: "var(--tertiary-color)", marginLeft: "3px", fontSize:'11px' }}
            />
          </Tooltip>
        </>
      ),
      key: "targetLanguage",
      dataIndex: "targetLanguage",
      width: 60,
    },
    {
      title: "Stage",
      key: "stage",
      dataIndex: "stage",
      width: 220,
    },
    {
      title: "GSuite Files",
      key: "gFile",
      dataIndex: "gFile",
      width: 350,
      render: (text, record) => (
        <Select
          size="medium"
          style={{ width: "100%" }}
          value={record.matchedTo ? record.matchedTo.name : "(No Match)"}
          onChange={(value) => handleSelectGSuite(value, record)}
          dropdownClassName="gsuite-dropdown"
        >
          <Option
            value={null}
            key={"no-match-id-001"}
            style={{
              background: !record.matchedTo ? "var(--tertiary-color)" : "unset",
              color: !record.matchedTo ? "var(--white)" : "unset",
            }}
          >
            (No Match)
          </Option>
          {deliverableFiles.map((item) => (
            <Option
              value={item.id}
              key={item.id}
              style={{
                background:
                  record.matchedTo && record.matchedTo.id === item.id
                    ? "var(--tertiary-color)"
                    : "unset",
                color:
                  record.matchedTo && record.matchedTo.id === item.id
                    ? "var(--white)"
                    : "unset",
              }}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Submission Result",
      key: ["submissionResult", "message"],
      dataIndex: ["submissionResult", "message"], // fix for nest path
      width: 250,
      render: (text, record) =>
        loadingSubmit && record.submissionResult.status !== "done" ? (
          <>
            <Spin style={{ marginRight: "10px" }} size="small" />
            Submitting...
          </>
        ) : (
          <Text
            type={
              record.submissionResult.status === "error"
                ? "danger"
                : record.submissionResult.status === "done"
                ? "success"
                : ""
            }
          >
            {text}
          </Text>
        ),
    },
  ];

  return (
    <>
      <UniversalButton
        clickMethod={showModal}
        text="Link GSuite Files"
        icon={<PullRequestOutlined />}
      />
      <Modal
        title="Submit GSuite Files"
        open={isModalVisible}
        onOk={handleSubmit}
        onCancel={hideModal}
        okText="Submit"
        confirmLoading={loadingSubmit}
        width={1400}
        okButtonProps={{
          style: { display: dtpFiles.length > 0 ? "unset" : "none" },
        }}
      >
        {gSuiteFiles.length === 0 ? (
          <>
            <ExclamationCircleOutlined
              style={{ marginRight: "5px", color: "var(--primary-color)" }}
            />
            No files with status AWAITING POST PROCESSING, POST PROCESSED
            UPLOADED or FAILED POST PROCESSING.
          </>
        ) : loadingModal ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0 -15px",
            }}
          >
            <Spin style={{ marginRight: "10px" }} size="medium" /> Loading
            files...
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={[...dtpFiles]}
            size="small"
            pagination={false}
            className="upload-modal-table"
            locale={{
              emptyText:
                "No files with status AWAITING POST PROCESSING, POST PROCESSED UPLOADED or FAILED POST PROCESSING.",
            }}
            style={{ marginTop: "0" }}
          />
        )}
      </Modal>
    </>
  );
};

export default LinkGSuiteFiles;
