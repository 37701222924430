import {Form, Select} from "antd";
import React from "react";

const { Option } = Select;

export default function Components( props ) {

  const {
    selectedProducts,
    handleComponentChange,
    components
  } = props;

  return (
    <Form.Item
      label="Component types"
      name="components"
      rules={[{ required: false}]}
      key={props.key}
    >
      <Select 
        className='select-multiple'
        mode="multiple"
        style={{ width: '100%' }}
        placeholder="Please select"
        value={selectedProducts}
        onChange={handleComponentChange}
        onClear={handleComponentChange}
        allowClear={true}
      >
        {
          ( components || [] ).map( ( p ) => {
            return <Option key={p.key} value={p.title}>{p.title}</Option>
          })
        }
      </Select>
    </Form.Item>
  )
}