import React from 'react';

import { Table, Row, Col, Tooltip, Select, Button, Spin } from 'antd';
import LoadingStar from '../../../../../ui/LoadingStar';
import TimezoneConverter from '../../../../../timezone-converter';
import { CaretLeftOutlined, CaretRightOutlined, PlusSquareFilled } from '@ant-design/icons';
import ListExtModal from '../../../../../routes/extensions/ListExtModal';

/**
 * List the request extension elements with the given props
 *
 * @param props
 * @returns RequestTable component
 */


const ExtProjectTable = (props) => {
  const {
    reqExtElements,
    stateRequest,
    filtersValues,
    setFiltersValues,
    handleExtensionIdClick,
    currentPage,
    setCurrentPage,
    arrTokens,
    projectsPerPage,
    hideLanguageCode = false,
  } = props;
  const { Option } = Select;
  // Create column elements to pass to ANT table
  const columns = [
    // {
    //   title: 'Request ID',
    //   dataIndex: 'extensionRequestId',
    //   key: 'extensionRequestId',
    //   sorter: true,
    //   render: (id, record) => {
    //     return (
    //       <Anchor affix={false} onClick={handleExtensionIdClick}>
    //         <Link title={record.extensionRequestId}></Link>
    //       </Anchor>
    //     );
    //   },
    //   align: 'center',
    //   width: 300,
    // },
    ...hideLanguageCode ? [] : [{
      title: 'Target Language',
      dataIndex: 'targetLanguageCode',
      key: 'targetLanguageCode',
      sorter: true,

      sortOrder: (filtersValues?.sortColumn === "targetLanguageCode" ? filtersValues?.sortOrder : null),
      align: 'center',
      width: 150,
    }],

    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      sortOrder: (filtersValues?.sortColumn === "createdAt" ? filtersValues?.sortOrder : null),
      align: 'center',
      render: (id, record) => {
        return (
          <React.Fragment>
            <TimezoneConverter date={record.createdAt} format={'DD MMM YYYY'} />
          </React.Fragment>
        );
      },
      width: 150,
      marginRight: 30,
    },
    {
      title: 'Last updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      sortOrder: (filtersValues?.sortColumn === "updatedAt" ? filtersValues?.sortOrder : null),
      align: 'center',
      render: (id, record) => {
        return (
          <React.Fragment>
            <TimezoneConverter date={record.updatedAt} format={'DD MMM YYYY'} />
          </React.Fragment>
        );
      },
      width: 150,
      marginRight: 30,
    },
    {
      title: 'Requested Due Date',
      dataIndex: 'requestedDueDate',
      key: 'requestedDueDate',
      sorter: true,
      sortOrder: (filtersValues?.sortColumn === "requestedDueDate" ? filtersValues?.sortOrder : null),
      align: 'center',
      render: (id, record) => {
        return (
          <React.Fragment>
            <TimezoneConverter date={record.requestedDueDate} format={'DD MMM YYYY'} />
          </React.Fragment>
        );
      },
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      sortOrder: (filtersValues?.sortColumn === "status" ? filtersValues?.sortOrder : null),

      align: 'center',
      width: 150,
    },
    {
      title: 'Details',
      dataIndex: 'moreDetails',
      key: 'moreDetails',
      sorter: false,
      align: 'center',
      width: 150,
      marginRight: 30,
      render: (text, record) => <ListExtModal requestId={record._id} visibleDates={false} />
      // render: (id, record) => {
      //   return (
      //     <PlusSquareFilled onClick={()=>handleExtensionIdClick(record.extensionRequestId)} />
      //   );
      // },
    },

  ];
  const onChangeTable = (
    pagination,
    filters,
    sorter
  ) => {
    // will save sort values in state to make a new api call with new values

    setFiltersValues({
      ...filtersValues,
      sortColumn: sorter.field,
      sortOrder: sorter.order,
    });
  };
  return stateRequest ? (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <Spin size="small" style={{ margin: "0 10px" }} />
      Loading list...
    </div>
  ) : (
    <div className="request-table">
      <>
        <Table
          className="table-extensions"
          sticky={false}
          // Add a key to the collection
          pagination={false}
          dataSource={reqExtElements?.map(
            (element, index) => {
              return {
                key: index,
                ...element,
              };
            }
          )}
          columns={columns}
          onChange={onChangeTable}
        ></Table>
        {/* <Row style={{ marginTop: '50px', paddingBottom: '15px' }} justify="center">
              {reqExtElements.length > 0 && (
                <>
                  <Col
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '0 22px 0 10px',
                    }}
                  >
                    {currentPage > 1 ? (
                      <Tooltip title="Previous page" placement={'top'}>
                        <CaretLeftOutlined
                          onClick={() => {
                            setCurrentPage(currentPage - 1);
                            //scrollToTop();
                          }}
                          className="pagination-button"
                        />
                      </Tooltip>
                    ) : (
                      <CaretLeftOutlined
                        disabled
                        className="pagination-button"
                        style={{ color: '#9ba9b6' }}
                      />
                    )}
                    <Tooltip title="Current page" placement={'top'}>
                      <Button
                        type="primary"
                        style={{
                          padding: '0 12px',
                          margin: '0 10px',
                          cursor: 'default',
                          background: 'white',
                          color: 'var(--primary-color)',
                        }}
                      >
                        {currentPage}
                      </Button>
                    </Tooltip>
                    {projectsPerPage === reqExtElements.length &&
                    (arrTokens[currentPage - 1] !== '' || currentPage === 1) ? (
                      <Tooltip title="Next page" placement={'top'}>
                        <CaretRightOutlined
                          onClick={() => {
                            setCurrentPage(currentPage + 1);
                            //scrollToTop();
                          }}
                          className="pagination-button"
                        />
                      </Tooltip>
                    ) : (
                      <CaretRightOutlined
                        disabled
                        className="pagination-button"
                        style={{ color: '#9ba9b6' }}
                      />
                    )}
                  </Col>

                  <Col style={{ display: 'flex', alignItems: 'center' }}>
                    <Select
                      style={{ width: 110, textAlign: 'center' }}
                      placeholder={`${projectsPerPage} / page`}
                      dropdownStyle={{
                        textAlign: 'center',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: 'var(--tertiary-color)',
                      }}
                    >
                      <Option value="100">100 / page</Option>
                    </Select>
                  </Col>
                </>
              )}
            </Row> */}
      </>
    </div>
  );
};
export default ExtProjectTable;
