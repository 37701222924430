import React, { useState, useEffect, useCallback } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Modal, DatePicker, Form, Select, Table } from "antd";
import { useMercuryContext } from "../../user-context";

import "./CheckRateMatch.scss";

import type { ColumnsType } from "antd/es/table";
import TimezoneConverter from "../../timezone-converter";
import { CustomTooltip } from "../../Components";

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

interface IProps {
  fetchingData?: boolean;
}

const CheckRateMatch: React.FC<IProps> = ({ fetchingData = false }) => {
  const context = useMercuryContext(),
    { legacyClient, client } = context;

  const [matchModal, setMatchModal] = useState<boolean>(false);
  const [resultsModal, setResultsModal] = useState<boolean>(false),
    [loadingOptions, setLoadingOptions] = useState<boolean>(false),
    [loadingCheckRate, setLoadingCheckRate] = useState<boolean>(false),
    [services, setServices] = useState<any[]>([]),
    [languages, setLanguages] = useState<any[]>([]),
    [matchedRates, setMatchedRates] = useState<any[]>([]);

  const [checkRateform] = Form.useForm();

  const getOptionsData = useCallback(async () => {
    setLoadingOptions(true);
    const servicesData = await client.get("/core-gss-v1/service"),
      languagesData = await legacyClient.get("/languageCode");

    setServices(servicesData.data);

    const sortedLanguages = [...languagesData.data].sort((a, b) => {
      if (a.code === "ALL") {
        return -1;
      }
      return b.code === "ALL" ? 1 : 0;
    });

    setLanguages(sortedLanguages);

    setLoadingOptions(false);
  }, [client, legacyClient]);

  useEffect(() => {
    (!services.length || !languages.length) && matchModal && getOptionsData();
  }, [getOptionsData, matchModal, services, languages]);

  const handleCheckRate = async () => {
    await checkRateform.validateFields();

    setLoadingCheckRate(true);
    let values = checkRateform.getFieldsValue();

    await client
      .get("/core-grs-v1/rate/match", {
        params: {
          effectiveDate: values.date.toISOString(),
          serviceName: values.service,
          sourceLanguageCode: [values.sourceLanguage],
          targetLanguageCode: [values.targetLanguage],
          multiple: true,
        },
      })
      .then((res: any) => {
        setMatchedRates(
          res.data.map((r: any) => {
            return { ...r, key: r._id };
          })
        );
      })
      .catch((e: any) => {
        console.error("Error fetching matched rates: ", e);
      });

    setLoadingCheckRate(false);
    setMatchModal(false);
    setResultsModal(true);
  };

  const closeResultsModal = () => {
    checkRateform.resetFields();
    setMatchedRates([]);
    setResultsModal(!resultsModal);
  };

  const closeMatchRateModal = () => {
    checkRateform.resetFields();
    setMatchModal(!matchModal);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Source Language",
      dataIndex: "sourceLang",
      key: "sourceLang",
      width: 100,
    },
    {
      title: "Target Language",
      dataIndex: "targetLang",
      key: "targetLang",
      width: 100,
    },
    {
      title: "Word Rate",
      dataIndex: "wordRate",
      key: "wordRate",
      width: 60,
    },
    {
      title: "Service",
      key: "service",
      dataIndex: "service",
      width: 160,
      render: (text) => {
        return <CustomTooltip text={services.find((s: any) => s.id === text).name} length={18}/>
      }
    },
    {
      title: "Service Rate",
      dataIndex: "serviceRate",
      key: "serviceRate",
      width: 110,
    },
    {
      title: "DTP Rate",
      dataIndex: "dtpRate",
      key: "dtpRate",
      width: 90,
    },
    {
      title: "Eng Rate",
      dataIndex: "engRate",
      key: "engRate",
      width: 90,
    },
    {
      title: "Effective Date",
      dataIndex: "effectiveDate",
      key: "effectiveDate",
      width: 160,
      render: (text:string) => <TimezoneConverter date={text}/>
    },
  ];

  return (
    <div className="check-rate-match">
      <Button
        type="primary"
        onClick={() => setMatchModal(true)}
        disabled={matchModal || resultsModal || fetchingData}
      >
        <CheckCircleOutlined /> Check Rate Match
      </Button>
      <Modal
        title={"Check Rate Match"}
        onCancel={() => closeMatchRateModal()}
        open={matchModal}
        className="check-rate-match-modal"
        footer={<Button
              type="primary"
              onClick={() => handleCheckRate()}
              loading={loadingOptions || loadingCheckRate}
              disabled={loadingOptions || loadingCheckRate}
            >
              Check
            </Button>
        }
      >
        <Form
          form={checkRateform}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 16 }}
          name="checkRateMatchForm"
        >
          <Form.Item
            label="Service"
            name="service"
            rules={[{ required: true, message: "Please select the service." }]}
          >
            <Select loading={loadingOptions}>
              {services.map((s: any) => (
                <Select.Option value={s.name} key={s.id}>
                  {s.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Source Language"
            name="sourceLanguage"
            rules={[
              { required: true, message: "Please select the source language." },
            ]}
          >
            <Select
              loading={loadingOptions}
              popupMatchSelectWidth={false}
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                  //@ts-ignore
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {languages.map((language: any) => (
                <Select.Option value={language.code} key={language._id}>
                  {language.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Target Language"
            name="targetLanguage"
            rules={[
              { required: true, message: "Please select the target language." },
            ]}
          >
            <Select
              loading={loadingOptions}
              popupMatchSelectWidth={false}
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                  // @ts-ignore
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {languages.map((language: any) => (
                <Select.Option value={language.code} key={language._id}>
                  {language.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Date"
            name="date"
            rules={[{ required: true, message: "Please select a date." }]}
          >
            <DatePicker />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={"Rate Match Results"}
        onCancel={() => closeResultsModal()}
        open={resultsModal}
        width={1300}
        className="rate-match-results-modal"
        footer={<Button
              type="primary"
              danger
              onClick={() => closeResultsModal()}
              disabled={loadingCheckRate}
            >
              Close
            </Button>
        }
      >
        <p>The following rate would be used for the selected criteria:</p>
        <Table
          columns={columns}
          dataSource={matchedRates}
          size="small"
          pagination={false}
        />
      </Modal>
    </div>
  );
};

export default CheckRateMatch;
