import React from "react";

import { Button, Drawer, Form, Input } from "antd";

const EditHelixTemplate = ({ handlers, values }) => {
  const { closeEditDrawer, onFinishEditing } = handlers;
  const { editingForm, visibleEditDrawer, editing, editFormRef, helixTemplates } = values;

  const editingRow = editing && editing.editingRow;

  return (
    <>
      <Drawer
        title="Edit Helix Template"
        placement="right"
        onClose={closeEditDrawer}
        open={visibleEditDrawer}
        width={500}
        closable={true}
        className="manage-pages-drawer"
        forceRender
      >
        {editingRow && (
          <Form
            form={editingForm}
            layout="vertical"
            onFinish={onFinishEditing}
            name="editProductForm"
            ref={editFormRef}
          >
            <Form.Item
              name="nameValue"
              label="Name"
              rules={[
                { required: true, message: "Please enter Helix Template name" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="helixIdValue"
              label="Helix Template ID"
              rules={[
                {
                  required: true,
                  message: "Please enter Helix Template ID",
                },
                // ids can't be the same
                ({ getFieldValue }) => ({
                  validator(record, value) {
                    const helixForm = getFieldValue();
                    const helixIdValues = [];

                    helixTemplates.map((item) => {
                      return helixIdValues.push({
                        id: item._id,
                        helixId: item.helixId,
                      });
                    });

                    const matchedHelixId = helixIdValues.filter(
                      (item) =>
                        item.helixId === value && item.id !== helixForm.idValue
                    );

                    if (matchedHelixId.length === 0) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error(
                          "ID: " +
                            value +
                            " already exists, please enter a new id"
                        )
                      );
                    }
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="descValue"
              label="Description"
              rules={[
                { required: true, message: "Please enter Helix a description" },
              ]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Template
              </Button>
            </Form.Item>
          </Form>
        )}
      </Drawer>
    </>
  );
};

export default EditHelixTemplate;
