import React from "react";
import { UpOutlined } from "@ant-design/icons";
import { FloatButton } from 'antd';

const BackTopButton = () => {
  return (
    <FloatButton.BackTop style={{ right: "12px" }}>
      <UpOutlined className="back-top-btn" />
    </FloatButton.BackTop>
  );
};

export default BackTopButton;
