import React, {useState} from "react";
import {Button, Tooltip} from "antd";
// import AccrualSubmissionModal from "./AccrualSubmissionModal";
import {useMercuryContext} from "../../user-context";
import { failedNotification } from "../../utils/notifications.js"

export default function AccrualButton( { selectedRows, reload, page }:any ) {

    const
      {gpSocket} = useMercuryContext(),
      rows = selectedRows || [],
      states = [ "DRAFT", "ACCRUED", "EDITED", "GOOGLE_ERRORED", "ERRORED", "PENDING_ACCRUAL", "ACCRUAL_PENDING" ],
      allowedSubmitCriteria = (element:any) => states.indexOf( element.status?.toUpperCase() ) !== -1,
      allowSubmit = rows.length > 0 && rows.filter( ( e:any ) => e.deleted !== true ).filter(allowedSubmitCriteria).length,
      [isLoading, setLoading] = useState( false );

  const handleOk = () => {
    setLoading( true );
    const socket = gpSocket.adj;
    Promise.all( ( rows.map( ( row: any ) => {
          return new Promise( ( accept ) => {
              socket.emit( "accrue", row._id, ( err:any ) => {
                  if ( err ) {
                      failedNotification( "Failed to submit batch for accrual", err );
                  }
                  return accept( null );
              })
          })
        })))
        .then( () => {
            return new Promise( ( accept ) => {
                socket.emit( "recalculate", rows.map( ( row:any ) => row._id), accept )
            })
        })
        .then( () => {
            setLoading(false);
        })
  };

  return (
      <>
        <Tooltip
            title={!allowSubmit ? "Select at least 1 item" : ""}
            placement="bottom"
            color={"var(--tertiary-color)"}
        >
          <Button
              onClick={handleOk}
              loading={isLoading}
              disabled={!allowSubmit}
              type="primary">
            Accrue
          </Button>
        </Tooltip>
      </>
  )

}
