import { useState } from "react";
import { Modal, Input, Form } from "antd";
import { failedNotification, successfulNotification } from "../../utils/notifications";

const ResetPassword = ({ reset, userContext }: { reset: any, userContext: any }) => {

  const [visible, setVisible] = useState(true);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);

  const getEmailFromUsername = (insertedUsername: string): string => {
    let usernameRegexCheck = /(015-)?(\w+)_\d+(@015vendor.com)?/;
    let matchResult = usernameRegexCheck.exec(insertedUsername);
  
    if (matchResult) {
      if (!matchResult[1]) {
        return `015-${insertedUsername}@015vendor.com`;
      }
  
      if (!matchResult[4]) {
        return `${insertedUsername}@015vendor.com`;
      }
    }
    
    return insertedUsername;
  }
  

  const handleOk = async () => {
    let userEmail = getEmailFromUsername(username);
    const data = {
      username: userEmail
    };
    setLoading(true);
    try {
        const res = await userContext.gpClient.post(
          `/google-gp-v1/sesameAccounts/resetPassword`,
          data,
        );
        setLoading(false);
        handleCancel();
        successfulNotification("Success",  `Password for ${res?.data?.username} successfully updated to 'changemenow'. The user will be prompted to change the password at first login.`)
      } catch (error: any) {
        setLoading(false);
        failedNotification("Error",  `Could not fetch users for the provided pattern. Please ensure the correct sesame language code is provided.`)
      }
  };

  const handleCancel = () => {
    reset();
    setVisible(false);
    setUsername("");
  };

  return (
    <Modal
      title="Reset Password"
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: !username || loading }}
      okText="Ok"
      confirmLoading={loading}
    >
      <Form layout="vertical">
        <Form.Item
          name="username"
          rules={[{ required: true }, {
            pattern: /(015-)?(\w+)_\d+(@015vendor.com)?/,
            message: "Invalid username. Accepted formats: 'es_1234', '015-es_1234', '015-es_1234@015vendor.com'."
          }]}
          extra={
            <span>
              Please insert the username in need of a password reset.", "Accepted formats: 'es_1234', '015-es_1234', '015-es_1234@015vendor.com
            </span>
          }
        >
          <Input
            placeholder="Enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ResetPassword;
