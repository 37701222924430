import React from "react";
import { Button, Select } from "antd";
import { SavedFilter } from "../Table/Table.abstract";

const { Option } = Select;
interface CurrentFilter extends SavedFilter {
  id?: string;
}

export interface FilterLoaderProps {
  currentSavedFilter?: CurrentFilter;
  appliedFilters?: any;
  savedFilters: SavedFilter[];
  onChange: (filter: SavedFilter) => Promise<void> | void;
  onUpdate?: (filter: SavedFilter) => Promise<void> | void;
  onDelete: (id: string) => Promise<void> | void;
  onReset: (id: string) => Promise<void> | void;
  controlsEnabled: boolean;
  loading:boolean;
}

export default function FiltersLoader(props: FilterLoaderProps) {

  return (
    <div className="my-filters">
      <p className="filters-title">My filters</p>
      <Select
        value={
          props.currentSavedFilter && !props.loading ? props.currentSavedFilter.id : undefined
        }
        loading={props.loading}
        placeholder={"Select saved filter"}
        disabled={props.loading}
        style={{ width: 200 }}
        onChange={(e) => {
          let filter = props.savedFilters.find((f) => f._id === e)!;

          if (typeof filter.filterData === "string") {
            filter.filterData = JSON.parse(filter.filterData);
          }

          // temporary fix for https://jira.sdl.com/browse/MER-2587
          // will be improved on https://jira.sdl.com/browse/MER-2594
          if (filter) {
            props.onReset(filter._id || "");
          }
          
          props.onChange(filter);
        }}
        size="small"
      >
        {
            props.loading ? [] : (props.savedFilters || []).map((filter) => (
                  <Option value={filter._id} key={filter._id}>
                    {filter.name}
                  </Option>
                ))
        }
      </Select>

      <div className="my-filters-buttons">
        <Button
          type="primary"
          size="small"
          onClick={() => {
            if (props.currentSavedFilter) {
              const newFilterData = props.currentSavedFilter;
              newFilterData.filterData = props.appliedFilters;

              props.onUpdate!(newFilterData);
            }
          }}
          disabled={!props.controlsEnabled || !props.currentSavedFilter || !props.appliedFilters}
        >
          Save
        </Button>
        <Button
          type="text"
          size="small"
          onClick={() => {
            if (props.currentSavedFilter) {
              props.onReset(props.currentSavedFilter.id || "");
            }
          }}
          disabled={!props.controlsEnabled || !props.currentSavedFilter}
        >
          Reset
        </Button>
        <Button
          danger
          disabled={!props.currentSavedFilter || !props.savedFilters.length}
          size="small"
          onClick={() => {
            if (props.currentSavedFilter) {
              props.onDelete!(props.currentSavedFilter._id);
            }
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
}
