import React, { useState } from 'react';
import { Modal, Form, InputNumber } from 'antd';


const EditWordCount = ({
    visible,
    selectedPLPs,
    onSubmit
  }) => {

    const [confirmLoading, setConfirmLoading] = useState(false);


    let initialValues = selectedPLPs.reduce(function (initialValues, selectedPLP) {
      if (!initialValues.wwc) {
        initialValues.wwc = selectedPLP.wwc;
      } else {
        if (initialValues.wwc !== selectedPLP.wwc) {
          initialValues.wwc = null;
        }
      }
      return initialValues;
    }, {})

    const [form] = Form.useForm();

    return (
      <Modal
        open={visible}
        title="Edit Word Count"
        okText="OK"
        cancelText="Cancel"
        onCancel={async () => {
          await onSubmit(false);
        }}
        onOk={async () => {
          try {
            let values = await form.validateFields();
            setConfirmLoading(true);
            await onSubmit(true, values);
            setConfirmLoading(false);
            form.resetFields();
          } catch (error) {
            console.log('Validate Failed:', error);
          }
        }}
        confirmLoading={confirmLoading}
      >
        <Form
          form={form}
          layout="vertical"
          name="editWordCount"
          initialValues={{
            modifier: 'public',
            totalWordCounttotalWeightedWordCount: initialValues.wwc,
          }}
        >
          <Form.Item
            name="totalWeightedWordCount"
            label="Total Weighted Word Count"
            rules={[{ required: true, message: 'Please enter the total weighted word count' }]}
          >
            <InputNumber step={0.01} />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  export default EditWordCount;
