import { createRef } from "react";
import {
  CustomTooltip,
  ListFilter,
  FiltersBar
} from "../Components";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import {
  BlockOutlined,
  DollarCircleOutlined,
  InfoCircleOutlined,
  PercentageOutlined,
  ReloadOutlined,
  SaveOutlined,
  ArrowLeftOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { NumericFormat as NumberFormat } from "react-number-format";
import StatusFilter from "../Search/Status.filter";
import TableWSAbstract from "../stories/Table/TableWS.abstract";
import { useParams, Navigate, Link } from "react-router-dom";
import VarStatusFilter from "../Search/VarStatus.filter";
import UrgencyFilter from "../Search/Urgency.filter";
import PolyglotLogo from "../stories/assets/polyglot-logo.svg";
import EmptyOverlay from "../stories/Table/EmptyOverlay";
import BaseTable from "../stories/Table/BaseTable";
import LoadingStar from "../stories/Loaders/LoadingStar";
import uuid from "uuid-browser";
import { Key } from "antd/es/table/interface";
import { Error as ErrorWarning } from "../stories/Errors/Error";
import { Helmet } from "react-helmet";
import Title from "antd/es/typography/Title";

import "../Search/Adjustments/CreateModal.scss";
import "./AdjustmentDetailsPage.scss";
import DeletePLPButton from "../Search/Adjustments/DeletePLPButton";
import BatchDetails from "../Search/Adjustments/BatchDetails";
import {
  productFilter,
  sourceLanguageCodeFilter,
  targetLanguageCodeFilter,
  latestTranslationDueTimestampFilter,
  receivedDateFilter
} from '../Search/filtersConfig';
import TextArea from "antd/es/input/TextArea";
import { useMercuryContext } from "../user-context";

class AdjustmentDetailsPage extends TableWSAbstract<any> {

  formRef = createRef() as any;

  private categories = ["correction", "bonus", "penalty", "admin", "credit"];

  linkRender = (id: string) => (
    <>
      <a
        href={`/project/${id}/?type=projectId`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <BlockOutlined style={{ marginRight: '3px' }} />
      </a>
      <Link to={`/project/${id}?type=projectId`} id={id}>
        {id}
      </Link>
    </>
  );

  renderProjectId = (id: string, record: any) => {

    const projectId = id ?? record.projectShortId;

    if (record.customVendorPLPMetadata?.projectType === 'WMT') {
      return this.linkRender(projectId);
    }

    return (
      <>
        {this.linkRender(projectId)}
      </>
    );
  };

  renderVarStatus = (text: string, record: any) => {

    const status = record.invoiceStatus ?? text;

    const hasInvoiceErrors = Array.isArray(record.invoiceData?.invoiceErrors) && record.invoiceData?.invoiceErrors.length > 0;
    const isNotAccrualEstimateInvoice = status !== "ACCRUAL" && status !== "ESTIMATE" && status !== "INVOICE";

    if (hasInvoiceErrors && isNotAccrualEstimateInvoice) {
      const errorMessage = record.invoiceData?.invoiceErrors.join("\r\n");
      return (
        <Tooltip title={errorMessage} placement="right" color={"var(--red)"}>
          {status}
          <InfoCircleOutlined style={{ color: "var(--red)", marginLeft: "3px" }} />
        </Tooltip>
      );
    }

    return status;
  };

  getAdjValue = (record: any) => {

    const formValues = {
      category: record?.batch?.category,
      description: record?.batch?.description,
      type: record?.batch?.type,
      amount: record?.batch?.amount,
      name: record?.batch?.name,
      PONumber: record?.batch?.PONumber,
      adminValues: record?.batch?.adminAmounts,
      useNewPONumber: !!record?.batch?.PONumber,
      penaltyValues: record?.batch?.penaltyAmounts
    };

    const invoiceAmount = record?.unadjustedCharge;

    const value = () => {
      switch (record?.batch?.category) {
        case "penalty":
          return calculatePenalty(formValues.penaltyValues, record, invoiceAmount);
        case "admin":
          return calculateAdmin(formValues.adminValues, record);
        default:
          return calculateDefault(formValues, record, invoiceAmount);
      }
    };

    const calculatePenalty = (penaltyValues: any, record: any, invoiceAmount: any) => {

      const adjPenaltyValue = penaltyValues.find((value: any) => value.language === record.targetLanguageCode);
      if (!adjPenaltyValue || !invoiceAmount) return 0;

      return adjPenaltyValue.type === "fixed"
        ? adjPenaltyValue.amount
        : (invoiceAmount * (adjPenaltyValue.amount / 100)).toFixed(4);
    };

    const calculateAdmin = (adminValues: any, record: any) => {
      const adjAdminValue = adminValues.find((value: any) => {
        return value.projectId === record.projectId && value.language === record.targetLanguageCode;
      });
      return adjAdminValue ? adjAdminValue.amount : 0;
    };

    const calculateDefault = (formValues: any, record: any, invoiceAmount: any) => {

      if (!formValues.amount) return 0;
      if (!formValues.type || formValues.type === "percentage") {
        return (invoiceAmount * (formValues.amount / 100)).toFixed(4);
      }
      return formValues.amount;
    };

    return <NumberFormat
      value={value()}
      decimalScale={4}
      fixedDecimalScale={true}
      displayType={"text"}
      thousandSeparator={true}
      prefix={"$"}
    />
  }

  footerRender = (data: any) => {
    return <div className={"adj-table-footer"}>
      <div className={"adj-table-total-projects"}>
        <dl>
          <dt>Total PLPs:</dt>
          <dd>{
            data.length >= 25000
              ?
              <span className={"adj-table-batch-size-warning"}>{data.length} (N.B. Maximum search size reached)</span>
              : data.length
          }</dd>
          <dt>Total amount:</dt>
          <dd>
            {data[0]?.batch?.category?.toUpperCase() === "ADMIN" ? (
              "N/A"
            ) : (
              <NumberFormat
                value={data[0]?.batch?.totalCharge?.toFixed(4)}
                decimalScale={4}
                fixedDecimalScale={true}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            )}
          </dd>
          <dt>Total Adjustment:</dt>
          <dd>
            {data[0]?.batch?.category?.toUpperCase() === "ADMIN" ? (
              "N/A"
            ) : (
              <NumberFormat
                value={data[0]?.batch?.adjustmentAmount?.toFixed(4)}
                decimalScale={4}
                fixedDecimalScale={true}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            )}
          </dd>
        </dl>
      </div>
    </div>
  }

  adminValueRender = (text: string, record: any, id: any) => {
    const { formValues, adminValues } = this.state.meta;

    if (formValues.category === "admin") {
      const adjAdminValue = adminValues.find((value: any) => {
        return value.projectId === record.projectId && value.language === record.targetLang
      });
      if (adjAdminValue) {
        return adjAdminValue[id];
      }
    } else {
      return text;
    }
  }

  defaultFilters = [
    {
      id: "projectId",
      name: "projectShortId",
      title: "Project ID",
      component: ListFilter,
      active: true
    },
    productFilter,
    sourceLanguageCodeFilter,
    targetLanguageCodeFilter
  ]

  adjFilters = [
    {
      id: "varStatus",
      name: "varStatus",
      title: "Invoice Status",
      component: VarStatusFilter,
      active: true
    },
    {
      id: "status",
      name: "catToolStatus",
      title: "Status",
      component: StatusFilter,
      active: true
    },
    {
      id: "urgency",
      name: "urgency",
      title: "Urgency",
      component: UrgencyFilter,
      active: true
    },
    latestTranslationDueTimestampFilter,
    receivedDateFilter
  ]

  defaultSortField = "name";
  FilterComponents = [
    ...this.defaultFilters,
    ...this.adjFilters
  ];

  columns = [
    {
      id: "projectId",
      dataIndex: "projectShortId",
      title: "Project ID",
      sorter: false,
      width: 140,
      fixed: "left",
      render: (id: string, record: any) => this.renderProjectId(id, record)
    },
    {
      id: "targetLanguageCode",
      title: <Tooltip title="Target Language" placement="left" color={"var(--tertiary-color)"}>Target La...</Tooltip> as any,
      dataIndex: "targetLang",
      sorter: false,
      width: 200,
      fixed: "left",
      render: (text: string, record: any) => {
        return record.targetLanguageCode ?? text
      }
    },
    {
      id: "product",
      title: "Product",
      dataIndex: "product",
      sorter: false,
      width: 200,
      render: (text: string) => {
        return <CustomTooltip text={text} length={25} />
      },
      fixed: undefined,
    },
    {
      id: "varStatus",
      title: "Invoice Status",
      dataIndex: "varStatus",
      sorter: false,
      width: 163,
      fixed: undefined,
      render: (text: string, record: any) => this.renderVarStatus(text, record)
    },
    {
      id: "PONumber",
      title: "PO Number",
      dataIndex: "PONumber",
      sorter: false,
      fixed: undefined,
      render: (text: string, record: any) => {
        return record.batch.PONumber ?? this.adminValueRender(text, record, "PONumber")
      }
      
    },
    {
      id: "invoiceName",
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      sorter: false,
      fixed: undefined,
      render: (text: string, record: any) => {
        return record.batch.invoiceName ?? this.adminValueRender(text, record, "invoiceNumber")
      }
    },
    {
      id: "unadjustedCharge",
      title: "Invoice Amount",
      dataIndex: "unadjustedCharge",
      sorter: false,
      render: (text: string, record: any) => {
        return <NumberFormat
          value={text}
          decimalScale={4}
          fixedDecimalScale={true}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      }
    },
    {
      id: "adjustment",
      title: "Adjustment",
      dataIndex: "adjustment",
      sorter: false,
      render: (text: any, record: any) => this.getAdjValue(record),
    }
  ];

  onResize = () => {
    const newTableWidth = this.calculateTableWidth();
    if (newTableWidth !== null) {
      this.setState({ meta: { ...this.state.meta, tableWidth: newTableWidth } });
    }
  }

  calculateTableWidth = (): number | null => {
    return this.yardStick.current ? this.yardStick.current.getBoundingClientRect().width : null;
  }

  rowClassName = (record: any): string => {
    return this.state.changedIds?.has(record?._id) ? "rowUpdated" : "";
  }

  defaultDisabledColumns = []

  pageTitle = "Edit adjustments - Mercury";
  title = "PLPs";
  pageClass = "AdjTable"
  public rowMap: Map<string, any> = new Map();

  public floodPrevention = (): void => {
    clearTimeout(this.floodTimer);
    this.floodTimer = undefined;
    this.setState({
      rowData: Array.from(this.rowMap.values()),
      changedIds: this.state.changedIds
    });
  }

  public floodTimer?: number;

  async componentDidMount() {

    this.props.context.gpClient.get(`/google-gp-v1/adjustments/${this.props.id}`).then((res: any) => {

      this.setState({ meta: res.data })

      if (res.data?.penaltyAmounts) {
        this.setState({ meta: { ...this.state.meta, penaltyValues: res.data.penaltyAmounts } })
      }

      const defaultValues = {
        category: this.state?.meta?.category,
        description: this.state?.meta?.description,
        type: this.state?.meta?.type,
        amount: this.state?.meta?.amount,
        name: this.state?.meta?.name,
        PONumber: this.state?.meta?.PONumber,
        adminValues: this.state?.meta?.adminAmounts,
        useNewPONumber: !!this.state?.meta?.PONumber,
        penaltyValues: this.state?.meta?.penaltyAmounts
      };

      this.setState({
        meta: {
          ...this.state.meta,
          adminValues: defaultValues.adminValues,
          formValues: { ...defaultValues, PONumber: undefined, useNewPONumber: true },
          plpData: this.state.rowData,
          loading: false,
          disablePercentage: false,
          tableWidth: 0,
          langsAdj: [],
          penaltyFormValues: { type: "percentage" },
          allPlpsHavePONumber: true,
          redirect: false,
          show: false,
          onClose: false
        },
      });

      this.formRef.current.setFieldsValue(defaultValues && defaultValues);

    })

    await super.componentDidMount();

    const { gpSocket, gpClient } = this.props.context;
    const socket = gpSocket.adj;

    socket.on("tail", (doc) => {
      const { changedIds, rowData } = this.state;

      this.rowMap.set(doc?._id, doc);
      if (changedIds) {
        changedIds.add(doc?._id);
      }

      const updatedRowData = [doc, ...rowData];

      this.setState({
        rowData: updatedRowData,
        changedIds
      });
    });


    window.addEventListener("resize", this.onResize);
    const response = await gpClient.get("/google-gp-v1/plp/uniqueLanguageCodes");
    this.setState({ meta: { ...this.state.meta, langsAdj: response.data } });
    this.onResize();
    this.yardStick = createRef();
  }

  private removeWindowResizeListener() {
    window.removeEventListener("resize", this.onResize);
  }

  private resetYardStick() {
    this.yardStick = createRef();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    super.componentDidUpdate(prevProps, prevState);

    // remove overflow hidden that stops scrollable table
    const body = document.querySelector("body");
    if (body) body.style.removeProperty("overflow");

    if (prevState.reqId !== this.state.reqId) {
      this.handleSocketEvents(prevState.reqId);
    }
  }

  private handleSocketEvents(prevReqId: string) {
    const socket = this.props.context.gpSocket.adj;
    this.unbindEvents(socket, prevReqId);
    this.bindRowEvent(socket);
    this.bindMetaEvent(socket);
  }

  private unbindEvents(socket: any, prevReqId: string) {
    socket.off(`search:row:${prevReqId}`);
    socket.off(`search:meta:${prevReqId}`);
  }

  private bindRowEvent(socket: any) {
    socket.on(`search:row:${this.state.reqId}`, (row: any) => {
      this.processRowData(row);
    });
  }

  private processRowData(row: any) {
    const rows = Array.isArray(row) ? row : [row];
    rows.forEach((item: any) => {
      this.rowMap.set(item?._id, item);
      this.bindDocumentEvent(item?._id);
    });
    this.updateStateAfterRowData();
  }

  private bindDocumentEvent(docId: string) {
    const socket = this.props.context.gpSocket.adj;
    socket.on(docId, (doc: any) => {
      this.state.changedIds?.add(doc?._id);
      if (this.rowMap.has(doc?._id)) {
        this.rowMap.set(doc?._id, doc);
        this.debounceFloodPrevention();
      }
    });
  }

  private debounceFloodPrevention() {
    if (this.floodTimer) {
      clearTimeout(this.floodTimer);
    }
    this.floodTimer = setTimeout(() => this.floodPrevention(), 500) as any;
  }

  private updateStateAfterRowData() {
    this.setState({
      loading: false,
      changedIds: new Set(),
      rowData: Array.from(this.rowMap.values())
    });
  }

  private bindMetaEvent(socket: any) {
    socket.on(`search:meta:${this.state.reqId}`, (meta: any) => {
      this.setState({
        selectedRows: [],
        currentPage: meta.page,
        meta: meta
      });
    });
  }


  componentWillUnmount() {
    this.detachSocketListener();
    this.removeWindowResizeListener();
    this.resetYardStick();
  }

  private detachSocketListener() {
    const socket = this.props.context.gpSocket.adj;
    super.componentWillUnmount();
    socket.off(`search:row:${this.state.reqId}`)
    socket.off(`search:meta:${this.state.reqId}`)
    socket.off(`tail`);
    Array.from((this.rowMap.keys())).forEach(key => {
      socket.off(key);
    });
  }


  async getData(): Promise<void> {
    const { rowData } = this.state;
    const ids = rowData.map((row: any) => row?._id);

    this.clearData(ids);
    this.setState({ reqId: uuid(), loading: true }, this.emitSocketEvents);
  }

  private clearData(ids: string[]) {
    const socket = this.userContext.gpSocket.adj;
    this.rowMap.clear();
    socket.emit("unbind:ids", ids);
    socket.emit("search:abort");
    ids.forEach(id => socket.off(id));
  }

  private emitSocketEvents = () => {
    const { sortOrder, sortField } = this.state;
    const filters = this.filtersToQuery();
    const sort = this.getSortObject(sortField, sortOrder);

    this.handleTailing(filters, sort);
    this.emitSearchDetails();
  }

  private getSortObject(sortField: string | undefined, sortOrder: string): object {
    return {
      [sortField ?? "importedTimestamp"]: sortOrder === "ascend" ? 1 : -1
    };
  }

  private handleTailing(filters: object, sort: object) {
    const socket = this.userContext.gpSocket.adj;
    if (this.shouldTail(filters, sort)) {
      socket.emit("tail", () => console.info("tailing for new projects activated..."));
    } else {
      socket.emit("untail", () => console.info("tailing de-activated"));
    }
  }

  private shouldTail(filters: any, sort: any): boolean {
    return (
      sort["importedTimestamp"] === -1 &&
      Object.keys(filters).length === 0 ||
      (
        Object.keys(filters).length === 1 &&
        filters["importedTimestamp"]?.length > 1 &&
        new Date(filters["importedTimestamp"][1]) > new Date()
      )
    );
  }

  private emitSearchDetails() {
    const socket = this.userContext.gpSocket.adj;
    socket.emit(`adjustmentPLPDetailsBySearch`, {
      filter: this.filtersToQuery(),
      sort: { projectId: -1 },
      pagination: {
        pageNumber: 1,
        resultsPerPage: 25000
      },
      reqId: this.state.reqId,
      batchId: this.props.id,
    }, () => {
      this.setState({ loading: false, changedIds: new Set() });
    });
  }


  send(): Promise<void> {
    return Promise.resolve(undefined);
  }

  submit = async (e: any) => {

    let { useNewPONumber, plpIds, adminValues, ...submission } = e;
    let { penaltyAmounts } = this.state.meta;

    this.setState({ loading: true })

    if (e?.category === "penalty") {
      penaltyAmounts = this.state.meta.penaltyAmounts.map((v: any) => {
        delete v?._id
        return v;
      })
    }

    try {

      const
        { data } = await this.props.context.gpClient.patch(`/google-gp-v1/adjustments/${this.props.id}`, {
          ...submission,
          penaltyAmounts,
        }),
        batch = data;
      console.log('batch', batch)

      this.setState({ loading: false, meta: { ...this.state.meta, redirect: true }} ) 


    } catch (e) {
      console.error(e);
    }
  };

  onFormChange(name: string, value: any) {
    this.formRef.current.setFieldsValue({ ...this.formRef.current.getFieldsValue(), [name]: value });
    this.setState({ meta: { ...this.state.meta, formValues: this.formRef.current.getFieldsValue() } })
  }


  tableRender(actions?: any) {

    if (this.state.error) {
      return <ErrorWarning resetFilters={this.clear} reload={this.reload} error={this.state.error.toString()} />
    }

    const
      { rowData } = this.state,
      cols = this.columnMapping()
        .map((item) => this.column(item as any)),
      // .sort(this.sortById);
      penaltyLanguages = rowData[0]?.batch?.penaltyAmounts.map((v: any) => v.language),
      filteredData = rowData[0]?.batch?.category === "penalty" ? rowData.filter((plp: any) => penaltyLanguages.indexOf(plp.targetLang) !== -1) : rowData,
      enableSelection = rowData[0]?.batch?.category === "admin" || rowData[0]?.status?.toUpperCase() === "INVOICED";

    // @ts-ignore
    return <BaseTable
      ref={this.tableRef}
      className='new-projects-table'
      dataSource={rowData}
      columns={cols}
      onColumnSort={(sorter: any) => this.sort({}, {}, sorter, {} as any)}
      // @ts-ignore
      sortBy={{ key: this.state.sortField, order: this.state.sortOrder === 'ascend' ? 'asc' : this.state.sortOrder === 'descend' ? 'desc' : this.state.sortOrder }}
      loading={this.state.loading}
      rowClassName={this.rowClassName}
      rowSelection={{
        selectedRowKeys: this.state.selectedRows.map((r: any) => r.key) as Key[],
        onSelectAll: this.selectAll,
        onSelect: this.select,
        // fixed: true
      }}
      rowHeight={this.props.rowHeight}
      disabled={this.state.loading}
      enabledRowSelection={!enableSelection}
      itemsPerPage={this.state.itemsPerPage}
      showFilters={this.state.showFilters}
      updateColumnWidth={this.updateColumnWidth}
      CellRenderer={this.props.CellRender}
      hasSidebar={this.props.hasSidebar}
      filtersRef={this.props.filtersRef}
      overlayRenderer={
        () =>
          this.state.loading ? <div className={"loading-table-wrapper"}><LoadingStar logo={true} animate={true} title={""} /></div> : null
      }
      emptyRenderer={<EmptyOverlay reset={this.clear} loading={this.state.loading} />}
      // @ts-ignore
      width={this.state.meta.tableWidth}
      height={400}
      footerHeight={30}
      footerRenderer={() => this.footerRender(rowData)}
    />
  }

  yardStick: any = createRef<any>();

  filtersRender() {

    const [rowData] = this.state.rowData;

    const { filters, savedFilters, filtersLoading, currentSavedFilter, loading } = this.state;

    if (rowData?.batch?.category === "admin" || rowData?.status?.toUpperCase() === "INVOICED") {
      return null;
    }

    const filterState = rowData?.batch?.category === "penalty"
      ? this.FilterComponents.filter(f => f.name !== "targetLanguageCode")
      : this.FilterComponents;

    return (
      <div className="filterWrapperRef" ref={this.filtersRef}>
        <FiltersBar
          title={this.title}
          onChange={this.updateAllFilters}
          filters={filterState.map((filter: any) => {
            return {
              id: filter.id,
              component: filter.component,
              title: filter.title,
              key: filter.name,
              name: filter.name,
              value: Array.isArray(filter.name)
                ? filter.name.map(
                  (name: any) => this.state.filters[name]
                )
                : this.state.filters[filter.name],
              onRemove: this.onRemoveFromList,
              onChange: (e: any) => {
                this.onUpdateFilter(e);
                this.setState({ selectedRows: [] });
              },
              disabled: this.state.disabledFilters.has(filter.name),
              onClearFilter: this.onClearFilter,
              active: filter.active
            };
          }) as any}
          onSave={this.onSaveFilters}
          onLoadedFilterChange={this.onChangeLoadedFilter}
          onLoadedFilterDelete={this.onDeleteSavedFilter}
          onLoadedFilterUpdate={this.onUpdateSavedFilter}
          onLoadedFilterReset={this.onResetSavedFilter}
          savedFilters={savedFilters}
          filtersLoading={filtersLoading}
          currentSavedFilter={currentSavedFilter}
          appliedFilters={filters}
          onClearAll={this.clear}
          loading={loading}
          additionalButtons={this.renderAdditionalButtons()}
          controlsEnabled={false}
          onApplyMoreLess={this.onApplyMoreLess}
          onChangeMoreLess={this.onChangeMoreLess}
        />
      </div>
    );
  }

  renderAdditionalButtons() {
    const { loading } = this.state;
    return [
      <Button
        size="small"
        disabled={loading}
        key="reload"
        onClick={this.handleReload}
      >
        <ReloadOutlined spin={loading} /> Reload
      </Button>
    ];
  }

  handleReload = async () => {
    this.bustCache();
    await this.getData();
  }


  formItemRefRender() {
    return (
      <Form.Item
        label="Internal reference:"
        name="name"
        rules={[{ required: true, message: "Internal name should be set" }]}
      >
        <Input type={"text"} placeholder={"Internal reference ( Not sent to google )"} onChange={(e) => this.onFormChange("name", e.target.value)} disabled={this.state.meta.status?.toUpperCase() === "INVOICED"} />
      </Form.Item>
    );
  }

  formItemAdjTypeRender() {
    return (
      <Form.Item
        label="Adjustment Type:"
        name="category"
      >
        <Select
          placeholder="Select an option"
          value={this.state.meta.category}
          onChange={(e) => this.onFormChange("category", e)}
          disabled={true}
        >
          {this.categories.map((cat) => {
            return (
              <Select.Option key={cat} value={cat} selected={this.state.meta.category === cat}>
                {cat[0].toUpperCase() + cat.slice(1)}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }

  formRender() {
    return <div className={"adjustment-edit"}>
      {this.state.meta && (
        <Form
          id={"edit-adjustment"}
          name="edit-adjustment"
          layout="vertical"
          onFinish={this.submit}
          ref={this.formRef}
        >
          <Row gutter={0}>
            <Col span={24} ref={this.yardStick}>
              {this.formItemRefRender()}
            </Col>
          </Row>
          <Row gutter={0}>
            <Col span={24}>
              {this.formItemAdjTypeRender()}
            </Col>
          </Row>
          {this.formElementsRender()}
        </Form>)}
    </div>
  }

  penaltyFormUpdate(name: any, value: any, id: any) {

    this.setState({
      meta: {
        ...this.state.meta,
        penaltyValues: this.state.meta.penaltyValues.map((pen: any) => {
          if (pen?._id === id) {
            pen[name] = value;
          }
          return pen;
        })
      }

    });

    if (name === 'language') {
      const map: any = {};
      for (const item of this.state.meta.penaltyValues) {
        map[item.language] = true;
      }
      const newPlps = this.state.meta.filter((pen: any) => map[pen.targetLang]);
      this.setState({ meta: { ...this.state.meta, selectedPLPs: newPlps } })
    }

  }

  penaltyRows() {

    const { penaltyValues } = this.state.meta;
    const selectedLanguages = this.state.rowData.map(item => item.targetLanguageCode);

    return (
      <>
        {penaltyValues.length > 0 &&
          penaltyValues
            .filter((pen: any, index: number) => {
              return selectedLanguages.includes(pen?.language);
            })
            .map((row: any, index: number) => {
              return this.renderPenaltyRows(row, index);
            })}
      </>
    );
  }


  setPenaltyLanguage(row: any) {

    this.setState({
      selectedRows: this.rows.filter((item) => {
        if (item.targetLanguageCode === row.language) {
          return item.key
        }
      })
    });

    this.setState({ meta: { ...this.state.meta, removeRow: row, show: true, onClose: false } });

  }

  removePenaltyLanguage(row: any) {

    const { penaltyValues } = this.state.meta;
    const selectedLanguages = this.state.rowData.map(item => item.targetLanguageCode);    
    
    let values: any = [];

    penaltyValues.length > 0 && penaltyValues
      .filter((pen: any, index: number) => {
        return selectedLanguages.includes(pen?.language);
      })
      .map((row: any, index: number) => {
        values.push(row)
      });

    this.setState({ meta: { ...this.state.meta, penaltyAmounts: values, removeRow: row, show: true, onClose: false } });

  }

  renderPenaltyRows = (row: any, index: number) => {

    const [rowData] = this.state.rowData;
    const disabled = rowData?.batch?.category === "admin" || rowData?.status?.toUpperCase() === "INVOICED";

    return (
      <div className={"penalty-row"} key={row?._id}>
        <Form.Item
          className={"penalty-language"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            onChange={(e) => this.penaltyFormUpdate("language", e, row?._id)}
            placeholder={"Select language code"}
            value={row.language ? row.language : ""}
            disabled
          >
            {rowData?.batch?.targetLanguageCode.map((lang: any) => (
              <Select.Option value={lang} key={lang}>
                {lang}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className={"penalty-type"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group
            name={"type"}
            onChange={(e) => {
              this.penaltyFormUpdate("type", e.target.value, row?._id);
            }}
            value={row.type}
            disabled={disabled}
          >
            <Radio
              value="percentage"
            >
              Percent
            </Radio>
            <Radio value="fixed">Fixed Amount</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className={"penalty-amount"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            defaultValue={row?.amount}
            placeholder={
              this.state.meta.formValues.type === "fixed"
                ? "Insert amount"
                : "Insert percent"
            }
            onChange={(e) => this.penaltyFormUpdate("amount", e, row?._id)}
            style={{ width: "98.5%" }}
            precision={2}
            min={
              this.formRef.current.getFieldValue("type") === "percentage"
                ? -100
                : -Number.MAX_VALUE
            }
            max={
              this.formRef.current.getFieldValue("type") === "percentage"
                ? 100
                : Number.MAX_VALUE
            }
            addonAfter={
              this.formRef.current.getFieldValue("type") === "percentage" ? (
                <PercentageOutlined />
              ) : (
                <DollarCircleOutlined />
              )
            }
            disabled={disabled}
          />
        </Form.Item>
        {!disabled && (
          <Form.Item className={"penalty-btn"}>
            <Button
              danger
              onClick={() => {
                this.setPenaltyLanguage(row);
              }}
              disabled={row.amount === null && true}
            >
              <DeleteOutlined />
            </Button>
          </Form.Item>
        )}
      </div>
    );
  }

  formItemTypeRender() {
    return (
      <Form.Item name="type" label="Value:" required>
        <Radio.Group onChange={e => this.onFormChange("type", e.target.value)} disabled={this.state.meta.status?.toUpperCase() === "INVOICED"}>
          <Radio value="percentage">Percent</Radio>
          <Radio value="fixed">Fixed Amount</Radio>
        </Radio.Group>
      </Form.Item>
    );
  }

  formItemAmountRender() {
    const { formValues } = this.state.meta;
    return (
      <Form.Item name="amount" rules={[{ required: true }]}>
        <InputNumber
          name="amount"
          value={formValues?.amount}
          placeholder={formValues?.type === "fixed" ? "Insert amount" : "Insert percent"}
          onChange={e => this.onFormChange("amount", e)}
          style={{ width: "98.5%" }}
          precision={2}
          min={formValues?.type === "percentage" ? -100 : -Number.MAX_VALUE}
          max={formValues?.type === "percentage" ? 100 : Number.MAX_VALUE}
          addonAfter={formValues?.type === "percentage" && <PercentageOutlined />}
          addonBefore={formValues?.type === "fixed" && <DollarCircleOutlined />}
          disabled={this.state.meta.status?.toUpperCase() === "INVOICED"}
        />
      </Form.Item>
    );
  }

  formItemPoRender() {
    const { formValues } = this.state.meta;
    return (
      <Form.Item
        name="PONumber"
        rules={[
          {
            required: formValues.useNewPONumber ? false : true,
            message: "Please type PO Number",
          },
        ]}
      >
        <Input
          disabled={formValues.useNewPONumber === undefined || formValues.useNewPONumber || this.state.meta.status?.toUpperCase() === "INVOICED" ? true : false}
          placeholder="Will be sent to google in place of project PO Number"
          value={formValues.PONumber}
          style={{ width: "98.5%" }}
          onChange={(e) => {
            this.onFormChange("PONumber", e.target.value);
          }}
        />
      </Form.Item>
    );
  }

  formItemCategoryRender() {
    return (
      <Form.Item label="PO Number:" required>
        <Form.Item noStyle name={"useNewPONumber"}>
          <Radio.Group
            value={this.formRef.current.getFieldValue("useNewPONumber") || true}
            onChange={(e) => this.onFormChange("useNewPONumber", e.target.value)}
            disabled={this.state?.meta?.status?.toUpperCase() === "INVOICED"}
          >
            <Space direction="vertical">
              <Tooltip
                title={!this.state?.meta?.allPlpsHavePONumber ? 'Not all selected PLPS have a PO Number, please specify PO Number below' : ''}
                placement="right"
                color={"var(--red)"}
                overlayInnerStyle={{ width: "508px" }}
              >
                <Radio
                  value={true}
                  disabled={!this.state?.meta?.allPlpsHavePONumber}
                  checked={this.formRef.current.getFieldValue("useNewPONumber")}
                >
                  Use Project PO Number
                  {!this.state?.meta?.allPlpsHavePONumber && <InfoCircleOutlined style={{ color: "var(--red)", marginLeft: "3px" }} />}
                </Radio>
              </Tooltip>
              <Radio value={false} checked={this.formRef.current.getFieldValue("useNewPONumber")}>Specify PO Number</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        {this.formItemPoRender()}
      </Form.Item>
    );
  }

  formItemDescriptRender() {
    return (
      <Form.Item
        name="description"
        label="Description:"
      >
        <TextArea rows={4} maxLength={500} placeholder={"Will be sent to google where applicable"} disabled={this.state.meta.status?.toUpperCase() === "INVOICED"} />
      </Form.Item>
    );
  }

  formItemFooterRender() {
    return (
      <Form.Item key="footer-form-item">
        <Space
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Link to={`/adjustments`}>
            <Button
              key="cancel"
              type="default"
              icon={<ArrowLeftOutlined />}
            >
              Cancel
            </Button>
          </Link>

          <Button
            onClick={this.formRef.current.submit}
            loading={this.state.loading}
            disabled={this.state.loading}
            icon={<SaveOutlined />}
            type="primary"
          >
            Save
          </Button>
        </Space>
      </Form.Item>
    );
  }

  handleShow = () => this.setState({ meta: { ...this.state.meta, show: true }} );
  handleClose = () => this.setState({ selectedRows: [], meta: { ...this.state.meta, show: false }} ) 

  formElementsRender() {
    const
      formValues: Record<string, any> = this.state.meta.formValues;

    if (!formValues?.category) {
      return null;
    }

    return (
      <>
        <hr />
        <Row gutter={0}>
          <Col span={24}>
            {["penalty", "admin"].indexOf(formValues?.category) === -1 && (
              <>
                {this.formItemTypeRender()}
                {this.formItemAmountRender()}
                <hr />
              </>
            )}
          </Col>
        </Row>
        <>
          <Row gutter={0}>
            <Col span={24}>
              <>
                {formValues?.category === "penalty" && this.penaltyRows()}
                {this.filtersRender()}
                {this.tableRender()}
              </>
            </Col>
          </Row>
          <hr />
        </>
        <Space
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <DeletePLPButton
            selectedRows={
              this.state.selectedRows.map((item: any) => {
                return { ...item, adjustment: this.getAdjValue(item) };
            })
          }
            batchId={this.props.id}
            reload={async () => {
              this.clear();
              this.bustCache();
              await this.getData();
              this.removePenaltyLanguage(this.state.meta.removeRow);
              this.handleClose();
            }}
            show={this.state.meta.show}
            handleClose={this.handleClose}
            handleShow={this.handleShow}
          />
        </Space>
        {formValues?.category && (
          <>
            <Row gutter={0}>
              <Col span={24}>{this.formItemCategoryRender()}</Col>
            </Row>
            <Row gutter={0}>
              <Col span={24}>{this.formItemDescriptRender()}</Col>
            </Row>
            <Row gutter={0}>
              <Col span={24}>{this.formItemFooterRender()}</Col>
            </Row>
          </>
        )}
      </>
    );
  }

  render() {

    const { id } = this.state.meta;
    const [rowData] = this.state.rowData;

    if (this.state.meta.redirect) {
      return <Navigate to="/adjustments" />;
    }

    return (
      <div id={"adjustmentDetailsPage"}>
        <div className={"table-title-container"}>
          <Helmet>
            <title>
              {`Adjustment batch - ${id?.slice(-8)}`}
            </title>
          </Helmet>
          <Title level={3} className={"table-title"}>
            {`Adjustment batch - ${id?.slice(-8)}`}
          </Title>
        </div>

        <div className={"adjustment-edit"}>
          <BatchDetails rowData={rowData?.batch} />
          {this.formRender()}
        </div>
      </div>

    );

  }

}

export default function (props: any) {

  const
    context = useMercuryContext(),
    id = useParams();

  // @ts-ignore
  return <AdjustmentDetailsPage context={context} id={id.adj} {...props} />
}
