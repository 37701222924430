import React from "react";
import {FiniteListFilter, FiniteListProps} from "../stories/FiniteListFilter/FiniteListFilter";

export default (props: FiniteListProps) => {
    const options = [
        "PRIORITY",
        "STANDARD",
        "TIER_UNSPECIFIED",
    ];
    return <FiniteListFilter
        {...props}
        data={options.map( ( option, i ) =>{
            return {
                key: i,
                title: option,
                value: option
            }
        })} />
}
