import React from "react";
import {Form, Input} from "antd";

export default function InvoiceNames( props ) {

  const {
    handleInvoiceNameChange,
    invoiceNameString
  } = props;

  return (
      <Form.Item
          label={"Invoice name"}
          name="invoiceNames"
          rules={[{ required: false}]}
          key={props.key}
      >
        <Input
            placeholder="Enter an invoice name"
            autoComplete="off"
            type="text"
            onChange={handleInvoiceNameChange}
            value={invoiceNameString}
            aria-expanded="true" />

      </Form.Item>
  )
}
