import moment from "moment";

const datePickerRanges = {
  "Last 6 months": [
    moment().subtract(5, "months").startOf("month"),
    moment().endOf("month"),
  ],
  "This Month": [moment().startOf("month"), moment().endOf("month")],
  "Today": [moment(), moment()],
};

// generate options dynamically
// add last month option
datePickerRanges[
  moment().subtract(1, "month").startOf("month").format("MMM YYYY").toString()
] = [
  moment().subtract(1, "months").startOf("month"),
  moment().subtract(1, "months").endOf("month"),
];

// add 2 months ago option
datePickerRanges[
  moment().subtract(2, "month").startOf("month").format("MMM YYYY").toString()
] = [
  moment().subtract(2, "months").startOf("month"),
  moment().subtract(2, "months").endOf("month"),
];

// add 3 months ago option
datePickerRanges[
  moment().subtract(3, "month").startOf("month").format("MMM YYYY").toString()
] = [
  moment().subtract(3, "months").startOf("month"),
  moment().subtract(3, "months").endOf("month"),
];

export default datePickerRanges;
