import React, { useState } from "react";
import Total from "../../../ui/Total";
import { sorter } from "../../../utils/helpers";

import { Table, Typography, Skeleton, Input, Spin, Row } from "antd";

import { EditOutlined, CheckOutlined, CloseOutlined, DeleteOutlined  } from "@ant-design/icons";

const CurrenciesTable = ({ values, handlers }) => {
  const { currencies, sortedInfo, editing, total } = values;
  const { handleChangeTable, handleClickEdit, handleClickDelete } = handlers;

  const { Link, Title } = Typography;
  const { Search } = Input;

  const [filterTable, setFilterTable] = useState(null);

  const data =
    currencies &&
    currencies.map((item, index) => {
      return {
        key: index,
        id: item.id,
        name: item.name,
        symbol: item.symbol,
        decimalCharacter: item.decimalCharacter,
        separatorCharacter: item.separatorCharacter,
        precision: item.precision,
        displayPattern: item.displayPattern,
        negativeDisplayPattern: item.negativeDisplayPattern,
        isActive: item.isActive,
      };
    });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => sorter(a.name, b.name),
      sortOrder: sortedInfo.field === "name" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.name === editing.editingRow.name ? (
            text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text
        ),
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      sorter: (a, b) => sorter(a.symbol, b.symbol),
      sortOrder: sortedInfo.field === "symbol" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.name === editing.editingRow.name ? (
            text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text
        ),
    },
    {
      title: "Decimal Character",
      dataIndex: "decimalCharacter",
      sorter: (a, b) => sorter(a.decimalCharacter, b.decimalCharacter),
      sortOrder: sortedInfo.field === "decimalCharacter" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.name === editing.editingRow.name ? (
            text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text
        ),
    },
    {
      title: "Separator Character",
      dataIndex: "separatorCharacter",
      sorter: (a, b) => sorter(a.separatorCharacter, b.separatorCharacter),
      sortOrder: sortedInfo.field === "separatorCharacter" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.name === editing.editingRow.name ? (
            text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text
        ),
    },
    {
      title: "Precision",
      dataIndex: "precision",
      sorter: (a, b) => sorter(a.precision, b.precision),
      sortOrder: sortedInfo.field === "precision" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.name === editing.editingRow.name ? (
            text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text
        ),
    },
    {
      title: "Display Pattern",
      dataIndex: "displayPattern",
      sorter: (a, b) => sorter(a.displayPattern, b.displayPattern),
      sortOrder: sortedInfo.field === "displayPattern" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.name === editing.editingRow.name ? (
            text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text
        ),
    },
    {
      title: "Negative Display Pattern",
      dataIndex: "negativeDisplayPattern",
      sorter: (a, b) => sorter(a.negativeDisplayPattern, b.negativeDisplayPattern),
      sortOrder: sortedInfo.field === "negativeDisplayPattern" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.name === editing.editingRow.name ? (
            text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text
        ),
    },
    {
      title: "Active",
      dataIndex: "isActive",
      width: 100,
      sorter: (a, b) => sorter(a.isActive, b.isActive),
      sortOrder: sortedInfo.field === "isActive" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.name === editing.editingRow.name ? (
            record.isActive === true ? (
              <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
            ) : (
              <CloseOutlined style={{ color: "var(--red)" }} />
            )
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : record.isActive === true ? (
          <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
        ) : (
          <CloseOutlined style={{ color: "var(--red)" }} />
        ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      width: 100,
      render: (text, record) =>
        editing.isEditing && record.name !== editing.editingRow.name ? (
          <Skeleton active size="small" title={true} paragraph={false} />
        ) : (
          <Link
            onClick={() => {
              handleClickEdit(record);
            }}
            style={{
              marginRight: "10px",
            }}
          >
            <EditOutlined /> Edit
          </Link>
        ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      width: 100,
      render: (text, record) =>
        editing.isDeleting && record.name !== editing.editingRow.name ? (
          <Skeleton active size="small" title={true} paragraph={false} />
        ) : (
          <Link
            onClick={() => {
              handleClickDelete(record);
            }}
            style={{
              marginRight: "10px",
            }}
          >
            <DeleteOutlined /> Delete
          </Link>
        ),
    },
  ];

  const handleSearch = (value) => {
    const filterTable = data.filter((item) =>
      item.name.toString().toLowerCase().includes(value.toLowerCase())
    );

    setFilterTable(filterTable);
  };

  return (
    <>
      <Search
        style={{ width: "300px", margin: "0 0 10px 0" }}
        placeholder="Search by name"
        onSearch={handleSearch}
        allowClear
        enterButton
      />
      <Row className="title-wrap">
        <Title level={5} style={{ margin: "0" }}>
          CURRENCY LIST
          {editing.isEditing || editing.isDeleting ? (
            <Spin size="small" style={{ marginLeft: "10px" }} />
          ) : null}
        </Title>
        <Total number={total} text="Currencies" />
      </Row>

      <Table
        columns={columns}
        dataSource={filterTable == null ? data : filterTable}
        size="small"
        className="manage-page-table"
        style={{ width: "100%"}}
        onChange={handleChangeTable}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: 20,
        }}
      />
    </>
  );
};

export default CurrenciesTable;
