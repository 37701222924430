const isEmpty = obj => {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop) && obj[prop] !== undefined && ((Array.isArray(obj[prop]) && obj[prop].length > 0) || (!Array.isArray(obj[prop]) && obj[prop] !== '')))
            return false;
    }
    return true;
}

export default isEmpty;


