import React, { FunctionComponent } from "react";
import { Button } from "antd";

import "./error.scss";

import { ReloadOutlined } from "@ant-design/icons";

interface ErrorProps {
  error?: string;
  resetFilters?: (params: any) => any;
  reload?: (params: any) => any;
  loading?:boolean;
}

export const Error: FunctionComponent<ErrorProps> = ({
  error,
  resetFilters,
  reload,
    loading
}) => {
  return (
    <div className="error-wrap">
      <h1>We've encountered some issues</h1>
      <h3>{error}</h3>
      <div className="actions">
        {resetFilters && (
          <Button
            type="primary"
            className="reset-filters"
            onClick={resetFilters}
            disabled={loading}
            loading={loading}
          >
            Reset Filters
          </Button>
        )}
        {reload && (
          <Button className="reload" icon={<ReloadOutlined spin={loading}/>} onClick={reload} loading={loading} disabled={loading}>
            Reload
          </Button>
        )}
      </div>
    </div>
  );
};

Error.defaultProps = {
  error: "Unknown error",
};
