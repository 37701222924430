import React, {useState} from "react";
import {Button, Modal, Tooltip} from "antd";
import {failedNotification, successfulNotification} from "../../utils/notifications";
import axios from "axios";
import {BASE_URL} from "../../utils/variables";
import {useMercuryContext} from "../../user-context";

const RecoverProject = ( { selectedRows, reload, page }: any) => {

  const context = useMercuryContext();
  const { accessToken } = context;
  const [ visible, setVisible ] = useState( false );
  const [confirmLoading, setConfirmLoading] = useState(false);

  async function onSubmit() {
    try {

      // Submit the form
      let headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      let recoveredProjectPromises = [];

      recoveredProjectPromises = selectedRows.map((project: any) => {
        return axios.delete(`${BASE_URL}/project/${project._id}/recover`,
            {
              headers: headers,
            }
          )
          .then((response) => {
            successfulNotification(
              `Project Recovered`,
              `Project with ID:${project.shortId} has been recovered.`
            );
          })
          .catch((error) => {
            if (error.response) {
              failedNotification(
                error.response.data.error,
                error.response.data.detail
              );
            } else {
              failedNotification("Failed to recover the Project");
            }
          });
      });

      await Promise.allSettled(recoveredProjectPromises);

    } catch (error) {
      failedNotification("Failed to Recover the Projects");
    }
  }

  let oneDeletedProject = selectedRows.find( ({ deleted }: any) => deleted === true );
  if ( (selectedRows.length !== 0)  && oneDeletedProject ) {
   return (
    <>
      <Button
        onClick={() => setVisible( !visible )}
        className="action-btn edit-btn"
        icon=""
        type="primary"
      >
        Recover Project(s)
      </Button>
      <Modal
        open={visible}
        title="Project Recovery"
        okText="OK"
        cancelText="Cancel"
        onCancel={ () => {
          setVisible( false );
          setConfirmLoading(false);
        }}
        onOk={async () => {
          try {

            setConfirmLoading(true);
            await onSubmit();
            setConfirmLoading(false);
            reload.call(page);
            setVisible(false);
          } catch (error) {
            console.error("Validate Failed:", error);
          }
        }}
        confirmLoading={confirmLoading}
      >
        <p>Would you like to recover the selected project(s)?</p>
      </Modal>
    </>
  );

}

  return (
    <Tooltip
      title="Select at least 1 row"
      placement="bottom"
      color={"var(--tertiary-color)"}
    >
      <Button
        disabled
        style={{ borderColor: "var(--tertiary-color)" }}
      >
        Recover Project(s)
      </Button>
    </Tooltip>
  )

};

export default RecoverProject;
