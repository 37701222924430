import { FiniteListWithAsyncLoaderAbstract, ListProps } from "../Components";
import { useMercuryContext } from "../user-context";

export const fetchProductArea = async (context: any) => {
  const { data } = await context.gpClient.get(
    "/google-gp-v1/project/listFields/productArea"
  );
  return data.map((item: any) => ({
    title: item.label,
    value: item.value,
  }));
};

class ProductAreaFilter extends FiniteListWithAsyncLoaderAbstract {
  fetch() {
    return fetchProductArea(this.props.context);
  }
}

export default (props: ListProps) => {
  const context = useMercuryContext();
  return <ProductAreaFilter context={context} {...props} />;
};
