import EventEmitter from "./EventEmitter";

export default class ConcurrentTaskQueue extends EventEmitter {

  tasks = [];

  complete = [];

  errors = [];

  running = [];

  concurrency = 1;

  constructor( concurrency ) {
    super();
    this.concurrency = concurrency || 1;
  }

  async add( task ) {
    if ( Array.isArray( task ) ) {
      this.tasks = this.tasks.concat(task);
    }
    if ( !this.started ) {
      this.run();
    }
  }

  started = false;

  runNext = () => {
    return ((this.running.length < this.concurrency ) && this.tasks.length);
  }

  async run() {
    this.started = true;
    while (this.runNext()) {
      const promise = this.tasks.shift()();
      promise.then((data) => {
        this.complete.push(this.running.shift());
        this.emit( "task_complete", data );
        if ( this.tasks.length === 0 && this.running.length === 0 ) {
          this.emit( "complete")
        }
        this.run();
      });
      this.running.push(promise);
    }
  }
}
