import React from "react";
import { Typography, Tooltip } from "antd";

const DetailsPageHeader = ({ title, shortId, projectId }) => {
  const { Title } = Typography;
  return (
    <header
      style={{
        padding: "30px 0 5px 0px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Title level={3} style={{ margin: "0", color: "var(--primary-color)", fontSize: "21px" }}>
        {projectId !== null ? (
          <React.Fragment>
            <Tooltip title="Project ID" placement="bottom" color={"var(--tertiary-color)"}>
              {projectId}{" "}
            </Tooltip>
          </React.Fragment>
        ) : (
          <Tooltip title="Short ID" placement="bottom" color={"var(--tertiary-color)"}>
            {shortId}
          </Tooltip>
        )}
      </Title>
      <Title level={4} style={{ margin: "0 10px", fontSize: "18px" }}>
        -
      </Title>
      <Title
        level={4}
        style={{
          margin: "0",
          textTransform: "capitalize",
          fontSize: "18px"
        }}
      >
        {title}
      </Title>
    </header>
  );
};

export default DetailsPageHeader;
