import { LoadingOutlined } from "@ant-design/icons";

const ExtensionLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: '50px 0 80px 0'
      }}
    >
      <LoadingOutlined
        style={{ fontSize: 24, color: "var(--primary-color)" }}
        spin
      />
      <span style={{ marginLeft: "20px" }}>
        Loading extension request details...
      </span>
    </div>
  );
};

export default ExtensionLoader;
