import React, {useState, useEffect} from "react";
import {Alert, Form, Modal, Spin, Input} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import ComponentMapping from "./ComponentMapping";

function RenderForm( props ) {
  const { inputs, handleDescription } = props;

  return (
    <Form layout="vertical">
      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: false }]}
        key={props.key}
      >
        <Input.TextArea
          placeholder="Description" 
          onChange={handleDescription}
          style={{ width: "100%" }}
        />
      </Form.Item>
      {inputs.map((input, i) => {
        return input({ ...props, key: i });
      })}
    </Form>
  );
}

export default function FormModal( props ) {
  const [isOpen, setIsOpen] = useState(false);

  const
    {
      newReport,
      setNewReport,
      submit,
      submitting,
      invalid
    } = props,
    {
      component,
    } = newReport,
    components = Array.isArray(component) ? component : component.split(","),
    FormInputs = components.map( ( c ) => {
      if ( c in ComponentMapping ) { 
        return ComponentMapping[c];
      }
      return null
    }).filter( c => !!c );

  useEffect(() => {
    if (newReport !== null) {
      setTimeout(() => {
        setIsOpen(true);
      }, 1800);
    }
  }, [newReport])

  if ( !props.newReport ) {
    return null;
  }

  return isOpen ? <Modal 
        className="reports-modal"
        title={props?.newReport?.name}
        maskClosable={false}
        open={isOpen}
        onCancel={() => setNewReport(null)}
        okButtonProps={
          FormInputs.length > 0
            ? { onClick: !submitting ? submit : null, disabled: ( submitting || invalid ), icon: submitting && <Spin indicator={<LoadingOutlined style={{ fontSize: 14, marginRight:'8px' }} spin />}/> }
            : { style: { "display": "none"}}
        }
        okText={
          FormInputs.length > 0
          ? !submitting ? "Queue" : "Queuing..."
            : false
          }
          >
        {
          FormInputs.length === 0
          ? <Alert message={`Report id: ${newReport.id} does not contain any configured fields.` } style={{marginTop: "20px"}}/>
          : <RenderForm inputs={FormInputs}{...props} />
        }
      </Modal> :
      <Modal 
        className="reports-modal-placeholder"
        title={props?.newReport?.name}
        maskClosable={false}
        closable={false}
        loading={true}
        open={!isOpen}
        okButtonProps={{disabled: true}}
        cancelButtonProps={{disabled: true}}
      >
        <Spin indicator={<LoadingOutlined spin />} />
        <p>Loading...</p>
      </Modal>
}