import React from "react";
import {
    FiniteListWithAsyncLoaderAbstract, ListProps
} from "../Components";
import {useMercuryContext} from "../user-context";

class WorkflowFilter extends FiniteListWithAsyncLoaderAbstract {

    async fetch() {
        const { data } = await this.props.context.gpClient.get("/google-gp-v1/WorkflowType");
        return data.map( ( item:any ) => {
            return {
                title: item.title,
                value: item._id
            }
        })
    }
}

export default function Workflow(props:ListProps) {
    const context = useMercuryContext();
    return <WorkflowFilter context={context} {...props} />
}
