import { useState, FunctionComponent, useMemo, useCallback } from "react";
import { 
  Button, 
  Modal, 
  Steps, 
  Form, 
  Select, 
  InputNumber, 
  Typography, 
  Row, 
  Col, 
  Checkbox,
  Progress
} from "antd";
import { ArrowDownOutlined, LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import "./documentWordCountsWizard.scss";
import Logo from "../Loaders/Logo";
import { PromisePool } from '@supercharge/promise-pool'

const  { Text } = Typography;
const { Step } = Steps;

// Define interfaces used by the component

interface DocumentWordCountsWizardProps {
  shortId: string,
  baseURL: string,
  accessToken: string,
  onWizardClose?: (dataChanged: boolean) => void
}

enum DocumentUpdateState {
  Pending = 1,
  InProgress,
  Success,
  Failure,
}

enum ApplyState {
  Pending = 1,
  InProgress,
  InProgressWithErrors,
  CompleteWithErrors,
  Complete
}

interface DocumentListItem {
  id: string,
  title: string,
  weightedWordCount: number,
  wordCount: number,
  catToolStatus: number,
  updateStatus: DocumentUpdateState,
  selected: boolean,
  leadPLP?: boolean;
}

interface PLPListItem {
  id: string,
  value: string
  title: string
}

interface PLPListItemMultiple {
  id: string,
  value: string
  title?: string,
  label?: string
}

const SELECT_ALL_OPTION = {
  label: "Select All",
  value: "all",
};

function useSelectAllOption(options:any) {
  const optionsWithAllOption = useMemo(
    () => [SELECT_ALL_OPTION, ...options],
    [options]
  );
  const getValueFromEvent = useCallback(
    (value:any, selections:any) => {
      if (!selections?.length) return selections;
      if (value?.length && value.includes("all")) {
        const labelInValue = typeof value[0]?.label === "string";
        return labelInValue ? options : options.map((o:any) => o.value);
      } else {
        return value;
      }
    },
    [options]
  );

  return [getValueFromEvent, optionsWithAllOption];
}

export const DocumentWordCountsWizard: FunctionComponent<DocumentWordCountsWizardProps> = ({
  shortId,
  baseURL,
  accessToken,
  onWizardClose
}) => {
 
  const NUM_WIZARD_STEPS:number = 3; 
  const SUCCESS_COLOR:string = "var(--primary-color)";
  const ERROR_COLOR:string = "var(--red)";

  const [step1Form] = Form.useForm();
  const [step2Form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [project, setProject] = useState<any>({});
  const [plpList, setPLPList] = useState<PLPListItem[]>([]);
  const [remainingPlpList, setRemainingPLPList] = useState<PLPListItemMultiple[]>([]);
  const [documentList, setDocumentList] = useState<DocumentListItem[]>([]);
  const [updateList, setUpdateList] = useState<DocumentListItem[]>([]);
  const [unmatchedUpdateList, setUnmatchedUpdateList] = useState<DocumentListItem[]>([]);
  const [applyState, setApplyState] = useState<ApplyState>(ApplyState.Pending);
  const [progressPercent, setProgressPercent] = useState(0);
  const [progressColor, setProgressColor] = useState(SUCCESS_COLOR);
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(true);
  const [getValueFromEvent, optionsWithAllOption] = useSelectAllOption(remainingPlpList);

  // Data retrieval and preparation methods

  // Retrieves the project details of the project and gets a list of the PLP's in the
  // project
  const retrieveProject = async() => {
    // Retrieve the project data
    const response = await fetch(`${baseURL}/projects/${shortId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to download the project');
    }

    const project = await response.json();
    setProject(project);

    // Get a list of the PLPs
    const plpList = project.plps.map((plp:any):PLPListItem => {
      return {
        id: plp._id,
        value: plp.targetLanguageCode,
        title: plp.targetLanguageCode
      }
    })

    setPLPList(plpList); 
  }

  // Gets a list of the documents for the given PLP
  const prepareDocumentList = (selectedTargetLanguage:string, selectedRemainingTargetLanguage:string[]) => {

    const selectedPLP = project.plps.find((plp:any) => {
      return (plp.targetLanguageCode === selectedTargetLanguage);
    })

    let remainingDocumentList:DocumentListItem[] = [];

    if (selectedRemainingTargetLanguage?.length) {
      const remainingSelectedPLPs = project.plps.filter((plp:any) => selectedRemainingTargetLanguage.includes(plp.targetLanguageCode))

      remainingSelectedPLPs.forEach((plp:any) => {
        const remainingPlpDocuments = plp.documents.map((document:any):DocumentListItem => {
          return {
            id: document._id,
            title: document.title,
            weightedWordCount: document.weightedWordCount,
            wordCount: document.wordCount,
            catToolStatus: document.catToolStatus,
            updateStatus: DocumentUpdateState.Pending,
            selected: (document.catToolStatus === "IN_PREPROCESSING"), 
            leadPLP: false
          }
        })

        remainingDocumentList.push(remainingPlpDocuments)
      })
    }

    const leadDocumentList = selectedPLP.documents.map((document:any):DocumentListItem => {
      return {
        id: document._id,
        title: document.title,
        weightedWordCount: document.weightedWordCount,
        wordCount: document.wordCount,
        catToolStatus: document.catToolStatus,
        updateStatus: DocumentUpdateState.Pending,
        selected: (document.catToolStatus === "IN_PREPROCESSING"), 
        leadPLP: true
      }
    })

    const finalDocumentList = selectedRemainingTargetLanguage?.length ? [...leadDocumentList, ...remainingDocumentList.flat()]: leadDocumentList;
    setDocumentList(finalDocumentList);

    step2Form.setFieldsValue({
      documentList: finalDocumentList
    })
  }

  //Gets a list of the documents to actually update based on whether the user 
  // has selected the document using the checkboxes in the UI
  const prepareUpdateList = (documentList:DocumentListItem[]) => {
    let updateList:DocumentListItem[] = [];
      
    const remainingListValue = step1Form.getFieldValue('selectedRemainingTargetLanguage');
  
    // look only at the lead plp if there was no remaining plps selected
    if (!remainingListValue) {
      updateList = documentList.filter((document) => document.selected);
    } else {
      const selectedLeadDocs = documentList.filter((document) => document.leadPLP && document.selected);

      const selectedRemainingDocs:DocumentListItem[] = [];

      // If the title will match against the lead plp, the value(WWC, Word Count) that was added to the lead plp
      // should be transferred to the matching document of the remaining plp
      documentList.forEach((document) => {
        const docTitleWithoutLanguage = document.title.substring(document.title.indexOf('_') + 1);

        selectedLeadDocs.forEach((ld) => {

          const ldTitleWithoutLanguage = ld.title.substring(ld.title.indexOf('_') + 1);

          if (!document.leadPLP && docTitleWithoutLanguage === ldTitleWithoutLanguage) {
            document.weightedWordCount = ld.weightedWordCount;
            document.wordCount = ld.wordCount;
            selectedRemainingDocs.push(document);
          }
        })
      });

      // determine unmatched items
      const allLeadTitlesWithoutLanguages = documentList.filter((doc) => doc.leadPLP).map((doc) => doc.title.substring(doc.title.indexOf('_') + 1))

      const unmatchedDocs:DocumentListItem[] = documentList.filter((doc) =>{
        const docTitleWithoutLanguage = doc.title.substring(doc.title.indexOf('_') + 1);
        if ( !doc.leadPLP && !allLeadTitlesWithoutLanguages.includes(docTitleWithoutLanguage)) {
          return doc;
        }

      })

      setUnmatchedUpdateList(unmatchedDocs)
      updateList = [...selectedLeadDocs, ...selectedRemainingDocs];
    }
    setUpdateList(updateList); 
  }

  // Sends the update to the server
  const updateDocumentListItem = async (documentListItemToUpdate:DocumentListItem) => {
    
    // Update the document data
    const response = await fetch(`${baseURL}/documents/${documentListItemToUpdate.id}/updatewordcounts`, {
      method: "post",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        id: documentListItemToUpdate.id,
        wordCount: documentListItemToUpdate.wordCount,
        weightedWordCount: documentListItemToUpdate.weightedWordCount,
        preProcessingHours: 0
      })
    });

    // Check the response
    if (!response.ok) {
      throw new Error(`Failed to update document ${documentListItemToUpdate.title} (${documentListItemToUpdate.id})`);
    }

    const updateListItems:DocumentListItem[] = updateList.map((documentListItem:DocumentListItem) => {
      if (documentListItemToUpdate.id === documentListItem.id) {
        documentListItem.updateStatus = DocumentUpdateState.Success;
      }

      return { ...documentListItem };
    })

    setUpdateList(updateListItems); 

  }

  // Starts sending the word counts for the selected documents to the server, if 
  // the process has been run once already it will only attempt to send those that 
  // have a failed status
  const applyChanges = async () => {

    // Reset the progress bar
    setProgressPercent(0);
    setProgressColor(SUCCESS_COLOR);

    let applyList = updateList.filter((documentListItem:DocumentListItem) => {
      if (applyState === ApplyState.CompleteWithErrors) {
        // Filter to only get the failed documents
        if (documentListItem.updateStatus !== DocumentUpdateState.Failure) {
          return false;
        }
      } 

      return true;
    })

    let currentState:ApplyState = ApplyState.InProgress;
    setApplyState(currentState); 

    await PromisePool
    .for(applyList)
    .withConcurrency(2) // Controls how many requests are sent to the server concurrently
    .handleError((error, item:DocumentListItem) => {
      const updateListItems:DocumentListItem[] = updateList.map((documentListItem:DocumentListItem) => {
        if (item.id === documentListItem.id) {
          documentListItem.updateStatus = DocumentUpdateState.Failure;
        }

        return { ...documentListItem };
      })

      setUpdateList(updateListItems); 

      currentState = ApplyState.InProgressWithErrors;
      setApplyState(currentState); 

      setProgressColor(ERROR_COLOR);
    })
    .onTaskStarted((item:DocumentListItem, pool) => {
      setProgressPercent(pool.processedPercentage());

      const updateListItems:DocumentListItem[] = updateList.map((documentListItem:DocumentListItem) => {
        if (item.id === documentListItem.id) {
          documentListItem.updateStatus = DocumentUpdateState.InProgress;
        }

        return { ...documentListItem };
      })

      setUpdateList(updateListItems); 

    })
    .onTaskFinished((item:DocumentListItem, pool) => {

      setProgressPercent(pool.processedPercentage());
      

    })
    .process(updateDocumentListItem);

    console.log(`Apply State: ${currentState}, In Progress with errors is: ${ApplyState.InProgressWithErrors}`);
    {/* @ts-ignore */ }
    if (currentState === ApplyState.InProgressWithErrors) {
      console.log('Setting apply state to complete with errors')
      setApplyState(ApplyState.CompleteWithErrors); 
    } else {
      console.log('Setting complete state to complete');
      setApplyState(ApplyState.Complete); 
    }

  }

  // Stores the information the user has completed in the current step and then 
  // moves the wizard to the next step
  const moveNext = (values:any) => {
    switch (current) {
      case 0:   
        prepareDocumentList(values.selectedTargetLanguage, values.selectedRemainingTargetLanguage);
        break;
      case 1:   
        setDocumentList(values.documentList);
        prepareUpdateList(values.documentList);
        break;

    }

    setCurrent(current + 1); 
  }

  // UI functions

  // Handles the user clicking the next button, submits the relevant form for the step the user 
  // is on, if submission is successful the moveNext function will be triggered by the form 
  // validation
  const next = () => {
    
    switch (current) {
      case 0:
        step1Form.submit();
        break;
      case 1:
        step2Form.submit();
        break;

    }
    
  };

  // Moves the wizard back one step
  const prev = () => {
    setCurrent(current - 1); 
  };

  // Initialises the wizard, all values should be set to there starting
  // state in this function
  const initialiseWizard = () => {
    setError(false);

    // Reset the wizard state and progress bar
    setCurrent(0);
    setProject(null);
    setPLPList([]);
    setDocumentList([]);
    setUpdateList([]);
    setUnmatchedUpdateList([])
    setApplyState(ApplyState.Pending);
    setProgressPercent(0);
    setProgressColor(SUCCESS_COLOR);
    step1Form.resetFields();
    step2Form.resetFields();

  }

  // Displays the modal and retrieves the details of the project the wizard is
  // associated with
  const startWizard = async () => {
    try {

      // Set the modal to loading and display
      setLoading(true);
      setOpen(true);

      // Initialise wizard values
      initialiseWizard();

      // Retrieve the project data
      await retrieveProject();

    } catch (error) {
      setError(true);
    } finally {
      // Remove the loading display
      setLoading(false);
    }

    
  };

  // Closes the wizard modal and resets the values of the wizard so that it is 
  // ready for the next open
  const handleCancel = () => {

    // Close the modal
    setOpen(false);

    // Call onClose handler
    if (onWizardClose) {
      onWizardClose((applyState === ApplyState.Complete || applyState === ApplyState.CompleteWithErrors));
    }

    // Set up the wizard for the next open
    initialiseWizard();
  };

  const handleSelectLeadPlp = ( value:string ) => {
    const remainingListValue = step1Form.getFieldValue('selectedRemainingTargetLanguage');

    if (remainingListValue?.includes(value)) {
      step1Form.setFieldsValue({'selectedRemainingTargetLanguage': remainingListValue.filter((item:any) => item !== value )})
    }

    const remaining = plpList.filter((plp:PLPListItemMultiple) => plp.value !== value );

    setRemainingPLPList(remaining);
  }

  const determineLastVisibleIndex = () => {
    const remainingListValue = step1Form.getFieldValue('selectedRemainingTargetLanguage');

    let index = documentList.length - 1;

    if (remainingListValue) {
      index = (documentList.length / (remainingListValue.length + 1)) - 1;
    }

    return index;
  }

  return (
      <div>
        <Button type="primary" onClick={startWizard}>
          Set Document Word Counts
        </Button>
        <Modal 
          open={open} 
          destroyOnClose={true}
          onCancel={handleCancel}
          footer={[
            <div key='footer'>
              <Button
                onClick={handleCancel}
                disabled={
                  (applyState === ApplyState.InProgress || 
                    applyState === ApplyState.InProgressWithErrors ||
                    applyState === ApplyState.Complete
                    )
                }
              >
                  Cancel
              </Button>

              {current > 0 && (
                <Button
                  onClick={prev}
                  disabled={
                    (applyState === ApplyState.InProgress || 
                      applyState === ApplyState.InProgressWithErrors ||
                      applyState === ApplyState.Complete ||
                      error)
                  }
                >
                  Previous
                </Button>
              )}

              {current < NUM_WIZARD_STEPS - 1 && (
                <Button 
                  type="primary"
                  onClick={next}
                  disabled={
                    (applyState === ApplyState.InProgress || 
                      applyState === ApplyState.InProgressWithErrors ||
                      error)
                  }
                >
                  Next
                </Button>
              )}

              {current === NUM_WIZARD_STEPS - 1 && 
              (
                applyState === ApplyState.Pending || 
                applyState === ApplyState.InProgress || 
                applyState === ApplyState.CompleteWithErrors || 
                applyState === ApplyState.InProgressWithErrors) && (
                <Button 
                  type="primary"
                  onClick={applyChanges}
                  disabled={
                    (applyState === ApplyState.InProgress || 
                      applyState === ApplyState.InProgressWithErrors ||
                      error)
                  }
                >
                  { applyState === ApplyState.Pending && (
                    <>
                      Apply Changes
                    </>
                  )}

                  { applyState === ApplyState.CompleteWithErrors && (
                    <>
                      Retry Failed
                    </>
                  )}

                  { (applyState === ApplyState.InProgress || applyState === ApplyState.InProgressWithErrors) && (
                    <span>
                     <LoadingOutlined /> Applying
                    </span>
                  )}
                </Button>
              )}

              {current === NUM_WIZARD_STEPS - 1 && 
              (
                applyState === ApplyState.Complete || 
                applyState === ApplyState.CompleteWithErrors) && (
                <Button 
                  type="primary"
                  onClick={handleCancel}
                >
                  Close
                </Button>
              )}
            </div>
          ]}
          width={1100}
          closable={false}
          keyboard={false}
          maskClosable={false}
          title={"Set Document Word Counts"}
          className={"document-word-counts-wizard-wrap"}
        >
          {/* @ts-ignore */ }
          <Steps current={current}>
            <Step title={'Step 1'} description={'Select PLP'}/>
            <Step title={'Step 2'} description={'Set Word Counts'}/>
            <Step title={'Step 3'} description={'Apply Changes'}/>
          </Steps>
              
          <div className="step-content">
              { loading && (
                <div className={"loading"}>
                  <Logo animate={true}/>
                  <div>Please Wait...</div>
                </div>
              )}

              { error && (
                <div className={"error"}>
                  <WarningOutlined className="error-icon-large"/>
                  <Text type="danger">We were unable to retrieve the project information at this time. Please close the wizard and try again or contact support.</Text>
                </div>
              )}

              { (!loading && !error) && (
                <>
                  {current === 0 && (
                    <>
                      <p>Select the PLP for which you want to set document word counts:</p>
                      <Form
                        name="Step1"
                        autoComplete="off"
                        form={step1Form}
                        onFinish={moveNext}
                      >
                        <Form.Item
                          label="Lead PLP"
                          name="selectedTargetLanguage"
                          rules={[
                            {
                              required: true,
                              message: 'Please select a PLP',
                            },
                          ]}
                        >
                          <Select
                            options={plpList}
                            onChange={handleSelectLeadPlp}
                            style={{
                              width: '100%',
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Remaining PLPs"
                          name="selectedRemainingTargetLanguage"
                          // @ts-ignore
                          getValueFromEvent={getValueFromEvent}
                          rules={[
                            {
                              required: false,
                              message: 'Please select another PLP',
                            },
                          ]}
                        >
                          <Select
                            allowClear
                            mode="multiple"
                            placeholder='Please select the lead PLP first'
                            style={{
                              width: '100%',
                            }}
                            //@ts-ignore
                            options={ remainingPlpList.length ? optionsWithAllOption : [] }
                          />
                        </Form.Item>
                      </Form>
                    </>
                  )}

                  {current === 1 && (
                    <>
                    <p>Enter the word counts for the Lead PLP documents:</p>
                    <Form
                      name="Step2"
                      autoComplete="off"
                      form={step2Form}
                      onFinish={moveNext}
                      initialValues={{ documentList: documentList}}
                      layout="vertical"
                      className="list-form"
                    >
                      <Form.List
                        name="documentList"
                      >
                        {fields => (
                          <div key='documentListWrapper'>
                            <Row
                              key="headerRow"
                              className="list-form-header-row"
                            >
                              <Col span={1}>
                                <Checkbox
                                  defaultChecked={selectAllCheckbox}
                                  onChange={(e) => {
                                    const fields = step2Form.getFieldsValue()
                                    const { documentList } = fields
                                    step2Form.setFieldsValue(documentList.map((documentListItem:DocumentListItem) => {
                                      documentListItem.selected = e.target.checked;
                                      setSelectAllCheckbox(e.target.checked)
                                      return { documentListItem };
                                    }))
                                  }}
                                />
                              </Col>
                              <Col span={13}>
                                Title
                              </Col>
                              <Col span={4}>
                                Status
                              </Col>
                              <Col span={3}>
                                Word Count
                              </Col>
                              <Col span={3}>
                                WWC
                              </Col>
                            </Row>
                            {fields.map((field, index) => {
                              const fieldValue = step2Form.getFieldValue("documentList")[field.fieldKey!];

                              return fieldValue.leadPLP && <Row 
                                key={field.key}
                                className="list-form-row"
                              >
                                <Col span={1}>
                                <Form.Item
                                    name={[field.name, "selected"]}
                                    fieldKey={[field.fieldKey!, "selected"]}
                                    className="list-form-item"
                                    valuePropName="checked"
                                  >
                                    <Checkbox />
                                    
                                  </Form.Item>
                                </Col>
                                <Col span={13}>
                                  <div className="list-form-label">
                                    { fieldValue.title }
                                  </div>
                                </Col>
                                <Col span={4}>
                                  <div className="list-form-label">
                                    { fieldValue.catToolStatus }
                                  </div>
                                </Col>
                                <Col span={3}>
                                  <Row>
                                      <Col span={20}>
                                        <Form.Item
                                          name={[field.name, "wordCount"]}
                                          fieldKey={[field.fieldKey!, "wordCount"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Required",
                                            },
                                          ]}
                                          className="list-form-item"
                                        >
                                          <InputNumber 
                                            onChange={(value) => {
                                              const fields = step2Form.getFieldsValue()
                                              const { documentList } = fields
                                              documentList[field.fieldKey!].weightedWordCount = value;
                                              step2Form.setFieldsValue({ documentList })
                                            }} 
                                            min={0}
                                            style={{ width: "100%" }}
                                          />
                                          
                                        </Form.Item>
                                      </Col>
                                      <Col span={4}>
                                        {(index !== determineLastVisibleIndex()) && <Button type='text' className="icon-button" onClick={() => {
                                          const fields = step2Form.getFieldsValue()
                                          const { documentList } = fields
                                          for (let documentCount = field.fieldKey! + 1; documentCount < documentList.length; documentCount++) {
                                            documentList[documentCount].wordCount = documentList[field.fieldKey!].wordCount;
                                          }
                                          step2Form.setFieldsValue({ documentList })
                                        }}>
                                          <ArrowDownOutlined />
                                        </Button>}
                                      </Col>
                                  </Row>
                                </Col>
                                <Col span={3}>
                                  <Row>
                                      <Col span={20}>
                                        <Form.Item
                                          name={[field.name, "weightedWordCount"]}
                                          fieldKey={[field.fieldKey!, "weightedWordCount"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Required",
                                            },
                                          ]}
                                          className="list-form-item"
                                        >
                                          <InputNumber min={0} style={{ width: "100%" }} />
                                        </Form.Item>
                                      </Col>
                                      <Col span={4}>
                                        {(index !== determineLastVisibleIndex()) && <Button type='text' className="icon-button" onClick={() => {
                                          const fields = step2Form.getFieldsValue()
                                          const { documentList } = fields
                                          for (let documentCount = field.fieldKey! + 1; documentCount < documentList.length; documentCount++) {
                                            documentList[documentCount].weightedWordCount = documentList[field.fieldKey!].weightedWordCount;
                                          }
                                          step2Form.setFieldsValue({ documentList })
                                        }}>
                                          <ArrowDownOutlined />
                                        </Button>
                                        }
                                      </Col>
                                  </Row>
                                </Col>
                              </Row>
                            })}
                          </div>
                        )}
                      </Form.List>
                    </Form>
                    
                  </>
                  )}

                  {current === 2 && (
                      <>
                        <div className="last-step-container last-step-container__matched">
                          <p>Lead PLP documents and any other matched documents from additional selected PLP's</p>
                          <Progress
                            percent={ progressPercent }
                            strokeColor={ progressColor }
                            showInfo={false}
                            style={{marginBottom: '5px'}}
                          />
                          <div className="list-form">
                            <Row
                              key="headerRow"
                              className="list-form-header-row"
                            >
                              <Col span={15}>
                                Title
                              </Col>
                              <Col span={3}>
                                Word Count
                              </Col>
                              <Col span={3}>
                                WWC
                              </Col>
                              <Col span={3}>
                                Update Status
                              </Col>
                            </Row>
                            { updateList.map((documentListItem:DocumentListItem) => (
                              <Row
                                key={documentListItem.id}
                                className="list-form-row"
                              >
                                <Col span={15}>
                                  { documentListItem.title }
                                </Col>
                                <Col span={3}>
                                  { documentListItem.wordCount }
                                </Col>
                                <Col span={3}>
                                  { documentListItem.weightedWordCount }
                                </Col>
                                <Col span={3}>
                                  {documentListItem.updateStatus === DocumentUpdateState.Pending && (
                                    <Text>Pending</Text>
                                  )}

                                  {documentListItem.updateStatus === DocumentUpdateState.InProgress && (
                                    <LoadingOutlined />
                                  )}

                                  {documentListItem.updateStatus === DocumentUpdateState.Failure && (
                                    <Text type="danger">Failed</Text>
                                  )}

                                  {documentListItem.updateStatus === DocumentUpdateState.Success && (
                                    <Text type="success">Success</Text>
                                  )}
                                </Col>
                              </Row>
                            ))}
                          </div>
                        </div>
                        {step1Form.getFieldValue('selectedRemainingTargetLanguage')?.length ? 
                        <div className="last-step-container last-step-container__unmatched">
                          <p>Documents from the other PLP's not matching a document in the lead PLP</p>
                          <div className="list-form">
                          { unmatchedUpdateList.length ? <Row
                              key="headerRow"
                              className="list-form-header-row"
                            >
                              <Col span={15}>
                                Title
                              </Col>
                              <Col span={3}>
                                Word Count
                              </Col>
                              <Col span={3}>
                                WWC
                              </Col>
                            </Row>
                            :
                            <p>No data</p>
                          }
                          { unmatchedUpdateList.map((documentListItem:DocumentListItem) => (
                            <Row
                              key={documentListItem.id}
                              className="list-form-row"
                            >
                              <Col span={15}>
                                { documentListItem.title }
                              </Col>
                              <Col span={3}>
                                { documentListItem.wordCount }
                              </Col>
                              <Col span={3}>
                                { documentListItem.weightedWordCount }
                              </Col>
                            </Row>
                            ))
                          }
                        </div>
                      </div>
                      : ''
                      }
                    </>
                  )}
                </>
              )}
              
          </div>
        </Modal>
      </div>
  );
};
