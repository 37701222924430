import React from "react";
import {Form, Select} from "antd";

const { Option } = Select;

export default function Status( props ) {

  const {
    status,
    selectedStatus,
    handleStatusChange
  } = props;

  return (
    <Form.Item
      label="Status"
      name="status"
      rules={[{ required: false}]}
      key={props.key}
    >
      <Select
        style={{ width: '100%' }}
        placeholder="Please select"
        value={selectedStatus}
        onChange={handleStatusChange}
        onClear={handleStatusChange}
        allowClear={true}
      >
        {
          ( status || [] ).map( ( p ) => {
            return <Option key={p.key} value={p.title}>{p.title}</Option>
          })
        }
      </Select>
    </Form.Item>
  )
}