import React from "react";

import { Button, Drawer, Form, Input, Switch } from "antd";

const EditProductArea = ({ handlers, values }) => {

  const { closeEditDrawer, onFinishEditing } = handlers;
  const { editingForm, visibleEditDrawer, editing, editFormRef } = values;

  const editingRow = editing && editing.editingRow;

  return (
    <>
      <Drawer
        title="Edit Product Area"
        placement="right"
        onClose={closeEditDrawer}
        open={visibleEditDrawer}
        width={500}
        closable={true}
        className="manage-pages-drawer"
        forceRender
      >
        {editingRow && (
          <Form
            form={editingForm}
            layout="horizontal"
            onFinish={onFinishEditing}
            name="editProductForm"
            ref={editFormRef}
          >
            <Form.Item
              name="titleValue"
              label="Product Area"
              rules={[{ required: true, message: "Please type product area title" }]}
            >
              <Input />
            </Form.Item>
        
            <Form.Item label="Active" name="activeValue" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update Product Area
              </Button>
            </Form.Item>
          </Form>
        )}
      </Drawer>
    </>
  );
};

export default EditProductArea;
