import { Table } from "antd";

const DocumentPrefillStatistics = ({ prefillStatistics }: any) => {
  const statistics = prefillStatistics?.entries?.map((el: any) => {
    return {
      key: el?._id,
      ...el,
    };
  });

  return (
    <div style={{ margin: "0 10px", border: "1px solid #d9d9d9" }}>
      <Table
        dataSource={statistics}
        columns={[
          {
            title: "Expansion Type",
            dataIndex: "expansionType",
            align: "left",
            render: (text) => text,
          },
          {
            title: "Prefill Type",
            dataIndex: "prefillType",
            align: "left",
            render: (text) => text,
          },
          {
            title: "Prefill Type Rate",
            dataIndex: "prefillTypeRate",
            align: "left",
            render: (text) => text,
          },
          {
            title: "Words",
            dataIndex: "words",
            align: "left",
            render: (text) => text,
          },
        ]}
        pagination={false}
      />
    </div>
  );
};

export default DocumentPrefillStatistics;
