import React, { useState } from "react";

import {
  Button,
  Drawer,
  Form,
  Input,
  Checkbox,
  Select,
  Switch,
  Table,
  Tooltip,
} from "antd";
import {
  ReloadOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import UniversalButton from "../../../ui/UniversalButton";

import { groupAndSortByKey } from "../../../utils/helpers";

const AddRole = ({ handlers, values }) => {
  const {
    showNewRoleDrawer,
    closeNewRoleDrawer,
    onFinishNewRole,
    handleRefresh,
  } = handlers;
  const {
      newRoleForm,
      visibleNewRoleDrawer,
      permissionsList,
      applicationsList,
    } = values,
    [displayedPermissions, setDisplayedPermissions] = useState([]), // rendered permissions
    [updatedPermissions, setUpdatedPermissions] = useState([]), // pemissions that will pe passed to the api request
    { Option } = Select,
    { TextArea } = Input;

  function onChangePermission(e, id) {
    const newCheckboxName = e.target.name,
      newCheckboxValue = e.target.checked;

    // match checkbox and update the value
    let newCheckbox =
      updatedPermissions &&
      updatedPermissions.find((permission) => permission.permissionId === id);
    newCheckbox[newCheckboxName] = newCheckboxValue;

    setUpdatedPermissions([...updatedPermissions]);
  }

  const permissionColumns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "",
      dataIndex: "description",
      key: "description",
      width: 10,
      render: (text, record) => (
        <Tooltip
          title={record.description}
          color={"var(--tertiary-color)"}
          placement={"left"}
        >
          <InfoCircleOutlined style={{ color: "var(--tertiary-color)" }} />
        </Tooltip>
      ),
    },
    {
      title: "View",
      dataIndex: "read",
      key: "read",
      render: (text, record) =>
        record.supportsRead ? (
          <Checkbox
            name="read"
            defaultChecked={false}
            onChange={(e) => onChangePermission(e, record.id)}
          />
        ) : (
          <Checkbox name="read" disabled />
        ),
    },
    {
      title: "Create",
      dataIndex: "create",
      key: "create",
      render: (text, record) =>
        record.supportsCreate ? (
          <Checkbox
            name="create"
            defaultChecked={false}
            onChange={(e) => onChangePermission(e, record.id)}
          />
        ) : (
          <Checkbox name="create" disabled />
        ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      render: (text, record) =>
        record.supportsEdit ? (
          <Checkbox
            name="edit"
            defaultChecked={false}
            onChange={(e) => onChangePermission(e, record.id)}
          />
        ) : (
          <Checkbox name="edit" disabled />
        ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (text, record) =>
        record.supportsDelete ? (
          <Checkbox
            name="delete"
            defaultChecked={false}
            onChange={(e) => onChangePermission(e, record.id)}
          />
        ) : (
          <Checkbox name="delete" disabled />
        ),
    },
  ];

  const groupedPermissions =
    permissionsList && groupAndSortByKey(displayedPermissions, "category");

  const renderedPermissions = [];

  // render a table for each category
  for (const [category, list] of Object.entries(groupedPermissions)) {
    const permissionGroup = (
      <Table
        columns={permissionColumns}
        dataSource={list?.sort((a, b) => a.name.localeCompare(b.name))}
        title={() => category}
        key={category}
        pagination={false}
        className="role-table"
      />
    );

    renderedPermissions.push(permissionGroup);
  }

  const handleChangeApplication = (value) => {
    const filteredPermissionsList = permissionsList.filter(
      (permission) => permission.customerApplication === value
    );

    setDisplayedPermissions([...filteredPermissionsList]);

    // generate a new list of permisssions that will be sent on finish editing
    const newUpdatedPermissions = filteredPermissionsList.map((p) => {
      // default to unchecked
      const newPermission = {
        permissionId: p.id,
        create: false,
        delete: false,
        edit: false,
        read: false,
      };

      return newPermission;
    });

    setUpdatedPermissions([...newUpdatedPermissions]);
  };

  const closeDrawer = () => {
    closeNewRoleDrawer();
    setDisplayedPermissions([]);
    setUpdatedPermissions([]);
  };

  return (
    <>
      <UniversalButton
        clickMethod={showNewRoleDrawer}
        text="New Role"
        type="primary"
        icon={
          !visibleNewRoleDrawer ? (
            <PlusCircleOutlined style={{ fontSize: "15px" }} />
          ) : (
            <MinusCircleOutlined style={{ fontSize: "15px" }} />
          )
        }
      />
      <UniversalButton
        clickMethod={handleRefresh}
        text="Refresh"
        icon={<ReloadOutlined />}
      />
      <Drawer
        title="Add New Role"
        placement="right"
        onClose={closeDrawer}
        open={visibleNewRoleDrawer}
        width={500}
        closable={true}
        className="manage-pages-drawer"
        forceRender
      >
        <Form
          form={newRoleForm}
          layout="vertical"
          onFinish={(values) => onFinishNewRole(values, updatedPermissions)}
          name="newRoleForm"
          className="new-role-form"
        >
          <Form.Item
            name="nameValue"
            label="Name"
            rules={[
              { required: true, message: "Please type the new role name" },
            ]}
          >
            <Input placeholder="Ex: System Admin" />
          </Form.Item>
          <Form.Item
            name="descriptionValue"
            label="Description"
            rules={[{ required: true, message: "Please type the description" }]}
          >
            <TextArea
              rows={4}
              placeholder="Ex: Project Manager role for ABC Company"
            />
          </Form.Item>
          <Form.Item
            label="Active"
            name="activeValue"
            valuePropName="checked"
            initialValue={false}
          >
            <Switch defaultChecked={false} />
          </Form.Item>

          <Form.Item
            label="Customer Application"
            name="customerApplicationValue"
            rules={[
              { required: true, message: "Please select customer application" },
            ]}
          >
            <Select
              placeholder="Select application"
              onChange={(value) => handleChangeApplication(value)}
            >
              {applicationsList
                ?.filter((i) => i.active)
                .map((app) => {
                  return (
                    <Option value={app.id} key={app.id}>
                      {app.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item label="Permissions">
            {newRoleForm.isFieldTouched("customerApplicationValue") ? (
              displayedPermissions.length ? (
                renderedPermissions
              ) : (
                <p>No permissions for this Customer Application.</p>
              )
            ) : (
              <p>Please select Customer Application to see the permissions.</p>
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save Role
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default AddRole;
