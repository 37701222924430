import React from "react";

function Loading() {
  return (
    <div className="loading-wrapper">
      <span>·</span>
      <span>·</span>
      <span>·</span>
    </div>
  );
}

export default Loading;
