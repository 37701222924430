import React from "react";
import { Avatar as AvatarComponent} from "antd";

function stringToColour(str:string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

interface AvatarProps {
    name:string;
    firstName: string;
    lastName: string;
    size?: number;
}

export default function Avatar( props:AvatarProps ) {
    return (
        <AvatarComponent size={props.size || 80} style={{backgroundColor: stringToColour(props.name)}}>
            {props.firstName.substring(0,1)}
            {props.lastName.substring(0,1)}
        </AvatarComponent>
    )
}
