import React from "react";
import { Form, Input } from "antd";

export default function BatchId( props ) {

  const {
   handleChangeBatchId  } = props;

  return (
    <Form.Item
      name="batch_id"
      rules={[{ required: false}]}
      key={props.key}
    >
     <Input placeholder="Batch Id" onChange={handleChangeBatchId}/>
    </Form.Item>
  )
}
