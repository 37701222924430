import React, { useState } from "react";
import TimezoneConverter from "../../../timezone-converter";
import Total from "../../../ui/Total";

import { Table, Typography, Skeleton, Input, Spin, Row } from "antd";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { sorter } from "../../../utils/helpers";

const RolesTable = ({ values, handlers }) => {
  const { roles, sortedInfo, editing, total, isLoading, applicationsList } =
      values,
    { handleChangeTable, handleClickEdit } = handlers,
    { Link, Title } = Typography,
    { Search } = Input,
    [filterTable, setFilterTable] = useState(null);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => sorter(a.name, b.name),
      sortOrder: sortedInfo.field === "name" && sortedInfo.order,
      render: (text) => text,
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => sorter(a.description, b.description),
      sortOrder: sortedInfo.field === "description" && sortedInfo.order,
      render: (text) => text,
    },
    {
      title: "Customer Application",
      dataIndex: "customerApplication",
      sorter: (a, b) => sorter(a.customerApplication, b.customerApplication),
      sortOrder: sortedInfo.field === "customerApplication" && sortedInfo.order,
      render: (text) => {
        // match id with name
        const found = applicationsList?.find((app) => app.id === text);
        return found ? found.name : "Error: Could not match Id with name."
      },
    },
    {
      title: "Active",
      dataIndex: "isActive",
      sorter: (a, b) => sorter(a.isActive, b.isActive),
      sortOrder: sortedInfo.field === "isActive" && sortedInfo.order,
      render: (text) =>
        text === true ? (
          <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
        ) : (
          <CloseOutlined style={{ color: "var(--red)" }} />
        ),
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      sorter: (a, b) => sorter(a.createdAt, b.createdAt),
      sortOrder: sortedInfo.field === "createdAt" && sortedInfo.order,
      render: (text) => <TimezoneConverter date={text} />,
    },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      sorter: (a, b) => sorter(a.updatedAt, b.updatedAt),
      sortOrder: sortedInfo.field === "updatedAt" && sortedInfo.order,
      render: (text) => <TimezoneConverter date={text} />,
    },
    {
      title: "Edit Role",
      dataIndex: "edit",
      render: (text, record) =>
        editing.isEditing && record.lastName !== editing.editingRow.lastName ? (
          <Skeleton active size="small" title={true} paragraph={false} />
        ) : (
          <Link
            onClick={() => {
              handleClickEdit(record);
            }}
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "nowrap",
            }}
          >
            <EditOutlined
              style={{
                marginRight: "5px",
              }}
            />
            Edit
          </Link>
        ),
    },
  ];

  const handleSearch = (value) => {
    const filterTable = roles.filter((item) =>
      item.name.toString().toLowerCase().includes(value.toLowerCase())
    );
    setFilterTable(filterTable);
  };

  return (
    <>
      <Search
        style={{ width: "300px", margin: "0 0 10px 0" }}
        placeholder="Filter by name"
        onSearch={handleSearch}
        allowClear
        enterButton
      />
      <Row className="title-wrap">
        <Title level={5} style={{ margin: "5px 0 0" }}>
          Roles
          {editing.isEditing && (
            <Spin size="small" style={{ marginLeft: "10px" }} />
          )}
        </Title>
        <Total number={total} text="roles" />
      </Row>
      <Table
        columns={columns}
        dataSource={filterTable == null ? roles :filterTable}
        className="manage-page-table"
        onChange={handleChangeTable}
        loading={isLoading}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: 20,
        }}
      />
    </>
  );
};

export default RolesTable;
