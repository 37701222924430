import {
  FiniteListWithAsyncLoaderAbstract, ListProps
} from "../../Components";
import {useMercuryContext} from "../../user-context";

class JustificationFiniteFilter extends FiniteListWithAsyncLoaderAbstract {

  async fetch() {
    const { data } = await this.props.context.client.get("google-ges-v1/justification/paginate?page=1&limit=500");

    return data.length && data[0].documents.map( ( item:any ) => {
      return {
        title: item.googleType, // todo: make sure backend supports array - https://jira.sdl.com/browse/MER-2298
        value: item.googleType
      }
    })
  }
}

const JustificationFilter = (props:ListProps) => {
  const context = useMercuryContext();

  return <JustificationFiniteFilter context={context} {...props} />

}

export default JustificationFilter;