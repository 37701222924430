import Components from "./Components/Components";
import ProductIds from "./Components/ProductIds";
import ProjectIds from "./Components/ProjectIds";
import InvoiceNames from "./Components/InvoiceNames";
import ReceivedDate from "./Components/ReceivedDate";
import Status from "./Components/Status";
import Urgency from "./Components/Urgency";
import TargetLanguage from "./Components/TargetLanguage";
import DeliveredDate from "./Components/DeliveredDate"
import DueDate from "./Components/DueDate";
import CostCode from "./Components/CostCode";
import ProjectNames from "./Components/ProjectNames";
import BatchId from "./Components/BatchId"

export default {
  "componentTypes": Components,
  "products": ProductIds,
  "projectIds": ProjectIds,
  "receivedDate": ReceivedDate,
  "catToolStatus": Status,
  "turnaroundTimeUrgency": Urgency,
  "targetLanguageCode": TargetLanguage,
  "deliveredDate": DeliveredDate,
  "invoiceNames": InvoiceNames,
  "dueDate": DueDate,
  "costCode": CostCode,
  "projectNames": ProjectNames,
  "batchId": BatchId
}
