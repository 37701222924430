import React, { useState, useEffect, useRef, useCallback } from "react";
import { useMercuryContext } from "../user-context";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Form } from "antd";
import { EXTENSIONS_API } from "../utils/variables";
import { defaultHeaders } from "../api/APIUtils";

import PageTitle from "../ui/PageTitle";
import AddExtensionJustification from "../components/managePages/extension-justifications/AddExtensionJustification";
import EditExtensionJustification from "../components/managePages/extension-justifications/EditExtensionJustification";
import DeleteExtensionJustification from "../components/managePages/extension-justifications/DeleteExtensionJustification";
import ExtensionJustificationTable from "../components/managePages/extension-justifications/ExtensionJustificationTable";
import BackTopButton from "../ui/BackTopButton";
import LoadingStar from "../ui/LoadingStar";

import {
  failedNotification,
  successfulNotification,
  infoNotification,
} from "../utils/notifications";

const initialEditingValue = {
  isEditing: false,
  editingRow: "",
  isDeleting: false,
};

const ManageExtensionJustifications = () => {
  const { accessToken } = useMercuryContext(),
    editFormRef = useRef(null),
    [form] = Form.useForm(),
    [editingForm] = Form.useForm(),
    [isLoading, setIsLoading] = useState(false),
    [visibleDrawer, setVisibleDrawer] = useState(false),
    [visibleEditDrawer, setVisibleEditDrawer] = useState(false),
    [justifications, setJustifications] = useState([]),
    [sortedInfo, setSortedInfo] = useState(""),
    [editing, setEditing] = useState(initialEditingValue),
    [total, setTotal] = useState(0);

  // const justificationsEndpoint = `${EXTENSIONS_API}/v1/ges/justifications/get`; // v1
  const justificationsEndpoint = `${process.env.REACT_APP_API_URL}/google-ges-v1/justification`; // v2

  const getJustificationsEndpoint = `${justificationsEndpoint}/paginate?page=1&limit=500`
  // fetch all justifications
  const fetchExtensionJustifications = useCallback(
    async (token, justificationsEndpoint, useIsLoading = true) => {
      try {
        setIsLoading(useIsLoading);
        await axios
          .get(justificationsEndpoint, {
            headers: defaultHeaders(token),
          })
          .then((result) => {
            // setJustifications(result.data.sort((a, b) => a.googleType.localeCompare(b.googleType))) // v1
            // setTotal(result.data.length); // v1
            setJustifications(result.data[0]?.documents?.sort((a, b) => a.googleType.localeCompare(b.googleType))) // v2
            setTotal(result.data[0]?.total); // v2
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.error('Error fetching justifications: ', error);
      }
      setIsLoading(false);
    },
    []
  );

  // add new extension justification
  const addExtensionJustification = async (token, body) => {
    // const endpoint = `${EXTENSIONS_API}/v1/ges/justifications/create`; // v1
    const endpoint = `${justificationsEndpoint}/`; // v2

    try {
      setEditing({ ...editing, isEditing: true, isDeleting: false });
      await axios({
        method: "post",
        url: endpoint,
        headers: defaultHeaders(token),
        data: body,
      }).then((result) => {
        if (result.data.error) {
          failedNotification(result.data.error, result.data.message);
        } else {
          successfulNotification(
            `${body.googleType} was added.`,
            "The page will be reloaded. Please refresh your page if the update is not visible."
          );
        }
      });
    } catch (error) {
      console.error('Error adding extension justification: ', error);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // update a single extension justification
  const updateExtensionJustification = async (token, newData) => {
    // const endpoint = `${EXTENSIONS_API}/v1/ges/justifications/update`; // v1
    const endpoint = `${justificationsEndpoint}/${newData.id}`; // v1

    delete newData.id;
    try {
      setEditing({ ...editing, isEditing: true, isDeleting: false });
      await axios({
        // method: "put", // v1
        method: "patch", // v2
        url: endpoint,
        headers: defaultHeaders(token),
        data: newData,
      }).then((result) => {
        console.log('Update justification result: ', result)
        successfulNotification(
          `${newData.googleType} was updated.`,
          `The page will be reloaded. Please refresh your page if the update is not visible.`
        );
      });
    } catch (error) {
      failedNotification(error.response.data.error, error.response.data.detail);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // on load
  useEffect(() => {
    accessToken &&
      fetchExtensionJustifications(accessToken, getJustificationsEndpoint);
  }, [accessToken, justificationsEndpoint, fetchExtensionJustifications]);

  const defaultValues = {
    idValue: editing.editingRow.id,
    googleTypeValue: editing.editingRow.googleType,
    kindValue: editing.editingRow.kind,
    limitValue: editing.editingRow.limit,
    frequencyValue: editing.editingRow.frequency,
    descValue: editing.editingRow.description,
  };

  // fix to update initial values of edit form
  useEffect(() => {
    if (editFormRef.current) {
      editingForm.setFieldsValue(defaultValues);
    }
  }, [editFormRef, editingForm, defaultValues]);

  const handleRefresh = async () => {
    const getJustificationsEndpoint = `${justificationsEndpoint}/paginate?page=1&limit=500`
    try {
      await fetchExtensionJustifications(
        accessToken,
        getJustificationsEndpoint,
        true
      );
      infoNotification(
        "Extension Justifications data reloaded",
        "Extension Justifications data has been reloaded"
      );
    } catch (error) {
      failedNotification(
        "Failed to reload",
        "Unable to reload the Extension Justifications data at this time, please try again. If the issue persists please contact support."
      );
    }
  };

  // Add new justification START
  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const closeDrawer = () => {
    setVisibleDrawer(false);
    form.resetFields();
  };

  // on save of add new justification
  const onFinishNewJustification = (values) => {
    // generates the new justification object
    const newValues = {
      googleType: values.googleTypeValue,
      kind: values.kindValue,
      limit: values.limitValue,
      frequency: values.frequencyValue,
      description: values.descValue,
    };

    addExtensionJustification(accessToken, newValues);
    closeDrawer();
  };
  // Add new extension END

  // Update extension START

  function showEditDrawer() {
    setVisibleEditDrawer(true);
  }

  const closeEditDrawer = (event) => {
    setVisibleEditDrawer(false);
    form.resetFields();
    event !== undefined && setEditing(initialEditingValue);
  };

  // on save of edit justification
  const onFinishEditing = (values) => {
    const {
      idValue,
      googleTypeValue,
      kindValue,
      limitValue,
      frequencyValue,
      descValue,
    } = values;

    const newData = {
      id: idValue,
      googleType: googleTypeValue,
      kind: kindValue,
      limit: limitValue,
      frequency: frequencyValue,
      description: descValue,
    };

    updateExtensionJustification(accessToken, newData);

    closeEditDrawer();
  };
  // Update END

  const handleFinishDelete = async (submitted) => {
    const id = editing.editingRow.id;
    // const endpoint = `${EXTENSIONS_API}/v1/ges/justifications/delete/${id}`; // v1
    const endpoint = `${justificationsEndpoint}/${id}`; // v2
    try {
      // Check if form was submitted or cancelled
      if (!submitted) {
        setEditing(initialEditingValue);
        return;
      }
      setEditing({ ...editing, isEditing: true, isDeleting: true });
      await axios({
        method: "delete",
        url: endpoint,
        headers: defaultHeaders(accessToken),
      }).then((result) => {
        successfulNotification(
          `Justification was deleted.`,
          `The page will be reloaded. Please refresh your page if the update is not visible.`
        );
      });
    } catch (error) {
      failedNotification(error.response.data.error, error.response.data.detail);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // handle change table
  const handleChangeTable = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
  };

  // handle click table edit button
  const handleClickEdit = (record) => {
    setEditing({
      ...editing,
      isEditing: true,
      editingRow: record,
      isDeleting: false,
    });
    showEditDrawer();
  };

  // Delete button
  const handleClickDelete = (record) => {
    setEditing({
      ...editing,
      isEditing: true,
      editingRow: record,
      isDeleting: true,
    });
  };

  return (
    <div className="manage-page manage-justifications-page" style={{padding: '0 10px'}}>
      <Helmet>
        <title>Manage Extension Justifications - Mercury © RWS</title>
      </Helmet>
      {isLoading ? (
        <LoadingStar />
      ) : (
        <>
          <PageTitle title="Manage Extension Justifications" />
          <AddExtensionJustification
            handlers={{
              showDrawer,
              closeDrawer,
              onFinishNewJustification,
              handleRefresh,
            }}
            values={{ form, visibleDrawer, justifications }}
          />
          <EditExtensionJustification
            handlers={{
              closeEditDrawer,
              onFinishEditing,
            }}
            values={{
              editingForm,
              visibleEditDrawer,
              editing,
              editFormRef,
              justifications,
            }}
          />
          <DeleteExtensionJustification
            visible={editing.isDeleting}
            onSubmit={handleFinishDelete}
            selectedJustification={editing.editingRow}
          />
          <ExtensionJustificationTable
            values={{ justifications, sortedInfo, editing, total }}
            handlers={{
              handleChangeTable,
              handleClickEdit,
              handleClickDelete,
              handleFinishDelete,
            }}
          />
          <BackTopButton />
        </>
      )}
    </div>
  );
};

export default ManageExtensionJustifications;
