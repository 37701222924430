import React from "react";
import { Typography } from "antd";

const PageTitle = ({ title }) => {
  const { Title } = Typography;
  return (
    <Title
      level={2}
      style={{
        fontWeight: "400",
        userSelect: "none",
        marginTop: "30px",
        fontSize: "21px",
      }}
    >
      {title}
    </Title>
  );
};

export default PageTitle;
