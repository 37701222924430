import React, { useEffect, useRef } from "react";
import { Modal, Form, Input } from "antd";

const EditFileIdForm = ({ visible, selectedGDoc, onSubmit }) => {
  const editFormRef = useRef(null);
  const [form] = Form.useForm();

  let initialValues = selectedGDoc;

  const defaultValues = {
    uploadedFileId: initialValues.uploadedFileId,
    resourceName: initialValues.resourceName,
  };

  // fix to update initial values of edit form
  useEffect(() => {
    if (editFormRef.current) {
      form.setFieldsValue(defaultValues);
    }
  }, [editFormRef, form, defaultValues]);

  return (
    <Modal
      mask={false}
      open={visible}
      title="File ID"
      okText="OK"
      cancelText="Cancel"
      onCancel={async () => {
        await onSubmit(false);
      }}
      onOk={async () => {
        try {
          let values = await form.validateFields();
          await onSubmit(true, values);
          form.resetFields();
        } catch (error) {
          console.log("Validate Failed:", error);
        }
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="editFileIdForm"
        initialValues={{
          modifier: "public",
          remember: false,
          uploadedFileId: initialValues.uploadedFileId,
          resourceName: initialValues.resourceName,
        }}
        ref={editFormRef}
      >
        <Form.Item
          name="uploadedFileId"
          label={`Enter File ID for ${initialValues.document}`}
          rules={[{ required: true, message: "Please enter File ID" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="resourceName" noStyle>
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditFileIdForm;
