export function currentYear() {
  const date = new Date();
  const year = date.getFullYear();
  return year;
}

export function formatFilters(array) {
  // Extracts the unique elements from array
  const newArray = [...new Set(array)];
  const formatArray = newArray.map((item) => {
    //removes underscore
    const displayedItem = item && item.replace(/_/g, " ");
    return {
      text: displayedItem,
      value: item,
    };
  });
  // Will return the items as antd format
  return formatArray;
}

export function sorter(a, b) {
  a = !a ? "" : Array.isArray(a) ? a.join() : a;
  b = !b ? "" : Array.isArray(b) ? b.join() : b;

  const diff = a - b;
  if (Number.isNaN(diff)) {
    return a.localeCompare(b) || a.length - b.length;
  } else {
    return diff;
  }
}

export const removeUnderscore = (string) => string?.replace(/_/g, " ");

export const removeDots = (string) => string.split(".").join("");

// remove a key and it's value from an object - using computed properties
export function removeProperty(obj, propertyName) {
  let { [propertyName]: _, ...result } = obj;
  return result;
}

// will check if the object exists and if has any value
// if exists and is empty will return true
export function isObjectEmpty(obj) {
  if (obj && Object.keys(obj).length === 0 && obj.constructor === Object) {
    return true;
  } else return false;
}

// check object values exists
export function objectHasValues(obj) {
  const hasValues = Object.values(obj).some((item) => item);
  return hasValues;
}

// extracts the extension of a string - after dot .
// document1.xls will return xls
export function getFileExtension(filename) {
  let fileExt = filename.split(".").pop();
  return `.${fileExt}`;
}

// will return everything up to first .
// DriveSourceFile_1.sdlxliff.xliff => DriveSourceFile_1
export function extractFileName(str) {
  if (str.includes(".")) {
    return str.substring(0, str.indexOf("."));
  } else {
    return str;
  }
}

// removes extension from a file
export function removeSpecifiedExtension(filename, extension) {
  let newFileName = filename.replace(extension, "");
  return newFileName;
}

// assumption that the last dot will be the extension delimiter
export function removeExtension(filename) {
  let lastDotPosition = filename.lastIndexOf(".");
  if (lastDotPosition === -1) return filename;
  else return filename.substr(0, lastDotPosition);
}

export function capitalizeString(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// will convert turnaroundTimeUrgency yo Turnaround Time Urgency
export const convertObjKeyToName = (key) => {
  return capitalizeString(key)
      .replace(/([A-Z])/g, " $1")
      .trim();
};

export const matchAdminItem = (list, location) => {
  let found = list
      .map((item) =>
          item.children.filter(
              (child) =>
                  child.path === location ||
                  child?.submenu?.some((value) => value.path === location)
          )
      )
      .find((val) => val.length);
  return found;
};

// group and sort an array by a certain key
export const groupAndSortByKey = (array, key) =>
    array.sort((a, b) => a[key].localeCompare(b[key])).reduce(
        (hash, obj) => ({
          ...hash,
          [obj[key].toLowerCase()]: (hash[obj[key].toLowerCase()] || []).concat(obj),
        }),
        {}
    );


// will replace each character with asterisk
export const hideString = (string) => {
  return string.replace(/./gi, "∗");
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function filterEmptyValues(obj) {

  const filteredObj = {};


  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (
        (Array.isArray(value) && value.length > 0) ||
        (typeof value === 'string' && value.trim() !== '') ||
        (typeof value !== 'undefined' && value !== null && !Array.isArray(value))
      ) {
        filteredObj[key] = value;
      }
    }
  }

  return filteredObj;
}