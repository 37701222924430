import React, { useState, useCallback, FunctionComponent } from "react";
import { TransferList, TransferListList } from "react-transfer-list";

import "./manageColumns.scss";
import { Button, Checkbox, Modal } from "antd";
import {
  LeftCircleOutlined,
  RightCircleOutlined,
  SettingOutlined,
  DownCircleOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
export interface Column {
  id: string;
  name?: string;
  title: string;
  dataIndex?: any;
  align?: string;
  width?: string | number;
  sorter?: any;
  fixed?: string | boolean;
  active: boolean;
}

export interface ManageColumnsProps {
  isModalOpen: boolean;
  toggleModal: () => any;
  columns: Column[];
  disabledColumns: string[];
  handleSubmit: (params: any) => any;
}

interface IBodyComponent {
  id: string;
  listId: string;
}

export const ManageColumns: FunctionComponent<ManageColumnsProps> = ({
  isModalOpen,
  toggleModal,
  columns,
  handleSubmit,
}) => {
  const determineIds = useCallback((data: Column[]) => {
    const initialLeft: string[] = [];
    const initialScrolling: string[] = [];
    const initialRight: string[] = [];

    data
      .filter((c: Column) => c.active)
      .forEach((col: Column) => {
        // some columns title are being passed as {}, ex STT
        let columnName =
          // @ts-ignore
          typeof col.title === "string" ? col.title : col.title.props.title;

        if (col.fixed === "left") {
          initialLeft.push(columnName);
        } else if (col.fixed === "right") {
          initialRight.push(columnName);
        } else {
          initialScrolling.push(columnName);
        }
      });

    const initialIds: Record<string, string[]> = {
      left: initialLeft,
      scrolling: initialScrolling,
      right: initialRight,
    };
    return initialIds;
  }, []);

  // ids value needs to be unique
  const [cols, setCols] = useState<Column[]>(columns),
    [ids, setIds] = useState(determineIds(cols));

  const handleChangeDragDrop = useCallback((listId: string, ids: string[]) => {
    setIds((orig) => {
      orig[listId] = [...ids];
      return { ...orig };
    });
  }, []);

  const handleOk = () => {
    // calculate order
    const categories = ["left", "scrolling", "right"];

    const findCategory = (title: string) => {
      for (const category of categories) {
        if (ids[category].indexOf(title) !== -1) return category;
      }
      return null;
    };

    const sortedColumns = cols.sort((obj1: Column, obj2: Column) => {
      const obj1Title =
        // @ts-ignore
        typeof obj1.title === "string" ? obj1.title : obj1.title.props.title;

      const obj2Title =
        // @ts-ignore
        typeof obj2.title === "string" ? obj2.title : obj2.title.props.title;

      const cat1 = findCategory(obj1Title);

      const cat2 = findCategory(obj2Title);

      // If objects are from different categories
      if (cat1 !== cat2) {
        if (cat1 && cat2) {
          return categories.indexOf(cat1) - categories.indexOf(cat2);
        } else {
          // Handle potential null values
          return cat1 ? -1 : 1;
        }
      }

      // If objects are from the same category
      if (cat1) {
        return ids[cat1].indexOf(obj1Title) - ids[cat1].indexOf(obj2Title);
      } else {
        // Handle potential null value for cat1
        return 0;
      }
    });

    // match ids with columns and update their fixed position
    const matchedColumns = sortedColumns.map((col: Column) => {
      for (const [key, value] of Object.entries(ids)) {
        let columnName =
          // @ts-ignore
          typeof col.title === "string" ? col.title : col.title.props.title;

        if (value.includes(columnName)) {
          col.fixed = key === "scrolling" ? undefined : key;
        }
      }
      return col;
    });

    handleSubmit(matchedColumns);
    toggleModal();
  };

  const handleCancel = () => {
    setIds(determineIds(columns));
    setCols(columns);
    toggleModal();
  };

  const toggleColumn = (e: any, colId: string) => {
    const updatedCols = cols.map((c: Column) => {
      if (colId === c.id) {
        return {
          ...c,
          active: e.target.checked,
        };
      } else {
        return c;
      }
    });

    setCols(updatedCols);

    // match ids
    const column = updatedCols.find((c: any) => c.id === colId);

    if (e.target.checked) {
      let newIds = ids;
      // add
      if (column) {
        let columnName =
          // @ts-ignore
          typeof column.title === "string"
            ? column.title
            : // @ts-ignore
              column.title.props.title;

        switch (column.fixed) {
          case "right":
            newIds.right.push(columnName);
            break;
          case "left":
            newIds.left.push(columnName);
            break;
          default:
            newIds.scrolling.push(columnName);
            break;
        }
        setIds(newIds);
      } else {
        console.error("No column was matched.");
      }
    } else {
      let newIds: any = {};
      // remove
      for (let [key, value] of Object.entries(ids)) {
        let columnName =
          // @ts-ignore
          typeof column.title === "string"
            ? // @ts-ignore
              column.title
            : // @ts-ignore
              column.title.props.title;

        if (value.includes(columnName)) {
          value = value.filter((i) => i !== columnName);
        }
        newIds[key] = value;
      }

      setIds(newIds);
    }
  };

  const moveColumn = (currentList: string, targetList: string, id: string) => {
    handleChangeDragDrop(
      currentList,
      ids[currentList].filter((i: string) => i !== id)
    );
    handleChangeDragDrop(targetList, [...ids[targetList], id]);
  };

  const reorderColumnItems = (
    columnId: string,
    id: string,
    direction: string
  ) => {
    const swapElements = (arr: string[], x: number, y: number) => {
      [arr[x], arr[y]] = [arr[y], arr[x]];
      return arr;
    };

    let column = ids[columnId],
      currentIndex = column.indexOf(id);

    if (direction === "up") {
      const orderedIds = swapElements(column, currentIndex, currentIndex - 1);
      handleChangeDragDrop(columnId, orderedIds);
    } else if (direction === "down") {
      const orderedIds = swapElements(column, currentIndex, currentIndex + 1);
      handleChangeDragDrop(columnId, orderedIds);
    } else {
      console.log("Allowed directions: up | down");
    }
  };

  return (
    <div className="manage-columns-container">
      <Button
        type="text"
        icon={<SettingOutlined />}
        onClick={() => toggleModal()}
        className="manage-columns-btn"
      />
      <Modal
        title={
          <>
            <SettingOutlined
              style={{ marginRight: "8px", color: "var(--primary-color)" }}
            />
            Manage Columns
          </>
        }
        className="manage-columns-mdl"
        open={isModalOpen}
        width={1100}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Apply"}
      >
        <TransferList ids={ids} onChange={handleChangeDragDrop}>
          <div className="left-container">
            <p>Fixed left</p>
            <TransferListList
              id="left"
              listItemBodyComponent={(props: IBodyComponent) => {
                const lastElement = ids.left.at(ids.left.length - 1);
                return (
                  <div className="drag-item-container">
                    <span className="row-title">{props.id}</span>
                    <div className="arrows-wrapper">
                      <UpCircleOutlined
                        title="Move up"
                        className={
                          props.id === ids.left[0]
                            ? "drag-arrow__disabled"
                            : "drag-arrow"
                        }
                        onClick={() =>
                          reorderColumnItems(props.listId, props.id, "up")
                        }
                      />
                      <DownCircleOutlined
                        title="Move down"
                        className={
                          props.id === lastElement
                            ? "drag-arrow__disabled"
                            : "drag-arrow"
                        }
                        onClick={() =>
                          reorderColumnItems(props.listId, props.id, "down")
                        }
                      />
                      <RightCircleOutlined
                        title="Move right"
                        className="drag-arrow drag-arrow__right"
                        onClick={() =>
                          moveColumn(props.listId, "scrolling", props.id)
                        }
                      />
                    </div>
                  </div>
                );
              }}
            />
          </div>
          <div className="scrolling-container">
            <p>Scrolling</p>
            <TransferListList
              id="scrolling"
              listItemBodyComponent={(props: IBodyComponent) => {
                const lastElement = ids.scrolling.at(ids.scrolling.length - 1);
                return (
                  <div className="drag-item-container">
                    <div className="left-container">
                    <LeftCircleOutlined
                      title="Move left"
                      className="drag-arrow drag-arrow__left"
                      onClick={() => moveColumn(props.listId, "left", props.id)}
                      />
                    <span className="row-title">{props.id}</span>
                    </div>
                    <div className="arrows-wrapper">
                      <UpCircleOutlined
                        title="Move up"
                        className={
                          props.id === ids.scrolling[0]
                            ? "drag-arrow__disabled"
                            : "drag-arrow"
                        }
                        onClick={() =>
                          reorderColumnItems(props.listId, props.id, "up")
                        }
                      />
                      <DownCircleOutlined
                        title="Move down"
                        className={
                          props.id === lastElement
                            ? "drag-arrow__disabled"
                            : "drag-arrow"
                        }
                        onClick={() =>
                          reorderColumnItems(props.listId, props.id, "down")
                        }
                      />
                      <RightCircleOutlined
                        title="Move right"
                        className="drag-arrow drag-arrow__right"
                        onClick={() =>
                          moveColumn(props.listId, "right", props.id)
                        }
                      />
                    </div>
                  </div>
                );
              }}
            />
          </div>
          <div className="right-container">
            <p>Fixed right</p>
            <TransferListList
              id="right"
              listItemBodyComponent={(props: IBodyComponent) => {
                const lastElement = ids.right.at(ids.right.length - 1);
                return (
                  <div className="drag-item-container">
                    <div className="arrows-wrapper">
                      <LeftCircleOutlined
                        title="Move left"
                        className="drag-arrow drag-arrow__left"
                        onClick={() =>
                          moveColumn(props.listId, "scrolling", props.id)
                        }
                      />
                      <UpCircleOutlined
                        title="Move up"
                        className={
                          props.id === ids.right[0]
                            ? "drag-arrow__disabled"
                            : "drag-arrow"
                        }
                        onClick={() =>
                          reorderColumnItems(props.listId, props.id, "up")
                        }
                      />
                      <DownCircleOutlined
                        title="Move down"
                        className={
                          props.id === lastElement
                            ? "drag-arrow__disabled"
                            : "drag-arrow"
                        }
                        onClick={() =>
                          reorderColumnItems(props.listId, props.id, "down")
                        }
                      />
                    </div>
                    <span className="row-title">{props.id}</span>
                  </div>
                );
              }}
            />
          </div>
        </TransferList>
        <div className="available-columns">
          <p className="title">Available Columns</p>
          <div className="grid-container">
            {cols
              .sort(function (a: Column, b: Column) {
                const idA =
                    typeof a.title === "string"
                      ? a.title.toLowerCase()
                      : // @ts-ignore
                        a.title.props.title.toLowerCase(),
                  idB =
                    typeof b.title === "string"
                      ? b.title.toLowerCase()
                      : // @ts-ignore
                        b.title.props.title.toLowerCase();
                if (idA < idB) {
                  return -1;
                }
                if (idA > idB) {
                  return 1;
                }
                return 0;
              })
              .map((col: Column) => (
                <div key={col.id} className="column">
                  <Checkbox
                    onChange={(e) => toggleColumn(e, col.id)}
                    checked={col.active}
                  />
                  <span style={{ marginLeft: "8px" }} className="column-title">
                    {typeof col.title === "string"
                      ? col.title
                      : // @ts-ignore
                        col.title.props.title}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};
