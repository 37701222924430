import React, { useState, useEffect, useCallback } from "react";
import { Modal, Form, InputNumber, Select, Checkbox } from "antd";

import { useMercuryContext } from "../../../user-context";
import { WarningOutlined } from "@ant-design/icons";

const { Option } = Select;

const NewRateForm = ({
  visible,
  selectedPLPs,
  onSubmit,
  generalInfo,
  ratesCollection,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(true);
  const { client } = useMercuryContext();

  const [matchedRates, setMatchedRates] = useState([]);
  const [form] = Form.useForm();

  const matchUsingPlpDate = useCallback(async () => {
    setConfirmLoading(true);

    await client
      .get("/core-grs-v1/rate/match", {
        params: {
          effectiveDate: new Date(
            selectedPLPs[0].importedTimestamp
          ).toISOString(),
          // serviceName: selectedPLPs.map( plp => plp.service ), No need to pass on frontend.
          targetLanguageCode: selectedPLPs.map((plp) => plp.plpLang),
          sourceLanguageCode: [generalInfo.sourceLanguageCode],
          multiple: true
        },
      })
      .then((res) => {
        // console.log("MATCHED", res);
        setConfirmLoading(false);
        setMatchedRates(res.data);
      })
      .catch((e) => {
        setConfirmLoading(false);
        console.error("Error fetching rates: ", e);
      });
  }, [client, generalInfo.sourceLanguageCode, selectedPLPs]);

  const matchUsingCurrentDate = useCallback(async () => {
    setConfirmLoading(true);

    await client
      .get("/core-grs-v1/rate/match", {
        params: {
          effectiveDate: new Date(Date.now()).toISOString(),
          // serviceName: selectedPLPs.map( plp => plp.service ), No need to pass on frontend.
          targetLanguageCode: selectedPLPs.map((plp) => plp.plpLang),
          sourceLanguageCode: [generalInfo.sourceLanguageCode],
          multiple: true
        },
      })
      .then((res) => {
        // console.log("MATCHED", res);
        setConfirmLoading(false);
        setMatchedRates(res.data);
      })
      .catch((e) => {
        setConfirmLoading(false);
        console.error("Error fetching rates: ", e);
      });
  }, [client, generalInfo.sourceLanguageCode, selectedPLPs]);

  useEffect(() => {
    if (visible && selectedPLPs?.length) {
      matchUsingPlpDate();
    }
  }, [matchUsingPlpDate, selectedPLPs.length, visible]);

  const onChangeCheckbox = (e) => {
    if (e.target.checked) {
      matchUsingCurrentDate();
    } else {
      matchUsingPlpDate();
    }
  };

  return (
    <Modal
      open={visible}
      title="New Rate"
      okText="OK"
      cancelText="Cancel"
      onCancel={async () => {
        await onSubmit(false);
      }}
      onOk={async () => {
        try {
          let values = await form.validateFields();

          const [rates] = matchedRates?.filter(
            (rate) => rate.name === values.rateNameApplied
          );

          if (!rates) {
            console.error("No Matched service on submitting new rate.");
          }

          setConfirmLoading(true);
          onSubmit(true, {
            rates: rates.rates,
            rateNameApplied: values.rateNameApplied,
            weekendSurcharge: values.weekendSurcharge,
            weekendSurchargeApplies: true,
            // service: matchService ? matchService : null,
          });

          setConfirmLoading(false);
          form.resetFields();
        } catch (error) {
          console.error("Validate Failed:", error);
        }
      }}
      confirmLoading={confirmLoading}
    >
      <>
        <Checkbox onChange={onChangeCheckbox} style={{ marginBottom: "10px" }}>
        Show all rates available.
          <WarningOutlined style={{ color: "var(--red)", margin: "0 5px" }} />
          Only use this option if you cannot find a rate for this service in the
          list below.
        </Checkbox>
        <Form
          form={form}
          layout="vertical"
          name="newRateForm"
          initialValues={{
            modifier: "public",
            rateNameApplied: undefined,
            weekendSurcharge: 0,
          }}
        >
          <Form.Item
            name="rateNameApplied"
            label="Rate"
            rules={[
              {
                required: true,
                message: "Please select a rate",
              },
            ]}
          >
            <Select
              disabled={confirmLoading}
              className="rate-select"
              placeholder="Select a Rate"
              dropdownStyle={{
                textAlign: "center",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "var(--tertiary-color)",
              }}
              popupMatchSelectWidth={false}
              allowClear
            >
              {Array.from(new Set(matchedRates?.map((rate) => rate.name)))?.map(
                (item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name="weekendSurcharge"
            label="Surcharge % "
            rules={[{ required: false, message: "Please enter Surcharge %" }]}
          >
            <InputNumber step={0.01} disabled={confirmLoading} />
          </Form.Item>
        </Form>
      </>
    </Modal>
  );
};

export default NewRateForm;
