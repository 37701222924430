import LocalStorage from "./LocalStorage";

export default class SessionStorage extends LocalStorage {

  get data() {

    const data = window.sessionStorage.getItem( this.key );

    if ( !data || data === "undefined" ) {
      return undefined;
    }

    try {
      return JSON.parse( window.sessionStorage.getItem( this.key ) || "");
    }
    catch (e) {
      return undefined;
    }
  }

  clear() {
    window.sessionStorage.clear();
  }

  set data( value ) {

    if ( !value ) {
      return;
    }

    try {
      window.sessionStorage.setItem( this.key, JSON.stringify( value ) );
    }
    catch (e) {
      console.error( e );
      return;
    }
  }
}
