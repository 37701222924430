import { useState, useEffect } from "react";
import { sorter } from "../../../utils/helpers";
import { Link } from "react-router-dom";
import { BlockOutlined } from "@ant-design/icons";

import {
  Table,
} from "antd";

const CaptionHubTab = ({ projectDoc }: any) => {
  const [updateList, setUpdateList] = useState<any>([]);
  const [pageSize, setPageSize] = useState<number>(15);

  useEffect(() => {

    let tableData: any = [];

    const documents = new Map();

    projectDoc?.captionHubProject?.documents?.forEach((document: any) => {
      documents.set( document._id, document );
    })

    const chMapped = new Map();

    projectDoc?.captionHubProject?.captionHubData.map( ( ch:any ) => {
      chMapped.set( ch.id, ch );
    })

    projectDoc?.captionHubProject?.documentMapping?.map((mapping: any, i:number) => {

      const chProject = chMapped.get( mapping.chProjectId );

      let
          document = documents.get( mapping.documentId ),
          data: any = {
            key: document?._id,
            captionHubDocumentName: document?.title,
            captionHubProjectId: chProject?.slug,
            ...mapping
          };



      tableData.push(data);
    });

    setUpdateList(tableData);

  }, []);


  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { pageSize } = pagination;
    setPageSize(pageSize);
  };

  const tableColumns = [
    {
      title: "Document Name",
      dataIndex: "captionHubDocumentName",
      width: 300,
      sorter: (a: any, b: any) => sorter(a.captionHubDocumentName, b.captionHubDocumentName),
      render: (text: string) => text,
    },
    {
      title: "CaptionHub Project ID",
      dataIndex: "captionHubProjectId",
      width: 150,
      sorter: ( a: any, b: any ) => sorter( a.captionHubProjectId, b.captionHubProjectId ),
      render: (text: any, record: any) => {
        return <Link
          to={`https://app.captionhub.com/projects/${text}`}
          id={text}
          className={"project-link"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <BlockOutlined className="project-link-icon" style={{marginRight: '5px'}}/>
          {text}
        </Link>
      }
    },
    {
      title: "Caption set ID",
      dataIndex: "captionHubProjectId",
      width: 150,
      sorter: ( a: any, b: any ) => sorter( a.captionHubProjectId, b.captionHubProjectId ),
      render: (text: any, record: any) => {

        if ( !record.captionSetId ) {
          return "PENDING CREATION";
        }

        return <Link
            to={`https://app.captionhub.com/projects/${text}/caption_sets/${record.captionSetId}`}
            id={text}
            className={"project-link"}
            target="_blank"
            rel="noopener noreferrer"
        >
          <BlockOutlined className="project-link-icon" style={{marginRight: '5px'}}/>
          {record.captionSetId}
        </Link>
      }
    },
    {
      title: "Target Language",
      dataIndex: "chLanguageCode",
      width: 150,
      sorter: (a: any, b: any) => sorter(a.captionHubTargetLanguage, b.captionHubTargetLanguage),
      render: (text: string) => text,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 150,
      sorter: (a: any, b: any) => sorter(a.captionHubStatus, b.captionHubStatus),
      render: (text: string) => text,
    },
  ];

  console.log( updateList )

  return (
    <Table
      columns={tableColumns}
      dataSource={updateList}
      className={"captionhub-table"}
      size="small"
      scroll={{ x: 1300 }}
      style={{ margin: "10px 15px 20px 15px" }}
      pagination={{
        position: ["bottomCenter"],
        pageSize,
      }}
      onChange={handleTableChange}
    />
  );
};

export default CaptionHubTab;
