import React, { useState } from "react";
import { Button, Modal, Table, Tag } from "antd";
import type { TableProps } from "antd";

import TimezoneConverter from "../../../timezone-converter";
import "./SharingAttemptsModal.scss";
interface IProps {
  attempts: string;
  documentId: string;
  history: any;
}

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const SharingAttemptsModal: React.FC<IProps> = ({
  attempts,
  documentId,
  history,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const sortedHistory = history?.sort((a: any, b: any) => {
    const dateA = new Date(a.time).getTime();
    const dateB = new Date(b.time).getTime();
    return dateA - dateB;
  });
  const data = sortedHistory || [];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const determineStatus = (response:any) => {
    if (!response) {
      return <p className="response-column__failure no-response">Failed attempt</p>
    } else if (response.code && !response.message) {
      return <p className="response-column__failure with-code-no-message">Failed attempt</p>
    } else if (response.code && response.message) {
      return <p className="response-column__failure with-code-and-message">{response.message}</p>
    } else {
      return <p className="response-column__success">Successful</p>
    }
  }

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      width: 160,
      render: (text) => <TimezoneConverter date={text} />,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 80,
    },
    {
      title: "Document Status",
      key: "documentStatus",
      dataIndex: "documentStatus",
      render: (text) => text,
    },
    {
      title: "Response",
      key: "response",
      dataIndex: "response",
      render: (text) => {
        return (
          <div className="response-column">
              { determineStatus(text) }
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Button type="link" onClick={showModal}>
        {attempts}
      </Button>
      <Modal
        title={`Attempts history for ${documentId}`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={850}
        cancelButtonProps={{
          style: { display: "none", pointerEvents: "none" },
        }}
        className="sharing-attempts-modal"
        okText="Close"
      >
        <Table
          columns={columns}
          dataSource={data?.map((i: any, index: number) => {
            return { ...i, key: index };
          })}
          size="small"
        />
      </Modal>
    </>
  );
};

export default SharingAttemptsModal;
