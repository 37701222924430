import axios from 'axios';
import moment from 'moment';
import {
  failedNotification,
  successfulNotification,
} from "../../../utils/notifications";
// import { BASE_PATH, API_VERSION } from './config';

const postExtensionForm = async (
  requestedDueDate,
  justificationType,
  justificationCode,
  extensions,
  setJustificationType,
  setJustificationCode,
  accessToken,
  setIsModalVisible,
  setLoading,
  setDisabled,
  caseId,
  setCaseId,
  rejectHandler,
) => {
  const resetState = () => {
    setIsModalVisible(false);
    setLoading(false);
    setJustificationType(null);
    setJustificationCode(0);
    setDisabled(false);
    setCaseId(null);
  };


  const isDuplicate = extensions.reduce((acc, current) => {
    acc[current.targetLanguageCode] = ++acc[current.targetLanguageCode] || 0;
    return acc;
  }, {});
  
  // get the first duplicate plp targetLanguageCode
  const targetLanguageCodeDuplicates = extensions.find((e) => isDuplicate[e.targetLanguageCode])

  let listExtensions = [];
  let errorResponse = [];

  targetLanguageCodeDuplicates ? listExtensions = [targetLanguageCodeDuplicates] : listExtensions = extensions;

  try {

    const submittedExtensions = listExtensions.map((extension) => ({
      projectId: extension.projectId,
      shortId: extension.shortId,
      sourceLanguageCode: extension.sourceLanguageCode,
      targetLanguageCode: extension.targetLanguageCode,
      originalDueDate: extension.originalDueDate,
      requestedDueDate: requestedDueDate ? requestedDueDate : moment().toJSON(),
      justificationCode,
      justification: justificationType.googleType,
      justificationKind: justificationType.kind,
      justificationLimit: justificationType.limit,
      justificationFrequency: justificationType.frequency,
      urgency: extension.urgency,
      requestedBy: extension.requestedBy,
      product: extension.product,
      productArea: extension.productArea,
      // extra fields
      title: extension.title,
      plpStatus: extension.plpStatus,
      docStatus: extension.docStatus,
      dueDateTier: extension.dueDateTier,
      componentType: extension.componentType,
      nonExtendable: extension.nonExtendable,
      receivedDate: extension.receivedDate,
      legallySensitive: extension.legallySensitive,
      projectType: extension.projectType,
      validationStatus: listExtensions?.length === 1 ? extension.plpStatus : extension.docStatus,
      ...(caseId ? { caseId } : ''),
    }))


    let headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    let submittedPLPPromises = [];

    submittedPLPPromises = submittedExtensions.map((extension) => {
      return axios
        .post(
          // `${BASE_PATH()}/${API_VERSION}/ges/extension`, //v1
          `${process.env.REACT_APP_API_URL}/google-ges-v1/`, extension, {
          headers: headers,
        })
        .then((response) => {
          let targetLanguage = response.data[0].targetLanguageCode;
          successfulNotification(
            `The extension request for ${targetLanguage} successfully submitted`,
            ""
          );
        })
        .catch((error) => {
          
          if (error.response) {

            errorResponse.push(error.response.data.extension);

            failedNotification(
              error.response.data.details.title.name,
              error.response.data.details.title.message
            );

          } else {
            failedNotification("Failed to submit extension");
          }
        });
    });

    await Promise.allSettled(submittedPLPPromises);

  } catch (error) {
    failedNotification(`Failed to submit extension: ${error}`);
  }

  if(errorResponse.length !==0) {
    rejectHandler(errorResponse)
  }

  resetState();
};

export default postExtensionForm;
