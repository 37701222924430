import {
  TableAbstract,
  CustomTooltip,
  FiniteListFilter,
  TextFilter,
  ListFilter,
} from "../../Components";
import { useMercuryContext } from "../../user-context";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import TimezoneConverter from "../../timezone-converter";
import { Link } from "react-router-dom";
import { Space, Tooltip } from "antd";
import SesameDropdown from "../../stories/Sesame/Dropdown/SesameDropdown";
import SesameAccountDialog from "../../stories/SesameAccount/SesameAccountDialog";
import {
  failedNotification,
  successfulNotification,
} from "../../utils/notifications";
import ResetPassword from "./ResetPassword";
import {
  requestTypes,
  roles,
  statusOptions,
  tcOptions,
} from "../../stories/SesameAccount/Json";
import LanguageCodeFilter from "../../Search/LanguageCode.filter";
import { FiniteListProps } from "../../stories/FiniteListFilter/FiniteListFilter";
import ProductAreaFilter from "../../Search/ProductArea.filter";
import { TextFilterProps } from "../../stories/TextFilter/TextFilter";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import SuspensionRequest from "./SuspensionRequest";
import RemoveExceptionGroup from "./RemoveExceptionGroup";
import { seasameStatusColumn } from "../../Search/columnsConfig";
import SesameStatusFilter from "./SesameStatus.filter";
import CreateRequest from "./CreateRequest";

const Role = (props: FiniteListProps) => (
  <FiniteListFilter {...props} name="role" title="Program Role" data={roles} />
);

const Affiliation = (props: FiniteListProps) => (
  <FiniteListFilter
    {...props}
    name="affiliation"
    title="Affiliation"
    data={[
      { key: "External", value: "External", title: "External" },
      { key: "RWS_Internal", value: "RWS_Internal", title: "RWS Internal" },
    ]}
  />
);

const AccountSuspended = (props: TextFilterProps) => (
  <FiniteListFilter
    {...props}
    name="accountSuspended"
    title="Account Suspended"
    data={statusOptions}
  />
);

const RequestType = (props: TextFilterProps) => (
  <FiniteListFilter
    {...props}
    name="requestType"
    title="Request Type"
    data={requestTypes}
  />
);

const SesameID = (props: TextFilterProps) => (
  <TextFilter {...props} name="sesameIds" title="Sesame ID" />
);

const AccountTCSuspended = (props: FiniteListProps) => {
  return (
    <FiniteListFilter
      {...props}
      name="accountTCSuspended"
      title="TC Suspended"
      data={tcOptions}
    />
  );
};

const getStatusIcon = (text: string) => {
  let status;

  if (text === "Yes") {
    status = <CheckOutlined style={{ color: "var(--tertiary-color)" }} />;
  } else if (text === "No") {
    status = <CloseOutlined style={{ color: "var(--red)" }} />;
  } else {
    status = null;
  }

  return status;
};

const IsEnrolledIn2Sv = (props: TextFilterProps) => (
  <FiniteListFilter
    {...props}
    name="isEnrolledIn2Sv"
    title="Is 2SV Enrolled"
    data={statusOptions}
  />
);

class SesameSearch extends TableAbstract<any> {
  defaultSortField = "requestedDate";
  FilterComponents = [
    {
      id: "role",
      name: "role",
      title: "Program Role",
      component: Role,
      active: true,
    },
    {
      id: "code",
      title: "Language Code",
      name: "languageCode",
      component: LanguageCodeFilter,
      active: false,
    },
    {
      id: "affiliation",
      title: "Affiliation",
      name: "affiliation",
      component: Affiliation,
      active: false,
    },
    {
      id: "status",
      name: "status",
      title: "Status",
      component: SesameStatusFilter,
      active: true,
    },
    {
      id: "productLine",
      title: "Product Line",
      name: "productLine",
      component: ProductAreaFilter,
    },
    {
      id: "requesterEmail",
      title: "Requester Email",
      name: "requesterEmail",
      component: ListFilter,
    },
    {
      id: "requestType",
      title: "Request Type",
      name: "requestType",
      component: RequestType,
    },
    {
      id: "suspended",
      title: "Account Suspended",
      name: "accountSuspended",
      component: AccountSuspended,
    },
    {
      id: "sesame",
      title: "Sesame ID",
      name: "sesameIds",
      component: SesameID,
    },
    {
      id: "tcsuspended",
      title: "TC Suspended",
      name: "accountTCSuspended",
      component: AccountTCSuspended,
    },
    {
      id: "isEnrolledIn2Sv",
      title: "Is 2Sv Enrolled",
      name: "isEnrolledIn2Sv",
      component: IsEnrolledIn2Sv,
    },
  ];

  commonFields = (id: string, title: string) => {
    return {
      id,
      dataIndex: id,
      title,
      sorter: true,
      fixed: undefined,
      width: 150,
      render: (text: string) => <CustomTooltip text={text} length={17} />,
    };
  };

  columns = [
    {
      id: "_id",
      title: "ID",
      dataIndex: "_id",
      width: 80,
      fixed: undefined,
      render: (id: string, record: any) => (
        <Link to={`/sesame/${id}`} id={id}>
          {id?.slice(-6)}
        </Link>
      ),
    },
    {
      id: "requestedDate",
      title: "Requested Date",
      dataIndex: "requestedDate",
      width: 180,
      fixed: undefined,
      render: (text: string) => <TimezoneConverter date={text} />,
    },
    {
      id: "requestType",
      title: "Request Type",
      dataIndex: "requestType",
      sorter: true,
      fixed: undefined,
      width: 150,
      render: (text: string) => <CustomTooltip text={text} length={17} />,
    },
    {
      id: "requesterEmail",
      title: "Requester Email",
      dataIndex: "requesterEmail",
      sorter: true,
      fixed: undefined,
      width: 200,
      render: (text: string) => <CustomTooltip text={text} length={25} />,
    },
    {
      ...this.commonFields("role", "Program/Project Role"),
    },
    {
      ...this.commonFields("languageCode", "Language Code"),
    },
    {
      ...this.commonFields("affiliation", "Affiliation"),
    },
    {
      ...this.commonFields("productLine", "Product Line"),
    },
    {
      ...this.commonFields("contentType", "Content Type"),
    },
    {
      ...this.commonFields("fileSharing", "Automated file sharing in Polyglot"),
    },
    {
      ...this.commonFields(
        "emailCommunications",
        "Include in HO emails and other email communications"
      ),
    },
    {
      ...this.commonFields("fullName", "Full Name"),
    },
    {
      ...this.commonFields("city", "City, Country"),
    },
    seasameStatusColumn,
    {
      id: "emailAddress",
      title: "Email ID of new resource",
      dataIndex: "emailAddress",
      sorter: true,
      fixed: undefined,
      width: 180,
      render: (text: string) => <CustomTooltip text={text} length={17} />,
    },
    {
      id: "notes",
      title: "Additional Instructions for Training team",
      dataIndex: "notes",
      sorter: true,
      fixed: undefined,
      width: 220,
      render: (text: string) => <CustomTooltip text={text} length={17} />,
    },
    {
      id: "suspended",
      title: (
        <Tooltip
          title="Account Suspended"
          placement="left"
          color={"var(--tertiary-color)"}
        >
          Account Suspended
        </Tooltip>
      ) as any,
      dataIndex: "accountSuspended",
      sorter: true,
      width: 60,
      render: (text: string) => getStatusIcon(text),
      fixed: undefined,
    },
    {
      id: "isEnrolledIn2Sv",
      title: (
        <Tooltip
          title="Is Two-Step Verification Enrolled"
          placement="left"
          color={"var(--tertiary-color)"}
        >
          Is 2SV Enrolled
        </Tooltip>
      ) as any,
      dataIndex: "isEnrolledIn2Sv",
      sorter: true,
      width: 60,
      render: (text: string) => getStatusIcon(text),
      fixed: undefined,
    },
  ];

  async handleSubmit(data: any, callback: any) {
    const { _id, createdAt, updatedAt, __v, key, id, ...cleanedRow } =
      this.state.selectedRows?.[0] || {};

    try {
      await this.userContext.gpClient.post(
        `/google-gp-v1/sesameAccounts`,
        data?.requestType !== "New" ? cleanedRow : data
      );
      callback();
      successfulNotification(
        "Success",
        "Your request has been successfully submitted"
      );
    } catch (error: any) {
      callback();
      failedNotification("Error", "Something went wrong!");
    }
  }

  async handleSelection(type: any) {
    //@ts-ignore
    this.setState({ currentSelection: type });
  }

  async resetSelection() {
    //@ts-ignore
    this.setState({ currentSelection: "" });
  }

  renderActions() {
    //@ts-ignore
    const { currentSelection } = this.state;
    const { userDetails, flags } = this.props.context;
    const selectedRows = this.state.selectedRows;

    return (
      <Space size={"large"}>
        <SesameAccountDialog
          onSubmit={(data, callback) => this.handleSubmit(data, callback)}
          requesterEmail={userDetails?.email}
          buttonLabel="Make a new request"
          disableOptions={selectedRows?.length === 1 ? [] : [1, 2]}
        />
        {currentSelection === "1" ? (
          <RemoveExceptionGroup userContext={this.userContext} />
        ) : null}

        {currentSelection === "2" ? (
          <CreateRequest
            userContext={this.userContext}
            selectedRows={selectedRows}
            reset={() => this.resetSelection()}
          />
        ) : null}
        {currentSelection === "3" ? (
          <ResetPassword
            userContext={this.userContext}
            reset={() => this.resetSelection()}
          />
        ) : null}
        {currentSelection === "4-1" ? (
          <SuspensionRequest
            userContext={this.userContext}
            selectedRows={selectedRows}
          />
        ) : null}
        {flags?.sesameAccountsAdminUsers ? (
          <SesameDropdown
            buttonLabel="Alibaba 2.0"
            onSelect={(selection) => this.handleSelection(selection)}
            disabledOptions={[
              ...(selectedRows?.length === 1 &&
              selectedRows?.[0]?.requestType === "New"
                ? []
                : ["2"]),
              ...(selectedRows?.length > 0 ? [] : ["4"]),
            ]}
          />
        ) : null}
      </Space>
    );
  }

  async fetch() {
    const { sortOrder, sortField } = this.state;

    const res = await this.userContext.gpClient.post(
      `/google-gp-v1/sesameAccounts/search`,
      {
        filter: this.filtersToQuery(),
        sort: {
          [sortField || "lastUpdatedTimestamp"]:
            sortOrder === "ascend" ? 1 : -1,
        },
        pagination: {
          pageNumber: this.currentPage || 1,
          resultsPerPage: this.itemsPerPage,
        },
      }
    );
    return { data: res.data } as any;
  }

  pageTitle = "Sesame Tracker";
  title = "Sesame Tracker";
  pageClass = "projectTable";
}

const SesameTable = () => {
  const context = useMercuryContext(),
    breaks = useBreakpoint();

  return (
    <SesameSearch
      id="SesameTracker"
      context={context}
      useLegacyEndpoints={false}
      breaks={breaks as any}
    />
  );
};

export default SesameTable;
