import React from "react";
import { Error as ErrorWarning } from "../../stories/Errors/Error";
import LoadingStar from "../../ui/LoadingStar";
import { useMercuryContext } from "../../user-context";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import DetailsPageHeader from "../projectDetailsPage/DetailsPageHeader";
import BackTopButton from "../../ui/BackTopButton";
import { Collapse, Descriptions, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";

interface State {
  error?: any;
  loading: boolean;
  details?: any;
}

class SesameDetails extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: undefined,
      loading: false,
      details: undefined,
    };
  }

  componentDidMount(): void {
    this.fetch();
  }

  async fetch() {
    this.setState({ loading: true });
    try {
      const { data } = await this.props.context.gpClient.get(
        `/google-gp-v1/sesameAccounts/${this.props.sesameId}`
      );
      this.setState({ details: data, loading: false });
    } catch (e: any) {
      this.setState({ error: e, loading: false });
    }
  }

  reload = async () => {};

  sesameDetails() {
    const { details } = this.state;
    const {
      requesterEmail,
      addedToLDAllowlistSheet,
      addedToNewLinguistTracker,
      affiliation,
      automatedPolyglotFileSharing,
      city,
      contentType,
      fullName,
      includedInEmailCommunications,
      languageCode,
      linguistStatusTCAccess,
      linguistTrainingLevel,
      mercuryAndPolyglotSharingIsEnabled,
      notes,
      personalEmail,
      productLine,
      requestedDate,
      role,
      trainingTeamInstructions,
      requestType,
    } = details;

    const items = [
      {
        key: "1",
        label: "General Information",
        children: (
          <Descriptions bordered={true} column={2} size={"small"}>
            <Descriptions.Item label="Requested Date">
              {requestedDate}
            </Descriptions.Item>
            <Descriptions.Item label="Request Type">
              {requestType}
            </Descriptions.Item>
            <Descriptions.Item label="Requester Email">
              {requesterEmail}
            </Descriptions.Item>
            <Descriptions.Item label="Program/Project Role">
              {role}
            </Descriptions.Item>
            <Descriptions.Item label="Language Code">
              {languageCode}
            </Descriptions.Item>
            <Descriptions.Item label="Affiliation">
              {affiliation}
            </Descriptions.Item>
            <Descriptions.Item label="Product Line">
              {productLine}
            </Descriptions.Item>
            <Descriptions.Item label="Content Type">
              {contentType}
            </Descriptions.Item>
            <Descriptions.Item label="Automated file sharing in Polyglot">
              {automatedPolyglotFileSharing}
            </Descriptions.Item>
            <Descriptions.Item label="Include in HO emails and other email communications">
              {includedInEmailCommunications}
            </Descriptions.Item>
            <Descriptions.Item label="Full Name">{fullName}</Descriptions.Item>
            <Descriptions.Item label="City">{city}</Descriptions.Item>
            <Descriptions.Item label="Personal Email Address">
              {personalEmail}
            </Descriptions.Item>
            <Descriptions.Item label="Additional Instructions for Training Team">
              {trainingTeamInstructions}
            </Descriptions.Item>
            <Descriptions.Item label="Added To LD Allowlist Sheet">
              <Select
                value={addedToLDAllowlistSheet}
                style={{ width: 100 }}
                onChange={() => {}}
              >
                <Select.Option value="Yes">Yes</Select.Option>
                <Select.Option value="No">No</Select.Option>
                <Select.Option value="NA">Na</Select.Option>
              </Select>
            </Descriptions.Item>
            <Descriptions.Item label="Added To New Linguist Tracker">
              <Select
                value={addedToNewLinguistTracker}
                style={{ width: 100 }}
                onChange={() => {}}
              >
                <Select.Option value="Yes">Yes</Select.Option>
                <Select.Option value="No">No</Select.Option>
                <Select.Option value="NA">Na</Select.Option>
              </Select>
            </Descriptions.Item>
            <Descriptions.Item label="Linguist Status TC Access">
              <Select
                value={linguistStatusTCAccess}
                style={{ width: 100 }}
                onChange={() => {}}
              >
                <Select.Option value="Yes">Yes</Select.Option>
                <Select.Option value="No">No</Select.Option>
                <Select.Option value="NA">Na</Select.Option>
              </Select>
            </Descriptions.Item>
            <Descriptions.Item label="Linguist Training Level">
              {linguistTrainingLevel}
            </Descriptions.Item>
            <Descriptions.Item label="Mercury And Polyglot Sharing Is Enabled">
              {mercuryAndPolyglotSharingIsEnabled}
            </Descriptions.Item>
            <Descriptions.Item label="Notes">
              <TextArea value={notes} onChange={() => {}} rows={3} />
            </Descriptions.Item>
          </Descriptions>
        ),
        className: "general-information",
      },
    ];
    return (
      <Collapse
        defaultActiveKey={["1"]}
        items={items}
        style={{ marginBottom: "10px" }}
      />
    );
  }

  render() {
    const { sesameId } = this.props;
    if (this.state.error) {
      // @ts-ignore
      return (
        <ErrorWarning
          reload={this.reload}
          loading={this.state.loading}
          error={JSON.stringify(this.state.error)}
        />
      );
    }

    if (this.state.loading || !this.state.details) {
      return (
        <LoadingStar
          logo={true}
          title={
            <div>
              Retrieving details for project: <br /> {sesameId}{" "}
            </div>
          }
        />
      );
    }

    return (
      <>
        <Helmet>
          <title>{sesameId} - Mercury © RWS</title>
        </Helmet>
        <div className="project-details-content">
          <DetailsPageHeader
            title={"Title"}
            projectId={sesameId}
            shortId={sesameId}
          />
          <div
            style={{ padding: "5px 0 10px 0" }}
            className="project-details-body"
          >
            {this.sesameDetails()}
            <BackTopButton />
          </div>
        </div>
      </>
    );
  }
}

const SesameRequestDetails = function (props: any) {
  const context = useMercuryContext(),
    { id } = useParams();

  return (
    <SesameDetails context={context} location={props.location} sesameId={id} />
  );
};

export default SesameRequestDetails;
