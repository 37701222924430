import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useMercuryContext } from "../user-context";

import { Form } from "antd";
import { API_URL } from "../utils/variables";
import { defaultHeaders } from "../api/APIUtils";

import PageTitle from "../ui/PageTitle";
import AddProductArea from "../components/managePages/product-areas/AddProductArea";
import EditProductArea from "../components/managePages/product-areas/EditProductArea";
import ProductAreasTable from "../components/managePages/product-areas/ProductAreasTable";
import BackTopButton from "../ui/BackTopButton";
import LoadingStar from "../ui/LoadingStar";
import {
  failedNotification,
  successfulNotification,
  infoNotification,
} from "../utils/notifications";

const initialEditingValue = { isEditing: false, editingRow: "" };

const ManageProductAreas = () => {
  const { accessToken } = useMercuryContext(),
    editFormRef = useRef(null),
    [form] = Form.useForm(),
    [editingForm] = Form.useForm(),
    [isLoading, setIsLoading] = useState(false),
    [visibleDrawer, setVisibleDrawer] = useState(false),
    [visibleEditDrawer, setVisibleEditDrawer] = useState(false),
    [switchValue, setSwitchValue] = useState(false),
    [productAreas, setProductAreas] = useState([]),
    [sortedInfo, setSortedInfo] = useState(""),
    [editing, setEditing] = useState(initialEditingValue),
    [total, setTotal] = useState(0),
    [pagination, setPagination] = useState({ currentPage: 1, pageSize: 20 });

  // fetch all products
  const fetchProductAreaList = async (token) => {
    const endpoint = `${API_URL}/core-gpms-v1/productarea`;
    try {
      setIsLoading(true);
      await axios
        .get(endpoint, {
          headers: defaultHeaders(token),
        })
        .then((result) => {
          setProductAreas(result.data);
          setTotal(result.data.length);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error('Error fetching product area list: ', error);
    }
    setIsLoading(false);
  };

  // add new product area
  const addProductArea = async (token, body) => {
    const endpoint = `${API_URL}/core-gpms-v1/productarea`;
    try {
      setEditing({ ...editing, isEditing: true });
      await axios({
        method: "post",
        url: endpoint,
        headers: defaultHeaders(token),
        data: body,
      }).then((result) => {
        if (result.data.error) {
          failedNotification(result.data.error, result.data.message);
        } else {
          infoNotification(
            `Product Area - ${result.data.title} was added.`,
            "Please refresh the page if the new product area is not visible."
          );
        }
      });
    } catch (error) {
      console.error('Error adding product area: ', error);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // update a single product
  const updateProductArea = async (token, productId, newData) => {
    const endpoint = `${API_URL}/core-gpms-v1/productarea/${productId}`;
    try {
      setEditing({ ...editing, isEditing: true });
      await axios({
        method: "patch",
        url: endpoint,
        headers: defaultHeaders(token),
        data: newData,
      }).then((result) => {
        successfulNotification(
          `Product Area - ${result.data.title} was updated`,
          `Please refresh your page if the update is not visible.`
        );
      });
    } catch (error) {
      failedNotification(error.name, error.message);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // on load
  useEffect(() => {
    accessToken && fetchProductAreaList(accessToken);
  }, [accessToken]);

  const defaultValues = {
    titleValue: editing.editingRow.title,
    activeValue: editing.editingRow.active,
  };

  // fix to update initial values of edit product form
  useEffect(() => {
    if (editFormRef.current) {
      editingForm.setFieldsValue(defaultValues);
    }
  }, [editFormRef, editingForm, defaultValues]);

  // refresh button
  const handleRefresh = () => {
    //fetch details again
    accessToken && fetchProductAreaList(accessToken);
  };
  // Add new product area START
  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const closeDrawer = () => {
    setVisibleDrawer(false);
    form.resetFields();
    setSwitchValue(false);
  };

  // handle change of active switch button
  function handleChangeActive(checked) {
    setSwitchValue(checked);
  }

  // on save of add new product area
  const onFinishNewProduct = (values) => {
    // generates the new product area object
    const newValues = {
      title: values.titleValue,
      isActive: switchValue,
    };
    addProductArea(accessToken, newValues);
    closeDrawer();
  };
  // Add new product area END

  // Update Product START

  function showEditDrawer() {
    setVisibleEditDrawer(true);
  }

  const closeEditDrawer = (event) => {
    setVisibleEditDrawer(false);
    form.resetFields();
    event !== undefined && setEditing(initialEditingValue);
  };

  // on save of edit product
  const onFinishEditing = (values) => {
    const { activeValue, titleValue } = values;

    const newData = {
      isActive: activeValue.toString(),
      title: titleValue,
    };

    // function to call api endpoint that will update
    const editingProductId = editing.editingRow.key;

    updateProductArea(accessToken, editingProductId, newData);

    closeEditDrawer();
  };
  // Update product END

  // handle change table
  const handleChangeTable = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
    setPagination({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  // handle click table edit button
  const handleClickEdit = (record) => {
    setEditing({ ...editing, isEditing: true, editingRow: record });
    showEditDrawer();
  };

  return (
    <div className="manage-page manage-product-areas-page" style={{padding: '0 10px'}}>
      <Helmet>
        <title>Manage Product Areas - Mercury © RWS</title>
      </Helmet>
      {isLoading ? (
        <LoadingStar />
      ) : (
        <>
          <PageTitle title="Manage Product Areas" />
          <AddProductArea
            handlers={{
              showDrawer,
              closeDrawer,
              onFinishNewProduct,
              handleChangeActive,
              handleRefresh,
            }}
            values={{ form, visibleDrawer, switchValue }}
          />
          <EditProductArea
            handlers={{
              closeEditDrawer,
              onFinishEditing,
            }}
            values={{
              editingForm,
              visibleEditDrawer,
              editing,
              editFormRef,
            }}
          />
          <ProductAreasTable
            values={{ productAreas, sortedInfo, editing, total, pagination }}
            handlers={{
              handleChangeTable,
              handleClickEdit,
            }}
          />
          <BackTopButton />
        </>
      )}
    </div>
  );
};

export default ManageProductAreas;
