import React from "react";
import { Typography, Col } from "antd";

const Total = ({ number, text }) => {
  const { Text } = Typography;
  return (
    <Col>
      <Text style={{userSelect: "none"}}>Total {text}:</Text>
      <Text
        strong
        type="success"
        style={{ margin: "0 10px 0 5px", userSelect: "none" }}
      >
        {number}
      </Text>
    </Col>
  );
};

export default Total;
