import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import "./side-admin-menu.scss";

const SideAdminMenu = ({ items }) => {
  const { Sider } = Layout,
    location = useLocation(),
    currentLocation = location ? location.pathname : "",
    [currentItem, setCurrentItem] = useState([]),
    [openKeys, setOpenKeys] = useState([]);

  useEffect(() => {
    const matchedItem = [];
    const submenuKeys = [];

    items.forEach((item) => {
      if (item.path) {
        if (item.path === currentLocation) {
          matchedItem.push(item);
        }
      } else {
        item.submenu.forEach((value) => {
          if (value.path === currentLocation) {
            matchedItem.push(value);
          }

          submenuKeys.push(value.parentId.toString());
        });
      }
    });

    setCurrentItem(matchedItem);
    // fix to extract unique strings
    setOpenKeys(submenuKeys.filter((v, i, a) => a.indexOf(v) === i));
  }, [items, currentLocation]);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const menuItems =
    items.length &&
    items.map((item) => {
      if (item.submenu) {
        return getItem(
          item.title,
          item.id,
          undefined,
          item.submenu.map((sub) =>
            getItem(<Link to={sub.path}>{sub.title}</Link>, sub.id)
          )
        );
      } else {
        return getItem(<Link to={item.path}>{item.title}</Link>, item.id);
      }
    });

  const handleChangeSubMenu = (openKeys) => {
    setOpenKeys(openKeys);
  };

  return currentItem.length ? (
    <Sider theme="light" className="aside-admin-menu">
      <Menu
        mode={"inline"}
        selectedKeys={currentItem[0].id.toString()}
        active={"true"}
        openKeys={openKeys}
        onOpenChange={handleChangeSubMenu}
        items={menuItems}
      />
    </Sider>
  ) : (
    ""
  );
};

export default SideAdminMenu;
