import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMercuryContext } from "../user-context";
import { Layout, Drawer, Button } from "antd";
import logo from "../assets/images/RWS_logo-colour.svg";
import ProgressLoader from "../components/headerComponents/ProgressLoader";
// import CustomerApplicationSelection from "../components/headerComponents/CustomerApplicationSelection";

import LeftMenu from "../components/headerComponents/LeftMenu";
import RightMenu from "../components/headerComponents/RightMenu";


import "./header.scss";
import {useScrollMonitor} from "../Components";

function Header({ adminMenuData }:any) {
  const
      { Header } = Layout,
      { accessToken } = useMercuryContext(),
      [drawOpen, setDrawOpen] = useState(false),
      position = useScrollMonitor();

  let
      height = 64,
      className = "main-header"

  if ( position < 64 && position >= 40 ) {
    height = 64 - ( position -40 )
  }
  else if ( position < 64 ) {
    height = 64
  }
  else {
    height = 40;
  }

  if ( height <= 47 ) {
    className = "main-header slimmer";
  }


  function toggleDrawer() {
    setDrawOpen(!drawOpen);
  }

  const handleCopyToken = (e:any) => {
    // only on double click
    if (e.detail === 2) {
      navigator.clipboard.writeText(accessToken);
    }
  };

  return (
    <Header id={"main-header"} className={className}>
      <nav className="menuBar">
        <div className="leftContainer">
          <div className="logoContainer">
            <Link to="/">
              <img src={logo} className="logo logo-lg" alt="logo" />
            </Link>
            <div className="product-name">
              <h1 onClick={handleCopyToken}>Mercury</h1>
            </div>
          </div>
          {/* // TODO: enable customer applications once functionality is being used */}
          {/* <CustomerApplicationSelection /> */}
        </div>
        <div className="menuCon">
          <div className="leftMenu">
            <LeftMenu {...{adminMenuData}} />
          </div>
          <div className="rightMenu">
            <RightMenu {...{ adminMenuData }} />
          </div>
          { !drawOpen && <Button className="barsMenu" type="primary" onClick={toggleDrawer}>
            <span className="barsBtn"/>
            <span className="barsBtn"/>
            <span className="barsBtn"/>
          </Button> }
          <Drawer
            title="Menu"
            placement="right"
            closable={true}
            onClose={toggleDrawer}
            open={drawOpen}
            width={350}
            className="header-drawer"
          >
            <LeftMenu mobile={true} {...{ adminMenuData }} />
            <RightMenu drawOpen {...{ adminMenuData }} />
          </Drawer>
          <div className="progressCon">
            <ProgressLoader />
          </div>
        </div>
      </nav>
    </Header>
  );
}

export default Header;
