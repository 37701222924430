import React from "react";
import {useMercuryContext} from "../user-context";
import { DateTime } from "luxon";
import {useFlags} from "launchdarkly-react-client-sdk";
import "./HoldingPage.scss";

function renderHoldingPage( startTime:string, endTime:string ) {

    const
        start = new Date( startTime ),
        now = new Date(),
        end = new Date( endTime );

    if ( now.getTime() >= start.getTime() ) {
        return (
            <div className={"holdingPageContainer"}>
                <h1>We'll be right with you...</h1>
                <p>We're currently performing maintenance on the application which requires us to take it offline for a while.</p>
                <p>The app should be back online {DateTime.fromJSDate( end ). toRelative()}.</p>
            </div>
        )
    }

    return null;
}

export default function HoldingPage( props:any ) {
    const
        { holdingPage } = props.flags;


    if ( holdingPage ) {
        try {
            const { startTime, endTime } = JSON.parse(holdingPage);
            return renderHoldingPage( startTime, endTime );
        }
        catch ( e ) {
            console.error( e );
            return null;
        }
    }
    return null;
}
