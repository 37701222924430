import React from "react";
import {FiniteListFilter, FiniteListProps} from "../../../stories/FiniteListFilter/FiniteListFilter";

export default (props: FiniteListProps) => {
    return <FiniteListFilter
        {...props}
        data={[
            {
                key: 0,
                title: "PENDING",
                value: 'PENDING'
            },
            {
                key: 1,
                title: "QUEUED",
                value: 'QUEUED'
            },
            {
                key: 2,
                title: "COMPLETE",
                value: 'COMPLETE'
            }
        ]}/>
}
