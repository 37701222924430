import React, { useState, FunctionComponent } from "react";
import { Row, Col, Typography } from "antd";
import Logo from "./Logo";
import LogoGIF from "./loading.gif";

export interface LoadingStarProps {
    animate?: boolean;
    logo?: boolean;
    title?: string;
    customStyle?: any;
}

export const LoadingStar: FunctionComponent<LoadingStarProps> = (props) => {
    const { Title } = Typography;
    return (
        <Row
            className={"loading-star"}
            justify="center"
            style={{
                textAlign: "center",
                height: "calc(100vh - 162px)",
                display: "flex",
                alignItems: "center",
                ...props.customStyle
            }}
        >
            <Col span={8}>
                {
                    props.logo
                        ? <Logo animate={props.animate} />
                        : <img
                            src={LogoGIF}
                            className="logo"
                            alt="logo"
                            style={{ minWidth: "45px", minHeight: "45px" }}
                        />
                }
                {
                    props.title && <Title level={5} style={{ fontWeight: "400", paddingTop: "10px" }}>
                        {props.title}
                    </Title>
                }
            </Col>
        </Row>
    );
}

export default LoadingStar;
