import {
  Descriptions,
  Collapse,
  CollapseProps,
} from "antd";
import { NumericFormat as NumberFormat } from "react-number-format";
import TimezoneConverter from "../../timezone-converter";
import { capitalizeString } from "../../utils/helpers";


export default function BatchDetails({ rowData }: any) {

  const items: CollapseProps['items'] = 
  [
    {
      key: '0',
      label: 'Batch details',
      children: <Descriptions bordered={true} column={3} size={"small"}>
        <Descriptions.Item label="Category">
          {rowData?.category ? capitalizeString(rowData?.category) : ""}
        </Descriptions.Item>
        <Descriptions.Item label="Plps #">{rowData?.plps}</Descriptions.Item>
        <Descriptions.Item label="Projects #">
          {rowData?.projects}
        </Descriptions.Item>
        <Descriptions.Item label="Invoice Amount">
          {rowData?.category?.toUpperCase() === "ADMIN" ? (
            "N/A"
          ) : (
            <NumberFormat
              value={rowData?.totalCharge}
              decimalScale={4}
              fixedDecimalScale={true}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Adjustment">
          {rowData?.adjustmentAmount?.toFixed(4)}
        </Descriptions.Item>
        <Descriptions.Item label="Adjusted Amount">
          {rowData?.category?.toUpperCase() === "ADMIN" ? (
            "N/A"
          ) : (
            <NumberFormat
              value={rowData?.adjustedAmount}
              decimalScale={4}
              fixedDecimalScale={true}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Modified">
          <TimezoneConverter date={rowData?.updatedAt} />
        </Descriptions.Item>
        <Descriptions.Item label="Description">
          {rowData?.description}
        </Descriptions.Item>
      </Descriptions>,
    },
  ];
  
  return (
    <Collapse
      items={items}
      defaultActiveKey={["0"]}
      style={{ marginBottom: "20px" }}
      className="batch-details"
    />
  )
}
