import React from "react";
import {
    DateFilter,
    ListFilter,
     TextFilter
} from "../../Components";
import {useMercuryContext} from "../../user-context";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {Button, Progress, Space, Table, Tooltip} from "antd"
import {Link, Route, Routes, useLocation, useParams} from "react-router-dom";
import {BlockOutlined, EditOutlined, InfoCircleOutlined} from "@ant-design/icons";
import TimezoneConverter from "../../timezone-converter";
import ProductFilter from "../Product.filter";
import LanguageCodeFilter from "../LanguageCode.filter";

import PolyglotLogo from '../../stories/assets/polyglot-logo.svg'
import TableWSAbstract from "../../stories/Table/TableWS.abstract";
import {NumericFormat as NumberFormat} from "react-number-format";
import Text from "antd/es/typography/Text";
import DownloadButton from "../../components/actions/adjustments/DownloadButton";
import DeleteButton from "../../components/actions/adjustments/DeleteButton";
import CreateAdjustmentButton from "./CreateButton";
import NotFoundPage from "../../routes/NotFoundPage";
import AdjustmentDetailsPage from "../../routes/AdjustmentDetailsPage";
import AdjStatusFilter from "../AdjStatus.filter";
import OwnerFilter from "../Owner.filter";
import ProductAreaFilter from "../ProductArea.filter";
import EmpowerCCFilter from "../EmpowerCC.filter";


import "./Adjustments.scss";
import {Error as ErrorWarning} from "../../stories/Errors/Error";
import BaseTable from "../../stories/Table/BaseTable";
import {Key} from "antd/es/table/interface";
import ButtonGroup from "antd/es/button/button-group";
import AssignInvoiceButton from "../../components/actions/adjustments/AssignInvoiceButton";
import InvoiceButton from "./InvoiceButton";
import AccrualButton from "./AccrueButton";
import AdjCategoryFilter from "../AdjCategory.filter";
import AdjTypeFilter from "../AdjType.filter";
import LoadingStar from "../../stories/Loaders/LoadingStar";
import EmptyOverlay from "../../stories/Table/EmptyOverlay";
import {TableProps} from "antd/lib/table";
import {TableState} from "../../stories/Table/TableBase";
import uuid from "uuid-browser";
import RecalculateButton from "./Recalculate";

function progressBoxes( error:number, pending: number, complete: number, total: number ) {
    const
        spans = [];
    if ( error ) {
        spans.push(
            <>
                <span className={"errored"}>{error}</span>
                <span>/</span>
            </>
        )
    }

    if ( complete === total ) {
        return <span className={"success"}>{complete}</span>
    }

    if ( pending ) {
        spans.push(
                    <Progress type="circle" percent={Math.round((complete / total) * 100) as any} size={55} format={(percent) => {
                        return <Tooltip
                                    title={`Pending: ${complete}/${total}`}
                                    placement="left"
                                    >{percent === 0 ? 'Waiting' : `${percent}%`}</Tooltip>
                    }} />
        )
    }

    if ( !spans.length ) {
        return <span className={"muted"}>-</span>
    }

    return spans

}

function errorProgressBox( mercury:number, google:number ) {

    if ( !mercury && !google ) {
        return <span className={"statusTotal"}>
            <span>-</span>
        </span>
    }

    if ( !mercury ) {
        return <span className={"statusTotal errored"}><span>{google}</span></span>
    }

    return <span className={"statusTotal errored"}>
        <span>{mercury}</span>
        <span>/</span>
        <span>{google}</span>
    </span>

}

function ProgressColumn(text: string, record: Record<any, any>) {
    const
        {statusMap} = record,
        errored = statusMap?.ERRORED+statusMap?.GOOGLE_ERRORED,
        boxes = [
            <div key={"errored"} className="box box--errored">
                <h4 className={errored ? "errored" : "disabled"}>Errored</h4>
                {errorProgressBox(statusMap?.ERRORED,statusMap?.GOOGLE_ERRORED)}
            </div>,
            <div key={"draft"} className="box box--draft">
                <h4>Draft</h4>
                <span className={"statusTotal"}>
                    <span>{statusMap?.DRAFT ? statusMap?.DRAFT : "-" }</span>
                </span>
            </div>,
            <div key={"accrued"} className="box box--accrued">
                <h4>Accrual</h4>
                <span className={"statusTotal"}>
                    {progressBoxes(statusMap?.ACCRUAL_FAILED, statusMap?.ACCRUAL_PENDING, statusMap?.ACCRUED, record?.plps)}
                </span>
            </div>,
            <div key={"invoiced"} className="box box--invoiced">
                <h4>Invoice</h4>
                <span className={"statusTotal"}>
                    {progressBoxes(statusMap?.INVOICE_FAILED, statusMap?.PENDING_INVOICE, statusMap?.INVOICED, record?.plps)}
                </span>
            </div>
        ]


    return <div className={"progressBoxes"}>{boxes}</div>
}

class ADJSearch extends TableWSAbstract<any> {
    defaultSortField = "createdAt";
    FilterComponents = [
        {
            id: "projectId",
            name: "projectShortId",
            title: "Project ID",
            component: ListFilter,
            active: true
        },
        {
            id: "category",
            name: "category",
            title: "Category",
            component: AdjCategoryFilter,
            active: true
        },
        {
            id: "product",
            name: "products",
            title: "Product",
            component: ProductFilter,
            active: true
        },
        {
            id: "productArea",
            name: "productAreas",
            title: "Product Area",
            component: ProductAreaFilter,
            active: true
        },
        {
            id: "targetLanguageCode",
            name: "targetLanguageCode",
            title: "Target Language",
            component: LanguageCodeFilter,
            active: true
        },
        {
            id: "adjStatus",
            name: "status",
            title: "Status",
            component: AdjStatusFilter,
            active: true
        },
        {
            id: "createdAt",
            name: "createdAt",
            title: "Created Date",
            component: DateFilter,
            active: true
        },
        {
            id: "owner",
            name: "creator",
            title: "Created by",
            component: OwnerFilter
        },
        {
            id: "invoiceName",
            name: "invoiceName",
            title: "Invoice name",
            component: TextFilter
        },
        {
            id: "name",
            name: "name",
            title: "Ref",
            component: TextFilter
        },
        {
            id: "batchId",
            name: "batchId",
            title: "Batch ID",
            component: ListFilter,
            active: true
        },
        {
            id: "type",
            name: "type",
            title: "Adjustment Type",
            component: AdjTypeFilter,
            active: true
        },
        {
            id: "poNumber",
            name: "PONumber",
            title: "PO Number",
            component: ListFilter,
            active: true
        },
        {
            id: "costCode",
            name: "costCodes",
            title: "Cost Code",
            component: EmpowerCCFilter
        },
    ];
    columns = [
        {
            title: "Batch #",
            id: "_id",
            dataIndex: "_id",
            render: (id:any, record:any) => {
                return <Link
                    to={`/adjustments/${id}`}
                    id={id}
                    className={"project-link"}
                >
                    <BlockOutlined className="project-link-icon" />
                    {record._id?.slice( -8 )}
                </Link>
            }
        },
        {
            title: "Category",
            id: "category",
            dataIndex: "category",
            sorter: true,
            render: (id:string, record:any) => {
                return record.category?.toUpperCase();
            }

        },
        {
            title: "Type",
            id: "type",
            dataIndex: "type",
            sorter: true

        },
        {
            title: "Amount",
            id: "amount",
            dataIndex: "amount",
            sorter: true,
            render: (text:string, record:any) => {
                if (
                    record.category.toUpperCase() === "PENALTY" ||
                    record.category.toUpperCase() === "ADMIN"
                ) {
                    return "N/A"
                }

                if ( record.type === "fixed" ) {
                    return text;
                }

                return `${text} %`;
            }

        },
        {
            title: "Ref",
            fixed: "left",
            id: "name",
            dataIndex: "name",
            sorter: true,
            width: 300,
        },
        {
            title: "Description",
            // fixed: this.breaks.lg ? "left" : undefined,
            id: "description",
            dataIndex: "description",
            sorter: true,
            // align: "left",
            width: 400,
        },
        {
            title: "PLPS",
            id: "noPlps",
            sorter: true,
            width: 80,
            dataIndex: "plps"
            // align: "center",
        },
        {
            title: "Projects",
            id: "noProjects",
            sorter: true,
            width: 90,
            dataIndex: "projects"
            // align: "center",
        },
        // {
        //     title: "Progress",
        //     id: "progress",
        //     sorter: false,
        //     width: 90,
        //     dataIndex: "progress",
        //     render: ( text:any, record:any) => {
        //         const
        //             { statusMap, plps } = record,
        //             errored = [
        //                 statusMap?.ERRORED,
        //                 statusMap?.GOOGLE_ERRORED,
        //                 statusMap?.ACCRUAL_FAILED,
        //                 statusMap?.INVOICE_FAILED
        //             ].reduce((accumulator, currentValue) => {
        //                 return accumulator + currentValue
        //             },0),
        //             complete = statusMap?.INVOICED,
        //             percentage = statusMap?.INVOICED/plps;
        //
        //         return <div style={{textAlign: "center"}}>
        //             <Progress type="circle" percent={Math.ceil(percentage*100) as any} size={60} status={errored ? "exception" : undefined} format={(percent) => {
        //                 if ( percent === 100.00 )  {
        //                     return "Done"
        //                 }
        //                 return `${percent}%`
        //             }} />
        //         </div>
        //     }
        // },
        {
            title: "Status breakdown",
            id: "statusBreakdown",
            sorter: false,
            width: 260,
            dataIndex: "counts",
            render: ProgressColumn
        },
        {
            title: "Invoice amount",
            id: "unadjustedCharge",
            dataIndex: "totalCharge",
            width: 140,
            sorter: true,
            // align: "right",
            render: (amount:number, record:any) => {

                if ( record.category.toUpperCase() === "ADMIN" ) {
                    return "N/A"
                }

                return <NumberFormat
                    value={amount}
                    decimalScale={4}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                />
            }
        },
        {
            title: "Adjustment",
            id: "adjustment",
            dataIndex: "adjustmentAmount",
            sorter: true,
            width: 140,
            render: (text:any, record:any) => {

                if ( text === undefined ) {
                    return "CORRUPTED"
                }

                if (
                    record.type === "fixed" || ["PENALTY", "ADMIN"].indexOf(record.category.toUpperCase()) !== -1) {
                    return <NumberFormat
                        value={text}
                        decimalScale={4}
                        fixedDecimalScale={true}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                    />;
                }
                return <>
                  <NumberFormat
                      value={text}
                      decimalScale={4}
                      fixedDecimalScale={true}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                  />
                  <span> ({record.amount}%)</span>
                </>
                // return "test"
            },
            // align: "right"
        },
        {
            title: "Adjusted amount",
            id: "adjustmentAmount",
            dataIndex: "adjustedAmount",
            width: 140,
            sorter: true,
            // align: "right",
            render: (amount:number, record:any) => {

                if ( record.category.toUpperCase() === "ADMIN" ) {
                    return "N/A"
                }
                return <NumberFormat
                    value={amount}
                    decimalScale={4}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                />;
            },
        },
        {
            title: "Status",
            id: "status",
            dataIndex: "status",
            width: 160,
            sorter: true,
            // align: "right",
            render: (text:any, record:any) => {

                if ( record.adjustmentAmount === undefined ) {
                    return <Tooltip
                        title={"This batch contains corrupted, or inconsolable data."}
                        placement="right"
                        color={"var(--red)"}
                    >
                        <Space>
                        CORRUPTED
                        <InfoCircleOutlined
                            style={{ color: "var(--red)", marginLeft: "3px" }}
                        />
                        </Space>

                    </Tooltip>
                }

                if ( !text )  {
                    return "ERRORED"
                }

                return text?.toUpperCase();
            }
        },
        {
            title: "Invoice name",
            id: "invoiceName",
            dataIndex: "invoiceName",
            width: 140,
            sorter: true,
            // align: "right",
            render: (text:any, record:any) => {
                if ( !text || !text.length ) {
                    return "NOT SET"
                }
                return text
            }
        },
        {
            title: "Modified",
            id: "updatedAt",
            dataIndex: "updatedAt",
            // sorter: true, <<< Not available due to nested field problem on docdb
            width: 180,
            sorter: true,
            render: (text:any, record:any) => {
                return (
                    <Text>
                        <TimezoneConverter date={text} />
                    </Text>
                );
            },
            // align: "right"
        },
        {
            title: "Created",
            id: "createdAt",
            dataIndex: "createdAt",
            // sorter: true, <<< Not available due to nested field problem on docdb
            width: 180,
            sorter: true,
            render: (text:any, record:any) => {
                return (
                    <Text>
                        <TimezoneConverter date={text} />
                    </Text>
                );
            },
            // align: "right"
        },
        {
            title: "Actions",
            id: "download",
            width: 150,
            dataIndex: "download",
            sorter: false,
            // align: "left",
            render: (text: any, record: any) => {
                return <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <ButtonGroup>
                        <DownloadButton record={record} />
                        {record?.status?.toUpperCase() !== "INVOICED" &&
                            <Link to={`/adjustments/${record.id}`} id={record.id} className={"project-link"}>
                                <Button>
                                    <EditOutlined style={{ marginRight: '3px' }} />
                                </Button>
                            </Link>
                        }
                        <DeleteButton record={record} reload={this.getData} />
                    </ButtonGroup>
                </div>
            },
        }
    ];

    renderActions() {

        return (
            <Space size={"large"}>
                <CreateAdjustmentButton reload={this.getData} />
                <ButtonGroup>
                    {
                        this.props.context.flags.showAdjRecalc && <RecalculateButton selectedRows={this.state.selectedRows ?? []} reload={this.getData}/>
                    }
                    <AccrualButton selectedRows={this.state.selectedRows ?? []} reload={this.getData}/>
                    <AssignInvoiceButton selectedRows={this.state.selectedRows ?? []} />
                    <InvoiceButton selectedRows={this.state.selectedRows ?? []} reload={this.getData}/>
                </ButtonGroup>
            </Space>
        );
    }

    rowClassName = (record: any): string => {
        let className = "";
        return `${className} ${this.state.changedIds?.has(record._id) ? "rowUpdated" : "" }`
    }

    defaultDisabledColumns = []

    pageTitle = "Invoicing adjustments - Mercury";
    title = "Adjustments";
    pageClass = "AdjTable"
    public rowMap:Map<string, any> = new Map();

    public floodPrevention = () => {
        clearTimeout(this.floodTimer);
        this.floodTimer = undefined;
        this.setState({ rowData: Array.from( this.rowMap.values() ), changedIds: this.state.changedIds } );
    }

    public floodTimer?:number;

    async componentDidMount(): Promise<void> {

        await super.componentDidMount();

        const
            socket = this.props.context.gpSocket.adj;

        socket.on( "tail", (doc:any) => {

            this.rowMap.set( doc._id, doc );
            this.state.changedIds?.add( doc._id )

            const { rowData } = this.state;

            rowData.unshift( doc );

            this.rowMap.clear();

            rowData.forEach( ( row:any ) => this.rowMap.set( row._id, row ))

            this.setState({ rowData: Array.from( this.rowMap.values() ), changedIds: this.state.changedIds } );
        } )
    }

    componentDidUpdate(prevProps: TableProps<any>, prevState: TableState<any>) {
        super.componentDidUpdate(prevProps as any, prevState);
        if ( prevState.reqId !== this.state.reqId ) {
            const socket = this.props.context.gpSocket.adj;
            socket.off( `search:row:${prevState.reqId}` )
            socket.off( `search:meta:${prevState.reqId}` )
            socket.on( `search:row:${this.state.reqId}`, ( row:any ) => {

                const rows = Array.isArray( row ) ? row : [ row ];

                rows.forEach( ( item:any ) => {
                    this.rowMap.set( item._id, item );
                    socket.on( item._id, ( doc:any ) => {
                        this.state.changedIds?.add( doc._id )
                        if ( this.rowMap.has( doc._id ) ) {
                            this.rowMap.set( doc._id, doc );
                            if ( this.floodTimer ) {
                                clearTimeout(this.floodTimer);
                                this.floodTimer = undefined;
                            }
                            this.floodTimer = setTimeout( () => this.floodPrevention(), 500 ) as any;
                        }
                    })
                } )

                // socket.emit( "bind:ids", Array.from( this.rowMap.keys() ) )

                this.setState({ loading:false, changedIds: new Set(), rowData: Array.from( this.rowMap.values() ) } );
            } );

            socket.on( `search:meta:${this.state.reqId}`, ( meta:any ) => {
                this.setState({selectedRows: [], currentPage: meta.page, meta: meta })
            }  )
        }
    }

    componentWillUnmount() {
        const socket = this.props.context.gpSocket.adj;
        super.componentWillUnmount();
        socket.off( `search:row:${this.state.reqId}` )
        socket.off( `search:meta:${this.state.reqId}` )
        socket.off( `tail` );
        Array.from(( this.rowMap.keys() ) ).forEach( key => {
            socket.off( key );
        } );
    }

    getData = async (): Promise<void> => {

        const
            socket = this.userContext.gpSocket.adj,
            {sortOrder, sortField, currentPage, itemsPerPage, rowData} = this.state,
            ids = rowData.map( row => row._id);

        this.rowMap.clear();

        socket.emit( "unbind:ids",  ids );
        socket.emit( "search:abort" );

        ids.forEach( id => {
            socket.off( id );
        })

        this.setState( { reqId: uuid(), loading: true }, () => {
            const
                filters = this.filtersToQuery(),
                sort = {
                    [sortField ?? "importedTimestamp"]: sortOrder === "ascend" ? 1 : -1
                };

            if (
                sort.importedTimestamp === -1 &&
                Object.keys( filters ).length === 0 ||
                (
                    Object.keys( filters ).length === 1 &&
                    filters.importedTimestamp?.length > 1 &&
                    new Date( filters.importedTimestamp[1] ) > new Date()
                )
            ) {
                socket.emit( "tail", () => console.info( "tailing for new projects activated..."))
            }
            else {
                socket.emit( "untail", () => console.info( "tailing de-activated"))
            }

            socket.emit(`search`, {
                filter: this.filtersToQuery(),
                // filter: this.filters,
                sort: {
                    [sortField ?? "created_at"]: sortOrder === "ascend" ? 1 : -1
                },
                pagination: {
                    pageNumber: currentPage || 1,
                    resultsPerPage: itemsPerPage
                },
                reqId: this.state.reqId
            }, () => {
                this.setState({ loading: false, changedIds: new Set() } )
            });
        })
    }

    send(): Promise<void> {
        return Promise.resolve(undefined);
    }

    renderTable(actions?: any) {

        if (this.state.error) {
            return <ErrorWarning resetFilters={this.clear} reload={this.reload} error={this.state.error.toString()}/>
        }

        const
            { rowData } = this.state,
            cols = this.columnMapping()
                .map((item) => this.column(item as any))
        // .sort(this.sortById);

        return <BaseTable
            ref={this.tableRef}
            className='new-projects-table'
            dataSource={rowData}
            columns={ cols }
            onColumnSort={(sorter:any) => this.sort({}, {}, sorter, {} as any)}
            // @ts-ignore
            sortBy={{ key: this.state.sortField, order: this.state.sortOrder === 'ascend' ? 'asc' : this.state.sortOrder === 'descend' ? 'desc' : this.state.sortOrder}}
            loading={this.state.loading}
            rowClassName={this.rowClassName}
            rowSelection={actions && {
                selectedRowKeys: this.state.selectedRows.map(r => r.key) as Key[],
                onSelectAll: this.selectAll,
                onSelect: this.select,
                // fixed: true
            }}
            rowHeight={80}
            // disabled={this.state.loading}
            enabledRowSelection={actions ? true : false}
            itemsPerPage={this.state.itemsPerPage}
            showFilters={this.state.showFilters}
            updateColumnWidth={this.updateColumnWidth}
            CellRenderer={this.props.CellRender}
            hasSidebar={this.props.hasSidebar}
            filtersRef={this.props.filtersRef}
            onScroll={( args:any ) => {
                const
                    filtersTop = this.filtersRef.current?.getBoundingClientRect().bottom;

                if ( filtersTop <= 30 && filtersTop >= 40 ) {
                    return;
                }

                window.scrollTo( 0, args.scrollTop );
            }}

            overscanRowCountnumberdefaults={200}
            overlayRenderer={
                () =>
                    this.state.loading ? <div className={"loading-table-wrapper"}><LoadingStar logo={true} animate={true} title={""} /></div> : null
            }
            emptyRenderer={<EmptyOverlay reset={this.clear} loading={this.state.loading} />}
        />
    }

}

const ADJSearchComponent = () => {

    const
        context = useMercuryContext(),
        location = useLocation(),
        breaks = useBreakpoint();

    return(
        <Routes location={location}>
            <Route
                path="/"
                element={<ADJSearch id={"Adjs"} displayHideFilters={true} context={context as any} useLegacyEndpoints={false} breaks={breaks as any}/>}
            />
            <Route
                path="/:adj"
                element={<AdjustmentDetailsPage key={location.key}/>}
            />
            <Route
                path='*'
                element={<NotFoundPage />}
            />
        </Routes>
    )
}

export default ADJSearchComponent;
