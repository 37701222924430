import { Modal } from "antd";
import { useState } from "react";
import { failedNotification, successfulNotification } from "../../utils/notifications";

const RemoveExceptionGroup = ({ userContext }: { userContext: any }) => {
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        try {
            await userContext.gpClient.post(
                `/google-gp-v1/sesameAccounts/removeExceptionGroup`);
            setLoading(false);
            handleCancel();
            successfulNotification("Success", `Account removed from Exception group.`)
        } catch (error) {
            setLoading(false);
            failedNotification("Error", `Account failed to remove from Exception group`)
        }
    };
    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <Modal
            title="Remove Exception Group"
            open={visible}
            onOk={handleSubmit}
            onCancel={handleCancel}
            okButtonProps={{ disabled: loading }}
            okText="Ok"
            confirmLoading={loading}
        >
        </Modal>
    );
};

export default RemoveExceptionGroup;