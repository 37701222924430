import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { defaultHeaders } from "../api/APIUtils";
import { getFileExtension, removeSpecifiedExtension } from "../utils/helpers";
import * as JSZip from "jszip";
import { v4 as uuidv4 } from "uuid";

import {
  Typography,
  Upload,
  Modal,
  Button,
  Checkbox,
  Table,
  Spin,
  Dropdown,
  Tooltip,
} from "antd";

import {
  CloudUploadOutlined,
  UploadOutlined,
  DownOutlined,
  PaperClipOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import {
  successfulNotification,
  failedNotification,
  infoNotification,
} from "../utils/notifications";
import { useNavigate } from "react-router-dom";

const UploadBtn = (props) => {
  let navigate = useNavigate();

  const redirectToProject = () => {
    if (navigate) {
      navigate(`/project/${props.shortId}?type=shortId`);
    }
  };
  
  const [filesList, setFilesList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState({ hasError: false, errorMsg: "" });
  const [dtpFiles, setDtpFiles] = useState([]);
  const [loadingDtpFiles, setLoadingDtpFiles] = useState(false);
  const [projectLink] = useState(
    <div>
      <Button
        type="link"
        onClick={redirectToProject}
        style={{ padding: "0", fontWeight: "bold" }}
      >
        View project details ({props.shortId})
      </Button>
    </div>
  );

  const { Text, Link } = Typography;
  const { Dragger } = Upload;

  const getDtpFiles = useCallback(async () => {
    try {
      const projectEndpoint = `${props.baseURI}/projects/${props.shortId}?type=shortId`;

      setLoadingDtpFiles(true);

      await axios
        .get(projectEndpoint, {
          headers: defaultHeaders(props.accessToken),
        })
        .then((files) => {
          // props.reload();
          const allFiles = files.data.files;
          setDtpFiles(allFiles);
        });
      setLoadingDtpFiles(false);
    } catch (error) {
      console.error(error);
      failedNotification(
        "Failed to load DTP Files",
        "Please refresh and try again."
      );
    }
  }, [props.accessToken, props.baseURI, props.shortId]);

  useEffect(() => {
    if (props.dtpFiles) {
      setDtpFiles(props.dtpFiles);
    } else {
      // fetch project details and extract dtp files
      visible && getDtpFiles();
    }
  }, [props.dtpFiles, getDtpFiles, visible]);

  // removes a file before uploading
  const handleRemove = (file) => {
    const newFilesList = filesList.filter((item) => item.uid !== file.key);

    setFilesList([...newFilesList]);
    if (error.hasError) {
      setError({ ...error, hasError: false });
    }
  };

  // removes all files before uploading
  const handleRemoveAll = () => {
    setFilesList([]);
    if (error.hasError) {
      setError({ ...error, hasError: false });
    }
  };

  // table dropdown matched to selection
  const handleSelectMenu = (key, uploadedFileId, dtpFiles) => {
    // get the id of the new dtp file
    const selectedFileId = key;

    // loop through all dtp files and get all details for selected Id
    const newMatchedTo = dtpFiles.find((item) => item._id === selectedFileId);

    // loop through filesList and find the item
    const file = filesList.find((item) => item.uid === uploadedFileId);

    // update selected key to pass as default value to dropdown
    file.selectedMatchToKey = selectedFileId;
    // update matched to with new details
    file.matchedTo = newMatchedTo;

    // update filesList
    setFilesList([...filesList]);
  };

  // logic to calculate what options will be displayed inside matched to dropdown
  const calculateDropdownOptions = (list, record) => {
    let finalList = list;

    // display only translated files if the project is IN POSTPROCESSING
    // and uploaded file is TRANSLATED
    if (
      record.tempFileType === "TRANSLATED" &&
      props.projectStatus === "IN_POSTPROCESSING"
    ) {
      finalList = list.filter((item) => item.fileType === "TRANSLATED");
    }

    // sort files by language code and display name
    finalList.sort((a, b) =>
      a.languageCode > b.languageCode
        ? 1
        : a.languageCode === b.languageCode
        ? a.displayName > b.displayName
          ? 1
          : -1
        : -1
    );
    return finalList;
  };

  const determineMenuItems = (record) => {
    const availableItems = calculateDropdownOptions(dtpFiles, record);
    return availableItems.map((item) => {
      return {
      label: <button className={item._id === record.selectedMatchToKey ? "menu-item-button isActive" : 'menu-item-button'}
                 onClick={() => handleSelectMenu(item._id, record.uid, dtpFiles)}>{`${item.languageCode} - ${item.displayName}`}
            </button>,
      key: item._id,
    }} );

  };

  const uploadColumns = [
    {
      title: "Uploaded file",
      dataIndex: "name",
      render: (text, record) => (
        <>
          {record.status === "uploading" && (
            <Spin size="small" style={{ marginRight: "10px" }} />
          )}

          <PaperClipOutlined
            style={{
              marginRight: "5px",
              color:
                record.status === "error"
                  ? "var(--red)"
                  : record.status === "done"
                  ? "var(--tertiary-color)"
                  : "",
            }}
          />
          <Text
            type={
              record.status === "error"
                ? "danger"
                : record.status === "done"
                ? "success"
                : ""
            }
          >
            {record.wasZip ? record.nameWithZip : text}
          </Text>
        </>
      ),
    },
    {
      title: "Type",
      dataIndex: "tempFileType",
      render: (text) => text,
    },
    {
      title: "Matched To",
      dataIndex: "matchedTo",
      width: 350,
      render: (text, record) => {
        const menuItems = props.projectStatus === "IN_POSTPROCESSING" &&
          record.matchedTo &&
          record.tempFileType === "TRANSLATED" ? [] : determineMenuItems(record);
        return <>
          {props.projectStatus !== "IN_PREPROCESSING" &&
            record.tempFileType === "SOURCE" && (
              <span style={{ marginRight: "5px" }}>
                <Tooltip
                  overlayInnerStyle={{ width: "250px" }}
                  color="#0096d6"
                  title={`Source files can be uploaded only if the project status is: IN PREPROCESSING`}
                  placement={"left"}
                >
                  <InfoCircleOutlined style={{ color: "#0096d6" }} />
                </Tooltip>
              </span>
            )}
        <Dropdown
            disabled={
              record.tempReference ||
              (props.projectStatus === "IN_PREPROCESSING" &&
                record.tempFileType === "TRANSLATED") ||
              (props.projectStatus === "IN_POSTPROCESSING" &&
                record.tempFileType === "SOURCE") // once a file has been matched automatically do not allow the user to rematch it to another source file for IN POSTPROCESSING
            }
            menu={{
              items: menuItems,
            }}
            trigger={['click']}
            overlayClassName='match-menu'
        >
           {text ? (
              <Link type="success">
                {text.displayName}
                {props.projectStatus === "IN_POSTPROCESSING" &&
                record.matchedTo &&
                record.tempFileType === "TRANSLATED" ? (
                  ""
                ) : (
                  <DownOutlined style={{ marginLeft: "5px" }} />
                )}
              </Link>
            ) : (
              <Link type="danger">
                Not Matched
                <DownOutlined style={{ marginLeft: "5px" }} />
              </Link>
            )}
      </Dropdown>
        </>
    },
    },
    {
      title: (
        <>
          {props.projectStatus === "IN_PREPROCESSING" && filesList.length > 1 && (
            <Tooltip
              overlayInnerStyle={{ width: "160px" }}
              color="#0096d6"
              title={`Check / Uncheck all files as REFERENCE`}
              placement={"top"}
            >
              <Checkbox
                onChange={(e) => handleChangeAllReference(e)}
                style={{ marginRight: "5px", fontSize: "12px" }}
              />
            </Tooltip>
          )}
          Reference
        </>
      ),
      dataIndex: "tempReference",
      width: 120,
      align: "center",
      render: (text, record) =>
        // allow reference files only in preprocessing status
        props.projectStatus !== "IN_PREPROCESSING" ? (
          <Tooltip
            overlayInnerStyle={{ width: "250px" }}
            color="#0096d6"
            title={`Reference files can be uploaded only if the project status is: IN PREPROCESSING`}
            placement={"left"}
          >
            <InfoCircleOutlined style={{ color: "#0096d6" }} />
          </Tooltip>
        ) : (
          <Checkbox
            checked={text}
            onChange={(e) => handleChangeReference(e, record)}
          />
        ),
    },
    {
      title: (
        <>
          {filesList.length > 1 && (
            <Tooltip
              overlayInnerStyle={{ width: "160px" }}
              color="var(--red)"
              title={`Remove all uploaded files`}
              placement={"top"}
            >
              <DeleteOutlined
                onClick={() => handleRemoveAll()}
                style={{ marginRight: "5px" }}
              />
            </Tooltip>
          )}
          Remove
        </>
      ),
      dataIndex: "remove",
      width: 100,
      align: "center",
      render: (text, record) => (
        <DeleteOutlined onClick={() => handleRemove(record)} />
      ),
    },
  ];

  // separate intance to set default headers
  const uploadAxiosInstance = axios.create();
  
  uploadAxiosInstance.defaults.headers = {
    "Content-type": "application/octet-stream", // Need to set the content type otherwise S3 will reject
  }

  // calculate file type
  const calculateFileType = (filename, isReference) => {
    // extract file extension
    let fileExt = getFileExtension(filename);
    if (isReference) {
      return "REFERENCE";
    }

    if (
      !isReference &&
      (fileExt.toLowerCase() === ".xlf" || fileExt.toLowerCase() === ".xliff")
    ) {
      return "SOURCE";
    } else {
      // Extension doesn't indicate an XLIFF so search for a TRANSLATED file
      return "TRANSLATED";
    }
  };

  const matchFile = (dtpFiles, filename, language = null) => {
    const matched = dtpFiles.find((file) => {
      return (
        file.displayName.toLowerCase().startsWith(filename.toLowerCase()) &&
        (file.languageCode.toLowerCase() === language || language === null)
      );
    });
    return matched;
  };

  // calculate files matching to dtp files list
  const calculateMatching = (fileType, fileName, dtpList) => {
    let fileExt = getFileExtension(fileName);

    if (fileType === "REFERENCE") {
      return null;
    }

    if (fileType === "SOURCE") {
      // don't match for IN POSTPROCESSING
      if (props.projectStatus === "IN_POSTPROCESSING") {
        return null;
      }
      // remove the .xliff extension and .sdlxliff if it is present
      fileName = removeSpecifiedExtension(fileName, fileExt);
      fileName = removeSpecifiedExtension(fileName, ".sdlxliff");

      let matchedFile = matchFile(dtpList, fileName);
      if (matchedFile) {
        return matchedFile;
      } else {
        return null;
      }
    }

    if (fileType === "TRANSLATED") {
      if (props.projectStatus === "IN_PREPROCESSING") {
        return null;
      }

      let filenameWithLanguage = removeSpecifiedExtension(fileName, fileExt);

      if (filenameWithLanguage.indexOf(".") > 0) {
        filenameWithLanguage = filenameWithLanguage.substring(
          0,
          filenameWithLanguage.lastIndexOf(".")
        );
      }

      if (filenameWithLanguage.lastIndexOf("_") < 0 && fileExt !== ".zip") {
        return null;
      }

      const searchLanguage = filenameWithLanguage
        .substring(filenameWithLanguage.lastIndexOf("_") + 1)
        .toLowerCase();

      const filenameWithoutLanguage = filenameWithLanguage.substring(
        0,
        filenameWithLanguage.lastIndexOf("_")
      );

      let searchFilename = filenameWithoutLanguage + fileExt;

      let matchedFile = matchFile(dtpList, searchFilename, searchLanguage);

      if (!matchedFile) {
        // Attempt to locate with just filename
        searchFilename = filenameWithoutLanguage;
        matchedFile = matchFile(dtpList, searchFilename, searchLanguage);
      }

      return matchedFile;
    }
  };

  const extractZip = async (file) => {
    try {
      let zip = new JSZip();

      // extract files
      const zipFiles = await zip.loadAsync(file);

      const extractedFiles = [];

      for (let item of Object.entries(zipFiles.files)) {
        const fileName = item[0];
        const fileDetails = item[1];

        // save the actual content and attach it to the uploaded file object
        let fileContent = await zip.files[fileName].async("blob");
        const newFile = {
          wasZip: true,
          nameWithZip: `${file.name}/${fileName}`, // displayed name format - files.zip/reference/reference.docx
          name: fileName.substring(fileName.lastIndexOf("/") + 1), // get only the file name without any folder name
          originFileContent: fileContent, // the actual content of the file
          uid: `zip-upload-${uuidv4()}`, //generates unique id for each file that was
          lastModified: file.lastModified, // pass data from zip file
          lastModifiedDate: file.lastModifiedDate, // pass data from zip file
        };

        // push only if is a file, ignore folders
        if (!fileDetails.dir) {
          extractedFiles.push(newFile);
        }
      }

      return extractedFiles;
    } catch (error) {
      console.error(
        `Error while unzipping the file: ${file.name}. See error on next line.`
      );
      console.log(error);
    }
  };

  // on click of all reference checkbox
  const handleChangeAllReference = (e) => {
    const newFilesList = [...filesList];

    newFilesList.forEach((listItem) => {
      listItem.tempReference = e.target.checked;
      listItem.tempFileType = calculateFileType(
        listItem.name,
        e.target.checked
      );
      listItem.previouslyMatchedTo = listItem.matchedTo;
    });

    // calculate matching
    newFilesList.forEach((listItem) => {
      if (listItem.previouslyMatchedTo === null) {
        listItem.matchedTo = calculateMatching(
          listItem.tempFileType,
          listItem.name,
          dtpFiles
        );
      } else {
        listItem.matchedTo = listItem.previouslyMatchedTo;
      }
    });

    // update fileslist
    setFilesList([...newFilesList]);
  };

  // on click of the reference checkbox
  const handleChangeReference = (e, record) => {
    // loop through filesList and find the item
    const file = filesList.find((item) => item.uid === record.uid);

    // update the reference based on checkbox
    record.tempReference = e.target.checked;
    file.tempReference = e.target.checked;

    // recalculate type
    file.tempFileType = calculateFileType(file.name, file.tempReference);
    file.previouslyMatchedTo = file.matchedTo;

    // set matched to - if was previously set don't recalculate
    // check if previouslyMatchedTo is empty
    if (file.previouslyMatchedTo === null) {
      file.matchedTo = calculateMatching(
        file.tempFileType,
        file.name,
        dtpFiles
      );
    } else {
      file.matchedTo = file.previouslyMatchedTo;
    }

    // update fileslist
    setFilesList([...filesList]);
  };

  // Upload Modal
  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setUploading(false);
    setError({ hasError: false, errorMsg: "" });
    setFilesList([]);
    setVisible(false);
    setDtpFiles([]);
  };

  const handleCancel = () => {
    hideModal();
  };

  // show errors in real time not only on processing upload
  useEffect(() => {
    const checkMatch = filesList.filter(
      (item) => !item.matchedTo && !item.tempReference
    );

    if (checkMatch.length > 0) {
      const list = checkMatch.map(({ name }) => name).join(", ");
      setError({
        hasError: true,
        errorMsg: (
          <div>
            <InfoCircleOutlined
              style={{
                color: "var(--red)",
                marginRight: "5px",
                userSelect: "none",
              }}
            />
            {`Please match/remove the following file${
              checkMatch.length > 1 ? "s" : ""
            }${
              props.projectStatus === "IN_PREPROCESSING"
                ? " or tick as reference"
                : ""
            }:`}
            <br />
            <p style={{ color: "rgba(0, 0, 0, 0.65)" }}>{list}</p>
          </div>
        ),
      });
    } else if (filesList.length < 1) {
      dtpFiles.length > 0 &&
        setError({
          hasError: true,
          errorMsg: `No files uploaded, please upload at least 1 file.`,
        });
    } else {
      setError({ hasError: false, errorMsg: "" });
    }
  }, [filesList, props.projectStatus, dtpFiles.length]);

  // called on click of upload button
  const handleUpload = async () => {
    let uploadErrors = false;
    let checkMatch = null;

    setUploading(true);

    // check if all files have been matched before uploading
    checkMatch = filesList.filter(
      (item) => !item.matchedTo && !item.tempReference
    );

    // stop uploading
    if (checkMatch.length > 0) {
      setUploading(false);
      return;
    }

    // at least one file should be uploaded
    if (filesList.length < 1) {
      // stop uploading
      setUploading(false);
      return;
    }

    // Loop through the files and upload each one
    for (var file of filesList) {
      try {
        //Set the status of the file to uploading
        file.status = "uploading";

        setFilesList([...filesList]);

        let headers = {
          Authorization: `Bearer ${props.accessToken}`,
        };

        let data = {
          filename: file.name,
          fileType: file.tempFileType,
          matchedFileId: file.matchedTo ? file.matchedTo._id : "", // fix for null value on encoding
          reference: file.tempReference,
        };

        // Get the URL to upload the file to
        let response = await axios
          .post(
            `${props.baseURI}/projects/${props.shortId}/files/upload`,
            data,
            {
              headers: headers,
            }
          )
          // .then(() => {
          //   props.reload();
          // });

        let uploadURL = response.data.url;
        
        // PUT the file to S3
        await uploadAxiosInstance.put(uploadURL, file.originFileContent)

        // Update the status of the file with success
        file.status = "done";
        setFilesList([...filesList]);
      } catch (error) {
        // Set upload errors to true
        uploadErrors = true;

        // File upload failed, set the status of the upload to error
        file.status = "error";
        file.response = error;

        setFilesList([...filesList]);
        setUploading(false);
      }
    }

    // Display an error message if needed otherwise close
    if (uploadErrors) {
      setError({
        hasError: true,
        errorMsg: "Failed to upload, please check and try again.",
      });
      // fix notification for succesful and failed responses
      filesList.map((file) =>
        file.status !== "error"
          ? successfulNotification("Upload successful", file.name)
          : "" || file.response.response !== undefined
          ? failedNotification(
              "Upload failed",
              file.response.response.data.error
            )
          : failedNotification("Upload failed", file.response.message)
      );
      // for activeProjectLink (dashboard) will display a redirect notification
      // if there is a successful uploaded file
      filesList.map(
        (file) =>
          file.status !== "error" &&
          props.activeProjectLink === true &&
          infoNotification("Check your documents", projectLink)
      );
    } else {
      hideModal();
      // if it's a reference file it will mentioned in the notification message
      filesList.forEach((file) => {
        if (file.status === "done") {
          successfulNotification(
            "Upload successful",
            <div>
              Name: {file.name}
              <br />
              File Type:{" "}
              <span style={{ color: "var(--tertiary-color)" }}>
                {file.tempFileType}
              </span>
              <br />
              Please Refresh. Uploaded files will be visible soon.
            </div>
          );
        }
      });
      // for activeProjectLink (dashboard) will display an redirect notification
      // will render a history link inside notification
      props.activeProjectLink === true &&
        infoNotification("Check your documents", projectLink);
    }
  };

  const draggerProps = {
    multiple: true,
    fileList: filesList,
    beforeUpload: async (file, fileList) => {
      // generate a new list with all files - normal / zip files
      let newFilesList = [];

      for (let singleFile of fileList) {
        if (getFileExtension(singleFile.name) === ".zip") {
          let zipList = [];
          zipList = await extractZip(singleFile);
          zipList.forEach((item) => newFilesList.push(item));
        } else {
          // store the actual file inside originFileContent and pass all upload details
          const newFile = {
            ...singleFile,
            originFileContent: singleFile,
            name: singleFile.name,
            lastModified: singleFile.lastModified,
            lastModifiedDate: singleFile.lastModifiedDate,
          };
          newFilesList.push(newFile);
        }
      }

      // loop through new files list and pass all data
      const updatedFileList = newFilesList.map((obj) => ({
        ...obj,
        key: obj.uid,
        tempReference: obj.tempReference || false,
        tempFileType: calculateFileType(obj.name, obj.tempReference),
        matchedTo: null,
        previouslyMatchedTo: null,
        status: obj.status || "unknown",
      }));

      // calculate matching for each file
      let listWithMatching = updatedFileList.map((obj) => ({
        ...obj,
        matchedTo: calculateMatching(obj.tempFileType, obj.name, dtpFiles),
      }));

      // fix to auto tick as reference if the project status is IN PREPROCESSING
      // and fileType is TRANSLATED
      if (props.projectStatus === "IN_PREPROCESSING") {
        listWithMatching = listWithMatching.map((obj) => ({
          ...obj,
          tempReference:
            obj.tempFileType === "TRANSLATED" ? true : obj.tempReference,
        }));

        listWithMatching = listWithMatching.map((obj) => ({
          ...obj,
          tempFileType: calculateFileType(obj.name, obj.tempReference),
        }));
      }

      // set selected key for each file based on matching
      const listWithSelectedkey = listWithMatching.map((obj) => ({
        ...obj,
        selectedMatchToKey: obj?.matchedTo?._id,
      }));

      // update file list
      setFilesList([...filesList, ...listWithSelectedkey]);

      // Turn off automatic uploading, this will be done when the user presses Upload
      return false;
    },
  };

  // Override Upload default upload
  const dummyRequest = () => {
    console.log("Prevent default upload");
  };

  return (
    <>
      <Button type="primary" disabled={props.disabled} onClick={showModal} icon={<UploadOutlined />}>
        Upload
      </Button>
      <Modal
        title={props.title}
        open={visible}
        okText="Upload"
        onOk={handleUpload}
        okButtonProps={ { disabled: uploading } }
        cancelButtonProps={ { disabled: uploading } }
        confirmLoading={uploading}
        onCancel={handleCancel}
        width={1300}
      >
        <Dragger
          {...draggerProps}
          showUploadList={false}
          customRequest={dummyRequest}
        >
          <p className="ant-upload-drag-icon">
            <CloudUploadOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Support for a single or bulk upload</p>
        </Dragger>
        {loadingDtpFiles ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0 -15px",
            }}
          >
            <Spin style={{ marginRight: "10px" }} /> Loading project details...
          </div>
        ) : (
          <Table
            columns={uploadColumns}
            dataSource={[...filesList]}
            size="small"
            pagination={false}
            className="upload-modal-table"
            locale={{ emptyText: "No files uploaded" }}
          />
        )}
        {error.hasError && (
          <Text
            type="danger"
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px auto -15px",
              maxWidth: "60%",
            }}
          >
            {error.errorMsg}
          </Text>
        )}
      </Modal>
    </>
  );
};

// export default withRouter(UploadBtn);
export default UploadBtn; // TODO: replace with hooks