import React from "react";
import {FiniteListFilter, FiniteListProps} from "../stories/FiniteListFilter/FiniteListFilter";

export default (props: FiniteListProps) => {
    return <FiniteListFilter
        {...props}
        data={[
            {
                key: 0,
                title: "Not Shared",
                value: 0
            },
            {
                key: 1,
                title: "Partially Shared",
                value: 1
            },
            {
                key: 2,
                title: "Fully Shared",
                value: 2
            }
        ]}/>
}
