import React from "react";
import { Form, Tag, Divider, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import './projectIds.scss'
export default function ProjectIds( props ) {

  const {
    projectIds,
    handleTagChange,
    projectString,
    handleKeyDown,
    handleRemoveTag,
    handleTagBlurChange
  } = props;

  return (
    <Form.Item
      label={
        <>Project IDS
          <Tooltip title={<div>
            <p style={{marginBottom:'3px'}}>Use comma, semi-colon, space or enter to separate the IDs.</p>
            <p style={{marginBottom:'unset'}}><InfoCircleOutlined style={{marginRight:'5px'}}/>Duplicate IDs will be ignored.</p>
            </div>}
          placement="right"
          color={"var(--tertiary-color)"}
          overlayInnerStyle={{ width: "390px" }}>
            <InfoCircleOutlined style={{marginLeft:'5px', color:'var(--tertiary-color)', cursor: 'help'}}/>
          </Tooltip>
        </>
        }
      name="project_ids"
      rules={[{ required: false}]}
      key={props.key}
    >
      <div className="reports-project-ids-filter ant-select ant-select-multiple ant-select-show-search" style={{width: "100%"}}>
        <div className="ant-select-selector">
          <span className="ant-select-selection-item" style={{width: "100%", background:'var(--white)'}}>
            {
              projectIds.map( ( t, index ) => {
                return <Tag closable={true} onClose={()=>{handleRemoveTag(t)}} key={`${t}-${index}`}>{t}</Tag>
              })
            }
            {projectIds.length > 0 && <Divider style={{margin:'5px 0 1px 0', background:'var(--tertiary-color)'}}/>}
              <input placeholder="Type here to add more IDs"
                autoComplete="off"
                type="search"
                autoFocus
                className="ant-select-selection-search-input"
                style={{}}
                role="combobox"
                aria-haspopup="listbox"
                aria-owns="rc_select_3_list"
                aria-autocomplete="list"
                aria-controls="rc_select_3_list"
                onChange={handleTagChange}
                value={projectString}
                onKeyDown={handleKeyDown}
                onBlur={handleTagBlurChange}
                aria-expanded="true" />
            <span className="ant-select-selection-search-mirror" aria-hidden="true">&nbsp;</span>
            </span>
        </div>
      </div>
    </Form.Item>
  )
}
