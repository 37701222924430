import {
  FiniteListFilter,
  FiniteListProps,
} from "../stories/FiniteListFilter/FiniteListFilter";

export default (props: FiniteListProps) => {
  const options = ["Pending", "Approved"];
  return (
    <FiniteListFilter
      {...props}
      data={options.map((option, i) => {
        return {
          key: i,
          title: option,
          value: option === "Not Set" ? null : option,
        };
      })}
    />
  );
};
