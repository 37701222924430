import EventEmitter from "events";
import TypedEmitter from "typed-emitter";
import axios from "axios";
import {UserContext} from "../index";
import Cookies from "js-cookie";
import {vendors} from "../LoginPage";

type Events = {
    token: ( token:string ) => void;
    errored: ( error: Error ) => void;
    loggedOut: () => void;
    loggingIn: () => void;
    loggingOut: () => void;
}

export default abstract class AuthVendorAbstract<P> extends (EventEmitter as new () => TypedEmitter<Events>) {

    public abstract readonly identifier:vendors;
    protected token?:string;

    public readonly provider:P;

    abstract doLogin():Promise<string|void>;
    abstract doLogout():Promise<void>;

    private inProgress = false;

    private verifyMASToken( token:string ) {
        return this.context.verifyToken( token );
    }

    protected async generateMASToken ( token:string ){

        console.log( "GEN MAS")

        let url = `${process.env.REACT_APP_MAS_URL}/core-mas-v1/tokenAuth`;

        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };

        try {
            const { data } = await axios.post(url, {}, config)
            if ( !data || !data.token ) {
                throw new Error( "MAS did not return a token")
            }
            console.log( "generated token ", data.token );
            return data.token as string;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    protected context:UserContext;

    constructor(provider:P, context:UserContext) {
        super();
        this.context = context;
        this.provider = provider;
    }

    async login() {
        try {

            if ( this.inProgress ) {
                return;
            }

            this.inProgress = true;
            this.emit( "loggingIn" );

            /**
             * A bug in the MSAL library means after logout, this key is hanging around in session storage, which makes it
             * impossible to login again.
             *
             * A bug with something microsoft wrote???
             *
             * {GASP!}
             *
             */
            sessionStorage.removeItem( "msal.interaction.status" )

            if ( this.context.MASToken && this.verifyMASToken( this.context.MASToken ) ) {
                this.token = this.context.MASToken
            }
            else {
                const vendorToken = await this.doLogin() as string;
                this.token = await this.generateMASToken( vendorToken ) as string;
            }

            Cookies.set( "auth_method", this.identifier)

            this.context.setToken( this.token );
            this.emit( "token", this.token );
            this.inProgress = false;
        }
        catch ( e ) {
            console.error( e );
            this.inProgress = false;
            this.emit( "errored", e as Error );
        }

    }

    async logout() {
        try {
            this.emit( "loggingOut" );
            Cookies.set("logged_out", "true" );
            Cookies.remove( "auth_method")
            this.context.removeToken();
            await this.doLogout();
            sessionStorage.clear();
            this.emit( "loggedOut" );
        }
        catch ( e:any ) {
            console.error( e );
            this.emit("errored", e as Error );
        }
    }
}
