import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Input, Select, Switch, Row, Col, Modal, Tooltip, Tag } from 'antd';
import { CloseOutlined, CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';

const affiliationOptions = [{ value: 'Internal', label: 'Internal' }, { value: 'External', label: 'External' }];

const EditLinguistic = ({
  accessToken,
  config,
  styles,
  allProgramRoles,
  allProjectRoles,
  allLanguages,
  allComponentTypes,
  allProducts,
  setViewEditLinguistic,
  reg,
  setRefreshLinguists
}) => {
  const [form] = Form.useForm();
  const [allAreas, setAllAreas] = useState([]);

  useEffect(() => {
    const areaKeys = {};
    for (const product of allProducts) {
      areaKeys[product.productArea._id] = product.productArea.title;
    }
    const areas = [];
    for (const key in areaKeys) {
      areas.push({ value: key, label: areaKeys[key] });
    }
    setAllAreas(areas);
  }, [allProducts]);

  useEffect(() => {
    if (reg) {
      form.setFieldsValue({
        name: reg ? reg.name : null,
        personal_email: reg ? reg.personal_email : null,
        sesameId: reg ? reg.sesameId : null,
        sdl_email: reg ? reg.sdl_email : null,
        program_role: reg ? reg.program_role : null,
        affiliation: reg ? reg.affiliation : null,
        eligible: reg ? reg.eligible : false,
        share_notifications: reg ? reg.share_notifications : false,
        active: reg ? reg.active : false,
        project_roles: reg ? reg.project_roles : [],
        language_codes: reg ? reg.language_codes : [],
        sourceLanguage: reg ? reg?.sourceLanguage : null,
        components: reg ? reg.components : [],
        products: reg ? reg.products : [],
        productAreas: reg ? reg.productAreas.map(x => x.value) : [],
      });
    }
  }, [reg]);

  const onSubmit = async () => {
    let response;
    const body = form.getFieldsValue();
    let requestOptions;
    if (reg) {
      requestOptions = {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(body),
      };
      response = await fetch(`${config.URL}/glms/linguistic/${reg.id}`, requestOptions);
    } else {
      requestOptions = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(body),
      };
      response = await fetch(`${config.URL}/glms/linguistic`, requestOptions);
    }
    onSave(response, body);
    return response;
  };

  const onBack = () => {
    clearForm();
    setViewEditLinguistic(false);
  };

  const onSave = (response, body) => {
    if (response.status === 200) {
      Modal.info({
        title: 'Success',
        icon: <CheckOutlined />,
        content: reg ? `Linguistic has been successfully updated.` : 'A new linguistic has been successfully created',
      });
      clearForm();
      setViewEditLinguistic(false);
      setRefreshLinguists(true);
    } else if (response.status === 400 && body.products.length === 0) {
      Modal.info({
        title: 'Error',
        icon: <CloseOutlined />,
        content: `'Products' is a mandatory field. You have to select at least one value.`,
        onOK: {},
      });
    } else {
      Modal.info({
        title: 'Error',
        icon: <CloseOutlined />,
        content: `We've found an internal error saving data. Please, try again.`,
        onOK: {},
      });
    }
  };

  const onReset = () => {
    clearForm();
  };

  const clearForm = () => {
    if (reg) {
      form.setFieldsValue({
        name: reg.name,
        personal_email: reg.personal_email ? reg.personal_email : null,
        sesameId: reg.sesameId ? reg.sesameId : null,
        sdl_email: reg.sdl_email ? reg.sdl_email : null,
        program_role: reg.program_role,
        affiliation: reg.affiliation,
        eligible: reg.eligible,
        share_notifications: reg.share_notifications,
        active: reg.active,
        project_roles: reg.project_roles,
        language_codes: reg.language_codes,
        sourceLanguage: reg?.sourceLanguage,
        components: reg.components,
        products: reg.products,
        productAreas: reg.productAreas.map(x => x.value),
      });
    } else {
      form.setFieldsValue({
        name: null,
        personal_email: null,
        sesameId: null,
        sdl_email: null,
        program_role: null,
        affiliation: null,
        eligible: true,
        share_notifications: false,
        active: true,
        project_roles: [],
        language_codes: [],
        sourceLanguage: null,
        components: [],
        products: [],
        productAreas: [],
      });
    }
  };

  const onProductsChange = (_, options) => {
    const areas = [];
    for (const product of options) {
      if (!areas.includes(product.areaid)) {
        areas.push(product.areaid);
      }
    }
    form.setFieldsValue({
      productAreas: areas,
    });
  };

  const onProductAreasChange = values => {
    form.setFieldsValue({
      products: allProducts.filter(x => values.includes(x.productArea._id)).map(product => product.title),
    });
  };

  const customTagRender = ({ label, closable, onClose }) => {
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color='var(--tertiary-color)'
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, marginBottom: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Modal
      title={`${reg === null ? 'Create' : 'Edit'} Vendor`}
      open={true}
      width="80%"
      onCancel={onBack}
      maskClosable={false}
      footer={[
        <Button key="cancel" type="default" onClick={onBack}>
          Cancel
        </Button>,
        <Button key="reset" type="default" danger onClick={onReset}>
          Reset
        </Button>,
        <Button key="ok" type="primary" onClick={form.submit}>
          {reg === null ? 'Save' : 'Update'}
        </Button>,
      ]}
    >
      <div id={styles['edit-linguistic']}>
        <Form name="new-edit-linguistic"
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          initialValues={{
            active: true,
            eligible: true,
            share_notifications: false,
          }}
        >
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item name="name" label="Name" rules={[{ required: true, message: 'This field is required.' }]}>
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="personal_email" label="Personal Email" rules={[{ type: 'email', message: 'Invalid email format.' }]}>
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item name="sesameId" label="Sesame Account" rules={[{ required: true, message: 'This field is required.' }]}>
                <Input placeholder="Sesame Account"
                  suffix={
                    <Tooltip title="A valid account in Google Translation Enterprise that may have documents shared with it. A Lead will not receive projects unless this is populated">
                      <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="sdl_email" label="RWS Email">
                <Input placeholder="RWS Email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item name="affiliation" label="Affiliation" layout="horizontal" rules={[{ required: true, message: 'This field is required' }]}>
                <Select options={affiliationOptions} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="components" label="Component Types" rules={[{ required: true, message: 'This field is required' }]}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Component Types"
                  options={allComponentTypes.map(x => ({ value: x, label: x }))}
                  filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                  tagRender={customTagRender}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item name="program_role" label="Program Role" rules={[{ required: true, message: 'This field is required' }]}>
                <Select options={allProgramRoles} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="project_roles" label="Project Roles" rules={[{ required: true, message: 'This field is required' }]}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Project Roles"
                  options={allProjectRoles.map(x => ({ value: x.value, label: x.value }))}
                  filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                  tagRender={customTagRender}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item name="productAreas" label="Product Areas" rules={[{ required: true, message: 'This field is required' }]}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Product Areas"
                  options={allAreas}
                  filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                  onChange={onProductAreasChange}
                  tagRender={customTagRender}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="products" label="Products" rules={[{ required: true, message: 'This field is required' }]}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%', maxHeight: '200px', overflowY: 'auto' }}
                  placeholder="Products"
                  options={allProducts.map(product => ({ value: product.title, label: product.title, areaid: product.productArea._id, areaname: product.productArea.title }))}
                  filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                  onChange={onProductsChange}
                  tagRender={customTagRender}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
            <Form.Item name="sourceLanguage" label="Source Language" rules={[{ required: true, message: 'This field is required' }]}>
                <Select
                  allowClear
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Language"
                  options={allLanguages.map(x => ({ value: x.code, label: `${x.name} (${x.code})` }))}
                  filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                  tagRender={customTagRender}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="language_codes" label="Target Languages" rules={[{ required: true, message: 'This field is required' }]}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Languages"
                  options={allLanguages.map(x => ({ value: x.code, label: `${x.name} (${x.code})` }))}
                  filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                  tagRender={customTagRender}
                />
              </Form.Item>
            </Col>
            </Row>
          <Row gutter={8}>
            <Col span={4}>
              <Form.Item name="active" label="Active" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Share Eligible">
                <Form.Item name="eligible" valuePropName="checked" noStyle>
                  <Switch />
                </Form.Item>
                <Tooltip title="This MUST be checked for a linguist to have projects automatically shared with them.">
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)', marginTop: '8px', marginLeft: '10px' }} />
                </Tooltip>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Receive Sharing Notifications">
                <Form.Item name="share_notifications" valuePropName="checked" noStyle>
                  <Switch />
                </Form.Item>
                <Tooltip title="Check this if a Linguist will receive notifications via email when a project is shared with them.">
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)', marginTop: '8px', marginLeft: '10px' }} />
                </Tooltip>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

EditLinguistic.propTypes = {
  styles: PropTypes.object,
  accessToken: PropTypes.string,
  config: PropTypes.object,
};

export default EditLinguistic;