import React from "react";
import { FiniteListWithAsyncLoaderAbstract, ListProps } from "../Components";
import { useMercuryContext } from "../user-context";

class OwnerFilter extends FiniteListWithAsyncLoaderAbstract {
  async fetch() {
    const { data } = await this.props.context.umsClient.get("/core-ums-v2/users/list");
    let ownerList = data.map((item: any) => {
      return {
        title: item.name,
        value: item._id,
      };
    });
    ownerList.push({
      title: "Unclaimed",
      value: null,
    });
    return ownerList;
  }
}

export default (props: ListProps) => {
  const context = useMercuryContext();
  return <OwnerFilter context={context} {...props} />;
};
