import React from "react";
import {
    FiniteListWithAsyncLoaderAbstract, ListProps
} from "../Components";
import {useMercuryContext} from "../user-context";

class RateNameFilter extends FiniteListWithAsyncLoaderAbstract {
    
    async fetch() {
        const { data } = await this.props.context.client.get("/core-grs-v1/rate/uniqueRateNames");
        return data.map( ( item:any ) => {
            return {
                title: item,
                value: item
            }
        })
    }
}

export default (props:ListProps) => {
    const context = useMercuryContext();
    return <RateNameFilter context={context} {...props} />
}
