import { Component, createRef } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import {
  BlockOutlined, DeleteOutlined,
  DollarCircleOutlined, InboxOutlined, InfoCircleOutlined,
  PercentageOutlined,
  PlusOutlined, ReloadOutlined,
} from "@ant-design/icons";
import { NumericFormat as NumberFormat } from "react-number-format";
import TextArea from "antd/es/input/TextArea";
import Dragger from "antd/es/upload/Dragger";
import { useMercuryContext } from "../../user-context";
import { ListFilter } from "../../stories/ListFilter/ListFilter";
import ProductFilter from "../Product.filter";
import LanguageCodeFilter from "../LanguageCode.filter";
import VarStatusFilter from "../VarStatus.filter";
import StatusFilter from "../Status.filter";
import UrgencyFilter from "../Urgency.filter";
import { DateFilter, DateFilterObj } from "../../stories/DateFilter/DateFilter";
import { Link } from "react-router-dom";
import { CustomTooltip } from "../../stories/CustomTooltip/CustomTooltip";
import EmptyOverlay from "../../stories/Table/EmptyOverlay";
import BaseTable from "../../stories/Table/BaseTable";
import { FiltersBar } from "../../Components";
import LoadingStar from "../../stories/Loaders/LoadingStar";
import uuid from "uuid-browser";

import "./CreateModal.scss";

const Papa = require("papaparse");

export class AdjustmentCreateModal extends Component<any, any> {

  formRef = createRef() as any;

  private categories = ["correction", "bonus", "penalty", "admin", "credit"];

  private readonly defaultState = {
    loading: false,
    tableLoading: false,
    adminValues: [],
    penaltyValues: [],
    plpData: [],
    formValues: { PONumber: undefined, useNewPONumber: true, description: "" },
    selectedPLPs: [],
    selectedRows: [],
    disablePercentage: false,
    projectIds: [],
    tableWidth: 0,
    pendingFromValues: {},
    showResetWarning: false,
    filters: {},
    activeFilters: [],
    filterData: {},
    adjPenaltyValues: [],
    creating: false,
    reqId: null,
    langsAdj: [],
    penaltyFormValues: { type: "percentage" },
    allPlpsHavePONumber: true
  }

  state = Object.assign(this.defaultState);

  renderLink = (id: string) => (
    <>
      <a
        href={`/project/${id}/?type=projectId`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <BlockOutlined style={{ marginRight: '3px' }} />
      </a>
      <Link to={`/project/${id}?type=projectId`} id={id}>
        {id}
      </Link>
    </>
  );

  projectIdRender = (id: string, record: any) => {
    if (record.customVendorPLPMetadata?.projectType === 'WMT') {
      return this.renderLink(id);
    }

    return (
      <>
        {this.renderLink(id)}
      </>
    );
  };

  varStatusRender = (text: string, record: any) => {
    if (Array.isArray(record.invoiceData?.invoiceErrors) && record.invoiceData?.invoiceErrors.length > 0) {
      if (record.varStatus !== "ACCRUAL" && record.varStatus !== "ESTIMATE" && record.varStatus !== "INVOICE") {
        const errorMessage = record.invoiceData?.invoiceErrors.join("\r\n");
        return (
          <Tooltip title={errorMessage} placement="right" color={"var(--red)"}>
            {text}
            <InfoCircleOutlined style={{ color: "var(--red)", marginLeft: "3px" }} />
          </Tooltip>
        );
      }
    }
    return text;
  };

  adminValueRender = (text: string, record: any, id: any) => {
    const { formValues, adminValues } = this.state;

    if (formValues.category === "admin") {
      const adjAdminValue = adminValues.find((value: any) => {
        return value.projectId === record.projectId && value.language === record.targetLang
      });
      if (adjAdminValue) {
        return adjAdminValue[id];
      }
    } else {
      return text;
    }
  }


  stdFilters = [
    {
      id: "projectId",
      name: "projectIds",
      title: "Project ID",
      component: ListFilter,
      active: true
    },
    {
      id: "product",
      name: "product",
      title: "Product",
      component: ProductFilter,
      active: true
    },
    {
      id: "sourceLangCode",
      name: "sourceLanguageCode",
      title: "Source Language",
      component: LanguageCodeFilter,
      active: true
    },
    {
      id: "targetLanguageCode",
      name: "targetLanguageCode",
      title: "Target Language",
      component: LanguageCodeFilter,
      active: true
    }
  ]

  filters = [
    ...this.stdFilters,
    {
      id: "varStatus",
      name: "varStatus",
      title: "Invoice Status",
      component: VarStatusFilter,
      active: true
    },
    {
      id: "status",
      name: "catToolStatus",
      title: "Status",
      component: StatusFilter,
      active: true
    },
    {
      id: "urgency",
      name: "urgency",
      title: "Urgency",
      component: UrgencyFilter,
      active: true
    },
    {
      id: "receivedDate",
      name: "importedDate",
      title: "Received Date",
      component: DateFilter,
      active: true
    },
    {
      id: "latestTranslationDueTimestamp",
      name: "latestTranslationDueTimestamp",
      title: "Due Date",
      component: DateFilter,
      active: true
    }
  ];

  columns = [
    {
      id: "projectId",
      title: "Project ID",
      dataIndex: "projectId",
      sorter: false,
      width: 140,
      fixed: "left",
      render: (id: string, record: any) => this.projectIdRender(id, record)
    },
    {
      id: "targetLanguageCode",
      title: <Tooltip title="Target Language" placement="left" color={"var(--tertiary-color)"}>Target La...</Tooltip> as any,
      dataIndex: "targetLang",
      sorter: false,
      width: 200,
      fixed: "left",
    },
    {
      id: "product",
      title: "Product",
      dataIndex: "product",
      sorter: false,
      width: 200,
      fixed: undefined,
      render: (text: string) => <CustomTooltip text={text} length={25} />,
    },
    {
      id: "varStatus",
      title: "Invoice Status",
      dataIndex: "invoiceStatus",
      sorter: false,
      width: 163,
      fixed: undefined,
      render: (text: string, record: any) => this.varStatusRender(text, record)
    },
    {
      id: "PONumber",
      title: "PO Number",
      dataIndex: "PONumber",
      sorter: false,
      fixed: undefined,
      render: (text: string, record: any) => this.adminValueRender(text, record, "PONumber")
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      sorter: false,
      fixed: undefined,
      render: (text: string, record: any) => this.adminValueRender(text, record, "invoiceNumber")
    },
    {
      title: "Invoice Amount",
      dataIndex: "totalCharge",
      sorter: false,
      render: (text: string, record: any) => {
        return <NumberFormat
          value={text}
          decimalScale={4}
          fixedDecimalScale={true}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      }
    },
    {
      title: "Adjustment",
      dataIndex: "adjustment",
      sorter: false,
      render: (text: any, record: any) => {

        const value = () => {
          let adjAmount = 0;
          const { formValues, adjPenaltyValues, adminValues } = this.state;

          if (formValues.category === "penalty") {
            const adjPenaltyValue = adjPenaltyValues.find((value: any) => value.language === record.targetLang);
            if (!adjPenaltyValue) return adjAmount;

            // if (!record.InvoiceAmount || record.InvoiceAmount === 0) return adjAmount;
            if (!record.totalCharge || record.totalCharge === 0) return adjAmount;

            return adjPenaltyValue.type === "fixed"
              ? adjPenaltyValue.amount
              // : (record.InvoiceAmount * (adjPenaltyValue.amount / 100)).toFixed(4);
              : (record.totalCharge * (adjPenaltyValue.amount / 100)).toFixed(4);
          } else if (formValues.category === "admin") {
            const adjAdminValue = adminValues.find((value: any) => {
              return value.projectId === record.projectId && value.language === record.targetLang
            });
            if (adjAdminValue) {
              return adjAdminValue.amount;
            }
          }

          if (!formValues.amount) {
            return 0
          }

          if (!formValues.type || formValues.type === "percentage") {
            return (record.totalCharge - (record.totalCharge - (record.totalCharge * (formValues.amount / 100)))).toFixed(4);
          }

          return formValues.amount;
        }

        return <NumberFormat
          value={value()}
          decimalScale={4}
          fixedDecimalScale={true}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      },
    }

  ];

  onRezise = () => {
    if (this.yardStick.current) {
      this.setState({ tableWidth: this.yardStick.current.getBoundingClientRect().width })
    }
  }

  public filtersToQuery() {
    const
      query: Record<any, any> = Object.assign({}, this.state.filterData);

    Object.keys(query).forEach(k => {
      query[k] = DateFilterObj.getDateRange(query[k])
    });

    if (this.state.formValues.category === "penalty" && this.state.adjPenaltyValues.length) {
      query.targetLanguageCode = this.state.adjPenaltyValues.map((v: any) => v.language);
    }

    return query;
  }

  async componentDidMount() {
    window.addEventListener("resize", this.onRezise);
    const { data } = await this.props.context.gpClient.get("/google-gp-v1/plp/uniqueLanguageCodes");
    this.setState({ langsAdj: data, activeFilters: this.filters, selectedFilters: this.filters.map((filter: any) => filter.name) })
    this.onRezise();
    this.yardStick = createRef()
  }

  componentWillUnmount() {
    const socket = this.props.context.gpSocket.adj;
    socket.off(`search:row:${this.state.reqId}`)
    window.removeEventListener("resize", this.onRezise)
    this.yardStick = createRef()
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {

    const
      { gpSocket } = this.props.context,
      socket = gpSocket.adj;

    if (this.state.formValues.category === "admin") {
      return;
    }

    if (prevState.reqId !== this.state.reqId) {

      socket.emit("search:abort");

      socket.off(`search:row:${prevState.reqId}`);
      socket.off(`search:meta:${prevState.reqId}`);

      if (!this.state.adjPenaltyValues.length && Object.keys(this.state.filterData).length === 0) {
        this.setState({ plpData: [], selectedRows: [] })
        return;
      }

      socket.on(`search:row:${this.state.reqId}`, (row: any) => {
        const rows = Array.isArray(row) ? row : [row];
        let { plpData } = this.state;
        plpData = plpData.concat(rows);
        this.setState({ plpData, loading: false })
      });
    }
  }

  submit = () => {
    const { gpSocket } = this.props.context;

    let { selectedRows, penaltyAmounts, formValues } = this.state;

    this.setState({ loading: true, creating: true });

    if (formValues.category === "penalty") {
      penaltyAmounts = this.state.adjPenaltyValues.map((v: any) => {
        delete v._id
        return v;
      })
    }

    const socket = gpSocket.adj;

    try {
      socket.emit("create", {
        ...formValues,
        penaltyAmounts,
        adminAmounts: this.state.adminValues,
        plpIds: selectedRows
      }, (res: any) => {

        if (res.error) {
          console.error("ERROR", res)
          throw new Error(res.error);
        }

        this.props.toggle();

        this.setState({
          loading: false,
          tableLoading: false,
          adminValues: [],
          penaltyValues: [],
          plpData: [],
          formValues: {},
          selectedPLPs: [],
          selectedRows: [],
          disablePercentage: false,
          projectIds: [],
          tableWidth: 0,
          pendingFromValues: {},
          showResetWarning: false,
          filters: {},
          activeFilters: [],
          filterData: {},
          adjPenaltyValues: [],
          creating: false,
          reqId: null,
          langsAdj: [],
          penaltyFormValues: {}
        })

        if (this.props.reload) this.props.reload();
      })

    } catch (e) {
      console.error(e);
    }

  }

  onFormChange(name: string, value: any) {
    const
      formValues: Record<string, any> = this.state.formValues;

    if (name === "category") {
      this.props.context.gpSocket.adj.emit("search:abort");
      this.setState({ plpData: [], selectedRows: [], filterData: {} })
    }

    formValues[name] = value;
    this.setState({ formValues, pendingFormValues: {}, showResetWarning: false }, () => {
      this.onRezise();
    })

    this.formRef.current.validateFields({
      PONumber: 'test',
    });

  }

  getAdminAdjPLPData() {

    const { gpSocket } = this.props.context;

    this.setState({ loading: true });

    gpSocket.adj.emit("plpDetailsByAdminValues", this.state.adminValues, (res: any) => {
      const { plpData, selectedRows } = this.state;

      this.setState({
        plpData: [...plpData, ...res],
        loading: false,
        selectedRows: Array.from(
          new Set([
            ...selectedRows,
            ...res.map((item: any) => item._id)
          ])
        ),
      })
    })
  }

  renderDragger() {

    const { formValues, adminValues } = this.state;

    if (formValues.category !== "admin") {
      return null;
    }

    return <Dragger
      name="file"
      multiple={false}
      accept="text/csv"
      beforeUpload={(file) => {
        Papa.parse(file, {
          complete: (results: any) => {

            const
              output: any = [],
              uniqueIds: any = new Set(),
              { category } = formValues;

            if (category === "admin") {
              results.data.forEach(([type, projectId, description, source, language, amount, PONumber]: any) => {
                uniqueIds.add(projectId, PONumber);
                output.push({
                  projectId,
                  language,
                  PONumber,
                  amount,
                  type: "fixed",
                });
              })
            }
            else {
              results.data.forEach(([type, projectId, language, amount, PONumber]: any) => {
                if (type.toLowerCase() === "admin") {
                  uniqueIds.add(projectId, PONumber);
                  output.push({
                    projectId,
                    language,
                    PONumber,
                    amount,
                    type: "fixed",
                  });
                }
              });
            }

            let allHavePONumber = true;

            let adminValuesArr = Array.from(
              new Set([...output, ...adminValues].map((v: any) => JSON.stringify(v)))
            ).map((v: any) => JSON.parse(v)).filter((row: any) => {
              return row.projectId && row.projectId !== "project_id"
            })

            adminValuesArr?.forEach((item: any) => {
              if (!item?.PONumber) {
                allHavePONumber = false;
              }
            })

            this.setState({
              projectIds: Array.from(new Set([...uniqueIds])).filter((id: any) => id !== null),
              adminValues: adminValuesArr,
              allPlpsHavePONumber: allHavePONumber
            }, this.getAdminAdjPLPData)
          },
        });
        return false;
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">
        Please ensure the CSV is in the correct format, e.g.:<br />
        "category","project_id","description","source_language","target_language","final_total_charge","purchase_order_number"
      </p>
    </Dragger>

  }

  public rowClassName(record: any, index: number): string {
    return ""
  }

  selectAll = (value: boolean) => {

    if (!value) {
      return this.setState({ selectedRows: [] })
    }

    let allHavePONumber = true;

    this.state.plpData.forEach((item: any) => {
      if (!item.PONumber) {
        allHavePONumber = false;
      }
    })

    const selectedRows = this.state.plpData.map((plp: any) => plp._id);
    this.setState({ selectedRows, allPlpsHavePONumber: allHavePONumber });
  }

  select = (props: any, value: any) => {
    let
      { id } = props,
      { selectedRows } = this.state;

    if (selectedRows.includes(id)) {
      const s = new Set(selectedRows);
      s.delete(id);
      selectedRows = Array.from(s);
    }
    else {
      selectedRows.push(id);
    }

    let allHavePONumber = true;

    this.state.plpData.forEach((item: any) => {
      if (selectedRows.includes(item._id)) {
        if (!item.PONumber) {
          allHavePONumber = false;
        }
      }
    })

    this.setState({ selectedRows, allPlpsHavePONumber: allHavePONumber });
  }

  renderTable() {

    if (!this.yardStick.current) return null;

    if (this.state.formValues.category === "penalty" && !this.state.adjPenaltyValues.length) {
      return null;
    }

    // @ts-ignore
    return <BaseTable
      className='new-projects-table'
      dataSource={this.state.plpData}
      columns={[
        ...this.columns
      ]}
      // onColumnSort={(sorter:any) => this.sort({}, {}, sorter, {} as any)}
      // @ts-ignore
      sortBy={{ key: this.state.sortField, order: this.state.sortOrder === 'ascend' ? 'asc' : this.state.sortOrder === 'descend' ? 'desc' : this.state.sortOrder }}
      loading={this.state.loading}
      rowClassName={(data: any) => {
        const rowClass = "";

        if (this.state.selectedRows.includes(data.id)) {
          return `BaseTable__row--selected ${rowClass}`;
        } else {
          return rowClass;
        }
      }}
      rowSelection={{
        selectedRowKeys: this.state.selectedRows,
        onSelectAll: this.selectAll,
        onSelect: this.select
      }}
      rowHeight={this.props.rowHeight}
      disabled={this.state.loading}
      enabledRowSelection={this.state.formValues.category !== "admin"}
      noSelect={this.state.formValues.category !== "admin"}
      itemsPerPage={this.state.itemsPerPage}
      showFilters={this.state.showFilters}
      CellRenderer={this.props.CellRender}
      hasSidebar={this.props.hasSidebar}
      filtersRef={this.props.filtersRef}
      emptyRenderer={<EmptyOverlay loading={this.state.loading} />}
      onColumnSort={() => { }}
      width={this.state.tableWidth}
      height={400}
      overlayRenderer={
        () =>
          this.state.loading ? <div className={"loading-table-wrapper"}><LoadingStar logo={true} animate={true} title={"Loading..."} customStyle={{ height: "400px" }} /></div> : null
      }
      footerHeight={30}
      footerRenderer={() => {
        return <div className={"adj-table-footer"}>
          <div className={"adj-table-total-projects"}>
            <dl>
              <dt>Total PLPs:</dt>
              <dd>{
                this.state.plpData.length >= 25000
                  ?
                  <span className={"adj-table-batch-size-warning"}>{this.state.plpData.length} (N.B. Maximum search size reached)</span>
                  : this.state.plpData.length
              }</dd>
              <dt>Total amount:</dt>
              <dd>
                <NumberFormat
                  value={this.state.plpData.reduce((accumulator: number, currentValue: any) => {
                    if (!this.state.selectedRows.includes(currentValue._id)) {
                      return accumulator;
                    }
                    return accumulator + currentValue.totalCharge
                  }, 0)}
                  decimalScale={4}
                  fixedDecimalScale={true}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </dd>
              <dt>Total Adjustment:</dt>
              <dd>
                <NumberFormat
                  value={
                    this.state.plpData.reduce((accumulator: number, record: any) => {

                      if (!this.state.selectedRows.includes(record._id)) {
                        return accumulator;
                      }

                      let adjAmount = 0
                      const { formValues, adjPenaltyValues, adminValues } = this.state;

                      let adjPenaltyValue: any;

                      if (formValues.category === "penalty") {
                        adjPenaltyValue = adjPenaltyValues.find((value: any) => value.language === record.targetLang);

                        if (!adjPenaltyValue) {
                          return Number(accumulator + adjAmount);
                        }

                        if (!record.totalCharge || record.totalCharge === 0) {
                          return Number(accumulator + adjAmount);
                        }

                        if (adjPenaltyValue.type === "fixed") {
                          return Number(accumulator + adjPenaltyValue.amount)
                        } else {
                          Number(accumulator + (record.totalCharge * (adjPenaltyValue.amount / 100)).toFixed(4))
                        }

                        if (!adjPenaltyValue.amount) {
                          return 0
                        }

                        if (!adjPenaltyValue.type || adjPenaltyValue.type === "percentage") {
                          return Number(accumulator + (record.totalCharge - (record.totalCharge - (record.totalCharge * (adjPenaltyValue.amount / 100)))));
                        }

                      } else if (formValues.category === "admin") {
                        const adjAdminValue = adminValues.find((value: any) => {
                          return value.projectId === record.projectId && value.language === record.targetLang
                        });

                        if (adjAdminValue) {
                          return Number(accumulator + parseFloat(adjAdminValue.amount));
                        }
                      }

                      if (!formValues.amount) {
                        return 0
                      }

                      if (!formValues.type || formValues.type === "percentage") {
                        return Number(accumulator + (record.totalCharge - (record.totalCharge - (record.totalCharge * (formValues.amount / 100)))));
                      }

                      return Number(accumulator + formValues.amount);
                    }, 0)
                  }
                  decimalScale={4}
                  fixedDecimalScale={true}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </dd>
            </dl>
          </div>
        </div>
      }}
    />
  }

  yardStick: any = createRef<any>();

  renderForm() {
    return <Form
      id={"add-adjustment"}
      name="add-adjustment"
      layout="vertical"
      initialValues={{ type: 'percentage' }}
      onFinish={this.submit}
      ref={this.formRef}
    >
      <Row gutter={0}>
        <Col span={24} ref={this.yardStick}>
          <Form.Item
            label="Internal reference:"
            name="name"
            rules={[{ required: true, message: "Internal name should be set" }]}
          >
            <Input type={"text"} placeholder={"Internal reference ( Not sent to google )"} onChange={(e) => this.onFormChange("name", e.target.value)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={0}>
        <Col span={24}>
          <Form.Item
            label="Adjustment Type:"
            name="category"
          >
            <Select
              placeholder="Select an option"
              value={this.state.formValues.category}
              onChange={(e) => this.onFormChange("category", e)}
            >
              {this.categories.map((cat) => {
                return (
                  <Select.Option key={cat} value={cat} selected={this.state.formValues.category === cat}>
                    {cat[0].toUpperCase() + cat.slice(1)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {this.renderFormElements()}
    </Form>
  }

  addPenaltyRow = () => {
    const
      { adjPenaltyValues, penaltyFormValues } = this.state;

    penaltyFormValues._id = uuid();

    adjPenaltyValues.push(penaltyFormValues);

    this.setState({ adjPenaltyValues, penaltyFormValues: {} }, () => {
      if (Object.keys(this.state.filterData).length) {
        this.getData()
      }
    })

  }

  penaltyFormUpdate(name: string, value: string) {
    const { penaltyFormValues } = this.state;
    penaltyFormValues[name] = value;
    this.setState({ penaltyFormValues });
  }

  penaltyFormValid() {
    const { language, type, amount } = this.state.penaltyFormValues;
    return (language && type !== 0) && (this.state.penaltyFormValues.hasOwnProperty('amount') && amount !== null);
  }

  penaltyRows() {

    const
      { adjPenaltyValues } = this.state,
      selectedLanguages = adjPenaltyValues.map((value: any) => value.language);

    return <>
      {adjPenaltyValues.length > 0 &&
        adjPenaltyValues.filter((lang: string) => !selectedLanguages.includes(lang)).map((row: any) => {
          return this.renderPenaltyRows(row);
        })
      }
      <div className={"penalty-row"} key={"input"}>
        <Form.Item className={"penalty-language"}>
          <Select
            showSearch
            allowClear
            onChange={(e) => this.penaltyFormUpdate("language", e)}
            placeholder={"Select language code"}
            value={this.state.penaltyFormValues.language}
          >
            {this.state.langsAdj.filter((lang: any) => !selectedLanguages.includes(lang)).map((lang: any) => (
              <Select.Option value={lang} key={lang}>
                {lang}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className={"penalty-type"}
          rules={[
            {
              required: true,
              message: "Required",
            },
          ]}>
          <Radio.Group
            name={"type"}
            onChange={(e) => {
              this.penaltyFormUpdate("type", e.target.value);
            }}
            value={this.state.penaltyFormValues.type}
            defaultValue={"percentage"}
          >
            <Radio disabled={this.state.disablePercentage} value="percentage">
              Percent
            </Radio>
            <Radio value="fixed">Fixed Amount</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className={"penalty-amount"}
          rules={[
            {
              required: true,
              message: "Required",
            },
          ]}
        >
          <InputNumber
            defaultValue={0}
            placeholder={
              this.state.formValues.type === "fixed" ? "Insert amount" : "Insert percent"
            }
            onChange={(e) => this.penaltyFormUpdate("amount", e)}
            style={{ width: "98.5%" }}
            precision={2}
            value={this.state.penaltyFormValues.amount}
            min={
              this.state.penaltyFormValues.type === "percentage"
                ? -100
                : -Number.MAX_VALUE
            }
            max={
              this.state.penaltyFormValues.type === "percentage" ? 100 : Number.MAX_VALUE
            }
            addonAfter={
              this.state.penaltyFormValues.type === "percentage" && (
                <PercentageOutlined />
              )
            }
            addonBefore={
              this.state.penaltyFormValues.type === "fixed" && (
                <DollarCircleOutlined />
              )
            }
          />
        </Form.Item>
        <Form.Item className={"penalty-btn"}>
          <Button disabled={!this.penaltyFormValid()} type={"primary"} onClick={this.addPenaltyRow}>
            Add Language
          </Button>
        </Form.Item>
      </div>
    </>
  }

  onAdjPenaltyChange = (adjName: any, adjValue: any, adjId: any) => {

    let { adjPenaltyValues, plpData } = this.state;

    const newPen = adjPenaltyValues.map((pen: any) => {
      if (pen._id === adjId) {
        pen[adjName] = adjValue;
      }
      return pen;
    });

    this.setState({ adjPenaltyValues: newPen });

    if (adjName === 'language') {
      const map: any = {};
      for (const item of adjPenaltyValues) {
        map[item.language] = true;
      }
      const newPlps = plpData.filter((pen: any) => map[pen.targetLang]);
      this.setState({ selectedPLPs: newPlps });
    }

  }

  removeAdjPenaltyRow(row: any) {
    let { adjPenaltyValues, plpData } = this.state;
    adjPenaltyValues = adjPenaltyValues.filter((pen: any) => pen._id !== row._id);

    const map: any = {};
    for (const item of adjPenaltyValues) {
      map[item.language] = true;
    }
    const newPlps = plpData.filter((pen: any) => map[pen.targetLang]);

    this.setState({ adjPenaltyValues, plpData: newPlps, selectedPLPs: newPlps });
  }

  renderPenaltyRows = (row: any) => {
    return (
      <div className={"penalty-row"} key={row._id}>
        <Form.Item className={"penalty-language"}>
          <Select
            showSearch
            allowClear
            onChange={(e) => this.onAdjPenaltyChange("language", e, row._id)}
            placeholder={"Select language code"}
            defaultValue={row.language ? row.language : ""}
          >
            {this.state.langsAdj.map((lang: any) => (
              <Select.Option value={lang} key={lang}>
                {lang}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className={"penalty-type"}>
          <Radio.Group
            name={"type"}
            onChange={(e) => {
              this.onAdjPenaltyChange("type", e.target.value, row._id);
            }}
            value={row.type}
          >
            <Radio disabled={this.state.disablePercentage} value="percentage">
              Percent
            </Radio>
            <Radio value="fixed">Fixed Amount</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className={"penalty-amount"}
          rules={[
            {
              required: true,
              message: "Required",
            },
          ]}
        >
          <InputNumber
            defaultValue={row.amount}
            placeholder={
              row.type === "fixed" ? "Insert amount" : "Insert percent"
            }
            onChange={(e) => this.onAdjPenaltyChange("amount", e, row._id)}
            style={{ width: "98.5%" }}
            precision={2}
            min={
              row.type === "percentage"
                ? -100
                : -Number.MAX_VALUE
            }
            max={
              row.type === "percentage" ? 100 : Number.MAX_VALUE
            }
            addonAfter={
              row.type === "percentage" && (
                <PercentageOutlined />
              )
            }
            addonBefore={
              row.type === "fixed" && (
                <DollarCircleOutlined />
              )
            }
          />
        </Form.Item>
        <Form.Item className={"penalty-btn"}>
          <Button danger onClick={() => this.removeAdjPenaltyRow(row)}>
            <DeleteOutlined />
          </Button>
        </Form.Item>
      </div>
    );
  };

  renderFormElements() {

    const
      formValues: Record<string, any> = this.state.formValues;

    if (!formValues.category) {
      return null;
    }

    return <>
      <hr />
      <Row gutter={0}>
        <Col span={24}>
          {["penalty", "admin"].indexOf(formValues.category) === -1 && (
            <>
              <Form.Item name="type" label="Value:" required>
                <Radio.Group
                  onChange={(e) => this.onFormChange("type", e.target.value)}
                // value={form.getFieldValue("type")}
                >
                  <Radio value="percentage">
                    Percent
                  </Radio>
                  <Radio value="fixed">Fixed Amount</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <InputNumber
                  name={"amount"}
                  value={formValues.amount}
                  placeholder={
                    formValues.type === "fixed"
                      ? "Insert amount"
                      : "Insert percent"
                  }
                  onChange={(e) => this.onFormChange("amount", e)}
                  style={{ width: "98.5%" }}
                  precision={2}
                  min={
                    formValues.type === "percentage"
                      ? -100
                      : -Number.MAX_VALUE
                  }
                  max={
                    formValues.type === "percentage"
                      ? 100
                      : Number.MAX_VALUE
                  }
                  addonAfter={
                    formValues.type === "percentage" && (
                      <PercentageOutlined />
                    )
                  }
                  addonBefore={
                    formValues.type === "fixed" && (
                      <DollarCircleOutlined />
                    )
                  }
                />
              </Form.Item>
              <hr />
            </>
          )}
        </Col>
      </Row>
      <>
        <Row gutter={10}>
          <Col span={24}>
            {this.renderDragger()}
          </Col>
        </Row>
        <Row gutter={0}>
          <Col span={24}>
            <>
              {
                this.state.formValues.category === "penalty" && this.penaltyRows()
              }
              {this.renderFilters()}
              {this.renderTable()}
            </>
          </Col>
        </Row>
        <hr />
      </>

      {formValues.category && (
        <>
          <Row gutter={0}>
            <Col span={24}>
              <Form.Item label="PO Number:" required>
                <Form.Item noStyle name={"useNewPONumber"}>
                  <Radio.Group
                    value={formValues.useNewPONumber || true}
                    onChange={(e) => this.onFormChange("useNewPONumber", e.target.value)}
                    defaultValue={true}
                  >
                    <Space direction="vertical">
                      <Tooltip
                        title={!this.state.allPlpsHavePONumber ? 'Not all selected PLPS have a PO Number, please specify PO Number below' : ''}
                        placement="right"
                        color={"var(--red)"}
                        overlayInnerStyle={{ width: "508px" }}
                      >
                        <Radio
                          value={true}
                          disabled={!this.state.allPlpsHavePONumber}
                        >
                          Use Project PO Number
                          {!this.state.allPlpsHavePONumber && <InfoCircleOutlined style={{ color: "var(--red)", marginLeft: "3px" }} />}
                        </Radio>
                      </Tooltip>
                      <Radio value={false}>Specify PO Number</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="PONumber"
                  rules={[
                    {
                      required: formValues.useNewPONumber ? false : true,
                      message: "Please type PO Number",
                    },
                  ]}
                >
                  <Input
                    disabled={formValues.useNewPONumber === undefined || formValues.useNewPONumber ? true : false}
                    placeholder="Will be sent to google in place of project PO Number"
                    value={formValues.PONumber}
                    style={{ width: "98.5%" }}
                    onChange={(e) => {
                      this.onFormChange("PONumber", e.target.value);
                    }}
                  />
                </Form.Item>

              </Form.Item>
            </Col>
          </Row>
          <Row gutter={0}>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Description:"
              >
                <TextArea rows={4}
                  maxLength={500}
                  placeholder={"Will be sent to google where applicable"}
                  onChange={(e) => this.onFormChange("description", e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </>
  }

  closeAdjModal = () => {
    this.props.toggle();
    this.setState(this.defaultState);
  }

  updateAllFilters(updated: any) {
    const { filterData } = this.state;
    filterData[updated.target.name] = updated.target.value;
    this.setState({ filterData })
  }

  onUpdateFilter = (updated: any) => {
    const { filterData } = this.state;
    if (updated.target.value) {
      filterData[updated.target.name] = updated.target.value;
    }
    else {
      delete filterData[updated.target.name];
    }
    this.setState({ filterData }, this.getData)
  }

  onClearFilter(update: any) {
    console.log(update)
  }

  clear() {
    this.setState({ filterData: {}, selectedPLPs: [], plpData: [] })
  }

  getData = () => {

    const
      { gpSocket } = this.props.context,
      socket = gpSocket.adj;

    this.setState({ reqId: uuid(), loading: true, plpData: [], selectedPLPs: [] }, () => {

      if (!Object.keys(this.filtersToQuery()).length) {
        this.setState({ loading: false });
        return;
      }

      socket.emit(`plpDetailsBySearch`, {
        filter: this.filtersToQuery(),
        sort: { projectId: -1 },
        pagination: {
          pageNumber: 1,
          resultsPerPage: 25000
        },
        reqId: this.state.reqId
      }, () => {
        this.setState({ loading: false })
      });
    })
  }

  filtersRef: any = createRef();

  onApplyMoreLess(list: any[]) {
    const newActiveFilters = this.filters.filter(filter => list.includes(filter.name));
    this.setState({ activeFilters: newActiveFilters });
  }

  onChangeMoreLess(list: any[]) {
      this.setState({ selectedFilters: list })
  }

  renderFilters() {

    const { formValues, activeFilters }: any = this.state;

    if (formValues.category === "admin") return null;

    if (this.state.formValues.category === "penalty" && !this.state.adjPenaltyValues.length) {
      return null;
    }

    const activeFiltersNames = new Set(activeFilters.map((filter:any) => filter.name));

    const notActiveFilters = this.filters
        .filter((item:any) => !activeFiltersNames.has(item.name))
        .map((item:any) => ({ ...item, active: false }));

    const allFiltersData: any = [...activeFilters, ...notActiveFilters];

    return (

      <div className={"filterWrapperRef"} ref={this.filtersRef}>
        {activeFilters.length && <FiltersBar
          title={"PLP"}
          onChange={this.updateAllFilters}
          filters={allFiltersData.filter((filter: any) => !(formValues.category === "penalty" && filter.name === "targetLanguageCode"))
            .map((filter: any) => {
              return {
                id: filter.id,
                component: filter.component,
                title: filter.title,
                key: filter.name,
                name: filter.name,
                value: Array.isArray(filter.name)
                  ? filter.name.map(
                    (name: any) => this.state.filterData[name]
                  )
                  : this.state.filterData[filter.name],
                // onRemove: this.onRemoveFromList,
                onChange: this.onUpdateFilter,
                onClearFilter: this.onClearFilter,
                disabled: false,
                active: filter.active
              };
            })}
          savedFilters={this.state.savedFilters}
          filtersLoading={this.state.filtersLoading}
          currentSavedFilter={this.state.currentSavedFilter}
          appliedFilters={this.state.selectedFilters}
          onClearAll={(params: any) => {
            this.clear();
          }}
          loading={this.state.loading}
          additionalButtons={[
            <Button
              size={"small"}
              disabled={this.state.loading}
              key={"reload"}
              onClick={async () => {
                this.getData()
              }}
            >
              <ReloadOutlined spin={this.state.loading} /> Reload
            </Button>
          ]}
          controlsEnabled={false}
          onApplyMoreLess={(list) => this.onApplyMoreLess(list)}
          onChangeMoreLess={(list) => this.onChangeMoreLess(list)}
        />}
      </div>

    );
  }

  isValid() {
    const
      { loading, creating, formValues, selectedRows } = this.state,
      {
        name,
        PONumber,
        useNewPONumber,
      } = formValues;

    if (loading || creating) {
      return true;
    }

    if (!selectedRows.length) {
      return true;
    }

    if (!name) {
      return true
    }

    if ((PONumber === "" || PONumber === undefined) && useNewPONumber === false) {
      return true
    }

    return false;
  }

  render() {

    const {
      creating,
    } = this.state;

    return (
      <Modal
        title="Add Adjustment"
        width={"100vw"}
        open={this.props.show}
        onCancel={this.closeAdjModal}
        maskClosable={false}
        // footer={null}
        zIndex={1000}
        footer={[
          <Form.Item key="footer-form-item">
            <Space style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button key="cancel" type="default" onClick={this.closeAdjModal}>
                Cancel
              </Button>
              <Button
                onClick={this.submit}
                loading={creating}
                disabled={this.isValid()}
                icon={<PlusOutlined />}
                type="primary"
              >
                Create
              </Button>
            </Space>
          </Form.Item>,
        ]}
      >
        {this.renderForm()}
      </Modal>
    );

  }
}

export default function (props: any) {
  const
    context = useMercuryContext(),
    [modal, contextHolder] = Modal.useModal();

  return <AdjustmentCreateModal context={context} modalContext={modal} modalHolder={contextHolder} {...props} />
}
