import React, {ReactElement} from "react";
import {Button, Timeline} from "antd";
import TimezoneConverter from "../../timezone-converter";
import moment from "moment/moment";
import {Link} from "react-router-dom";


export interface NotificationProps {
    id: string;
    title: string;
    received: Date|string;
    body: string|ReactElement;
    url?:string;
}

export default function Notification( notification:NotificationProps ) {
    return (
        <div className={"notification"}>
            <span className={"notification-item"}>
                <span className={"notification-title"}>
                  {notification.title}
                </span>
                {/* ToDo: Service does not currently suport deleting one at a time. */}
                {/* <span className={"notification-actions"}>*/}
                {/*  <Tooltip title="Delete notification">*/}
                {/*    <Button type={"danger"} size={"small"} shape={"circle"}>*/}
                {/*      <DeleteFilled />*/}
                {/*    </Button>*/}
                {/*  </Tooltip>*/}
                {/*</span>*/}
                <span className={"notification-timestamp"}>
                  <TimezoneConverter date={notification.received} />
                  <span className={"notification-fromnow"}>
                    {moment(new Date(notification.received)).fromNow()}
                  </span>
                </span>
                <span>
                  {notification.body}
                </span>
              </span>
            { notification.url && <Link to={notification.url} className={"notification-link"}>
              <Button type="primary">Go</Button>
            </Link> }
        </div>
    )
}
