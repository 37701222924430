import React, { useEffect, useState } from "react";
import { useMercuryContext } from "../../user-context";
import LoadingStar from "../../ui/LoadingStar";
import Message from "../../ui/Message";
import PageTitle from "../../ui/PageTitle";
import TimezoneConverter from "../../timezone-converter";

import {Button, Descriptions} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import {convertObjKeyToName} from '../../utils/helpers';

async function getReport(id, setReport, client) {
  const { data: report } = await client.get(`/core-rs-v1/queuedreports/${id}`);
  setReport(report);
  return report;
}

export default function Report(props) {

  const
    TimedOut = ( record ) => {

      if ( record.status.toUpperCase() !== "PENDING" ) {
        return record.status;
      }

      const
        fromNow = new Date( record.dateQueued ).getTime() + ( 5 * 60 * 60 * 1000 ),
        TimedOut = new Date().getTime() > fromNow;

      if ( TimedOut ) {
        return "TIMED OUT";
      }

      return record.status;
    },
    { location } = props,
    { queuedReportId } = props,
    { client } = useMercuryContext(),
    [error, setError] = useState(null),
    [report, setReport] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        await getReport(queuedReportId, setReport, client);
      } catch (e) {
        console.error(e);
        return setError(true);
      }
    })();
  }, [client, queuedReportId, setReport, setError, location]);

  if (error) {
    return (
      <Message
        first={"Something went wrong"}
        second={`Report ${queuedReportId} could not be found.`}
      />
    );
  }

  if (!report) {
    return (
      <LoadingStar
        title={
          <div>
            Retrieving details for report ID: <br /> {queuedReportId}
          </div>
        }
      />
    );
  }


const reportCriteria = Object.entries(report.parameters)

  return (
    <>
      <PageTitle title={report.name} />
      <Descriptions
        bordered={true}
        column={1}
        size={"small"}
        className="report-details"
      >
        <Descriptions.Item label="ID:">
          {report.queuedReportId}
        </Descriptions.Item>

        <Descriptions.Item label="Queued by:">
          {report.queuedBy}
        </Descriptions.Item>

        <Descriptions.Item label="Date queued:">
          <TimezoneConverter date={report.dateQueued} />
        </Descriptions.Item>

        <Descriptions.Item label="Date completed:">
          {report.dateCompleted ? (
            <TimezoneConverter date={report.dateCompleted} />
          ) : (
            "Not complete"
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Status:">
          {TimedOut(report)}
        </Descriptions.Item>

        <Descriptions.Item label="Description:">
          {report.parameters.reportDescription}
        </Descriptions.Item>

        {
          report.errorMessage && report.errorMessage !== 'none'
            && <Descriptions.Item label="Error:">
                {report.errorMessage}
              </Descriptions.Item>
        }
        <Descriptions.Item label="Report Criteria">
         {
           reportCriteria.map((item, index)=>{
             let key = '',
             value = item[1],
             criteria = null;

            // fix for different names
            switch (item[0]) {
              case 'catToolStatus':
                key = 'Status';
                break;
              case 'turnaroundTimeUrgency':
                key = 'Urgency';
                break;
              case 'reportDescription':
                key = '';
                value = '';
                break;
              default:
                key = item[0];
            }

                if(Array.isArray(value)) {
                  if(value.length) {
                    criteria = <p key={index} style={{margin:'0'}}><span style={{color:'var(--primary-color)'}}>{convertObjKeyToName(key)}: </span>{value.join(', ')}</p>
                  }
                } else {
                  if(value) {
                    criteria = <p key={index} style={{margin:'0'}}><span style={{color:'var(--primary-color)'}}>{convertObjKeyToName(key)}: </span>{value}</p>
                  }
                }

             return criteria;
           })
         }
        </Descriptions.Item>
      </Descriptions>
      {
        report.url && <Button
          text="Download"
          type="primary"
          icon={<DownloadOutlined />}
          href={report.url} download={true} target={"_blank"}
        >Download report</Button>
      }
    </>
  );
}
