import React, {
  useEffect,
  useState,
  useCallback,
  FunctionComponent,
} from "react";
import { Helmet } from "react-helmet";

import { useMercuryContext } from "../user-context";

import { Button, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";

import PageTitle from "../ui/PageTitle";
import BackTopButton from "../ui/BackTopButton";
import Total from "../ui/Total";

import { sorter } from "../utils/helpers";

import "./QuarantinedProjecs.scss";
import { Error } from "../stories/Errors/Error";
import { failedNotification, infoNotification } from "../utils/notifications";

interface QuarantinedProject {
  projects: number;
  product: string;
  key?: React.Key;
  name?: string;
}

const QuarantineProjects: FunctionComponent = () => {
  const { client } = useMercuryContext(),
    [projects, setProjects] = useState<QuarantinedProject[]>([]),
    [loading, setLoading] = useState<boolean>(true),
    [error, setError] = useState<boolean>(false),
    [total, setTotal] = useState<number>(0),
    [selectedRows, setSelectedRows] = useState<QuarantinedProject[]>([]);

  const getData = useCallback(async () => {
    setLoading(true);

    const { data } = await client.get("/google-gp-v1/QuarantinedProjects");

    setProjects(
      data.map((p: QuarantinedProject, index: number) => {
        return { ...p, key: index };
      })
    );

    setTotal(
      data.reduce(
        (accumulator: any, currentValue: any) =>
          accumulator + currentValue.projects,
        0
      )
    );

    setError(false);
    setLoading(false);
  }, [client]);

  useEffect(() => {
    try {
      getData();
    } catch (error: any) {
      console.error(error.toString());
      setLoading(false);
      setTotal(0);
      setError(true);
    }

    return () => {
      setProjects([]);
    };
  }, [client, getData]);

  const handleClickReDownload = async () => {
    if (selectedRows.length !== 0) {
      const productList: string[] = [];
      let totalProjects = 0;

      selectedRows.forEach((p: any) => {
        totalProjects = totalProjects + p.projects;
        productList.push(p.product);
      });

      try {
        await client.post(`/google-gp-v1/QuarantinedProjects/requeue`, {
          products: productList,
        });

        infoNotification(
          "Success",
          `${totalProjects} projects have been added to re-download queue. Products: ${productList.join(
            ", "
          )}`
        );

        getData();

      } catch (error) {
        console.error("Error on /QuarantinedProjects/requeue");
        console.error(error);

        failedNotification(
          "Error",
          `Failed to re-download ${totalProjects} projects.`
        );
      }
    }

    setSelectedRows([]);
  };

  const columns: ColumnsType<QuarantinedProject> = [
    {
      title: "Product",
      dataIndex: "product",
      sorter: (a: QuarantinedProject, b: QuarantinedProject) =>
        sorter(a.product, b.product),
    },
    {
      title: "Projects",
      dataIndex: "projects",
      sorter: (a: QuarantinedProject, b: QuarantinedProject) =>
        sorter(a.projects, b.projects),
      width: 100,
    },
  ];

  if (error) {
    return <Error reload={getData} error={"Please reload"} />;
  }

  return (
    <div className="manage-page manage-quarantined-projects">
      <Helmet>
        <title>Manage Quarantined Projects - Mercury © RWS</title>
      </Helmet>
      <PageTitle title="Quarantined Projects" />
      <div className="top">
        <div className="actions">
          <Tooltip
            title={
              selectedRows.length === 0
                ? "Select at least 1 item"
                : "Re-download selected quarantined projects"
            }
            placement="right"
            color={"var(--tertiary-color)"}
          >
            <Button
              type="primary"
              disabled={selectedRows.length === 0 ? true : false}
              onClick={() => handleClickReDownload()}
            >
              Re-download
            </Button>
          </Tooltip>
        </div>
        <Total number={total} text="quarantined projects" />
      </div>
      <Table
        columns={columns}
        dataSource={projects}
        loading={loading}
        className="manage-page-table"
        size="small"
        rowSelection={{
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: QuarantinedProject[]
          ) => {
            setSelectedRows(selectedRows);
          },
          selectedRowKeys: selectedRows.map(
            (r: QuarantinedProject) => r.key
          ) as React.Key[],
        }}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: 200,
        }}
      />
      <BackTopButton />
    </div>
  );
};

export default QuarantineProjects;
