import {Component, useEffect, useState} from "react";
import {UserContext} from "../user-context";
import {Button, Dropdown, Form, Input, MenuProps, Modal, Space} from "antd";
import {DollarOutlined} from "@ant-design/icons";

import {Socket} from "socket.io-client";

export const InvoicingBulkAction = ( props:{selectedRows:any[], userContext:UserContext, contentType:"PLP"|"project", reload:()=>any}) => {

    const
        estimateStates = [ "NEW", "ESTIMATE_FAILED", "ESTIMATE"],
        accrueStates = [ "ESTIMATE", "ACCRUAL_FAILED", "ACCRUAL"],
        invoiceStates = [ "ACCRUAL", "INVOICE_FAILED" ],
        projectSocket:Socket = props.userContext.gpSocket.project,
        plpSocket:Socket = props.userContext.gpSocket.plp,
        estimate = new Set(),
        accrue = new Set(),
        invoice = new Set(),
        invoiceNumber = new Set(),
        [ loading, setLoading ] = useState( false ),
        [ error, setError ] = useState<string|null>( null ),
        [ invoiceNumberStr, setInvoiceNumberStr ] = useState( "" ),
        [ confirmProps, setConfirmProps ] = useState<Record<string, any>>({
            open: false,
            type: null,
            ids: []
        })

    const setup = ( rows:any ) => {

        estimate.clear()
        accrue.clear()
        invoice.clear();
        invoiceNumber.clear();

        rows.forEach( ( row:any ) => {

            if ( row.varStatus !== "INVOICE" ) {
                invoiceNumber.add( row.key );;
            }

            if ( estimateStates.indexOf( row.varStatus ) !== -1 ) {
                estimate.add( row.key )
            }
            if ( accrueStates.indexOf( row.varStatus ) !== -1 ) {
                accrue.add( row.key );
            }
            if ( invoiceStates.indexOf(row.varStatus) !== -1 ) {
                invoice.add( row.key );
            }
        } );
    }

    const invoiceSubmissionModal = () => {
        return <Modal
            open={confirmProps.open}
            title={<span>{`Send ${confirmProps.ids.length} ${props.contentType}(s) to ${confirmProps.type}...`}</span>}
            onOk={() => setConfirmProps({
                open: false,
                type: null,
                ids: []
            })}
            onCancel={() => setConfirmProps({
                open: false,
                type: null,
                ids: []
            })}
            footer={[
                <Button key="back" onClick={() => setConfirmProps({
                    open: false,
                    type: null,
                    ids: []
                })}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={submitInvoiceSubmissions}>
                    Submit
                </Button>
            ]}
        >
            {error ? <p>{error}</p> : null }
            {
                props.contentType === "project" ?
                    <>
                        <p style={{paddingBottom: "10px"}}>Clicking submit will queue all applicable plps associated
                            with these projects to be sent to {confirmProps.type}.</p>
                        <p style={{paddingBottom: "10px"}}>Projects will automatically update in real time.</p>
                    </>
                    :  <>
                        <p style={{paddingBottom: "10px"}}>Clicking submit will queue all selected applicable plps to be sent to {confirmProps.type}.</p>
                        <p style={{paddingBottom: "10px"}}>Project data will automatically update in real time.</p>
                    </>
            }

            <p className={"warning"}><b>This action cannot be undone.</b></p>
        </Modal>
    }

    const invoiceNumberModal = () => {
        return <Modal
            open={confirmProps.open}
            title={<span>Update invoice number for {invoiceNumber.size} project(s)</span>}
            onOk={() => setConfirmProps({
                open: false,
                type: null,
                ids: []
            })}
            onCancel={() => setConfirmProps({
                open: false,
                type: null,
                ids: []
            })}
            footer={[
                <Button key="back" onClick={() => setConfirmProps({
                    open: false,
                    type: null,
                    ids: []
                })}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} disabled={invoiceNumberStr.length === 0} onClick={submitInvoiceNumber}>
                    Submit
                </Button>
            ]}
        >
            {error ? <p>{error}</p> : null }
            <Form>
                <Form.Item<string>
                    label="Invoice number"
                    name="invoiceNo"
                    rules={[{ required: true, message: 'Please input a invoice number' }]}
                >
                    <Input value={invoiceNumberStr} onChange={(e) => setInvoiceNumberStr(e.target.value)} />
                </Form.Item>
            </Form>
        </Modal>
    }

    const modal = () => {
        if ( confirmProps.type === "setInvoiceNumber" ) {
            return invoiceNumberModal()
        }
        return invoiceSubmissionModal();
    }

    const submitInvoiceNumber = () => {
        setLoading( true );
        projectSocket.emit( "bulkAssignInvoiceNumber", { invoiceName: invoiceNumberStr, ids: Array.from( invoiceNumber) }, (err?:Error) => {
            if ( err ) {
                setError( err.toString() );
                setLoading( false );
                return null;
            }

            setLoading( false );

            setConfirmProps({
                open: false,
                type: null,
                ids: []
            })

            setInvoiceNumberStr("" )

            props.reload()
        })
    }

    const submitInvoiceSubmissions = () => {
        setLoading( true );
        setError( null )
        let
            event = "bulkEstimate",
            ids = Array.from( estimate );

        switch (confirmProps.type) {
            case "estimate":
                ids = Array.from( estimate );
                break;
            case "accrue":
                event = "bulkAccrue";
                ids = Array.from( accrue );
                break;
            case "invoice":
                event = "bulkInvoice";
                ids = Array.from( invoice );
                break;
        }

        projectSocket.emit( event, { ids }, (err?:Error) => {
            if ( err ) {
                setError( err.toString() );
                setLoading( false );
                return null;
            }

            setLoading( false );

            setConfirmProps({
                open: false,
                type: null,
                ids: []
            })

            setInvoiceNumberStr("" )
            setError( null )
        })
    }

    setup( props.selectedRows );

    useEffect(() => {
        setup( props.selectedRows );
    }, [ props.selectedRows, accrue, invoice, estimate, invoiceNumber ]);

    useEffect( () => {
        setInvoiceNumberStr( "" )
        setError( null )
    }, [props.selectedRows])

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Button block={true} className={"action-btn edit-btn"} target="_blank" rel="noopener noreferrer" disabled={estimate.size === 0}
                    onClick={() => setConfirmProps({
                        open: true,
                        type: "estimate",
                        ids: Array.from(estimate)
                    })}
                >
                    Estimate ( {estimate.size} )
                </Button>
            ),
        },
        {
            key: '2',
            label: (
                <Button block={true} className={"action-btn edit-btn"} target="_blank" rel="noopener noreferrer" disabled={accrue.size === 0}
                    onClick={() => setConfirmProps({
                        open: true,
                        type: "Accrue",
                        ids: Array.from(accrue)
                    })}
                >
                    Accrue ( {accrue.size} )
                </Button>
            ),
        },
        {
            key: '3',
            label: (
                <Button block={true} className={"action-btn edit-btn ant-btn-dangerous"} target="_blank"
                        rel="noopener noreferrer" disabled={invoice.size === 0}
                        onClick={() => setConfirmProps({
                            open: true,
                            type: "invoice",
                            ids: Array.from(invoice)
                        })}
                >
                    Invoice ( {invoice.size} )
                </Button>
            ),
        }
    ];

    if ( props.contentType === "project" ) {
        items.push({
            key: '4',
            label: (
                <Button block={true} className={"action-btn edit-btn"} target="_blank" rel="noopener noreferrer" disabled={invoiceNumber.size === 0}
                        onClick={() => setConfirmProps({
                            open: true,
                            type: "setInvoiceNumber",
                            ids: Array.from(invoiceNumber)
                        })}
                >
                    Set invoice number ( {invoiceNumber.size} )
                </Button>
            ),
        })
    }

    return <>

        {modal()}

        <Dropdown disabled={props.selectedRows.length === 0 || confirmProps.open } menu={{items}} placement="top"
                  arrow={{pointAtCenter: true}}>
        <Button
                className="action-btn edit-btn"
                icon={<DollarOutlined />}
                type="primary"
            >
                Invoicing
            </Button>
        </Dropdown>;
    </>
}
