import React, { useState } from "react";
import TimezoneConverter from "../../../timezone-converter";
import Total from "../../../ui/Total";

import { Table, Typography, Skeleton, Input, Spin, Row, Tooltip } from "antd";

import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

const ProductTable = ({ values, handlers }) => {
  const { products, sortedInfo, editing, total, pagination } = values;
  const { handleChangeTable, handleClickEdit } = handlers;

  const { Text, Link, Title } = Typography;
  const { Search } = Input;

  const [filterTable, setFilterTable] = useState(null);

  const data =
    products &&
    products.map((item) => {
      return {
        key: item._id || item.id,
        title: item.title,
        vendor: item.vendor,
        description: item.description,
        productArea: item?.productArea?.title,
        productAreaId: item?.productArea?._id || item?.productArea?.id,
        costCode: item?.costCode?.code,
        costCodeId: item?.costCode?._id || item?.costCode?.id,
        nonExtendable: item?.isNonExtendable,
        queryVariation: item?.isQueryVariation,
        active: item?.isActive,
        createDate: item?.createdAt || item?.created_at,
        updateDate: item?.updatedAt || item?.updated_at,
      };
    });

  const DescriptionPanel = ({ description, id }) => {
    const [descriptionOpen, setDescriptionOpen] = useState(false);
    return (
      <div key={id}>
        {descriptionOpen ? (
          <span style={{ float: "left" }}>{description}</span>
        ) : (
          <span className={"ellipsis"}>{description}</span>
        )}
        {description?.length > 30 && (
          <span
            style={{
              color: "var(--tertiary-color)",
              cursor: "pointer",
              float: descriptionOpen ? "right" : "left",
            }}
            onClick={() => setDescriptionOpen((prev) => !prev)}
          >
            {descriptionOpen ? (
              <MinusSquareOutlined />
            ) : (
              <PlusSquareOutlined />
            )}
          </span>
        )}
      </div>
    );
  };

  const columns = [
    {
      title: "Product Title",
      dataIndex: "title",
      width: 275,
      fixed: "left",
      sorter: (a, b) => {
        a = a.title === null ? "" : a.title;
        b = b.title === null ? "" : b.title;
        return a.localeCompare(b);
      },
      sortOrder: sortedInfo.field === "title" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.title === editing.editingRow.title ? (
            text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text
        ),
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      width: 80,
      fixed: "left",
      sorter: (a, b) => {
        a = a.vendor === null ? "" : a.vendor;
        b = b.vendor === null ? "" : b.vendor;
        return a.length - b.length || a.localeCompare(b);
      },
      sortOrder: sortedInfo.field === "vendor" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.title === editing.editingRow.title ? (
            text[0].toUpperCase() + text.substring(1)
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text[0].toUpperCase() + text.substring(1)
        ),
    },
    {
      title: "Product Area",
      dataIndex: "productArea",
      width: 200,
      sorter: (a, b) => {
        a = a.productArea === null ? "" : a.productArea;
        b = b.productArea === null ? "" : b.productArea;
        return a.localeCompare(b);
      },
      sortOrder: sortedInfo.field === "productArea" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.title === editing.editingRow.title ? (
            text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text
        ),
    },
    {
      title: "Cost Code",
      dataIndex: "costCode",
      width: 150,
      sorter: (a, b) => {
        a = a.costCode === null ? "" : a.costCode;
        b = b.costCode === null ? "" : b.costCode;
        return a.length - b.length || a.localeCompare(b);
      },
      sortOrder: sortedInfo.field === "costCode" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.title === editing.editingRow.title ? (
            text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text
        ),
    },
    {
      title: "Create Date",
      dataIndex: "createDate",
      width: 180,
      render: (text, record) =>
        editing.isEditing && record.title !== editing.editingRow.title ? (
          <Skeleton active size="small" title={true} paragraph={false} />
        ) : text != null ? (
          <Text>
            <TimezoneConverter date={text} />
          </Text>
        ) : (
          ""
        ),
    },
    {
      title: "Last Update Date",
      dataIndex: "updateDate",
      width: 180,
      render: (text, record) =>
        editing.isEditing && record.title !== editing.editingRow.title ? (
          <Skeleton active size="small" title={true} paragraph={false} />
        ) : text != null ? (
          <Text>
            <TimezoneConverter date={text} />
          </Text>
        ) : (
          ""
        ),
    },
    {
      title: "Non extendable without Fast Approval",
      dataIndex: "nonExtendable",
      width: 150,
      sorter: (a, b) => {
        a = a.nonExtendable === null ? "" : a.nonExtendable;
        b = b.nonExtendable === null ? "" : b.nonExtendable;
        return a - b;
      },
      sortOrder: sortedInfo.field === "nonExtendable" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.title === editing.editingRow.title ? (
            record.nonExtendable === true ? (
              <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
            ) : (
              <CloseOutlined style={{ color: "var(--red)" }} />
            )
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : record.nonExtendable === true ? (
          <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
        ) : (
          <CloseOutlined style={{ color: "var(--red)" }} />
        ),
    },
    {
      title: "Query Variation",
      dataIndex: "queryVariation",
      width: 100,
      sorter: (a, b) => {
        a = a.queryVariation === null ? "" : a.queryVariation;
        b = b.queryVariation === null ? "" : b.queryVariation;
        return a - b;
      },
      sortOrder: sortedInfo.field === "queryVariation" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.title === editing.editingRow.title ? (
            record.queryVariation === true ? (
              <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
            ) : (
              <CloseOutlined style={{ color: "var(--red)" }} />
            )
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : record.queryVariation === true ? (
          <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
        ) : (
          <CloseOutlined style={{ color: "var(--red)" }} />
        ),
    },
    {
      title: "Active",
      dataIndex: "active",
      width: 70,
      sorter: (a, b) => {
        a = a.active === null ? "" : a.active;
        b = b.active === null ? "" : b.active;
        return a - b;
      },
      sortOrder: sortedInfo.field === "active" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.title === editing.editingRow.title ? (
            record.active === true ? (
              <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
            ) : (
              <CloseOutlined style={{ color: "var(--red)" }} />
            )
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : record.active === true ? (
          <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
        ) : (
          <CloseOutlined style={{ color: "var(--red)" }} />
        ),
    },
    {
      title: () => (
        <Tooltip
          title={
            <div>
              <p style={{ marginBottom: "3px" }}>
                Reason for changing product status
              </p>
            </div>
          }
          placement="right"
          color={"var(--tertiary-color)"}
        >
          {"Description"}
          <InfoCircleOutlined
            style={{
              marginLeft: "5px",
              color: "var(--tertiary-color)",
              cursor: "help",
            }}
          />
        </Tooltip>
      ),
      dataIndex: "description",
      width: 200,
      sortOrder: sortedInfo.field === "description" && sortedInfo.order,
      render: (text, record, index) => (
        <DescriptionPanel id={index} description={text} />
      ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      width: 75,
      render: (text, record) =>
        editing.isEditing && record.title !== editing.editingRow.title ? (
          <Skeleton active size="small" title={true} paragraph={false} />
        ) : (
          <Link
            onClick={() => {
              handleClickEdit(record);
            }}
            style={{
              marginRight: "10px",
            }}
          >
            <EditOutlined /> Edit
          </Link>
        ),
    },
  ];

  const handleSearch = (value) => {
    const filterTable = data.filter((item) =>
      item.title.toString().toLowerCase().includes(value.toLowerCase())
    );

    setFilterTable(filterTable);
  };

  return (
    <>
      <Search
        style={{ width: "300px", margin: "0 0 10px 0" }}
        placeholder="Search by title"
        onSearch={handleSearch}
        allowClear
        enterButton
      />
      <Row className="title-wrap">
        <Title level={5} style={{ margin: 0 }}>
          PRODUCT LIST
          {editing.isEditing && (
            <Spin size="small" style={{ marginLeft: "10px" }} />
          )}
        </Title>
        <Total number={total} text="products" />
      </Row>
      <Table
        columns={columns}
        dataSource={filterTable == null ? data : filterTable}
        size="small"
        className="manage-page-table"
        onChange={handleChangeTable}
        pagination={{
          position: ["bottomCenter"],
          pageSize: pagination.pageSize,
          current: pagination.currentPage,
        }}
        scroll={{ x: 1500 }}
        sticky={{ offsetHeader: 64 }}
        rowKey={(record) => record.key}
      />
    </>
  );
};

export default ProductTable;
