import React, { useState, useCallback, useEffect, useRef } from "react";
import { useMercuryContext } from "../user-context";
import { Helmet } from "react-helmet";

import { Form } from "antd";

import "../components/managePages/api-clients/manage-api-clients.scss";

import PageTitle from "../ui/PageTitle";
import BackTopButton from "../ui/BackTopButton";
import { sorter } from "../utils/helpers";

import {
  failedNotification,
  successfulNotification,
} from "../utils/notifications";

import AddClientApi from "../components/managePages/api-clients/AddClientApi";
import ApiClientsTable from "../components/managePages/api-clients/ApiClientsTable";

import NewApiClientConfirmationModal from "../components/managePages/api-clients/NewApiClientConfirmationModal";
import EditClientApi from "../components/managePages/api-clients/EditClientApi";

const defaultNewApiClientModal = { isActive: false, data: {} },
  defaultEditingValue = { isEditing: false, editingRow: "" };

const ManageApiClients = () => {
  const { umsClient: Client, client:mcasClient, flags } = useMercuryContext(),
    [isLoading, setIsLoading] = useState(false),
    [apiClients, setApiClients] = useState(null),
    [sortedInfo, setSortedInfo] = useState(""),
    [visibleNewClientApiDrawer, setVisibleNewClientApiDrawer] = useState(false),
    [total, setTotal] = useState(0),
    [newApiClientForm] = Form.useForm(),
    [addedNewClientApiModal, setAddedNewClientApiModal] = useState(
      defaultNewApiClientModal
    ),
    [editing, setEditing] = useState(defaultEditingValue),
    [visibleEditDrawer, setVisibleEditDrawer] = useState(false),
    [editingForm] = Form.useForm(),
    editFormRef = useRef(null),
    [permissionsList, setPermissioonsList] = useState([]),
    [applicationsList, setApplicationsList] = useState([]); // customer application list

  // fetch all permissions
  const fetchPermissionsList = useCallback(async () => {
    try {
      const { data } = await Client.get( `/core-ums-v2/permissions` );
      // fix to add unique key
      const dataWithKey = data.map((item) => {
        return {
          ...item,
          key: item.id,
        };
      });
      setPermissioonsList(dataWithKey);
    } catch (error) {
      console.error('Error fetching permissions list', error);
    }
  }, [Client, flags.umsVersion]);

  const defaultValues = {
    nameValue: editing.editingRow.name,
    descriptionValue: editing.editingRow.description,
    customerApplicationValue: editing.editingRow.customerApplication,
    isActiveValue: editing.editingRow.isActive,
  };

  // fix to update initial values of edit user form
  useEffect(() => {
    if (editFormRef.current) {
      editingForm.setFieldsValue(defaultValues);
    }
  }, [editFormRef, editingForm, defaultValues]);

  // fetch all roles
  const fetchApiClients = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await Client.get( `/core-ums-v2/api-clients` );
      // fix to add unique key
      const dataWithKey = data.map((item) => {
        return {
          ...item,
          key: item.id,
          isClientIdHidden: true,
        };
      });
      setApiClients(dataWithKey.sort((a, b) => sorter(a.name, b.name)));
      setTotal(data.length);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, [Client, flags.umsVersion]);

  // fetch all permissions
  const fetchApplicationsList = useCallback(async () => {
    try {
      const { data } = await mcasClient.get("core-mcas-v1");

      setApplicationsList(data);
    } catch (error) {
      console.error('Error fetching applications list: ', error);
    }
  }, [Client]);

  useEffect(() => {
    if(!applicationsList?.length) {
      fetchApplicationsList();
    }
    if (!permissionsList?.length) {
      fetchPermissionsList();
    }
  }, [fetchApplicationsList, applicationsList,fetchPermissionsList, permissionsList]);

  useEffect(() => {
    fetchApiClients();
  }, [fetchApiClients]);

  // manage client api page
  // handle change client api's table
  const handleChangeTable = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
  };

  // show table client id
  const handleClickShowClientId = (id) => {
    const foundRowItem = apiClients.find((item) => item.id === id);

    if (foundRowItem.isClientIdHidden) {
      foundRowItem.isClientIdHidden = false;
      setApiClients([...apiClients]);
    }
  };

  // hide table client id
  const handleClickHideClientId = (id) => {
    const foundRowItem = apiClients.find((item) => item.id === id);

    if (foundRowItem.isClientIdHidden === false) {
      foundRowItem.isClientIdHidden = true;
      setApiClients([...apiClients]);
    }
  };

  // refresh button
  const handleRefresh = () => {
    setApiClients(null)
    fetchApiClients();
  };

  // Create a new client api
  const showNewClientApiDrawer = () => {
    setVisibleNewClientApiDrawer(true);
  };

  const closeNewClientApiDrawer = () => {
    setVisibleNewClientApiDrawer(false);
    newApiClientForm.resetFields();
  };

  const addNewClientApi = async (body) => {
    try {
      setEditing({ ...editing, isEditing: true });
      const res = await Client.post( `/core-ums-v2/api-clients`, body);

      if (res instanceof Error) {
        if (res.message === "Request failed with status code 422") {
          failedNotification(
            "Error",
            res?.response?.data?.details?.name?.message
          );
        } else {
          failedNotification(res.name, res.message);
        }
      } else {
        const { data } = res;
        setAddedNewClientApiModal({ isActive: true, data: data });
      }
    } catch (error) {
      console.error(error);
      console.error(error.response);
      failedNotification(error.response.data.name, error.response.data.message);
    }
    setEditing(defaultEditingValue);
    handleRefresh();
  };

  const onFinishNewClientApi = (values, permissionsValue) => {
    const { nameValue, descriptionValue, customerApplicationValue } = values;

    addNewClientApi({
      name: nameValue,
      description: descriptionValue,
      customerApplication: customerApplicationValue,
      permissions: permissionsValue,
    });

    closeNewClientApiDrawer();
  };

  // Editing client api
  const showEditDrawer = () => {
    setVisibleEditDrawer(true);
  };

  const closeEditDrawer = (event) => {
    setVisibleEditDrawer(false);
    editingForm.resetFields();
    event !== undefined && setEditing(defaultEditingValue);
    handleRefresh()
  };

  // handle click table edit button
  const handleClickEdit = (record) => {
    setEditing({ ...editing, isEditing: true, editingRow: record });
    showEditDrawer();
  };

  // Edit role
  const updateClientApi = async (newData, id) => {
    try {
      const res = await Client.patch(`/core-ums-v2/api-clients/${id}`, newData);

      if (res instanceof Error) {
        if (res.message === "Request failed with status code 422") {
          failedNotification(
            "Error",
            res?.response?.data?.details?.name?.message
          );
        } else {
          failedNotification(res.name, res.message);
        }
      } else {
        successfulNotification(
          `Successfully submitted`,
          `Client Api (${res.data.name}) was updated.`
        );
      }
      setEditing(defaultEditingValue);
    } catch (error) {
      console.error(error);
      if (error.response.data.error) {
        failedNotification(error.response.data.error, error.response.data.detail);
      }
      failedNotification(error.response.data.name, error.response.data.message);
      setEditing(defaultEditingValue);
    }
    handleRefresh();
  };

  // on save of edit role
  const onFinishEditing = (values, id, permissions) => {
    const {
      nameValue,
      descriptionValue,
      customerApplicationValue,
      isActiveValue,
    } = values;

    const newData = {
      name: nameValue,
      description: descriptionValue,
      customerApplication: customerApplicationValue,
      isActive: isActiveValue,
      permissions: permissions,
    };

    updateClientApi(newData, id);
    closeEditDrawer();
  };

  return (
    <div className="manage-page manage-api-clients-page">
      <Helmet>
        <title>Manage Api Clients - Mercury © RWS</title>
      </Helmet>

      <PageTitle title="Manage Api Clients" />
      <AddClientApi
        handlers={{
          handleRefresh,
          showNewClientApiDrawer,
          closeNewClientApiDrawer,
          onFinishNewClientApi,
        }}
        values={{
          visibleNewClientApiDrawer,
          newApiClientForm,
          permissionsList,
          applicationsList,
        }}
      />
      <NewApiClientConfirmationModal
        values={{ addedNewClientApiModal, defaultNewApiClientModal }}
        handlers={{ setAddedNewClientApiModal }}
      />
      <EditClientApi
        values={{
          editing,
          visibleEditDrawer,
          editingForm,
          editFormRef,
          permissionsList,
          applicationsList,
        }}
        handlers={{ closeEditDrawer, onFinishEditing }}
      />
      <ApiClientsTable
        values={{ sortedInfo, total, apiClients, editing, isLoading, applicationsList }}
        handlers={{
          handleChangeTable,
          handleClickShowClientId,
          handleClickHideClientId,
          handleClickEdit,
        }}
      />
      <BackTopButton />
    </div>
  );
};

export default ManageApiClients;
