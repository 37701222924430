import React, { useState, useEffect, useRef } from "react";
import { useMercuryContext } from "../user-context";
import axios from "axios";
import { Helmet } from "react-helmet";

import { Form } from "antd";
import { API_URL } from "../utils/variables";
import { defaultHeaders } from "../api/APIUtils";

import PageTitle from "../ui/PageTitle";
import AddHelixTemplate from "../components/managePages/helix-templates/AddHelixTemplate";
import EditHelixTemplate from "../components/managePages/helix-templates/EditHelixTemplate";
import DeleteHelixTemplate from "../components/managePages/helix-templates/DeleteHelixTemplate";
import HelixTemplatesTable from "../components/managePages/helix-templates/HelixTemplatesTable";
import BackTopButton from "../ui/BackTopButton";
import LoadingStar from "../ui/LoadingStar";

import {
  failedNotification,
  successfulNotification,
  infoNotification,
} from "../utils/notifications";

const initialEditingValue = {
  isEditing: false,
  editingRow: "",
  isDeleting: false,
};

const ManageHelixTemplates = () => {
  const { accessToken } = useMercuryContext();
  const editFormRef = useRef(null);
  const [form] = Form.useForm();
  const [editingForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [visibleEditDrawer, setVisibleEditDrawer] = useState(false);
  const [helixTemplates, setHelixTemplates] = useState([]);
  const [sortedInfo, setSortedInfo] = useState("");
  const [editing, setEditing] = useState(initialEditingValue);
  const [total, setTotal] = useState(0);

  const templatesEndpoint = `${API_URL}/core-htms-v1?page_size=${total}&skip=0`;

  // fetch all helix templates
  const fetchHelixTemplatesList = async (token, endpoint, useIsLoading = true) => {
    try {
      setIsLoading(useIsLoading);
      await axios
        .get(endpoint, {
          headers: defaultHeaders(token),
        })
        .then((result) => {
          setHelixTemplates(result.data);
          setTotal(result.data.length);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error('Error fetching helix templates list: ', error);
    }
    setIsLoading(false);
  };

  // add new helix template
  const addHelixTemplate = async (token, body) => {
    const endpoint = `${API_URL}/core-htms-v1`;
    try {
      setEditing({ ...editing, isEditing: true, isDeleting: false });
      await axios({
        method: "post",
        url: endpoint,
        headers: defaultHeaders(token),
        data: body,
      }).then((result) => {
        if (result.data.error) {
          failedNotification(result.data.error, result.data.message);
        } else {
          successfulNotification(
            `${result.data.name} was added.`,
            "The page will be reloaded. Please refresh your page if the update is not visible."
          );
        }
      });
    } catch (error) {
      console.error('Error adding helix template: ', error);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // update a single helix template
  const updateHelixTemplate = async (token, newData) => {
    const id = editing.editingRow.id;
    const endpoint = `${API_URL}/core-htms-v1/${id}`;
    try {
      setEditing({ ...editing, isEditing: true, isDeleting: false });
      await axios({
        method: "patch",
        url: endpoint,
        headers: defaultHeaders(token),
        data: newData,
      }).then((result) => {
        successfulNotification(
          `${result.data.name} was updated.`,
          `The page will be reloaded. Please refresh your page if the update is not visible.`
        );
      });
    } catch (error) {
      failedNotification(error.response.data.error, error.response.data.detail);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // on load
  useEffect(() => {
    accessToken && fetchHelixTemplatesList(accessToken, templatesEndpoint);
  }, [accessToken, templatesEndpoint]);

  const defaultValues = {
    idValue: editing.editingRow.id,
    helixIdValue: editing.editingRow.helixId,
    nameValue: editing.editingRow.name,
    descValue: editing.editingRow.description,
  };

  // fix to update initial values of edit form
  useEffect(() => {
    if (editFormRef.current) {
      editingForm.setFieldsValue(defaultValues);
    }
  }, [editFormRef, editingForm, defaultValues]);

  const handleRefresh = async () => {
    try {
      await fetchHelixTemplatesList(accessToken, templatesEndpoint, false);
      infoNotification(
        "Helix template data reloaded",
        "The Helix template data has been reloaded"
      );
    } catch (error) {
      failedNotification(
        "Failed to reload",
        "Unable to reload the Helix template data at this time, please try again. If the issue persists please contact support."
      );
    }
  };

  // Add new helix template START
  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const closeDrawer = () => {
    setVisibleDrawer(false);
    form.resetFields();
  };

  // on save of add new template
  const onFinishNewTemplate = (values) => {
    // generates the new template object
    const newValues = {
      helixId: values.helixIdValue,
      name: values.nameValue,
      description: values.descValue,
    };

    addHelixTemplate(accessToken, newValues);
    closeDrawer();
  };
  // Add new helix template END

  // Update helix template START

  function showEditDrawer() {
    setVisibleEditDrawer(true);
  }

  const closeEditDrawer = (event) => {
    setVisibleEditDrawer(false);
    form.resetFields();
    event !== undefined && setEditing(initialEditingValue);
  };

  // on save of edit helix template
  const onFinishEditing = (values) => {
    const { idValue, helixIdValue, nameValue, descValue } = values;

    const newData = {
      id: idValue,
      helixId: helixIdValue,
      name: nameValue,
      description: descValue,
    };

    updateHelixTemplate(accessToken, newData);

    closeEditDrawer();
  };
  // Update helix template END

  const handleFinishDelete = async (submitted) => {
    const id = editing.editingRow.id;
    const endpoint = `${API_URL}/core-htms-v1/${id}`;
    try {
      // Check if form was submitted or cancelled
      if (!submitted) {
        setEditing(initialEditingValue);
        return;
      }
      setEditing({ ...editing, isEditing: true, isDeleting: true });
      await axios({
        method: "delete",
        url: endpoint,
        headers: defaultHeaders(accessToken),
      }).then((result) => {
        successfulNotification(
            `The Helix template was deleted.`,
            `The page will be reloaded. Please refresh your page if the update is not visible.`
        );
      });
    } catch (error) {
      failedNotification(error.response.data.error, error.response.data.detail);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // handle change table
  const handleChangeTable = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
  };

  // handle click table edit button
  const handleClickEdit = (record) => {
    setEditing({
      ...editing,
      isEditing: true,
      editingRow: record,
      isDeleting: false,
    });
    showEditDrawer();
  };

  // Delete button
  const handleClickDelete = (record) => {
    setEditing({
      ...editing,
      isEditing: true,
      editingRow: record,
      isDeleting: true,
    });
  };

  return (
    <div className="manage-page manage-helix-templates-page" style={{padding: '0 10px'}}>
      <Helmet>
        <title>Manage Helix Templates - Mercury © RWS</title>
      </Helmet>
      {isLoading ? (
        <LoadingStar />
      ) : (
        <>
          <PageTitle title="Manage Helix Templates" />
          <AddHelixTemplate
            handlers={{
              showDrawer,
              closeDrawer,
              onFinishNewTemplate,
              handleRefresh,
            }}
            values={{ form, visibleDrawer, helixTemplates }}
          />
          <EditHelixTemplate
            handlers={{
              closeEditDrawer,
              onFinishEditing,
            }}
            values={{
              editingForm,
              visibleEditDrawer,
              editing,
              editFormRef,
              helixTemplates
            }}
          />
          <DeleteHelixTemplate
            visible={editing.isDeleting}
            onSubmit={handleFinishDelete}
            selectedTemplate={editing.editingRow}
          />
          <HelixTemplatesTable
            values={{ helixTemplates, sortedInfo, editing, total }}
            handlers={{
              handleChangeTable,
              handleClickEdit,
              handleClickDelete,
              handleFinishDelete,
            }}
          />
          <BackTopButton />
        </>
      )}
    </div>
  );
};

export default ManageHelixTemplates;
