import { useState, FunctionComponent } from "react";
import { Button, Popover, Checkbox } from "antd";

import type { CheckboxValueType } from "antd/es/checkbox/Group";
import "./booleanFilter.scss";

import { DownOutlined } from "@ant-design/icons";

interface BooleanFilterProps {
  /**
   * Optional dropdown text, default will be set to Filter
   */
  name?: string;
  /**
   * Mandatory function to return the result.
   */
  onChange: (params: any) => any;
  title: string;
  placeholder?: string;
  value: any;
  /**
   * conditional showing not set option
   */
  enabledNotSet?: boolean;
}

export const BooleanFilter: FunctionComponent<BooleanFilterProps> = ({
  name,
  title,
  onChange,
  value,
  placeholder = "Select",
  enabledNotSet = true,
}) => {
  let checkBoxesOptions = enabledNotSet
    ? [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
        { label: "(Not Set)", value: "null" },
      ]
    : [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ];

  const [checkBoxesValue, setCheckboxesValue] =
      useState<CheckboxValueType[]>(value),
    [open, setOpen] = useState<boolean>(false),
    handleSearch = () => {
      setOpen(false);

      const res = {
        name,
        value: checkBoxesValue?.map((v) => JSON.parse(v as any)),
      };
      onChange({
        target: res,
      });
    },
    handleVisibleChange = (e: boolean) => {
      // fix to close popover if the user will click anywhere in the page
      if (!e) {
        setOpen(false);
      }
      if (Array.isArray(value) && value?.length) {
        setCheckboxesValue([...checkBoxesValue]);
      } else {
        setCheckboxesValue([]);
      }
    },
    handleChange = (checkedValues: CheckboxValueType[]) => {
      setCheckboxesValue(checkedValues);
    },
    handleClear = () => {
      setOpen(false);
      setCheckboxesValue([]);
      checkBoxesOptions = [];
      onChange({
        target: {
          name: name,
          value: undefined,
        },
      });
    };

  // Checkbox group expects only an array of strings
  const stringifiedValue = checkBoxesValue?.map((i: any) => {
    if (typeof i !== "string") {
      i = JSON.stringify(i);
    }

    return i;
  });

  const content = (
    <div className="booleanFilter">
      <div className="top">
        <Checkbox.Group
          options={checkBoxesOptions}
          onChange={handleChange}
          value={stringifiedValue}
        />
      </div>
      <div className="separator" />
      <div className="bottom">
        <Button onClick={handleClear} danger size="small">
          Clear
        </Button>
        <Button onClick={handleSearch} type="primary" size="small">
          Search
        </Button>
      </div>
    </div>
  );

  const displayedText =
    Array.isArray(value) && value.length > 0
      ? value
          .map((i) => {
            if (i === null) {
              i = "(Not Set)";
              return i;
            }
            return i;
          })
          .join(", ")
      : placeholder;
      
  return (
    <div className="boolean-filter-wrap">
      <Popover
        placement="bottomLeft"
        content={content}
        trigger="click"
        open={open}
        onOpenChange={handleVisibleChange}
      >
        <label className="filter-label">{title}</label>
        <Button
          className="trigger-filter"
          size="small"
          onClick={() => setOpen((prev) => !prev)}
        >
          {displayedText}
          <DownOutlined />
        </Button>
      </Popover>
    </div>
  );
};
