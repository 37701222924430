import { Form, Input, Switch } from "antd";

export const titleFormItem = (required:boolean = false) => {
  return (
    <Form.Item
      name="title"
      label="Title"
      rules={[{ required: required, message: "Please enter the title" }]}
    >
      <Input placeholder="Title" />
    </Form.Item>
  );
};

export const descriptionFormItem = (required:boolean = false) => {
    return <Form.Item
    name="description"
    label="Description"
    rules={[{ required: required, message: "Please enter the description" }]}
  >
    <Input placeholder="Description" />
  </Form.Item>
}

export const activeFormItem = (handleSwitchChange: any) => {
  return (
    <Form.Item label="Active" name="isActive" valuePropName="checked">
      <Switch onChange={handleSwitchChange} />
    </Form.Item>
  );
};
