import React, { useState } from "react";
import Search from "antd/es/input/Search";
import { Button, Tooltip, Select, Popover } from "antd";
import {
  InfoCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";
import "./listfilter.scss";

export interface ListProps {
  id:string;
  onChange: (params: any) => any;
  title: string;
  placeholder?: string;
  onRemove: any;
  name: string;
  value: any;
  forceOpen?:boolean;
}

export const ListFilter = ({
  onChange,
  onRemove,
  value,
  name,
  title,
  placeholder = "Select",
  forceOpen = false
} : ListProps) => {

  const
      [searchValue, setSearchValue] = useState<any>(value),
      [open, setOpen] = useState(false),
      inputs = value || [],
      values = Array.from(new Set(inputs));

  const handleSearch = () => {
    setOpen(false);
    onSearch(searchValue);
  }

  const handleClear = () => {
    setSearchValue([]);
    return onChange({
      target: {
        name,
        value: [],
      },
    });
  };

  const onSearch = (e: any) => {
    setSearchValue("");
    return onChange({
      target: {
        name,
        value: Array.from( new Set([...values, ...e.split(/[ ,;]+/)] ) ),
      },
    });
  };

  const handlePopoverVisibleChange = () => {
    setOpen((prev) => !prev);
  };

  const content = (
    <div className={"listfilter"}>
        <div className="top-wrap">
          <Search
            name={name ? name : "items"}
            placeholder={values.length === 0 ? "Search items" : "Add more items"}
            onChange={(e: any) => setSearchValue(e.target.value)}
            value={searchValue}
            onSearch={onSearch}
            />
          <label className="label">
              <Tooltip
                title={
                  <div>
                    <p style={{ marginBottom: "3px" }}>
                      Multiple items are allowed. Use comma, semi-colon or space to
                      separate.
                    </p>
                    <p style={{ marginBottom: "unset" }}>
                      <InfoCircleOutlined style={{ marginRight: "5px" }} />
                      Duplicate items will be ignored. Press enter to submit.
                    </p>
                  </div>
                }
                placement="right"
                color={"var(--tertiary-color)"}
                overlayInnerStyle={{ width: "448px" }}
              >
                <InfoCircleOutlined
                  style={{
                    marginLeft: "8px",
                    color: "var(--tertiary-color)",
                    cursor: "help",
                  }}
                />
              </Tooltip>
          </label>
        </div>
      {/* Fix for onFocus error using css*/}
        <div className={"itemsFiltered"} style={{display: values.length > 0  ? 'block' : 'none'}}>
          <Select
            showSearch
            mode={"multiple"}
            style={{ width: "100%" }}
            placeholder={`${values.length} items selected`}
            optionFilterProp="children"
            onMouseDown={(e) => e.preventDefault()}
            filterOption={(input, option) => {
              // @ts-ignore
              if (option && option.children && option.children.search) {
                // console.log(option);
                // @ts-ignore
                return option.children.search(input) >= 0;
              }
              return false;
            }}
            value={[]}
          >
            <Select.Option
              disabled={true}
              value={""}
              onMouseDown={(e: any) => e.preventDefault()}
            >
              Click to remove an item
            </Select.Option>
            {Array.from(new Set(values)).map((id: any, index: any) => {
              return (
                <Select.Option
                  key={index}
                  value={id}
                  onMouseDown={(e: any) => {
                    e.preventDefault();
                    onChange({
                      target: {
                        name,
                        value: Array.from(new Set(values)).filter( v => v!== id ),
                      },
                    });
                  }}
                >
                  {id}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      <div className="separator" />
      <div className="bottom">
        <Button onClick={handleClear} danger size="small">
          Clear
        </Button>
        <Button onClick={handleSearch} disabled={!searchValue || searchValue.length === 0} type="primary" size="small">
          Search
        </Button>
      </div>
    </div>
  );

  if ( forceOpen ) {
    return (
        <div className="list-filter-wrap">
          <div className={"ant-popover-inner-content"}>
            {content}
          </div>
        </div>
    )
  }

  return (
    <div className="list-filter-wrap">
      <Popover
        placement="bottomLeft"
        content={content}
        trigger="click"
        open={open}
        onOpenChange={handlePopoverVisibleChange}
      >
        <label className="filter-label">{title}</label>
        <Button className="trigger-filter" size="small">
        {
            Array.isArray(value) && value.length
              ? `${value.length} items`
                : placeholder
          } <DownOutlined />
        </Button>
      </Popover>
    </div>
  );
};
