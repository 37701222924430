import React from "react";
import {DatePicker, Form} from "antd";
import datePickerRanges from "../../../utils/datePickerRanges";

const { RangePicker } = DatePicker;

export default function DueDate( props ) {

  const {
    updateDueDate
  } = props;

  return (
    <Form.Item
      label="Due date"
      name="dueDate"
      rules={[{ required: false}]}
      key={props.key}
    >
      <RangePicker onChange={updateDueDate} style={{width: "100%"}} onClear={updateDueDate} allowClear={true} presets={datePickerRanges}/>
    </Form.Item>
  )
}