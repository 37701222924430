import React, {useCallback, useState} from "react";
import { Button } from "antd";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import {useMercuryContext} from "../../../user-context";
import fileDownload from 'js-file-download'

export default function ({ record }) {

  const
      [ loading, setLoading ] = useState( false ),
      { gpClient:client } = useMercuryContext(),
      download = useCallback( () => {
        setLoading(true)
        client.get( `/google-gp-v1/adjustments/${record.id}/download` )
            .then( ( { data } ) => {
              fileDownload(data, `adjustment-${record.id}.csv`, 'text/csv')
              setLoading(false)
            })
      }, [ client, record ])

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button
        title={`Download Adj #${record.id.slice( -8 )}`}
        type="primary"
        disabled={loading}
        icon={loading ? <LoadingOutlined /> : <DownloadOutlined />}
        // href={`${process.env.REACT_APP_API_URL}/google-gp-v1/adjustments/${record.id}/download`}
        // download={`adjustment-${record.id}.csv`}
        // target={"_blank"}
          onClick={download}
      />
    </div>
  );
}
