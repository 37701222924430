import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Modal, Tooltip } from "antd";

import { InfoCircleOutlined, CopyOutlined } from "@ant-design/icons";

const NewApiClientConfirmationModal = ({ values, handlers }) => {
  const { addedNewClientApiModal, defaultNewApiClientModal } = values,
    { setAddedNewClientApiModal } = handlers;

  return (
    <Modal
      title={`Api Client Added (${addedNewClientApiModal?.data?.name})`}
      open={addedNewClientApiModal.isActive}
      cancelText={"Close"}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ type: "danger" }}
      onCancel={() => setAddedNewClientApiModal(defaultNewApiClientModal)}
    >
      <>
        Your Api Client has been added to Mercury, shown below are your client
        ID and secret that can be used to access Mercury.
      </>
      <p>
        <InfoCircleOutlined
          style={{ color: "var(--red)", marginRight: "5px" }}
        />
        Please store these in a safe location, the client secret will not be
        visible again once you close this message.
      </p>
      <p>
        <b>Client ID: </b>
        {addedNewClientApiModal?.data?.clientId}{" "}
        <Tooltip
          title="Copy Client ID to Clipboard"
          color={"var(--tertiary-color)"}
          placement={"right"}
        >
          <CopyToClipboard text={addedNewClientApiModal?.data?.clientId}>
            <CopyOutlined
              style={{
                color: "var(--tertiary-color)",
                padding: "5px 5px 0",
              }}
            />
          </CopyToClipboard>
        </Tooltip>
      </p>
      <>
        <b>Client Secret: </b>
        {addedNewClientApiModal?.data?.clientSecret}{" "}
        <Tooltip
          title="Copy Client Secret to Clipboard"
          color={"var(--tertiary-color)"}
          placement={"right"}
        >
          <CopyToClipboard text={addedNewClientApiModal?.data?.clientSecret}>
            <CopyOutlined
              style={{
                color: "var(--tertiary-color)",
                padding: "5px 5px 0",
              }}
            />
          </CopyToClipboard>
        </Tooltip>
      </>
    </Modal>
  );
};

export default NewApiClientConfirmationModal;
