import React, { useState } from "react";
import { useMercuryContext } from "../user-context";
import { Helmet } from "react-helmet";
import {Alert, Button, Form, Input} from "antd";
import PageTitle from "../ui/PageTitle";
import BackTopButton from "../ui/BackTopButton";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {Switch} from "../Components";
import ReactJson from 'react-json-view'

const DownloadProject = () => {
  const
      { gpSocket } = useMercuryContext(),
      [ loading, setLoading ] = useState( false ),
      [ shortId, setShortId ] = useState(""),
      [ success, setSuccess ] = useState( false ),
      [ failed, setFailed ] = useState( false ),
      [ output, setOutput] = useState( undefined ),
      { project:socket } = gpSocket;

  async function submit() {
    setSuccess( false );
    setFailed( false );
    setLoading( true );
    try {

      setOutput( undefined )
      socket.emit( "download", shortId, ( data:any ) => {
        console.log( "data", data );
        if ( data.error ) {
          setFailed( data.error );
        }
        else {
          setSuccess( data )
        }
        setLoading( false )
        setOutput( data )
      })
    }
    catch ( e ) {
      console.error( e )
      setLoading(false )
      setFailed( true );
    }
  }

  return (
      <div className="manage-page manage-workflowtypes-page" style={{padding: '0 10px'}}>
        <Helmet>
          <title>Download google project - Mercury © RWS</title>
        </Helmet>
        <PageTitle title="Download project"/>

        <Form
            name="basic"
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            style={{maxWidth: 600}}
            initialValues={{remember: true}}
            onFinish={submit}
            autoComplete="off">
          <Form.Item
              label="Project shortId"
              name="id"
              rules={[{required: true, message: 'Project id is required'}]}
          >
            <Input onChange={(e) => setShortId(e.target.value)} value={shortId} disabled={loading}/>
          </Form.Item>
          <Form.Item wrapperCol={{offset: 8, span: 16}}>
            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
        {
            success &&
            <Alert message="The Project has successfully downloaded"
                   type="success"/>
        }
        {
            failed &&
            <Alert message="The project could not be downloaded. Please check the project id." type="error"/>
        }
        {
            output && <div className={"download-output-json"} style={{
              border: "1px solid #ccc",
              padding: "10px",
              margin: "10px 0 0 0",
              borderRadius: "10px"
            }}>
              <ReactJson src={output}/>
            </div>
        }
        <BackTopButton/>
      </div>
  );
};

export default DownloadProject;
