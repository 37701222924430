import React, { useState, useEffect } from "react";
import Search from "antd/es/input/Search";
import { Button, Popover } from "antd";
import { DownOutlined } from "@ant-design/icons";
import "./textfilter.scss";

export interface TextFilterProps {
  value: any;
  name: string;
  title: string;
  placeholder?:string;
}

export default function TextFilter(props: any) {
  const [searchValue, setSearchValue] = useState<string>(props.value),
    [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setSearchValue(props.value);
  }, [props.value]);

  const handleSearch = () => {
    // console.log("search:", searchValue);
    setOpen(false);

    let result = {
      target: {
        name: props.name ? props.name : "search",
        value: searchValue,
      },
    };

    // return value
    props.onChange(result);
  };

  const handleClear = () => {
    setSearchValue("");
    return props.onChange({
      target: {
        name: props.name,
        value: '',
      },
    });
  };

  const handlePopoverVisibleChange = () => {
    setOpen((prev) => !prev);
  };

  const content = (
    <div className="textSearch">
      <Search
        name={props.name ? props.name : "search"}
        value={searchValue}
        placeholder={props.placeholder}
        onChange={(e: any) => setSearchValue(e.target.value)}
        onSearch={handleSearch}
        onPressEnter={handleSearch}
      />
      <div className="separator" />
      <div className="bottom">
        <Button onClick={handleClear} danger size="small">
          Clear
        </Button>
        <Button onClick={handleSearch} type="primary" size="small">
          Search
        </Button>
      </div>
    </div>
  );

  return (
    <div className="text-search-wrap">
      <Popover
        placement="bottomLeft"
        content={content}
        trigger="click"
        open={open}
        onOpenChange={handlePopoverVisibleChange}
      >
        <label className="filter-label">{props.title}</label>
        <Button className="trigger-filter" size="small">
          {
            props.value && props.value.length
              ? props.value
                : props.placeholder || "Select"
          } <DownOutlined />
        </Button>
      </Popover>
    </div>
  );
}
