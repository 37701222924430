import React, { useState, useEffect, useCallback, useRef } from "react";
import { useMercuryContext } from "../user-context";
import axios from "axios";

import { Helmet } from "react-helmet";

import { Form } from "antd";

import { API_URL } from "../utils/variables";
import { defaultHeaders } from "../api/APIUtils";

import PageTitle from "../ui/PageTitle";
import AddProduct from "../components/managePages/products/AddProduct";
import EditProduct from "../components/managePages/products/EditProduct";
import ProductsTable from "../components/managePages/products/ProductsTable";
import BackTopButton from "../ui/BackTopButton";
import LoadingStar from "../ui/LoadingStar";
import {
  failedNotification,
  successfulNotification,
  infoNotification,
} from "../utils/notifications";

const initialEditingValue = { isEditing: false, editingRow: "" };

const ManageProducts = () => {
  const { client: Client, accessToken } = useMercuryContext(),
    editFormRef = useRef(null),
    [form] = Form.useForm(),
    [editingForm] = Form.useForm(),
    [isLoading, setIsLoading] = useState(false),
    [visibleDrawer, setVisibleDrawer] = useState(false),
    [visibleEditDrawer, setVisibleEditDrawer] = useState(false),
    [switchValue, setSwitchValue] = useState(false),
    [queryVariationValue, setQueryVariationValue] = useState(false),
    [nonExtendableValue, setNonExtendableValue] = useState(false),
    [products, setProducts] = useState([]),
    [sortedInfo, setSortedInfo] = useState(""),
    [dropdownOptions, setDropdownOptions] = useState({
      productArea: [],
      costCode: [],
    }),
    [editing, setEditing] = useState(initialEditingValue),
    [total, setTotal] = useState(0),
    [pagination, setPagination] = useState({ currentPage: 1, pageSize: 20 });

  // fetch dropdown option for add new product
  const fetchDropdown = useCallback(async (token, field) => {
    const endpoint = `${API_URL}/core-gpms-v1/${field}`;

    try {
      await axios
        .get(endpoint, {
          headers: defaultHeaders(token),
        })
        .then((result) => {
          setDropdownOptions((dropdownOptions) => {
            // fix to prevent changing all variable names
            if (field === "productarea") {
              field = "productArea";
            }
            return { ...dropdownOptions, [field]: result.data };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(`Error fetching ${field}`, error);
    }
  }, []);

  const fetchCostCodes = useCallback(async () => {
    try {
      const { data } = await Client.get("core-ccs-v1");

      setDropdownOptions((dropdownOptions) => {
        return { ...dropdownOptions, costCode: data };
      });
    } catch (error) {
      console.error('Error fetching cost codes', error);
    }
  }, [Client]);

  // fetch all products
  const fetchProductList = async (token) => {
    const productEndpoint = `${API_URL}/core-gpms-v1/product`;
    const prodAreaEndpoint = `${API_URL}/core-gpms-v1/productarea`;
    const costCodeEndpoint = `${API_URL}/core-ccs-v1`;

    try {
      setIsLoading(true);
      let products =  await axios.get(productEndpoint, { headers: defaultHeaders(token) })
      let productsAreas =  await axios.get(prodAreaEndpoint, { headers: defaultHeaders(token) })
      let costCodes =  await axios.get(costCodeEndpoint, { headers: defaultHeaders(token) })

      // set up areas dictionary with keys being the IDs
      const areas = {};
      if( productsAreas.data.length > 0) {
        for( const area of productsAreas.data) {
          areas[area.id] = area
        }
      }

      // set up costCode dictionary with keys being the IDs
      const codes = {};
      if( costCodes.data.length > 0) {
        for( const costCode of costCodes.data) {
          codes[costCode.id] = costCode;
        }
      }

      for (const product of products.data) {
        product["productArea"] = areas[product.productArea]
        product["costCode"] = codes[product.costCode];
      }

      setProducts(products.data);
      setTotal(products.data.length);


    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };




  // add new product
  const addProduct = async (token, body) => {
    const endpoint = `${API_URL}/core-gpms-v1/product`;

    try {
      setEditing({ ...editing, isEditing: true });
      let createdProduct = await axios({
        method: "post",
        url: endpoint,
        headers: defaultHeaders(token),
        data: body,
      });

      infoNotification(
          `Product - ${createdProduct.data.title} was added`,
          "Please refresh the page if the new product is not visible."
        );


    } catch (error) {
      failedNotification(error.name, error.message);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // update a single product
  const updateProduct = async (token, productId, newData) => {
    const endpoint = `${API_URL}/core-gpms-v1/product/${productId}`;
    try {
      setEditing({ ...editing, isEditing: true });
      await axios({
        method: "patch",
        url: endpoint,
        headers: defaultHeaders(token),
        data: newData,
      }).then((result) => {
        successfulNotification(
          `Product - ${result.data.title} was updated`,
          `Please refresh your page if the update is not visible.`
        );
      });
    } catch (error) {
      failedNotification(error.name, error.message);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // on load
  useEffect(() => {
    accessToken && fetchDropdown(accessToken, "productarea");
    accessToken && fetchCostCodes(); // fix to use cost codes service
    accessToken && fetchProductList(accessToken);
  }, [accessToken, fetchDropdown, fetchCostCodes ]);

  const defaultValues = {
    productValue: editing.editingRow.title,
    productAreaValue: editing.editingRow.productAreaId,
    costCodeValue: editing.editingRow.costCodeId,
    vendorValue: editing.editingRow.vendor,
    descValue: editing.editingRow.description,
    nonExtendableValue: editing.editingRow.nonExtendable,
    queryVariationValue: editing.editingRow.queryVariation,
    activeValue: editing.editingRow.active,
  };

  // fix to update initial values of edit product form
  useEffect(() => {
    if (editFormRef.current) {
      editingForm.setFieldsValue(defaultValues);
    }
  }, [editFormRef, editingForm, defaultValues]);

  // refresh button
  const handleRefresh = () => {
    //fetch details again
    accessToken && fetchProductList(accessToken);
  };
  // Add new product START
  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const closeDrawer = () => {
    setVisibleDrawer(false);
    form.resetFields();
    setSwitchValue(false);
    setQueryVariationValue(false);
    setNonExtendableValue(false);
  };

  // handle change of active switch button
  function handleChangeActive(checked) {
    setSwitchValue(checked);
  }

  function handleChangeVariation(checked) {
    setQueryVariationValue(checked);
  }

  function handleChangeNonExtendable(checked) {
    setNonExtendableValue(checked);
  }

  // on save of add new product
  const onFinishNewProduct = (values) => {
    // generates the new product object
    const body = {
      title: values.productValue.trim(),
      productArea: values.productAreaValue,
      costCode: values.costCodeValue,
      vendor: values.vendorValue,
      description: values.descValue,
      isNonExtendable: nonExtendableValue,
      isQueryVariation: queryVariationValue,
      isActive: switchValue,
    };
    addProduct(accessToken, body);
    closeDrawer();
  };
  // Add new product END

  // Update Product START

  function showEditDrawer() {
    setVisibleEditDrawer(true);
  }

  const closeEditDrawer = (event) => {
    setVisibleEditDrawer(false);
    form.resetFields();
    event !== undefined && setEditing(initialEditingValue);
  };

  // on save of edit product
  const onFinishEditing = (values) => {
    const {
      activeValue,
      queryVariationValue,
      nonExtendableValue,
      costCodeValue,
      productAreaValue,
      productValue,
      vendorValue,
      descValue,
    } = values;

    const newData = {
      isNonExtendable: nonExtendableValue.toString(),
      isQueryVariation: queryVariationValue.toString(),
      isActive: activeValue.toString(),
      title: productValue.trim(),
      costCode: costCodeValue,
      productArea: productAreaValue,
      vendor: vendorValue,
      description: descValue,
    };

    // function to call api endpoint that will update
    const editingProductId = editing.editingRow.key;

    updateProduct(accessToken, editingProductId, newData);

    closeEditDrawer();
  };
  // Update product END

  // handle change table
  const handleChangeTable = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
    setPagination({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  // handle click table edit button
  const handleClickEdit = (record) => {
    setEditing({ ...editing, isEditing: true, editingRow: record });
    showEditDrawer();
  };

  return (
    <div className="manage-page manage-products-page" style={{padding: '0 10px'}}>
      <Helmet>
        <title>Manage Products - Mercury © RWS</title>
      </Helmet>
      {isLoading ? (
        <LoadingStar />
      ) : (
        <>
          <PageTitle title="Manage Products" />
          <AddProduct
            handlers={{
              showDrawer,
              closeDrawer,
              onFinishNewProduct,
              handleChangeActive,
              handleChangeVariation,
              handleChangeNonExtendable,
              handleRefresh,
            }}
            values={{ form, visibleDrawer, dropdownOptions, switchValue, queryVariationValue, nonExtendableValue }}
          />
          <EditProduct
            handlers={{
              closeEditDrawer,
              onFinishEditing,
            }}
            values={{
              editingForm,
              visibleEditDrawer,
              dropdownOptions,
              editing,
              editFormRef,
            }}
          />
          <ProductsTable
            values={{ products, sortedInfo, editing, total, pagination }}
            handlers={{
              handleChangeTable,
              handleClickEdit,
            }}
          />
          <BackTopButton />
        </>
      )}
    </div>
  );
};

export default ManageProducts;
