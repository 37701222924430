import React from "react";

import {
  Button,
  Drawer,
  Form,
  Input,
  InputNumber,
  Switch,
  Tooltip,
  Space,
} from "antd";

import { InfoCircleOutlined } from "@ant-design/icons";

const EditCurrency = ({ handlers, values }) => {
  const { closeEditDrawer, onFinishEditing } = handlers;
  const { editingForm, visibleEditDrawer, editing, editFormRef } = values;
  const editingRow = editing && editing.editingRow;

  return (
    <>
      <Drawer
        title="Edit Currency"
        placement="right"
        onClose={closeEditDrawer}
        open={visibleEditDrawer}
        width={600}
        closable={true}
        className="manage-pages-drawer"
        forceRender
      >
        {editingRow && (
          <Form
            form={editingForm}
            layout="horizontal"
            onFinish={onFinishEditing}
            name="editCurrencyForm"
            ref={editFormRef}
          >
            <Form.Item label="Name">
              <Space>
                <Form.Item
                  name="nameValue"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter the name of the code, for example 'USD'",
                    },
                  ]}
                >
                  <Input placeholder="Name" maxLength={3} />
                </Form.Item>
                <Tooltip
                  title="The name of the code, for example 'USD'"
                  color={"var(--tertiary-color)"}
                  placement={"bottom"}
                >
                  <InfoCircleOutlined
                    style={{ color: "var(--tertiary-color)" }}
                  />
                </Tooltip>
              </Space>
            </Form.Item>

            <Form.Item label="Symbol">
              <Space>
                <Form.Item
                  name="symbolValue"
                  noStyle
                >
                  <Input maxLength={3} placeholder="$" style={{ width: 100 }} />
                </Form.Item>
                <Tooltip
                  title="The symbol to use for the currency, for example: '$'"
                  color={"var(--tertiary-color)"}
                  placement={"bottom"}
                >
                  <InfoCircleOutlined
                    style={{ color: "var(--tertiary-color)" }}
                  />
                </Tooltip>
              </Space>
            </Form.Item>

            <Form.Item label="Decimal Character">
              <Space>
                <Form.Item
                  name="decimalCharacterValue"
                  noStyle
                >
                  <Input maxLength={3} placeholder="." style={{ width: 100 }} />
                </Form.Item>
                <Tooltip
                  title="The character used to separate between the whole number and the decimal fraction, for example: '.'"
                  color={"var(--tertiary-color)"}
                  placement={"bottom"}
                >
                  <InfoCircleOutlined
                    style={{ color: "var(--tertiary-color)" }}
                  />
                </Tooltip>
              </Space>
            </Form.Item>

            <Form.Item label="Separator Character">
              <Space>
                <Form.Item
                  name="separatorCharacterValue"
                  noStyle
                >
                  <Input maxLength={3} placeholder="," style={{ width: 100 }} />
                </Form.Item>
                <Tooltip
                  title="The character used to separate thousands in the number, for example: ','"
                  color={"var(--tertiary-color)"}
                  placement={"bottom"}
                >
                  <InfoCircleOutlined
                    style={{ color: "var(--tertiary-color)" }}
                  />
                </Tooltip>
              </Space>
            </Form.Item>

            <Form.Item label="Precision">
              <Space>
                <Form.Item
                  name="precisionValue"
                  noStyle
                >
                  <InputNumber
                    maxLength={999}
                    placeholder="2"
                    min={0}
                    max={100}
                    precision={0}
                    style={{ width: 100 }}
                  />
                </Form.Item>
                <Tooltip
                  title="The number of numbers to show after the decimal point, some currencies do not support fractions of values. "
                  color={"var(--tertiary-color)"}
                  placement={"bottom"}
                >
                  <InfoCircleOutlined
                    style={{ color: "var(--tertiary-color)" }}
                  />
                </Tooltip>
              </Space>
            </Form.Item>

            <Form.Item label="Display Pattern">
              <Space>
                <Form.Item
                  name="displayPatternValue"
                  noStyle
                  rules={[
                    {
                      required: false,
                      pattern: new RegExp("^[!.#]*$"),
                      message:
                        "Please enter the display pattern as to where to display the currency symbol and value, for example: '!#' where ! is the symbol and # the value",
                    },
                  ]}
                >
                  <Input
                    maxLength={2}
                    placeholder="!#"
                    style={{ width: 100 }}
                  />
                </Form.Item>
                <Tooltip
                  title="The display pattern as to where to display the currency symbol and value, for example: '!#' where ! is the symbol and # the value"
                  color={"var(--tertiary-color)"}
                  placement={"bottom"}
                >
                  <InfoCircleOutlined
                    style={{ color: "var(--tertiary-color)" }}
                  />
                </Tooltip>
              </Space>
            </Form.Item>

            <Form.Item label="Negative Display Pattern">
              <Space>
                <Form.Item
                  name="negativeDisplayPatternValue"
                  noStyle
                  rules={[
                    {
                      required: false,
                      pattern: new RegExp("^[-.!.#]*$"),
                      message:
                        "Please enter the display pattern as to where to display the currency symbol and value for negative numbers, for example: '-!#' where ! is the symbol and # the value",
                    },
                  ]}
                >
                  <Input
                    maxLength={3}
                    placeholder="-!#"
                    style={{ width: 100 }}
                  />
                </Form.Item>
                <Tooltip
                  title="The display pattern as to where to display the currency symbol and value for negative numbers, for example: '-!#' where ! is the symbol and # the value"
                  color={"var(--tertiary-color)"}
                  placement={"bottom"}
                >
                  <InfoCircleOutlined
                    style={{ color: "var(--tertiary-color)" }}
                  />
                </Tooltip>
              </Space>
            </Form.Item>

            <Form.Item
              label="Active"
              name="isActiveValue"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Currency
              </Button>
            </Form.Item>
          </Form>
        )}
      </Drawer>
    </>
  );
};

export default EditCurrency;
