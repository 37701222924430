import React from "react";

import { BooleanFilter, ListProps } from "../Components";

const BooleanWithoutNotSet = (props: ListProps) => {
    const
     { value, onChange, ...rest } = props;
  
    return (<BooleanFilter
        {...rest}
        value={value ? value.filter( (v:any) => v !== null ) : []}
        onChange={( v:any) => {
          // Ensure v is an array before calling includes
          if (Array.isArray(v) && v.includes(false)) {
            onChange([...v, null]); // If `false` is in the array, add `null`
          } else {
            onChange(v); // Otherwise, just pass `v` unchanged
          }
        }}
        enabledNotSet={false}
      />
    );
  };

export default BooleanWithoutNotSet;
