import InvoiceSubmission from "./InvoiceSubmission";
import { BASE_URL } from "../../utils/variables";
import ConcurrentTaskQueue from "../../utils/ConcurrentTaskQueue";

export default class AccrualSubmission extends InvoiceSubmission {
    //@ts-ignore
    url = (id: any, plp: any) => `${BASE_URL}/plp/${plp}/submitAccrual`

    async send(project: any, plp: any) {
        //@ts-ignore
        return this.Client.post(this.url(project.shortId, plp), {});
    }

    ignoreProject(project: any) {
        return false;
    }

    getState = (project: any) => {
        if (project.errored) {
            return "Errored";
        }
        if (project.completed === project.total) {
            return "Complete";
        }
        return "Submitting";
    };

    getResult = (project: any) => {
        if (project.errored) {
            return `Submitted ${project.completed} of ${project.total}`;
        }
        if (project.completed === project.total) {
            return "Success";
        }
        return "Pending";
    };

    async submitProject(project: any) {
        this.updateProject({ ...project, errored: 0 })
        await new Promise((accept) => {
            const Queue = new ConcurrentTaskQueue(process.env.REACT_APP_INVOICE_SUBMISSION_CONCURRENCY || 1);
            Queue.on("complete", () => {
                const updated = this.projects.get(project.shortId);
                if (updated.state === "Errored" && updated.attempts < 2) {
                    updated.attempts++
                    const retry = { ...updated, state: "Errored", result: "Retrying...", errored: 0, completed: 0, attempts: updated.attempts };
                    this.updateProject(retry);
                    this.submitProject(retry);
                    //@ts-ignore
                    return accept();
                }
                else {
                    updated.sent = true;
                }
                this.updateProject(updated);
                //@ts-ignore
                return accept();
            });

            Queue.add(project.plps.map((plp: any) => {
                return async () => {

                    await new Promise((accept) => {
                        this.updateProject({ ...project, state: "Submitting" });
                        this.send(project, plp)
                            .then((response) => this.handleSuccess(accept, project))
                            .catch((error) => this.handleError(error, accept, project));
                    });
                }
            }))
        })
    }


    handleSuccess = (accept: Function, project: any) => {
        project.completed++;
        this.updateProject({
            ...project,
            state: this.getState(project),
            result: this.getResult(project),
            sent: project.completed === project.total
        });
        accept();
    };

    handleError = (error: any, accept: Function, project: any) => {
        if (error.response) {
            this.updateProject({
                ...project,
                state: "Errored",
                result: `Submitted ${project.completed} of ${project.total}`,
                errored: project.errored++
            });
        } else {
            this.updateProject({
                ...project,
                state: "Errored",
                result: `An unknown error occurred, will retry.`,
                errored: project.errored++
            });
        }
        accept();
    };

}
