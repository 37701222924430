import React from "react";
import "./TeamsLogo.scss";

interface TeamsLogoProps {
	scale?:number;
}

export default function (props:TeamsLogoProps) {
	return (
		<svg className="TeamsLogo" version="1.1"  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 2458 2458" style={{scale: props.scale || 1 }} >

			<circle className="st0" cx="1305.8" cy="460.9" r="358.5"/>
			<path className="st1" d="M1280.2,537.7H955.5l1.6,7.1l0.2,0.6l1.7,6.6c49,185.9,235.8,300.1,423.6,259v-171
				C1382.5,583.6,1336.7,537.9,1280.2,537.7z"/>
			<path className="st2"
				  d="M1203.4,614.5H981.9c59.3,125.1,185.4,204.9,323.9,204.9V716.9C1305.6,660.4,1259.9,614.7,1203.4,614.5z"/>
			<circle className="st3" cx="2112.3" cy="576.1" r="268.8"/>
			<path className="st3" d="M2342.8,921.8H1739c-27.2,0-49.2,22-49.2,49.2v622.9c-3.4,185.8,123.6,348.7,304.7,390.5
				c207.8,43.6,411.7-89.5,455.3-297.4c5.4-25.9,8.2-52.3,8.2-78.7V1037C2458,973.3,2406.4,921.8,2342.8,921.8z"/>
			<path className="st0" d="M1920.3,1037v729.7c0.2,339.2-274.6,614.3-613.8,614.5c-219.6,0.1-422.5-117-532.3-307.2
				c-14.8-24.6-27.6-50.2-38.4-76.8c-10.1-25-18.5-50.7-25.3-76.8c-12.7-50.2-19.1-101.8-19.2-153.6V1037
				c-0.1-63.5,51.4-115.1,114.9-115.2c0.1,0,0.2,0,0.3,0h998.6c63.5-0.1,115.1,51.4,115.2,114.9
				C1920.3,1036.8,1920.3,1036.9,1920.3,1037z"/>
			<path className="st2"
				  d="M1203.4,614.5H981.9c59.3,125.1,185.4,204.9,323.9,204.9V716.9C1305.6,660.4,1259.9,614.7,1203.4,614.5z"/>
			<path className="st1" d="M1382.6,921.8V1895c0,47.7-33.3,89-79.9,99.1c-7.2,2.1-14.7,3.2-22.3,3.1H735.9c-10.1-25-18.5-50.7-25.3-76.8
				c-12.7-50.2-19.1-101.8-19.2-153.6V1037c-0.1-63.5,51.4-115.1,114.9-115.2c0.1,0,0.2,0,0.3,0H1382.6z"/>
			<path className="st2" d="M1305.8,921.8v1050c0.1,7.5-0.9,15-3.1,22.3c-10.1,46.6-51.4,79.9-99.1,79.9H774.3
				c-14.8-24.6-27.6-50.2-38.4-76.8c-10.1-25-18.5-50.7-25.3-76.8c-12.7-50.2-19.1-101.8-19.2-153.6V1037
				c-0.1-63.5,51.4-115.1,114.9-115.2c0.1,0,0.2,0,0.3,0H1305.8z"/>
			<path className="st2" d="M1305.8,921.8v896.4c-0.3,56.3-45.8,101.9-102.2,102.2H710.5c-12.7-50.2-19.1-101.8-19.2-153.6V1037
				c-0.1-63.5,51.4-115.1,114.9-115.2c0.1,0,0.2,0,0.3,0H1305.8z"/>
			<path className="st2" d="M806.5,921.8c-63.5-0.1-115.1,51.4-115.2,114.9c0,0.1,0,0.2,0,0.3v729.7c0.1,51.8,6.5,103.4,19.2,153.6h416.3
				c56.3-0.3,101.9-45.8,102.2-102.2V921.8H806.5z"/>
			<path className="st4" d="M102.4,614.5h1024.2c56.5,0,102.4,45.8,102.4,102.4l0,0v1024.2c0,56.5-45.8,102.4-102.4,102.4c0,0,0,0,0,0
				H102.4C45.8,1843.5,0,1797.7,0,1741.1c0,0,0,0,0,0V716.9C0,660.3,45.8,614.5,102.4,614.5C102.4,614.5,102.4,614.5,102.4,614.5z"/>
			<path className="st5" d="M920.2,996.6H690.5v616H539.8v-616H308.8V845.3h611.4V996.6z"/>
			<path className="st6" d="M0,0h2458v2458H0V0z"/>
		</svg>
	)
}
