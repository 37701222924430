import React from "react";
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export function ProjectTooltip() {
  return (
    <>
      Project IDS
      <Tooltip
        title={
          <div>
            <p style={{ marginBottom: '3px' }}>
              Multiple IDs are allowed. Use comma, semi-colon or space to separate.
            </p>
            <p style={{ marginBottom: 'unset' }}>
              <InfoCircleOutlined style={{ marginRight: '5px' }} />
              Duplicate IDs will be ignored. Press enter to submit.
            </p>
          </div>
        }
        placement="right"
        color={'#00a89f'}
        overlayInnerStyle={{ width: '448px' }}
      >
        <InfoCircleOutlined
          style={{
            marginLeft: '5px',
            color: '#00a89f',
            cursor: 'help',
          }}
        />
      </Tooltip>
    </>
  );
}
