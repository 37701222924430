import React, { useEffect, useState } from 'react';
import './ExtensionDetailsModal.scss';
import { getExtensionRequestById } from '../../api/extensionRequest';
import moment from 'moment';
import { useMercuryContext } from '../../../../user-context';
import { Modal, Descriptions } from 'antd';
import LoadingStar from '../../../../ui/LoadingStar';
import TimezoneConverter from '../../../../timezone-converter';
import { JustificationCodes } from '../../constants/placeholders';

/**
 *
 * @param props
 * modalExtensionId - string: Modal extension ID that will be used to call the API
 * isModalVisible - boolean : If the modal must be visible or not;
 * setIsModalVisible - callback : Function to update modal visible state;
 * @returns
 */
const ExtensionDetailsModal = (props) => {
  const { modalExtensionId, isModalVisible, setIsModalVisible, isDateNeeded } =
    props;
  const initialExtensionDetails = {};
  const { Item } = Descriptions;
  const [requestData, setRequestData] = useState(initialExtensionDetails);

  const { accessToken } = useMercuryContext();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Clean up the component to avoid memory leak problems

    setIsLoaded(false);
    if (isModalVisible && modalExtensionId) {
      // Request the data to GET endpoint with request ID

      getExtensionRequestById(modalExtensionId, accessToken).then((res) => {
        res.code = 404;
        if (res.code !== 404 || res === undefined) {
          alert('Register not found in database');
        } else {
          setRequestData(res);
          setIsLoaded(true);
        }
      });
    }
  }, [isModalVisible, accessToken, modalExtensionId]);
  //FIX Story MER-1446
  const printJustDesc = JustificationCodes.find(
    (element) => parseInt(element.name.substring(0, 1)) === requestData.justificationCode
  )?.name?.substring(2);

  return (
    <Modal
      className="extension-details-modal"
      open={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={null}
      data-testid="extension-modal"
      width={600}
    >
      {isLoaded && (
        <Descriptions title="Extension Request Details" bordered column={1}>
          <Item label="Requested By"> {requestData.requestedBy}</Item>
          {isDateNeeded && (
            <>
              <Item label="Created">
                <TimezoneConverter
                  date={moment(requestData.created)}
                  format={'DD MMM YYYY\t hh:mm:ss'}
                />
              </Item>
              <Item label="Last Updated">
                <TimezoneConverter
                  date={moment(requestData.lastUpdated)}
                  format={'DD MMM YYYY\t hh:mm:ss'}
                />
              </Item>
            </>
          )}
          <Item label="Justification Code">
            {requestData.justificationCode} {printJustDesc}
          </Item>
          <Item label="Justification">{requestData.justification}</Item>
          <Item label="Product">{requestData.product}</Item>
          <Item label="Product Area"> {requestData.productArea}</Item>
        </Descriptions>
      )}
      {!isLoaded && <LoadingStar />}
    </Modal>
  );
};
export default ExtensionDetailsModal;
