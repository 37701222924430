import React, { useState, useEffect, useRef, useCallback } from "react";
import { useMercuryContext } from "../user-context";
import { Helmet } from "react-helmet";
import { Form } from "antd";

import PageTitle from "../ui/PageTitle";
import AddWorkflowType from "../components/managePages/workflow-types/AddWorkflowType";
import EditWorkflowType from "../components/managePages/workflow-types/EditWorkflowType";
import WorkflowTypeTable from "../components/managePages/workflow-types/WorkflowTypeTable";
import BackTopButton from "../ui/BackTopButton";
import LoadingStar from "../ui/LoadingStar";

import {
  failedNotification,
  successfulNotification,
  infoNotification,
} from "../utils/notifications";

const initialEditingValue = {
  isEditing: false,
  editingRow: "",
}

const ManageWorkflowTypes = () => {
  const { client: Client, accessToken } = useMercuryContext(),
    editFormRef = useRef(null),
    [form] = Form.useForm(),
    [editingForm] = Form.useForm(),
    [isLoading, setIsLoading] = useState(false),
    [visibleDrawer, setVisibleDrawer] = useState(false),
    [visibleEditDrawer, setVisibleEditDrawer] = useState(false),
    [switchValue, setSwitchValue] = useState(true),
    [workflowTypes, setWorkflowTypes] = useState([]),
    [sortedInfo, setSortedInfo] = useState(""),
    [editing, setEditing] = useState(initialEditingValue),
    [total, setTotal] = useState(0);

  const fetchWorkflowTypesList = useCallback(
    async (useIsLoading = true) => {
      try {
        setIsLoading(useIsLoading);
        const { data } = await Client.get("google-gp-v1/WorkflowType");

        setWorkflowTypes(data);
        setTotal(data.length);
      } catch (error) {
        console.error('Error fetching workflow types list: ', error);
      }
      setIsLoading(false);
    },
    [Client]
  );

  // add new workflow type
  const addWorkflowType = async (body) => {
    try {
      setEditing({ ...editing, isEditing: true });
      const { data } = await Client.post("google-gp-v1/WorkflowType", body);

      if (data.error) {
        failedNotification(data.error, data.message);
      } else {
        infoNotification(
          `Workflow type - ${data.title} was added.`,
          "Please refresh the page if the new Workflow type is not visible."
        );
      }
    } catch (error) {
      console.error('Error adding workflow type: ' ,error);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // update a single workflow type
  const updateWorkflowType = async (workflowTypeId, newData) => {
    try {
      setEditing({ ...editing, isEditing: true });
      const { data } = await Client.patch(`google-gp-v1/WorkflowType/${workflowTypeId}`, newData);

      if (data) {
        successfulNotification(
          `Workflow type - ${data.title} was updated.`,
          `Please refresh your page if the update is not visible.`
        );
      }
    } catch (error) {
      console.error('Error updating workflowtype: ', error);
      failedNotification(error.message, error.details);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // on load
  useEffect(() => {
    fetchWorkflowTypesList();
  }, [fetchWorkflowTypesList]);

  const defaultValues = {
    idValue: editing.editingRow.id,
    descriptionValue: editing.editingRow.description,
    titleValue: editing.editingRow.title,
    isActiveValue: editing.editingRow.isActive,
  };

  // fix to update initial values of edit form
  useEffect(() => {
    if (editFormRef.current) {
      editingForm.setFieldsValue(defaultValues);
    }
  }, [editFormRef, editingForm, defaultValues]);

  const handleRefresh = async () => {
    try {
      (await accessToken) && fetchWorkflowTypesList(false);
      infoNotification(
        "Workflow types data reloaded",
        "The Workflow types data has been reloaded"
      );
    } catch (error) {
      failedNotification(
        "Failed to reload",
        "Unable to reload the Workflow types data at this time, please try again. If the issue persists please contact support."
      );
    }
  };

  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const closeDrawer = () => {
    setVisibleDrawer(false);
    form.resetFields();
  };

  // handle change of active switch button
  function handleChangeActive(checked) {
    setSwitchValue(checked);
  }

  // on save of add new workflow type
  const onFinishNewWorkflowType = (values) => {
    // generates the new object
    const newValues = {
      description: values.descriptionValue,
      title: values.titleValue,
      isActive: switchValue,
    };

    addWorkflowType(newValues);
    closeDrawer();
  };


  function showEditDrawer() {
    setVisibleEditDrawer(true);
  }

  const closeEditDrawer = (event) => {
    setVisibleEditDrawer(false);
    form.resetFields();
    event !== undefined && setEditing(initialEditingValue);
  };

  // on save of edit
  const onFinishEditing = (values) => {
    const {
      idValue,
      descriptionValue,
      titleValue,
      isActiveValue,
    } = values;

    const newData = {
      id: idValue,
      description: descriptionValue,
      title: titleValue,
      isActive: isActiveValue.toString(),
    };

    const workflowTypeId = editing.editingRow.id;

    updateWorkflowType(workflowTypeId, newData);

    closeEditDrawer();
  };

  // handle change table
  const handleChangeTable = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
  };

  // handle click table edit button
  const handleClickEdit = (record) => {
    setEditing({
      ...editing,
      isEditing: true,
      editingRow: record,
    });
    showEditDrawer();
  };

  return (
    <div className="manage-page manage-workflowtypes-page" style={{padding: '0 10px'}}>
      <Helmet>
        <title>Manage Workflow Types - Mercury © RWS</title>
      </Helmet>
      {isLoading ? (
        <LoadingStar />
      ) : (
        <>
          <PageTitle title="Manage Workflow Types" />
          <AddWorkflowType
            handlers={{
              showDrawer,
              closeDrawer,
              onFinishNewWorkflowType,
              handleChangeActive,
              handleRefresh,
            }}
            values={{ form, visibleDrawer, switchValue, workflowTypes }}
          />
          <EditWorkflowType
            handlers={{
              closeEditDrawer,
              onFinishEditing,
            }}
            values={{
              editingForm,
              visibleEditDrawer,
              editing,
              editFormRef,
              workflowTypes,
            }}
          />
          <WorkflowTypeTable
            values={{ workflowTypes, sortedInfo, editing, total }}
            handlers={{
              handleChangeTable,
              handleClickEdit,
            }}
          />
          <BackTopButton />
        </>
      )}
    </div>
  );
};

export default ManageWorkflowTypes;
