import React, {useState} from "react";

import { Tooltip, Input } from "antd";

const NumericInput = (props) => {
  const { value, onChange, onPressEnter, max, min } = props;
  const [isDisabled, setIsDisabled] = useState(false);

  const formatNumber = (value) => new Intl.NumberFormat().format(value);
  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^\d*(\d*)?$/;

    if (reg.test(inputValue) || inputValue === '' || parseInt(inputValue) > 50 ) {
      
      onChange(parseInt(inputValue) > max ? "50" : parseInt(inputValue) < min ? "1" : inputValue);
    }
  }; // '.' at the end or only '-' in the input box.

  const handleBlur = () => {
    let valueTemp = value;
    if(valueTemp === "" ){
      valueTemp = min.toString()
    }
    
    onChange(valueTemp);
  };


  const handleEnterPressed = async (e) => {
    setIsDisabled(true);
    await onPressEnter(e.target.value);
    setIsDisabled(false);
  }

  const title = value ? (
    <span className="numeric-input-title">{value !== '-' ? formatNumber(Number(value)) : '-'}</span>
  ) : (
    'Input a number'
  );
  return (
    <Tooltip trigger={['focus']} title={title} placement="topLeft" overlayClassName="numeric-input">
      <Input
        {...props}
        disabled={isDisabled}
        onChange={handleChange}
        onBlur={handleBlur}
        maxLength={2}
        onPressEnter={handleEnterPressed}
      />
    </Tooltip>
  );
};


export default NumericInput;
