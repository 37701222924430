import React, {Component} from "react";
export default class VirtualScrollbar extends Component<any, any> {
    render() {
        const
            { table, hasSidebar } = this.props;


        const

            tableWidth = table?.current?.table.headerRef.headerRef.firstChild.getBoundingClientRect();

        return <div className={`virtualScrollbar ${hasSidebar ? "with-sidebar" : ""}`} onScroll={(evt:any) => {
            table.current?.scrollToLeft(evt.currentTarget.scrollLeft)
        }}>
            <div style={{width: `${tableWidth?.width}px`, height: "9px"}}/>
        </div>
    }
}
