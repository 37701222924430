import React, { useEffect } from "react";
import { Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useMercuryContext } from "../../user-context";

import { useLocation } from "react-router-dom";

const LeftMenu = ({ adminMenuData, mobile = false }) => {
  const { isAdmin, userDetails, flags } = useMercuryContext();

  const location = useLocation();

  const items = [
    flags?.searchTab && {
      label: (
        <Link
          to={"/"}
          onClick={() => adminMenuData.handleMainMenuSelection("projects")}
        >
          Search {!mobile && <DownOutlined />}
        </Link>
      ),
      key: "search",
      popupClassName: 'search-dropdown-menu',
      children: [
        {
          label: <Link to={"/"}>Projects</Link>,
          key: "projects",
        },
        {
          label: <Link to={"/plps"}>Plps</Link>,
          key: "plps",
        },
        {
          label: <Link to={"/documents"}>Documents</Link>,
          key: "documents",
        },
      ],
    },
    {
      label: <Link to={"/invoicing"}>Invoicing</Link>,
      key: "invoicing",
      // icon: <DownOutlined />,
    },
    (userDetails.vendorManager || isAdmin) && {
      label: <Link to={"/linguist-management"}>Linguist Management</Link>,
      key: "linguist-management",
    },
    {
      label: <Link to={"/reports"}>Reports</Link>,
      key: "reports",
    },
    {
      label: <Link to={"/extension-requests"}>Extension Requests</Link>,
      key: "extension-requests",
    },
    (flags?.adjustmentsTab || isAdmin) && {
      label: <Link to={"/adjustments"}>Adjustments</Link>,
      key: "adjustments",
    },
  ];

  useEffect(() => {
    const allItems = items
      .map((i) => (i.children ? [...i.children, i] : i))
      .flat();

      // fix for homepage
    const path =
      location?.pathname === "/" || location.pathname.includes("/project/")
        ? "search"
        : location?.pathname.replaceAll("/", "");

    const isUsed = allItems.filter((i) => path.includes(i.key)).length;
    
    if (!isUsed) {
      adminMenuData.handleMainMenuSelection(null);
    }
  }, [location]);

  return (
    <Menu
      onClick={(e) => adminMenuData.handleMainMenuSelection(e.key)}
      selectedKeys={[adminMenuData.currentMenuItem]}
      mode={mobile ? "inline" : "horizontal"}
      items={items}
    />
  );
};

export default LeftMenu;
