import React from "react";
import PropTypes from 'prop-types';
import { Button, Tooltip } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import { failedNotification } from "../utils/notifications";
class DownloadReferenceBtn extends React.Component {
  state = {
    downloadState: "INACTIVE",
    hasError: false,
    errorMessage: "",
  };
  handleDownload = async () => {
    this.setState({
      downloadState: "DOWNLOADING",
    });

    try {
      let response = null;

      // Get with the array of files to download
      response = await this.props.client.post(`/google-gp-v1/reference-file/download/${this.props.resourceId}`);
      // Get the URL to upload the file to
      let downloadURL = response.data.url;

      // Create a link and click it to download the file
      let downloadLink = document.createElement("a");
      downloadLink.href = downloadURL;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error);
      if (error) {
        failedNotification(
          "Download failed",
          "Unable to download the file(s) at this time."
        );
      }
    } finally {
      this.setState({
        downloadState: "INACTIVE",
      });
    }
  };

  render() {
    switch (this.state.downloadState) {
      case "DOWNLOADING":
        return (
          <Button
            icon={<DownloadOutlined className="download-single-icon" />}
            style={{ border: "none", padding: "0", color: "var(--tertiary-color)" }}
            color={"var(--tertiary-color)"}
            loading
          />
        );
      case "INACTIVE":
      default:
        return (
          <Tooltip
            title={`Download ${this.props.name}`}
            color={"var(--tertiary-color)"}
            placement={"left"}
          >
            <Button
              icon={<DownloadOutlined className="download-single-icon" />}
              style={{ border: "none", padding: "0", height: "25px", borderRadius: "0" }}
              onClick={this.handleDownload}
            />
          </Tooltip>
        );
    }
  }
}

DownloadReferenceBtn.propTypes = {
  name: PropTypes.string,
  resourceId: PropTypes.string,
  client: PropTypes.object
};

export default DownloadReferenceBtn;
