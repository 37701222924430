import React, {useEffect, useState} from "react";
import {NumberFilterProps, NumberFilter} from "../stories/NumberFilter/NumberFilter";


export default function MongoNumberFilter( props:NumberFilterProps ) {

    const
        [ displayValue, setDisplayValue ] = useState<any>({}),
        { onChange, value, ...rest } = props,
        middleware = ( params: any ) => {

            const {
                    target
                } = params,
                {
                    name,
                    value:type
                } = target;

            if ( !type ) {
                onChange( { target: {
                    name,
                    value: undefined
                } } );
                return;
            }
            const {
                    selection,
                    value
                } = type;

            let output:any = [];

            switch( selection as string ) {
                case "between":
                    output = {
                            $gte: value[0],
                            $lte: value[1]
                    };
                    break;
                case "less":
                    output = {
                        $lte: value
                    }
                    break;
                case "greater":
                    output = {
                        $gte: value
                    }
                    break;
                default:
                    output = value;
                    break;
            }

            onChange( { target: {
                name,
                value: output
            } } );
        };

    useEffect( () => {
        if ( !props.value ) {
            setDisplayValue( undefined )
            return;
        }
        if ( props.value && Object.keys( props.value ).length > 1 ) {
            setDisplayValue( {
                selection: "between",
                value: [ props.value.$gte, props.value.$lte ]
            })
        }
        else if ( "$lte" in props.value ) {
            setDisplayValue( {
                selection: "less",
                value: props.value.$lte
            })
        }
        else {
            setDisplayValue( {
                selection: "greater",
                value: props.value.$gte
            })
        }

    }, [props])


    return <NumberFilter {...rest} value={displayValue} onChange={middleware} placeholder={"Select"}/>
}
