import React from "react";
import {
    FiniteListWithAsyncLoaderAbstract, ListProps
} from "../Components";
import {useMercuryContext} from "../user-context";

class CostCodeFilter extends FiniteListWithAsyncLoaderAbstract {

    async fetch() {
        const { data } = await this.props.context.client.get("/core-ccs-v1");
        return data.map( ( item:any ) => {
            return {
                title: item.code,
                value: item.code
            }
        })
    }
}

export default (props:ListProps) => {
    const context = useMercuryContext();
    return <CostCodeFilter context={context} {...props} />
}

