import React from "react";
import {Form, Input} from "antd";

export default function ProjectNames( props ) {

  const {
    handleProjectNameChange,
    projectNameString
  } = props;

  return (
    <Form.Item
      label={"Project Names"}
      name="projectNames"
      rules={[{ required: false}]}
      key={props.key}
    >
      <Input
        placeholder="Full or partial project name"
        autoComplete="off"
        type="text"
        onChange={handleProjectNameChange}
        value={projectNameString}
        aria-expanded="true" />

    </Form.Item>
  )
}
