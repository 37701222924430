import React, {Component, useEffect, useState} from "react";
import {useMercuryContext} from "../../user-context";
import { Comment, Commenter as CommentInput } from "../../Components";
import LoadingStar from "../../ui/LoadingStar";

export class Commenter extends Component<any, any> {

    state = {
        id: null,
        loading: false,
        comments: [],
        boundIds: new Set()
    }

    onUpdate = ( comments:any ) => {
        const
            ids = new Set( comments.map( ( comment:any ) => {
                return [ comment.document, comment._id, ...comment.replies.map( ( comment:any ) => comment.document ) ]
            }).flat() );

        this.props.ums.umsSocket.comment.emit("bind:ids", Array.from( ids ), () => console.log( "BOUND"))
        this.setState({ comments, loading: false, boundIds: ids  })
    }

    componentDidMount() {
        this.props.ums.umsSocket.comment.emit("getCommentsByRef", this.props.id, this.onUpdate );
        this.props.ums.umsSocket.comment.on( this.props.id, this.onUpdate )
    }

    componentWillUnmount() {
        this.props.ums.umsSocket.comment.emit("unbind:ids", [this.state.boundIds])
        this.props.ums.umsSocket.comment.off( this.props.id, this.onUpdate )
    }

    add = ( text:string ) => {
        this.props.ums.umsSocket.comment.emit("addComment",{ document:this.props.id, text } );
    }

    like = ( id:string ) => {
        this.props.ums.umsSocket.comment.emit("like", { document: id, parent: this.props.id } )
    }

    reply = (id:string, text:string) => {
        this.props.ums.umsSocket.comment.emit("addReply", { document: id, parent: this.props.id, text } )
    }

    render() {

        if ( this.state.loading || !this.props.ums.userDetails ) {
            return <LoadingStar title={"Fetching notes..."} />
        }

        return <>
                {
                    this.state.comments.map( ( comment:any ) => {
                        const
                            c = {
                                key : comment._id,
                                ...comment,
                                setLike:  async ( commentId:string, user:string ) => {
                                    await this.like( commentId );
                                },
                                currentUser: this.props.ums.userDetails._id,
                                addReply: async ( commentId:string, text:string, user:string ) => {
                                    this.reply( commentId, text );
                                }
                            }
                        return <Comment { ...c } />
                    })
                }
                <CommentInput
                    _id={this.props.id as string}
                    name={this.props.id as string}
                    onComment={async (newId, text, user ) => {
                        this.add( text )
                    }}
                    placeholder={this.props.placeholder}
                    user={this.props.ums.userDetails._id}
                />
            </>
    }
}

export default function CommentFn({ id, placeholder, comments }:any) {

    const
        ums = useMercuryContext();

    return <Commenter
        id={id}
        placeholder={placeholder}
        ums={ums}
    />
}
