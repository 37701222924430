import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Input, Select, Row, Col, Space, Modal, Tooltip } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, CloseOutlined, CloseCircleOutlined, AlignLeftOutlined } from '@ant-design/icons';
import createPayload from '../helpers/createPayload';
import isEmpty from '../helpers/objects';

const affiliationOptions = [{ value: 'Internal', label: 'Internal' }, { value: 'External', label: 'External' }];

const Filters = ({ showFilters, setShowFilters, programRoles, projectRoles, componentTypes, languages, products, setFilterQuery, setRefreshLinguists, setIsDirty, isDirty, setValueFilters }) => {
  const [form] = Form.useForm();

  const onSubmit = values => {
    const payload = createPayload(values);
    setFilterQuery(payload);
    setRefreshLinguists(true);
    setShowFilters(true);
  };

  const handleReset = () => {
    Modal.confirm({
      title: 'Confirm Clear Filters',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to clear the filters?',
      okText: 'Clear Filters',
      cancelText: 'Cancel',
      onOk: clearForm,
    });
  };

  const clearForm = () => {
    setFilterQuery({});
    setRefreshLinguists(true);
    form.resetFields();
    setIsDirty(false);
    setShowFilters(false);
    setValueFilters({});
  };

  const formChanges = () => {
   
    if (isEmpty(form.getFieldsValue()))
    {
      setIsDirty(false); 
      setValueFilters({});
    }
    else
    {
      setIsDirty(true); 
      setValueFilters(form.getFieldsValue());
    }
  }

  return (
    <div>
      <Tooltip placement="right" title={showFilters ? 'Hide filters' : 'View all filters'} color="var(--tertiary-color)">
        <Button style={{ borderRadius: '4px'}} type="primary" icon={showFilters ? <CloseCircleOutlined /> : <AlignLeftOutlined />} onClick={() => { setShowFilters(x => !x); }}>Filters</Button>
      </Tooltip>
      {showFilters && (
        <div style={{ background: '#f4f5f7', border: '1px solid var(--grey)', padding: '10px', margin: '10px' }}>
          <Form form={form}
            name="control-hooks"
            onFinish={onSubmit}
            layout="vertical"
            onValuesChange={formChanges}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item name="email" label="Personal Email">
                  <Input placeholder="Personal Email" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="sesameAccount" label="Sesame Account">
                  <Input placeholder="Sesame Account" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="sdl_email" label="RWS Email">
                  <Input placeholder="RWS Email" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="sharingElegible" defaultValue='' label="Sharing Eligible">
                  <Select allowClear>
                    <Select.Option value='yes'>Yes</Select.Option>
                    <Select.Option value='no'>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
              <Form.Item name="sourceLanguage" label="Source Languages">
                  <Select mode="multiple">
                    {languages && languages.length > 0 && (
                      languages.map(lang => {
                        return <Select.Option key={`lang-${lang.code}`} value={lang.code}>{`${lang.name} (${lang.code})`}</Select.Option>;
                      })
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="language" label="Target Languages">
                  <Select mode="multiple">
                    {languages && languages.length > 0 && (
                      languages.map(lang => {
                        return <Select.Option key={`lang-${lang.code}`} value={lang.code}>{`${lang.name} (${lang.code})`}</Select.Option>;
                      })
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="product" label="Product">
                  <Select mode="multiple">
                    {products && products.length > 0 && (
                      products.map(prod => {
                        return <Select.Option key={`prod-${prod._id}`} value={prod.title}>{prod.title}</Select.Option>;
                      })
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="componentType" label="Component Type">
                  <Select mode="multiple">
                    {componentTypes && componentTypes.length > 0 && (
                      componentTypes.map((component, index) => {
                        return <Select.Option key={`component-${index}`} value={component}>{component}</Select.Option>;
                      })
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="projectRole" label="Project Role">
                  <Select mode="multiple">
                    {projectRoles && projectRoles.length > 0 && (
                      projectRoles.map((project, index) => {
                        return <Select.Option key={`projectRole-${index}`} value={project.value}>{project.value}</Select.Option>;
                      })
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item name="programRole" label="Program Role">
                  <Select>
                    <Select.Option value=''></Select.Option>
                    {programRoles && programRoles.length > 0 && (
                      programRoles.map((program, index) => {
                        return <Select.Option key={`program-${index}`} value={program.value}>{program.value}</Select.Option>;
                      })
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="affiliation" label="Affiliation" layout="horizontal">
                  <Select options={affiliationOptions} allowClear />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="active" defaultValue='' label="Active">
                  <Select allowClear>
                    <Select.Option value='yes'>Yes</Select.Option>
                    <Select.Option value='no'>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Space>
                  {isDirty && <Button style={{ borderRadius: '4px'}} type="default" icon={<CloseOutlined />} onClick={handleReset} danger>Clear Filters</Button>}
                  <Button style={{ borderRadius: '4px', marginLeft: '8px'}} type="primary" htmlType="submit" icon={<SearchOutlined />}>Search</Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
};

Filters.propTypes = {
  accessToken: PropTypes.string,
  config: PropTypes.object,
  styles: PropTypes.object,
  programRoles: PropTypes.array,
  projectRoles: PropTypes.array,
  componentTypes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  languages: PropTypes.array,
  products: PropTypes.array,
  pageSize: PropTypes.number,
  setFilterQuery: PropTypes.func,
  setRefreshLinguists: PropTypes.func,
};

export default Filters;
