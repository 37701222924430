class Config {
  constructor(stage) {
    switch (stage) {
      default:
      case "qa":
        this.URL = "http://localhost:3001";
        this.API_OLD = "https://ff2ba7pjrg.execute-api.eu-west-1.amazonaws.com";
        this.AUTH = "http://localhost:9001/tokenapp";
        this.API = "http://localhost:3001";
        break;
      case "dev":
        // this.URL = "https://tc9elziwog.execute-api.eu-west-1.amazonaws.com";
        this.URL = "https://f6w6suez2d.execute-api.eu-west-1.amazonaws.com";
        this.API_OLD = "https://ff2ba7pjrg.execute-api.eu-west-1.amazonaws.com";
        this.AUTH = "https://sdl-prod.eu.auth0.com/oauth/token";
        this.API = "https://dev-api.mercury.sdl.com";
        break;
      case "preprod":
        // this.URL = "https://2lw084j91h.execute-api.eu-west-1.amazonaws.com";
        this.URL = "https://1eiugi25ml.execute-api.eu-west-1.amazonaws.com";
        this.API_OLD = "https://ldvgeyuydj.execute-api.eu-west-1.amazonaws.com";
        this.AUTH = "https://sdl-prod.eu.auth0.com/oauth/token";
        this.API = "https://stage-api.mercury.sdl.com";
        break;
      case "prod":
        this.URL = "https://cy97aa1dlf.execute-api.eu-west-1.amazonaws.com";
        this.API_OLD = "https://tricdnv2fh.execute-api.eu-west-1.amazonaws.com";
        this.AUTH = "https://sdl-prod.eu.auth0.com/oauth/token";
        this.API = "https://api.mercury.sdl.com";
        break;
      case "test":
        // this.URL = "https://7v0e1z5n8l.execute-api.eu-west-1.amazonaws.com";
        this.URL = "https://tgzi8hbai0.execute-api.eu-west-1.amazonaws.com";
        this.API_OLD = "https://j78a2b885j.execute-api.eu-west-1.amazonaws.com";
        this.AUTH = "https://sdl-prod.eu.auth0.com/oauth/token";
        this.API = "https://test-api.mercury.sdl.com";
        break;
    }
    this.BODY_AUTH = {
      client_id: "627cd2bf644e75c48d91b3a3",
      client_secret: "BxwhIrMC8sSummuMLx1p5wdnRiYMzS",
    };
  }
}
export default Config;