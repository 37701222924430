import React, { useState, useRef } from "react";
// @ts-ignore
import Highlighter from "react-highlight-words";
import moment from "moment";
import DownloadBtn from "../../containers/DownloadBtn";
import { formatFilters, removeUnderscore, objectHasValues, sorter } from "../../utils/helpers";
import {
  Collapse,
  Input,
  Button,
  Space,
  Divider,
  Table,
  Tooltip,
  Spin,
  List,
  ConfigProvider,
  Popconfirm
} from "antd";

import {
  DownloadOutlined,
  DeleteOutlined,
  SearchOutlined,
  LoadingOutlined,
  InfoCircleOutlined,
  GoogleOutlined,
  EditOutlined,
} from "@ant-design/icons";

import EditFileIdForm from "./editFileIdForm/EditFileIdForm";

const DtpFilesSection = ({
  details,
  rowSelectionDtpFiles,
  selectedDtpRows,
  downloadProps,
  readyProjectType,
  handlers,
  values,
}: any) => {
  const [activeFilterSorter, setActiveFilterSorter] = useState<boolean>(false);
  const [filteredInfo, setFilteredInfo] = useState<any>("");
  const [sortedInfo, setSortedInfo] = useState<any>("");
  const [totalDtpProjects, setTotalDtpProjects] = useState<any>("");
  const [documentSearchValue, setDocumentSearchValue] = useState<any>("");
  const [tLanSortOrder, setTLanSortOrder] = useState<any>("ascend");

  const { Panel } = Collapse;

  const { editing } = values;
  const {
    handleClickFinishEditing,
    handleClickEdit,
    handleDeleteUploadededDocument,
    resetDtpTableSelection,
  } = handlers;

  // Nested object keys
  const readyFiles = details?.files;

  // DTP files section
  const dtpFilesData : any = [];
  // default filter values
  const filterValues : any = { targetLanguage: [], stage: [], fileType: [] };

  const prepareUploadDocuments = (dtpFile: any) => {
    let uploadedDocuments = [];
  
    if (dtpFile.fileType === "SOURCE") {
      if (dtpFile.customVendorFileMetadata.preProcessedFiles?.length) {
        uploadedDocuments = dtpFile.customVendorFileMetadata.preProcessedFiles;
      } else if (dtpFile.customVendorFileMetadata.associatedFilenames) {
        uploadedDocuments = dtpFile.customVendorFileMetadata.associatedFilenames.map(
          (associatedFile: any) => ({
            _id: null,
            filename: associatedFile,
            previewURL: null,
            fileDataRef: dtpFile.customVendorFileMetadata.fileDataRef,
            status: dtpFile.customVendorFileMetadata.status,
          })
        );
      } else if (dtpFile.customVendorFileMetadata.associatedFilename) {
        uploadedDocuments = [
          {
            _id: null,
            filename: dtpFile.customVendorFileMetadata.associatedFilename,
            previewURL: null,
            fileDataRef: dtpFile.customVendorFileMetadata.fileDataRef,
            status: dtpFile.customVendorFileMetadata.status,
          },
        ];
      }
    } else if (dtpFile.fileType === "TRANSLATED") {
      if (dtpFile.customVendorFileMetadata.associatedFilename) {
        uploadedDocuments = [
          {
            _id: null,
            filename: dtpFile.customVendorFileMetadata.associatedFilename,
            previewURL: null,
            fileDataRef: dtpFile.customVendorFileMetadata.fileDataRef,
            status: dtpFile.customVendorFileMetadata.status,
          },
        ];
      }
    }
  
    return uploadedDocuments;
  };
  

  // Loop through nested arrays
  readyFiles?.forEach(function (file: any, index: number) {
      const newDocument: any = {
        key: index,
        id: file._id,
        resourceName: file.fileDataRef.resourceName,
        uploadedFileId: file.customVendorFileMetadata.uploadedFileId,
        preview: file.customVendorFileMetadata.previewURL,
        document: file.displayName,
        fileType: file.fileType,
        fileUri: file?.fileUri,
        stage: file.customVendorFileMetadata.status,
        targetLanguage:
          file.customVendorFileMetadata.status === "TRANSLATED" ||
            file.customVendorFileMetadata.status ===
            "SENDING_TO_TRANSLATED_POST_PROCESSED" ||
            file.customVendorFileMetadata.status ===
            "TRANSLATED_POST_PROCESSED" ||
            file.customVendorFileMetadata.status === "POST_PROCESSED_UPLOADED" ||
            file.customVendorFileMetadata.status === "FAILED_POST_PROCESSING" ||
            file.customVendorFileMetadata.status === "AWAITING_POST_PROCESSING"
            ? [file.languageCode]
            : file.targetLanguageCodes.sort((a: any, b: any) => a.localeCompare(b)),
        uploadedDocument: prepareUploadDocuments(file),

        // will be displayed as a tooltip if the status is Failed
        failedReason:
          file.customVendorFileMetadata.failedReason === undefined
            ? ""
            : file.customVendorFileMetadata.failedReason,
        processingDuration: file.customVendorFileMetadata?.postProcessingDuration
      };

      // Filter to display only the files with fileType: SOURCE or TRANSLATED
      if (file.fileType === "SOURCE" || file.fileType === "TRANSLATED") {
        dtpFilesData.push(newDocument);

        // generate filters values
        // fix for null value - target language filter values
        file.targetLanguageCodes !== null &&
          file.targetLanguageCodes.forEach((item: any) =>
            filterValues.targetLanguage.push(item)
          );

        // stage column filter values
        filterValues.stage.push(newDocument.stage);

        //document type column filter values
        filterValues.fileType.push(newDocument.fileType);
      }
    });

  // handle change of DTP files table
  const handleChangeDtpFiles = (pagination: any, filters: any, sorter: any, extra: any) => {

    if (!Array.isArray(sorter) && sorter.columnKey === "targetLanguage") {
      if (tLanSortOrder === "ascend") {
        setTLanSortOrder("descend");
      }
      if (tLanSortOrder === "descend") {
        setTLanSortOrder(false);
      }
      if (tLanSortOrder === false) {
        setTLanSortOrder("ascend");
      }
    }
    if (!Array.isArray(sorter) && sorter.columnKey !== "targetLanguage") {
      setTLanSortOrder(false);
    }

    if (Array.isArray(sorter) && sorter[sorter.length - 1].columnKey !== "targetLanguage") {
      setTLanSortOrder(false);
    }

    setActiveFilterSorter(true);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setTotalDtpProjects(extra.currentDataSource.length);
    // reset selection only if there were filters applied
    objectHasValues(filters) && resetDtpTableSelection();
  };

  // Clear Filters and Sorter
  const clearDtpFiltersSorter = () => {
    setActiveFilterSorter(false);
    setFilteredInfo("");
    setSortedInfo("");
    setDocumentSearchValue("");
  };

  // Column Search filter - START
  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setDocumentSearchValue(selectedKeys[0]);
  };

  const handleReset = (clearFilters: any) => {
    setActiveFilterSorter(false);
    clearFilters();
    setDocumentSearchValue("");
  };

  // criteria to display/hide entire columns
  const visibleColumns: any =
    dtpFilesData.filter(
      (item: any) =>
        item.stage === "AWAITING_POST_PROCESSING" ||
        item.stage === "POST_PROCESSED_UPLOADED" ||
        item.stage === "FAILED_POST_PROCESSING"
    ).length > 0;

  // will check if dtp files contains any GDrive file
  const hasGdriveFiles =
    dtpFilesData.filter((item: any) => item.fileUri && item.fileUri !== null)
      .length > 0;

  // textInput must be declared here so the ref can refer to it
  const textInput: any = useRef(null);
  const getDocumentColumnSearchProps: any = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={textInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined
        style={{ color: filtered ? "var(--tertiary-color)" : undefined }}
      />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible: any) => {
      if (visible) {
        setTimeout(() => textInput?.current?.select(), 100);
      }
    },
  });
  // Column Search filter - END

  const processingDuration = (record: any, text: any) => {
    return (
      record.processingDuration ? (
        <Tooltip
          title={`Processing time: ${moment.utc(record.processingDuration.replace('s', '') * 1000).subtract(1, "day").format('DD - HH:mm:ss')}`}
          placement="left"
        >
          {removeUnderscore(text)}
        </Tooltip>
      ) : (
        removeUnderscore(text)
      )
    );
  };

  const isGeneratingPreview = (item: any) => {
    return (
      item.status === "GENERATING_PREVIEW" ? (
        <Tooltip
          title="Generating Preview"
          placement="bottom"
          color={"var(--tertiary-color)"}
        >
          <LoadingOutlined disabled />
        </Tooltip>
      ) : null
    );
  };

  const generatingPreview = (item: any) => {
    return (
      item.status === "READY_FOR_PREVIEW" ? (
        <a href={item.previewURL} target="_blank" rel="noopener noreferrer">
          <SearchOutlined />
        </a>
      ) : (
        isGeneratingPreview(item)
      )
    );
  };
  

  // Dtp files Section - Columns passed to table
  const dtpFilesColumns: any = [
    {
      title: "Document",
      dataIndex: "document",
      key: "document",
      width: 300,
      filteredValue: filteredInfo.document || null,
      onFilter: (value: any, record: any) => record.document.includes(value),
      sorter: (a: any, b: any) => sorter(a.document, b.document),
      sortOrder: sortedInfo.columnKey === "document" && sortedInfo.order,
      ...getDocumentColumnSearchProps("document"),
      render: (text: any, record: any, index: number) => (
        <>
          {record.fileUri && record.stage !== "POSTPROCESSING_COMPLETE" ? (
              <a
                href={record.fileUri}
                target="_blank"
                rel="noopener noreferrer"
                title="Google Drive file"
              >
                <GoogleOutlined
                  style={{
                    marginRight: "3px",
                    transform: "translateY(1px)",
                    color: "#fbbc05",
                  }}
                />
                <Highlighter
                  highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                  searchWords={[documentSearchValue]}
                  autoEscape
                  textToHighlight={text ? text.toString() : ""}
                />
              </a>
          ) : (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[documentSearchValue]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          )}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      width: 100,
      editFileIdCol: hasGdriveFiles && visibleColumns ? "" : "editFileId",
      render: (text: any, record: any, index: number) => (
        <>
          {record.stage === "AWAITING_POST_PROCESSING" ||
            record.stage === "POST_PROCESSED_UPLOADED" ||
            record.stage === "FAILED_POST_PROCESSING" ? (
            <Tooltip
              title="Edit File ID"
              placement="left"
              color={"var(--tertiary-color)"}
            >
              <Button
                onClick={() => {
                  handleClickEdit(record);
                }}
                className="action-btn edit-btn"
                icon={<EditOutlined />}
                size="small"
                shape="circle"
                style={{
                  color: "var(--tertiary-color)",
                  left: "30px",
                  border: "none",
                  position: "relative",
                }}
              ></Button>
              <EditFileIdForm
                visible={editing.isEditing}
                onSubmit={handleClickFinishEditing}
                selectedGDoc={editing.editingRow}
              />
            </Tooltip>
          ) : (
            <EditOutlined
              disabled
              style={{
                marginLeft: "10px",
                color: "#fff",
              }}
            />
          )}
        </>
      ),
    },
    {
      title: "File ID",
      dataIndex: "fileId",
      width: 110,
      fileIdCol: hasGdriveFiles && visibleColumns ? "" : "fileId",
      render: (text: any, record: any, index: any) => (
        <>
          {record.stage === "AWAITING_POST_PROCESSING" ||
            record.stage === "POST_PROCESSED_UPLOADED" ||
            record.stage === "FAILED_POST_PROCESSING" ? (
            <Tooltip
              title={`File ID : ${record.uploadedFileId ? record.uploadedFileId : ""
                }`}
              placement="bottom"
              color={"var(--tertiary-color)"}
            >
              <InfoCircleOutlined
                disabled
                style={{
                  color: "var(--tertiary-color)",
                }}
              />
            </Tooltip>
          ) : null}
        </>
      ),
    },
    {
      title: "Target Language",
      dataIndex: "targetLanguage",
      key: "targetLanguage",
      width: 150,
      filters: formatFilters(filterValues.targetLanguage),
      filteredValue: filteredInfo.targetLanguage || null,
      onFilter: (value: any, record: any) =>
        record.targetLanguage !== null && record.targetLanguage.includes(value),
      sorter: (a: any, b: any) => sorter(a.targetLanguage, b.targetLanguage),
      //sortOrder: sortedInfo.columnKey === "targetLanguage" && sortedInfo.order,
      sortOrder: tLanSortOrder,
      render: (text: any) => (text === null ? null : text.join(", ")),
    },
    {
      title: "Uploaded Document",
      dataIndex: "uploadedDocument",
      key: "uploadedDocument",
      width: 300,
      render: (uploadedDocuments: any, record: any) => (
        <ConfigProvider renderEmpty={() => []}>
          <List
            itemLayout="horizontal"
            split={false}
            size="small"
            dataSource={uploadedDocuments}
            renderItem={(item: any) => (
              <List.Item
                actions={[
                  (
                    <>{generatingPreview(item)}</>
                  ),
                  record.stage === "GENERATING_PREVIEW" ||
                    record.stage === "READY_FOR_PREVIEW" ||
                    record.stage === "FAILED_PRE_PROCESSING" ? (
                    <Popconfirm
                      onConfirm={() => handleDeleteUploadededDocument(record.id, item._id)}
                      okText="Yes"
                      cancelText="No"
                      okType="danger"
                      icon={<DeleteOutlined style={{ color: "var(--red)" }} />}
                      title={
                        <div>
                          <b>Do you want to delete</b>
                          <br />
                          {item.filename} ?
                        </div>
                      }
                      cancelButtonProps={{ type: "default" }}
                      okButtonProps={{ type: "primary" }}
                    >
                      <Button
                        danger
                        type="text"
                      >
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  ) : (
                    null
                  ),
                ]}
              >
                <List.Item.Meta title={item.filename} />
              </List.Item>
            )}
          />
        </ConfigProvider>
      ),
    },
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
      width: 200,
      filters: formatFilters(filterValues.stage),
      filteredValue: filteredInfo.stage || null,
      onFilter: (value: any, record: any) => record.stage.includes(value),
      sorter: (a: any, b: any) => sorter(a.stage, b.stage),
      sortOrder: sortedInfo.columnKey === "stage" && sortedInfo.order,
      render: (text: any, record: any) =>
        record.stage === "FAILED_PRE_PROCESSING" ||
          record.stage === "FAILED_POST_PROCESSING" ? (
          <Tooltip
            title={record.failedReason}
            placement="left"
            color={"var(--red)"}
          >
            <InfoCircleOutlined
              style={{ color: "var(--red)", marginRight: "3px" }}
            />
            {removeUnderscore(text)}
          </Tooltip>
        ) : ( processingDuration(record, text) )
      ,
    },
    {
      title: "Document Type",
      dataIndex: "fileType",
      key: "fileType",
      width: 200,
      filters: formatFilters(filterValues.fileType),
      filteredValue: filteredInfo.fileType || null,
      onFilter: (value: any, record: any) => record.fileType.includes(value),
      sorter: (a: any, b: any) => sorter(a.fileType, b.fileType),
      sortOrder: sortedInfo.columnKey === "fileType" && sortedInfo.order,
      render: (text: any) => (text === null ? "" : text),
    },
  ];

  return (
    <React.Fragment>
      {readyProjectType === "DTP_V2" && (
        <Collapse
          defaultActiveKey={["1"]}
          style={{ margin: "0 15px 20px 15px" }}
        >
          <Panel
            header={
              !editing.isEditing ? (
                "DTP Files"
              ) : (
                <>
                  DTP Files <Spin size="small" style={{ marginLeft: "10px" }} />
                </>
              )
            }
            key="1"
            className="dtp-files"
            extra={`Total: ${activeFilterSorter ? totalDtpProjects : dtpFilesData.length
              }`}
          >
            <div className="dtp-buttons" style={{ margin: "15px 16px" }}>
              {selectedDtpRows.length > 0 ? (
                <DownloadBtn {...downloadProps} />
              ) : (
                <Tooltip
                  title="Select at least 1 document"
                  placement="bottom"
                  color={"var(--tertiary-color)"}
                >
                  <Button
                    type="primary"
                    disabled
                    className="download-projects"
                    icon={<DownloadOutlined />}
                    style={{ marginRight: "20px" }}
                  >
                    Download
                  </Button>
                </Tooltip>
              )}
              {(documentSearchValue !== "" && documentSearchValue !== null) ||
                (filteredInfo !== "" &&
                  !Object.values(filteredInfo).every((x) => x === null)) ||
                sortedInfo.column !== undefined ? (
                <Button
                  danger
                  onClick={clearDtpFiltersSorter}
                  style={{ marginRight: "20px" }}
                >
                  Clear filters and sorting
                </Button>
              ) : (
                ""
              )}
            </div>
            <Divider style={{ backgroundColor: "var(--grey)", margin: "0" }} />
            <Table
              columns={dtpFilesColumns.filter(
                (col: any) =>
                  col.fileIdCol !== "fileId" &&
                  col.editFileIdCol !== "editFileId"
              )}
              dataSource={dtpFilesData}
              onChange={handleChangeDtpFiles}
              className={"dtp-files-table"}
              pagination={false}
              rowSelection={{
                ...rowSelectionDtpFiles,
              }}
              rowKey={(record) => record.key}
            />
          </Panel>
        </Collapse>
      )}
    </React.Fragment>
  );
};

export default DtpFilesSection;
