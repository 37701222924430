import React from "react";

import { Button, Drawer, Form, Input, Switch } from "antd";

const EditWorkflowType = ({ handlers, values }) => {
  const { closeEditDrawer, onFinishEditing } = handlers;
  const { editingForm, visibleEditDrawer, editing, editFormRef } = values;
  const editingRow = editing && editing.editingRow;

  return <Drawer
        title="Edit Workflow Type"
        placement="right"
        onClose={closeEditDrawer}
        open={visibleEditDrawer}
        width={600}
        closable={true}
        className="manage-pages-drawer"
        forceRender
      >
        {editingRow && (
          <Form
            form={editingForm}
            layout="horizontal"
            onFinish={onFinishEditing}
            name="editWorkflowTypeForm"
            ref={editFormRef}
          >
            <Form.Item
              name="titleValue"
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Please enter the title",
                },
              ]}
            >
              <Input placeholder="Title" />
            </Form.Item>

            <Form.Item
              name="descriptionValue"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Please enter the description",
                },
              ]}
            >
              <Input.TextArea placeholder="description" />
            </Form.Item>

            <Form.Item
              label="Active"
              name="isActiveValue"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Workflow Type
              </Button>
            </Form.Item>
          </Form>
        )}
      </Drawer>
};

export default EditWorkflowType;
