import { useState } from "react";
import { Modal } from "antd";
import {
  failedNotification,
  successfulNotification,
} from "../../utils/notifications";

const CreateRequest = ({
  reset,
  userContext,
  selectedRows,
}: {
  reset: any;
  userContext: any;
  selectedRows: any;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleOk = async () => {
    const data = {
      ...selectedRows?.[0],
    };
    setLoading(true);
    try {
      const res = await userContext.gpClient.post(
        "/google-gp-v1/sesameAccounts/newRequest",
        data
      );

      successfulNotification(
        "Success",
        `Sesame Id ${res?.data?.primaryEmail} was created`
      );
      setIsModalVisible(false);
    } catch (error: any) {
      failedNotification(
        "Error",
        `${
          error?.response?.data?.details?.split("\n")?.[0] ||
          error?.response?.data?.message
        }`
      );
    } finally {
      setLoading(false);
      reset();
    }
  };

  const handleCancel = () => {
    reset();
    setIsModalVisible(false);
  };

  return (
    <Modal
      title="Are you sure you want to create this request?"
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ loading, disabled: loading }}
      cancelButtonProps={{ style: { color: "var(--secondary-color)" } }}
      maskClosable={false}
    >
      <p>Once confirmed, a new request will be submitted.</p>
    </Modal>
  );
};

export default CreateRequest;
