import React, { useState, FunctionComponent } from "react";
import { InputNumber, Radio, Button, Popover } from "antd";

import "./numberFilter.scss";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { DownOutlined } from "@ant-design/icons";

type NumberInput = number | string | null | undefined;

export interface NumberFilterProps {
  /**
   * Optional dropdown text, default is Filter
   */
  name?: string;
  /**
   * Mandatory function to return the result.
   */
  onChange: (params: any) => any;
  value: any;
  title?: string;
  placeholder: string;
}

interface IResult {
  selection?: string;
  value?: number[] | number | null | undefined;
}

export const NumberFilter: FunctionComponent<NumberFilterProps> = ({
  name,
  title,
  value,
  placeholder = "Select",
  onChange,
}) => {
  /**
   * Radio selection options:
   * 1 = less, 2 = greater, 3 = between
   */
  const [radioValue, setRadioValue] = useState(1),
    [lessValue, setLessValue] = useState<any>(null),
    [greaterValue, setGreaterValue] = useState<any>(null),
    [betweenMinValue, setBetweenMinValue] = useState<any>(null),
    [betweenMaxValue, setBetweenMaxValue] = useState<any>(null),
    [open, setOpen] = useState<boolean>(false);

  const handleChangeRadio = (e: RadioChangeEvent) => {
    const newValue = e.target.value;

    setRadioValue(newValue);

    switch (newValue) {
      case 1:
        setGreaterValue(null);
        setBetweenMinValue(null);
        setBetweenMaxValue(null);
        break;
      case 2:
        setLessValue(null);
        setBetweenMinValue(null);
        setBetweenMaxValue(null);
        break;
      case 3:
        setLessValue(null);
        setGreaterValue(null);
        break;

      default:
        break;
    }
  };

  const handleChangeLess = (number: NumberInput) => {
    setLessValue(number);
  };

  const handleChangeGreater = (number: NumberInput) => {
    setGreaterValue(number);
  };

  const handleChangeBetweenMin = (number: NumberInput) => {
    setBetweenMinValue(number);
  };

  const handleChangeBetweenMax = (number: NumberInput) => {
    setBetweenMaxValue(number);
  };

  const handleClear = () => {
    setRadioValue(1);
    setLessValue(null);
    handleChangeGreater(null);
    setGreaterValue(null);
    setBetweenMinValue(null);
    setBetweenMaxValue(null);

    onChange({ target: { name, value: undefined } });
  };

  const handleSearch = () => {
    let result: IResult = { selection: undefined, value: undefined };

    switch (radioValue) {
      case 1:
        result = { selection: "less", value: lessValue };
        break;
      case 2:
        result = { selection: "greater", value: greaterValue };
        break;
      case 3:
        result = {
          ...result,
          selection: "between",
          value: [betweenMinValue, betweenMaxValue],
        };
        break;
      default:
        return;
    }

    setOpen(false);

    onChange({ target: { name, value: result } });
  };

  const handleVisibleChange = (e: boolean) => {
    // fix to close popover if the user will click anywhere in the page
    if (!e) {
      setOpen(false);
    }
    if (
      (Array.isArray(value?.value) && value?.value.length > 0) ||
      (value && typeof value.value === "number")
    ) {
      radioValue === 1 && setLessValue(value?.value);
      radioValue === 2 && setGreaterValue(value?.value);
      radioValue === 3 && setBetweenMinValue(value?.value[0]);
      radioValue === 3 && setBetweenMaxValue(value?.value[1]);
    } else {
      setLessValue(null);
      setGreaterValue(null);
      setBetweenMinValue(null);
      setBetweenMaxValue(null);
    }
  };

  const content = (
    <div className="numberFilter">
      <div className="top">
        <Radio.Group
          onChange={handleChangeRadio}
          value={radioValue}
          className="options-wrapper"
        >
          <div className="option option__1">
            <Radio value={1}>Less than</Radio>
            <InputNumber
              type="number"
              disabled={radioValue !== 1}
              className="less-input"
              value={lessValue}
              onChange={handleChangeLess}
              onPressEnter={handleSearch}
            />
          </div>
          <div className="option option__2">
            <Radio value={2}>Greater than</Radio>
            <InputNumber
              type="number"
              disabled={radioValue !== 2}
              className="greater-input"
              value={greaterValue}
              onChange={handleChangeGreater}
              onPressEnter={handleSearch}
            />
          </div>
          <div className="option option__3">
            <Radio value={3}>Between</Radio>
            <div className="between-inputs">
              <InputNumber
                type="number"
                placeholder="Min"
                disabled={radioValue !== 3}
                value={betweenMinValue}
                onChange={handleChangeBetweenMin}
              />
              <InputNumber
                type="number"
                placeholder="Max"
                disabled={radioValue !== 3}
                value={betweenMaxValue}
                onChange={handleChangeBetweenMax}
                onPressEnter={handleSearch}
              />
            </div>
          </div>
        </Radio.Group>
      </div>
      <div className="separator" />
      <div className="bottom">
        <Button onClick={handleClear} danger size="small">
          Clear
        </Button>
        <Button onClick={handleSearch} type="primary" size="small">
          Search
        </Button>
      </div>
    </div>
  );

  return (
    <div className="number-filter-wrap">
      <Popover
        placement="bottomLeft"
        content={content}
        trigger="click"
        open={open}
        onOpenChange={handleVisibleChange}
      >
        <label className="filter-label">{title}</label>
        <Button
          className="trigger-filter"
          onClick={() => setOpen((prev) => !prev)}
          size="small"
        >
          {(Array.isArray(value?.value) && value?.value.length > 0) ||
          (value && typeof value.value === "number")
            ? `${value.selection} ${value.value}`
            : placeholder}
          <DownOutlined />
        </Button>
      </Popover>
    </div>
  );
};
