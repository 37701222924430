import React, { useCallback, useEffect, useState } from "react";
import { useMercuryContext } from "./../user-context";
import axios from "axios";
import { Helmet } from "react-helmet";

import { Table, Row, Col, Card, Empty, Button, Typography } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  multiDragAwareReorder,
  multiSelectTo as multiSelect,
} from "../utils/utilsDragDrop";
import { API_URL } from "../utils/variables";
import LoadingStar from "../ui/LoadingStar";
import PageTitle from "../ui/PageTitle";

import {
  failedNotification,
  successfulNotification,
} from "../utils/notifications";

const { Paragraph } = Typography;

const PRIMARY_BUTTON_NUMBER = 0;

const ProductAssignment = () => {
    const { accessToken } = useMercuryContext();
  const [entities, setEntities] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [draggingProductId, setDraggingProductId] = useState(null);

  useEffect(() => {
    const fetchProductTypes = async (accessToken) => {
      const productsEndpoint = `${API_URL}/core-gpms-v1/product`;
      try {
        setIsLoading(true);
        let headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        const response = await axios
          .get(productsEndpoint, { headers })
          .catch((err) => console.error(err));

        const finalColumns = {
          sdl: {
            id: "sdl",
            productIds: [],
          },
          products: {
            id: "products",
            productIds: [],
          },
          moravia: {
            id: "moravia",
            productIds: [],
          },
        };
        // Will assign each item in the right table
        const sortedData = response.data.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
        sortedData.forEach((vendor) => {
          if (vendor.vendor === "sdl") {
            finalColumns.sdl.productIds.push(vendor._id);
          }
          if (vendor.vendor === "moravia") {
            finalColumns.moravia.productIds.push(vendor._id);
          }
          if (vendor.vendor === "products") {
            finalColumns.products.productIds.push(vendor._id);
          }
        });

        setEntities({
          productTypes: response.data,
          vendor: ["sdl", "products", "moravia"],
          columns: finalColumns,
        });
      } catch (error) {
        failedNotification("Error Fetching Api", error);
      }
      setIsLoading(false);
    };

    accessToken && fetchProductTypes(accessToken);
  }, [accessToken]);

  const tableColumns = [
    {
      dataIndex: "",
    },
    {
      title: "Product",
      dataIndex: "title",
      sortDirections: ["descend", "ascend"],
      key: "title",
    },
  ];

  /**
   * On window click
   */
  const onWindowClick = useCallback((e) => {
    if (e.defaultPrevented) {
      return;
    }

    setSelectedProductIds([]);
  }, []);

  /**
   * On window key down
   */
  const onWindowKeyDown = useCallback((e) => {
    if (e.defaultPrevented) {
      return;
    }

    if (e.key === "Escape") {
      setSelectedProductIds([]);
    }
  }, []);

  /**
   * On window touch end
   */
  const onWindowTouchEnd = useCallback((e) => {
    if (e.defaultPrevented) {
      return;
    }

    setSelectedProductIds([]);
  }, []);

  /**
   * Event Listener
   */
  useEffect(() => {
    window.addEventListener("click", onWindowClick);
    window.addEventListener("keydown", onWindowKeyDown);
    window.addEventListener("touchend", onWindowTouchEnd);

    return () => {
      window.removeEventListener("click", onWindowClick);
      window.removeEventListener("keydown", onWindowKeyDown);
      window.removeEventListener("touchend", onWindowTouchEnd);
    };
  }, [onWindowClick, onWindowKeyDown, onWindowTouchEnd]);

  /**
   * Droppable table body
   */
  const DroppableTableBody = ({ columnId, products, ...props }, index) => {
    return (
      <Droppable droppableId={columnId} key={columnId}>
        {(provided, snapshot) => [
          <tbody
            ref={provided.innerRef}
            key={columnId}
            {...props}
            {...provided.droppableProps}
            className={`${props.className} ${
              snapshot.isDraggingOver ? "is-dragging-over" : ""
            }`}
          />,
          provided.placeholder ? (
            <tbody key={"placeholder"}>{provided.placeholder}</tbody>
          ) : null,
        ]}
      </Droppable>
    );
  };

  /**
   * Draggable table row
   */
  const DraggableTableRow = ({
    index,
    record,
    columnId,
    products,
    ...props
  }) => {
    if (!products.length) {
      return (
        <tr className="ant-table-placeholder row-item" {...props}>
          <td colSpan={tableColumns.length} className="ant-table-cell">
            <div className="ant-empty ant-empty-normal">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          </td>
        </tr>
      );
    }

    const isSelected = selectedProductIds.some(
      (selectedProductId) => selectedProductId === record._id
    );

    const isGhosting =
      isSelected &&
      Boolean(draggingProductId) &&
      draggingProductId !== record._id;

    return (
      <Draggable
        key={props["data-row-key"]}
        draggableId={props["data-row-key"].toString()}
        index={index}
      >
        {(provided, snapshot) => [
          <tr
            ref={provided.innerRef}
            {...props}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`row-item ${isSelected ? "row-selected" : ""} ${
              isGhosting ? "row-ghosting" : ""
            } ${snapshot.isDragging ? "row-dragging" : ""}`}
            key={props["data-row-key"]}
          />,

          provided.placeholder ? (
            <tr>
              <td>{provided.placeholder}</td>
            </tr>
          ) : null,
        ]}
      </Draggable>
    );
  };

  /**
   * Get products
   */
  const getProducts = (entities, id) => {
    return entities.columns[id].productIds.map((productId) =>
      entities.productTypes.find((item) => item._id === productId)
    );
  };

  /**
   * On before capture
   */
  const onBeforeCapture = (start) => {
    const draggableId = start.draggableId;
    const selected = selectedProductIds.find(
      (productId) => productId === draggableId
    );

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      setSelectedProductIds([]);
    }

    setDraggingProductId(draggableId);
  };

  /**
   * On drag end
   */
  const onDragEnd = (result) => {
    const destination = result.destination;
    const source = result.source;

    // nothing to do
    if (!destination || result.reason === "CANCEL") {
      setDraggingProductId(null);
      return;
    }

    const processed = multiDragAwareReorder({
      entities,
      selectedProductIds,
      source,
      destination,
    });

    setEntities(processed.entities);
    setDraggingProductId(null);
  };

  /**
   * Toggle selection
   */
  const toggleSelection = (productId) => {
    const wasSelected = selectedProductIds.includes(productId);

    const newProductIds = (() => {
      // Product was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [productId];
      }

      // Product was part of a selected group
      // will now become the only selected item
      if (selectedProductIds.length > 1) {
        return [productId];
      }

      // Product was previously selected but not in a group
      // we will now clear the selection
      return [];
    })();

    setSelectedProductIds(newProductIds);
  };

  /**
   * Toggle selection in group
   */
  const toggleSelectionInGroup = (productId) => {
    const index = selectedProductIds.indexOf(productId);

    // if not selected - add it to the selected items
    if (index === -1) {
      setSelectedProductIds([...selectedProductIds, productId]);

      return;
    }

    // it was previously selected and now needs to be removed from the group
    const shallow = [...selectedProductIds];
    shallow.splice(index, 1);

    setSelectedProductIds(shallow);
  };

  /**
   * Multi select to
   * This behaviour matches the MacOSX finder selection
   */
  const multiSelectTo = (newProductId) => {
    const updated = multiSelect(entities, selectedProductIds, newProductId);

    if (updated == null) {
      return;
    }

    setSelectedProductIds(updated);
  };

  const onClickRow = (e, record) => {
    if (e.defaultPrevented) {
      return;
    }

    if (e.button !== PRIMARY_BUTTON_NUMBER) {
      return;
    }

    // marking the event as used
    e.preventDefault();
    performAction(e, record);
  };

  /**
   * On touch end from row
   */
  const onTouchEndRow = (e, record) => {
    if (e.defaultPrevented) {
      return;
    }

    // marking the event as used
    e.preventDefault();
    toggleSelectionInGroup(record._id);
  };

  /**
   * Was toggle in selection group key used
   * Determines if the platform specific toggle selection in group key was used
   */
  const wasToggleInSelectionGroupKeyUsed = (e) => {
    const isUsingWindows = navigator.platform.indexOf("Win") >= 0;
    return isUsingWindows ? e.ctrlKey : e.metaKey;
  };

  /**
   * Was multi select key used
   * Determines if the multiSelect key was used
   */
  const wasMultiSelectKeyUsed = (e) => e.shiftKey;

  /**
   * Perform action
   */
  const performAction = (e, record) => {
    if (wasToggleInSelectionGroupKeyUsed(e)) {
      toggleSelectionInGroup(record._id);
      return;
    }

    if (wasMultiSelectKeyUsed(e)) {
      multiSelectTo(record._id);
      return;
    }

    toggleSelection(record._id);
  };

  const saveAll = () => {
    let finalList = [];

    entities.vendor.map((id) => {
      return finalList.push({
        columnId: entities.columns[id].id,
        products: getProducts(entities, id),
      });
    });

    const sdlVendorTitle = finalList[0].columnId;
    const sdlVendorList = finalList[0].products;

    const productsVendorList = finalList[1].products;

    const moraviaVendorTitle = finalList[2].columnId;
    const moraviaVendorList = finalList[2].products;

    const updateVendor = async (tokenValue, newVendor, id) => {
      let headersConfig = {
        Authorization: `Bearer ${tokenValue}`,
      };
      const body = {
        vendor: newVendor,
      };
      const endpointUrl = `${API_URL}/core-gpms-v1/product/${id}`;

      return await axios({
        method: "patch",
        url: endpointUrl,
        headers: headersConfig,
        data: body,
      });
    };

    // check if available products is empty
    if (productsVendorList.length !== 0) {
      failedNotification(
        "Error",
        <div>
          <b>There are still products available to be assigned.</b>
          <br />
          Please assign all the products and save again.
        </div>
      );
    } else {
      try {
        // call the function each time for all the items from sdl table
        sdlVendorList.forEach((product) => {
          updateVendor(
            accessToken,
            sdlVendorTitle.toLowerCase(),
            product._id
          ).catch((error) => {
            console.error(error);
            failedNotification(
              "The following vendor wasn't updated.",
              <div>
                <p style={{ marginBottom: "0" }}>
                  <strong>Title: </strong>
                  <span style={{ textTransform: "capitalize" }}>
                    {product.title}
                  </span>
                </p>

                <p style={{ marginBottom: "0" }}>
                  <strong>Vendor: </strong>
                  <span style={{ textTransform: "capitalize" }}>
                    {product.vendor}
                  </span>
                </p>
              </div>
            );
          });
        });

        // call the function each time for all the items from moravia table
        moraviaVendorList.forEach((product) => {
          updateVendor(
            accessToken,
            moraviaVendorTitle.toLowerCase(),
            product._id
          ).catch((error) => {
            console.error(error);
            failedNotification(
              "The following vendor wasn't updated.",
              <div>
                <p style={{ marginBottom: "0" }}>
                  <strong>Title: </strong>
                  <span style={{ textTransform: "capitalize" }}>
                    {product.title}
                  </span>
                </p>

                <p style={{ marginBottom: "0" }}>
                  <strong>Vendor: </strong>
                  <span style={{ textTransform: "capitalize" }}>
                    {product.vendor}
                  </span>
                </p>
              </div>
            );
          });
        });
      } catch (error) {
        console.error(error);
      } finally {
        successfulNotification(
          "Changes Processed",
          "All the vendor changes have been processed."
        );
      }
    }
  };

  return isLoading ? (
    <LoadingStar />
  ) : (
    <div className="product-assignment" style={{padding: '0 10px'}}>
      <Helmet>
        <title>Product Assignment - Mercury © RWS</title>
      </Helmet>
      <PageTitle title="Product Assignment" />
      <Card
        bordered={false}
        bodyStyle={{ overflow: "hidden", paddingTop: "30px" }}
        className={`c-multi-drag-table ${
          draggingProductId ? "is-dragging" : ""
        }`}
      >
        <DragDropContext
          onBeforeCapture={onBeforeCapture}
          onDragEnd={onDragEnd}
        >
          <Row gutter={40} justify="center" align="top">
            {entities.vendor.map((id, index) => (
              <Col key={index} xs={5}>
                <div className="inner-col">
                  <Row justify="center" align="middle">
                    <h2
                      style={{ textTransform: "capitalize", marginBottom: "0" }}
                    >
                      {id === "products"
                        ? "Available Products"
                        : id === "sdl"
                        ? "fSDL (015)"
                        : "fMoravia (004)"}
                    </h2>
                  </Row>
                  <Row
                    justify="center"
                    align="middle"
                    style={{ minHeight: "30px", paddingBottom: "10x" }}
                  >
                    {draggingProductId && selectedProductIds.length > 0
                      ? selectedProductIds.length + " records are being dragged"
                      : draggingProductId && selectedProductIds.length <= 0
                      ? "1 record is being dragged"
                      : ""}
                  </Row>
                  <Table
                    dataSource={getProducts(entities, id)}
                    columns={tableColumns}
                    rowKey={(record) => record._id}
                    pagination={false}
                    components={{
                      body: {
                        // Custom tbody
                        wrapper: (val) =>
                          DroppableTableBody({
                            columnId: entities.columns[id].id,
                            products: getProducts(entities, id),
                            ...val,
                          }),
                        // Custom td
                        row: (val) =>
                          DraggableTableRow({
                            products: getProducts(entities, id),
                            ...val,
                          }),
                      },
                    }}
                    // Set props on per row (td)
                    onRow={(record, index) => ({
                      index,
                      record,
                      onClick: (e) => onClickRow(e, record),
                      onTouchEnd: (e) => onTouchEndRow(e, record),
                    })}
                  />
                </div>
              </Col>
            ))}
          </Row>

          <Row
            justify="center"
            align="middle"
            style={{ paddingTop: "20px", paddingBottom: "10px" }}
          >
            <Button type="primary" onClick={saveAll} icon={<SaveOutlined />}>
              Save All
            </Button>
          </Row>
          <Row justify="center" align="middle">
            <Paragraph>
              <i>
                Drag a product to the vendor to assign it. Multi select:
                Ctrl/Shift + Left Click
              </i>
            </Paragraph>
          </Row>
        </DragDropContext>
      </Card>
    </div>
  );
};

export default ProductAssignment;
