import { useState, useEffect } from "react";
import { Popover, Button, Badge } from "antd";
import { useMercuryContext } from "../../user-context";
import { Progress } from "../../Components";

const ProgressLoader = () => {

  const { progress } = useMercuryContext();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(true);

  const bars: any = progress ? Array.from(progress.values()) : null;
  const completed = bars?.filter((bar: any) => bar.item.progress !== 1);

  const total = completed?.reduce((accumulator: any, currentValue: any) => {
    return accumulator + currentValue.item.progress
  }, 0)


  useEffect(() => {

    if (((total * 100) / completed?.length) === 100 || completed?.length ===0 ) {
      setTimeout(function () {
        setShow(false)
      }, 8000);
    } else {
      setShow(true)
    }

  }, [show, total]);

  if (!progress) {
    return null;
  }

  const handlePopoverVisibleChange = () => {
      setOpen((prev) => !prev);
  };

  const content = bars?.map((bar: any) => {
    const errorIds = bar.item.errors?.map((p: any) => p.id);
    const errorsCount = bar.item.errors?.map((p: any) => p.id).length;
    let toggle = bar.item.progress === 1 ? false : true;

    return (
      <>
        <span key={bar.requestId}>
          <div
            className={
              toggle
                ? 'show-bar'
                : 'hide-bar'
            }
          >
            <span className={"progress-title"}>
              {bar.item.progress === 1
                ? `${bar.item.total - errorsCount} of ${
                    bar.item.total
                  } successful`
                : bar.item.name}
            </span>
            {bar.item.errors.length !== 0 && bar.item.progress === 1 ? (
              <div style={{ color: "var(--red)", maxWidth: "260px" }}>
                {`${errorsCount} of ${bar.item.total} failed`}
                <br />
                <div style={{ overflowWrap: "break-word" }}>
                  {errorIds && `id(s) : ${errorIds?.join(", ")} `}
                </div>
              </div>
            ) : (
              ""
            )}
            <Progress
              status={"active"}
              progress={Math.ceil(bar.item.progress * 100)}
              showInfo={true}
              strokeColor={{ "0%": "#C4FFFC", "100%": "var(--primary-color)" }}
              trailColor="#eee"
              type={"line"}
              size={"small"}
            />
          </div>
        </span>
      </>
    );
  });

  return (
    <>
      {show &&
        <Popover
          placement="bottomLeft"
          content={content}
          trigger="click"
          open={open}
        >
          <Button onClick={handlePopoverVisibleChange} type="link" style={{ all: 'unset', height: '100%' }}>
          <Badge
            count={completed?.length}
            style={{ backgroundColor: "var(--tertiary-color)", top: "3px", right: "2px" }}
            size="small"
          >
            <Progress
              status={"active"}
              progress={completed?.length !==0 ? (total * 100) / completed?.length : 100}
              type={"circle"}
              size="small"
              strokeColor={{
                "0%": "#C4FFFC",
                "100%": "var(--primary-color)",
              }}
              trailColor="#eee"
            />
            </Badge>
          </Button>
        </Popover>}
    </>
  );
};

export default ProgressLoader;
