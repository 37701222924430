import React, { useCallback, useEffect, useState, useRef } from "react";
import Total from "../../../ui/Total";

import {
  Table,
  Typography,
  Skeleton,
  Input,
  Spin,
  Row,
  Tag,
  Empty,
} from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  UpCircleOutlined,
  DownCircleOutlined
} from "@ant-design/icons";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { liftAndMove } from "../../../utils/utilsDragDrop";

const PRIMARY_BUTTON_NUMBER = 0;

const HelixTemplateMapTable = ({ values, handlers }) => {
  const { helixTemplateMaps, helixTemplates, editing, total } =
    values;
  const {
    handleChangeTable,
    handleClickEdit,
    handleClickDelete,
    batchUpdateTemplateMaps,
    handleRefresh,
  } = handlers;

  const { Link } = Typography;
  const { Search } = Input;

  const initialData =
    helixTemplateMaps &&
    helixTemplateMaps.map((item, index) => {
      return {
        key: index,
        id: item.id,
        services: item.services,
        products: item.products,
        components: item.components,
        helixTemplateId: item.helixTemplateId,
        helixTemplateObj: helixTemplates.find(
          (template) => template._id === item.helixTemplateId
        ),
        order: item.order,
      };
    });

  const [filterTable, setFilterTable] = useState(null);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [draggingProductId, setDraggingProductId] = useState(null);

  const [data, setData] = useState(initialData);

  const [orderResult, setOrderResult] = useState();

  const sensorAPIRef = useRef(null);
  const [isControlDragging, setIsControlDragging] = useState(false);

  const tableColumns = [
    {
      title: "",
      dataIndex: "order",
      width: 70,
      defaultSortOrder: "descend",
      render: () => null,
    },
    {
      title: "Services",
      dataIndex: "services",
      render: (text, record) =>
        editing.isEditing ? (
          record.services === editing.editingRow.services ? (
            <>
              {text.map((service) => {
                return <Tag key={service}>{service}</Tag>;
              })}
            </>
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : text.length !== 0 ? (
          <>
            {text.map((service) => {
              return <Tag key={service}>{service}</Tag>;
            })}
          </>
        ) : (
          "(Any Service)"
        ),
    },
    {
      title: "Products",
      dataIndex: "products",
      render: (text, record) =>
        editing.isEditing ? (
          record.products === editing.editingRow.products ? (
            <>
              {text.map((product) => {
                return <Tag key={product}>{product}</Tag>;
              })}
            </>
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : text.length !== 0 ? (
          <>
            {text.map((product) => {
              return <Tag key={product}>{product}</Tag>;
            })}
          </>
        ) : (
          "(Any Product)"
        ),
    },
    {
      title: "Components",
      dataIndex: "components",
      render: (text, record) =>
        editing.isEditing ? (
          record.components === editing.editingRow.components ? (
            <>
              {text.map((component) => {
                return <Tag key={component}>{component}</Tag>;
              })}
            </>
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : text.length !== 0 ? (
          <>
            {text.map((component) => {
              return <Tag key={component}>{component}</Tag>;
            })}
          </>
        ) : (
          "(Any Component)"
        ),
    },
    {
      title: "Helix Template",
      dataIndex: "helixTemplateObj",
      render: (text, record) =>
        editing.isEditing ? (
          record.helixTemplateId === editing.editingRow.helixTemplateId ? (
            text?.name
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text?.name
        ),
    },
    {
      title: "Move Up",
      dataIndex: "up",
      width: 120,
      render: (text, record) =>
        editing.isEditing && record.name !== editing.editingRow.name ? (
          <Skeleton active size="small" title={true} paragraph={false} />
        ) : (
          <Link
            onClick={() =>
              liftAndMove(record.id, sensorAPIRef, "UP", setIsControlDragging)
            }
            disabled={isControlDragging}
            label="up"
          >
            <UpCircleOutlined style={{ fontSize: '150%'}} />
          </Link>
        ),
    },
    {
      title: "Move Down",
      dataIndex: "down",
      width: 120,
      render: (text, record) =>
        editing.isEditing && record.name !== editing.editingRow.name ? (
          <Skeleton active size="small" title={true} paragraph={false} />
        ) : (
          <Link
            onClick={() =>
              liftAndMove(record.id, sensorAPIRef, "DOWN", setIsControlDragging)
            }
            disabled={isControlDragging}
            label="down"
          >
            <DownCircleOutlined style={{ fontSize: '150%'}} />
          </Link>
        ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      width: 100,
      render: (text, record) =>
        editing.isEditing && record.name !== editing.editingRow.name ? (
          <Skeleton active size="small" title={true} paragraph={false} />
        ) : (
          <Link
            onClick={() => {
              handleClickEdit(record);
            }}
            style={{
              marginRight: "10px",
              padding: "10px 0px 10px 0px",
            }}
          >
            <EditOutlined /> Edit
          </Link>
        ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      width: 100,
      render: (text, record) =>
        editing.isEditing && record.name !== editing.editingRow.name ? (
          <Skeleton active size="small" title={true} paragraph={false} />
        ) : (
          <Link
            onClick={() => {
              handleClickDelete(record);
            }}
            style={{
              marginRight: "10px",
              padding: "10px 0px 10px 0px",
            }}
          >
            <DeleteOutlined /> Delete
          </Link>
        ),
    },
  ];

  /**
   * On window click
   */
  const onWindowClick = useCallback((e) => {
    if (e.defaultPrevented) {
      return;
    }

    setSelectedProductIds([]);
  }, []);

  /**
   * On window key down
   */
  const onWindowKeyDown = useCallback((e) => {
    if (e.defaultPrevented) {
      return;
    }

    if (e.key === "Escape") {
      setSelectedProductIds([]);
    }
  }, []);

  /**
   * On window touch end
   */
  const onWindowTouchEnd = useCallback((e) => {
    if (e.defaultPrevented) {
      return;
    }

    setSelectedProductIds([]);
  }, []);

  /**
   * Event Listener
   */
  useEffect(() => {
    window.addEventListener("click", onWindowClick);
    window.addEventListener("keydown", onWindowKeyDown);
    window.addEventListener("touchend", onWindowTouchEnd);

    return () => {
      window.removeEventListener("click", onWindowClick);
      window.removeEventListener("keydown", onWindowKeyDown);
      window.removeEventListener("touchend", onWindowTouchEnd);
    };
  }, [onWindowClick, onWindowKeyDown, onWindowTouchEnd]);

  /**
   * Draggable table row
   */
  const DraggableTableRow = ({ index, record, id, ...props }) => {
    if (!data.length) {
      return (
        <tr className="ant-table-placeholder row-item" {...props}>
          <td colSpan={tableColumns.length} className="ant-table-cell">
            <div className="ant-empty ant-empty-normal">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          </td>
        </tr>
      );
    }

    const isSelected = selectedProductIds.some(
      (selectedProductId) => selectedProductId === record.id
    );

    const isGhosting =
      isSelected &&
      Boolean(draggingProductId) &&
      draggingProductId !== record.id;

    return (
      <Draggable
        key={record.id}
        draggableId={record.id.toString()}
        index={index}
      >
        {(provided, snapshot) => [
          <tr
            ref={provided.innerRef}
            {...props}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`row-item ${isSelected ? "row-selected" : ""} ${
              isGhosting ? "row-ghosting" : ""
            } ${snapshot.isDragging ? "row-dragging" : ""}`}
            key={record.id}
          />,
          provided.placeholder ? (
            <tr>
              <td>{provided.placeholder}</td>
            </tr>
          ) : null,
        ]}
      </Draggable>
    );
  };

  /**
   * On before capture
   */
  const onBeforeCapture = (start) => {
    setIsControlDragging(true);
    const draggableId = start.draggableId;
    const selected = selectedProductIds.find(
      (productId) => productId === draggableId
    );

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      setSelectedProductIds([]);
    }

    setDraggingProductId(draggableId);
  };

  const onDragEnd = (result) => {
    setIsControlDragging(false);
    const destination = result.destination;

    // nothing to do
    if (!destination || result.reason === "CANCEL") {
      setDraggingProductId(null);
      return;
    }

    const oldIndex = result.source.index;
    const source = data[oldIndex];
    const newIndex = result.destination.index;
    let newData = data.concat([]);
    newData.splice(oldIndex, 1);
    newData.splice(newIndex, 0, source);

    setData(
      newData.map((row, index) => {
        return {
          id: row?.id,
          services: row?.services,
          products: row?.products,
          components: row?.components,
          helixTemplateId: row?.helixTemplateId,
          helixTemplateObj: row?.helixTemplateObj,
          order: index,
        };
      })
    );

    setDraggingProductId(null);
    setOrderResult(result);
  };

  /**
   * Toggle selection
   */
  const toggleSelection = (productId) => {
    const wasSelected = selectedProductIds.includes(productId);

    const newProductIds = (() => {
      // Product was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [productId];
      }

      // Product was part of a selected group
      // will now become the only selected item
      if (selectedProductIds.length > 1) {
        return [productId];
      }

      // Product was previously selected but not in a group
      // we will now clear the selection
      return [];
    })();

    setSelectedProductIds(newProductIds);
  };

  /**
   * Toggle selection in group
   */
  const toggleSelectionInGroup = (productId) => {
    const index = selectedProductIds.indexOf(productId);

    // if not selected - add it to the selected items
    if (index === -1) {
      setSelectedProductIds([...selectedProductIds, productId]);

      return;
    }

    // it was previously selected and now needs to be removed from the group
    const shallow = [...selectedProductIds];
    shallow.splice(index, 1);

    setSelectedProductIds(shallow);
  };

  const onClickRow = (e, record) => {
    if (e.defaultPrevented) {
      return;
    }

    if (e.button !== PRIMARY_BUTTON_NUMBER) {
      return;
    }

    // marking the event as used
    e.preventDefault();
    performAction(e, record);
  };

  /**
   * On touch end from row
   */
  const onTouchEndRow = (e, record) => {
    if (e.defaultPrevented) {
      return;
    }

    // marking the event as used
    e.preventDefault();
    toggleSelectionInGroup(record.id);
  };

  /**
   * Was toggle in selection group key used
   * Determines if the platform specific toggle selection in group key was used
   */
  const wasToggleInSelectionGroupKeyUsed = (e) => {
    const isUsingWindows = navigator.platform.indexOf("Win") >= 0;
    return isUsingWindows ? e.ctrlKey : e.metaKey;
  };

  /**
   * Perform action
   */
  const performAction = (e, record) => {
    if (wasToggleInSelectionGroupKeyUsed(e)) {
      toggleSelectionInGroup(record.id);
      return;
    }

    toggleSelection(record.id);
  };

  /**
   * Handle search
   */
  const handleSearch = (value) => {
    const filterTable = data.filter((item) =>
      item.helixTemplateObj?.name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
    );
    if (filterTable.length !== 0) {
      setFilterTable(filterTable);
    }
    if (data.length === filterTable.length) {
      handleRefresh();
    }
  };

  if (orderResult) {
    batchUpdateTemplateMaps(data);
  }

  return (
    <>
      <Search
        style={{ width: "300px", margin: "0 0 10px 0" }}
        placeholder="Search by Helix template"
        onSearch={handleSearch}
        allowClear
        enterButton
      />
      <Row
        className={`c-multi-drag-table ${
          draggingProductId ? "is-dragging" : ""
        }`}
      >
        <Row className="title-wrap">
          <span style={{ margin: "5px 0 0" }}>
            {editing.isEditing || editing.isDeleting ? (
              <Spin size="small" style={{ marginLeft: "10px" }} />
            ) : null}
          </span>
          <Total number={total} text="Helix Template Mappings" />
        </Row>
        <Row
          justify="center"
          align="middle"
          style={{ minHeight: "30px", paddingBottom: "10x" }}
        >
          {draggingProductId && selectedProductIds.length > 0
            ? selectedProductIds.length + " records are being dragged"
            : draggingProductId && selectedProductIds.length <= 0
            ? "1 record is being dragged"
            : ""}
        </Row>
        {/* TODO: The following errors occur only in dev: 
        Droppable setup issue [droppableId: "droppable"]:DroppableProvided > placeholder could not be found. 
        You are attempting to add or remove a Draggable [id: ] while a drag is occurring. This is only supported for virtual lists.*/}
        <DragDropContext
          onBeforeCapture={onBeforeCapture}
          onDragEnd={onDragEnd}
          sensors={[
            (api) => {
              sensorAPIRef.current = api;
            },
          ]}
        >
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps} style={{width: '100%'}}>
                <Table
                  columns={tableColumns}
                  dataSource={filterTable == null ? data : filterTable}
                  size="small"
                  className="manage-page-table"
                  style={{ width: "100%" }}
                  rowKey="id"
                  components={{
                    body: {
                      // Custom td
                      row: (val) =>
                        DraggableTableRow({
                          data: data,
                          ...val,
                        }),
                    },
                  }}
                  // Set props on per row (td)
                  onRow={(record, index) => ({
                    index,
                    record,
                    onClick: (e) => onClickRow(e, record),
                    onTouchEnd: (e) => onTouchEndRow(e, record),
                  })}
                  onChange={handleChangeTable}
                  pagination={{
                    position: ["bottomCenter"],
                    defaultPageSize: 20,
                  }}
                />
                {/* The placeholder causes warnings in development */}
                {/* {provided.placeholder} */}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Row>
    </>
  );
};

export default HelixTemplateMapTable;
