import React from "react";
import {
    FiniteListFilter, ListProps
} from "../Components";
import {useMercuryContext} from "../user-context";

export default (props:ListProps) => {
    const context = useMercuryContext();
    return <FiniteListFilter data={
        [
            {
                key: "percentage",
                title: "Percentage",
                value: "percentage"
            },
            {
                key: "fixed",
                title: "Fixed",
                value: "fixed"
            },
        ]
    } {...props}/>
}
