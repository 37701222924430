import React, { useState } from "react";
import { Modal, Form, Input } from "antd";

const EditDescriptionForm = ({
  visible,
  selectedDescription,
  onSubmit,
}) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);

  let initialValues = selectedDescription;

  return (
    <Modal
      // maskStyle={{
      //   backgroundColor: "rgba(0, 0, 0, 0.20)",
      // }}
      open={visible}
      title={`${initialValues?.name}`}
      okText="OK"
      cancelText="Cancel"
      onCancel={async () => {
        await onSubmit(false);
      }}
      onOk={async () => {
        try {
          let values = await form.validateFields();
          setConfirmLoading(true);
          onSubmit(true, {
            ...initialValues,
            reportDescription: values?.reportDescription,
          });
          setConfirmLoading(false);
        } catch (error) {
          console.error("Validate Failed:", error);
        }
      }}
      confirmLoading={confirmLoading}
    >
      <Form
        form={form}
        layout="vertical"
        name="editDescriptionForm"
        initialValues={{
          modifier: "public",
          reportDescription: initialValues?.parameters?.reportDescription,
        }}
      >
        <Form.Item
          name="reportDescription"
          label="Description"
          rules={[{ required: false }]}
        >
          <Input.TextArea placeholder="Enter Description" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditDescriptionForm;
