const getTokenApp = async (config) => {
    const body = config.BODY_AUTH;
    const response = await fetch(config.AUTH, { method: "POST", body });
    const result = await response.json();
    return result.access_token;
  };

const token = {
    getTokenApp,
};

export default token;