import React from "react";
import {SearchOutlined} from "@ant-design/icons";
import {Button} from "antd";

export default function EmptyOverlay(props:any) {

    if ( props.loading ) {
        return null;
    }

    return <div className={"table-empty"}>
        <SearchOutlined />
        <p>No results</p>
        {
            props.reset && <Button onClick={props.reset}>Clear filters</Button>
        }
    </div>
}
