import React, { FunctionComponent } from "react";
import "./customTooltip.scss";

interface TooltipProps {
  text: string;
  length?: number; // min string length to display the tooltip - 20 default
}

export const CustomTooltip: FunctionComponent<TooltipProps> = ({
  text,
  length = 20,
}) => {
  return (
    <div className="custom-tooltip" title={text?.length >= length ? text : ""}>
      {text}
    </div>
  );
};
