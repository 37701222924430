import React, { useState } from "react";
import Total from "../../../ui/Total";

import { Table, Typography, Skeleton, Input, Spin, Row } from "antd";

import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const HelixTemplatesTable = ({ values, handlers }) => {
  const { helixTemplates, sortedInfo, editing, total } = values;
  const { handleChangeTable, handleClickEdit, handleClickDelete } = handlers;

  const { Link } = Typography;
  const { Search } = Input;

  const [filterTable, setFilterTable] = useState(null);

  const data =
    helixTemplates &&
    helixTemplates.map((item, index) => {
      return {
        key: index,
        id: item.id,
        helixId: item.helixId,
        name: item.name,
        description: item.description,
      };
    });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => {
        a = a.name === null ? "" : a.name;
        b = b.name === null ? "" : b.name;
        return a.localeCompare(b);
      },
      sortOrder: sortedInfo.field === "name" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.name === editing.editingRow.name ? (
            text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text
        ),
    },
    {
      title: "Helix Id",
      dataIndex: "helixId",
      sorter: (a, b) => a.helixId - b.helixId,
      sortOrder: sortedInfo.field === "helixId" && sortedInfo.order,
      render: (text, record) =>
        editing.isEditing ? (
          record.name === editing.editingRow.name ? (
            text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text
        ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text, record) =>
        editing.isEditing ? (
          record.name === editing.editingRow.name ? (
            text
          ) : (
            <Skeleton active size="small" title={true} paragraph={false} />
          )
        ) : (
          text
        ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      width: 100,
      render: (text, record) =>
        editing.isEditing && record.name !== editing.editingRow.name ? (
          <Skeleton active size="small" title={true} paragraph={false} />
        ) : (
          <Link
            onClick={() => {
              handleClickEdit(record);
            }}
            style={{
              marginRight: "10px",
            }}
          >
            <EditOutlined /> Edit
          </Link>
        ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      width: 100,
      render: (text, record) =>
        editing.isDeleting && record.name !== editing.editingRow.name ? (
          <Skeleton active size="small" title={true} paragraph={false} />
        ) : (
          <Link
            onClick={() => {
              handleClickDelete(record);
            }}
            style={{
              marginRight: "10px",
            }}
          >
            <DeleteOutlined /> Delete
          </Link>
        ),
    },
  ];

  const handleSearch = (value) => {
    const filterTable = data.filter((item) =>
      item.name.toString().toLowerCase().includes(value.toLowerCase())
    );

    setFilterTable(filterTable);
  };

  return (
    <>
      <Search
        style={{ width: "300px", margin: "0 0 10px 0" }}
        placeholder="Search by name"
        onSearch={handleSearch}
        allowClear
        enterButton
      />
      <Row className="title-wrap">
        <span style={{ margin: "5px 0 0" }}>
          {editing.isEditing || editing.isDeleting ? (
            <Spin size="small" style={{ marginLeft: "10px" }} />
          ) : null}
        </span>
        <Total number={total} text="Helix Templates" />
      </Row>
      <Table
        columns={columns}
        dataSource={filterTable == null ? data : filterTable}
        size="small"
        className="manage-page-table"
        style={{ width: "100%"}}
        onChange={handleChangeTable}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: 20,
        }}
      />
    </>
  );
};

export default HelixTemplatesTable;
