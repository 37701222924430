// RequestExtension

export const CANCEL_BUTTON = 'Cancel';
export const ADD_BUTTON = 'Add';
export const MAKE_REQUEST = 'Make an extension request';
export const SELECT_MESSAGE = 'Please select an option';
export const SELECT_DUE_DATE = 'Please insert a due date';
export const TEXTAREA_MESSAGE = 'Please insert a justification';

// ListExtension + subcomponents

export const FILTERS = 'Filters';
export const FILTER_DUE_DATE = 'Requested Due date (to-from)';
export const FILTER_REQUEST_ID = 'Request ID';
export const FILTER_JUSTIFICATION = 'Justification';
export const FILTER_SHORT_ID = 'Project ID';
export const FILTER_STATUS = 'Status';
export const FILTER_PRODUCT = 'Product';
export const FILTER_PRODUCT_AREA = 'Product Area';
export const FILTER_CREATED_DATE = 'Created date (to-from)';
export const FILTER_BULK = 'Bulk request';
