import {FunctionComponent} from "react";
import {
    Grid
} from "antd";
import {UserContext} from "../../user-context";
import "./Table.abstract.scss"
import TableBaseAbstract from "./TableBase";
import {ColumnType} from "antd/es/table/interface";

export interface TableProps {
    id?: string;
    sorter?: boolean;
    dataIndex?: string;
    offsetHeader?: number;
    context: UserContext;
    useLegacyEndpoints: boolean;
    breaks: typeof Grid.useBreakpoint;
    displayHideFilters?: boolean;
    noSelect?:boolean;
    useEstimatedRowHeight?: boolean;
}

export type TableFilters = Record<string, any>;

export interface SavedFilter {
    _id: string;
    creator: string;
    sharedWith?: string[];
    filterData: any;
    name: string;
    key: string;
}

export interface TableState<R> {
    breaks: typeof Grid.useBreakpoint;
    initialised: boolean;
    loading: boolean;
    rowData: R[];
    filters: TableFilters;
    showFilters: boolean;
    selectedRows: R[];
    currentPage: number;
    itemsPerPage: number;
    disabledFilters: Set<keyof TableFilters>;
    sortField?: string;
    sortOrder: "ascend" | "descend";
    error?: Error;
    tempColumnOrder: string[];
    columnOrder: string[];
    hiddenColumns: string[];
    pinnedColumns: string[];
    columnsWidth: ColsWidth[];
    showManageColumnsModal: boolean;
    disabledColumns: string[];
    showExportModal: boolean;
    exportFilename: string;
    users: { email: string; name: string; _id: string }[];
    workflows: { title: string; description: string; _id: string }[];
    savedFilters: SavedFilter[];
    currentSavedFilter?: SavedFilter;
    pinnedLeft?: string;
    pinnedRight?: string;
    meta: any;
    filtersLoading: boolean;
}

export interface FilterComponent {
    id: string;
    name: string;
    title: string;
    component: FunctionComponent<any>;
    left?: boolean;
    active?: boolean;
}

export interface BaseRow extends Record<string, any> {
    key?: string | number;
}

export interface IPagination {
    currentPage: number;
    totalPages: number;
    totalResults?: number;
}

export interface FetchResponseData<R> {
    data: R[];
    pagination: IPagination;
}

export interface FetchResponseDataFlat<R> {
    data: R[];
}

export interface Column<R> extends Omit<ColumnType<R>, "fixed"> {
    id: string;
    title: string;
    fixed?: string | boolean;
    frozen?: string | boolean;
}

interface abstractColumn<R> extends Column<R> {
    FilterComponent: any
}

interface ColsWidth {
    key: string;
    width: number;
}


export default abstract class TableAbstract<R extends BaseRow> extends TableBaseAbstract<R> {

    abstract fetch(signal: any): Promise<FetchResponseData<R> | FetchResponseDataFlat<R>>;
    abstract title: string;
    abstract pageTitle: string;
    abstract FilterComponents: FilterComponent[];
    abstract columns: Column<R>[];
    abstract defaultSortField: string;

    private async send() {
        try {

            const res = await this.fetch({signal: this.abortController.signal});

            return res.data;
        } catch (e: any) {
            console.error(e);
            this.setState({loading: false, error: e});
            return [];
        }
    }

    public getData = async () => {

        try {
            this.setState({selectedRows: [], loading: true}, async () => {
                const data = await this.send() as any;
                this.setState({selectedRows: [], rowData: data.documents, currentPage: data.meta.page, meta: data.meta, loading: false })
                // this.setState({selectedRows: [], rowData: [], currentPage: 9, meta: [], loading: false })
            });
        } catch (e: any) {
            console.error(e);
            this.setState({initialised: true, error: e, loading: false})
        }
    }
}
