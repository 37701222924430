import React, { useState, useEffect, useCallback } from "react";
import { Button, InputNumber, Modal } from "antd";

import { DownloadOutlined, EditOutlined } from "@ant-design/icons";

import "./ManagePmFee.scss";
import { useMercuryContext } from "../../user-context";

interface IPmFeeData {
  fee: number;
  updated_at: Date;
  created_at: Date;
  createdAt: Date;
  _id: string;
}

interface IProps {
  fetchingData?: boolean;
}

const ManagePmfee:React.FC<IProps> = ({fetchingData = false}) => {
  const context = useMercuryContext(),
    { legacyClient } = context;

  const [pmFeeModal, setPmFeeModal] = useState<boolean>(false),
    [pmFeeData, setPmFeeData] = useState<IPmFeeData | null>(null),
    [pmFeeValue, setPmFeeValue] = useState<number | undefined>(0),
    [loading, setLoading] = useState<boolean>(true);

  const getPmFeeData = useCallback(async () => {
    setLoading(true);
    const { data } = await legacyClient.get("/pmFee");
    setPmFeeData(data);
    setPmFeeValue(data.fee);
    setLoading(false);
  }, [legacyClient]);

  useEffect(() => {
    !pmFeeData && getPmFeeData();
  }, [pmFeeData, getPmFeeData]);

  const handleSavePmFee = async () => {
    setLoading(true);
    const res = await legacyClient.post("/pmFee", { fee: pmFeeValue });

    setPmFeeModal(!pmFeeModal);
    if (res.status === 200) {
      getPmFeeData();
    }
  };

  const handleCancelModal = () => {
    setPmFeeModal(!pmFeeModal);
    setPmFeeValue(pmFeeData?.fee);
  };

  return (
    <div className="manage-pmfee">
      <div className="pm-fee-container">
        <span className="title">PM Fee: </span>
        <span className="value">{pmFeeData?.fee}%</span>
      </div>
      <Button
        type="primary"
        icon={<EditOutlined />}
        onClick={() => setPmFeeModal(!pmFeeModal)}
        loading={loading}
        disabled={loading || fetchingData}
      >
        Edit PM Fee
      </Button>
      <Modal
        title={"Edit PM Fee"}
        onCancel={() => setPmFeeModal(!pmFeeModal)}
        open={pmFeeModal}
        footer={
          <>
            <Button
              type="default"
              onClick={() => handleCancelModal()}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={pmFeeData?.fee === pmFeeValue || loading}
              loading={loading}
              icon={<DownloadOutlined />}
              onClick={() => handleSavePmFee()}
            >
              Save
            </Button>
          </>
        }
      >
        <>
          <InputNumber
            onChange={(e: any) => setPmFeeValue(e)}
            value={pmFeeValue}
            min={0.1}
            max={100}
            step={1}
            placeholder={`${pmFeeValue}`}
            style={{ width: "65px" }}
          />
          <span style={{ marginLeft: "5px" }}>%</span>
        </>
      </Modal>
    </div>
  );
};

export default ManagePmfee;
