import React, {useState} from "react";
import {Button, Tooltip} from "antd";
// import InvoiceSubmissionModal from "./InvoiceSubmissionModal";
import {useMercuryContext} from "../../user-context";
import {failedNotification} from "../../utils/notifications";

export default function InvoiceButton( { selectedRows }:any ) {

    const
        {gpSocket} = useMercuryContext(),
        rows = selectedRows || [],
        states = [ "ACCRUED", "PENDING_INVOICE", "INVOICE_FAILED" ],
        checkStatus = ((element:any) => states.indexOf( element.status?.toUpperCase() ) !== -1),
        checkInvoiceName = (element: any) => element.invoiceName,
        allowSubmit = rows.length > 0 && rows.every(checkInvoiceName) && rows.filter( ( e:any ) => e.deleted !== true ).filter(checkStatus).length,
        [isLoading, setLoading] = useState( false );

    const handleOk = () => {
        setLoading( true );
        const socket = gpSocket.adj;
        Promise.all( ( rows.map( ( row: any ) => {
            return new Promise( ( accept ) => {
                socket.emit( "invoice", row._id, ( err:any ) => {
                    if ( err ) {
                        failedNotification( "Failed to submit batch for accrual", err );
                    }
                    return accept( null );
                })
            })
        })))
            .then( () => {
                return new Promise( ( accept ) => {
                    socket.emit( "recalculate", rows.map( ( row:any ) => row._id), accept )
                })
            })
            .then( () => {
                setLoading(false);
            })
    };

  return (
      <>
        <Tooltip
            title={!allowSubmit ? "Select at least 1 item" : ""}
            placement="bottom"
            color={"var(--tertiary-color)"}
        >
          <Button
              onClick={handleOk}
              loading={isLoading}
              disabled={!allowSubmit}
              type="primary">
            Invoice
          </Button>
        </Tooltip>
      </>
  )

}
