import React from "react";
import {FiniteListFilter, FiniteListProps} from "../../stories/FiniteListFilter/FiniteListFilter";

export default (props: FiniteListProps) => {
    return <FiniteListFilter
        {...props}
        data={[
            {
                key: 'NEW',
                title: "NEW",
                value: "NEW"
            },
            {
                key: 'CREATED',
                title: "CREATED",
                value: "CREATED"
            }
        ]}/>
}
