import {ActionTypes} from "../user-context";
import { default as io, Socket } from "../user-context/SocketIOSocket";

export default class ProgressManager {

    private dispatcher:any
    private socket:Socket;

    constructor(dispatcher:any, token:string ) {
        this.dispatcher = dispatcher;

        this.socket = io.create(
            "/google-gp-v1/ws/socket.io",
            token,
            ""
        );

        this.socket.on( "progress", this.requestListener )
    }

    public requestListener = ( data:any ) => {
        this.dispatcher( { type:ActionTypes.UPDATE_PROGRESS, payload: { requestId: data.requestId, item: data } })
    }
}
