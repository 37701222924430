import { Tooltip } from "antd";
import { CustomTooltip } from "../stories/CustomTooltip/CustomTooltip";
import TimezoneConverter from "../timezone-converter";
import CurrencyFormat from "react-currency-format";
import {AlignType} from "rc-table/lib/interface";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

//*** Define abstract table columns ***//

export const productColumn = {
    id: "product",
    title: "Product",
    dataIndex: "product",
    sorter: false,
    width: 200,
    render: (text: string) => {
        return <CustomTooltip text={text} length={25} />
    },
    fixed: undefined,
}

export const lastImportedTimestampColumn = {
    id: "lastImportedTimestamp",
    title: <Tooltip title="Last Imported Timestamp" placement="left" color={"var(--tertiary-color)"}>Last Imported Timestamp</Tooltip> as any,
    dataIndex: "lastImportedTimestamp",
    sorter: true,
    width: 150,
    render: (text: string) => <TimezoneConverter date={text}/>,
    fixed: undefined,
}

export const lastUpdatedTimestampColumn = {
    id: "lastUpdatedTimestamp", // from Google
    title: <Tooltip title="Last Updated Timestamp" placement="left" color={"var(--tertiary-color)"}>Last Updated Timestamp</Tooltip> as any,
    dataIndex: "lastUpdatedTimestamp",
    sorter: true,
    width: 150,
    render: (text: string) => <TimezoneConverter date={text}/>,
    fixed: undefined,
};

export const creationTimestampColumn = {
    id: "creationTimestamp",
    title: <Tooltip title="Creation Timestamp" placement="left" color={"var(--tertiary-color)"}>Creation Timestamp</Tooltip> as any,
    dataIndex: "creationTimestamp",
    sorter: true,
    width: 150,
    render: (text: string) => <TimezoneConverter date={text}/>,
    fixed: undefined,
};

export const serviceChargeColumn = {
    id: "serviceCharge",
    title: "Service Charge",
    dataIndex: "serviceCharge",
    sorter: true,
    width: 110,
    fixed: undefined,
    render: ( text:string ) => !text ? <span className="currency">{"$0.00"}</span> : <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value:any) => <span className={"currency"}><CustomTooltip text={value} length={16}/></span>} />
};

export const dtpRateColumn = {
    id: "dtpRate",
    title: "DTP Rate",
    dataIndex: "dtpRate",
    sorter: true,
    width: 90,
    fixed: undefined,
    render: ( text:string ) => !text ? <span className="currency">{"$0.00"}</span> : <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value:any) => <span className={"currency"}><CustomTooltip text={value} length={16}/></span>} />
};

export const dtpChargeColumn = {
    id: "dtpCharge",
    title: "DTP Charge",
    dataIndex: "dtpCharge",
    sorter: true,
    width: 110,
    fixed: undefined,
    render: ( text:string ) => !text ? <span className="currency">{"$0.00"}</span> : <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value:any) => <span className={"currency"}><CustomTooltip text={value} length={16}/></span>} />
};

export const engineeringRateColumn = {
    id: "engineeringRate",
    title: "Engineering Rate",
    dataIndex: "engineeringRate",
    sorter: true,
    width: 145,
    fixed: undefined,
    render: ( text:string ) => !text ? <span className="currency">{"$0.00"}</span> : <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value:any) => <span className={"currency"}><CustomTooltip text={value} length={16}/></span>} />
};

export const plpDueDateColumn = {
    id: "latestTranslationDueTimestamp",
    title: "Due Date",
    dataIndex: "latestTranslationDueTimestamp",
    sorter: true,
    width: 150,
    fixed: undefined,
    render: (text: string) => <TimezoneConverter date={text}/>
};

export const plpStatusColumn = {
    id: "catToolStatus",
    title: "Status",
    dataIndex: "catToolStatus",
    sorter: true,
    width: 130,
    fixed: undefined,
    render: (text: string) => <CustomTooltip text={text} length={15}/>
};

export const invoiceMonthColumn = {
    id: "invoiceMonth",
    name: "invoiceMonth",
    title: "Invoice Month",
    dataIndex: "invoiceMonth",
    align: "left" as AlignType,
    sorter: true,
    width: 130,
    render: (text: string) =>{
        if ( !text ) {
            return <i className={"muted"}>Not set</i>
        }
        try {
            new Date(text);
            return <TimezoneConverter date={text} format={"MM-YYYY"}/>
        }
        catch ( e ) {
            return <i className={"muted"}>Not set</i>
        }
    },
    fixed: undefined,
};


export const PODateColumn = {
    id: "PODate",
    name: "PODate",
    title: "PO Date",
    dataIndex: "PODate",
    align: "left" as AlignType,
    sorter: true,
    width: 130,
    render: (text: string) =>{
        if ( !text ) {
            return <i className={"muted"}>Not set</i>
        }
        try {
            new Date(text);
            return <TimezoneConverter date={text} format={"MM-YYYY"}/>
        }
        catch ( e ) {
            return <i className={"muted"}>Not set</i>
        }
    },
    fixed: undefined,
};

export const poTypeColumn = {
    id: "poType",
    name: "poType",
    title: "PO Type",
    dataIndex: "poTypeTitle",
    align: "left" as AlignType,
    width: 130,
    sorter: true,
    render: ( text:string ) => {
        return text ? <CustomTooltip text={text} length={15}/> : <i className={"muted"}>Not set</i>
    },
    fixed: undefined,
};

export const titleColumn = {
    id: "title",
    title: "Title",
    dataIndex: "title",
    sorter: true,
    width: 250,
    fixed: undefined,
    render: (text: string) => <CustomTooltip text={text} length={30}/>
};

export const descriptionColumn = {
    id: "description",
    title: "Description",
    dataIndex: "description",
    sorter: true,
    width: 250,
    fixed: undefined,
    render: (text: string) => <CustomTooltip text={text} length={30}/>
};

export const seasameStatusColumn = {
    id: "status",
    title: "Status",
    dataIndex: "status",
    sorter: true,
    width: 150,
    fixed: undefined,
    render: (text: string) => text
};

export const expeditedColumn =  {
    id: "expedited",
    name: "expedited",
    title:"Expedited",
    dataIndex: "expedited",
    align: "center" as AlignType,
    sorter: true,
    width: 95,
    render: (text: string) =>
        text ? (
            <CheckOutlined style={{color: "var(--tertiary-color)"}}/>
        ) : (
            <CloseOutlined style={{color: "var(--red)"}}/>
        ),
    fixed: undefined,
};

export const onHoldColumn = {
    id: "On hold",
    name: "onHold",
    title: "On Hold",
    dataIndex: "onHold",
    align: "center" as AlignType,
    sorter: true,
    width: 85,
    render: (text: string) =>
        text ? (
            <CheckOutlined style={{color: "var(--tertiary-color)"}}/>
        ) : (
            <CloseOutlined style={{color: "var(--red)"}}/>
        ),
    fixed: undefined,
};