import React, { useState, useEffect } from "react";
import {
  Button,
  Drawer,
  Form,
  Checkbox,
  Input,
  Select,
  Switch,
  Table,
  Tooltip,
} from "antd";

import { InfoCircleOutlined } from "@ant-design/icons";

import { groupAndSortByKey } from "../../../utils/helpers";

const EditRole = ({ handlers, values }) => {
  const { closeEditDrawer, onFinishEditing } = handlers,
    {
      editingForm,
      visibleEditDrawer,
      editing,
      editFormRef,
      permissionsList,
      applicationsList,
    } = values,
    editingRow = editing && editing.editingRow,
    editingRowPermissions = editing?.editingRow?.permissions,
    [displayedPermissions, setDisplayedPermissions] = useState([]), // rendered permissions
    [updatedPermissions, setUpdatedPermissions] = useState([]), // pemissions that will pe passed to the api request
    { Option } = Select,
    { TextArea } = Input;

  // fix to save in state on load
  useEffect(() => {
    setUpdatedPermissions(editingRowPermissions);

    const filteredPermissionsList =
      permissionsList &&
      permissionsList.filter(
        (permission) =>
          permission.customerApplication === editingRow.customerApplication
      );

    // fix to set default values
    editingRowPermissions &&
      editingRowPermissions.forEach((ep) => {
        let foundP = filteredPermissionsList.find(
          (p) => p.id === ep.permissionId
        );
        if (foundP) {
          if (ep.read) {
            foundP.read = ep.read;
          } else {
            foundP.read = false;
          }

          if (ep.edit) {
            foundP.edit = ep.edit;
          } else {
            foundP.edit = false;
          }

          if (ep.create) {
            foundP.create = ep.create;
          } else {
            foundP.create = false;
          }

          if (ep.delete) {
            foundP.delete = ep.delete;
          } else {
            foundP.delete = false;
          }
        }
      });

    setDisplayedPermissions([...filteredPermissionsList]);
  }, [editingRowPermissions, permissionsList, editingRow.customerApplication]);

  function onChangePermission(e, id) {
    const newCheckboxName = e.target.name,
      newCheckboxValue = e.target.checked;

    // update displayed list
    let displayedCheckbox =
      displayedPermissions &&
      displayedPermissions.find((permission) => permission.id === id);
    displayedCheckbox[newCheckboxName] = newCheckboxValue;
    setDisplayedPermissions([...displayedPermissions]);

    // update permissions that will be sent
    let updatedCheckbox =
      updatedPermissions &&
      updatedPermissions.find((permission) => permission.permissionId === id);
    updatedCheckbox[newCheckboxName] = newCheckboxValue;

    setUpdatedPermissions([...updatedPermissions]);
  }

  const initialFormValues = {
    nameValue: editingRow.name,
    descriptionValue: editingRow.description,
    customerApplicationValue: editingRow.customerApplication,
    isActive: editingRow.isActive,
  };

  const permissionColumns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "",
      dataIndex: "description",
      key: "description",
      width: 10,
      render: (text, record) => (
        <Tooltip
          title={record.description}
          color={"var(--tertiary-color)"}
          placement={"left"}
        >
          <InfoCircleOutlined style={{ color: "var(--tertiary-color)" }} />
        </Tooltip>
      ),
    },
    {
      title: "View",
      dataIndex: "read",
      key: "read",
      render: (text, record) =>
        record.supportsRead ? (
          <Checkbox
            name="read"
            checked={record.read}
            onChange={(e) => onChangePermission(e, record.id)}
          />
        ) : (
          <Checkbox name="read" checked={record.read} disabled />
        ),
    },
    {
      title: "Create",
      dataIndex: "create",
      key: "create",
      render: (text, record) =>
        record.supportsCreate ? (
          <Checkbox
            name="create"
            checked={record.create}
            onChange={(e) => onChangePermission(e, record.id)}
          />
        ) : (
          <Checkbox name="create" checked={record.create} disabled />
        ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      render: (text, record) =>
        record.supportsEdit ? (
          <Checkbox
            name="edit"
            checked={record.edit}
            onChange={(e) => onChangePermission(e, record.id)}
          />
        ) : (
          <Checkbox name="edit" checked={record.edit} disabled />
        ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (text, record) =>
        record.supportsDelete ? (
          <Checkbox
            name="delete"
            checked={record.delete}
            onChange={(e) => onChangePermission(e, record.id)}
          />
        ) : (
          <Checkbox name="delete" checked={record.delete} disabled />
        ),
    },
  ];

  const groupedPermissions =
    displayedPermissions && groupAndSortByKey(displayedPermissions, "category");

  const renderedPermissions = [];
  // render a table for each category
  for (const [category, list] of Object.entries(groupedPermissions)) {
    const permissionGroup = (
      <Table
        columns={permissionColumns}
        dataSource={list?.sort((a, b) => a.name.localeCompare(b.name))}
        title={() => category}
        key={category}
        pagination={false}
        className="role-table"
      />
    );

    renderedPermissions.push(permissionGroup);
  }

  const handleChangeApplication = (value) => {
    // when user changes customer application a new set of permissions should be visible
    // generate a new list of permisssions that will be displayed
    const filteredPermissionsList = permissionsList.filter(
      (permission) => permission.customerApplication === value
    );

    setDisplayedPermissions([...filteredPermissionsList]);

    // generate a new list of permisssions that will be sent on finish editing
    const newUpdatedPermissions = filteredPermissionsList.map((p) => {
      const newPermission = {
        permissionId: p.id,
        create: false, // default to unchecked
        delete: false,
        edit: false,
        read: false,
      };
      return newPermission;
    });

    setUpdatedPermissions([...newUpdatedPermissions]);
  };

  return (
    <>
      <Drawer
        title={`Edit Role: ${editingRow.name}`}
        placement="right"
        onClose={closeEditDrawer}
        open={visibleEditDrawer}
        width={400}
        closable={true}
        className="manage-pages-drawer"
        forceRender
      >
        {editingRow && (
          <Form
            form={editingForm}
            layout="vertical"
            onFinish={(values) =>
              onFinishEditing(values, editingRow.id, updatedPermissions)
            }
            name="editUserForm"
            ref={editFormRef}
            initialValues={initialFormValues}
            className="edit-role-form"
          >
            <Form.Item
              name="nameValue"
              label="Name"
              rules={[
                { required: true, message: "Please type the new role name" },
              ]}
            >
              <Input placeholder="Ex: System Admin" />
            </Form.Item>
            <Form.Item
              name="descriptionValue"
              label="Description"
              rules={[
                { required: true, message: "Please type the new description" },
              ]}
            >
              <TextArea
                rows={4}
                placeholder="Ex: Project Manager role for ABC Company"
              />
            </Form.Item>

            <Form.Item
              label="Active"
              name="isActiveValue"
              valuePropName="checked"
              initialValue={editingRow.isActive}
            >
              <Switch defaultChecked={editingRow.isActive} />
            </Form.Item>

            {/* // TODO: generate options from api - use id's and ignore current services */}
            <Form.Item
              label="Customer Application"
              name="customerApplicationValue"
              rules={[
                {
                  required: true,
                  message: "Please select customer application",
                },
              ]}
            >
              <Select
                placeholder="Select application"
                onChange={(value) => handleChangeApplication(value)}
              >
                {/* // TODO: set core value */}
                {applicationsList
                  ?.filter((i) => i.active)
                  .map((app) => {
                    return (
                      <Option value={app.id} key={app.id}>
                        {app.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item label="Permissions">{renderedPermissions}</Form.Item>

            <Button type="primary" htmlType="submit">
              Update Role
            </Button>
          </Form>
        )}
      </Drawer>
    </>
  );
};

export default EditRole;
