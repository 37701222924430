import React from "react";
import axios from "axios";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import { failedNotification } from "../utils/notifications";
class DownloadBtn extends React.Component {
  state = {
    downloadState: "INACTIVE",
    hasError: false,
    errorMessage: "",
  };

  handleDownload = async () => {
    this.setState({
      downloadState: "DOWNLOADING",
    });

    try {
      let headers = {
        Authorization: `Bearer ${this.props.accessToken}`,
      };

      let response = null;

      // POST with the array of files to download
      response = await axios.post(
        `${this.props.baseURI}/projects/${this.props.shortId}/files/download`,
        this.props.resourceName,
        {
          headers: headers,
        }
      );

      // Get the URL to upload the file to
      let downloadURL = response.data.url;

      // Create a link and click it to download the file
      let downloadLink = document.createElement("a");
      downloadLink.href = downloadURL;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      // There is an error so show a fail
      if (error) {
        failedNotification(
          "Download failed",
          "Unable to download the file(s) at this time."
        );
      }
    } finally {
      this.setState({
        downloadState: "INACTIVE",
      });
    }
  };

  render() {
    switch (this.state.downloadState) {
      case "DOWNLOADING":
        return (
          <Button
            type="primary"
            className="download-projects"
            icon={<DownloadOutlined />}
            style={{ marginRight: "20px" }}
            loading
          >
            Download
          </Button>
        );
      case "INACTIVE":
      default:
        return (
          <Button
            type="primary"
            className="download-projects"
            icon={<DownloadOutlined />}
            style={{ marginRight: "20px" }}
            onClick={this.handleDownload}
          >
            Download
          </Button>
        );
    }
  }
}

export default DownloadBtn;
