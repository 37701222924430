import React from "react";
import { Typography, Tooltip } from "antd";

const DetailsPageHeader = ({ title, projectId }) => {
  const { Title } = Typography;
  return (
    <header
      style={{
        padding: "30px 0 5px 0px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Title level={3} style={{ margin: "0", color: "var(--primary-color)" }}>
        <Tooltip title="Project ID" placement="bottom" color={"var(--tertiary-color)"}>
          {projectId}
        </Tooltip>
      </Title>
      <Title level={4} style={{ margin: "0 5px 0 0" }}>
        :
      </Title>
      <Title level={4} style={{ margin: "0 5px 0 0" }}>
        Invoice Details:
      </Title>
      <Title
        level={4}
        style={{
          margin: "0",
          textTransform: "capitalize",
        }}
      >
        {title}
      </Title>
    </header>
  );
};

export default DetailsPageHeader;
