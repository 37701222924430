import React, { useState } from "react";
import TimezoneConverter from "../../../timezone-converter";
import Total from "../../../ui/Total";

import { Table, Typography, Input, Spin, Row, Tooltip } from "antd";
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { sorter, hideString } from "../../../utils/helpers";

const RolesTable = ({ values, handlers }) => {
  const { sortedInfo, total, apiClients, editing,isLoading,applicationsList } = values,
    {
      handleChangeTable,
      handleClickShowClientId,
      handleClickHideClientId,
      handleClickEdit,
    } = handlers,
    { Title } = Typography,
    { Search } = Input,
    [filterTable, setFilterTable] = useState(null);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => sorter(a.name, b.name),
      sortOrder: sortedInfo.field === "name" && sortedInfo.order,
      render: (text) => text,
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => sorter(a.description, b.description),
      sortOrder: sortedInfo.field === "description" && sortedInfo.order,
      render: (text) => text,
    },
    {
      title: "Customer Application",
      dataIndex: "customerApplication",
      sorter: (a, b) => sorter(a.customerApplication, b.customerApplication),
      sortOrder: sortedInfo.field === "customerApplication" && sortedInfo.order,
      render: (text) => {
        // match id with name
        const found = applicationsList?.find((app) => app.id === text);
        return found ? found.name : "Error: Could not match Id with name."
      },
    },
    {
      title: "Client ID",
      dataIndex: "clientId",
      sorter: (a, b) => sorter(a.clientId, b.clientId),
      sortOrder: sortedInfo.field === "clientId" && sortedInfo.order,
      width: 240,
      render: (text, record) =>
        record.isClientIdHidden ? (
          <div className="client-id-container">
            {hideString(text)}
            <Tooltip
              title={`View ID for client: ${record.name}`}
              placement={"right"}
            >
              <EyeOutlined
                className="view-icon"
                onClick={() => handleClickShowClientId(record.id)}
              />
            </Tooltip>
          </div>
        ) : (
          <div className="client-id-container">
            {text}
            <EyeInvisibleOutlined
              className="view-icon"
              onClick={() => handleClickHideClientId(record.id)}
            />
          </div>
        ),
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      sorter: (a, b) => sorter(a.createdAt, b.createdAt),
      sortOrder: sortedInfo.field === "createdAt" && sortedInfo.order,
      render: (text) => <TimezoneConverter date={text} />,
    },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      sorter: (a, b) => sorter(a.updatedAt, b.updatedAt),
      sortOrder: sortedInfo.field === "updatedAt" && sortedInfo.order,
      render: (text) => <TimezoneConverter date={text} />,
    },
    {
      title: "Active",
      dataIndex: "isActive",
      sorter: (a, b) => sorter(a.isActive, b.isActive),
      sortOrder: sortedInfo.field === "isActive" && sortedInfo.order,
      render: (text) =>
        text === true ? (
          <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
        ) : (
          <CloseOutlined style={{ color: "var(--red)" }} />
        ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      render: (text, record) => (
        <p
          className="edit-btn"
          onClick={() => {
            handleClickEdit(record);
          }}
        >
          <EditOutlined />
          <span>Edit</span>
        </p>
      ),
    },
  ];

  const handleSearch = (value) => {
    const filterTable = apiClients.filter((item) =>
      item.name.toString().toLowerCase().includes(value.toLowerCase())
    );
    setFilterTable(filterTable);
  };

  return (
    <>
      <Search
        style={{ width: "300px", margin: "0 0 10px 0" }}
        placeholder="Filter by name"
        onSearch={handleSearch}
        allowClear
        enterButton
      />
      <Row className="title-wrap">
        <Title level={5} style={{ margin: "5px 0 0" }}>
          Api Clients
          {editing.isEditing && (
            <Spin size="small" style={{ marginLeft: "10px" }} />
          )}
        </Title>
        <Total number={total} text="Api Clients" />
      </Row>
      <Table
        columns={columns}
        dataSource={filterTable == null ? apiClients : filterTable}
        className="manage-page-table api-clients-table"
        onChange={handleChangeTable}
        loading={isLoading}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: 20,
        }}
      />
    </>
  );
};

export default RolesTable;
