import { useState } from "react";
import {
  Button,
  Form,
  Modal,
  Tooltip,
  Checkbox,
  Row,
  Col,
  InputNumber,
} from "antd";
import { EditOutlined, WarningOutlined } from "@ant-design/icons";
import {
  failedNotification,
} from "../utils/notifications";
import { useMercuryContext } from "../user-context";
import uuid from "uuid-browser";

const BatchHours = (props: {
  options?: [];
  selectedRows: any;
  contentType: any;
  reload: () => Promise<void>;
  bust: () => void
}) => {
  const context = useMercuryContext();
  const { gpSocket } = context;
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const selectedRows = props.selectedRows;
  const projectShortIds = selectedRows?.map((p: any) => p.shortId);
  const [dtpHours, setDtpHours] = useState<boolean>(false);
  const [engineeringHours, setEngineeringHours] = useState<boolean>(false);
  const [serviceHours, setServiceHours] = useState<boolean>(false);
  const contentTypeText = props?.contentType === 'project' || props?.contentType === 'invoice' ? 'project(s)' : 'plps(s)';
  const rows = selectedRows || [];
  const allowedSubmitCriteria = (element: any) => element.varStatus !== "INVOICE";
  const allowSubmit = rows.length > 0 && rows.filter( ( e: any ) => e.deleted !== true ).filter(allowedSubmitCriteria).length;


  const confirmHours = () => {
    Modal.confirm({
      title: "Proceed to update the hours?",
      content: `You are about to update ${projectShortIds.length} ${contentTypeText}, this will change the costs of these ${contentTypeText}.`,
      mask: true,
      okText: "Confirm and Update",
      cancelText: "Cancel",
      onOk: () => {
        let values = form.getFieldsValue();
        setConfirmLoading(true);
        batchEditHours(
          values
        );
        setIsOpen(false);
      },
      okType: "primary",
      onCancel: async () => {
        resetCheckboxes();
        setIsOpen(false);
      },
      icon: <WarningOutlined style={{ color: "var(--red)" }} />,
      className: "action-btn delete-btn",
      width: 600,
      okButtonProps: { 
        style: { background: "var(--red)" },
      },
    });
  };

  const batchEditHours = async (values: any) => {

    try {

      setLoading(true);

      const workLog = {
        dtpHours: values?.dtpHours,
        engineeringHours: values?.engineeringHours,
        serviceHours: values?.serviceHours,
      } as Record<any, any>

      return await new Promise((accept, reject) => {

        const
          requestId = uuid(),
          projectIds = rows?.filter(allowedSubmitCriteria).map((p: any) => p._id);

        let
          contentType = props?.contentType === 'project' || props?.contentType === 'invoice' ? gpSocket.project : gpSocket.plp,
          data = {
            ids: projectIds,
            workLog
          },
          name = `Batch set hours ( ${projectIds.length} ) ${props?.contentType}(s)`

          // For plps send "batch:setQuantities:plp
          contentType.emit(`batchSetQuantities`, { requestId, name, data }, () => {
            setLoading(false);
            setConfirmLoading(false);
            setIsOpen(false);
            form.resetFields();
            resetCheckboxes();
            return accept(null);

          })

          contentType.on(requestId, async (requestData: any) => {
            try {
              return accept(requestData)
            } catch (e) {
              console.error(e)
              return reject(requestData?.error);
            }
          })

      })
    } catch (error) {
      console.error(error)
      failedNotification(`Failed to update projects`);
    }
  };

  const resetCheckboxes = () => {
    setDtpHours(false);
    setEngineeringHours(false);
    setServiceHours(false);
  }

  return (
    <>
      <Tooltip
        title={!allowSubmit ? "Select at least 1 item" : ""}
        placement="bottom"
        color={"var(--tertiary-color)"}
      >
        <Button
          onClick={() => setIsOpen(true)}
          className="action-btn edit-btn"
          icon={<EditOutlined />}
          type="primary"
          loading={loading}
          disabled={!allowSubmit}
        >
          Edit Hours
        </Button>
      </Tooltip>
      {/* @ts-ignore */}
      <Modal
        open={isOpen}
        title="Batch Update Hours"
        okText="Ok"
        cancelText="Cancel"
        onCancel={async () => {
          resetCheckboxes();
          form.resetFields();
          setIsOpen(false);
        }}
        okButtonProps={{
          disabled: dtpHours || engineeringHours || serviceHours ? false : true,
        }}
        onOk={async () =>
          (await form.validateFields()) &&
            (dtpHours || engineeringHours || serviceHours)
            ? confirmHours()
            : null
        }
        destroyOnClose={true}
        confirmLoading={confirmLoading}
      >
        <Form
          form={form}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 18,
          }}
          name="batchEditingHoursForm"
        >
          <p>
            Specify the hours to be updated on the selected {contentTypeText} using the
            checkboxes.
          </p>

          <Row gutter={18}>
            <Col span={22}>
              <Form.Item
                label="DTP Hours"
                name="dtpHours"
                rules={[
                  {
                    required: dtpHours,
                    message: "Please enter the DTP Hours",
                  },
                ]}
              >
                <InputNumber
                  step={0.01}
                  placeholder={"DTP Hours"}
                  disabled={!dtpHours}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item valuePropName="checked">
                <Checkbox
                  defaultChecked={false}
                  onChange={(e) => {
                    setDtpHours(e.target.checked);
                    form.resetFields(["dtpHours"]);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={18}>
            <Col span={22}>
              <Form.Item
                label="Engineering Hours"
                name="engineeringHours"
                rules={[
                  {
                    required: engineeringHours,
                    message: "Please enter the Engineering Hours",
                  },
                ]}
              >
                <InputNumber
                  step={0.01}
                  placeholder={"Engineering Hours"}
                  disabled={!engineeringHours}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item valuePropName="checked">
                <Checkbox
                  defaultChecked={false}
                  onChange={(e) => {
                    setEngineeringHours(e.target.checked);
                    form.resetFields(["engineeringHours"]);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={18}>
            <Col span={22}>
              <Form.Item
                label="Service Hours"
                name="serviceHours"
                rules={[
                  {
                    required: serviceHours,
                    message: "Please enter the Engineering Service Hours",
                  },
                ]}
              >
                <InputNumber
                  step={0.01}
                  placeholder={"Service Hours"}
                  disabled={!serviceHours}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item valuePropName="checked">
                <Checkbox
                  defaultChecked={false}
                  onChange={(e) => {
                    setServiceHours(e.target.checked);
                    form.resetFields(["serviceHours"]);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default BatchHours;
