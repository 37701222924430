import { useState, FunctionComponent } from "react";
import {
  failedNotification,
  successfulNotification,
} from "../../../utils/notifications";
import { Button } from "antd";
import { SisternodeOutlined } from "@ant-design/icons";
import { useMercuryContext } from "../../../user-context";

interface IProps {
  documentIds: string[];
}

const ShareDocumentsButton: FunctionComponent<IProps> = ({ documentIds }) => {
  const { isAdmin, gpClient } = useMercuryContext();

  const [isSharing, setIsSharing] = useState<boolean>(false);

  const handleClickShare = async () => {
    setIsSharing(true);

    let response = "";
    try {
      const body = {
        documentIds: documentIds,
        type: "MANUAL",
        // "linguistId":"",
        // "email": "",
        // "sesameId": ""
      };

      response = await gpClient.post(
        `/google-gp-v1/share/document/manual`,
        body
      );

      successfulNotification(
        `Your request to share documents has been sent, 
            any documents at IN TRANSLATION will be shared shortly.`,
        ""
      );
    } catch (error) {
      console.log("Sharing request response: ", response);
      console.error("Error sharing: ", error);
      failedNotification(`The request to share documents has failed`, "");
    }
    setIsSharing(false);
  };

  return isAdmin ? (
    <Button
      loading={isSharing}
      onClick={handleClickShare}
      className="action-btn edit-btn"
      icon={<SisternodeOutlined />}
      type="dashed"
      disabled={documentIds.length < 1}
      style={{ marginLeft: "10px" }}
    >
      Share Selected Documents
    </Button>
  ) : (
    <></>
  );
};

export default ShareDocumentsButton;
