import { notification } from "antd";

// Succesfull notification
export const successfulNotification = (title, description) => {
  notification.success({
    message: title,
    description: description,
    duration: 10,
    style: { padding: "8px" },
  });
};

// Error notification
export const failedNotification = (title, description) => {
  notification.error({
    message: title,
    description: description,
    duration: 10,
    style: { padding: "8px" },
  });
};

// info notification
export const infoNotification = (title, description) => {
  notification.info({
    message: title,
    description: description,
    duration: 10,
    style: { padding: "8px" },
  });
};
