import React from "react";
import axios from "axios";

import { Button, Tooltip, Popconfirm, notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const DeleteReferenceFileBtn = ({
  baseURI,
  accessToken,
  shortId,
  resourceName,
  name,
  projectStatus,
}) => {
  // call the api to delete only that reference file
  const handleDelete = async () => {
    let hasError = false;
    let errorTitle = "";
    let errorMessage = "";
    try {
      let headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const fileId = resourceName;

      let url = `${baseURI}/projects/${shortId}/files/delete/${fileId}`;

      // make the api request to delete the reference file
      await axios.delete(url, {
        headers: headers,
      });
    } catch (err) {
      console.error(err);
      errorTitle = err.response.data.error;
      errorMessage = err.response.data.detail;
      hasError = true;
    } finally {
      if (hasError) {
        notification.error({
          message: errorTitle,
          description: (
            <>
              <p>
                Unable to delete {name} at this time. Please Refresh your page.
              </p>
              <p>{errorMessage}</p>
            </>
          ),
          duration: 5,
          style: { padding: "8px" },
        });
      } else {
        notification.success({
          message: "Delete successfull",
          description: `${name} was deleted. Please press the Refresh button.`,
          duration: 5,
          style: { padding: "8px" },
        });
      }
    }
  };

  return projectStatus === "IN_PREPROCESSING" ? (
    <Tooltip color="var(--red)" title={`Delete ${name} ?`} placement={"left"}>
      <Popconfirm
        onConfirm={handleDelete}
        okText="Yes"
        cancelText="No"
        okType="danger"
        icon={<DeleteOutlined style={{ color: "var(--red)" }} />}
        title={
          <div>
            <b>Do you want to delete</b>
            <br />
            {name} ?
          </div>
        }
        cancelButtonProps={{ type: "ghost" }}
        okButtonProps={{ type: "primary" }}
      >
        <Button
          style={{
            border: "none",
            borderLeft: "1px solid var(--grey)",
            borderRadius: 0,
            padding: "0",
            height: "25px",
          }}
          icon={<DeleteOutlined style={{ color: "var(--red)"}} />}
          danger
        />
      </Popconfirm>
    </Tooltip>
  ) : (
    <Tooltip color="var(--grey)" title={`Reference files cannot be deleted once a project is no longer in the pre-processing state.`} placement={"left"}>  <Button
    style={{
      border: "none",
      borderLeft: "1px solid var(--grey)",
      borderRadius: 0,
      padding: "0",
      height: "25px",
      pointerEvents: 'none',
    }}
    icon={<DeleteOutlined />}
    disabled
  /></Tooltip>
  );
};

export default DeleteReferenceFileBtn;
