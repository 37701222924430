import React from "react";

import { Button, Drawer, Form, Input, Select } from "antd";

const EditExtensionJustification = ({ handlers, values }) => {
  const { Option } = Select;
  const { closeEditDrawer, onFinishEditing } = handlers;
  const { editingForm, visibleEditDrawer, editing, editFormRef } = values;
  const editingRow = editing && editing.editingRow;

  return (
    <>
      <Drawer
        title="Edit Extension Justification"
        placement="right"
        onClose={closeEditDrawer}
        open={visibleEditDrawer}
        width={600}
        closable={true}
        className="manage-pages-drawer"
        forceRender
      >
        {editingRow && (
          <Form
            form={editingForm}
            layout="vertical"
            onFinish={onFinishEditing}
            name="editJustificationForm"
            ref={editFormRef}
            initialValues={{
              modifier: "public",
              remember: true,
              idValue: editing.editingRow.id,
              googleTypeValue: editing.editingRow.googleType,
              kindValue: editing.editingRow.kind,
              limitValue: editing.editingRow.limit,
              frequencyValue: editing.editingRow.frequency,
              descValue: editing.editingRow.description,
            }}
          >

            <Form.Item name="idValue" noStyle>
              <Input type="hidden" />
            </Form.Item>

            <Form.Item
              name="googleTypeValue"
              label="Justification Name"
              rules={[
                {
                  required: true,
                  message: "Please enter the Justification Name",
                },
              ]}
            >
              <Input placeholder="Justification Name" />
            </Form.Item>

            <Form.Item
              name="kindValue"
              label="Kind"
              rules={[
                {
                  required: true,
                  message: "Please enter the kind",
                },
              ]}
            >
              <Select
                className="kind-select"
                placeholder="Please select kind"
                dropdownStyle={{
                  textAlign: "center",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "var(--tertiary-color)",
                }}
                popupMatchSelectWidth={false}
                allowClear
              >
                <Option key="1" value="review">
                  Additional review
                </Option>
                <Option key="2" value="fastApproval">
                  Fast approval
                </Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="limitValue"
              label="Due Date Change"
              rules={[
                {
                  required: true,
                  message: "Please enter a Due Date Change",
                },
              ]}
            >
              <Select
                className="limit-select"
                placeholder="Please select a Due Date Change"
                dropdownStyle={{
                  textAlign: "center",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "var(--tertiary-color)",
                }}
                popupMatchSelectWidth={false}
                allowClear
              >
                <Option key="1" value={0}>
                  Anytime
                </Option>
                <Option key="2" value={48}>
                  48 Hours
                </Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="frequencyValue"
              label="Frequency"
              rules={[
                {
                  required: true,
                  message: "Please enter the frequency of 1 or 0",
                },
              ]}
            >
              <Select
                className="frequency-select"
                placeholder="Please select a Frequency"
                dropdownStyle={{
                  textAlign: "center",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "var(--tertiary-color)",
                }}
                popupMatchSelectWidth={false}
                allowClear
              >
                <Option key="1" value={1}>
                  1
                </Option>
                <Option key="2" value={0}>
                  N/A
                </Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="descValue"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Please enter the description",
                },
              ]}
            >
              <Input.TextArea placeholder="description" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Justification
              </Button>
            </Form.Item>
          </Form>
        )}
      </Drawer>
    </>
  );
};

export default EditExtensionJustification;
