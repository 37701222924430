import React from "react";

import { Button, Drawer, Form, Input, Switch, Select } from "antd";

const EditProduct = ({ handlers, values }) => {
  const { Option } = Select;
  const { closeEditDrawer, onFinishEditing } = handlers;
  const { editingForm, visibleEditDrawer, dropdownOptions, editing, editFormRef } = values;

  const productAreaOptions =
    dropdownOptions &&
    dropdownOptions?.productArea.map((item) => (
      <Option value={item._id} key={item._id}>
        {item.title}
      </Option>
    ));

  const costCodeOptions =
    dropdownOptions &&
    dropdownOptions?.costCode.map((item) => (
      <Option value={item._id} key={item._id}>
        {item.code}
      </Option>
    ));
  const editingRow = editing && editing.editingRow;

  return (
    <>
      <Drawer
        title="Edit Product"
        placement="right"
        onClose={closeEditDrawer}
        open={visibleEditDrawer}
        width={500}
        closable={true}
        className="manage-pages-drawer"
        forceRender
      >
        {editingRow && (
          <Form
            form={editingForm}
            layout="vertical"
            onFinish={onFinishEditing}
            name="editProductForm"
            ref={editFormRef}
          >
            <Form.Item
              name="productValue"
              label="Product Title"
              rules={[{ required: true, message: "Please type product title" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="productAreaValue"
              label="Product Area"
              rules={[
                { required: true, message: "Please select the product area" },
              ]}
            >
              <Select>{productAreaOptions}</Select>
            </Form.Item>
            <Form.Item
              name="costCodeValue"
              label="Cost Code"
              rules={[
                { required: true, message: "Please select the cost code" },
              ]}
            >
              <Select>{costCodeOptions}</Select>
            </Form.Item>
            <Form.Item
              name="vendorValue"
              label="Vendor"
              rules={[{ required: true, message: "Please select the vendor" }]}
            >
              <Select>
                <Option value="sdl">Sdl</Option>
                <Option value="moravia">Moravia</Option>
              </Select>
            </Form.Item>
            <Form.Item name="descValue" label="Description">
              <Input.TextArea placeholder="Reason for changing product status" />
            </Form.Item>
            <Form.Item label="Non extendable without Fast Approval" name="nonExtendableValue" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item label="Query Variation" name="queryVariationValue" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item label="Active" name="activeValue" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update Product
              </Button>
            </Form.Item>
          </Form>
        )}
      </Drawer>
    </>
  );
};

export default EditProduct;
