import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import { LogoutButton as Logout } from "./../Components";
import Loading from "../ui/Loading";

import { Dropdown, Avatar, Typography, Button, Badge, Collapse } from "antd";

import "./profile.scss";

import { useLocation, Link } from "react-router-dom";
import { useMercuryContext } from "../user-context";

import SetTimezone from "../components/headerComponents/SetTimezone";
import { BellOutlined } from "@ant-design/icons";

import { matchAdminItem } from "../utils/helpers";

const Notifications = ({ handleMainMenuSelection }) => {
  const { userDetails } = useMercuryContext();
  if (userDetails.notifications.length === 0) {
    return null;
  }

  return (
    <Button
      type="text"
      icon={<BellOutlined />}
      className="profile-menu-item profile-menu-item__notifications"
    >
      <Link to={"/notifications"} onClick={() => handleMainMenuSelection(null)}>
        Notifications ({userDetails.notifications.length})
      </Link>
    </Button>
  );
};

const UserData = () => {
  const { userDetails } = useMercuryContext();
  const { Text } = Typography;
  return (
    <div
      className="top-wrap"
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      {userDetails.picture ? (
        <Avatar size="80" src={userDetails.picture} />
      ) : (
        <Avatar
          size="80"
          style={{ backgroundColor: stringToColour(userDetails.name) }}
        >
          {userDetails.firstName.substring(0, 1)}
          {userDetails.lastName.substring(0, 1)}
        </Avatar>
      )}
      <div
        className="details-wrap"
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "10px",
        }}
      >
        <Text strong>{userDetails.name}</Text>
        <Text>{userDetails.email}</Text>
      </div>
    </div>
  );
};

function stringToColour(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
}

const Settings = ({ isMainOpen }) => {
  const [activePanel, setActivePanel] = useState();

  const onChangePanel = (key) => {
    setActivePanel(key);
  };

  useEffect(() => {
    !isMainOpen && setActivePanel();
  }, [isMainOpen]);

  const items = [
    {
      key: "1",
      label: "Settings",
      children: <SetTimezone />,
    },
  ];

  return (
    <Collapse
      ghost
      className="profile-menu-item profile-menu-item__settings"
      activeKey={activePanel}
      onChange={onChangePanel}
      items={items}
    />
  );
};

const AdminArea = ({ adminData, toggleMainMenu, handleMainMenuSelection }) => {
  const location = useLocation(),
    currentLocation = location ? location.pathname : undefined,
    adminMenuItems = adminData.adminMenuItems,
    matchAdminMenuItem = matchAdminItem(adminMenuItems, currentLocation),
    [activePanel, setActivePanel] = useState();

  const onChangePanel = (key) => {
    setActivePanel(key);
  };

  const handleClickMenuItem = (children) => {
    toggleMainMenu();
    handleMainMenuSelection(null);
    adminData.openAdminMenu(children);
  };

  const items = [
    {
      key: "admin-area",
      label: "Admin Area",
      children: adminData?.adminMenuItems?.map((item) => {
        let isMatching = false;
        if (matchAdminMenuItem && matchAdminMenuItem[0]?.parentId === item.id) {
          isMatching = true;
        }

        return (
          <button
            key={item.id}
            onClick={() => handleClickMenuItem(item.children)}
            className={`admin-link ${isMatching ? "admin-link-active" : ""}`}
          >
            {item.title}
          </button>
        );
      }),
    },
  ];

  return (
    <Collapse
      items={items}
      activeKey={activePanel}
      onChange={onChangePanel}
      className="menu admin-menu"
    />
  );
};

const SesameArea = ({ isMainOpen }) => {
  const location = useLocation();
  const [activePanel, setActivePanel] = useState();
  const isActive = /\/sesame(\/\d+)?$/.test(location.pathname) ? "admin-link-active" : "";

  const onChangePanel = (key) => {
    setActivePanel(key);
  };

  useEffect(() => {
    !isMainOpen && setActivePanel();
  }, [isMainOpen]);

  const items = [
    {
      key: "1",
      label: "Sesame Area",
      children: (
        <Button type="text" className={`admin-link ${isActive ? "admin-link-active" : ""}`}>
          <Link to="/sesame">
            Sesame Account
          </Link>
        </Button>
      ),
    },
  ];

  return (
    <Collapse
      ghost
      className="profile-menu-item profile-menu-item__settings"
      activeKey={activePanel}
      onChange={onChangePanel}
      items={items}
    />
  );
};

SesameArea.propTypes = {
  isMainOpen: PropTypes.bool,
}

const Profile = ({ adminData, handleMainMenuSelection }) => {
  const { isAuthLoading, userDetails, isAdmin, flags } = useMercuryContext();

  const [isMainOpen, setIsMainOpen] = useState(false);

  if (isAuthLoading) {
    return <Loading />;
  }

  const toggleMainMenu = (e) => {
    setIsMainOpen(!isMainOpen);
  };

  const items = [
    {
      label: <UserData />,
      key: "0",
    },
    {
      label: (
        <Notifications handleMainMenuSelection={handleMainMenuSelection} />
      ),
      key: "1",
    },
    isAdmin && {
      label: (
        <AdminArea
          adminData={adminData}
          toggleMainMenu={toggleMainMenu}
          handleMainMenuSelection={handleMainMenuSelection}
        />
      ),
      key: "3",
    },
    {
      label: <Settings isMainOpen={isMainOpen} />,
      key: "4",
    },
    flags?.sesameAccount && {
      label: <SesameArea isMainOpen={isMainOpen} />,
      key: "5",
    },
    {
      label: <Logout />,
      key: "6",
    },
  ];
  return (
    userDetails.email && (
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
        placement="bottomRight"
        onOpenChange={toggleMainMenu}
        open={isMainOpen}
        overlayClassName="profile-menu"
      >
        <button
          className="ant-dropdown-link profile-wrap"
          onClick={(e) => e.preventDefault()}
          style={{
            padding: "0 10px",
          }}
        >
          <Badge
            count={userDetails.notifications.length}
            style={{ backgroundColor: "var(--tertiary-color)" }}
            size="small"
          >
            {userDetails.picture ? (
              <Avatar
                size="80"
                src={userDetails.picture}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <Avatar
                size="80"
                style={{
                  cursor: "pointer",
                  backgroundColor: stringToColour(userDetails.name),
                }}
              >
                {userDetails.firstName.substring(0, 1)}
                {userDetails.lastName.substring(0, 1)}
              </Avatar>
            )}
          </Badge>
        </button>
      </Dropdown>
    )
  );
};

export default Profile;
