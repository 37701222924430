import { useState } from "react";
import { Modal } from "antd";
import { failedNotification, successfulNotification } from "../../utils/notifications";

const SuspensionRequest = ({ userContext, selectedRows }: { userContext: any, selectedRows: any },) => {
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        try {
            await userContext.gpClient.post(
                `/google-gp-v1/sesameAccounts/accountSuspension`,
                selectedRows,
            );
            setLoading(false);
            handleCancel();
            successfulNotification("Success", `Sesame account suspension for ${selectedRows.map((el: { sesameIds: any; }) => el.sesameIds).join(", ")}`)
        } catch (error) {
            setLoading(false);
            failedNotification("Error", `Selected sesame accounts suspension failed`)
        }
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <Modal
            title="Suspension Request"
            open={visible}
            onOk={handleSubmit}
            onCancel={handleCancel}
            okButtonProps={{ disabled: loading }}
            okText="Ok"
            confirmLoading={loading}
        >
            {selectedRows.map((el: { sesameIds: any; }) => el.sesameIds).join(", ")}
        </Modal>
    );
};

export default SuspensionRequest;