/******** SDL ID Variables ********/

// dev variables

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const domain = process.env.REACT_APP_AUTH_DOMAIN;
export const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
export const audience = process.env.REACT_APP_AUTH_AUDIENCE;
export const HELIX_BASE_URL = process.env.REACT_APP_HELIX_BASE_URL;

//test variables

// export const BASE_URL = process.env.REACT_APP_TEST_BASE_URL;
// export const domain = process.env.REACT_APP_TEST_AUTH_DOMAIN;
// export const clientId = process.env.REACT_APP_TEST_AUTH_CLIENT_ID;
// export const audience = process.env.REACT_APP_TEST_AUTH_AUDIENCE;

// preprod variables

// export const BASE_URL = process.env.REACT_APP_PREPROD_BASE_URL;
// export const domain = process.env.REACT_APP_PREPROD_AUTH_DOMAIN;
// export const clientId = process.env.REACT_APP_PREPROD_AUTH_CLIENT_ID;
// export const audience = process.env.REACT_APP_PREPROD_AUTH_AUDIENCE;

// prod variables

// export const BASE_URL = process.env.REACT_APP_PROD_BASE_URL;
// export const domain = process.env.REACT_APP_PROD_AUTH_DOMAIN;
// export const clientId = process.env.REACT_APP_PROD_AUTH_CLIENT_ID;
// export const audience = process.env.REACT_APP_PROD_AUTH_AUDIENCE;

// API URL variables

export const API_URL = process.env.REACT_APP_API_URL;

// GMLS Linguist Management variables

export const glms_stage = process.env.REACT_APP_GLMS_STAGE;
export const BASE_GLMS_URL = process.env.REACT_APP_BASE_GLMS_URL;

// Extension variables

export const ext_stage = process.env.REACT_APP_EXT_STAGE;
export const EXTENSIONS_API = process.env.REACT_APP_EXTENSIONS_API;

// Adjustments variables

export const gadjs_stage = process.env.REACT_APP_GADJS_STAGE;

// Launch Darkly variables

export const ldId = process.env.REACT_APP_LD_ID;