import React from "react";
import {Form, Select} from "antd";

const { Option } = Select;

export default function Urgency( props ) {

  const {
    selectedUrgency,
    handleUrgencyChange,
    urgency
  } = props;

  return (
    <Form.Item
      label="Urgency"
      name="urgency"
      rules={[{ required: false}]}
      key={props.key}
    >
      <Select
        style={{ width: '100%' }}
        placeholder="Please select"
        value={selectedUrgency}
        onChange={handleUrgencyChange}
        onClear={handleUrgencyChange}
        allowClear={true}
      >
        {
          ( urgency || [] ).map( ( p ) => {
            return <Option key={p.key} value={p.title}>{p.title}</Option>
          })
        }
      </Select>
    </Form.Item>
  )
}