import React, { useCallback, useEffect, useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
} from "antd";
import moment from "moment";
import { useMercuryContext } from "../../user-context";
import {
  failedNotification,
  successfulNotification,
} from "../../utils/notifications";
import { useScrollMonitor } from "../../Components";

import "./AddProjectRate.scss";
import EffectiveDateTooltip from "./EffectiveDateTooltip";

interface INewRate {
  _id: string;
  name: string;
  service: string;
  sourceLanguageCode: string;
  targetLanguageCode: string;
  wordRate: number;
  serviceRate: number;
  serviceUnit: string;
  dtpRate: number;
  dtpUnit: string;
  engRate: number;
  engUnit: string;
  currency: string;
  effectiveDate: Date | string | null;
  active: boolean;
}

interface IProps {
  reload: () => any;
  fetchingData?: boolean;
}

const AddProjectRate: React.FC<IProps> = ({ reload, fetchingData }) => {
  const context = useMercuryContext(),
    { legacyClient, client } = context;

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false),
    [switchValue, setSwitchValue] = useState(false),
    [services, setServices] = useState<any[]>([]),
    [languages, setLanguages] = useState<any[]>([]),
    [currencies, setCurrencies] = useState<any[]>([]),
    [loading, setLoading] = useState<boolean>(false),
    [errorMsg, setErrorMsg] = useState<string>("");

  const position = useScrollMonitor();

  const [form] = Form.useForm();

  // TODO: remove the entire height logic once we update antd version and scrollLocker warning is not visible
  // with the current antd version, the user is allowed to scroll, but when is scrolling the header height is changing
  let height = 64,
    drawerClassName = "add-project-rate-drawer";

  if (isDrawerOpen && position < 64 && position >= 40) {
    height = 64 - (position - 40);
  } else if (isDrawerOpen && position < 64) {
    height = 64;
  } else if (isDrawerOpen) {
    height = 40;
  }

  if (isDrawerOpen && height <= 47) {
    drawerClassName = "add-project-rate-drawer ant-drawer__bigger";
  }

  const getOptionsData = useCallback(async () => {
    setLoading(true);
    const currenciesData = await client.get("/core-cs-v1"),
      servicesData = await client.get("/core-gss-v1/service"),
      languagesData = await legacyClient.get("/languageCode");

    setCurrencies(currenciesData.data);
    setServices(servicesData.data);
    setLanguages(languagesData.data);
    setLoading(false);
  }, [client, legacyClient]);

  useEffect(() => {
    isDrawerOpen && getOptionsData();
  }, [getOptionsData, isDrawerOpen]);

  const disabledDate = (current: any) => {
    // disable days before today
    return current && current < new Date(Date.now() - 864e5);
  };

  const closeDrawer = () => {
    setErrorMsg("");
    setIsDrawerOpen(false);
    form.resetFields();
    setSwitchValue(false);
  };

  // handle change of active switch button
  function handleChangeActive(checked: any) {
    setSwitchValue(checked);
  }

  const createProjectRate = async (body: INewRate) => {
    try {
      await client({
        method: "post",
        url: "/core-grs-v1/rate",
        data: body,
      }).then((result: any) => {
        if (result.data.error) {
          failedNotification(result.data.error, result.data.message);
        } else {
          successfulNotification(
            `${result.data.name} rate was created.`,
            "The new rate should be visible in the rates table."
          );
          isDrawerOpen && closeDrawer();
        }

        reload();
      });
    } catch (e: any) {
      console.error("Error creating a new rate: ");
      console.error(e);

      if (e.response.status === 422) {
        e.response.data.details.nameValidationError &&
          failedNotification(
            e.response.data.message,
            e.response.data.details.nameValidationError.message
          );

        e.response.data.details.effectiveDateValidationError &&
          failedNotification(
            e.response.data.message,
            e.response.data.details.effectiveDateValidationError.message
          );
      } else {
        failedNotification(e.response.data.message, e.response.data.details);
      }
    }
  };

  const onFinishNewRate = (values: any) => {
    const { wordRateValue, serviceRateValue, dtpRateValue, engRateValue } =
      values;

    if (
      (wordRateValue && wordRateValue > 0) ||
      (serviceRateValue && serviceRateValue > 0) ||
      (dtpRateValue && dtpRateValue > 0) ||
      (engRateValue && engRateValue > 0)
    ) {
      const newValues = {
        _id: values.idValue,
        name: values.nameValue,
        service: values.serviceValue,
        sourceLanguageCode: values.sourceLanguageCodeValue,
        targetLanguageCode: values.targetLanguageCodeValue,
        wordRate: wordRateValue,
        serviceRate: serviceRateValue,
        serviceUnit: values.serviceUnitValue,
        dtpRate: dtpRateValue,
        dtpUnit: values.dtpUnitValue,
        engRate: engRateValue,
        engUnit: values.engUnitValue,
        currency: values.currencyValue,
        effectiveDate: values.effectiveDate
          ? moment(values.effectiveDate.toISOString()).format(
              "YYYY-MM-DDT00:00:00"
            )
          : null,
        active: switchValue,
      };
      setErrorMsg("");
      createProjectRate(newValues);
    } else {
      setErrorMsg(
        "Rate was not created! Please provide a value greater than 0 for at least one of the following fields: Word Rate, Service Rate, DTP Rate or Eng Rate."
      );
    }
  };

  const sortedLanguages = [...languages].sort((a, b) => {
    if (a.code === "ALL") {
      return -1;
    }
    return b.code === "ALL" ? 1 : 0;
  });

  return (
    <div className="add-project-rate">
      <Button
        type="primary"
        onClick={() => setIsDrawerOpen(true)}
        disabled={isDrawerOpen || fetchingData}
      >
        <PlusCircleOutlined /> Add Project Rate
      </Button>
      { isDrawerOpen && (
          <Drawer
            title="Add Project Rate"
            placement="right"
            onClose={closeDrawer}
            open={isDrawerOpen}
            width={550}
            closable={true}
            className={drawerClassName}
            forceRender
          >
            <Form
              layout="horizontal"
              form={form}
              onFinish={onFinishNewRate}
              name="AddProjectRateForm"
              initialValues={{
                modifier: "public",
              }}
            >
              <Form.Item
                name="nameValue"
                label="Name"
                rules={[
                  { required: true, message: "Please enter Project Rate name" },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>

              <Form.Item
                name="serviceValue"
                label="Service"
                initialValue={null}
              >
                <Select
                  className="service-select"
                  placeholder="Select a Service"
                  dropdownStyle={{
                    textAlign: "center",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "var(--tertiary-color)",
                  }}
                  popupMatchSelectWidth={false}
                  allowClear
                  loading={loading}
                >
                  {services.map((service, index) => (
                    <Select.Option key={service._id} value={service._id}>
                      {service.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="sourceLanguageCodeValue"
                label="Source Language"
                rules={[
                  {
                    required: true,
                    message: "Please select a Source Language",
                  },
                ]}
              >
                <Select
                  showSearch
                  className="language-select"
                  placeholder="Select a Source Language"
                  dropdownStyle={{
                    textAlign: "center",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "var(--tertiary-color)",
                  }}
                  popupMatchSelectWidth={false}
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                      // @ts-ignore
                    option?.children.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={loading}
                >
                  {sortedLanguages.map((sourceLang, index) => (
                    <Select.Option key={sourceLang._id} value={sourceLang._id}>
                      {sourceLang.code}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="targetLanguageCodeValue"
                label="Target Language"
                rules={[
                  {
                    required: true,
                    message: "Please select a Target Language",
                  },
                ]}
              >
                <Select
                  showSearch
                  className="language-select"
                  placeholder="Select a Target Language"
                  dropdownStyle={{
                    textAlign: "center",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "var(--tertiary-color)",
                  }}
                  popupMatchSelectWidth={false}
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                      // @ts-ignore
                    option?.children.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={loading}
                >
                  {sortedLanguages.map((targetLang, index) => (
                    <Select.Option key={targetLang._id} value={targetLang._id}>
                      {targetLang.code}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="wordRateValue"
                label="Word Rate"
                rules={[
                  { required: false },
                  ({ getFieldValue }) => ({
                    validator(record, value) {
                      errorMsg.length > 0 && setErrorMsg("");
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber
                  placeholder="Word Rate"
                  min={0}
                  precision={4}
                  step={1}
                  max={999}
                />
              </Form.Item>

              <Form.Item
                name="serviceRateValue"
                label="Service Rate"
                rules={[
                  { required: false },
                  ({ getFieldValue }) => ({
                    validator(record, value) {
                      const ratesForm = getFieldValue("serviceUnitValue");
                      errorMsg.length > 0 && setErrorMsg("");
                      if (value === null) {
                        return Promise.resolve();
                      } else if (value === "") {
                        return Promise.reject(
                          new Error("Enter a Service Rate")
                        );
                      } else if (ratesForm !== null) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error("Enter a Service Unit")
                        );
                      }
                    },
                  }),
                ]}
                initialValue={null}
              >
                <InputNumber
                  placeholder="Service Rate"
                  min={0}
                  precision={2}
                  step={1}
                  max={999}
                />
              </Form.Item>

              <Form.Item
                name="serviceUnitValue"
                label="Service Unit"
                rules={[
                  { required: false },
                  ({ getFieldValue }) => ({
                    validator(record, value) {
                      const ratesForm = getFieldValue("serviceRateValue");
                      if (value === null) {
                        return Promise.resolve();
                      } else if (value === "") {
                        return Promise.reject(
                          new Error("Enter a Service Unit")
                        );
                      } else if (ratesForm !== null) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error("Enter a Service Rate")
                        );
                      }
                    },
                  }),
                ]}
                initialValue={null}
              >
                <Select
                  placeholder="Select Service Unit"
                  className="in-cell-input"
                  allowClear
                >
                  <Select.Option value="hour">Hour</Select.Option>
                  <Select.Option value="minute">Minute</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="dtpRateValue"
                label="DTP Rate"
                rules={[
                  { required: false },
                  ({ getFieldValue }) => ({
                    validator(record, value) {
                      const ratesForm = getFieldValue("dtpUnitValue");
                      errorMsg.length > 0 && setErrorMsg("");
                      if (value === null) {
                        return Promise.resolve();
                      } else if (value === "") {
                        return Promise.reject(new Error("Enter a DTP Rate"));
                      } else if (ratesForm !== null) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error("Enter a DTP Unit"));
                      }
                    },
                  }),
                ]}
                initialValue={null}
              >
                <InputNumber
                  placeholder="DTP Rate"
                  min={0}
                  precision={2}
                  step={1}
                  max={999}
                />
              </Form.Item>

              <Form.Item
                name="dtpUnitValue"
                label="DTP Unit"
                rules={[
                  { required: false },
                  ({ getFieldValue }) => ({
                    validator(record, value) {
                      const ratesForm = getFieldValue("dtpRateValue");
                      if (value === null) {
                        return Promise.resolve();
                      } else if (value === "") {
                        return Promise.reject(new Error("Enter a DTP Unit"));
                      } else if (ratesForm !== null) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error("Enter a DTP Rate"));
                      }
                    },
                  }),
                ]}
                initialValue={null}
              >
                <Select
                  placeholder="Select DTP Unit"
                  className="in-cell-input"
                  allowClear
                >
                  <Select.Option value="hour">Hour</Select.Option>
                  <Select.Option value="minute">Minute</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="engRateValue"
                label="Eng Rate"
                rules={[
                  { required: false },
                  ({ getFieldValue }) => ({
                    validator(record, value) {
                      const ratesForm = getFieldValue("engUnitValue");
                      errorMsg.length > 0 && setErrorMsg("");
                      if (value === null) {
                        return Promise.resolve();
                      } else if (value === "") {
                        return Promise.reject(new Error("Enter a Eng Rate"));
                      } else if (ratesForm !== null) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error("Enter a Eng Unit"));
                      }
                    },
                  }),
                ]}
                initialValue={null}
              >
                <InputNumber
                  placeholder="Eng Rate"
                  min={0}
                  precision={2}
                  step={1}
                  max={999}
                />
              </Form.Item>

              <Form.Item
                name="engUnitValue"
                label="Eng Unit"
                rules={[
                  { required: false },
                  ({ getFieldValue }) => ({
                    validator(record, value) {
                      const ratesForm = getFieldValue("engRateValue");
                      if (value === null) {
                        return Promise.resolve();
                      } else if (value === "") {
                        return Promise.reject(new Error("Enter a Eng Unit"));
                      } else if (ratesForm !== null) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error("Enter a Eng Rate"));
                      }
                    },
                  }),
                ]}
                initialValue={null}
              >
                <Select
                  placeholder="Select Eng Unit"
                  className="in-cell-input"
                  allowClear
                >
                  <Select.Option value="hour">Hour</Select.Option>
                  <Select.Option value="minute">Minute</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="currencyValue"
                label="Currency"
                rules={[
                  { required: true, message: "Please select a Currency" },
                ]}
              >
                <Select
                  className="currency-select"
                  placeholder="Select a Currency"
                  dropdownStyle={{
                    textAlign: "center",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "var(--tertiary-color)",
                  }}
                  popupMatchSelectWidth={false}
                  allowClear
                  loading={loading}
                >
                  {currencies
                    .filter((item) => item.isActive)
                    .map((cur) => (
                      <Select.Option key={cur._id} value={cur._id}>
                        {cur.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item label="Active">
                <Switch checked={switchValue} onChange={handleChangeActive} />
              </Form.Item>

              <Form.Item
                label={<EffectiveDateTooltip />}
                name="effectiveDate"
                rules={[
                  { required: true, message: "Please set an effective date." },
                ]}
              >
                <DatePicker
                  disabledDate={disabledDate}
                  placeholder="Select Date"
                />
              </Form.Item>

              <Form.Item name="orderValue" noStyle>
                <Input type="hidden" />
              </Form.Item>
              {errorMsg.length > 0 && (
                <p
                  style={{
                    padding: "5px",
                    color: "var(--red)",
                    border: "1px dashed var(--red)",
                  }}
                >
                  {errorMsg}
                </p>
              )}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Save Project Rate
                </Button>
              </Form.Item>
            </Form>
          </Drawer>
        )
      }
    </div>
  );
};

export default AddProjectRate;
