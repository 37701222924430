import React, { useState } from "react";
import { Modal, Form, Checkbox, Row, Col } from "antd";

const PLPRecoveryForm = ({
  values,
  handlers,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { deletedPLPs, recovering } = values;
  const { handleSubmitPLPRecovery, handleClickPLPRecovery } = handlers;

  const [form] = Form.useForm();

  return (
    <Modal
      open={recovering}
      title="PLP Recovery"
      okText="OK"
      okType="primary"
      cancelText="Cancel"
      onCancel={async () => {
        handleClickPLPRecovery(false);
        setConfirmLoading(false);
      }}
      onOk={async () => {
        try {

          // get for values
          let values = await form.validateFields();
          setConfirmLoading(true);
          await handleSubmitPLPRecovery(values);
          setConfirmLoading(false);
          handleClickPLPRecovery(false);
          form.resetFields();
        } catch (error) {
          console.log("Validate Failed:", error);
        }
      }}
      confirmLoading={confirmLoading}
      className="plp-recovery-modal"
    >
      <Form
        form={form}
        layout="vertical"
        name="RecoveryForm"
      >
        <Form.Item 
          name="deletedPLPs" 
          label={ deletedPLPs.length > 1 ? `Select from the following language codes` :
          "Select the language code"}>
          <Checkbox.Group>
            <Row>
              {
                deletedPLPs.map((item, index) => (
                  <Col key={index} style={{marginRight:'8px'}}>
                    <Checkbox value={item._id} style={{ lineHeight: '16px' }}> {item.targetLanguageCode}
                    </Checkbox>
                  </Col>
                ))

              }
            </Row>

          </Checkbox.Group>
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default PLPRecoveryForm;
