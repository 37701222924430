import { Table } from "antd";
import TimezoneConverter from "../timezone-converter";

const StatusHistory = ({ statusHistory }: any) => {
  const history = statusHistory?.map((el: any) => {
    return {
      key: el?._id,
      ...el,
    };
  });

  return (
    <div style={{ margin: "0 10px", border: "1px solid #d9d9d9" }}>
      <Table
        dataSource={history}
        columns={[
          {
            title: "Date",
            dataIndex: "timestamp",
            align: "left",
            render: (text) => {
              return <TimezoneConverter date={text} />;
            },
          },
          {
            title: "Status",
            dataIndex: "status",
            align: "left",
            render: (text) => text,
          },
        ]}
        pagination={false}
      />
    </div>
  );
};

export default StatusHistory;
