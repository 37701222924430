import React, {useState} from "react";
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import CreateAdjustmentModal from "./CreateModal";

export default function CreateAdjustmentButton( { reload, page }:any ) {

  const
      [isLoading] = useState( false ),
      [Show, setShow] = useState(false);

  const toggleShow = () => {
    setShow( !Show )
  };

  return (
      <>
        <Button
            onClick={toggleShow}
            loading={isLoading}
            icon={<PlusOutlined />}
            type="primary">
          Create
        </Button>
        { Show && <CreateAdjustmentModal show={Show} toggle={toggleShow} reload={reload} page={page} /> }
      </>
  )

}
