import {
    BaseRow,
    Column,
    FilterComponent
} from "./Table.abstract";
import TableBaseAbstract from "./TableBase";

export default abstract class TableWSAbstract<R extends BaseRow> extends TableBaseAbstract<R> {
    abstract title: string;
    abstract pageTitle: string;
    abstract FilterComponents: FilterComponent[];
    abstract columns: Column<R>[];
    abstract defaultSortField: string;
    abstract send():Promise<void>
    abstract getData():Promise<void>;
}
