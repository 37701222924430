import React, { useState } from "react";

import { Descriptions, Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import DownloadReferenceBtn from "../../../containers/DownloadReferenceBtn";
import DownloadReferenceZip from "../../../containers/DownloadReferenceZip";
import DeleteReferenceFileBtn from "../../../containers/DeleteReferenceFileBtn";

import { BASE_URL } from "../../../utils/variables";

import DeleteAllReferenceFilesBtn from "../../../containers/DeleteAllReferenceFilesBtn";
import { useMercuryContext } from "../../../user-context";


const ReferenceFiles = ({ data, limit, accessToken, shortId, projectStatus }) => {
  const [displayedList, setDisplayedList] = useState(data.slice(0, limit));
  const [isActive, setIsActive] = useState(false);

  const context = useMercuryContext();

  const showAllReferenceFiles = () => {
    setDisplayedList(data);
    setIsActive(true);
  };

  const showLessReferenceFiles = () => {
    setDisplayedList(data.slice(0, limit));
    setIsActive(false);
  };

  const referenceFiles = displayedList.map((item, index) => (
    <Descriptions.Item
      className="reference-file"
      key={index}
      label={item.displayName}
    >
      <DownloadReferenceBtn
        client={context.gpClient}
        resourceId={item._id}
        name={item.displayName}
      />
      <DeleteReferenceFileBtn
        baseURI={BASE_URL}
        accessToken={accessToken}
        shortId={shortId}
        resourceName={item._id}
        name={item.displayName}
        projectStatus={projectStatus}
      />
    </Descriptions.Item>
  ));

  return (
    <>
      <Descriptions
        bordered={true}
        size={"small"}
        column={3}
        title={`Reference Files (${data.length}):`}
        style={{ marginTop: "5px", border: "1px solid #e1e7eb" }}
        className="reference-table"
        extra={
          data.length > 1 && (
            <>
              <DownloadReferenceZip
                className="download-all-icon"
                baseURI={BASE_URL}
                accessToken={accessToken}
                shortId={shortId}
              />
              <DeleteAllReferenceFilesBtn
                baseURI={BASE_URL}
                accessToken={accessToken}
                shortId={shortId}
                data={data}
                projectStatus={projectStatus}
              />
            </>
          )
        }
      >
        {referenceFiles}
      </Descriptions>
      {isActive && data.length > limit && (
        <Button
          type="primary"
          size="small"
          className="show-button"
          onClick={showLessReferenceFiles}
          icon={<UpOutlined />}
        >
          Show Less
        </Button>
      )}

      {!isActive && data.length > limit && (
        <Button
          type="primary"
          size="small"
          className="show-button"
          onClick={showAllReferenceFiles}
          icon={<DownOutlined />}
        >
          Show All
        </Button>
      )}
    </>
  );
};

export default ReferenceFiles;
