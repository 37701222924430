import React, {createRef} from "react";
import Table, {BaseTableProps, Column, Column as BaseColumn} from "react-base-table";

import "react-base-table/styles.css";
import "./BaseTable.scss";

import LoadingStar from "../Loaders/LoadingStar";
import VirtualScrolbar from "./VirtualScrolbar";
import EmptyOverlay from "./EmptyOverlay";

function TableCell( props: any ) {

  const
      rowData = props.rowData,
      text = rowData[props.column.dataIndex],
      record = rowData;

  if ( props.render ) {
    return props.render(text, record);
  }

  return text;
}


type SortingOrder = 'asc' | 'desc';
export interface SortingKey {
  key: string;
  order: SortingOrder;
}

export interface TableProps extends Omit<BaseTableProps, "width"|"height"> {
  loading: boolean;
  enabledRowSelection: boolean;
  dataSource: any;
  rowSelection?: any;
  onColumnSort: (params: any) => any;
  sortBy: SortingKey;
  rowClassName: any;
  columns: any;
  itemsPerPage: number;
  cellHeight?: number;
  showFilters?: boolean;
  updateColumnWidth?: (params: any) => any;
  CellRenderer?:any;
  width?:number;
  height?:number;
  footerRenderer?:any;
  footerHeight?:number;
  enabledVirtualScrollbar?: boolean;
  useEstimatedRowHeight?: boolean;
}

export interface TableColumn extends BaseColumn {
  _id: any;
}

export class SelectionCell extends React.PureComponent<any, any> {
  _handleChange = (e: any) => {
    const { rowData, column } = this.props;
    const { onChange } = column;

    onChange(rowData);
  };

  render() {
    const
        { rowData, column } = this.props,
        { selectedRowKeys } = column;

    const checked = selectedRowKeys.includes(rowData.key);

    return (
      <input type="checkbox" checked={checked} onChange={this._handleChange} />
    );
  }
}

function Empty( props:any ) {
  if ( props.loading ) return null;
  return props.node || null;
}

export default class BaseTable extends React.Component<TableProps, any> {
  private CellRenderer:React.FunctionComponent;

  protected constructor(props: any) {
    super(props);

    this.CellRenderer = props.CellRenderer || TableCell;

    this.state = {
      columns: props.columns,
      fullScreen: false,
      selectedRowKeys: props.rowSelection
        ? props.rowSelection.selectedRowKeys
        : [],
      cellHeight: props.cellHeight || 41.5,
      tableWidth: props.hasSidebar ? window.innerWidth - 200 : window.innerWidth,
      changedIds: new Set()
    }
  }

  getTableHeight = () => {
    let tableHeight = window.innerHeight - 104;

    // if(this.props.showFilters) {
      let filters = this.props.filtersRef;

      let
          filtersHeight = filters ? filters.getBoundingClientRect().height.toFixed(2) : 0;

      // actions bar - filters - header
      tableHeight = window.innerHeight - Number(filtersHeight)  - 80;

    // }

    return tableHeight;
  };

  updateTableSize = () => {
    this.setState({
      tableWidth: this.props.hasSidebar ? window.innerWidth - 200 : window.innerWidth
    });

    this.getTableHeight()
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateTableSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateTableSize);
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot?: any) {
    if (this.state.columns !== this.props.columns) {
      this.setState({ columns: this.props.columns });
    }

    if (
      this.props.rowSelection &&
      this.state.selectedRowKeys !== this.props.rowSelection.selectedRowKeys
    ) {
      this.setState({
        selectedRowKeys: this.props.rowSelection.selectedRowKeys,
      });
    }
  }

  CheckboxHeaderCell = () => {
    const _handleChange = (e: any) => {
      if (e.target.checked) {
        this.props.rowSelection?.onSelectAll(true);
      } else {
        this.props.rowSelection?.onSelectAll();
      }
    };

    return <input type="checkbox" onChange={_handleChange} />;
  };

  tableRef:any = createRef();

  estimatedRowHeight = ({ rowData }: any) => {
    // estimate height based on the length of the text
    const rowTextLength = (rowData.col1 || '').length + (rowData.col2 || '').length;
    const estimatedHeight = Math.max(40, Math.ceil(rowTextLength / 20) * 30);

    return estimatedHeight;
  };

  render() {
    const { hasSidebar, useEstimatedRowHeight } = this.props;
    let columns = [
      ...this.state.columns.map((c: any) => {
        let frozen;
        if (c.fixed === "left") frozen = BaseColumn.FrozenDirection.LEFT;
        if (c.fixed === "right") frozen = BaseColumn.FrozenDirection.RIGHT;

        return {
          key: c.dataIndex,
          title: c.title,
          dataIndex: c.dataIndex,
          sortable: c.sorter,
          width: c.width ? c.width + 10 : 160,
          maxWidth: c.width ? c.width + 300 : 460,
          minWidth: 60,
          resizable: true,
          align: c.align,
          cellRenderer: ( row:any ) => this.CellRenderer( { ...row, ...c } ),
          frozen: frozen,
        };
      }),
    ];

    if (this.props.enabledRowSelection) {
      columns = [
        {
          width: 40,
          flexShrink: 0,
          resizable: false,
          frozen: BaseColumn.FrozenDirection.LEFT,
          cellRenderer: SelectionCell,
          // cellRenderer: this.CheckboxSelectionCell,
          // ...selectionColumnProps,
          key: "__selection__",
          rowKey: "__rowKey__",
          selectedRowKeys: this.state.selectedRowKeys,
          onChange: this.props.rowSelection?.onSelect,
          headerRenderer: this.CheckboxHeaderCell,
        },
        ...columns,
      ];
    }

    const showVirtualScrollBar = this.props.enabledVirtualScrollbar ?? true;

    return (
        <>
          <Table
              ref={this.tableRef}
              width={this.props.width || this.state.tableWidth}
              // height={this.props.itemsPerPage * this.state.cellHeight}
              height={this.props.height ?? this.getTableHeight()}
              disabled={this.props.loading}
              fixed
              columns={columns}
              data={this.props.dataSource.map((r: TableColumn) => {
                return { ...r, id: r._id, key: r._id };
              })}
              //@ts-ignore
              rowClassName={(data: any) => {
                const rowClass = this.props.rowClassName(data.rowData, data.rowIndex);
                let finalClass = rowClass;

                if (useEstimatedRowHeight) {
                  finalClass += ' flex-wrap-row';
                }

                if (this.props.enabledRowSelection) {
                  if (this.state.selectedRowKeys.includes(data.rowData.id)) {
                    finalClass += ' BaseTable__row--selected';
                  }
                }

                return finalClass;
              }}
              
              // fix to match antd
              onColumnSort={(data: any) => {
                // fix to cancel sorting on click
                if ((data.key === this.props.sortBy.key) && (this.props.sortBy.order === 'desc')) {
                  return this.props.onColumnSort({
                    column: undefined,
                    field: undefined,
                    order: undefined,
                  })
                }

                return this.props.onColumnSort({
                  column: data.column,
                  field: data.key,
                  order:
                      data.order === "asc"
                          ? "ascend"
                          : data.order === "desc"
                              ? "descend"
                              : data.order,
                })
              }
              }
              overscanRowCountnumber={100}
              sortBy={this.props.sortBy}
              className={this.props.className}
              rowHeight={this.props.rowHeight||40}
              headerHeight={40}
              onColumnResize={this.props.updateColumnWidth}
              overscanRowCountnumberdefaults={200}
              overlayRenderer={
                this.props.overlayRenderer ? this.props.overlayRenderer :
                () => this.props.loading ? <div className={"loading-table-wrapper"}><LoadingStar logo={true} animate={true} title={""} /></div> : null
              }
              onScroll={this.props.onScroll}
              emptyRenderer={<Empty node={this.props.emptyRenderer} loading={this.state.loading} />}
              footerHeight={this.props.footerHeight ?? 0}
              footerRenderer={this.props.footerRenderer}
              estimatedRowHeight={useEstimatedRowHeight ? this.estimatedRowHeight : undefined}
          />
          {showVirtualScrollBar && <VirtualScrolbar hasSidebar={hasSidebar} table={this.tableRef} />}
        </>

    );
  }
}
