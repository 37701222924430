import React, { useState } from "react";

import { Descriptions, Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const GoogleReferenceFiles = ({ data, limit, accessToken, shortId, projectStatus }) => {
  const result = data.filter( item => item.url);
  const [displayedList, setDisplayedList] = useState(result.slice(0, limit));
  const [isActive, setIsActive] = useState(false);

  const showAllReferenceFiles = () => {
    setDisplayedList(data);
    setIsActive(true);
  };

  const showLessReferenceFiles = () => {
    setDisplayedList(data.slice(0, limit));
    setIsActive(false);
  };

  const googleReferenceFiles = displayedList.map((item, index) => (
    <Descriptions.Item
      key={index}
    >
      <a href={item.url}  target="_blank" rel="noopener noreferrer" >
          { item.name }
      </a>
    </Descriptions.Item>
  ));

  return (
    <>
      <Descriptions
        bordered={true}
        size={"small"}
        column={3}
        title={` Google Reference Files (${result.length}):`}
        style={{ marginTop: "5px", border: "1px solid #e1e7eb" }}
        className="reference-table"
      >
        {googleReferenceFiles}
      </Descriptions>
      {isActive && data.length > limit && (
        <Button
          type="primary"
          size="small"
          className="show-button"
          onClick={showLessReferenceFiles}
          icon={<UpOutlined />}
        >
          Show Less
        </Button>
      )}

      {!isActive && data.length > limit && (
        <Button
          type="primary"
          size="small"
          className="show-button"
          onClick={showAllReferenceFiles}
          icon={<DownOutlined />}
        >
          Show All
        </Button>
      )}
    </>
  );
};

export default GoogleReferenceFiles;
